import storage from "@faizaanceg/pandora";
import datalayerPush from "common/datalayer-push";
import ErrorBoundary from "common/errors/boundary";
import ModalHandler from "common/modal-handler";
import { parseStringAsBoolean } from "common/parse-string";
import WalkThrough, { actionTypes } from "common/walkthrough";
import EnableTwoFactorAuthenticationModal from "modals/enable-2fa";
import WalkThroughModal from "modals/walkthrough";
import React, {
  forwardRef,
  Suspense,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import Joyride, { ACTIONS, EVENTS } from "react-joyride";
import { useSelector } from "react-redux";

const ResellerWalkThrough = forwardRef((props, ref) => {
  const [run, setRun] = useState(false);
  const [tempRun, setTempRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [disableNext, setDisableNext] = useState(false);
  const [imageHash, setImageHash] = useState(false);
  const [enable2faModal, setEnable2faModal] = useState(false);
  const is2faEnabled = useSelector(state =>
    parseStringAsBoolean(state.details.profileInfo.twofactorauth_enabled)
  );

  const { t } = useTranslation();
  useEffect(() => {
    const SHOW_WALKTHROUGH_IN_PAGE = "/dashboard";
    if (
      props.isOldReseller &&
      !props.isMaintenanceOn &&
      document.location.pathname === SHOW_WALKTHROUGH_IN_PAGE &&
      !storage.get("walk-through-completed", false)
    ) {
      props.showWalkThroughModal();
    }
  }, []);

  useImperativeHandle(ref, () => ({ startTour }));

  const startTour = () => {
    setRun(true);
    setStepIndex(0);
  };

  const showHelpMessage = () => {
    setTempRun(true);
    setTimeout(() => setTempRun(false), 4000);
    setTimeout(() => setWalkThroughCompleted(), 500);
    setTimeout(() => !is2faEnabled && setEnable2faModal(true), 500);
  };

  const setWalkThroughCompleted = () =>
    storage.set("walk-through-completed", true, { shouldPersist: true });

  const resetTour = () => {
    setRun(false);
    setStepIndex(0);
    setWalkThroughCompleted();
    if (!is2faEnabled) {
      setEnable2faModal(true);
    }
  };

  const handleJoyrideCallback = data => {
    const { action, index, type } = data;
    switch (type) {
      case EVENTS.STEP_BEFORE:
        if (stepIndex === 2) {
          setDisableNext(true);
          setImageHash(true);
          setTimeout(() => setDisableNext(false), 5500);
        } else {
          setImageHash(false);
        }
        break;
      case EVENTS.TOUR_END:
        if (run) {
          resetTour();
        }
        setWalkThroughCompleted();
        break;
      case EVENTS.STEP_AFTER:
      case EVENTS.TARGET_NOT_FOUND:
        setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
        datalayerPush("Walkthrough Next", {
          modal: index + 1
        });
        break;
      default:
        // Unreachable step
        break;
    }
  };

  return (
    <Suspense fallback={<div />}>
      {run && (
        <WalkThrough
          run={run}
          stepIndex={stepIndex}
          handleJoyrideCallback={handleJoyrideCallback}
          handleJoyrideClose={resetTour}
          disableNext={disableNext}
          imageHash={imageHash}
        />
      )}
      <ErrorBoundary errorMessage="">
        <ModalHandler
          opensOn={actionTypes.SHOW_WALKTHROUGH_MODAL}
          render={modalProps => (
            <WalkThroughModal
              startWalkThrough={startTour}
              showHelpMessage={showHelpMessage}
              {...modalProps}
            />
          )}
        />
      </ErrorBoundary>
      <ErrorBoundary errorMessage="">
        {enable2faModal && (
          <EnableTwoFactorAuthenticationModal
            onHide={() => setEnable2faModal(false)}
            show={enable2faModal}
          />
        )}
      </ErrorBoundary>
      {tempRun && (
        <Joyride
          continuous
          scrollToFirstStep
          showProgress={false}
          run={tempRun}
          disableOverlayClicks
          tooltipComponent={({ tooltipProps }) => (
            <div className="walkthrough-header-close" {...tooltipProps}>
              {t(
                "DASHBOARD_WALKTHROUGH_FIND",
                "You can always find the walkthrough here!"
              )}
            </div>
          )}
          steps={[{ disableBeacon: true, target: "#step7", content: "" }]}
        />
      )}
    </Suspense>
  );
});

export default ResellerWalkThrough;
