import {
  categories as AnalyticsCategories,
  actions as AnalyticsActions
} from "analytics/constants";

import { isSuccess, isFailure } from "common/api-status/utils";

export const actionTypes = {
  GET_CUSTOMER_DETAILS: "[Customer Profile] Get customer details",
  SUSPEND_CUSTOMER: "[Customer Profile] Suspend customer",
  UNSUSPEND_CUSTOMER: "[Customer Profile] Unsuspend customer",
  DELETE_CUSTOMER: "[Customer Profile] Delete customer",
  GET_CUSTOMER_ACTIONS: "[Customer Profile] Get customer actions",
  SAVE_PIN: "[Customer Profile] Save PIN",
  AUTO_LOGIN: "[Customer Profile] Start auto login",
  MODIFY_CUSTOMER_REQUEST: "[Customer Profile] Modify details",
  SUSPENDED_CUSTOMER_CANNOT_AUTO_LOGIN:
    "[Customer Profile]Cannot log into a suspended account",
  REFRESH_CUSTOMER_DETAILS_ERROR: "[Customer profile] Refresh customer details",
  SAVE_PASSWORD: "[Customer Profile] Save Password",
  SHOW_SUSPEND_MODAL: "[Customer Profile] Show suspend modal",
  SHOW_UNSUSPEND_MODAL: "[Customer Profile] Show unsuspend modal",
  SHOW_DELETE_MODAL: "[Customer Profile] Show delete modal",
  SHOW_EDIT_MODAL: "[Customer Profile] Show edit modal",
  SHOW_PASSWORD_MODAL: "[Customer Profile] Show password modal",
  SHOW_PIN_MODAL: "[Customer Profile] Show pin modal"
};

export const actions = {
  modifyCustomerDetails: (customerid, values) => ({
    type: actionTypes.MODIFY_CUSTOMER_REQUEST,
    customerid,
    values,
    track: {
      eventCategory: AnalyticsCategories.customer_management,
      eventAction: AnalyticsActions.edit_customer_profile
    }
  }),
  modifyCustomerDetailsSuccess: () => ({
    type: isSuccess(actionTypes.MODIFY_CUSTOMER_REQUEST)
  }),
  modifyCustomerDetailsFailure: error => ({
    type: isFailure(actionTypes.MODIFY_CUSTOMER_REQUEST),
    error
  }),
  suspendCustomer: (customerId, params) => ({
    type: actionTypes.SUSPEND_CUSTOMER,
    customerId,
    params
  }),
  unSuspendCustomer: (customerId, params) => ({
    type: actionTypes.UNSUSPEND_CUSTOMER,
    customerId,
    params
  }),
  deleteCustomer: customerId => ({
    type: actionTypes.DELETE_CUSTOMER,
    customerId
  }),
  deleteCustomerSuccess: () => ({
    type: isSuccess(actionTypes.DELETE_CUSTOMER),
    track: {
      eventCategory: AnalyticsCategories.customer_management,
      eventAction: "Delete Customer"
    }
  }),
  deleteCustomerFailure: error => ({
    type: isFailure(actionTypes.DELETE_CUSTOMER),
    error
  }),
  savePassword: ({ customerid, newPassword }) => ({
    type: actionTypes.SAVE_PASSWORD,
    customerid,
    newPassword
  }),
  savePasswordSucccess: newPassword => ({
    type: isSuccess(actionTypes.SAVE_PASSWORD),
    value: { newPassword },
    track: {
      eventAction: "Change Account Password"
    }
  }),
  savePasswordFailure: error => ({
    type: isFailure(actionTypes.SAVE_PASSWORD),
    error
  }),
  savePin: payload => ({
    type: actionTypes.SAVE_PIN,
    payload,
    track: {
      eventAction: "Change Pin"
    }
  }),
  savePinSuccess: () => ({
    type: isSuccess(actionTypes.SAVE_PIN)
  }),
  savePinFailure: error => ({
    type: isFailure(actionTypes.SAVE_PIN),
    error
  }),
  getCustomerDetails: ({ customerId }) => ({
    type: actionTypes.GET_CUSTOMER_DETAILS,
    customerId
  }),
  getCustomerDetailsSuccess: (customer, orders) => ({
    type: isSuccess(actionTypes.GET_CUSTOMER_DETAILS),
    payload: {
      customer,
      orders
    }
  }),
  getCustomerDetailsFailure: error => ({
    type: isFailure(actionTypes.GET_CUSTOMER_DETAILS),
    error
  }),
  getCustomerActions: orderIds => ({
    type: actionTypes.GET_CUSTOMER_ACTIONS,
    orderIds
  }),
  getCustomerActionsSuccess: response => ({
    type: isSuccess(actionTypes.GET_CUSTOMER_ACTIONS),
    response
  }),
  getCustomerActionsFailure: error => ({
    type: isFailure(actionTypes.GET_CUSTOMER_ACTIONS),
    error
  }),
  autoLoginResellerAsCustomer: (customerId, isCustomerActive) =>
    isCustomerActive
      ? {
          type: actionTypes.AUTO_LOGIN,
          payload: {
            customerId
          },
          track: {
            eventCategory: AnalyticsCategories.customer_management,
            eventAction: AnalyticsActions.auto_login_customer_account
          }
        }
      : {
          type: actionTypes.SUSPENDED_CUSTOMER_CANNOT_AUTO_LOGIN,
          error: new Error("Customer is suspended cannot login")
        },
  autoLoginSuccess: payload => ({
    type: isSuccess(actionTypes.AUTO_LOGIN),
    payload
  }),
  autoLoginFailure: (error, payload) => ({
    type: isFailure(actionTypes.AUTO_LOGIN),
    error,
    payload
  }),
  refreshCustomerDetailsError: error => ({
    type: actionTypes.REFRESH_CUSTOMER_DETAILS_ERROR,
    error
  }),
  showSuspendModal: () => ({
    type: actionTypes.SHOW_SUSPEND_MODAL
  }),
  showUnsuspendModal: () => ({
    type: actionTypes.SHOW_UNSUSPEND_MODAL
  }),
  showDeleteModal: () => ({
    type: actionTypes.SHOW_DELETE_MODAL
  }),
  showEditModal: () => ({
    type: actionTypes.SHOW_EDIT_MODAL
  }),
  showPasswordModal: () => ({
    type: actionTypes.SHOW_PASSWORD_MODAL
  }),
  showPINModal: () => ({
    type: actionTypes.SHOW_PIN_MODAL,
    track: {
      eventAction: "View Pin"
    }
  })
};

const helpers = {
  serializeActionsResponse: ({ recsindb, recsonpage, ...response } = {}) =>
    Object.values(response)
};

export const initialState = {
  customer: {},
  orders: [],
  actions: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case isSuccess(actionTypes.GET_CUSTOMER_DETAILS): {
      const { customer, orders } = action.payload;

      return { ...state, customer, orders };
    }

    case isSuccess(actionTypes.GET_CUSTOMER_ACTIONS): {
      return {
        ...state,
        actions: helpers.serializeActionsResponse(action.response)
      };
    }

    default:
      return state;
  }
}
