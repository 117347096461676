import { applyAll } from "common/util";
import { useState } from "react";

export function useHover() {
  let [hovered, setHover] = useState(false);
  return {
    hovered,
    onMouseEnter: _ => setHover(true),
    onMouseLeave: _ => setHover(false)
  };
}

export default function Hoverable({ children, ...props }) {
  let hoverProps = useHover();
  return children({
    ...hoverProps,
    ...props,
    onMouseEnter: applyAll(props.onMouseEnter, hoverProps.onMouseEnter),
    onMouseLeave: applyAll(props.onMouseLeave, hoverProps.onMouseLeave)
  });
}

Hoverable.defaultProps = {
  onMouseEnter: _ => {},
  onMouseLeave: _ => {}
};
