import api from "api";
import constants from "constants/endpoints";

export const services = {
  disableTheftProtection: orderId =>
    api.post(`${constants.domain}disable-theft-protection`, {
      "order-id": orderId
    }),
  enableTheftProtection: orderId =>
    api.post(`${constants.domain}enable-theft-protection`, {
      "order-id": orderId
    })
};
