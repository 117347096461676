import { isSuccess } from "common/api-status/utils";
import { actionTypes as customerCreateTypes } from "common/customer-create/ducks";
import { CustomerManagementSdk } from "common/sdk/customer-management";
import services from "common/services";
import { mapEffect } from "map-effect";
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  throttle
} from "redux-saga/effects";
import { customerTypes } from "../constants";
import { actionTypes as customerProfileActionTypes } from "../customer-profile/ducks";
import { actions, actionTypes } from "./ducks";

export const listCustomersSagas = [
  throttle(1500, actionTypes.GET_CUSTOMERS, mapEffect(onGetCustomers)),
  takeEvery(
    isSuccess(customerProfileActionTypes.GET_CUSTOMER_DETAILS),
    updateCustomerDetails
  ),
  takeLatest(isSuccess(customerCreateTypes.CREATE_CUSTOMER), updateNewCustomer),
  takeLatest(actionTypes.GET_ALL_CUSTOMERS, onGetAllCustomers)
];

function* updateNewCustomer(action) {
  let customer = yield services.getCustomerDetails(
    action.effectResponse.customerid
  );
  yield call(updateCustomerDetails, { payload: { customer } });
}

function* updateCustomerDetails(action) {
  try {
    const { payload: { customer } = {} } = action;
    const { [customer.customerid]: fromState = {} } = yield select(
      state => state.customerManagement.list.customers
    );
    let orderCount =
      customer?.orderCount > 0 ? customer.orderCount : fromState.orderCount;
    yield put(
      actions.updateCustomerDetails({ ...fromState, ...customer, orderCount })
    );
  } catch (error) {
    console.error("Failed to update customer", action, error);
  }
}

function getRootInfo(state) {
  let {
    defaultCustomer,
    userInfo: { useremail }
  } = state.root;
  return { defaultCustomer, resellerEmail: useremail };
}

function getCustomerType(type) {
  switch (type) {
    case customerTypes.active:
      return "Active";
    case customerTypes.inactive:
      return "InActive";
    case customerTypes.suspended:
      return "Suspended";
    default:
      break;
  }
}

function* onGetCustomers(action) {
  let { effectResponse = {}, payload } = action;
  payload = { ...payload, ...effectResponse };
  let { customerType } = payload;

  let { fromCount } = yield select(
    state => state.customerManagement.list[customerType]
  );
  let userId = yield select(state => state.root.userInfo.userid);
  let {
    customersList: customers = [],
    totalCustomersCount: totalCount = 0
  } = yield services.getCustomersNewestFirst({
    userId,
    skip: (fromCount / 10).toFixed(),
    status: getCustomerType(customerType)
  });

  let { defaultCustomer, resellerEmail } = yield select(getRootInfo);
  let customersById = {};
  let customerIDs = [];
  let idToSkip = null;

  if (customerType === "active" && !defaultCustomer.notFound) {
    totalCount -= 1;
    idToSkip = defaultCustomer.customerId;
  }
  if (defaultCustomer.notFound) {
    let customerWithSameName = customers.find(
      customer => customer.username === resellerEmail
    );
    if (customerWithSameName) {
      totalCount -= 1;
      idToSkip = customerWithSameName.customerid;
    }
  }
  for (let i = 0; i < customers.length; i++) {
    const {
      customerId: customerid,
      status: customerstatus,
      username: useremail
    } = customers[i];
    if (idToSkip === customerid) {
      continue;
    }
    customerIDs.push(customerid);
    customersById[customerid] = {
      ...customers[i],
      customerid,
      customerstatus,
      useremail
    };
  }
  if (customerIDs.length > 0) {
    let orderCounts = yield CustomerManagementSdk.orders.count(customerIDs);
    orderCounts.forEach(({ count, customerId }) => {
      customersById[customerId].orderCount = count;
    });
  }
  return { customers: customersById, totalCount, customerIDs, customerType };
}

function* onGetAllCustomers(action) {
  try {
    let [active, inactive, suspended] = yield all(
      [
        customerTypes.active,
        customerTypes.inactive,
        customerTypes.suspended
      ].map(customerType =>
        call(onGetCustomers, { ...action, payload: { customerType } })
      )
    );

    yield put({
      type: isSuccess(actionTypes.GET_ALL_CUSTOMERS),
      active,
      inactive,
      suspended
    });
  } catch (error) {
    console.error("Failed to get customers list", error);
  }
}
