import api from "api";
import customerManagementServices from "common/customer-management/customer-profile/services";
import { addQueryParamsToUrl } from "common/urls";
import endpoints from "constants/endpoints";
import format from "string-format";

function getServletParamForPanel(panelName) {
  switch (panelName) {
    case "codeguard":
      return null;
    case "dns":
    case "free_email":
    case "mailhosting":
    case "mailbox":
    case "premiumdns":
    case "virtualserverlinuxus":
    case "webhosting":
    case "websitebuilder":
      return "service-name";
    case "domainprotect":
      return "domainprotect";
    default:
      return "panel";
  }
}

/**
 * @typedef {Object} AdminPanelPayload
 * @property {string} panelName - Indicates the panel to redirect the user to.
 * @property {string} redirectUrl - Indicates the branding url to use for OB redirection.
 * @property {string} customerid - Customer ID
 * @property {string} orderid - Order ID
 */

export const services = {
  /**
   *
   * @param {AdminPanelPayload} payload
   * @param {import("constants/index").SimpleName} simpleProductName
   * @returns {Promise<{ autoLoginUrl: string }>} SSOLink
   */
  getAdminPanelURL: async (
    { panelName, redirectUrl, ...payload },
    simpleProductName = null
  ) => {
    switch (simpleProductName) {
      case "procloud":
        let { ssoUrl } = await api.get(
          `${endpoints.zuulOrderBox}procloud/asia/sso`,
          {
            "order-id": payload.orderid,
            "for-customer": true
          }
        );
        return { autoLoginUrl: ssoUrl };
      case "backup":
        return api.get(
          format(payload.endpoints.getBackupAutoLoginDetails, payload),
          { "backup-order-id": payload.orderid }
        );
      case "titanmail":
        let { sso_url } = await api.get(
          format(payload.endpoints.getAutoLoginUrl, payload)
        );
        return { autoLoginUrl: sso_url };
      default: {
        let servletParams = { orderid: payload.orderid };
        let servletParam = getServletParamForPanel(panelName);

        if (servletParam === "domainprotect") {
          let { ssoUrl } = await api.get(
            `${endpoints.domain}sitelock/details`,
            { "order-id": payload.orderid }
          );
          return { autoLoginUrl: ssoUrl };
        }
        if (servletParam !== null) {
          servletParams[servletParam] = panelName;
        }
        let { TOKEN } = await customerManagementServices.getAutoLoginToken({
          customerId: payload.customerid
        });
        servletParams["auth-token"] = TOKEN;
        return {
          autoLoginUrl: addQueryParamsToUrl(
            `https://${redirectUrl}/servlet/ManageServiceServletForAPI`,
            servletParams
          )
        };
      }
    }
  },
  getWidgetDetails: ({ endpoint, method = "GET" }) => api.call(method, endpoint)
};
