import { MaintenancePage } from "common/maintenance";
import { showLoader } from "common/util";
import React, { lazy, Suspense } from "react";
import { Redirect, Route } from "react-router-dom";

const Dashboard = lazy(() =>
  import(/* webpackChunkName: "dashboard" */ "./dashboard/route")
);
const ProfileRoute = lazy(() =>
  import(/* webpackChunkName: "my-profile" */ "./dashboard/routes/my-profile")
);
const HelpRoute = lazy(() =>
  import(/* webpackChunkName: "dashboard-help" */ "./dashboard/routes/help")
);

const Orders = lazy(() =>
  import(/* webpackChunkName: "list-orders" */ "./list-orders")
);
const OrderRequests = lazy(() =>
  import(
    /* webpackChunkName: "order-requests" */ "./list-orders/routes/requests"
  )
);
const OrderDetail = lazy(() =>
  import(/* webpackChunkName: "order-detail" */ "./order-management")
);
const OrderHistory = lazy(() =>
  import(/* webpackChunkName: "order-history" */ "./order-history")
);
const MySites = lazy(() =>
  import(/* webpackChunkName: "sites-mfe" */ "./order-management/my-sites")
);

const Invoices = lazy(() =>
  import(/* webpackChunkName: "invoices" */ "./invoices")
);
const CreateManualInvoice = lazy(() =>
  import(
    /* webpackChunkName: "create-manual-invoice" */ "./invoices/create-manual-invoice"
  )
);

const ViewSystemInvoice = lazy(() =>
  import(
    /* webpackChunkName: "view-system-invoice" */ "./invoices/routes/view-invoice/system-invoice"
  )
);
const ViewManualInvoice = lazy(() =>
  import(
    /* webpackChunkName: "view-manual-invoice" */ "./invoices/routes/view-invoice/manual-invoice"
  )
);

const ViewReceipt = lazy(() =>
  import(
    /* webpackChunkName: "view-receipt" */ "./invoices/routes/view-invoice/receipt"
  )
);

const ProSuiteGetStarted = lazy(() =>
  import(
    /* webpackChunkName: "pro-suite-get-started" */ "./settings/pro-suite/pro-invoices/get-started"
  )
);
const ProSuiteWelcome = lazy(() =>
  import(/* webpackChunkName: "pro-suite-welcome" */ "./settings/pro-suite")
);
const APISettings = lazy(() =>
  import(
    /* webpackChunkName: "api-settings" */ "./settings/pro-suite/api-settings"
  )
);
const PaymentGateways = lazy(() =>
  import(
    /* webpackChunkName: "pro-suite-payment-gateways" */ "./settings/pro-suite/payment-gateways"
  )
);
const PaymentGatewayStatus = lazy(() =>
  import(/* webpackChunkName: "pg-status" */ "./payment-gateway-status")
);

function LoadingComponent(Component) {
  return (props) => (
    <Suspense fallback={showLoader}>
      <Component {...props} />
    </Suspense>
  );
}

let commonRoutes = [
  <Route
    key="maintenance"
    path="/maintenance"
    exact
    component={MaintenancePage}
  />,
  <Route
    key="dashboard-profile"
    path="/dashboard/profile"
    component={LoadingComponent(ProfileRoute)}
  />,
  <Route
    key="dashboard-help"
    path="/dashboard/help"
    component={LoadingComponent(HelpRoute)}
  />,
  <Route
    key="dashboard"
    path="/dashboard"
    component={LoadingComponent(Dashboard)}
    exact
  />,
  <Route
    key="orders-history"
    path="/orders/history/:domain/:product/:orderId?"
    component={OrderHistory}
  />,
  <Route
    key="orders-sites"
    path="/orders/sites/:orderid/:productKey"
    component={MySites}
  />,
  <Route
    key="orders-manage"
    path="/orders/manage/:domain/:product"
    component={OrderDetail}
  />,
  <Route path="/orders" key="order" component={Orders} exact />,
  <Route
    key="payment-gateway-status"
    path="/payment-gateway-status"
    component={PaymentGatewayStatus}
  />
];

if (process.env.FEATURE_AUTO_RENEWAL) {
  const BillingAgreementFlow = lazy(() =>
    import(
      /* webpackChunkName: "billing-agreement" */ "./billing-agreement-flow"
    )
  );
  commonRoutes.push(
    <Route
      path="/billing-agreement-flow"
      key="billing-agreement-flow"
      component={BillingAgreementFlow}
      exact
    />
  );
}

if (process.env.FEATURE_ENDPOINT !== "bigrock") {
  commonRoutes.push(
    <Route key="invoices" path="/invoices" component={Invoices} exact />,
    <Route key="receipts" path="/receipts" component={Invoices} exact />,
    <Route
      key="invoices-system"
      path="/invoices/system/:invoiceId"
      component={ViewSystemInvoice}
      exact
    />,
    <Route
      key="invoices-receipt"
      path="/invoices/receipt/:invoiceId"
      component={ViewReceipt}
      exact
    />,
    <Route
      key="invoice-detail"
      path="/invoices/:invoiceId"
      component={ViewManualInvoice}
    />
  );
}

export const getUnAuthenticatedRoutes = () => {
  const DevTools = lazy(() =>
    import(/* webpackChunkName: "devtools" */ "./devtools")
  );

  return [
    <Route path="/tools" exact key="tools" component={DevTools} />,
    <Route
      path="/customer"
      key="obox-customer"
      render={(props) => (
        <Redirect to={{ pathname: "/login", search: props.location.search }} />
      )}
    />,
    <Route path="/" exact key="/" render={() => <Redirect to={"/login"} />} />
  ];
};

export const getRoutesByRole = (role, isAdamUrlPresent) => {
  if (isAdamUrlPresent) {
    const Support = lazy(() =>
      import(/* webpackChunkName: "support" */ "./support")
    );
    commonRoutes.push(
      <Route path="/support" key="support" exact component={Support} />
    );
  }
  if (isAdamUrlPresent || role === "reseller") {
    const Services = lazy(() =>
      import(/* webpackChunkName: "services" */ "./services")
    );
    const Transactions = lazy(() =>
      import(/* webpackChunkName: "transactions" */ "./transactions")
    );
    commonRoutes.push(
      <Route path="/affiliate" key="affiliate" component={Services} exact />,
      <Route
        path="/transactions"
        key="transactions"
        component={LoadingComponent(Transactions)}
      />
    );
  }
  switch (role) {
    case "reseller":
      const Customers = lazy(() =>
        import(
          /* webpackChunkName: "pro-suite-customers" */ "./settings/pro-suite/customers"
        )
      );
      const CustomerPanel = lazy(() =>
        import(/* webpackChunkName: "customer-panel" */ "./customer-panel")
      );
      const CustomerProfile = lazy(() =>
        import(
          /* webpackChunkName: "customer-profile" */ "./customer-panel/routes/customer-profile"
        )
      );
      const SupersiteSettings = lazy(() =>
        import(
          /* webpackChunkName: "ss-settings" */ "./settings/pro-suite/supersite"
        )
      );
      const Welcome = lazy(() =>
        import(/* webpackChunkName: "welcome-page" */ "./welcome-page")
      );
      const MyShop = lazy(() =>
        import(/* webpackChunkName: "my-shop" */ "./my-shop")
      );
      const TransactionsStatusAddFunds = lazy(() =>
        import(
          /* webpackChunkName: "add-funds" */ "./transaction-status/add-funds"
        )
      );
      return [
        <Route
          path="/settings/pro-suite/customers"
          key="settings"
          component={Customers}
        />,
        <Route
          path="/customers/profile/:customerId"
          key="customer-profile"
          component={CustomerProfile}
        />,
        <Route path="/customers" key="customers" component={CustomerPanel} />,
        <Route
          path="/settings/pro-suite/supersite"
          key="pro"
          component={SupersiteSettings}
          exact
        />,
        <Route path="/welcome" key="welcome" component={Welcome} />,
        <Route
          path="/orders-requests"
          key="orderrequests"
          component={LoadingComponent(OrderRequests)}
          exact
        />,
        <Route
          path="/invoices/new"
          key="create-manual-invoice"
          component={CreateManualInvoice}
          exact
        />,
        <Route path="/my-shop" key="my-shop" component={MyShop} />,
        <Route
          path="/global-settings"
          key="global-settings"
          component={Invoices}
          exact
        />,
        <Route
          path="/settings/pro-suite"
          key="pro-suite"
          component={ProSuiteWelcome}
          exact
        />,
        <Route
          path="/settings/pro-suite/api-settings"
          key="pro-suite-customers"
          component={APISettings}
          exact
        />,
        <Route
          path="/settings/pro-suite/invoices"
          key="pro"
          component={ProSuiteGetStarted}
          exact
        />,
        <Route
          path="/settings/pro-suite/payment-gateways"
          key="payment-gateways"
          component={PaymentGateways}
        />,
        <Route
          path="/transactions/status/add-funds"
          key="transactions-add-funds"
          component={TransactionsStatusAddFunds}
        />,
        ...commonRoutes,
        <Route path="*">
          <Redirect to="/dashboard" />
        </Route>
      ];
    case "customer":
      const Legal = lazy(() =>
        import(/* webpackChunkName: "legal-agreements" */ "./legal-agreements")
      );
      return [
        ...commonRoutes,
        <Route path="/legal" key="legal" exact component={Legal} />,
        <Route path="*">
          <Redirect to="/dashboard" />
        </Route>
      ];
    default:
      return [];
  }
};
