import { Loader } from "assets";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

type UpdateButtonProps = JSX.IntrinsicElements["button"] & {
  updating?: boolean;
};

const UpdateButton: React.FC<UpdateButtonProps> = ({
  updating,
  disabled,
  type,
  className,
  children,
  ...remainingProps
}) => {
  let { t } = useTranslation();
  return (
    <button
      type={type ?? "button"}
      disabled={Boolean(disabled || updating)}
      className={classNames(
        "update-btn",
        { "ub-disabled": disabled ?? false },
        className
      )}
      {...remainingProps}
    >
      <div style={{ position: "relative" }}>
        {children ?? t("SIMPLE_UPDATE", "update")}
        {updating && (
          <div className="loader-wrap">
            <img className="ub-loading" src={Loader} alt="loading..." />
          </div>
        )}
      </div>
    </button>
  );
};

export default UpdateButton;
