import classNames from "classnames";
import { BrandConfigurationOperations } from "common/brand-configuration";
import UpdateButton from "common/buttons/update";
import { formatAmountWithCurrency } from "common/localize-amount";
import WPModal from "common/modal";
import ModalHandler from "common/modal-handler";
import {
  actionTypes as payActionTypes,
  actions as payActions
} from "common/pay-buttons/ducks";
import { colors, fonts } from "common/styles";
import WPTooltip from "common/tooltip";
import { applyAll } from "common/util";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { actionTypes as upsellActionTypes } from "./ducks";

const PPDummyData = sampleEmail => [
  {
    isProtected: false,
    title: "Without Privacy Protection",
    fields: [
      {
        label: "Registrant Name",
        value: "Ravi Sharma"
      },
      {
        label: "Phone Number",
        value: "+91 22 6666 0000"
      },
      {
        label: "Email",
        value: "contactravi@gmail.com"
      },
      {
        label: "Organisation",
        value: "Ravi & Co. Textiles"
      },
      {
        label: "Address",
        value: "Goregaon, Mumbai, India"
      }
    ]
  },
  {
    isProtected: true,
    title: "With Privacy Protection",
    fields: [
      {
        label: "Registrant Name",
        value: "Domain Admin"
      },
      {
        label: "Phone Number",
        value: "+45 36946676"
      },
      {
        label: "Email",
        value: sampleEmail
      },
      {
        label: "Organisation",
        value: "Privacy Protection IN"
      },
      {
        label: "Address",
        value: "#10796, PO Box 16, AU"
      }
    ]
  }
];

let PPModalFor = styled.div`
  font: 400 12px/1.5 ${fonts.OpenSans};
  color: ${colors.gray.gray900};
`;

const Tooltip = styled.span`
  display: flex;
  flex-flow: column;
`;

const TooltipTitle = styled.span`
  border-bottom: 1px dashed ${colors.gray.gray900};
`;

let TooltipText = styled.span`
  color: #ffffff;
  font: 400 12px/1.5 ${fonts.OpenSans};
`;

let PPModalContentText = styled.p`
  margin: 20px 0px 5px;
  position: relative;
`;

const PPModalTitle = styled.div`
  font: 600 14px/1.2 ${fonts.BrandonGrotesque};
  letter-spacing: 0.46px;
  margin: 0 auto;
  text-align: center;
`;

let PPModalHeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

let PPDetailsWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 30px;
`;

let PPDetailsBox = styled.div`
  border: 1px solid ${colors.gray.gray400};
  flex-basis: 50%;
`;

const PPDetailsBoxTitle = styled.h4`
  padding: 15px 25px;
  line-height: 21px;
  font: 600 14px ${fonts.OpenSans};
  margin: 0;
`;

const PPLabel = styled.span`
  display: block;
  color: ${colors.gray.gray900};
  font: 400 13px ${fonts.OpenSans};
`;

const PPValue = styled.p`
  color: ${colors.black.regular};
  font: 600 13px ${fonts.OpenSans};
`;

const UpsellModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: Dispatch = useDispatch();
  const { brandConfiguration } = useSelector(state => ({
    brandConfiguration: BrandConfigurationOperations.findById(
      state.root.userInfo.parentid
    )
  }));

  let whoisUrl = brandConfiguration?.urls.storefront.whoisUrl ?? "";
  let sampleEmail = brandConfiguration?.urls.storefront.sampleEmail ?? "";
  if (process.env.FEATURE_ENDPOINT === "customer") {
    whoisUrl = "https://publicdomainregistry.com/whois";
    sampleEmail = "yourdomain@domain.com";
  }

  return (
    <ModalHandler
      opensOn={upsellActionTypes.OPEN_PRIVACY_PROTECTION_UPSELL_MODAL}
      closesOn={payActionTypes.CHOOSE_PAYMENT}
      render={(
        modalProps,
        {
          title,
          payButtonsInfo,
          totalCost,
          assignedDomain,
          currencySymbol,
          goBackToPrevState,
          domOnlyOrdersWithoutPP,
          totalWithPP,
          domOnlyOrdersWithoutPPCost
        }
      ) => {
        const domainNameforOrdersWithoutPP = [];
        const ppOrdersCount = domOnlyOrdersWithoutPP?.length;
        if (ppOrdersCount > 1) {
          assignedDomain = `${ppOrdersCount} Orders `;
        } else {
          assignedDomain =
            !!domOnlyOrdersWithoutPP &&
            domOnlyOrdersWithoutPP[0]?.meta?.domainname;
        }

        const selectedTenure =
          !!domOnlyOrdersWithoutPP && domOnlyOrdersWithoutPP[0]?.params.years;
        const sellingPrice = formatAmountWithCurrency({
          cost: domOnlyOrdersWithoutPPCost,
          currency: currencySymbol,
          currencyDisplay: "code"
        });
        const updatedOrdersWithPPAddon = payButtonsInfo?.actionDetails?.value?.map(
          order => {
            let isMatch = domOnlyOrdersWithoutPP.some(
              ({ key }) => key === order?.key
            );
            if (isMatch) {
              const { key, params, pathParams, ...rest } = order;
              domainNameforOrdersWithoutPP.push(
                <TooltipText key={key}>{rest?.meta?.domainname}</TooltipText>
              );
              return {
                key,
                params: { ...params, "purchase-privacy": true },
                ...rest
              };
            } else {
              return order;
            }
          }
        );

        const commonClickProps = {
          isPendingPayment: payButtonsInfo?.isPendingPayment,
          transid: payButtonsInfo?.transid,
          layoutInfo: payButtonsInfo?.layoutInfo,
          startAndEndActionTypes: payButtonsInfo?.startAndEndActionTypes,
          title: t("CONFIRM_RENEWAL", "CONFIRM RENEWAL"),
          assignedDomain,
          goBackToPrevState: () => dispatch(modalProps.triggerAction)
        };

        return (
          <WPModal
            show={modalProps.show}
            onHide={modalProps.onHide}
            alignCenter
            width={665}
            title=""
            mainContent={
              <>
                <PPModalHeaderWrapper>
                  {goBackToPrevState && (
                    <button
                      style={{ fontSize: "12px", padding: "0px" }}
                      className="wp-btn-anti"
                      onClick={applyAll(modalProps.onHide, goBackToPrevState)}
                      type="button"
                    >
                      Back
                    </button>
                  )}
                  {assignedDomain && (
                    <PPModalTitle>
                      {title}
                      <PPModalFor>
                        {t("ODR_DNS_FOR", "For")}: {assignedDomain}
                      </PPModalFor>
                    </PPModalTitle>
                  )}
                </PPModalHeaderWrapper>
                <PPModalContentText>
                  Your contact details are made public via{" "}
                  <a target="_blank" href={whoisUrl}>
                    WHOIS
                  </a>
                  , as mandated by ICANN regulations when you register a domain
                  name. These details can be at risk from phishing attacks like
                  identity theft, financial fraud and other forms of cybercrime.
                </PPModalContentText>
                <PPModalContentText>
                  Privacy Protection masks your real contact details with proxy
                  information.
                </PPModalContentText>
                <PPDetailsWrapper>
                  {PPDummyData(sampleEmail).map(
                    ({ title, fields, isProtected }, index) => (
                      <PPDetailsBox key={index}>
                        <PPDetailsBoxTitle
                          style={{
                            background: isProtected ? "#F2FFF3" : "#FFF8F8",
                            color: isProtected
                              ? colors.green.regular
                              : colors.orange.dull
                          }}
                        >
                          {title}
                        </PPDetailsBoxTitle>
                        <div style={{ padding: "15px 25px" }}>
                          {fields.map(({ label, value }) => (
                            <div key={label}>
                              <PPLabel>{label}</PPLabel>
                              <PPValue
                                style={{
                                  color: isProtected
                                    ? colors.green.regular
                                    : colors.black.regular
                                }}
                              >
                                {value}
                              </PPValue>
                            </div>
                          ))}
                        </div>
                      </PPDetailsBox>
                    )
                  )}
                </PPDetailsWrapper>
                <PPModalContentText
                  style={{ textAlign: "center", margin: "20px 0 10px" }}
                >
                  Privacy Protection for{" "}
                  {ppOrdersCount > 1 ? (
                    <WPTooltip
                      position="top"
                      label={<Tooltip>{domainNameforOrdersWithoutPP}</Tooltip>}
                    >
                      {<TooltipTitle>{assignedDomain}</TooltipTitle>}
                    </WPTooltip>
                  ) : (
                    assignedDomain
                  )}{" "}
                  {ppOrdersCount === 1 &&
                    `(${selectedTenure} ${
                      selectedTenure > 1 ? "years" : "year"
                    }) `}
                  - {sellingPrice}
                </PPModalContentText>

                <div style={{ textAlign: "center" }}>
                  <UpdateButton
                    data-testid="proceed-to-upsell-modal"
                    className={classNames(
                      "wp-btn-green-with-icon pay-card-icon",
                      css`
                        padding: 16px 30px 16px 62px !important;
                        margin: 0px auto;
                        width: unset !important;
                        display: block !important;
                        &::before {
                          left: 35px !important;
                        }
                      `
                    )}
                    onClick={() =>
                      dispatch(
                        payActions.choosePayment({
                          actionDetails: {
                            cost: totalWithPP,
                            currencySymbol,
                            ...payButtonsInfo?.actionDetails,
                            value: updatedOrdersWithPPAddon
                          },
                          ...commonClickProps
                        })
                      )
                    }
                    type="button"
                  >
                    {t("PROCEED_WITH_PP", "PROCEED WITH PRIVACY PROTECTION")}
                  </UpdateButton>
                  <button
                    style={{ fontSize: "12px", padding: "11px" }}
                    className="wp-btn-anti"
                    onClick={() =>
                      dispatch(
                        payActions.choosePayment({
                          actionDetails: {
                            cost: totalCost,
                            currencySymbol,
                            ...payButtonsInfo?.actionDetails
                          },
                          ...commonClickProps
                        })
                      )
                    }
                    type="button"
                  >
                    I'll take the risk
                  </button>
                </div>
              </>
            }
          />
        );
      }}
    />
  );
};

export default UpsellModal;
