import api from "api";
import webProStore from "ducks";
import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { SWRConfig } from "swr";
import AppContainer from "./container";
import i18n from "./i18n";

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById("root");

let render = () => {
  ReactDOM.render(
    <Provider store={webProStore}>
      <SWRConfig
        value={{
          fetcher: api.get,
          dedupingInterval: 100,
          refreshInterval: 0,
          revalidateOnReconnect: false,
          revalidateOnFocus: false,
          refreshWhenHidden: false
        }}
      >
        <I18nextProvider i18n={i18n}>
          <AppContainer />
        </I18nextProvider>
      </SWRConfig>
    </Provider>,
    MOUNT_NODE
  );
};

// This code is excluded from production bundle
if (process.env.BUILD_ENV === "development") {
  if (module.hot) {
    // Development render functions
    const renderApp = render;
    const renderError = error => {
      const RedBox = require("redbox-react").default;

      ReactDOM.render(<RedBox error={error} />, MOUNT_NODE);
    };

    // Wrap render in try/catch
    render = () => {
      try {
        renderApp();
      } catch (error) {
        console.error(error);
        renderError(error);
      }
    };

    // Setup hot module replacement
    module.hot.accept("./routes", () => {
      if (window.location.pathname.includes("tools")) {
        return;
      }
      setImmediate(() => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        render();
      });
    });
  }
}

render();
