import { isSuccess } from "common/api-status/utils";

export const actionTypes = {
  GET_TRANSFER_DETAILS: "[Transfer In] Get details",
  SAVE_TRANSFER_IN_DETAILS: "[Transfer In] Save"
};

export const initialState = {
  domainTransferIn: {
    contact: {
      admin: undefined,
      billing: undefined,
      tech: undefined,
      registrant: undefined
    },
    productKey: "",
    transferCost: 0
  }
};

export default (state = {}, action) => {
  switch (action.type) {
    case isSuccess(actionTypes.GET_TRANSFER_DETAILS): {
      return {
        ...state,
        domain: {
          ...state.domain,
          domainTransferIn: {
            ...state.domain.domainTransferIn,
            ...action.effectResponse
          }
        }
      };
    }

    default: {
      return state;
    }
  }
};

export const actions = {
  getTransferDetails: (payload, transferCode) => ({
    type: actionTypes.GET_TRANSFER_DETAILS,
    payload,
    transferCode
  })
};
