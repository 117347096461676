import { mapEffect } from "map-effect";
import { put, takeEvery } from "redux-saga/effects";
import { actionTypes } from "./ducks";
import { services } from "./services";
import { getSimpleNameFromProductKey } from "constants/index";

export default [
  takeEvery(actionTypes.GENERATE_WHMCS_KEY, mapEffect(getWHMCSKey)),
  takeEvery(actionTypes.SHOW_DELETE_KEY_MODAL, mapEffect(deleteWHMCSKey))
];

export function getWHMCSKey(action) {
  return services.getWHMCSKey(action);
}

export function deleteWHMCSKey(action) {
  return services.deleteWHMCSKey(action);
}
