import { useCallback, useEffect, useReducer, useRef } from "react";
import { actions, reducer } from "./reducer";
export function useListbox(selectedItemRef, defaultSelected = 0) {
  let [
    { selected = defaultSelected, activeChild = 0, expand },
    dispatch
  ] = useReducer(reducer, { expand: false });
  let activeChildRef = useRef(null);
  useEffect(() => {
    let option =
      +activeChild === 0 ? selectedItemRef.current : activeChildRef.current;
    if (option === null) {
      dispatch(actions.moveUp());
      return;
    }
    option.tabIndex = 0;
    option.focus();
    return () => {
      option.tabIndex = -1;
    };
  }, [activeChild, selectedItemRef]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  let onSelect = useCallback(
    event => dispatch(actions.selectItem(+event.currentTarget.id)),
    []
  );

  let onReset = useCallback(
    defaultSelectedIndex => dispatch(actions.reset(+defaultSelectedIndex)),
    []
  );

  const handleClickOutside = event => {
    if (
      selectedItemRef.current &&
      !selectedItemRef.current.contains(event.target)
    ) {
      dispatch(actions.close());
    }
  };

  let getSelectedItemProps = useCallback(
    () => ({
      ref: selectedItemRef,
      id: 0,
      onFocus: event => dispatch(actions.focus(+event.currentTarget.id)),
      onKeyDown: event => {
        switch (event.key) {
          case "ArrowDown":
            if (!expand) {
              dispatch(actions.toggle());
              dispatch(actions.focus(selected + 1));
            } else {
              dispatch(actions.moveDown());
            }
            break;
          case "Enter":
          case "Tab":
            onSelect(event);
            break;
          case "Escape":
            if (expand) {
              dispatch(actions.toggle());
            }
            break;
          default:
            break;
        }
      }
    }),
    [expand, onSelect, selected, selectedItemRef]
  );

  let getOptionProps = useCallback(
    index => ({
      role: "option",
      "aria-selected": selected === index,
      ref: +activeChild === index + 1 ? activeChildRef : null,
      id: index + 1,
      onClick: onSelect,
      onKeyDown: event => {
        switch (event.key) {
          case "ArrowDown":
            dispatch(actions.moveDown());
            break;
          case "ArrowUp":
            dispatch(actions.moveUp());
            break;
          case "Enter":
          case "Tab":
            onSelect(event);
            break;
          default:
            break;
        }
      }
    }),
    [activeChild, onSelect, selected]
  );

  let handleReset = useCallback(
    defaultSelectedIndex => onReset(defaultSelectedIndex),
    [onReset]
  );

  let getListboxProps = useCallback(
    label => ({
      role: "listbox",
      ...(label && { "aria-label": label }),
      "aria-activedescendant": activeChild,
      "aria-required": true,
      tabIndex: 0
    }),
    [activeChild]
  );

  let getControls = useCallback(
    () => ({ onClick: () => dispatch(actions.toggle()) }),
    []
  );

  return {
    selected,
    expand,
    getControls,
    getListboxProps,
    getOptionProps,
    getSelectedItemProps,
    handleReset
  };
}
