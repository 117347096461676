export const actionTypes = {
  OPEN_BILLING_PREFERENCES_MODAL:
    "[Billing Preferences] Open Billing Preferences Modal"
};

export const actions = {
  openBillingPreferencesModal: payload => ({
    type: actionTypes.OPEN_BILLING_PREFERENCES_MODAL,
    ...payload
  })
};
