import { isSuccess } from "common/api-status/utils";

export const actionTypes = {
  SHOW_BUY_MODAL: "[Manage Volumes] Show buy modal",
  SHOW_DELETE_MODAL: "[Manage Volumes] Show delete modal",
  SHOW_MANAGE_MODAL: "[Manage Volumes] Show manage modal",
  SHOW_UPGRADE_MODAL: "[Manage Volumes] Show upgrade modal",
  BUY_VOLUME: "[Manage Volumes] Buy",
  RENEW_VOLUME: "[Manage Volumes] Renew",
  UPGRADE_VOLUME: "[Manage Volumes] Upgrade",
  DELETE_VOLUME: "[Manage Volumes] Delete",
  GET_VPS_VOLUME_PRICING: "[Manage Volumes] Get VPS Volume Pricing"
};

export type Volume = {
  currentstatus: string;
  domainName: string;
  endtime: number;
  mountPoint: string;
  orderid: number;
  productkey: string;
  storageSizeGb: number;
  storageStatus: string;
};

export type ManageVolumes = {
  volumes: Volume[];
  pricing: any;
  attachedTo?: { currentstatus: string; domainName: string; orderid: number };
  volumeDetails?: Partial<Volume>;
};

export const initialState: { manageVolumes: ManageVolumes } = {
  manageVolumes: { volumes: [], pricing: {} }
};

export const actions = {
  deleteVolume: payload => ({ type: actionTypes.DELETE_VOLUME, payload }),
  getPricingForVPS: ({ meta, hierarchy }) => ({
    type: actionTypes.GET_VPS_VOLUME_PRICING,
    payload: { ...hierarchy, parent: meta.orderid, ...meta }
  }),
  upgradeVolume: payload => ({ type: actionTypes.UPGRADE_VOLUME, payload }),
  showUpgradeModal: ({ meta }) => ({
    type: actionTypes.SHOW_UPGRADE_MODAL,
    ...meta
  })
};

type VPSOrderDetails = { attachedVolumes?: { [x: string]: VolumeResponse } };

type VolumeResponse = {
  currentstatus: string;
  endtime: number;
  orderid: number;
  productkey: string;
  mountPoint: string;
  storageSizeGb: number;
  storageStatus: string;
};

type AttachedVPS = {
  currentstatus: string;
  mountPoint?: string;
  orderid: number;
};
export type BlockStorageOrderDetails = {
  attachedOrders: { [x: string]: AttachedVPS };
  storageSizeGb: number;
  storageStatus: string;
  domainname: string;
  endtime: string;
  orderid: string;
  productkey: string;
  currentstatus: string;
};

export type VolumeDetails = {
  mountPoint: string;
  storageSizeGb: number;
  storageStatus: string;
  orderid: number;
  endtime: number;
  domainname: string;
};

type VolumeResponseParser<T> = {
  virtualserver: (details: VPSOrderDetails, meta: any) => Partial<T>;
  blockstorage: (details: BlockStorageOrderDetails, meta: any) => Partial<T>;
};

function parseNull(value?: string) {
  return value === "null" ? null : value;
}

export const parsers: VolumeResponseParser<ManageVolumes> = {
  virtualserver: (details, meta) => {
    let volumes: Volume[] = Object.entries<VolumeResponse>(
      details?.attachedVolumes ?? {}
    ).map(([domainName, volumeInfo]) => ({
      domainName,
      ...volumeInfo,
      mountPoint: parseNull(volumeInfo.mountPoint)
    }));
    return { volumes };
  },
  blockstorage: (details, meta) => {
    let [attachedTo] = Object.entries<AttachedVPS>(
      details.attachedOrders
    ).map(([domainName, order]) => ({ domainName, ...order }));
    return {
      attachedTo,
      volumeDetails: {
        mountPoint: parseNull(attachedTo?.mountPoint),
        storageSizeGb: details.storageSizeGb,
        storageStatus: details.storageStatus,
        orderid: Number(details.orderid),
        endtime: Number(details.endtime),
        domainname: details.domainname
      }
    };
  }
};

export default (state, action) => {
  switch (action.type) {
    case isSuccess(actionTypes.GET_VPS_VOLUME_PRICING): {
      let { child, parent } = action.payload;
      return {
        ...state,
        [parent]: {
          ...state[parent],
          [child]: { ...state[parent][child], ...action.effectResponse }
        }
      };
    }
    default:
      return state;
  }
};
