import { helpers } from "common/orders/helpers";
import {
  getOrderDetails,
  getPricingFromProductKey,
  onGetPlanDetails,
  updatePricingAndAddonDetails
} from "common/orders/sagas";
import { getLocationFromProductName } from "constants/index";
import ProductKeyMapping from "constants/ProductKeyMapping";
import { mapEffect } from "map-effect";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { actions } from "widgets/common/ducks";
import { actionTypes, Volume } from "./ducks";
import { services } from "./services";

export default [
  takeEvery(actionTypes.DELETE_VOLUME, mapEffect(deleteVolume)),
  takeLatest(actionTypes.RENEW_VOLUME, mapEffect(renewVolume)),
  takeEvery(actionTypes.GET_VPS_VOLUME_PRICING, mapEffect(getVPSPricing))
];

function* getVPSPricing(action) {
  yield put(
    actions.setupState({ meta: action.payload, hierarchy: action.payload })
  );
  let { location, details } = action.payload;
  let blockStorageProduct = `blockstorage${location}`;
  let blockStoragePlans = yield call(onGetPlanDetails, {
    meta: {
      ...ProductKeyMapping[blockStorageProduct].meta,
      productKey: blockStorageProduct
    }
  });
  let { planId: planToAdd } = Object.entries<{ isSSDPlan: boolean }>(
    blockStoragePlans
  )
    .map(([planId, details]) => ({ ...details, planId }))
    .find(_ => _.isSSDPlan === details.isSSDPlan);
  let response = yield call(
    getPricingFromProductKey,
    blockStorageProduct,
    true
  );

  let { [blockStorageProduct]: blockStoragePricing } =
    response.reseller_pricing ?? response;

  return {
    planToAdd,
    pricing: blockStoragePricing.plans[planToAdd],
    planDetails: blockStoragePlans[planToAdd]
  };
}

function deleteVolume(action) {
  return services.deleteVolume(action.payload);
}

type RenewalDetailsSlice = { orders: { renewalDetails: { [x: string]: any } } };

function* renewVolume(action) {
  let { orderid, productkey } = action.volume as Volume;
  let orderDetails = yield call(
    getOrderDetails,
    {
      orderid,
      productDetailsKey: productkey
    },
    { getRawData: true, forceReload: false }
  );
  yield call(
    updatePricingAndAddonDetails,
    helpers.formatOboxOrderDetails(orderDetails, {
      // These value are not availabe in orderDetails,
      product_category: `Block Storage ${getLocationFromProductName(
        productkey
      ).toUpperCase()}`,
      deletesIn: 0,
      deletesOn: 0
    }),
    orderDetails,
    null
  );
  let renewalDetails = yield select<RenewalDetailsSlice>(
    state => state.orders.renewalDetails[orderid] ?? null
  );
  if (renewalDetails === null) {
    throw new Error(
      `Renewal flow for volume ${action.volume?.domainName} failed`
    );
  }
  return renewalDetails;
}
