import { Syringe } from "@faizaanceg/syringe";
import api from "api";
import { User } from "common/sdk/user";
import { addQueryParamsToUrl } from "common/urls";
import constants from "constants/endpoints";
import { partition } from "lodash";

type BrandedUrls = { type: "D" | "P"; url: string }[];

export const getSuperSiteUrl = () =>
  api.get<BrandedUrls>(`${constants.get_branded_url}supersite2`).then(
    domainNames => {
      let fullyBrandedUrls = partition(
        domainNames?.filter(domain => domain.type !== "D"),
        _ => _.type === "P"
      ).flat();
      const defaultUrl = domainNames.find(domain => domain.type === "D")?.url;
      return fullyBrandedUrls.length > 0 ? fullyBrandedUrls[0].url : defaultUrl;
    },
    () => ""
  );

export let changePlanRedirection = ({ domainname, productkey, role }) => {
  let externalAccessManager = Syringe.inject("externalAccessManager");
  const autoLoginWindow = externalAccessManager.createWindow("_blank");
  try {
    let { path, ...queryParams } =
      role === "reseller"
        ? { path: "reseller.php", action: "validate_res_upgrade" }
        : { path: "upgrade.php", action: "validate_upgrade", r: "customer" };
    getSuperSiteUrl().then(currentURL =>
      User.token().then(({ TOKEN }) => {
        autoLoginWindow.location.replace(
          addQueryParamsToUrl(`https://${currentURL}/${path}`, {
            tid: TOKEN,
            dn: domainname,
            p: productkey,
            upgrade: true,
            ...queryParams
          })
        );
      })
    );
  } catch (error) {
    autoLoginWindow.close();
  }
};
