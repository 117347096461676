export let actionTypes = {
  CANCEL_IRTP_CONTACT: "[Notifications] Cancel IRTP",
  CANCEL_PRIVACY_ACTION: "[Notifications] Cancel Privacy Action",
  CANCEL_DISABLE_PRIVACY: "[Notifications] Cancel disable free privacy request",
  GET_PRIVACY_PROTECTION_STATUS:
    "[Notifications] Get Privacy Protection Status",
  GET_IRTP_STATUS: "[Notifications] Get IRTP Status",
  RESEND_IRTP_AUTHORIZATION: "[Notifications] Resend IRTP",
  RESEND_DOMAIN_CONTACT_VERIFICATION: "[Notifications] Resend verification",
  RESEND_DISABLE_PRIVACY: "[Notifications] Resend request",
  SHOW_RESEND_DISABLE_REQUEST: "[Notifications] Show Resend request modal",
  SET_UPDATED_AUTH_CODE: "[Notifications] Update Auth Code"
};

export const actions = {
  getIRTPStatus: meta => ({
    type: actionTypes.GET_IRTP_STATUS,
    meta
  }),
  getPrivacyProtectionStatus: ({ orderid }, details) => ({
    type: actionTypes.GET_PRIVACY_PROTECTION_STATUS,
    orderid,
    details
  }),
  resendDomainContactVerification: orderid => ({
    type: actionTypes.RESEND_DOMAIN_CONTACT_VERIFICATION,
    orderid
  }),
  resendIRTPAuthorization: orderid => ({
    type: actionTypes.RESEND_IRTP_AUTHORIZATION,
    orderid
  }),
  resendDisableRequest: meta => ({
    type: actionTypes.RESEND_DISABLE_PRIVACY,
    meta
  }),
  showResendDisableRequest: meta => ({
    type: actionTypes.SHOW_RESEND_DISABLE_REQUEST,
    ...meta
  }),
  cancelDisableRequest: meta => ({
    type: actionTypes.CANCEL_DISABLE_PRIVACY,
    meta
  }),
  cancelIRTPChanges: orderid => ({
    type: actionTypes.CANCEL_IRTP_CONTACT,
    orderid
  }),
  cancelPrivacyAction: (orderid, eaqid) => ({
    type: actionTypes.CANCEL_PRIVACY_ACTION,
    orderid,
    eaqid
  })
};
