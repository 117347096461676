import { takeEvery, put } from "redux-saga/effects";
import services from "./services";
import { actionTypes, actions } from "./ducks";

export const legalSagas = [
  takeEvery(actionTypes.GET_AGREEMENT_TEXT, onGetAgreementText),
  takeEvery(actionTypes.GET_PRIVACY_POICY_DETAILS, onGetPrivacyPolicyDetails),
  takeEvery(actionTypes.UPDATE_PRIVACY_POLICY, onUpdatePrivacyPolicy)
];

function* onGetAgreementText(action) {
  try {
    const { title, agreementId } = action.agreement;
    const {
      [agreementId]: { agreementflag, defaultagreement: text }
    } = yield services.getAgreementText(agreementId);
    yield put(
      actions.getAgreementTextSuccess({
        agreementflag,
        text,
        title,
        show: action.agreementType
      })
    );
  } catch (error) {
    yield put(actions.getAgreementTextFailure(error));
  }
}

function* onGetPrivacyPolicyDetails(action) {
  try {
    const { agreementId } = action;
    const {
      [agreementId]: { agreementflag: isDefault, agreement: text }
    } = yield services.getAgreementText(agreementId);

    yield put(
      actions.getPrivacyPolicyDetailsSuccess({
        privacyPolicy: {
          isDefault,
          text
        }
      })
    );
  } catch (error) {
    yield put(actions.getPrivacyPolicyDetailsFailure(error));
  }
}

function* onUpdatePrivacyPolicy(action) {
  try {
    const updatePrivacyPolicyResponse = yield services.updatePrivacyPolicy(
      action.value
    );
    if (updatePrivacyPolicyResponse) {
      yield put(
        actions.updatePrivacyPolicySuccess({
          text: action.value
        })
      );
    } else {
      throw new Error("could not update privacy policy");
    }
  } catch (error) {
    yield put(actions.updatePrivacyPolicyFailure(error));
  }
}
