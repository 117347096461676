import { mapEffect } from "map-effect";
import { call, takeEvery } from "redux-saga/effects";
import { actionTypes } from "./ducks";
import { services } from "./services";

function getIRTPStatus(action) {
  return services.getIRTPStatus(action.meta);
}

function cancelDisableRequest(action) {
  return services.cancelDisableRequest(action.meta);
}

function cancelIRTPContact(action) {
  return services.cancelIrtpContactChanges(action.orderid);
}

function* cancelPrivacy(action) {
  let { isCancelled } = yield call(
    services.cancelPrivacyAction,
    action.orderid,
    action.eaqid
  );
  if (!isCancelled) {
    throw new Error("Something went wrong while canceling");
  }
}

export function* isPrivacyProtectionCancelable(action) {
  let { details, orderid } = action;
  let { actiontype, actionstatus, eaqid } = details;
  if (
    actiontype === "PurchasePrivactProtection" &&
    actionstatus === "InvoicePaid"
  ) {
    let isCancellable = yield call(
      services.isActionCancellable,
      orderid,
      eaqid
    );
    return {
      isPending: true,
      isCancellable
    };
  } else {
    return {
      isPending: false
    };
  }
}

function resendIRTPAuthorization(action) {
  return services.resendIRTPAuthorization(action.orderid);
}

function resendDomainContactVerification(action) {
  return services.resendDomainContactVerificationEmail(action.orderid);
}

function resendDisableRequest(action) {
  return services.resendDisableRequest(action.meta);
}

export default [
  takeEvery(actionTypes.GET_IRTP_STATUS, mapEffect(getIRTPStatus)),
  takeEvery(
    actionTypes.GET_PRIVACY_PROTECTION_STATUS,
    mapEffect(isPrivacyProtectionCancelable)
  ),
  takeEvery(
    actionTypes.CANCEL_DISABLE_PRIVACY,
    mapEffect(cancelDisableRequest)
  ),
  takeEvery(actionTypes.CANCEL_PRIVACY_ACTION, mapEffect(cancelPrivacy)),
  takeEvery(actionTypes.CANCEL_IRTP_CONTACT, mapEffect(cancelIRTPContact)),
  takeEvery(
    actionTypes.RESEND_DISABLE_PRIVACY,
    mapEffect(resendDisableRequest)
  ),
  takeEvery(
    actionTypes.RESEND_DOMAIN_CONTACT_VERIFICATION,
    mapEffect(resendDomainContactVerification)
  ),
  takeEvery(
    actionTypes.RESEND_IRTP_AUTHORIZATION,
    mapEffect(resendIRTPAuthorization)
  )
];
