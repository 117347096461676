import {
  actions as AnalyticsActions,
  categories as AnalyticsCategories,
  labels as AnalyticsLabels
} from "analytics/constants";
import { isFailure, isSuccess } from "common/api-status/utils";
import { getSimpleNameFromProductKey } from "constants/index";

export const actionTypes = {
  SHOW_ADD_MODAL: "[Manage Email] Show add modal",
  SHOW_DELETE_MODAL: "[Manage Email] Show delete modal",
  SHOW_MANAGE_MODAL: "[Manage Email] Show manage modal",
  SHOW_VIEW_MODAL: "[Manage Email] Show view modal",

  PURCHASE_EMAIL_ACCOUNTS: "[Manage Email] Purchase",
  DELETE_EMAIL_ACCOUNTS: "[Manage Email] Delete"
};

export const initialState = {
  activeEmailAccount: {
    accounts: 0,
    costPerAccount: 0,
    emailList: [],
    emailPricing: {},
    discount: null,
    maximumSeats: null,
    noOfAccounts: 0,
    usedAccounts: 0,
    currentOrderTenure: 0
  }
};

export const actions = {
  deleteEmailAccounts: (
    orderid,
    endpoints,
    { parent, child, productkey },
    { accounts }
  ) => ({
    type: actionTypes.DELETE_EMAIL_ACCOUNTS,
    track: helpers.getTrackInfo(productkey),
    payload: {
      accounts,
      child,
      endpoint: endpoints.deleteEmailAccounts,
      orderid,
      parent,
      productkey
    }
  }),
  deleteEmailAccountsSuccess: payload => ({
    type: isSuccess(actionTypes.DELETE_EMAIL_ACCOUNTS),
    ...payload
  }),
  deleteEmailAccountsFailure: (error, payload) => ({
    type: isFailure(actionTypes.DELETE_EMAIL_ACCOUNTS),
    error,
    payload
  })
};

export default (state, action) => {
  switch (action.type) {
    case actionTypes.SHOW_DELETE_MODAL: {
      if (!action.value) {
        return state;
      }
      const { addonToBeDeleted, parent, child } = action.value;
      return {
        ...state,
        [parent]: {
          ...state[parent],
          [child]: {
            ...state[parent][child],
            addonToBeDeleted
          }
        }
      };
    }
    default:
      return state;
  }
};

export const parsers = {
  detailsParser: (
    { emailaccounts, noofaccounts: accounts = emailaccounts },
    meta
  ) => ({ accounts, noOfAccounts: accounts }),
  gsuiteDetailsParser: (
    {
      noofaccounts: accounts,
      used_account_count: usedAccounts = 0,
      autoRenewTermType,
      currentOrderTenure
    },
    meta
  ) => ({
    accounts,
    noOfAccounts: accounts,
    usedAccounts,
    isRenewTypeLongTerm: autoRenewTermType === "LONG_TERM",
    currentOrderTenure
  }),
  usedAccountsParser: payload => ({
    usedAccounts: payload.response.domainAccountsInfo.noOfMailAccounts
  }),
  addressParser: payload => ({
    emailList: payload.response.users.map(value => ({
      name: value.emailAddress,
      showDelete: false
    }))
  }),
  pricingParser: (payload = {}, { productkey }) => {
    const { [productkey]: { email_account_ranges: emailPricing } = {} } =
      payload.reseller_pricing ?? payload.customer_pricing ?? payload;
    return { emailPricing };
  },
  emailStoragePricing: (payload = {}, { productkey }) => {
    const { [productkey]: { addons } = {} } =
      payload.reseller_pricing ?? payload.customer_pricing ?? payload;
    return { pricing: addons };
  },
  gsuitePricingParser: (payload = {}, { productkey, details }) => {
    const { [productkey]: { plans } = {} } =
      payload.reseller_pricing ?? payload.customer_pricing ?? payload;
    return { emailPricing: plans[details.planid] };
  },
  gsuitePlanParser: (payload, { productkey, details }) => {
    let discount = payload[productkey][details.planid]?.discount;
    let maximumSeats = payload[productkey][details.planid]?.maximumSeats;

    // This if block has been added on temp basis. Check with PMs after August 2021 if we can remove it
    if (maximumSeats === undefined) {
      if (details.plan_name.toLowerCase().includes("enterprise")) {
        maximumSeats = 2000;
      } else {
        maximumSeats = 300;
      }
    }

    const planStatus = payload[productkey][details.planid]?.plan_status;
    let isDiscountAvailable =
      details.noofaccounts < (discount?.number_of_accounts ?? 0);
    let { isAlreadyRenewedForMoreThan1Year, isTransferIn } = details;
    let isDiscountApplicable =
      isDiscountAvailable && !isAlreadyRenewedForMoreThan1Year && !isTransferIn;
    return {
      discount: isDiscountApplicable ? discount : null,
      maximumSeats,
      planStatus
    };
  }
};

const helpers = {
  getTrackInfo: productKey => {
    let eventMeta = {};
    switch (getSimpleNameFromProductKey(productKey)) {
      case "businessemail": {
        eventMeta = {
          eventCategory: AnalyticsCategories.be_view,
          eventAction: AnalyticsActions.delete_accounts_be
        };
        break;
      }
      case "enterpriseemail": {
        eventMeta = {
          eventCategory: AnalyticsCategories.ee_view,
          eventAction: AnalyticsActions.delete_accounts_ee
        };
        break;
      }
      case "gsuite": {
        eventMeta = {
          eventCategory: AnalyticsCategories.gsuite_view,
          eventAction: AnalyticsActions.delete_accounts_gsuite
        };
        break;
      }
      case "titanmail": {
        eventMeta = {
          eventCategory: AnalyticsCategories.te_view,
          eventAction: AnalyticsActions.delete_accounts_te
        };
        break;
      }
      default:
        break;
    }
    eventMeta.eventLabel = AnalyticsLabels.delete_email_accounts;
    return eventMeta;
  }
};
