import React from "react";
import { styled } from "linaria/react";
import { fonts, colors } from "common/styles";

const Badge = styled.div`
  background-color: ${colors.blue.regular};
  color: ${colors.white.regular};
  border-radius: 20px;
  height: 16px;
  padding: 2px 6px;
  display: inline-block;
  font: 600 9px / 13px ${fonts.OpenSans};
`;

type WPBadgeProps = {
  text: string;
  styles?: Object;
};

const WPBadge: React.FunctionComponent<WPBadgeProps> = ({
  text,
  styles = {}
}) => <Badge style={styles}>{text}</Badge>;

export default WPBadge;
