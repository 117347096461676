import { Portal } from "@reach/portal";
import { useTooltip } from "@reach/tooltip";
import { css } from "linaria";
import { styled } from "linaria/react";
import React, { cloneElement, useCallback, useState } from "react";
import { fonts } from "./styles";
import { TooltipPopupWithWC } from "./tooltip-use-wc";

let WebProTooltip = styled.div`
  position: relative;
  display: inline;
  z-index: 999;
`;

let TooltipTarget = styled.span`
  background: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  border: none;
`;

let ReachTooltip = css`
  position: absolute;
  color: #ffffff;
  border: none;
  font-family: ${fonts.OpenSans};
  font-size: 11px;
  background-color: #3b3e47;
  border-radius: 2px;
  padding: 6px 15px 8px;
  text-align: left;
  line-height: 17px;
  white-space: pre-line;
  z-index: 2010;
`;

let TooltipArrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  z-index: 2010;
  &[data-attr-position="top"] {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #3b3e47;
  }
  &[data-attr-position="bottom"],
  &[data-attr-position="bottomLeft"] {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #3b3e47;
  }
  &[data-attr-position="right"] {
    border-right: 7px solid #3b3e47;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
  }
`;

const arrow = (triggerRect, position) => {
  switch (position) {
    case "top":
      return triggerRect
        ? {
            left: triggerRect.left - 7 + triggerRect.width / 2,
            top: triggerRect.top - 8 + window.scrollY
          }
        : {};
    case "bottom":
      return triggerRect
        ? {
            left: triggerRect.left - 7 + triggerRect.width / 2,
            top: triggerRect.bottom + 2 + window.scrollY
          }
        : {};
    case "bottomLeft":
      return triggerRect
        ? {
            left: triggerRect.left + 10,
            top: triggerRect.bottom + 2 + window.scrollY
          }
        : {};
    case "right":
      return triggerRect
        ? {
            left: triggerRect.left + triggerRect.width,
            top: triggerRect.top - 7 + triggerRect.height / 2 + window.scrollY
          }
        : {};
    default:
      return triggerRect
        ? {
            left: triggerRect.left - 7 + triggerRect.width / 2,
            top: triggerRect.top - 10 + window.scrollY
          }
        : {};
  }
};

function TriangleTooltip({
  children,
  label,
  ariaLabel,
  position,
  popupStyles,
  triangleStyles
}) {
  const [trigger, tooltip] = useTooltip();
  const { isVisible, triggerRect } = tooltip;
  const { left, top } = arrow(triggerRect, position);

  const [height, setHeight] = useState(0);

  const measureRefHeight = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const tooltipTop = (triggerRect, tooltipRect) => {
    const triggerCenter = triggerRect.left + triggerRect.width / 2;
    const left = triggerCenter - tooltipRect.width / 2;
    const maxLeft = window.innerWidth - tooltipRect.width - 2;
    return {
      left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
      top: triggerRect.top - (height + 7) + window.scrollY
    };
  };

  const tooltipBottom = (triggerRect, tooltipRect) => {
    const triggerCenter = triggerRect.left + triggerRect.width / 2;
    const left = triggerCenter - tooltipRect.width / 2;
    const maxLeft = window.innerWidth - tooltipRect.width - 2;
    return {
      left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
      top: triggerRect.bottom + 7 + window.scrollY
    };
  };

  const tooltipRight = (triggerRect, tooltipRect) => {
    const triggerCenterV = triggerRect.top + triggerRect.height / 2;
    const top = triggerCenterV - tooltipRect.height / 2;
    const maxTop = window.innerHeight - tooltipRect.width - 2;
    return {
      left: triggerRect.left + triggerRect.width + 5,
      top: Math.min(Math.max(2, top), maxTop) + window.scrollY
    };
  };

  const tooltipBottomLeft = (triggerRect, tooltipRect) => {
    return {
      left: triggerRect.left,
      top: triggerRect.bottom + 7 + window.scrollY
    };
  };

  const tooltipPositions = {
    top: tooltipTop,
    bottom: tooltipBottom,
    bottomLeft: tooltipBottomLeft,
    right: tooltipRight
  };
  return (
    <>
      {cloneElement(children, trigger)}
      {isVisible && (
        <Portal>
          <TooltipArrow
            data-attr-position={position}
            height={height}
            style={{ left, top, ...triangleStyles }}
          />
        </Portal>
      )}
      <TooltipPopupWithWC
        {...tooltip}
        label={label}
        className={ReachTooltip}
        ref={measureRefHeight}
        aria-label={ariaLabel}
        position={tooltipPositions[position]}
        style={popupStyles}
      />
    </>
  );
}

type WPToolTipType = {
  position?: "top" | "bottom" | "bottomLeft" | "right";
  label: React.ReactElement | string;
  show?: boolean;
  tooltipStyle?: React.CSSProperties;
  popupStyles?: React.CSSProperties;
  triangleStyles?: React.CSSProperties;
};

const WPTooltip: React.FC<WPToolTipType> = ({
  position,
  label,
  children,
  show = true,
  tooltipStyle = {},
  popupStyles = {},
  triangleStyles = {}
}) => {
  return show ? (
    <WebProTooltip>
      <TriangleTooltip
        position={position}
        label={label}
        ariaLabel={null}
        popupStyles={popupStyles}
        triangleStyles={triangleStyles}
      >
        <TooltipTarget style={tooltipStyle}>{children}</TooltipTarget>
      </TriangleTooltip>
    </WebProTooltip>
  ) : (
    <span>{children}</span>
  );
};

export default WPTooltip;
