import React, { useEffect, useReducer } from "react";
import { useTaxPayerIdByCountryCode, useSaveTaxPayerId } from "./hooks";
import { styled } from "linaria/react";
import { useTranslation } from "react-i18next";
import { withInlineNotifications } from "common/inline-notifications/ducks";
import { colors, fonts } from "common/styles";
import UpdateButton from "common/buttons/update";
import { css } from "linaria";

let TaxPayerInputWrapper = styled.div`
  border: 1px ${colors.blue.regular} solid;
  padding: 4.5px 4px 4.5px 11px;
  width: 100%;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  &[data-disabled="true"] {
    border-color: ${colors.gray.gray400};
  }
  &[data-error="true"] {
    border-color: ${colors.red.regular};
  }
`;

let TaxPayerInput = styled.input`
  outline: none;
  border: none;
  font: 400 14px/1.5 ${fonts.OpenSans};
  color: ${colors.black.regular};
  width: 100%;
`;

let TaxPayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 13px;
  padding-left: 23px;
  padding-right: 23px;
  text-align: left;
`;

let submitButtonStyles = css`
  color: ${colors.white.regular};
  text-align: center;
  background: ${colors.blue.regular};
  vertical-align: middle;
  font: 600 12px/1.3 ${fonts.OpenSans};
  text-transform: uppercase;
  padding: 9px 21px 8px;
  cursor: pointer;
  display: inline-block;
  border-radius: 2px;
  outline: none;
  border: none;
  transition: 0.2s all;
  &:hover {
    background: #4b97ff;
  }
`;

let TaxPayerTitle = styled.h4`
  font: 600 15px/1.5 ${fonts.OpenSans};
  color: ${colors.black.regular};
`;

let ErrorDiv = styled.div`
  font: 400 12px/1.5 ${fonts.OpenSans};
  color: ${colors.red.regular};
  margin-top: 4px;
`;

type CanSaveFunctionProps = {
  setCanSaveValue: (x: boolean) => void;
};

type ShowNotificationFunctionProps = {
  showNotification: (s: string) => void;
};

type TaxPayerProps = {
  countryCode: string;
  showLabel: boolean;
  customStyle: string;
} & CanSaveFunctionProps &
  ShowNotificationFunctionProps;

export function TaxPayerID(props: TaxPayerProps) {
  const { t } = useTranslation();

  const initialState = {
    inputValue: "",
    inputPrevValue: "",
    errorMessage: ""
  };

  const manageTaxPayerIdReducer = (state, action) => {
    switch (action.type) {
      case "ENTER_TAXPAYERID": {
        return {
          ...state,
          inputValue: action.payload
        };
      }
      case "LOAD_TAXPAYERID": {
        return {
          ...state,
          inputValue: action.payload,
          inputPrevValue: action.payload
        };
      }
      case "TAXPAYERID_EMPTY": {
        return {
          ...state,
          errorMessage: t(
            "ERROR_TAXPAYER_NOT_VALID",
            "Please enter a valid CPF/CNPJ ID"
          )
        };
      }
      case "SAVE_TAXPAYERID_SUCCESS": {
        return {
          ...state,
          errorMessage: ""
        };
      }
      case "SAVE_TAXPAYERID_FAILED": {
        return {
          ...state,
          errorMessage: t("ERROR_SAVING_TAXPAYERID", "Error saving CPF/CNPJ ID")
        };
      }
      case "FOCUS_TAXPAYERID": {
        return {
          ...state,
          inputPrevValue: action.payload,
          errorMessage: ""
        };
      }
    }
  };

  let [{ inputValue, inputPrevValue, errorMessage }, dispatch] = useReducer(
    manageTaxPayerIdReducer,
    initialState
  );

  let [
    apiGetStatus,
    taxPayerIdResponse,
    getApiActions
  ] = useTaxPayerIdByCountryCode(props.countryCode);
  const [apiSaveStatus, saveTaxPayerId] = useSaveTaxPayerId();

  useEffect(() => {
    if (taxPayerIdResponse && taxPayerIdResponse?.taxId?.length > 0) {
      props.setCanSaveValue(true);
      dispatch({
        type: "LOAD_TAXPAYERID",
        payload: taxPayerIdResponse.taxId
      });
    }
  }, [taxPayerIdResponse]);

  const SaveTaxPayerId = async e => {
    e.preventDefault();
    e.stopPropagation();
    if (
      inputValue &&
      inputValue !== "" &&
      (inputValue.length === 11 || inputValue.length === 14)
    ) {
      try {
        let response = await saveTaxPayerId(props.countryCode, inputValue);
        if (response !== null && response instanceof Error) {
          dispatch({
            type: "SAVE_TAXPAYERID_FAILED"
          });
        } else {
          await getApiActions.refetch();
          if (errorMessage === "") {
            props.showNotification("Taxpayer ID successfully submitted");
          }
        }
      } catch (error) {
        dispatch({
          type: "SAVE_TAXPAYERID_FAILED"
        });
        props.setCanSaveValue(false);
      }
    } else {
      dispatch({
        type: "TAXPAYERID_EMPTY"
      });
    }
  };

  function handleInputChange(event) {
    event.preventDefault();
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      dispatch({
        type: "ENTER_TAXPAYERID",
        payload: event.target.value
      });
    }
  }

  function handleInputFocus(event) {
    props.setCanSaveValue(false);
    dispatch({
      type: "FOCUS_TAXPAYERID",
      payload: inputValue
    });
  }

  function handleInputBlur(event) {
    if (inputValue === inputPrevValue) {
      props.setCanSaveValue(true);
    }
  }

  return (
    <TaxPayerContainer style={props.customStyle}>
      {props.showLabel && (
        <TaxPayerTitle> {t("TAX_PAYER_ID", "Tax Payer ID")} </TaxPayerTitle>
      )}
      <TaxPayerInputWrapper
        data-disabled={Boolean(apiGetStatus.isFetching)}
        data-error={!!errorMessage}
      >
        <TaxPayerInput
          placeholder={t("TAX_PAYER_ID_PLACEHOLDER", "Enter CPF/CNPJ ID")}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          value={inputValue}
        />
        <UpdateButton
          onClick={SaveTaxPayerId}
          disabled={false}
          updating={apiGetStatus.isFetching || apiSaveStatus.isFetching}
          type="button"
          className={submitButtonStyles}
        >
          {t("SUBMIT", "Submit")}
        </UpdateButton>
      </TaxPayerInputWrapper>
      {errorMessage && <ErrorDiv>{errorMessage}</ErrorDiv>}
    </TaxPayerContainer>
  );
}

export default withInlineNotifications(TaxPayerID);
