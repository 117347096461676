export function Services(props) {
  return (
    <svg
      {...props}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="partner-benefit" clipPath="url(#clip0_1478_52)">
        <path
          d="M4.80005 4.80002H4.81055M4.80005 13.2H4.81055M13.2 4.80002H13.2105M13.2 13.2H13.2105"
          stroke="#BBBBCA"
          strokeWidth="1.575"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.3125 2.69998C10.3125 3.04808 10.1742 3.38192 9.92803 3.62806C9.68189 3.8742 9.34805 4.01248 8.99995 4.01248C8.65185 4.01248 8.31801 3.8742 8.07187 3.62806C7.82573 3.38192 7.68745 3.04808 7.68745 2.69998C7.68745 2.35188 7.82573 2.01805 8.07187 1.7719C8.31801 1.52576 8.65185 1.38748 8.99995 1.38748C9.34805 1.38748 9.68189 1.52576 9.92803 1.7719C10.1742 2.01805 10.3125 2.35188 10.3125 2.69998ZM16.6125 8.99998C16.6125 9.34808 16.4742 9.68192 16.228 9.92806C15.9819 10.1742 15.648 10.3125 15.3 10.3125C14.9519 10.3125 14.618 10.1742 14.3719 9.92806C14.1257 9.68192 13.9875 9.34808 13.9875 8.99998C13.9875 8.65188 14.1257 8.31805 14.3719 8.0719C14.618 7.82576 14.9519 7.68748 15.3 7.68748C15.648 7.68748 15.9819 7.82576 16.228 8.0719C16.4742 8.31805 16.6125 8.65188 16.6125 8.99998ZM4.01245 8.99998C4.01245 9.34808 3.87417 9.68192 3.62803 9.92806C3.38189 10.1742 3.04805 10.3125 2.69995 10.3125C2.35185 10.3125 2.01802 10.1742 1.77187 9.92806C1.52573 9.68192 1.38745 9.34808 1.38745 8.99998C1.38745 8.65188 1.52573 8.31805 1.77187 8.0719C2.01802 7.82576 2.35185 7.68748 2.69995 7.68748C3.04805 7.68748 3.38189 7.82576 3.62803 8.0719C3.87417 8.31805 4.01245 8.65188 4.01245 8.99998ZM10.3125 15.3C10.3125 15.6481 10.1742 15.9819 9.92803 16.2281C9.68189 16.4742 9.34805 16.6125 8.99995 16.6125C8.65185 16.6125 8.31801 16.4742 8.07187 16.2281C7.82573 15.9819 7.68745 15.6481 7.68745 15.3C7.68745 14.9519 7.82573 14.618 8.07187 14.3719C8.31801 14.1258 8.65185 13.9875 8.99995 13.9875C9.34805 13.9875 9.68189 14.1258 9.92803 14.3719C10.1742 14.618 10.3125 14.9519 10.3125 15.3Z"
          fill="#BBBBCA"
          stroke="#BBBBCA"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.3125 8.99998C10.3125 9.34808 10.1742 9.68192 9.92808 9.92806C9.68194 10.1742 9.3481 10.3125 9 10.3125C8.6519 10.3125 8.31806 10.1742 8.07192 9.92806C7.82578 9.68192 7.6875 9.34808 7.6875 8.99998C7.6875 8.65189 7.82578 8.31805 8.07192 8.07191C8.31806 7.82577 8.6519 7.68748 9 7.68748C9.3481 7.68748 9.68194 7.82577 9.92808 8.07191C10.1742 8.31805 10.3125 8.65189 10.3125 8.99998Z"
          fill="#BBBBCA"
          stroke="#BBBBCA"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1478_52">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
