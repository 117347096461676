import { mapEffect } from "map-effect";
import { takeLatest } from "redux-saga/effects";
import { actionTypes } from "./ducks";
import { services } from "./services";

export default [
  takeLatest(actionTypes.PANEL_PASSWORD_CHANGE, mapEffect(onPasswordChange))
];

export function onPasswordChange(action) {
  return services.changePassword(action);
}
