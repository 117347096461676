export function h(el: string, attrs = {}, ...children: Node[]) {
  let element: HTMLElement = document.createElement(el);
  Object.entries(attrs).forEach(([key, value]) => {
    element[key] = value;
  });
  if (children.length > 0) {
    element.append(...children);
  }
  return element;
}
