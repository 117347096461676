import { colors, fonts } from "common/styles";
import { styled } from "linaria/react";
import React from "react";

let PopoverTitle = styled.div`
  border: none;
  color: ${colors.gray.gray700};
  font: 500 11px/16px ${fonts.BrandonGrotesque};
  padding-left: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

let RegionPopover = styled.div`
  background: white;
  margin-top: -6px;
  z-index: 2;
  border-radius: 0;
  border: none;
  position: absolute;
  border-radius: 0 2px 2px 2px;
  box-shadow: 0 2px 4px 0 rgb(211 211 218 / 60%);
`;

export function OptionsPopover({ children, title = "", ...props }) {
  return (
    <RegionPopover {...props}>
      {title && (
        <>
          <div
            style={{ height: "20px" }}
            className="region-popover-top-space-adjuster"
          />
          <PopoverTitle>{title}</PopoverTitle>
        </>
      )}
      <div
        style={{ height: "6px" }}
        className="region-popover-bottom-space-adjuster"
      />
      {children}
    </RegionPopover>
  );
}
