import api from "api";
import { isIntegratedWithExpose } from "common/util";
import { getSimpleNameFromProductKey } from "constants";
import format from "string-format";
export const services = {
  deleteEmailAccounts: ({ endpoint, orderid, accounts, productkey }) =>
    isIntegratedWithExpose(getSimpleNameFromProductKey(productkey))
      ? api.delete(format(endpoint, { orderid, accounts })).catch(async err => {
          if (err.cause?.response) {
            const json = await err.cause.response.json();
            throw new Error(json.error);
          }
        })
      : api.post(endpoint, { "order-id": orderid, "no-of-accounts": accounts })
};
