import { Syringe } from "@faizaanceg/syringe";
import api from "api";
import { PaymentGateway } from "common/orderbox-types";
import Endpoints from "constants/endpoints";

const sessionManager = Syringe.inject("sessionManager");

interface WalletRouteConfig {
  ADD_BALANCE: string;
  BALANCE: string;
  GATEWAYS: string;
  TOKENS: string;
}

const RESELLER: WalletRouteConfig = {
  ADD_BALANCE: `${Endpoints.zuulOrderBox}billing/add-reseller-fund`,
  BALANCE: `${Endpoints.zuulOrderBox}billing/request-reseller-balance`,
  GATEWAYS: `${Endpoints.zuulOrderBox}pg/allowedlist-for-reseller`,
  TOKENS: `${Endpoints.zuulOrderBox}pg/active-payment-tokens`
};

const CUSTOMER: WalletRouteConfig = {
  ADD_BALANCE: `${Endpoints.zuulOrderBox}billing/add-customer-fund`,
  BALANCE: `${Endpoints.zuulOrderBox}billing/customer-balance`,
  GATEWAYS: `${Endpoints.zuulOrderBox}pg/allowedlist-for-customer`,
  TOKENS: `${Endpoints.zuulOrderBox}pg/active-payment-tokens`
};

// const WALLET = (role: Role) => (role === "reseller" ? RESELLER : CUSTOMER);
const WALLET: Record<Role, WalletRouteConfig> = {
  reseller: RESELLER,
  customer: CUSTOMER
};

const USER_ID: Record<Role, string> = {
  reseller: "reseller-id",
  customer: "customer-id"
};

export const WalletsSdk = {
  /**
   * ### `addBalance`
   *
   * Add funds to the wallet of the authenticated user.
   *
   * | Roles    |
   * | -------- |
   * | Customer, Reseller |
   *
   * #### Usage
   * ```js
   * await HttpApi.Wallet.addBalance( \/** TODO *\/ );
   * ```
   */
  async addBalance(payload) {
    const {
      details: { role }
    } = await sessionManager.create();
    return api.post(WALLET[role as Role].ADD_BALANCE, payload);
  },
  /**
   *
   *
   * Fetch balance for the authenticated user.
   *
   * | Roles    |
   * | -------- |
   * | Customer, Reseller |
   *
   * #### Usage
   *
   * ```js
   * const wallet = await HttpApi.Wallet.balance(userInfo.userid);
   * ```
   *
   */
  async balance(userId: string) {
    const {
      details: { role }
    } = await sessionManager.create();
    return api.get(WALLET[role as Role].BALANCE, {
      [USER_ID[role as Role]]: userId
    });
  },
  /**
   *
   * Fetch payment gateways for authenticated user.
   *
   * | Roles    |
   * | -------- |
   * | Customer, Reseller |
   *
   * #### Usage
   *
   * ```js
   * const paymentGateways = await HttpApi.Wallet.gateways(userInfo.userid);
   * ```
   *
   */
  async gateways(userId: string): Promise<Record<string, PaymentGateway>> {
    const {
      details: { role }
    } = await sessionManager.create();
    return api.get(
      WALLET[role as Role].GATEWAYS,
      role === "reseller"
        ? { "gateway-types-to-return": "GENERIC", "payment-type": "AddFund" }
        : { "customer-id": userId }
    );
  },
  /**
   *
   *
   * Fetch saved tokens for authenticated user.
   *
   * | Roles    |
   * | -------- |
   * | Customer, Reseller |
   *
   * #### Usage
   *
   * ```js
   * const tokens = await HttpApi.Wallet.tokens(userInfo.userid, userInfo.role);
   * ```
   *
   */
  async tokens(userId: string) {
    const {
      details: { role }
    } = await sessionManager.create();
    return api.get(WALLET[role as Role].TOKENS, { "user-id": userId, role });
  },
  /**
   * Call the same methods for a specific customer with authenticated reseller
   *
   * | Roles    |
   * | -------- |
   * | Reseller |
   *
   * #### Usage
   *
   * ```js
   * const customerBalance = await HttpApi.Wallet.customer.balance(customerId);
   * ```
   *
   */
  customer: {
    async addBalance(payload) {
      return api.post(CUSTOMER.ADD_BALANCE, payload);
    },
    async balance(userId: string) {
      return api
        .get<{ totalsellingbalance: number }>(CUSTOMER.BALANCE, {
          "customer-id": userId
        })
        .catch(() => ({ totalsellingbalance: -1 }));
    },
    async gateways(userId: string) {
      return api.get(CUSTOMER.GATEWAYS, { "customer-id": userId });
    }
  }
};
