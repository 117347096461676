import api from "api";
import constants from "constants/index";

const searchElasticSearch = query =>
  api
    .post(constants.es_search, { size: 500 }, query)
    .then(results => results.hits.hits);

const getAutoLoginToken = ({ customerId }) =>
  api.post(`${constants.webpro}open-customer-panel/generate-token`, {
    "customer-id": customerId
  });

export default { searchElasticSearch, getAutoLoginToken };
