import api from "api";

export default {
  setupGsuiteAdmin: ({ endpoints: { setupAdminPanel } }, values) =>
    api.postBody(
      setupAdminPanel,
      new URLSearchParams({
        "order-id": values.orderid,
        "email-address": values.email_address,
        "first-name": values.firstname,
        "last-name": values.lastname,
        "alternate-email-address": values.alternate_email_address,
        name: values.name,
        password: values.password,
        company: values.company,
        zip: values.zip
      })
    )
};
