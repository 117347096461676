import { BaseAction, ReducerFactory } from "common/reducer";
import { roundOffDate } from "common/util";
import { getInvoiceState, InvoiceStatus } from "constants/invoices";
import { makeSystemInvoiceOrReceipt } from "../list-invoices/constants";
import { InvoiceStore } from "./list-invoices-context";

export let invoicesReducer: ReducerFactory<InvoiceStore> = {
  addNewPage(state, action: BaseAction<any>) {
    let {
      type,
      invoices,
      invoicesTypes,
      searchText,
      selectedFilters,
      dateFilterRange
    } = action.payload;

    if (type === "receipt") {
      return {
        ...state,
        receipts: {
          ...invoices,
          selectedFilters,
          searchFilter: {
            ...state.receipts.searchFilter,
            searchText,
            searchPageCount: 1
          },
          dateFilterRange: {
            ...state.receipts.dateFilterRange,
            dateFilterPageCount: 1,
            from: dateFilterRange?.from ?? "",
            to: dateFilterRange?.to ?? dateFilterRange?.from ?? ""
          }
        }
      };
    }

    let invoice = type === "manual" ? "manualInvoices" : "systemInvoices";
    let totalInvoices = Object.keys(InvoiceStatus).reduce(
      (total, invoiceType) =>
        total + (invoicesTypes?.[invoiceType]?.totalInvoices ?? 0),
      0
    );

    return {
      ...state,
      invoices: {
        ...state.invoices,
        [invoice]: invoices
      },
      [invoice]: {
        ...invoicesTypes,
        selectedFilters,
        totalInvoices,
        searchFilter: {
          ...state[invoice].searchFilter,
          searchText,
          searchPageCount: 1
        },
        dateFilterRange: {
          ...state[invoice].dateFilterRange,
          dateFilterPageCount: 1,
          from: dateFilterRange?.from ?? "",
          to: dateFilterRange?.to ?? dateFilterRange?.from ?? ""
        }
      }
    };
  },
  addCustomerPage: (state, action: BaseAction<any>) => {
    let { invoices, totalInvoices, customerId } = action.payload;
    return {
      ...state,
      invoices: {
        ...state.invoices,
        customerInvoices: {
          ...state.invoices.customerInvoices,
          [customerId]: invoices
        }
      },
      customerInvoices: {
        ...state.customerInvoices,
        [customerId]: {
          manualInvoices: {
            pageCount: 1,
            totalInvoices: totalInvoices.manual
          },
          systemInvoices: {
            pageCount: 1,
            totalInvoices: totalInvoices.system
          }
        }
      }
    };
  },
  setPageCount: (state, action: BaseAction<any>) => {
    let {
      invoiceType,
      searchText,
      selectedFilters,
      dateFilterRange
    } = action.payload;

    let invoice =
      invoiceType === "all"
        ? "manualInvoices"
        : invoiceType === "manual"
        ? "manualInvoices"
        : "systemInvoices";
    if (searchText) {
      return {
        ...state,
        [invoice]: {
          ...state[invoice],
          searchFilter: {
            ...state[invoice].searchFilter,
            searchPageCount: state[invoice].searchFilter.searchPageCount + 1
          }
        }
      };
    } else if (selectedFilters.length > 0) {
      let newState = selectedFilters.reduce((obj, filter) => {
        let filterName = filter.toLowerCase();
        if (state[invoice][filterName]?.pageCount >= 0) {
          obj[filterName] = {
            ...state[invoice][filterName],
            pageCount: state[invoice][filterName]?.pageCount + 1
          };
        }
        return obj;
      }, {});

      return {
        ...state,
        [invoice]: {
          ...state[invoice],
          ...newState
        }
      };
    } else if (dateFilterRange?.from && dateFilterRange?.to) {
      return {
        ...state,
        [invoice]: {
          ...state[invoice],
          dateFilterRange: {
            ...state[invoice].dateFilterRange,
            dateFilterPageCount:
              state[invoice].dateFilterRange.dateFilterPageCount + 1
          }
        }
      };
    } else {
      let updatedInvoices = Object.entries(state[invoice]).reduce(
        (obj, [invoiceType, invoiceData]: any) => {
          if (Object.keys(InvoiceStatus).includes(invoiceType)) {
            obj[invoiceType] = {
              ...invoiceData,
              pageCount: state[invoice][invoiceType].pageCount + 1
            };
          }
          return obj;
        },
        {}
      );
      return {
        ...state,
        [invoice]: {
          ...state[invoice],
          ...updatedInvoices
        }
      };
    }
  },
  updateNewPage: (state, action: BaseAction<any>) => {
    let { type, invoices, invoicesTypes } = action.payload;

    if (type === "receipt") {
      return {
        ...state,
        receipts: {
          ...state.receipts,
          ...invoices
        }
      };
    }

    let invoice = type === "manual" ? "manualInvoices" : "systemInvoices";
    let totalInvoices = Object.keys(InvoiceStatus).reduce(
      (total, invoiceType) =>
        total + (state[invoice]?.[invoiceType]?.totalInvoices ?? 0),
      0
    );
    return {
      ...state,
      invoices: {
        ...state.invoices,
        [invoice]: {
          ...state.invoices[invoice],
          ...invoices
        }
      },
      [invoice]: {
        ...state[invoice],
        ...invoicesTypes,
        totalInvoices
      }
    };
  },
  setAllCustomerInvoicesCount: (state, action: BaseAction<any>) => {
    let { customerId } = action.payload;
    return {
      ...state,
      customerInvoices: {
        ...state.customerInvoices,
        [customerId]: {
          ...state.customerInvoices[customerId],
          manualInvoices: {
            ...state.customerInvoices[customerId].manualInvoices,
            pageCount:
              state.customerInvoices[customerId].manualInvoices.pageCount + 1
          },
          systemInvoices: {
            ...state.customerInvoices[customerId].systemInvoices,
            pageCount:
              state.customerInvoices[customerId].systemInvoices.pageCount + 1
          }
        }
      }
    };
  },
  updateNewCustomerPage: (state, action: BaseAction<any>) => {
    let { invoices, customerId } = action.payload;
    return {
      ...state,
      invoices: {
        ...state.invoices,
        customerInvoices: {
          ...state.invoices.customerInvoices,
          [customerId]: {
            ...state.invoices.customerInvoices[customerId],
            ...invoices
          }
        }
      }
    };
  },
  createNewInvoice: (state, action: BaseAction<any>) => {
    const invoiceState = getInvoiceState(action.payload).toLowerCase();
    return {
      ...state,
      manualInvoices: {
        ...state.manualInvoices,
        [invoiceState]: {
          ...(state.manualInvoices?.[invoiceState] ?? []),
          totalInvoices:
            (state.manualInvoices?.[invoiceState]?.totalInvoices ?? 0) + 1
        },
        totalInvoices: state.manualInvoices.totalInvoices + 1
      },
      customerInvoices: {
        ...state.customerInvoices,
        [action.payload.customerId]: {
          ...(state.customerInvoices?.[action.payload.customerId] ?? []),
          manualInvoices: {
            ...(state.customerInvoices?.[action.payload.customerId]
              ?.manualInvoices ?? []),
            totalInvoices:
              (state.customerInvoices?.[action.payload.customerId]
                ?.manualInvoices?.totalInvoices ?? 0) + 1
          }
        }
      },
      invoices: {
        ...state.invoices,
        manualInvoices: {
          ...state.invoices.manualInvoices,
          [action.payload.invoiceId]: {
            ...action.payload,
            creationDate: Math.floor(action.payload.creationDate / 1000)
          }
        },
        customerInvoices: {
          ...state.invoices.customerInvoices,
          [action.payload.customerId]: {
            ...(state.invoices.customerInvoices?.[action.payload.customerId] ??
              []),
            [action.payload.invoiceId]: {
              ...action.payload,
              creationDate: Math.floor(action.payload.creationDate / 1000)
            }
          }
        }
      }
    };
  },
  editInvoiceState: (state, action: BaseAction<any>) => {
    let { previousState, invoice } = action.payload;
    let { actions, ...invoiceDetails } = invoice;
    let invoiceState = getInvoiceState(invoiceDetails).toLowerCase();

    return {
      ...state,
      manualInvoices: {
        ...state.manualInvoices,
        [invoiceState]:
          previousState !== invoiceState
            ? {
                ...(state.manualInvoices?.[invoiceState] ?? []),
                totalInvoices:
                  (state.manualInvoices?.[invoiceState]?.totalInvoices ?? 0) + 1
              }
            : state.manualInvoices[invoiceState],
        [previousState]:
          previousState !== invoiceState
            ? {
                ...(state.manualInvoices?.[previousState] ?? []),
                totalInvoices:
                  (state.manualInvoices?.[previousState]?.totalInvoices ?? 1) -
                  1
              }
            : state.manualInvoices[previousState]
      },
      invoices: {
        ...state.invoices,
        manualInvoices: {
          ...state.invoices.manualInvoices,
          [invoiceDetails.invoiceId]: {
            ...state.invoices.manualInvoices[invoiceDetails.invoiceId],
            ...invoiceDetails,
            creationDate:
              state.invoices.manualInvoices[invoiceDetails.invoiceId]
                ?.creationDate ?? "",
            state: getInvoiceState(invoiceDetails),
            lineItems: invoiceDetails.lineItems.map(lineItem => ({
              ...lineItem,
              meta: {
                ...lineItem.meta,
                taxRule: lineItem.meta.taxRule ?? lineItem.meta.taxRules[0]
              }
            }))
          }
        },
        customerInvoices: state.invoices.customerInvoices?.[
          invoiceDetails.addressInfo.userId
        ]
          ? {
              ...state.invoices.customerInvoices,
              [invoiceDetails.addressInfo.userId]: {
                ...state.invoices.customerInvoices[
                  invoiceDetails.addressInfo.userId
                ],
                [invoiceDetails.invoiceId]: {
                  ...state.invoices.customerInvoices[
                    invoiceDetails.addressInfo.userId
                  ][invoiceDetails.invoiceId],
                  ...invoiceDetails,
                  creationDate:
                    state.invoices.customerInvoices[
                      invoiceDetails.addressInfo.userId
                    ][invoiceDetails.invoiceId].creationDate,
                  state: getInvoiceState(invoiceDetails),
                  lineItems: invoiceDetails.lineItems.map(lineItem => ({
                    ...lineItem,
                    meta: {
                      ...lineItem.meta,
                      taxRule:
                        lineItem.meta.taxRule ?? lineItem.meta.taxRules[0]
                    }
                  }))
                }
              }
            }
          : state.invoices.customerInvoices
      }
    };
  },
  updateInvoiceById: (state, action: BaseAction<any>) => {
    const invoiceById = {
      ...action.payload,
      state: getInvoiceState(action.payload),
      creationDate: roundOffDate(+action.payload.creationDate / 86400),
      creationTime: +action.payload.creationDate,
      type: "custom_invoice",
      lineItems: action.payload.lineItems.map(lineItem => ({
        ...lineItem,
        meta: {
          ...lineItem.meta,
          taxRule: lineItem.meta.taxRule ?? lineItem.meta.taxRules[0]
        }
      }))
    };
    return {
      ...state,
      invoices: {
        ...state.invoices,
        manualInvoices: {
          ...state.invoices.manualInvoices,
          [invoiceById.invoiceId]: {
            ...state.invoices.manualInvoices[action.payload.invoiceId],
            ...invoiceById
          }
        }
      }
    };
  },
  updateSystemInvoiceById: (state, action: BaseAction<any>) => {
    const invoiceById = makeSystemInvoiceOrReceipt(action.payload);
    return {
      ...state,
      invoices: {
        ...state.invoices,
        systemInvoices: {
          ...state.invoices.systemInvoices,
          [invoiceById.invoiceId]: {
            ...state.invoices.systemInvoices[invoiceById.invoiceId],
            ...invoiceById,
            state: getInvoiceState(invoiceById)
          }
        }
      }
    };
  },
  updateReceiptById: (state, action: BaseAction<any>) => {
    const invoiceById = makeSystemInvoiceOrReceipt(action.payload);
    return {
      ...state,
      receipts: {
        ...state.receipts,
        list: {
          ...state.receipts.list,
          [invoiceById.invoiceId]: {
            ...state.receipts.list[invoiceById.invoiceId],
            ...invoiceById
          }
        }
      }
    };
  },
  updateOrderDetails: (state, action: BaseAction<any>) => {
    return {
      ...state,
      invoices: {
        ...state.invoices,
        systemInvoices: {
          ...state.invoices.systemInvoices,
          [action.payload.invoiceId]: {
            ...state.invoices.systemInvoices[action.payload.invoiceId],
            orderDetails: action.payload
          }
        }
      }
    };
  },
  updatePendingTransactionDetails: (state, action: BaseAction<any>) => {
    return {
      ...state,
      invoices: {
        ...state.invoices,
        systemInvoices: {
          ...state.invoices.systemInvoices,
          [action.payload.transid]: {
            ...state.invoices.systemInvoices[action.payload.transid],
            pendingTransactionDetails: action.payload
          }
        }
      }
    };
  },
  setSelectedTab: (
    state,
    action: BaseAction<{ tabName: "all" | "manual" | "system" }>
  ) => {
    return {
      ...state,
      selectedTab: action.payload.tabName
    };
  }
};
