import { css } from "linaria";
import React from "react";

let DropDownButton = css`
  vertical-align: middle;

  &[data-attr-rotate="true"] {
    animation: rotate 0.2s linear forwards;
    margin-left: 5px;
  }
  &[data-attr-rotate="false"] {
    animation: rotateReverse 0.2s linear forwards;
    margin-left: 5px;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
  @keyframes rotateReverse {
    0% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

export let DropDownCaretIcon = props => (
  <svg
    width="12px"
    height="7px"
    viewBox="0 0 12 7"
    className={DropDownButton}
    role="button"
    {...props}
  >
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g
        transform="translate(-860.000000, -226.000000)"
        fill="#697489"
        fillRule="nonzero"
        id="fill-area"
      >
        <g transform="translate(77.000000, 64.000000)">
          <g transform="translate(26.000000, 152.000000)">
            <path
              d="M768.836292,10.1653921 C768.615769,9.94486931 768.257419,9.94486931 768.036897,10.1653921 L763.007733,15.2083381 L757.964787,10.1653921 C757.744264,9.94486931 757.385915,9.94486931 757.165392,10.1653921 C756.944869,10.3859149 756.944869,10.7442644 757.165392,10.9647871 L762.594253,16.393648 C762.704514,16.5039094 762.842341,16.5590401 762.99395,16.5590401 C763.131777,16.5590401 763.283387,16.5039094 763.393648,16.393648 L768.822509,10.9647871 C769.056814,10.7442644 769.056814,10.3859149 768.836292,10.1653921 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default DropDownCaretIcon;
