export const actionTypes = {
  SHOW_MODAL: "[Gsuite] Show",
  GSUITE_ADMIN_SETUP_REQUEST: "[Gsuite] Setup"
};

export const actions = {
  setupGsuiteAdmin: (meta, values) => ({
    type: actionTypes.GSUITE_ADMIN_SETUP_REQUEST,
    meta,
    values
  })
};

export const initialState = {
  pendingSetup: {
    customerInfo: { name: "", company: "", zip: "" }
  }
};

export const parsers = {
  parseCustomerInfo: ({ name, company, zip }) => ({
    customerInfo: { name, company, zip }
  })
};
