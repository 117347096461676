import { updatePricingAndAddonDetails } from "common/orders/sagas";
import { call, put, takeEvery } from "redux-saga/effects";
import { actions, actionTypes } from "./ducks";
import { mapEffect } from "map-effect";

export const retryRenewalModalSaga = [
  takeEvery(
    actionTypes.GET_RETRY_RENEWAL_PRICING,
    mapEffect(getRetryRenewalPricing)
  )
];

export function* getRetryRenewalPricing(action) {
  yield call(
    updatePricingAndAddonDetails,
    action.orderdetails,
    action.taxinfo,
    null
  );
  yield put(actions.openRetryRenewalModal(action.orderdetails));
}
