import { Syringe } from "@faizaanceg/syringe";
import { TransactionsSdk } from "common/sdk/transactions";
import { mapEffect } from "map-effect";
import { call, select, takeEvery } from "redux-saga/effects";
import { actionTypes } from "./ducks";
import { services } from "./services";

export const addFundsSagas = [
  takeEvery(
    actionTypes.ADD_FUNDS,
    process.env.FEATURE_ENDPOINT === "reseller"
      ? mapEffect(onAddFunds)
      : mapEffect(addFundsForCustomer)
  ),
  takeEvery(
    actionTypes.GET_NEXT_30_DAYS_RENEWALS,
    mapEffect(onGetNext30DaysRenewals)
  ),
  takeEvery(
    actionTypes.GET_GREEDY_TRANSACTIONS,
    mapEffect(onGetGreedyTransactions)
  )
];

function* addFundsForCustomer(action) {
  const { gateway, fundsToBeAdded } = action.payload;
  const { parentbrandingurl } = yield select(
    state => state.root.brandingDetails
  );
  const response = yield services.createAddFundsLink({
    parentbrandingurl,
    gateway,
    fundsToBeAdded
  });
  window.location.href = response.url;
}
function* onAddFunds(action) {
  const { gateway, fundsToBeAdded } = action.payload;
  let externalAccessManager = Syringe.inject("externalAccessManager");
  const response = yield services.addFunds({
    custompaymentid: gateway,
    amount: fundsToBeAdded,
    redirecturl: externalAccessManager.createPaymentUrl("add-funds"),
    description: "Add funds from webpro"
  });
  window.location.href = response.url;
}

function* onGetNext30DaysRenewals() {
  if (process.env.FEATURE_ENDPOINT === "bigrock") {
    let { userid } = yield select(state => state.root.userInfo);
    let renewalCost = yield call(services.getNext30DaysRenewals, userid);
    return renewalCost;
  } else {
    return yield services.getNext30DaysRenewals();
  }
}

function* onGetGreedyTransactions() {
  const response = yield TransactionsSdk.greedy();
  return response;
}
