import { put, takeEvery } from "redux-saga/effects";
import { actions, actionTypes } from "./ducks";
import { services } from "./services";
import { mapEffect } from "map-effect";

export default [
  takeEvery(actionTypes.ADD_DNS_SEC, mapEffect(addDnsSec)),
  takeEvery(actionTypes.DELETE_DNS_SEC, mapEffect(deleteDnsSec))
];

function addDnsSec(action) {
  let index = 1;
  let params = {
    "order-id": action.orderid
  };
  let propename = "attr-name";
  let propvalue = "attr-value";
  Object.keys(action.params).forEach(data => {
    if (action.params[data] && action.params[data] !== "") {
      params[propename + index] = data;
      params[propvalue + index] = action.params[data];
      index++;
    }
  });
  return services.addDnsSec(params);
}

function deleteDnsSec(action) {
  let params = {
    "order-id": action.orderid,
    "attr-name1": "keytag",
    "attr-value1": action.params.keytag,
    "attr-name2": "algorithm",
    "attr-value2": action.params.algorithm,
    "attr-name3": "digesttype",
    "attr-value3": action.params.digesttype,
    "attr-name4": "digest",
    "attr-value4": action.params.digest
  };
  return services.deleteDnsSec(params).then(action.postActionHandler);
}
