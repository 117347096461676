import { colors, fonts } from "common/styles";
import { isDateInSeries } from "common/timeseries";
import { monthIndices } from "constants/index";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import MaintenanceImage from "./maintenance.png";

// TODO: Ideally, this could come from an async source in the future.
let downtimes = [
  {
    start: Date.UTC(2020, monthIndices.Mar - 1, 15, 1, 30, 0),
    end: Date.UTC(2020, monthIndices.Mar - 1, 15, 2, 0, 0)
  },
  {
    start: Date.UTC(2020, monthIndices.Aug - 1, 9, 2, 30, 0),
    end: Date.UTC(2020, monthIndices.Aug - 1, 9, 3, 10, 0)
  },
  {
    start: Date.UTC(2021, monthIndices.Jun - 1, 27, 1, 30, 0),
    end: Date.UTC(2021, monthIndices.Jun - 1, 27, 3, 0, 0)
  }
];

export function isProductUnderMaintainence(productKey) {
  let setProductStatus, getProductStatus;
  if (window.__cfWarning) {
    let getProductsArray = window.__cfWarning.productUnderMaintainence;

    for (let productsToBeDisabled of getProductsArray) {
      for (let getAffectedProductKey of productsToBeDisabled.affectedProducts) {
        if (productKey === getAffectedProductKey) {
          getProductStatus = isDateInSeries(
            Date.now(),
            productsToBeDisabled.duration
          );
          if (getProductStatus !== undefined) {
            setProductStatus = getProductStatus;
          }
        }
      }
    }
  }

  return setProductStatus;
}

export function areWeInMaintenance() {
  return isDateInSeries(Date.now(), downtimes);
}

let Page = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: main;
  color: ${colors.black.regular};
`;

let Title = styled.h2`
  font: bold 19px ${fonts.BrandonGrotesque};
  line-height: 27px;
  margin-bottom: 20px;
  text-transform: uppercase;
`;

let Description = styled.p`
  font: normal 16px ${fonts.OpenSans};
  line-height: 20px;
  &:last-child {
    margin-top: 16px;
  }
`;

let Image = styled.img`
  margin: 140px 0 11px 0;
  height: 238px;
`;

export function MaintenancePage(props) {
  return (
    <Page>
      <Image src={MaintenanceImage} alt="" />
      <Title>We're upgrading our systems</Title>
      <Description>
        We need a short downtime to bring you a better experience. We will be
        back up shortly.
      </Description>
      <Description>Apologies for the inconvenience.</Description>
      <br />
    </Page>
  );
}

export function ErrorPage(props) {
  return (
    <Page>
      <Image src={MaintenanceImage} alt="" />
      <Title style={{ marginBottom: "10px" }}>
        An error occurred while processing your request
      </Title>
      <Description>
        Please try again or contact our support team for further assistance.
      </Description>
      <br />
      <button
        className={css`
          font: 600 14px ${fonts.OpenSans};
          color: ${colors.white.regular};
          border: none;
          display: block;
          text-align: center;
          padding: 15px 77px;
          border-radius: 2px;
          background: ${colors.blue.regular};
          transition: 0.2s all linear;
          text-transform: uppercase;
          margin-top: 23px;
          &:hover,
          &:focus,
          &:active {
            background: ${colors.blue.hover};
            color: ${colors.white.regular};
          }
        `}
        type="button"
        onClick={() => window.location.reload(true)}
      >
        Go Back To Panel
      </button>
    </Page>
  );
}
