export const actionTypes = {
  OPEN_PAYMENT_ACTIONS_SUMMARY_MODAL: "[Payment actions summary] Open modal",
  CLOSE_PAYMENT_ACTIONS_SUMMARY_MODAL: "[Payment actions summary] Close"
};

export const actions = {
  closeSummaryModal: () => ({
    type: actionTypes.CLOSE_PAYMENT_ACTIONS_SUMMARY_MODAL
  })
};
