export const platform = {
  WINDOWS: "windows",
  LINUX: "linux"
};
/**
 *
 * @param {string} productName
 * @returns {"windows" | "linux"}
 */
export const getPlatformFromProductName = productName => {
  if (productName.includes("windows")) {
    return platform.WINDOWS;
  }
  return platform.LINUX;
};
