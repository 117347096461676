import {
  categories as AnalyticsCategories,
  actions as AnalyticsActions,
  labels as AnalyticsLabels
} from "analytics/constants";

export const actionTypes = {
  UPDATE_CHILD_NAMESERVERS: "[Child Name Servers] Update",
  SHOW_CHILD_NAMESERVERS: "[Child Name Servers] Show"
};

export const actions = {
  updateChildNameServers: (meta, oldFormData = [], newFormData) => ({
    type: actionTypes.UPDATE_CHILD_NAMESERVERS,
    track: {
      eventCategory: AnalyticsCategories.domains_view,
      eventAction: AnalyticsActions.edit_child_name_servers,
      eventLabel: AnalyticsLabels.child_name_servers
    },
    ...meta,
    oldFormData,
    newFormData
  }),
  showChildNameServerModal: meta => ({
    type: actionTypes.SHOW_CHILD_NAMESERVERS,
    ...meta
  })
};

export const initialState = {
  childnameservers: { childnameservers: undefined }
};

export const parsers = {
  detailsParser: ({ cns }, meta) => {
    let cnsValues = [];
    Object.entries(cns).forEach(([hostname, ips]) => {
      cnsValues.push(...ips.map(ip => ({ hostname, ip })));
    });

    if (cnsValues.length === 0) {
      cnsValues = undefined;
    }

    return { childnameservers: cnsValues };
  }
};
