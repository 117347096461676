import { getValueInRange } from "common/util";
import { BasePricingStrategy } from "./generic";
import { PricingInfo, PricingType } from "./index";
export class EmailStrategy extends BasePricingStrategy {
  getAccountPrice(
    emailPricing: Record<PricingType, Record<string | number, number>>,
    pricingKey: PricingType,
    { numberOfAccounts, remainingNumberOfMonths, selectedTenure }: PricingInfo
  ) {
    const pricingInRange = getValueInRange(emailPricing, numberOfAccounts);
    if (remainingNumberOfMonths < 1) {
      remainingNumberOfMonths = 1;
    }
    const monthSlots = Object.keys(
      pricingInRange?.[pricingKey] ?? {}
    ).map(monthSlot => parseInt(monthSlot, 10));
    let chargeableMonth: number;
    if (selectedTenure) {
      chargeableMonth = selectedTenure;
    } else {
      for (let monthIndex = 0; monthIndex < monthSlots.length; monthIndex++) {
        if (
          monthIndex + 1 === monthSlots.length ||
          (monthSlots[monthIndex] <= remainingNumberOfMonths &&
            monthSlots[monthIndex + 1] >= remainingNumberOfMonths)
        ) {
          chargeableMonth = monthSlots[monthIndex];
          break;
        }
      }
    }
    return pricingInRange?.[pricingKey]?.[chargeableMonth] ?? 0;
  }
}
