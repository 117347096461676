import api from "api";
import constants from "constants/index";

export default {
  getAgreementText(agreementId = "") {
    return api.get(constants.legal_agreements, { type: agreementId });
  },

  updatePrivacyPolicy(newAgreement = "") {
    return api.call("PUT", constants.legal_agreements, {
      "agreement-type": "privacypolicyagreement",
      "is-default-agreement": true,
      "agreement-desc": newAgreement
    });
  }
};
