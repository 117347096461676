import api from "api";
import endpoints from "constants/endpoints";

export default {
  validateZipcode: ({ zipcode, countryCode }) =>
    !countryCode
      ? Promise.reject(new Error("Please select a country"))
      : api.get(`${endpoints.zuulOrderBox}country/validate-zipcode`, {
          "country-code": countryCode,
          "zip-code": zipcode
        }),
  getCountryFromZipcode: payload => api.get("/service/address", payload)
};
