export const actionTypes = {
  SHOW_ADD_MODAL: "[Manage Blocks] Show add modal",
  SHOW_DELETE_MODAL: "[Manage Blocks] Show delete modal",
  SHOW_MANAGE_MODAL: "[Manage Blocks] Show manage modal",
  BUY_BLOCKS: "[Manage Blocks] Buy",
  CONFIRM_DELETE_BLOCKS: "[Manage Blocks] Confirm Delete",
  DELETE_BLOCKS: "[Manage Blocks] Delete"
};

export const initialState = {
  manageBlocks: {
    blocks: { available: 0, unitsPerBlock: 1 },
    usedBlocks: 0,
    isAddonAvailable: undefined
  }
};

export const actions = {
  deleteBlocks: payload => ({ type: actionTypes.DELETE_BLOCKS, payload })
};

const helpers = {
  convertToBlocks(accounts, accountsPerBlock) {
    let usedBlocks = Math.trunc(accounts / accountsPerBlock);
    let partiallyUsedBlock = accounts % accountsPerBlock > 0 ? 1 : 0;
    return usedBlocks + partiallyUsedBlock;
  }
};

export const parsers = {
  emailHostingParser: (details, meta) => {
    let storageAddon = details.addons?.find(
      _ => _.addon_name === "email_storage_5"
    );
    return {
      blocks: { available: storageAddon?.quantity ?? 0, unitsPerBlock: 5 },
      usedBlocks:
        (storageAddon?.quantity ?? 0) - (storageAddon?.unused_quantity ?? 0),
      isAddonAvailable: true
    };
  },
  resellerHostingParser: (details, meta) => ({
    isAddonAvailable: details.addons?.cpanel_blocks?.is_paid ?? false,
    blocks: {
      available: details?.cpanel_blocks_detail?.cpanel_blocks_count ?? 0,
      unitsPerBlock:
        details?.cpanel_blocks_detail?.cpanel_accounts_per_block ?? 1
    }
  }),
  cpanelParser: (payload, { details }) => {
    let canBuyBlocks = details.addons?.cpanel_blocks?.is_paid ?? false;
    if (!canBuyBlocks) {
      return {};
    }
    let freeAccounts = details.cpanel_accounts;
    let {
      cpanel_blocks_count: availableBlocks,
      cpanel_accounts_per_block: accountsPerBlock
    } = details.cpanel_blocks_detail;
    let extraAccounts = +payload.resourceUsage.cpanel_accounts - freeAccounts;
    let usedBlocks =
      extraAccounts > 0
        ? helpers.convertToBlocks(extraAccounts, accountsPerBlock)
        : 0;
    return { usedBlocks: Math.min(usedBlocks, availableBlocks) };
  }
};
