import { Syringe } from "@faizaanceg/syringe";
import constants from "constants/endpoints";
import { createApiClient } from "http-with-fetch";

const client = createApiClient(
  {
    credentials: Syringe.inject("credentials"),
    visitor: {
      async ok(response: Response) {
        return response.json();
      },
      async fail(request: Request, response: Response) {
        let error = await response.json();
        if (process.env.BUILD_ENV !== "production") {
          console.error("API Call Failed: ", error, request);
        }
        throw new Error(error);
      }
    }
  },
  true
);

const Endpoints = {
  CART: `${constants.apiHost}cart/`
};

export const SmartCartSdk = {
  health: () => client.get<{ status: number }>(`${Endpoints.CART}health`),
  list: (limit: number) =>
    client.get<SmartCart.GetCart[]>(`${Endpoints.CART}list`, { limit }),
  delete: (cartId: string, itemId: string) =>
    client.delete(`${Endpoints.CART}${cartId}/${itemId}`)
};
