import storage from "@faizaanceg/pandora";
import { Maximize, Minimize, SurveyImage, SurveySuccess } from "assets";
import classnames from "classnames";
import { parseStringAsBoolean } from "common/parse-string";
import { ConsentSdk } from "common/sdk/consents";
import { FeedbackSdk } from "common/sdk/feedback";
import React, { useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

/**
 * We show the migration consent flow to the user under the following conditions
 * 1. User has completed `ResellerToWebProMigrationSurvey` feedback form in the old panel, and we've determined
 * that the user is eligible for the new panel.
 * 2. The user hasn't given consent to move them to WebPro Panel.
 */

function TryPanel() {
  let userId = useSelector<RootStateOrAny, string>(
    state => state.root.userInfo.userid
  );
  let consentKey = `ask-migration-consent-${userId}`;
  let migrationConsent = useSWR("ask-migration-consent", async () => {
    let askMigrationConsent = storage.get<boolean>(consentKey, null);
    if (askMigrationConsent !== null) {
      return askMigrationConsent;
    }
    const response = await FeedbackSdk.fetch(userId);
    let migrationFeedback = response["feedback-list"].find(
      feedback => feedback.category === "ResellerToWebProMigrationSurvey"
    );
    let options = migrationFeedback
      ? JSON.parse(migrationFeedback.reason)
      : { "is-eligible-for-new-panel": "false" };
    let isConsentNeeded = false;
    if (parseStringAsBoolean(options["is-eligible-for-new-panel"])) {
      let { consent } = await ConsentSdk.fetch(["switch-to-webpro-panel"]);
      isConsentNeeded = consent.length === 0;
    }
    let opts = { ttl: 5 * 86400 * 1000, shouldPersist: true };
    storage.set<boolean>(consentKey, isConsentNeeded, opts);
    return isConsentNeeded;
  });
  let askMigrationConsent = migrationConsent.data;
  let moveToWebProPanel = useSWRMutation("migration-consent", async () => {
    await ConsentSdk.accept("switch-to-webpro-panel");
    storage.set<boolean>(consentKey, false, { shouldPersist: true });
  });
  let [minimized, setMinimized] = useState(false);
  let [showMessage, setMessageView] = useState(null);
  let [isClosed, setClosed] = useState(false);
  if (migrationConsent.isLoading) {
    return <span />;
  }
  if (isClosed || !askMigrationConsent) {
    return null;
  }
  let initialView = askMigrationConsent ? "askConsent" : "consented";
  if (showMessage === null) {
    showMessage = initialView;
  }
  let dropdownFragment =
    showMessage === "askConsent" ? (
      <button
        className="survey-popup-action"
        onClick={_ => setMinimized(prev => !prev)}
      >
        <img
          src={minimized ? Minimize : Maximize}
          alt={minimized ? "Minimize" : "Maximize"}
        />
      </button>
    ) : null;
  if (minimized) {
    return (
      <div className={classnames("survey-popup", "minimized")}>
        {dropdownFragment}
        <div className="survey-popup-wrap">
          <div className="survey-popup-image">
            <img alt="" src={SurveyImage} />
          </div>
          <div className="survey-popup-description">
            <div className="survey-popup-description-title">
              Switch over to our all-new <br />
              panel now.{" "}
              <span
                role="img"
                aria-hidden
                style={{ position: "relative", top: "2px", left: "2px" }}
              >
                🙂
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  let messageFragment;
  switch (showMessage) {
    case "askConsent":
      messageFragment = (
        <div className="survey-popup-wrap">
          <div className="survey-popup-image">
            <img alt="" src={SurveyImage} />
          </div>
          <div className="survey-popup-description">
            <div className="survey-popup-description-title">
              Hope you are liking our new panel{" "}
              <span
                role="img"
                aria-hidden
                style={{ position: "relative", top: "2px", left: "2px" }}
              >
                🙂
              </span>
            </div>
            <div className="survey-popup-description-subtitle">
              It already supports the features <br />
              you use.
            </div>
            <div className="survey-popup-description-text">
              Would you like to switch over?
            </div>
            <div className="survey-action-holder">
              <button
                onClick={async () => {
                  await moveToWebProPanel.trigger();
                  setMessageView("consented");
                }}
                className="wp-btn"
              >
                Yes, switch me over
              </button>
              <button
                onClick={() => setMessageView("skipConsent")}
                className="cancel-link"
              >
                I'm not ready yet
              </button>
            </div>
          </div>
        </div>
      );
      break;

    case "consented":
      messageFragment = (
        <div className="survey-popup-success">
          <img src={SurveySuccess} alt="Success" />
          <div className="survey-popup-success-text">SURE THING</div>
          <div className="survey-popup-success-subtext">
            The new panel will be activated <br />
            for you shortly
          </div>
          <button onClick={_ => setClosed(true)} className="wp-btn">
            Thanks
          </button>
        </div>
      );
      break;
    case "skipConsent":
      messageFragment = (
        <div className="survey-popup-success">
          <img src={SurveySuccess} alt="Success" />
          <div className="survey-popup-success-text">SURE THING</div>
          <div className="survey-popup-success-subtext">
            You can switch over anytime from your existing panel.
          </div>
          <button onClick={_ => setClosed(true)} className="wp-btn">
            Thanks
          </button>
        </div>
      );
      break;
    default:
      messageFragment = null;
      break;
  }
  return (
    <div className="survey-popup">
      {dropdownFragment}
      {messageFragment}
    </div>
  );
}

export default TryPanel;
