import { mapEffect } from "map-effect";
import { all, takeEvery } from "redux-saga/effects";
import { actionTypes } from "./ducks";
import services from "./services";

export default [
  takeEvery(
    actionTypes.UPDATE_CHILD_NAMESERVERS,
    mapEffect(onUpdateChildNameServers)
  )
];

const doesNameServerExist = (nameServers = [], givenNameServer) => {
  const filteredNameServerList = nameServers.find(
    nameServer =>
      nameServer.hostname === givenNameServer.hostname &&
      nameServer.ip === givenNameServer.ip
  );
  return !!filteredNameServerList;
};

const isNSPresent = cns => !(cns.hostname.length === 0 && cns.ip.length === 0);

const groupByHostname = (cns, { hostname, ip }) => ({
  ...cns,
  [hostname]: cns[hostname] ? [...cns[hostname], ip] : [ip]
});

function* onUpdateChildNameServers(action) {
  let { oldFormData, newFormData, orderid, productKey } = action;
  oldFormData = oldFormData.filter(isNSPresent);
  newFormData = newFormData.nameServers.filter(isNSPresent);
  let newlyAddedChildNameServers = [];
  let deleteChildNameServers = [];
  let IPModifiedNameServers = [];

  if (productKey === "dotai") {
    newlyAddedChildNameServers = newFormData.filter(
      _ => _.oldCNS === "" && _.oldIP === ""
    );

    IPModifiedNameServers = newFormData.filter(
      _ => _.oldCNS === _.hostname && _.oldIP !== _.ip
    );

    deleteChildNameServers = oldFormData.filter(
      givenNameServer =>
        !newFormData.find(
          nameServer =>
            nameServer.oldCNS === givenNameServer.hostname &&
            nameServer.oldIP === givenNameServer.ip
        )
    );
  } else {
    newlyAddedChildNameServers = newFormData.filter(
      nameServer => !doesNameServerExist(oldFormData, nameServer)
    );
    deleteChildNameServers = oldFormData.filter(
      nameServer => !doesNameServerExist(newFormData, nameServer)
    );
  }

  yield all(
    Object.entries(
      newlyAddedChildNameServers.reduce(groupByHostname, {})
    ).map(([cns, ip]) => services.addNameServer(orderid, { cns, ip }))
  );

  yield all(
    IPModifiedNameServers.map(({ hostname: cns, oldIP, ip: newIP }) =>
      services.modifyNameServerIP(orderid, { cns, oldIP, newIP })
    )
  );

  yield all(
    Object.entries(
      deleteChildNameServers.reduce(groupByHostname, {})
    ).map(([cns, ip]) => services.deleteNameServer(orderid, { cns, ip }))
  );

  return newFormData;
}
