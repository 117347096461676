import { Syringe } from "@faizaanceg/syringe";

const compatSettings = Syringe.inject("compatSettings");
const apiHost = compatSettings.needsCompat
  ? `${compatSettings.layer}/`
  : "/api/";

function withCompat(host: string, prefix: string) {
  if (
    compatSettings.needsCompat &&
    process.env.FEATURE_ENDPOINT !== "reseller"
  ) {
    host = `${compatSettings.layer}/`;
  }
  return `${host}${prefix}/`;
}

const experience = "/experience/webpro/";
const webpro = `${apiHost}webpro/`;
const billing = `${apiHost}billing/`;
const common = `${webpro}common/`;
const webhooks = `${apiHost}webhooks/`;
const user = `${apiHost}user/`;

const apiUrls = Syringe.inject("apiUrls");

const zuulCacheableOrderBox = `${apiUrls.cacheableApiHost}api/`;
const bclCacheableOrderBox = withCompat(apiUrls.cacheableApiHost, "api");
const zuulCacheableOrderBoxV2 = `${apiUrls.cacheableApiHost}v2/`;
const zuulOrderBox = `${apiUrls.apiHost}api/`;
const bclOrderBox = withCompat(apiUrls.apiHost, "api");
const zuulOrderBoxV2 = `${apiUrls.apiHost}v2/`;
const bclOrderBoxV2 = withCompat(apiUrls.apiHost, "v2");
const zuulOrderBoxRest = `${apiUrls.apiHost}restapi/`;
const bclOrderBoxRest = withCompat(apiUrls.apiHost, "restapi");
const elastic = `${apiUrls.searchURL}`;

const reseller = {
  verify_password: `${zuulOrderBox}reseller/verify-current-password`,
  pricing_details_productkey: `${zuulCacheableOrderBox}products/reseller-cost-price.json?product-key={productkey}`,
  pricing_details: `${zuulCacheableOrderBox}products/reseller-cost-price.json`,
  send_otp: `${zuulOrderBox}reseller/generate-otp-username-change`,
  verify_otp: `${zuulOrderBox}resellers/authenticate/verify-otp`
};

const customer = {
  verify_password: `${zuulOrderBox}customers/verify-current-password`,
  pricing_details_productkey: `${bclCacheableOrderBox}products/customer-price.json?product-key={productkey}`,
  pricing_details: `${bclCacheableOrderBox}products/customer-price.json`,
  send_otp: `${zuulOrderBox}customers/authenticate/generate-otp`,
  verify_otp: `${zuulOrderBox}customers/authenticate/verify-otp`
};

const overrides =
  process.env.FEATURE_ENDPOINT === "reseller" ? reseller : customer;

export default {
  apiHost,
  experience,
  embassy: "/embassy/",
  kong: "/kong/",
  logout: `${webpro}logout`,
  change_prefix: `${zuulOrderBox}resellers/set-prefix`,
  es_search: `${elastic}v1.basket/_search`,
  es_transactions: `${elastic}v1.transactions/_search`,
  webhooks,

  legal_agreements: `${zuulOrderBox}commons/legal-agreements`,

  send_sms_otp: `${zuulOrderBox}resellers/authenticate/generate-otp`,
  send_email_otp: `${zuulOrderBox}resellers/authenticate/generate-email-otp`,
  verify_email_otp: `${zuulOrderBox}resellers/authenticate/verify-email-otp`,

  // Common
  get_plan_details: `${bclCacheableOrderBox}products/plan-details.json?product-key={productKey}`,
  get_branded_url: `${bclOrderBoxV2}branded-urls/`,

  domain: `${zuulOrderBox}domains/`,
  dns: `${zuulOrderBox}dns/manage/`,
  ssl: `${zuulOrderBox}sslcert/`,

  // Billing and transactions
  pay_pending_invoice: `${zuulOrderBox}billing/reseller-pay`,
  get_order_details_with_orderid: `${zuulOrderBox}orders/get-order-info`,
  current_actions: `${zuulOrderBox}actions/search-current`,
  archived_actions: `${zuulOrderBox}actions/search-archived`,
  start_paypal_flow: `${billing}paypal-flow`,

  common,
  customer_details: `${zuulOrderBoxV2}reseller/customers/`,
  // Customer requests
  send_mail: `${apiHost}request/send-request-mail`,

  webpro,
  billing,
  // RC
  buy_order: `${common}buy-from-rc-link`,

  paypal_agreement_hook: `${webhooks}create-paypal-agreement`,

  add_card_link: `${billing}add-card-link`,
  add_card_hook: `${webhooks}add-card-status`,

  transaction_pdf: `${billing}get-transaction-pdf`,

  // BillingPanda
  billingPandaV2: `${zuulOrderBoxV2}billing/custom-invoices/`,
  billingSystemV2: `${zuulOrderBoxV2}billing/system-transactions/CUSTOMER/`,
  activeBillingTemplate: `${zuulOrderBoxV2}billing/custom-invoices/template`,

  bclOrderBox,
  bclOrderBoxV2,
  bclCacheableOrderBox,
  bclOrderBoxRest,
  zuulCacheableOrderBox,
  zuulCacheableOrderBoxV2,
  zuulOrderBox,
  zuulOrderBoxV2,
  zuulOrderBoxRest,

  // Obox Cron
  cron_entity_action: `${zuulOrderBoxV2}entity-action/`,

  user,
  get_user_details: `${user}details`,
  pay_invoice: `${zuulOrderBox}billing/customer-pay`,

  // CCP Microservices
  product: `/api/products/v2/`,
  support: `/api/support/v2/`,
  ...overrides
};
