import { withInlineNotifications } from "common/inline-notifications/ducks";
import { WPSelect } from "common/listbox/dropdown";
import WPModal from "common/modal/index";
import { WPButton } from "common/modal/styles";
import { styled } from "linaria/react";
import { css } from "linaria";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LanguageDropdownTick from "assets/icons/language-dropdown-tick";
import "widgets/widgets.scss";
import "./modals.scss";
import { actions as detailsActions } from "common/profile-details/ducks";
import { connect } from "react-redux";
import { oboxLanguageReverseMap, oboxLanguageMap } from "i18n";

let dropDownStyles = css`
  padding: 10px 18px 9px;
  &:nth-child(2) {
    margin-top: 12px;
  }
`;

const LanguageSwitcherModalBody = styled.div`
  padding-top: 30px;
  margin-bottom: 0;
  .language-subtext {
    font-size: 13px;
  }
  .list-box {
    margin-top: 10px;
    > div {
      border-radius: 2px;
      padding: 12px 15px 11px 13px;
      &[data-expanded="true"] {
        border: 1px solid #468def;
      }
      &[data-expanded="true"]::before {
        content: none;
      }
    }
    > div > span > span {
      font-size: 14px;
      font-weight: normal;
    }
    .checkIcon {
      display: none;
    }
  }

  .options-popover {
    > div {
      border-bottom: none;
      &[aria-selected="true"] {
        > span > span {
          font-weight: 600;
        }
      }
    }
    > div > span > span {
      font-size: 14px;
      font-weight: normal;
    }
  }
  .checkIcon {
    position: absolute;
    right: 15px;
    top: 13px;
  }
  .save-language-button {
    height: 39px;
    width: 88px;
    margin-top: 40px;
  }
`;
const LanguageSwitcher = ({
  availableLanguages,
  showNotification,
  changeLanguage,
  profileInfo,
  ...props
}) => {
  const { i18n, t } = useTranslation();
  const [languageKey, setLanguageKey] = useState(i18n.language);
  const languageKeys = Object.keys(availableLanguages);
  const { langpref } = profileInfo;
  let defaultLanguageIndex = languageKeys.indexOf(
    oboxLanguageReverseMap[langpref]
  );

  return (
    <WPModal
      show={props.show}
      title={t("PREF_LANG", "SELECT YOUR PREFERRED LANGUAGE")}
      onHide={props.onHide}
      width={443}
      mainContent={
        <LanguageSwitcherModalBody>
          <span className="language-subtext">
            {t("SELECT_LANG", "Select Language")}
          </span>
          <WPSelect
            items={languageKeys.map((item, index) => (
              <span key={index}>
                {availableLanguages[item]}
                {item === oboxLanguageReverseMap[langpref] && (
                  <LanguageDropdownTick className="checkIcon" />
                )}
              </span>
            ))}
            onChange={selectedIndex => {
              setLanguageKey(languageKeys[selectedIndex]);
            }}
            width={331}
            dropDownStyles={dropDownStyles}
            defaultSelectedIndex={defaultLanguageIndex}
          />

          <WPButton
            type="button"
            className="save-language-button"
            onClick={() => {
              changeLanguage({
                ...profileInfo,
                langpref: oboxLanguageMap[languageKey]
              });
              i18n
                .changeLanguage(languageKey)
                .then(() =>
                  showNotification(
                    t(
                      "LANG_CHANGE_SUCCESS",
                      `Language changed to ${availableLanguages[languageKey]} successfully.`,
                      { languageKey: availableLanguages[languageKey] }
                    )
                  )
                )
                .then(props.onHide);
            }}
            disabled={languageKey === langpref}
          >
            {t("SIMPLE_SAVE", "Save")}
          </WPButton>
        </LanguageSwitcherModalBody>
      }
    />
  );
};

const mapStateToProps = state => {
  const { details } = state;
  return { profileInfo: details.profileInfo };
};
const mapDispatchToProps = {
  changeLanguage: detailsActions.modifyDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withInlineNotifications(LanguageSwitcher));
