import ErrorBoundary from "common/errors/boundary";
import history from "common/history";
import { ListInvoicesContext } from "common/invoices/hooks/list-invoices-context";
import { ErrorPage } from "common/maintenance";
import ScrollToTop from "common/scroll-top";
import { StorageProvider } from "common/storage/hooks";
import { showLoader } from "common/util";
import React, { lazy, Suspense } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Root from "./routes/root";
import { getRoutesByRole, getUnAuthenticatedRoutes } from "./routes";
import { BrandConfigurationOperations } from "./common/brand-configuration";
const Login = lazy(() =>
  import(/* webpackChunkName: "login" */ "./routes/login")
);

class AppContainer extends React.Component {
  static mapStateToProps = state => ({
    role: state.root.userInfo.role,
    brandConfig: BrandConfigurationOperations.findById(
      state.root.brandingDetails.parentId
    ),
    isLoggedIn: state.root.isLoggedIn
  });

  shouldComponentUpdate(nextProps) {
    return nextProps.isLoggedIn !== this.props.isLoggedIn;
  }

  render() {
    const { role, brandConfig, isLoggedIn } = this.props;
    const isAdamUrlPresent =
      (brandConfig?.urls?.storefront?.adamUrl || "").length > 0;
    return (
      <StorageProvider>
        <ListInvoicesContext>
          <div style={{ height: "100%" }}>
            <Suspense fallback={showLoader}>
              <Router history={history}>
                <ScrollToTop>
                  <ErrorBoundary fallback={<ErrorPage />}>
                    <Switch>
                      {getUnAuthenticatedRoutes()}
                      <Root key={"root"}>
                        <Route path="/login" component={Login} />
                        {isLoggedIn ? (
                          <Switch>
                            {getRoutesByRole(role, isAdamUrlPresent)}
                          </Switch>
                        ) : null}
                      </Root>
                    </Switch>
                  </ErrorBoundary>
                </ScrollToTop>
              </Router>
            </Suspense>
          </div>
        </ListInvoicesContext>
      </StorageProvider>
    );
  }
}

export default connect(AppContainer.mapStateToProps, null)(AppContainer);
