import { User } from "common/sdk/user";
import { addQueryParamsToUrl } from "common/urls";
import useSWRMutation from "swr/mutation";

function useAutoLoginHelpDesk() {
  let autoLoginToHelpdesk = useSWRMutation("helpdesk-autologin", async () => {
    let { TOKEN: token } = await User.token();
    window.open(
      addQueryParamsToUrl("https://helpdesk.resellerclub.com/authenticate", {
        token,
        role: "reseller"
      }),
      "_blank"
    );
  });
  return [
    autoLoginToHelpdesk.trigger,
    { isFetching: autoLoginToHelpdesk.isMutating }
  ] as const;
}

export default function AutoLoginHelpDesk({ children }) {
  let [autoLoginToHelpdesk, helpDeskStatus] = useAutoLoginHelpDesk();
  return children({ autoLoginToHelpdesk, helpDeskStatus });
}
