import React from "react";
import { HtmlParserRenderProps } from "common/html-parser";
const countries = [
  { country_short: "AF", country: "Afghanistan", country_code: "93" },
  { country_short: "AL", country: "Albania", country_code: "355" },
  { country_short: "DZ", country: "Algeria", country_code: "213" },
  { country_short: "AS", country: "American Samoa", country_code: "1" },
  { country_short: "AD", country: "Andorra", country_code: "376" },
  { country_short: "AO", country: "Angola", country_code: "244" },
  { country_short: "AI", country: "Anguilla", country_code: "1" },
  { country_short: "AQ", country: "Antarctica", country_code: "" },
  { country_short: "AG", country: "Antigua And Barbuda", country_code: "1" },
  { country_short: "AR", country: "Argentina", country_code: "54" },
  { country_short: "AM", country: "Armenia", country_code: "374" },
  { country_short: "AW", country: "Aruba", country_code: "297" },
  { country_short: "AU", country: "Australia", country_code: "61" },
  { country_short: "AT", country: "Austria", country_code: "43" },
  { country_short: "AZ", country: "Azerbaijan", country_code: "994" },
  { country_short: "BS", country: "Bahamas", country_code: "1" },
  { country_short: "BH", country: "Bahrain", country_code: "973" },
  { country_short: "BD", country: "Bangladesh", country_code: "880" },
  { country_short: "BB", country: "Barbados", country_code: "1" },
  { country_short: "BY", country: "Belarus", country_code: "375" },
  { country_short: "BE", country: "Belgium", country_code: "32" },
  { country_short: "BZ", country: "Belize", country_code: "501" },
  { country_short: "BJ", country: "Benin", country_code: "229" },
  { country_short: "BM", country: "Bermuda", country_code: "1" },
  { country_short: "BT", country: "Bhutan", country_code: "975" },
  { country_short: "BO", country: "Bolivia", country_code: "591" },
  {
    country_short: "BA",
    country: "Bosnia and Herzegovina",
    country_code: "387"
  },
  { country_short: "BW", country: "Botswana", country_code: "267" },
  { country_short: "BV", country: "Bouvet Island", country_code: "" },
  { country_short: "BR", country: "Brazil", country_code: "55" },
  {
    country_short: "IO",
    country: "British Indian Ocean Territory",
    country_code: "246"
  },
  { country_short: "BN", country: "Brunei", country_code: "673" },
  { country_short: "BG", country: "Bulgaria", country_code: "359" },
  { country_short: "BF", country: "Burkina Faso", country_code: "226" },
  { country_short: "BI", country: "Burundi", country_code: "257" },
  { country_short: "KH", country: "Cambodia", country_code: "855" },
  { country_short: "CM", country: "Cameroon", country_code: "237" },
  { country_short: "CA", country: "Canada", country_code: "1" },
  { country_short: "CV", country: "Cape Verde", country_code: "238" },
  { country_short: "KY", country: "Cayman Islands", country_code: "1" },
  {
    country_short: "CF",
    country: "Central African Republic",
    country_code: "236"
  },
  { country_short: "TD", country: "Chad", country_code: "235" },
  { country_short: "CL", country: "Chile", country_code: "56" },
  { country_short: "CN", country: "China", country_code: "86" },
  { country_short: "CX", country: "Christmas Island", country_code: "61" },
  {
    country_short: "CC",
    country: "Cocos (Keeling) Islands",
    country_code: "61"
  },
  { country_short: "CO", country: "Colombia", country_code: "57" },
  { country_short: "KM", country: "Comoros", country_code: "269" },
  { country_short: "CG", country: "Congo", country_code: "242" },
  {
    country_short: "CD",
    country: "Congo, Democractic Republic",
    country_code: "243"
  },
  { country_short: "CK", country: "Cook Islands", country_code: "682" },
  { country_short: "CR", country: "Costa Rica", country_code: "506" },
  {
    country_short: "CI",
    country: "Cote D'Ivoire (Ivory Coast)",
    country_code: "225"
  },
  { country_short: "HR", country: "Croatia (Hrvatska)", country_code: "385" },
  { country_short: "CW", country: "Curacao", country_code: "599" },
  { country_short: "CY", country: "Cyprus ", country_code: "357" },
  { country_short: "CZ", country: "Czech Republic", country_code: "420" },
  { country_short: "DK", country: "Denmark", country_code: "45" },
  { country_short: "DJ", country: "Djibouti", country_code: "253" },
  { country_short: "DM", country: "Dominica", country_code: "1" },
  { country_short: "DO", country: "Dominican Republic", country_code: "1" },
  { country_short: "TP", country: "East Timor", country_code: "" },
  { country_short: "EC", country: "Ecuador", country_code: "593" },
  { country_short: "EG", country: "Egypt", country_code: "20" },
  { country_short: "SV", country: "El Salvador", country_code: "503" },
  { country_short: "GQ", country: "Equatorial Guinea", country_code: "240" },
  { country_short: "ER", country: "Eritrea", country_code: "291" },
  { country_short: "EE", country: "Estonia", country_code: "372" },
  { country_short: "ET", country: "Ethiopia", country_code: "251" },
  {
    country_short: "FK",
    country: "Falkland Islands (Islas Malvinas)",
    country_code: "500"
  },
  { country_short: "FO", country: "Faroe Islands", country_code: "298" },
  { country_short: "FJ", country: "Fiji Islands", country_code: "679" },
  { country_short: "FI", country: "Finland", country_code: "358" },
  { country_short: "FR", country: "France", country_code: "33" },
  { country_short: "FX", country: "France", country_code: " Metropolitan" },
  { country_short: "GF", country: "French Guiana", country_code: "594" },
  { country_short: "PF", country: "French Polynesia", country_code: "689" },
  {
    country_short: "TF",
    country: "French Southern Territories",
    country_code: ""
  },
  { country_short: "GA", country: "Gabon", country_code: "241" },
  { country_short: "GM", country: "Gambia, The", country_code: "220" },
  { country_short: "GE", country: "Georgia", country_code: "995" },
  { country_short: "DE", country: "Germany", country_code: "49" },
  { country_short: "GH", country: "Ghana", country_code: "233" },
  { country_short: "GI", country: "Gibraltar", country_code: "350" },
  { country_short: "GR", country: "Greece", country_code: "30" },
  { country_short: "GL", country: "Greenland", country_code: "299" },
  { country_short: "GD", country: "Grenada", country_code: "1" },
  { country_short: "GP", country: "Guadeloupe", country_code: "590" },
  { country_short: "GU", country: "Guam", country_code: "1" },
  { country_short: "GT", country: "Guatemala", country_code: "502" },
  { country_short: "GG", country: "Guernsey", country_code: "44" },
  { country_short: "GN", country: "Guinea", country_code: "224" },
  { country_short: "GW", country: "Guinea-Bissau", country_code: "245" },
  { country_short: "GY", country: "Guyana", country_code: "592" },
  { country_short: "HT", country: "Haiti", country_code: "509" },
  {
    country_short: "HM",
    country: "Heard and McDonald Islands",
    country_code: ""
  },
  { country_short: "HN", country: "Honduras", country_code: "504" },
  { country_short: "HK", country: "Hong Kong S.A.R.", country_code: "852" },
  { country_short: "HU", country: "Hungary", country_code: "36" },
  { country_short: "IS", country: "Iceland", country_code: "354" },
  { country_short: "IN", country: "India", country_code: "91" },
  { country_short: "ID", country: "Indonesia", country_code: "62" },
  { country_short: "IQ", country: "Iraq", country_code: "964" },
  { country_short: "IE", country: "Ireland", country_code: "353" },
  { country_short: "IM", country: "Isle of Man", country_code: "44" },
  { country_short: "IL", country: "Israel", country_code: "972" },
  { country_short: "IT", country: "Italy", country_code: "39" },
  { country_short: "JM", country: "Jamaica", country_code: "1" },
  { country_short: "JP", country: "Japan", country_code: "81" },
  { country_short: "JE", country: "Jersey", country_code: "44" },
  { country_short: "JO", country: "Jordan", country_code: "962" },
  { country_short: "KZ", country: "Kazakhstan", country_code: "7" },
  { country_short: "KE", country: "Kenya", country_code: "254" },
  { country_short: "KI", country: "Kiribati", country_code: "686" },
  { country_short: "KR", country: "Korea", country_code: "82" },
  { country_short: "KO", country: "Kosovo ", country_code: "383" },
  { country_short: "KW", country: "Kuwait", country_code: "965" },
  { country_short: "KG", country: "Kyrgyzstan", country_code: "996" },
  { country_short: "LA", country: "Laos", country_code: "856" },
  { country_short: "LV", country: "Latvia", country_code: "371" },
  { country_short: "LB", country: "Lebanon", country_code: "961" },
  { country_short: "LS", country: "Lesotho", country_code: "266" },
  { country_short: "LR", country: "Liberia", country_code: "231" },
  { country_short: "LY", country: "Libya", country_code: "218" },
  { country_short: "LI", country: "Liechtenstein", country_code: "423" },
  { country_short: "LT", country: "Lithuania", country_code: "370" },
  { country_short: "LU", country: "Luxembourg ", country_code: "352" },
  { country_short: "MO", country: "Macau S.A.R.", country_code: "853" },
  { country_short: "MK", country: "Macedonia", country_code: "389" },
  { country_short: "MG", country: "Madagascar", country_code: "261" },
  { country_short: "MW", country: "Malawi", country_code: "265" },
  { country_short: "MY", country: "Malaysia", country_code: "60" },
  { country_short: "MV", country: "Maldives", country_code: "960" },
  { country_short: "ML", country: "Mali", country_code: "223" },
  { country_short: "MT", country: "Malta", country_code: "356" },
  { country_short: "MH", country: "Marshall Islands", country_code: "692" },
  { country_short: "MQ", country: "Martinique", country_code: "596" },
  { country_short: "MR", country: "Mauritania", country_code: "222" },
  { country_short: "MU", country: "Mauritius", country_code: "230" },
  { country_short: "YT", country: "Mayotte", country_code: "262" },
  { country_short: "MX", country: "Mexico", country_code: "52" },
  { country_short: "FM", country: "Micronesia", country_code: "691" },
  { country_short: "MD", country: "Moldova", country_code: "373" },
  { country_short: "MC", country: "Monaco", country_code: "377" },
  { country_short: "MN", country: "Mongolia", country_code: "976" },
  { country_short: "ME", country: "Montenegro", country_code: "382" },
  { country_short: "MS", country: "Montserrat", country_code: "1" },
  { country_short: "MA", country: "Morocco", country_code: "212" },
  { country_short: "MZ", country: "Mozambique", country_code: "258" },
  { country_short: "MM", country: "Myanmar", country_code: "95" },
  { country_short: "NA", country: "Namibia", country_code: "264" },
  { country_short: "NR", country: "Nauru", country_code: "674" },
  { country_short: "NP", country: "Nepal", country_code: "977" },
  { country_short: "NL", country: "Netherlands", country_code: "31" },
  { country_short: "AN", country: "Netherlands Antilles", country_code: " " },
  { country_short: "NC", country: "New Caledonia", country_code: "687" },
  { country_short: "NZ", country: "New Zealand", country_code: "64" },
  { country_short: "NI", country: "Nicaragua", country_code: "505" },
  { country_short: "NE", country: "Niger", country_code: "227" },
  { country_short: "NG", country: "Nigeria", country_code: "234" },
  { country_short: "NU", country: "Niue", country_code: "683" },
  { country_short: "NF", country: "Norfolk Island", country_code: "672" },
  {
    country_short: "MP",
    country: "Northern Mariana Islands",
    country_code: "1"
  },
  { country_short: "NO", country: "Norway", country_code: "47" },
  { country_short: "OM", country: "Oman", country_code: "968" },
  { country_short: "PK", country: "Pakistan", country_code: "92" },
  { country_short: "PW", country: "Palau", country_code: "680" },
  {
    country_short: "PS",
    country: "Palestinian Territory, Occupied",
    country_code: "970"
  },
  { country_short: "PA", country: "Panama", country_code: "507" },
  { country_short: "PG", country: "Papua new Guinea", country_code: "675" },
  { country_short: "PY", country: "Paraguay", country_code: "595" },
  { country_short: "PE", country: "Peru", country_code: "51" },
  { country_short: "PH", country: "Philippines", country_code: "63" },
  { country_short: "PN", country: "Pitcairn Island", country_code: "" },
  { country_short: "PL", country: "Poland", country_code: "48" },
  { country_short: "PT", country: "Portugal", country_code: "351" },
  { country_short: "PR", country: "Puerto Rico", country_code: "1" },
  { country_short: "QA", country: "Qatar", country_code: "974" },
  { country_short: "RE", country: "Reunion", country_code: "262" },
  { country_short: "RO", country: "Romania", country_code: "40" },
  { country_short: "RU", country: "Russia", country_code: "7" },
  { country_short: "RW", country: "Rwanda", country_code: "250" },
  { country_short: "SH", country: "Saint Helena", country_code: "290" },
  { country_short: "KN", country: "Saint Kitts And Nevis", country_code: "1" },
  { country_short: "LC", country: "Saint Lucia", country_code: "1" },
  {
    country_short: "PM",
    country: "Saint Pierre and Miquelon",
    country_code: "508"
  },
  {
    country_short: "VC",
    country: "Saint Vincent And The Grenadines",
    country_code: "1"
  },
  { country_short: "WS", country: "Samoa", country_code: "685" },
  { country_short: "SM", country: "San Marino", country_code: "378" },
  {
    country_short: "ST",
    country: "Sao Tome and Principe",
    country_code: "239"
  },
  { country_short: "SA", country: "Saudi Arabia", country_code: "966" },
  { country_short: "SN", country: "Senegal", country_code: "221" },
  { country_short: "RS", country: "Serbia", country_code: "381" },
  { country_short: "CS", country: "Serbia and Montenegro", country_code: "" },
  { country_short: "SC", country: "Seychelles", country_code: "248" },
  { country_short: "SL", country: "Sierra Leone", country_code: "232" },
  { country_short: "SG", country: "Singapore", country_code: "65" },
  { country_short: "SX", country: "Sint Maarten", country_code: "1" },
  { country_short: "SK", country: "Slovakia", country_code: "421" },
  { country_short: "SI", country: "Slovenia", country_code: "386" },
  { country_short: "SB", country: "Solomon Islands", country_code: "677" },
  { country_short: "SO", country: "Somalia", country_code: "252" },
  { country_short: "ZA", country: "South Africa ", country_code: "27" },
  {
    country_short: "GS",
    country: "South Georgia And The South Sandwich Islands",
    country_code: ""
  },
  { country_short: "ES", country: "Spain", country_code: "34" },
  { country_short: "LK", country: "Sri Lanka", country_code: "94" },
  { country_short: "SD", country: "Sudan", country_code: "249" },
  { country_short: "SR", country: "Suriname", country_code: "597" },
  {
    country_short: "SJ",
    country: "Svalbard And Jan Mayen Islands",
    country_code: "47"
  },
  { country_short: "SZ", country: "Swaziland", country_code: "268" },
  { country_short: "SE", country: "Sweden", country_code: "46" },
  { country_short: "CH", country: "Switzerland", country_code: "41" },
  { country_short: "TW", country: "Taiwan", country_code: "886" },
  { country_short: "TJ", country: "Tajikistan", country_code: "992" },
  { country_short: "TZ", country: "Tanzania", country_code: "255" },
  { country_short: "TH", country: "Thailand", country_code: "66" },
  { country_short: "TL", country: "Timor-Leste", country_code: "670" },
  { country_short: "TG", country: "Togo", country_code: "228" },
  { country_short: "TK", country: "Tokelau", country_code: "690" },
  { country_short: "TO", country: "Tonga", country_code: "676" },
  { country_short: "TT", country: "Trinidad And Tobago", country_code: "1" },
  { country_short: "TN", country: "Tunisia", country_code: "216" },
  { country_short: "TR", country: "Turkey", country_code: "90" },
  { country_short: "TM", country: "Turkmenistan", country_code: "993" },
  {
    country_short: "TC",
    country: "Turks And Caicos Islands",
    country_code: "1"
  },
  { country_short: "TV", country: "Tuvalu", country_code: "688" },
  { country_short: "UG", country: "Uganda", country_code: "256" },
  { country_short: "UA", country: "Ukraine", country_code: "380" },
  { country_short: "AE", country: "United Arab Emirates", country_code: "971" },
  { country_short: "GB", country: "United Kingdom", country_code: "44" },
  { country_short: "US", country: "United States", country_code: "1" },
  {
    country_short: "UM",
    country: "United States Minor Outlying Islands",
    country_code: ""
  },
  { country_short: "UY", country: "Uruguay", country_code: "598" },
  { country_short: "UZ", country: "Uzbekistan", country_code: "998" },
  { country_short: "VU", country: "Vanuatu", country_code: "678" },
  {
    country_short: "VA",
    country: "Vatican City State (Holy See)",
    country_code: "379"
  },
  { country_short: "VE", country: "Venezuela", country_code: "58" },
  { country_short: "VN", country: "Vietnam", country_code: "84" },
  {
    country_short: "VG",
    country: "Virgin Islands (British)",
    country_code: "1"
  },
  { country_short: "VI", country: "Virgin Islands (US)", country_code: "1" },
  { country_short: "EH", country: "WESTERN SAHARA", country_code: "212" },
  {
    country_short: "WF",
    country: "Wallis And Futuna Islands",
    country_code: "681"
  },
  { country_short: "YE", country: "Yemen", country_code: "967" },
  { country_short: "YU", country: "Yugoslavia", country_code: "" },
  { country_short: "ZM", country: "Zambia", country_code: "260" },
  { country_short: "ZW", country: "Zimbabwe", country_code: "263" },
  { country_short: "AX", country: "Åland Islands", country_code: "358" }
];

export const getCountry = countryCode =>
  countries.find(countryInfo => countryInfo.country_code === countryCode);

export const getCountryFullName = countryShort =>
  countries.find(countryInfo => countryInfo.country_short === countryShort);

export const getCountryCode = country =>
  countries.find(countryInfo => countryInfo.country === country);

export const countryOptions = (
  <HtmlParserRenderProps>
    {parser =>
      countries.map((item, index) => (
        <option key={`country_${index}`} value={item.country_short}>
          {parser(item.country)}
        </option>
      ))
    }
  </HtmlParserRenderProps>
);

export default countries;
