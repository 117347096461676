import api from "api";
import endpoints from "constants/endpoints";

export const services = {
  modifyPrivacyProtection: ({ orderid }, isEnabled) =>
    api.post(`${endpoints.domain}modify-privacy-protection`, {
      "order-id": orderid,
      "protect-privacy": isEnabled,
      reason: "webpro-no-reason"
    }),
  disableFreePrivacyProtection: ({ orderid }) =>
    api.post(`${endpoints.domain}gdpr/disable`, { "order-id": orderid }),
  enableFreePrivacyProtection: ({ orderid }) =>
    api.post(`${endpoints.domain}gdpr/enable`, { "order-id": orderid }),
  getOrderWithoutPrivacyProtection: () =>
    api.get(`${endpoints.domain}search`, {
      "no-of-records": 50,
      "page-no": 1,
      "privacy-enabled": false,
      status: "Active"
    })
};
