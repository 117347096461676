import { getSimpleNameFromProductKey } from "constants/index";
import { EmailStrategy } from "./email";
import { BasePricingStrategy } from "./generic";
import { GsuiteStrategy } from "./gsuite";
import { PricingStrategy } from "./index";
import { TitanStrategy } from "./titanmail";

export function getPricingStrategy(productKey: string): PricingStrategy {
  switch (getSimpleNameFromProductKey(productKey)) {
    case "gsuite":
      return new GsuiteStrategy();
    case "titanmail":
      return new TitanStrategy();
    case "enterpriseemail":
    case "businessemail":
      return new EmailStrategy();
    default:
      return new BasePricingStrategy();
  }
}
