import { mapEffect } from "map-effect";
import { takeEvery } from "redux-saga/effects";
import { actionTypes } from "./ducks";
import { services } from "./services";

export default [takeEvery(actionTypes.DELETE_BLOCKS, mapEffect(deleteBlocks))];

function deleteBlocks(action) {
  return services.deleteBlocks(action.payload);
}
