import InvoiceOptions from "common/invoice-options";
import { getPricingStrategy } from "common/pricing-strategy/factory";
import { SimpleName } from "constants/index";
import { getPlatformFromProductName, platform } from "constants/platform";
import * as React from "react";

export const TenureOptions: React.FC<{ pricing: any; unit: string }> = ({
  pricing,
  unit
}): any =>
  Object.keys(pricing).map((tenure) => (
    <option value={tenure} key={`${unit}${tenure}`}>
      {tenure} {unit}
    </option>
  ));

export const getMinimumTenure = (pricing = {}) =>
  Object.keys(pricing).sort()[0];

export const getDomainName = (orderid: string, renewalDataByOrderID: any) => {
  let renewalData = renewalDataByOrderID?.[orderid] ?? {};
  return (
    renewalData?.restoreDetails?.domainname ??
    renewalData?.orderDetails?.domainname ??
    ""
  );
};

export const getCostStrategy = (orderid: string, renewalDataByOrderID: any) => {
  return getPricingStrategy(
    renewalDataByOrderID[orderid]?.orderDetails.keyToGetProductDetails
  );
};
export const getSelectedTenure = (
  orderid: string,
  renewalDataByOrderID: any,
  modifiedRenewalDetails: any
) => {
  return (
    modifiedRenewalDetails?.[orderid]?.selectedTenure ??
    renewalDataByOrderID?.[orderid]?.selected_tenure
  );
};

export const getRenewalPrice = (
  orderid: string,
  renewalDataByOrderID: any,
  modifiedRenewalDetails: any
) => {
  return getCostStrategy(orderid, renewalDataByOrderID).getPrice(
    modifiedRenewalDetails[orderid],
    renewalDataByOrderID[orderid],
    getSelectedTenure(orderid, renewalDataByOrderID, modifiedRenewalDetails)
  );
};

type SharedHostingRenewal = {
  product: Extract<SimpleName, "sdh" | "mdh">;
  options: { ssl: boolean };
};

type ResellerHostingRenewal = {
  product: Extract<SimpleName, "rchosting">;
  options: { blocks: string | number };
};

type SimpleRenewal = {
  product: Extract<
    SimpleName,
    "wph" | "backorder" | "backup" | "bhvpshosting" | "wpcloud"
  >;
  options: {};
};

type DomainRenewal = {
  product: Extract<SimpleName, "domain">;
  options: {
    exp_date: string;
    privacy_protection: boolean;
    premium_dns: boolean;
    restore: boolean;
  };
};
type TitanRenewal = {
  product: Extract<SimpleName, "titanmail">;
  options: {
    noOfAccounts: number;
    "total-accounts": number;
  };
};

type GenericHostingRenewal = { product: never; options: {} };

type RenewDetails = {
  autoRenew: boolean;
  orderId: string;
  tenure: number;
  productKey: string;
  role: Role;
} & (
  | SharedHostingRenewal
  | ResellerHostingRenewal
  | SimpleRenewal
  | DomainRenewal
  | GenericHostingRenewal
  | TitanRenewal
);

type DomainRenewalQuery = {
  "exp-date": string;
  "purchase-privacy": boolean;
  "purchase-premium-dns": boolean;
  years: number;
};
type TitanRenewalQuery = {
  "additional-info": string;
};

type RenewQuery = {
  accounts: number;
  "auto-renew": boolean;
  "backup-order-id": string;
  brand: "bluehost";
  "cpanel-blocks": number;
  "enable-ssl": boolean;
  "invoice-option": string;
  months: number;
  "order-id": string;
} & DomainRenewalQuery &
  TitanRenewalQuery;

export const getRenewQuery = (details: RenewDetails) => {
  let query: Partial<RenewQuery> = {
    "invoice-option":
      details.role === "reseller"
        ? InvoiceOptions.NO_INVOICE
        : InvoiceOptions.ONLY_ADD,
    "auto-renew": details.autoRenew,
    "order-id": details.orderId,
    months: details.tenure
  };
  switch (details.product) {
    case "sdh":
    case "mdh":
      query["enable-ssl"] = details.options.ssl;
      break;
    case "rchosting":
      if (getPlatformFromProductName(details.productKey) === platform.LINUX) {
        let blocks = Number(details.options.blocks);
        if (blocks > 0) {
          query["cpanel-blocks"] = blocks;
        }
      }
      break;
    case "domain":
      if (details.options.restore) {
        return {
          "order-id": details.orderId,
          "invoice-option": query["invoice-option"]
        };
      }
      query["exp-date"] = details.options.exp_date;
      query["purchase-premium-dns"] = details.options.premium_dns;
      query["purchase-privacy"] = details.options.privacy_protection;
      delete query.months;
      query.years = details.tenure;
      break;
    case "bhvpshosting":
      query.brand = "bluehost";
      break;
    case "backup":
      delete query["order-id"];
      query["backup-order-id"] = details.orderId;
      break;
    case "wph":
    case "wpcloud":
    case "backorder":
      return {
        "auto-renew": details.autoRenew,
        "invoice-option": query["invoice-option"]
      };
    case "titanmail":
      delete query["order-id"];
      delete query.months;
      const {
        noOfAccounts: existingEmailAccounts,
        "total-accounts": totalAccount
      } = details?.options;
      const addonAccounts = totalAccount - existingEmailAccounts;
      if (addonAccounts > 0) {
        query["additional-info"] = JSON.stringify({
          no_of_accounts: String(addonAccounts)
        });
      }
      break;
    default:
      break;
  }
  return query;
};

export const getBodyParams = (productkey: SimpleName) => {
  switch (productkey) {
    case "backup":
    case "backorder":
    case "wph":
    case "wpcloud":
    case "titanmail":
      return new URLSearchParams();
    default:
      return {};
  }
};
