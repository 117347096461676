import PropTypes from "prop-types";
import React, { useContext, useState } from "react";

export default function TabSwitcher(props) {
  let [selectedTab, onTabSwitch] = useState(props.initialValue);
  return props.render({
    selectedTab,
    onTabSwitch
  });
}

TabSwitcher.propTypes = {
  initialValue: PropTypes.any.isRequired,
  render: PropTypes.func.isRequired
};

let TabContext = React.createContext({
  selectedTab: "",
  setTab: _ => {}
});

export class Tabs extends React.Component {
  static propTypes = {
    initialValue: PropTypes.any.isRequired,
    children: PropTypes.node.isRequired
  };

  state = {
    selectedTab: null
  };

  setTab = selectedTab => {
    this.setState({ selectedTab });
    if (selectedTab === "PAYMENT") window.scrollTo(0, 355);
  };

  static getDerivedStateFromProps(props, state) {
    return state.selectedTab === null &&
      state.selectedTab !== props.initialValue
      ? {
          selectedTab: props.initialValue
        }
      : null;
  }

  render() {
    return (
      <TabContext.Provider
        value={{
          ...this.state,
          setTab: this.setTab
        }}
      >
        {this.props.children}
      </TabContext.Provider>
    );
  }
}

export function useTabs() {
  return useContext(TabContext);
}

export function Tab(props) {
  let { setTab, selectedTab } = useTabs();
  return props.children(setTab, selectedTab);
}

export function TabContent(props) {
  let { selectedTab } = useTabs();
  return props.activeWhen === selectedTab ? props.children : null;
}
