import { takeEvery, takeLatest, put } from "redux-saga/effects";
import { services } from "./services";
import { actions, actionTypes } from "./ducks";

export default [
  takeLatest(actionTypes.ENABLE_SHELL_ACCESS, onEnableShellAccess),
  takeEvery(actionTypes.DISABLE_SHELL_ACCESS, onDisableShellAccess)
];

export function* onEnableShellAccess(action) {
  try {
    yield services.enableShellAccess(action.payload);
    yield put(actions.enableSuccess(action.payload));
  } catch (error) {
    yield put(actions.enableFailure(error));
  }
}

export function* onDisableShellAccess(action) {
  try {
    yield services.disableShellAccess(action.payload);
    yield put(actions.disableSuccess(action.payload));
  } catch (error) {
    yield put(actions.disableFailure(error));
  }
}
