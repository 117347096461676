import { isSuccess, isFailure } from "common/api-status/utils";
export const actionTypes = {
  GET_AGREEMENT_TEXT: "[Settings] Fetch agreement text",

  SET_AGREEMENT_VIEW_STATUS: "[Settings] Set agreement view status",
  GET_PRIVACY_POICY_DETAILS: "[Settings] Fetch the privacy policy details",
  UPDATE_PRIVACY_POLICY: "[Settings] Update privacy policy"
};

export const actions = {
  getAgreementText(agreement, agreementType) {
    return {
      type: actionTypes.GET_AGREEMENT_TEXT,
      agreement,
      agreementType
    };
  },
  getAgreementTextSuccess(payload) {
    return {
      type: isSuccess(actionTypes.GET_AGREEMENT_TEXT),
      payload
    };
  },
  getAgreementTextFailure(error) {
    return {
      type: isFailure(actionTypes.GET_AGREEMENT_TEXT),
      error
    };
  },
  setAgreementViewStatus(value = "legal") {
    return {
      type: actionTypes.SET_AGREEMENT_VIEW_STATUS,
      value
    };
  },
  getPrivacyPolicyDetails({ agreementId }) {
    return {
      type: actionTypes.GET_PRIVACY_POICY_DETAILS,
      agreementId
    };
  },
  getPrivacyPolicyDetailsSuccess(payload) {
    return {
      type: isSuccess(actionTypes.GET_PRIVACY_POICY_DETAILS),
      payload
    };
  },
  getPrivacyPolicyDetailsFailure(error) {
    return {
      type: isFailure(actionTypes.GET_PRIVACY_POICY_DETAILS),
      error
    };
  },
  updatePrivacyPolicy(value = "") {
    return {
      type: actionTypes.UPDATE_PRIVACY_POLICY,
      value
    };
  },
  updatePrivacyPolicySuccess(payload) {
    return {
      type: isSuccess(actionTypes.UPDATE_PRIVACY_POLICY),
      payload
    };
  },
  updatePrivacyPolicyFailure(error) {
    return {
      type: isFailure(actionTypes.UPDATE_PRIVACY_POLICY),
      error
    };
  }
};

const initialState = {
  show: "",
  text: "",
  title: "",
  privacyPolicy: {
    isDefault: true,
    text: ""
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case isSuccess(actionTypes.GET_AGREEMENT_TEXT):
      return {
        ...state,
        ...action.payload
      };

    case actionTypes.SET_AGREEMENT_VIEW_STATUS:
      return {
        ...state,
        show: action.value
      };
    case isSuccess(actionTypes.GET_PRIVACY_POICY_DETAILS):
      return {
        ...state,
        ...action.payload
      };
    case isSuccess(actionTypes.UPDATE_PRIVACY_POLICY):
      return {
        ...state,
        privacyPolicy: {
          ...state.privacyPolicy,
          ...action.payload
        }
      };
    default:
      return state;
  }
};
