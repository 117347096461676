export const Size = {
  LARGE: "large",
  SMALL: "small",
  XSMALL: "xsmall"
};

export const SIZE_MAP = {
  large: "lg",
  medium: "md",
  small: "sm",
  xsmall: "xs",
  lg: "lg",
  md: "md",
  sm: "sm",
  xs: "xs"
};

export const DEVICE_SIZES = ["lg", "md", "sm", "xs"];

export const State = {
  SUCCESS: "success",
  WARNING: "warning",
  DANGER: "danger",
  INFO: "info"
};

export const Style = {
  DEFAULT: "default",
  PRIMARY: "primary",
  LINK: "link",
  INVERSE: "inverse"
};
