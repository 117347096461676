import { Syringe } from "@faizaanceg/syringe";
import { PaidInvoice, PendingInvoice } from "assets";
import classNames from "classnames";
import UpdateButton from "common/buttons/update";
import InvoiceOptions from "common/invoice-options";
import { formatAmountWithCurrency } from "common/localize-amount";
import WPModal from "common/modal";
import ModalHandler from "common/modal-handler";
import { parseStringAsBoolean } from "common/parse-string";
import {
  actionTypes as payActionTypes,
  actions as payActions
} from "common/pay-buttons/ducks";
import { WPBorderedRadioButton } from "common/radio-button/bordered-radio";
import { WalletsSdk } from "common/sdk/wallet";
import { colors, fonts } from "common/styles";
import { applyAll } from "common/util";
import { Form, Formik } from "formik";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useSWRMutation from "swr/mutation";
import { actionTypes, actions } from "./ducks";

let BPModalFor = styled.div`
  font: 400 12px/1.5 ${fonts.OpenSans};
  color: ${colors.gray.gray900};
`;

let BPModalContentWrapper = styled.div`
  margin: 20px 10px 0 10px;
  position: relative;
`;

let BPModalBorderWrapper = styled.div`
  padding: 17px 21px;
  border: 1px ${colors.gray.gray400} solid;
  border-radius: 2px;
  margin-top: 14px;
  display: flex;
`;

let BPModalQuestion = styled.div`
  line-height: 21px;
  font-weight: bold;
  font-family: brandon-grotesque;
  font-size: 13px;
  text-transform: uppercase;
`;

let BPModalRadioDescription = styled.div`
  font: 400 13px/1.5 ${fonts.OpenSans};
  color: ${colors.gray.gray700};
  margin-left: 25px;
  padding-right: 20px;
`;

let BPModalNote = styled.span`
  display: block;
  margin-top: 10px;
  color: ${colors.gray.gray900};
`;

function BillingPreferencesModal() {
  const user = useSelector(state => {
    return {
      sellingCurrencySymbol: state.details.profileInfo?.sellingcurrencysymbol,
      isWebPro: parseStringAsBoolean(state.details.profileInfo?.iswebpro)
    };
  });
  const dispatch = useDispatch();

  let handlePaidInvoices = useSWRMutation(
    "handle-paid-invoices",
    async (_, { arg: sellingPriceById }) => {
      for (let [id, price] of Object.entries<number>(sellingPriceById)) {
        let wallet = await WalletsSdk.customer.balance(id);
        if (wallet.totalsellingbalance === -1) {
          continue;
        }
        if (wallet.totalsellingbalance < price) {
          let txnKey = `AddFunds-C${id}-${Date.now()}`;
          let amount = Number(price)?.toFixed(2);
          await WalletsSdk.customer.addBalance({
            "customer-id": id,
            amount,
            description: `Add ${formatAmountWithCurrency({
              cost: price,
              currency: user.sellingCurrencySymbol,
              currencyDisplay: "symbol"
            })} to complete order purchase`,
            "transaction-type": "credit",
            "transaction-key": txnKey,
            "update-total-receipt": true
          });
        }
      }
    }
  );

  const { t } = useTranslation();
  return (
    <ModalHandler
      opensOn={actionTypes.OPEN_BILLING_PREFERENCES_MODAL}
      closesOn={[payActionTypes.PAY_WITH_WALLET]}
      render={(
        modalProps,
        {
          title,
          payButtonsInfo,
          totalCost,
          assignedDomain,
          currencySymbol,
          productkey,
          customerId,
          goBackToPrevState,
          sellingPriceByCustomerId
        }
      ) => {
        let sellingPrice = ` for ${formatAmountWithCurrency({
          cost: Number(sellingPriceByCustomerId?.[customerId] ?? 0),
          currency: user.sellingCurrencySymbol,
          currencyDisplay: "code"
        })} + Tax`;

        return (
          <WPModal
            show={modalProps.show}
            onHide={modalProps.onHide}
            alignCenter
            width={600}
            title={title}
            mainContent={
              <Formik
                initialValues={{
                  invoicePreferences: user.isWebPro
                    ? InvoiceOptions.KEEP_INVOICE
                    : InvoiceOptions.NO_INVOICE
                }}
                onSubmit={async values => {
                  if (
                    values.invoicePreferences === InvoiceOptions.PAY_INVOICE
                  ) {
                    await handlePaidInvoices.trigger(sellingPriceByCustomerId);
                  }
                  modalProps.onHide();
                  let invoiceOption = values.invoicePreferences;
                  let { value: mainActionData } = payButtonsInfo?.actionDetails;
                  if (Array.isArray(mainActionData)) {
                    payButtonsInfo.actionDetails.value = mainActionData.map(
                      action => ({
                        ...action,
                        params: {
                          ...action.params,
                          "invoice-option": invoiceOption
                        }
                      })
                    );
                  } else {
                    mainActionData.params["invoice-option"] = invoiceOption;
                  }
                  const actionDetails = {
                    cost: totalCost,
                    currencySymbol,
                    ...payButtonsInfo?.actionDetails
                  };
                  let constraints = Syringe.inject("userConstraints");
                  if (constraints.addFundsOnDemand) {
                    dispatch(
                      payActions.choosePayment({
                        actionDetails,
                        isPendingPayment: payButtonsInfo?.isPendingPayment,
                        transid: payButtonsInfo?.transid,
                        layoutInfo: payButtonsInfo?.layoutInfo,
                        startAndEndActionTypes:
                          payButtonsInfo?.startAndEndActionTypes,
                        title,
                        assignedDomain,
                        goBackToPrevState: () =>
                          dispatch(
                            actions.openBillingPreferencesModal({
                              payButtonsInfo,
                              totalCost,
                              assignedDomain,
                              title,
                              currencySymbol,
                              productkey,
                              customerId,
                              sellingPriceByCustomerId,
                              goBackToPrevState
                            })
                          )
                      })
                    );
                  } else {
                    dispatch(
                      payActions.payWithWallet(
                        actionDetails,
                        payButtonsInfo?.startAndEndActionTypes
                      )
                    );
                  }
                }}
              >
                {({ setFieldValue, values }) => {
                  let { value: mainActionData } = payButtonsInfo?.actionDetails;
                  let invoiceOption = values.invoicePreferences;
                  return (
                    <>
                      {assignedDomain && (
                        <BPModalFor>
                          {t("ODR_DNS_FOR", "For")}: {assignedDomain}
                        </BPModalFor>
                      )}
                      <BPModalContentWrapper>
                        {user.isWebPro && (
                          <BPModalQuestion>
                            {t(
                              "WANT_TO_BILL_CLIENT_FOR_SELLING_PRICE",
                              `How do you want to bill your client?
                            `
                            )}
                          </BPModalQuestion>
                        )}
                        <Form>
                          {!user.isWebPro && (
                            <BPModalQuestion>
                              {t(
                                "WANT_TO_BILL_CLIENT_FOR_SELLING_PRICE",
                                `Customer's Debit Account`
                              )}
                            </BPModalQuestion>
                          )}
                          <WPBorderedRadioButton
                            labelName={t(
                              "PAID_INVOICE",
                              `Create Paid Invoice${
                                mainActionData.length > 1
                                  ? `s for ${assignedDomain.toLowerCase()}`
                                  : sellingPrice
                              }`
                            )}
                            labelStyles={{
                              fontSize: "14px",
                              fontWeight: "600"
                            }}
                            variantColor="blue"
                            fieldName="invoicePreferences"
                            fieldValue={InvoiceOptions.PAY_INVOICE}
                            icon={PaidInvoice}
                            onChange={() =>
                              setFieldValue(
                                "invoicePreferences",
                                InvoiceOptions.PAY_INVOICE
                              )
                            }
                            appendRadioDescription={
                              <BPModalRadioDescription>
                                {t(
                                  "SELECT_PAID_INVOICE",
                                  "This will deduct the balance from the customer's debit account."
                                )}
                              </BPModalRadioDescription>
                            }
                          />
                          {!user.isWebPro && (
                            <BPModalQuestion style={{ paddingTop: "1em" }}>
                              {t(
                                "WANT_TO_BILL_CLIENT_FOR_SELLING_PRICE",
                                `Execute without payment`
                              )}
                            </BPModalQuestion>
                          )}
                          <WPBorderedRadioButton
                            labelName={t(
                              "PENDING_INVOICE",
                              `Create Pending Invoice${
                                mainActionData.length > 1
                                  ? `s for ${assignedDomain.toLowerCase()}`
                                  : sellingPrice
                              }`
                            )}
                            labelStyles={{
                              fontSize: "14px",
                              fontWeight: 600
                            }}
                            variantColor="blue"
                            fieldName="invoicePreferences"
                            fieldValue={InvoiceOptions.KEEP_INVOICE}
                            icon={PendingInvoice}
                            checked={
                              values.invoicePreferences ===
                              InvoiceOptions.KEEP_INVOICE
                            }
                            onChange={() =>
                              setFieldValue(
                                "invoicePreferences",
                                InvoiceOptions.KEEP_INVOICE
                              )
                            }
                            appendRadioDescription={
                              <BPModalRadioDescription>
                                {t(
                                  "PENDING_INVOICE_DESCRIPTION",
                                  "If your customer hasn't paid. The order will be active unless you decide to suspend it."
                                )}
                              </BPModalRadioDescription>
                            }
                          />
                          {!user.isWebPro && (
                            <WPBorderedRadioButton
                              labelName={t(
                                "CANCEL_INVOICE",
                                `Cancel Invoice(s)`
                              )}
                              labelStyles={{
                                fontSize: "14px",
                                fontWeight: "600"
                              }}
                              variantColor="blue"
                              fieldName="invoicePreferences"
                              fieldValue={InvoiceOptions.NO_INVOICE}
                              icon={PendingInvoice}
                              checked={
                                values.invoicePreferences ===
                                InvoiceOptions.NO_INVOICE
                              }
                              onChange={() =>
                                setFieldValue(
                                  "invoicePreferences",
                                  InvoiceOptions.NO_INVOICE
                                )
                              }
                              appendRadioDescription={
                                <BPModalRadioDescription>
                                  {t(
                                    "SELECT_CANCEL_INVOICE",
                                    "Choose this option if you do not want your customer to pay."
                                  )}
                                </BPModalRadioDescription>
                              }
                            />
                          )}
                          {user.isWebPro && (
                            <BPModalNote>
                              Note:{" "}
                              {mainActionData.length > 1
                                ? "It will create separate invoices for multiple orders."
                                : "Selling prices and tax for your customer can be configured from My Shop."}
                            </BPModalNote>
                          )}
                          <UpdateButton
                            data-testid="proceed-to-billing-references"
                            disabled={!invoiceOption}
                            className={classNames(
                              "btn-blue-bg",
                              css`
                                padding: 11px 18px !important;
                                margin-top: 30px;
                              `
                            )}
                            type="submit"
                          >
                            {t("PROCEED", "PROCEED")}
                          </UpdateButton>
                          {goBackToPrevState && (
                            <button
                              style={{ fontSize: "12px", padding: "11px" }}
                              type="button"
                              className="wp-btn-anti"
                              onClick={applyAll(
                                modalProps.onHide,
                                goBackToPrevState
                              )}
                            >
                              Back
                            </button>
                          )}
                        </Form>
                      </BPModalContentWrapper>
                    </>
                  );
                }}
              </Formik>
            }
          />
        );
      }}
    />
  );
}

export default BillingPreferencesModal;
