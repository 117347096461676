import api from "api";
import constants from "constants/endpoints";

export default {
  /**
   * @param {string | number} customerId
   * @description For usermode != reseller, this endpoint can be moved to /v2/customers/ once we start to use JWT based APIs
   */
  getCustomerDetails: customerId =>
    api.get(`${constants.customer_details}${customerId}`).then(response => {
      let {
        customerId,
        status,
        customerStatusDesc,
        pin,
        resellerId,
        twoFactorAuthEnabledPrivate,
        ...details
      } = response;
      return {
        ...details,
        customerid: customerId,
        customerstatus: status,
        customerstatusdesc: customerStatusDesc,
        pin: String(pin),
        resellerId: String(resellerId),
        twofactorauth_enabled: twoFactorAuthEnabledPrivate,
        useremail: details.username
      };
    }),
  getCustomersNewestFirst: ({ skip, status, userId }) =>
    api.get(`${constants.zuulOrderBoxV2}reseller/${userId}/customers`, {
      limit: 10,
      skip,
      status
    }),
  getPricingForProductKey: productKey =>
    api.get(constants.pricing_details, { "product-key": productKey }),
  getPremiumDomainPrice: domain =>
    api
      .get(`${constants.domain}premium-check`, { "domain-name": domain })
      .catch(() => ({ premium: "false", status: "SUCCESS" })),
  getBandID: params =>
    api.get(`${constants.zuulOrderBox}orders/get-band-id`, params)
};
