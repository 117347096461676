import { isSuccess } from "common/api-status/utils";
export const actionTypes = {
  GET_WALLET_INFO: "[Wallet] Get available balance",
  GET_TAX_INFO: "[Wallet] Get tax info"
};

const initialState = {
  currencySymbol: "",
  paymentGateways: [],
  paymentMethods: { paypalec: [], "pay.pw": [], "payu.in": [] },
  walletBalance: undefined,
  taxinfo: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case isSuccess(actionTypes.GET_WALLET_INFO): {
      const {
        sellingcurrencysymbol: myCurrencySymbol,
        availablebalance, // webpro uses this
        sellingcurrencybalance, // CCP uses this
        sellingcurrencylockedbalance: lockedFunds,
        ...otherBalanceInfo
      } = action.effectResponse;
      let currencySymbol =
        process.env.FEATURE_ENDPOINT !== "reseller"
          ? action.effectResponse.currencysymbol
          : action.effectResponse.parentsellingcurrencysymbol;

      return {
        ...state,
        currencySymbol,
        myCurrencySymbol,
        walletBalance:
          process.env.FEATURE_ENDPOINT === "bigrock"
            ? +sellingcurrencybalance
            : +availablebalance,
        lockedFunds,
        ...otherBalanceInfo
      };
    }

    case isSuccess(actionTypes.GET_TAX_INFO): {
      return {
        ...state,
        taxinfo: action.effectResponse
      };
    }
    default:
      return state;
  }
};

export const actions = {
  getWalletBalance: () => ({ type: actionTypes.GET_WALLET_INFO }),
  getTaxInfo: () => ({ type: actionTypes.GET_TAX_INFO })
};
