import React from "react";

export function CloseModal(props) {
  return (
    <svg width={20} height={20} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle stroke="#C5C5C5" cx={10} cy={10} r={9.5} />
        <path
          d="M11.207 10.207l3.535-3.535a.503.503 0 000-.708.505.505 0 00-.707 0L10.5 9.5 6.965 5.965a.503.503 0 00-.708 0 .505.505 0 000 .707l3.536 3.535-3.535 3.535a.503.503 0 000 .708c.189.19.511.195.707 0l3.535-3.536 3.535 3.536a.503.503 0 00.708 0 .505.505 0 000-.708l-3.536-3.535z"
          fill="#C5C5C5"
        />
      </g>
    </svg>
  );
}
