import api from "api";
import endpoints from "constants/endpoints";

const Endpoints = {
  CONSENTS: `${endpoints.zuulOrderBox}consents`
};

type Consent = {
  consentName: string;
  params: Record<string, string[]>;
};

export const ConsentSdk = {
  /**
   *
   * Records a new consent for the authenticated user.
   * Some consents require optional information.
   *
   * | Roles    |
   * | -------- |
   * | Reseller |
   *
   * #### Usage
   * ```js
   * await HttpApi.Consents.accept("gsuite-consent", { ip: "127.0.0.1" })
   * ```
   *
   */
  async accept(consent: string, params: Record<string, any> = {}) {
    return api.postBody<{ consent: Consent; message: string }>(
      Endpoints.CONSENTS,
      { ...params, "consent-name": consent }
    );
  },
  /**
   *
   * List consents accepted by the authenticated user.
   *
   * | Roles    |
   * | -------- |
   * | Reseller |
   *
   * #### Usage
   *
   * ```js
   * const givenConsents = await HttpApi.Consents.fetch(["gsuite-consent", "switch-to-webpro-panel"]);
   * for (let consent of givenConsents.consent) {
   *    console.log("Consent given ", consent.consentName, " with ", consent.params);
   * }
   * ```
   *
   */
  async fetch(consents?: string[]) {
    return api.get<{ consent: Consent[] }>(Endpoints.CONSENTS, {
      "consent-name": consents
    });
  }
};
