import { isSuccess } from "common/api-status/utils";
import { actionTypes as autoRenewalActionTypes } from "common/auto-renewal/ducks";
import { actionTypes as payButtonActionTypes } from "common/pay-buttons/ducks";
import { TaxSdk } from "common/sdk/tax";
import { WalletsSdk } from "common/sdk/wallet";
import { actionTypes as sessionActionTypes } from "common/session-management/ducks";
import { mapEffect } from "map-effect";
import { select, takeEvery } from "redux-saga/effects";
import { actionTypes } from "./ducks";

export const walletSagas = [
  takeEvery(
    [
      isSuccess(sessionActionTypes.AUTHENTICATE_USER),
      isSuccess(sessionActionTypes.VERIFY_USER),
      isSuccess(payButtonActionTypes.PAY_WITH_WALLET),
      isSuccess(autoRenewalActionTypes.DELETE_PAYMENT_METHOD),
      isSuccess(autoRenewalActionTypes.ADD_CARD),
      actionTypes.GET_WALLET_INFO
    ],
    mapEffect(onGetWalletInfo, actionTypes.GET_WALLET_INFO)
  ),
  takeEvery(
    [
      isSuccess(sessionActionTypes.AUTHENTICATE_USER),
      isSuccess(sessionActionTypes.VERIFY_USER),
      actionTypes.GET_TAX_INFO
    ],
    mapEffect(onGetTaxInfo, actionTypes.GET_TAX_INFO)
  )
];

function* onGetWalletInfo() {
  let { userid, parentsellingcurrencysymbol, role } = yield select(
    state => state.root.userInfo
  );
  let paymentGateways = yield WalletsSdk.gateways(userid);
  const response = yield WalletsSdk.balance(userid);
  let paymentMethods = yield WalletsSdk.tokens(userid);
  return {
    ...response,
    paymentGateways: Object.values(paymentGateways),
    paymentMethods,
    parentsellingcurrencysymbol
  };
}

function* onGetTaxInfo() {
  let { userid, country, role } = yield select(state => state.root.userInfo);
  let allTaxInfo = yield TaxSdk.percentages(userid);
  if (role === "reseller" && country === "US") {
    const taasInfo = yield TaxSdk.reseller.taas(userid);
    allTaxInfo = { ...allTaxInfo, ...taasInfo };
  }
  return allTaxInfo;
}
