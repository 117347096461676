import { Syringe } from "@faizaanceg/syringe";
import APIStatus from "common/api-status";
import { isSuccess } from "common/api-status/utils";
import ModalHandler from "common/modal-handler";
import { convertToPropTypes } from "common/prop-types";
import { applyAll } from "common/util";
import { get, isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  didAutoRenewFail,
  isRenewalDisabled,
  willRenewNextTime
} from "../constants";
import { actionTypes, actions } from "../ducks";
import { DisableAutoRenewal } from "../modals/disable";

export class AutoRenewalToggle extends React.Component {
  static mapStateToProps = (state, ownProps) => {
    let order = get(state.orders.ordersByOrderID, [ownProps.orderId]);
    if (order === undefined) {
      return {};
    }
    let { oboxOrderDetails: obData = {} } = order;
    let autoRenewalDetails = get(
      state.orders.autoRenewDetails,
      ownProps.orderId,
      { status: "success" }
    );
    let renewalDetails = get(state.orders.renewalDetails, ownProps.orderId, {});

    return {
      domainName: obData.domainname,
      isAutoRenewalDisabled:
        isRenewalDisabled(order, renewalDetails) ||
        state.orders.onholdOrders.length > 0,
      previousAutoRenewFailed: didAutoRenewFail(order, autoRenewalDetails),
      nextTime: willRenewNextTime(order),
      recurring: order.recurring
    };
  };

  static propTypes = {
    onlyRenderToggle: PropTypes.bool.isRequired,
    disableAutoRenewal: PropTypes.func,
    domainName: PropTypes.string.isRequired,
    isAutoRenewalDisabled: PropTypes.bool,
    supportautorenew: PropTypes.bool,
    previousAutoRenewFailed: PropTypes.bool,
    nextTime: PropTypes.bool,
    orderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    recurring: PropTypes.bool.isRequired,
    render: PropTypes.func.isRequired,
    ...convertToPropTypes(actions)
  };

  static defaultProps = {
    onlyRenderToggle: false,
    domainName: "",
    isAutoRenewalDisabled: false,
    previousAutoRenewFailed: false,
    nextTime: false,
    recurring: false
  };

  state = {
    autoRenewalStatus: this.props.recurring
  };

  get isAutoRenewEnabled() {
    return this.props.recurring;
  }

  set autoRenewalStatus(newStatus) {
    this.setState(_ => ({
      autoRenewalStatus: newStatus
    }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.recurring !== this.props.recurring) {
      this.autoRenewalStatus = this.props.recurring;
    }
  }

  turnOnAutoRenewal = () => {
    this.autoRenewalStatus = true;
  };

  turnOffAutoRenewal = () => {
    this.autoRenewalStatus = false;
  };

  render() {
    let { supportautorenew, paypw, paypal, payu } = this.props;
    let hasAutoRenewablePaymentMethods =
      paypw.isAllowed || paypal.isAllowed || payu.isAllowed;
    let hasTokens =
      !isEmpty(paypw.cards) ||
      !isEmpty(paypal.accounts) ||
      !isEmpty(payu.accounts);
    const userConstraints = Syringe.inject("userConstraints");
    let shouldAskForPaymentMethod =
      !hasTokens &&
      hasAutoRenewablePaymentMethods &&
      userConstraints.manageAutoRenewal;
    let performAction = this.state.autoRenewalStatus
      ? this.props.showDisable.bind(this, this.props.orderId)
      : shouldAskForPaymentMethod
      ? this.props.showEnable.bind(this, {
          orderIds: [this.props.orderId],
          postSuccessActions: [this.turnOnAutoRenewal],
          domainName: this.props.domainName
        })
      : this.props.enableAutoRenewal.bind(
          this,
          this.props.orderId,
          this.turnOnAutoRenewal
        );
    return supportautorenew ? (
      <>
        <APIStatus
          shouldUpdate={action => action.orderId === this.props.orderId}
          startsOn={actionTypes.SET_AUTO_RENEWAL}
          render={({ originalAction, ...apiStatus }) =>
            this.props.render({
              autoRenewable: this.state.autoRenewalStatus,
              disableAutoRenewable: this.props.isAutoRenewalDisabled,
              shouldAskForPaymentMethod,
              nextTime: this.props.nextTime,
              enableAutoRenewal: this.props.enableAutoRenewal.bind(
                this,
                this.props.orderId,
                this.turnOnAutoRenewal
              ),
              showEnable: this.props.showEnable.bind(this, {
                orderIds: [this.props.orderId],
                postSuccessActions: [this.turnOnAutoRenewal],
                domainName: this.props.domainName
              }),
              disableAutoRenewal: this.props.disableAutoRenewal.bind(
                this,
                this.props.orderId,
                this.turnOffAutoRenewal
              ),
              setAutorenewal: this.props.setAutorenewal.bind(
                this,
                this.props.orderId
              ),
              previousAutoRenewFailed: this.props.previousAutoRenewFailed,
              toggleAutoRenewal: performAction,
              turnOnAutoRenewal: this.turnOnAutoRenewal,
              turnOffAutoRenewal: this.turnOffAutoRenewal,
              hasAutoRenewablePaymentMethods,
              hasTokens,
              ...apiStatus
            })
          }
        />
        {!this.props.onlyRenderToggle ? (
          <>
            <ModalHandler
              opensOn={actionTypes.SHOW_DISABLE}
              closesOn={isSuccess(actionTypes.SET_AUTO_RENEWAL)}
              opensWhen={action => action.orderId === this.props.orderId}
              render={modalProps => (
                <DisableAutoRenewal
                  disableAutoRenewal={this.props.disableAutoRenewal.bind(
                    this,
                    this.props.orderId,
                    this.turnOffAutoRenewal
                  )}
                  domainName={this.props.domainName}
                  monitorInfo={[[actionTypes.SET_AUTO_RENEWAL]]}
                  show={modalProps.show}
                  onHide={applyAll(modalProps.onHide, this.turnOnAutoRenewal)}
                />
              )}
            />
          </>
        ) : null}
      </>
    ) : null;
  }
}

export default connect(AutoRenewalToggle.mapStateToProps)(AutoRenewalToggle);
