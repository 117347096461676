export const actionTypes = {
  GET_RETRY_RENEWAL_PRICING:
    "[Retry Renewal Pricing] Get Retry Renewal Pricing",
  OPEN_RETRY_RENEWAL_MODAL: "[Retry Renewal Modal] Open Retry Renewal Pricing"
};

export const actions = {
  getRetryRenewalPricingDetails: (orderdetails, taxinfo) => {
    return {
      type: actionTypes.GET_RETRY_RENEWAL_PRICING,
      orderdetails: { productKey: orderdetails.productkey, ...orderdetails },
      taxinfo
    };
  },
  openRetryRenewalModal: meta => ({
    type: actionTypes.OPEN_RETRY_RENEWAL_MODAL,
    meta
  })
};
