import React from "react";
import { SidebarLanguageSwitch } from "../styles";
import ModalHandler from "common/modal-handler";
import LanguageIcon from "../icons/language-icon";
import ForwardArrow from "../icons/forward-arrow";
import LanguageSwitcher from "modals/language-switcher";
import { LANGUAGES, oboxLanguageReverseMap } from "i18n";
import { connect } from "react-redux";

const InternationalComponents = ({ profileInfo: { langpref }, role }) => {
  return (
    role === "reseller" && (
      <ModalHandler
        render={({ show, onHide, onOpen }) => (
          <>
            <SidebarLanguageSwitch onClick={onOpen}>
              <LanguageIcon />
              {LANGUAGES[oboxLanguageReverseMap[langpref]]}
              <ForwardArrow />
            </SidebarLanguageSwitch>
            <LanguageSwitcher
              show={show}
              onHide={onHide}
              availableLanguages={LANGUAGES}
            />
          </>
        )}
      />
    )
  );
};

const mapStateToProps = state => {
  const {
    details,
    root: {
      userInfo: { role }
    }
  } = state;
  return { profileInfo: details.profileInfo, role };
};

export default connect(mapStateToProps)(InternationalComponents);
