import { connect } from "react-redux";
import i18next from "i18n";

type i18Translation = {
  key: string;
  defaultMessage: string;
  dynamicText?: any;
  notificationType?: string;
};

export const actionTypes = {
  SHOW_ERROR: "[Inline Notifications] Show Error",
  SHOW: "[Inline Notifications] Show"
};
export const constants = {
  SUCCESS: "success",
  ERROR: "error",
  PENDING: "pending"
};
export const actions = {
  showNotification: (
    defaultMessage = "",
    notificationType = constants.SUCCESS
  ) => {
    switch (notificationType) {
      case constants.ERROR:
        return {
          type: actionTypes.SHOW_ERROR,
          defaultMessage,
          notificationType
        };
        break;
      default:
        return {
          type: actionTypes.SHOW,
          defaultMessage,
          notificationType
        };
        break;
    }
  },
  showTranslatedNotification: ({
    key,
    defaultMessage,
    dynamicText,
    notificationType
  }: i18Translation) => {
    switch (notificationType) {
      case constants.ERROR:
        return {
          type: actionTypes.SHOW_ERROR,
          defaultMessage: i18next.t(
            key,
            defaultMessage ?? "",
            dynamicText ?? {}
          ),
          notificationType: notificationType
        };
        break;
      default:
        return {
          type: actionTypes.SHOW,
          defaultMessage: i18next.t(
            key,
            defaultMessage ?? "",
            dynamicText ?? {}
          ),
          notificationType: notificationType
        };
        break;
    }
  }
};

export let withInlineNotifications = connect(null, actions);
