import { isFailure, isSuccess } from "common/api-status/utils";
import { convertUTCTimestampToDate, getParsedAndAbsoluteValue } from "../util";
export const actionTypes = {
  GET_CURRENCY_CONVERSTION_INFO: "[Pay Buttons] Currency Conversion Load",
  GET_GREEDY_TRANSACTIONS: "[Pay Buttons] Greedy Transactions",
  CHOOSE_PAYMENT: "[Pay Buttons] Choose Payments",
  PAY_ONLINE: "[Pay Buttons] Online Payment",
  PAY_WITH_WALLET: "[Pay Buttons] With wallet"
};

export const initialState = {
  greedyTransactionsDetails: {
    greedyTransactions: [],
    summary: ""
  },
  conversionRate: 1
};

export default (state = initialState, action) => {
  switch (action.type) {
    case isSuccess(actionTypes.GET_GREEDY_TRANSACTIONS): {
      return {
        ...state,
        greedyTransactionsDetails: helpers.serializeGreedyTransactions(
          action.effectResponse
        )
      };
    }
    case isSuccess(actionTypes.GET_CURRENCY_CONVERSTION_INFO): {
      return { ...state, conversionRate: action.effectResponse };
    }
    case isFailure(actionTypes.GET_GREEDY_TRANSACTIONS):
      return initialState;
    default:
      return state;
  }
};

export const actions = {
  getCurrencyConversion: payload => ({
    type: actionTypes.GET_CURRENCY_CONVERSTION_INFO,
    payload
  }),
  getGreedyTransactions: () => ({
    type: actionTypes.GET_GREEDY_TRANSACTIONS
  }),
  choosePayment: payload => ({
    type: actionTypes.CHOOSE_PAYMENT,
    ...payload
  }),
  payOnline: (payload = {}, actionsMetaData = {}, layoutInfo) => ({
    type: actionTypes.PAY_ONLINE,
    payload: {
      ...payload,
      key: actionsMetaData.key + Date.now(),
      actionsMetaData,
      layoutInfo
    }
  }),
  payWithWallet: (actionDetails = {}, startAndEndActionTypes = {}) => ({
    type: actionTypes.PAY_WITH_WALLET,
    actionDetails,
    startAndEndActionTypes
  })
};

const helpers = {
  serializeGreedyTransactions: response => {
    let summary = [];
    const greedyTransactions = Object.values(response).map(transaction => {
      let unutilisedsellingamount = getParsedAndAbsoluteValue(
        transaction["reseller_transaction.unutilisedsellingamount"]
      );
      let description = transaction["reseller_transaction.description"];
      summary.push(description);
      return {
        unutilisedsellingamount,
        sellingcurrencysymbol:
          transaction["reseller_transaction.sellingcurrencysymbol"],
        transactiondate: convertUTCTimestampToDate(
          transaction["reseller_transaction.transactiondate"]
        ),
        description,
        transid: transaction["reseller_transaction.transid"]
      };
    });
    return {
      greedyTransactions,
      summary: summary.join(", ")
    };
  }
};
