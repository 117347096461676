import React from "react";

export function PlusSign(props) {
  return (
    <svg width="9px" height="10px" viewBox="0 0 9 10" {...props}>
      <path
        d="M5.5 8H8a1 1 0 110 2H5.5v2.5a1 1 0 01-2 0V10H1a1 1 0 110-2h2.5V5.5a1 1 0 112 0V8z"
        transform="translate(-81 -653) translate(0 71) translate(20 569) translate(61 9)"
        fill="#FFF"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  );
}
