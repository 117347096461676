import api from "api";
import endpoints from "constants/endpoints";
import { CODEGUARD, SITELOCK } from "./defaults";

export function getPlanDetails(productKey: string) {
  if (productKey === "sitelock") {
    return Promise.resolve({ sitelock: SITELOCK });
  }
  if (productKey === "codeguard") {
    return Promise.resolve({ codeguard: CODEGUARD });
  }
  return api.get<Record<string, Record<string, any>>>(
    `${endpoints.bclCacheableOrderBox}products/plan-details.json`,
    { "product-key": productKey }
  );
}
