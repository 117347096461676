import { colors, fonts } from "common/styles";
import { css, cx } from "linaria";
import React from "react";

export function ErrorMessage(props) {
  return (
    <p
      className={cx(
        css`
          color: ${colors.red.regular};
          font: 400 11px/1.5 ${fonts.OpenSans};
        `,
        "white-space-respect"
      )}
    >
      {props.children}
    </p>
  );
}

export function Message(props) {
  return (
    <p
      className={cx(
        css`
          color: ${colors.gray.gray900};
          font: 400 12px/1.5 ${fonts.OpenSans};
          margin: 0;
          padding-top: 2px;
        `,
        "white-space-respect"
      )}
    >
      {props.children}
    </p>
  );
}
