import { takeEvery, takeLatest, put } from "redux-saga/effects";
import { services } from "./services";
import { actions, actionTypes } from "./ducks";

export default [
  takeLatest(actionTypes.DISABLE_THEFT_PROTECTION, onDisableTheftProtection),
  takeEvery(actionTypes.ENABLE_THEFT_PROTECTION, onEnableTheftProtection)
];

export function* onDisableTheftProtection(action) {
  try {
    yield services.disableTheftProtection(action.payload.orderid);
    yield put(actions.disableSuccess(action.payload));
  } catch (error) {
    yield put(actions.disableFailure(error, action.payload));
  }
}

export function* onEnableTheftProtection(action) {
  try {
    yield services.enableTheftProtection(action.payload.orderid);
    yield put(actions.enableSuccess(action.payload));
  } catch (error) {
    yield put(actions.enableFailure(error, action.payload));
  }
}
