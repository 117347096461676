import { addQueryParamsToUrl } from "common/util";
import endpoints from "constants/endpoints";
import * as React from "react";

type AddCardMessage = { queryString: string; type: "webpro-panel" };

class AddCardIFrame extends React.Component<{
  onSuccess: (qs: URLSearchParams) => void;
}> {
  componentDidMount() {
    window.addEventListener("message", this.addCardSuccess);
  }

  addCardSuccess = (e: MessageEvent<AddCardMessage>) => {
    if (
      e.origin === window.location.origin &&
      e.data?.type === "webpro-panel"
    ) {
      this.props.onSuccess(new URLSearchParams(e.data.queryString));
      this.componentWillUnmount();
    }
  };

  componentWillUnmount() {
    window.removeEventListener("message", this.addCardSuccess);
  }

  render() {
    return (
      <iframe
        src={addQueryParamsToUrl(endpoints.add_card_link, {
          redirectUri: `${endpoints.webhooks}integrations/complete/addcard.html`
        })}
        title="Add card with pay.pw"
      />
    );
  }
}

export default AddCardIFrame;
