import { Discount, DiscountBreakdown } from "common/pricing-strategy/index";
import { colors, fonts } from "common/styles/index";
import WPTooltip from "common/tooltip";
import { formatAmountWithCurrency } from "common/util";
import { withWalletInfo } from "common/wallet/index";
import { cx, css } from "linaria";
import * as React from "react";

type BreakdownProps = DiscountBreakdown & {
  currencySymbol: string;
  discount: Discount;
};

function SimpleBreakdown({ currencySymbol, price }: BreakdownProps) {
  return (
    <div
      className={cx(
        "notranslate",
        css`
          padding: 10px 5px 9px;
          font: 600 12px/1.5 ${fonts.OpenSans};
        `
      )}
    >
      <div>
        {formatAmountWithCurrency({
          cost: price.discount,
          currency: currencySymbol,
          currencyDisplay: "code"
        })}
        /acc/mo
      </div>
      <div
        className={css`
          color: ${colors.gray.gray700};
          font-size: 11px;
          font-weight: 400;
          text-decoration: line-through;
        `}
      >
        {formatAmountWithCurrency({
          cost: price.regular,
          currency: currencySymbol,
          currencyDisplay: "code"
        })}
        /acc/mo
      </div>
    </div>
  );
}

function CompleteBreakdown({
  currencySymbol,
  accounts,
  price,
  discount
}: BreakdownProps) {
  return (
    <div
      className={css`
        display: grid;
        font: 600 12px/1.5 ${fonts.OpenSans};
        grid: auto-flow / repeat(2, auto);
        gap: 17px 30px;
        padding: 15px 5px 13px;
      `}
    >
      <div>
        {accounts.discount} accounts
        <div
          className={css`
            font-size: 11px;
            font-weight: 400;
          `}
        >
          {discount.value} {discount.type === "PERCENT" ? "%" : ""} off
        </div>
      </div>
      <div className="notranslate">
        {formatAmountWithCurrency({
          cost: price.discount,
          currency: currencySymbol,
          currencyDisplay: "code"
        })}
        /acc/mo
        <div
          className={css`
            color: ${colors.gray.gray700};
            font-size: 11px;
            font-weight: 400;
            text-decoration: line-through;
          `}
        >
          {formatAmountWithCurrency({
            cost: price.regular,
            currency: currencySymbol,
            currencyDisplay: "code"
          })}
          /acc/mo
        </div>
      </div>
      <div>{accounts.regular} accounts</div>
      <div className="notranslate">
        {formatAmountWithCurrency({
          cost: price.regular,
          currency: currencySymbol,
          currencyDisplay: "code"
        })}
        /acc/mo
      </div>
    </div>
  );
}

type TooltipProps = {
  breakdown: BreakdownProps;
  discount: Discount;
  onlyShowComplete?: boolean;
};

let EmailDiscountTooltip: React.FunctionComponent<TooltipProps> = function({
  breakdown,
  children,
  onlyShowComplete = false,
  ...props
}) {
  let shouldDisplayShort = breakdown.accounts.regular === 0;
  if (shouldDisplayShort && onlyShowComplete) {
    return null;
  }
  let ViewType = shouldDisplayShort ? SimpleBreakdown : CompleteBreakdown;
  return props.discount ? (
    <WPTooltip
      position="bottom"
      label={<ViewType {...breakdown} {...props} />}
      tooltipStyle={{
        color: colors.gray.gray800,
        cursor: "pointer",
        font: `400 12px/1.5 ${fonts.OpenSans}`,
        textDecoration: "underline"
      }}
    >
      {children}
    </WPTooltip>
  ) : null;
};

export default withWalletInfo(EmailDiscountTooltip);
