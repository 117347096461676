import { isSuccess } from "common/api-status/utils";
import { actionTypes as customerCreateTypes } from "common/customer-create/ducks";
import { composeReducers } from "common/util";

export const actionTypes = {
  GET_CUSTOMERS: "[Customer Management] Get Customers",
  OPEN_CREATE_CUSTOMER_MODAL:
    "[Customer Management] Open Create Customer modal",
  UPDATE_CUSTOMER_DETAILS: "[Customer Management] Update customer details",
  GET_ALL_CUSTOMERS: "[Customer Management] Get All Customers",
  UPDATE_CUSTOMER: "[Customer Management] Update Customer"
};

export const actions = {
  openCustomerModal() {
    return {
      type: actionTypes.OPEN_CREATE_CUSTOMER_MODAL
    };
  },
  getCustomers(payload) {
    return {
      type: actionTypes.GET_CUSTOMERS,
      payload
    };
  },
  updateCustomerDetails(customerDetails) {
    return {
      type: actionTypes.UPDATE_CUSTOMER_DETAILS,
      customerDetails
    };
  },
  getAllCustomers(payload) {
    return {
      type: actionTypes.GET_ALL_CUSTOMERS,
      payload
    };
  }
};

export const initialState = {
  customers: {},
  active: { fromCount: 0, totalCustomers: 0 },
  inactive: { fromCount: 0, totalCustomers: 0 },
  suspended: { fromCount: 0, totalCustomers: 0 }
};

function internalReducer(state = initialState, action) {
  switch (action.type) {
    case isSuccess(actionTypes.GET_CUSTOMERS): {
      const {
        customers = {},
        totalCount,
        customerIDs = [],
        customerType
      } = action.effectResponse;

      return {
        ...state,
        [customerType]: {
          fromCount: state[customerType].fromCount + customerIDs.length,
          totalCustomers: totalCount
        },
        customers: { ...state.customers, ...customers }
      };
    }
    case actionTypes.UPDATE_CUSTOMER_DETAILS: {
      const { customerDetails = {} } = action;
      return {
        ...state,
        customers: {
          ...state.customers,
          [customerDetails.customerid]: customerDetails
        }
      };
    }
    case isSuccess(actionTypes.GET_ALL_CUSTOMERS): {
      let customerState = {};
      let allCustomers = {};

      for (let type of ["active", "inactive", "suspended"]) {
        let { fromCount } = state[type];
        let { customerIDs, totalCount, customers } = action[type];
        customerState = {
          ...customerState,
          [type]: {
            fromCount: fromCount + customerIDs.length,
            totalCustomers: totalCount
          }
        };
        allCustomers = { ...allCustomers, ...customers };
      }

      return {
        ...state,
        ...customerState,
        customers: { ...state.customers, ...allCustomers }
      };
    }

    default:
      return state;
  }
}

function externalReducer(state = initialState, action) {
  switch (action.type) {
    case isSuccess(customerCreateTypes.CREATE_CUSTOMER): {
      let { customerid } = action.effectResponse;
      return {
        ...state,
        active: {
          fromCount: state.active.fromCount + 1,
          totalCustomers: state.active.totalCustomers + 1
        },
        customers: { ...state.customers, [customerid]: { orderCount: 0 } }
      };
    }

    default:
      return state;
  }
}

export default composeReducers(internalReducer, externalReducer);
