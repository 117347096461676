import listCustomersReducer, {
  actionTypes as listCustomersActionTypes,
  actions as listCustomersActions
} from "./list-customers/ducks";

import customerProfileReducer, {
  actionTypes as customerProfileActionTypes,
  actions as customerProfileActions
} from "./customer-profile/ducks";

import { combineReducers } from "redux";

export const actionTypes = {
  ...listCustomersActionTypes,
  ...customerProfileActionTypes
};

export const actions = {
  ...listCustomersActions,
  ...customerProfileActions
};

export default combineReducers({
  profile: customerProfileReducer,
  list: listCustomersReducer
});
