import { categories as AnalyticsCategories } from "analytics/constants";
import { isFailure, isSuccess } from "common/api-status/utils";
import { constants as inlineNotificationConstants } from "common/inline-notifications/ducks";
export const actionTypes = {
  SHOW_ENABLE: "[Auto Renewal] Show Enable",
  SHOW_DISABLE: "[Auto Renewal] Show Disable",
  SET_AUTO_RENEWAL: "[Auto Renewal] Set",
  SHOW_ADD_PAYMENT_METHOD: "[Auto Renewal] Show add payment method",
  SHOW_DELETE_PAYMENT_METHOD: "[Auto Renewal] Show delete payment method",
  DELETE_PAYMENT_METHOD: "[Auto Renewal] Delete payment method",
  ADD_CARD: "[Manage Payment Method] Add Card"
};

export const actions = {
  showDisable: orderId => ({ type: actionTypes.SHOW_DISABLE, orderId }),
  showEnable: ({ orderIds, postSuccessActions, domainName }) => ({
    type: actionTypes.SHOW_ENABLE,
    orderIds,
    postSuccessActions,
    domainName
  }),
  enableAutoRenewal: (orderId, postSuccessAction = _ => {}) => ({
    type: actionTypes.SET_AUTO_RENEWAL,
    orderId,
    status: true,
    defaultMessage: "Auto-renewal has been enabled",
    postSuccessAction,
    track: {
      eventCategory: AnalyticsCategories.order_micro_states,
      eventAction: "Auto Renew Enable",
      attributes: {
        toggle: "Enable",
        page: window.location.pathname
      }
    }
  }),
  disableAutoRenewal: (orderId, postSuccessAction = _ => {}) => ({
    type: actionTypes.SET_AUTO_RENEWAL,
    orderId,
    status: false,
    defaultMessage: "Auto-renewal has been disabled",
    postSuccessAction,
    track: {
      eventCategory: AnalyticsCategories.order_micro_states,
      eventAction: "Auto Renew Disable",
      attributes: {
        toggle: "Disable",
        page: window.location.pathname
      }
    }
  }),
  setAutorenewal: (orderId, status) => ({
    type: actionTypes.SET_AUTO_RENEWAL,
    orderId,
    status,
    postSuccessAction: _ => {}
  }),
  setAutorenewalSuccess: (orderId, defaultMessage) => ({
    type: isSuccess(actionTypes.SET_AUTO_RENEWAL),
    defaultMessage,
    orderId
  }),
  setAutorenewalFailure: (error, orderId) => ({
    type: isFailure(actionTypes.SET_AUTO_RENEWAL),
    error,
    orderId
  }),
  showDeletePaymentMethod: paymentMethodDetails => ({
    type: actionTypes.SHOW_DELETE_PAYMENT_METHOD,
    ...paymentMethodDetails
  }),
  deletePaymentMethod: token => ({
    type: actionTypes.DELETE_PAYMENT_METHOD,
    token
  }),
  showAddPaymentMethod: paymentMethodType => ({
    type: actionTypes.SHOW_ADD_PAYMENT_METHOD,
    paymentMethodType
  }),
  addCardSuccess: urlParams => {
    switch (urlParams.get("status")) {
      case "AuthSuccessful": {
        return {
          type: isSuccess(actionTypes.ADD_CARD),
          defaultMessage: "Card saved successfully!",
          notificationType: inlineNotificationConstants.SUCCESS
        };
      }
      case "AuthPending": {
        return {
          type: isSuccess(actionTypes.ADD_CARD),
          defaultMessage: "Your card will be added shortly",
          notificationType: inlineNotificationConstants.PENDING
        };
      }
      case "AuthFailed":
      default: {
        return {
          type: isFailure(actionTypes.ADD_CARD),
          defaultMessage: "Card could not be saved",
          notificationType: inlineNotificationConstants.ERROR
        };
      }
    }
  }
};
