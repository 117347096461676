import React from "react";
import classnames from "classnames";
// Currently supports only Input and textarea
import { get } from "lodash";
export const FormFieldWithError = ({
  FieldType = "input",
  field, // { name, value, onChange, onBlur }
  form: { touched, errors },
  ignoreTouched = false,
  className, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  containerClassName = "",
  errorClassName = "wp-error",
  label = () => null,
  ...props
}) => (
  <div className={containerClassName}>
    <FieldType
      {...field}
      {...props}
      className={classnames(className, {
        "input-error-border":
          (ignoreTouched || touched[field.name]) && errors[field.name]
      })}
    />
    {label()}
    {(ignoreTouched || touched[field.name]) && errors[field.name] && (
      <div className={errorClassName}>{errors[field.name]}</div>
    )}
  </div>
);

export const FieldArrayWithError = ({
  FieldType = "input",
  field,
  form: { touched, errors },
  ignoreTouched = false,
  className,
  label = () => {},
  ...props
}) => {
  let dimensions = field.name.split(".");
  return (
    <div>
      <FieldType
        {...field}
        {...props}
        className={classnames(className, {
          "input-error-border":
            ignoreTouched ||
            (get(touched, dimensions, false) && get(errors, dimensions, false))
        })}
      />
      {label()}
      {(ignoreTouched || get(touched, dimensions, false)) &&
        get(errors, dimensions, false) && (
          <div className="wp-error">{get(errors, dimensions, false)}</div>
        )}
    </div>
  );
};
