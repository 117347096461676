import { isSuccess } from "common/api-status/utils";

export const actionTypes = {
  REFRESH_PENDING_INVOICE_STATUS: "[Orders] Refresh Pending Invoice Status"
};

export const actions = {
  refreshPendingInvoiceStatus(orderId) {
    return {
      type: isSuccess(actionTypes.REFRESH_PENDING_INVOICE_STATUS),
      payload: {
        orderid: orderId
      }
    };
  }
};
