import { createFormikValidator } from "common/error-map";
import { FormFieldWithError } from "common/inline-error-field";
import { colors, fonts } from "common/styles";
import { formatAmountWithCurrency } from "common/util";
import { withWalletInfo } from "common/wallet";
import { Field, Form, Formik } from "formik";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import { connect } from "react-redux";

function mapStateToProps(state, myProps) {
  let details = state.orders.renewalDetails[myProps.orderId];
  let blocksNeeded = details?.addonDetails.addons?.blocks?.blocksNeeded ?? 0;
  let planLimit = details?.planDetails?.cpanel_accounts;
  let usedAccounts = details?.miscDetails?.accountsUsage;
  let addonPrice =
    details?.miscDetails?.addonsPricing ??
    details?.addonDetails.addons?.blocks?.pricingDetails;
  let customerAddonPrice =
    details?.addonDetails.addons?.blocks?.customerPricingDetails ?? 0;
  return {
    addonPrice,
    blocksNeeded,
    planLimit,
    usedAccounts,
    customerAddonPrice
  };
}

let Column = styled.p`
  font: 400 11px/1.5 ${fonts.OpenSans};
  color: ${colors.gray.gray700};
  span {
    font: bold normal 13px ${fonts.OpenSans};
    color: ${colors.black.regular};
  }
  margin-bottom: 9px;
`;

let ColumnItalic = styled(Column)`
  font-style: italic;
`;

type AddBlocksProps = {
  currencySymbol: string;
  usedAccounts: number;
  planLimit: number;
  blocksNeeded: number;
  addonPrice: number;
  customerAddonPrice: number;
  onChange: (blocks: number, price: number, customerPrice: number) => void;
};

type AddBlocksState = { blocks: number };

export let AddBlocks = withWalletInfo(
  connect(
    mapStateToProps,
    null
  )(function AddBlocks(props: AddBlocksProps) {
    return (
      <>
        <div
          className={css`
            position: relative;
            margin: 25px -30px 22px;
            &::before {
              content: "";
              border-top: 1px ${colors.gray.gray400} solid;
              position: absolute;
              width: 100%;
              top: 9px;
            }
          `}
        >
          <span
            className={css`
              margin-left: 21px;
              z-index: 2;
              position: relative;
              font: 400 12px/1.5 ${fonts.OpenSans};
              color: ${colors.gray.gray800};
              background: ${colors.white.regular};
              padding: 0 9px;
            `}
          >
            Buy cPanel Add-on
          </span>
        </div>
        <div
          className={css`
            display: grid;
            grid-gap: 65px;
            grid-template-columns: repeat(2, max-content) 2fr;
            justify-items: start;
          `}
        >
          {props.blocksNeeded > 0 && (
            <>
              <div>
                <Column>USED cPANEL</Column>
                <ColumnItalic>
                  <span>{props.usedAccounts}</span> accounts
                </ColumnItalic>
              </div>
              <div>
                <Column>CURRENT PLAN LIMIT</Column>
                <ColumnItalic>
                  <span>{props.planLimit}</span> accounts
                </ColumnItalic>
              </div>
            </>
          )}
          <div>
            <Column>ADD BLOCKS</Column>
            <Formik
              onSubmit={() => {}}
              validate={createFormikValidator<AddBlocksState>({
                blocks: {
                  isWrong: v =>
                    v.blocks < 0 ||
                    isNaN(v.blocks) ||
                    Math.trunc(v.blocks) !== v.blocks,
                  message: v => "Invalid value"
                }
              })}
              initialValues={{ blocks: props.blocksNeeded }}
            >
              {({ handleChange }) => (
                <>
                  <Form>
                    <Field
                      component={FormFieldWithError}
                      className="be-acc-no"
                      style={{ fontSize: "13px" }}
                      type="number"
                      name="blocks"
                      min={props.blocksNeeded}
                      onChange={e => {
                        handleChange(e);
                        props.onChange(
                          e.target.value,
                          props.addonPrice,
                          props.customerAddonPrice
                        );
                      }}
                    />
                  </Form>
                  <ColumnItalic style={{ margin: "6px 0 5px" }}>
                    1 block = 10 cPanel <wbr />
                    accounts
                  </ColumnItalic>
                  <ColumnItalic className="notranslate">
                    @
                    {formatAmountWithCurrency({
                      cost: props.addonPrice,
                      currency: props.currencySymbol,
                      currencyDisplay: "code"
                    })}{" "}
                    /block /mo
                  </ColumnItalic>
                </>
              )}
            </Formik>
          </div>
        </div>
      </>
    );
  })
);
