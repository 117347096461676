export let productNames = {
  BUSINESS_EMAIL: "Business Email",
  ENTERPRISE_EMAIL: "Enterprise Email",
  GSUITE: "Google Workspace",
  TITANMAIL: "Titan Mail",
  VPS_LINUX_KVM: "VPS Linux KVM",
  VPS_BLUEHOST: "VPS Hosting by Bluehost",
  DEDI_BLUEHOST: "Dedicated Servers By Bluehost",
  DEDI_HOSTGATOR: "Dedicated Servers by Hostgator",
  MANAGED_SERVERS: "Managed Server Linux",
  DEDI_LINUX: "Dedicated Server Linux",
  DEDI_WINDOWS: "Dedicated Server Windows",
  SSL: "SSL Certificate",
  CODEGUARD: "CodeGuard",
  SITELOCK: "SiteLock",
  SDH_LINUX: "Single Domain Linux Hosting",
  SDH_WINDOWS: "Single Domain Windows Hosting",
  MDH_LINUX: "Multi Domain Linux Hosting",
  MDH_WINDOWS: "Multi Domain Windows Hosting",
  RH_LINUX: "Reseller Linux Hosting",
  RH_WINDOWS: "Reseller Windows Hosting",
  CLOUD: "Cloud Hosting",
  PRO_CLOUD: "Pro Cloud",
  WP_CLOUD: "WordPress Cloud",
  WORDPRESS: "Wordpress Hosting",
  SHARED_HOSTGATOR: "Shared Hosting by Hostgator",
  WEBSITE_BUILDER: "Website Builder (Design only)",
  BLOCKSTORAGE: "Block Storage",
  BACKUP: "Acronis Cyber Backup",
  BACKORDER: "Domain Backordering"
};

let email = {
  [productNames.BUSINESS_EMAIL]: ["eeliteus"],
  [productNames.ENTERPRISE_EMAIL]: ["enterpriseemailus"],
  [productNames.GSUITE]: ["gappsgbl", "gappsin", "gappsse"],
  [productNames.TITANMAIL]: ["titanmailindia", "titanmailglobal"]
};

let servers = {
  [productNames.VPS_LINUX_KVM]: [
    "virtualserverlinuxus",
    "virtualserverlinuxin"
  ],
  [productNames.VPS_BLUEHOST]: ["bluehostvpslinuxus"],
  [productNames.DEDI_HOSTGATOR]: ["hostgatordedicatedserverlinuxus"],
  [productNames.DEDI_BLUEHOST]: ["bluehostdedicatedserverlinuxus"],
  [productNames.MANAGED_SERVERS]: ["managedserverlinuxus"],
  [productNames.DEDI_LINUX]: [
    "dedicatedserverlinuxin",
    "dedicatedserverlinuxus"
  ],
  [productNames.DEDI_WINDOWS]: [
    "dedicatedserverwindowsin",
    "dedicatedserverwindowshk"
  ]
};

let backupAndSecurity = {
  [productNames.SSL]: ["sslcert"],
  [productNames.CODEGUARD]: ["codeguard"],
  [productNames.SITELOCK]: ["sitelock"]
};

export let hosting = {
  [productNames.BACKORDER]: ["backorderservice"],
  [productNames.SDH_LINUX]: [
    "singledomainhostinglinuxin",
    "singledomainhostinglinuxhk",
    "singledomainhostinglinuxtr",
    "singledomainhostinglinuxuk",
    "singledomainhostinglinuxus",
    "singledomainhostinglinuxusca"
  ],
  [productNames.SDH_WINDOWS]: [
    "singledomainhostingwindowsin",
    "singledomainhostingwindowshk",
    "singledomainhostingwindowstr",
    "singledomainhostingwindowsuk",
    "singledomainhostingwindowsus"
  ],
  [productNames.MDH_LINUX]: [
    "multidomainhosting",
    "multidomainhostinglinuxin",
    "multidomainhostinglinuxhk",
    "multidomainhostinglinuxtr",
    "multidomainhostinglinuxuk",
    "multidomainhostinglinuxusca"
  ],
  [productNames.MDH_WINDOWS]: [
    "multidomainwindowshostingin",
    "multidomainwindowshosting",
    "multidomainwindowshostinghk",
    "multidomainwindowshostingtr",
    "multidomainwindowshostinguk"
  ],
  [productNames.RH_LINUX]: [
    "resellerhostinglinuxin",
    "resellerhostinglinuxtr",
    "resellerhostinglinuxuk",
    "resellerhosting"
  ],
  [productNames.RH_WINDOWS]: [
    "resellerwindowshosting",
    "resellerwindowshostingin",
    "resellerwindowshostingtr",
    "resellerwindowshostinguk"
  ],
  [productNames.WORDPRESS]: ["wordpresshostingusa"],
  [productNames.WP_CLOUD]: ["wordpresscloudindia"],
  [productNames.CLOUD]: ["cloudsiteslinuxin", "cloudsiteslinuxus"],
  // [productNames.PRO_CLOUD]: ["procloudasia"],  // TODO: Uncommet in phase 2
  [productNames.SHARED_HOSTGATOR]: ["hostgatorhostingsharedlinuxus"],
  [productNames.WEBSITE_BUILDER]: ["hosting"]
};

export function slugify(name) {
  return name.replace(/\s+/g, "-").toLowerCase();
}

export const slugToCategory = Object.fromEntries(
  Object.values({ domains: "domains", ...productNames }).map(key => [
    slugify(key),
    key
  ])
);

/**
 *
 * @param {import("constants/ProductKeyMapping").ProductKeys} productKey
 * @returns {string}
 */
export function getProductNameFromKey(productKey) {
  switch (productKey) {
    case "blockstorageus":
    case "blockstoragein":
      return productNames.BLOCKSTORAGE;
    case "acronisbackupasia":
    case "acronisbackupus":
      return productNames.BACKUP;
    case "backorderservice":
      return productNames.BACKORDER;
    default:
      return null;
  }
}

export default {
  email,
  hosting,
  servers,
  "backup & security": backupAndSecurity
};
