import dataLayerPush, { gtmEcommercePush } from "common/datalayer-push";
import gtmFormatters from "./gtm-formatters";

const analyticsMiddleware = store => next => action => {
  if (action?.track) {
    const {
      track: { eventAction, attributes = {} }
    } = action;

    if (eventAction?.startsWith("gtm")) {
      const { allErrors = [], allResponses = [] } = action.paymentSummary ?? {};
      attributes.amountSummary = {
        total_sum: 0,
        tax_sum: 0,
        subtotal_sum: 0
      };

      switch (action.type) {
        case "gtm_addons_flow":
          if (allErrors.length > 0) {
            dataLayerPush(
              "addons_add_failure",
              gtmFormatters.gtmAddonSuccessFormatter(
                attributes,
                action,
                allErrors
              )
            );
          }

          if (allResponses.length > 0) {
            dataLayerPush(
              "addons_add_success",
              gtmFormatters.gtmAddonSuccessFormatter(
                attributes,
                action,
                allResponses
              )
            );

            gtmEcommercePush(
              "transaction",
              gtmFormatters.gtmAddonsEcommerceSuccessFormatter(
                attributes,
                allResponses
              )
            );
          }
          break;
        case "gtm_renew_action_":
          if (allErrors.length > 0) {
            dataLayerPush(
              "Renew Failure",
              gtmFormatters.gtmRenewSuccessFormatter(
                { ...attributes, numberOfOrders: allErrors.length },
                allErrors
              )
            );
          }

          if (allResponses.length > 0) {
            dataLayerPush(
              "Renew Success",
              gtmFormatters.gtmRenewSuccessFormatter(
                { ...attributes, numberOfOrders: allResponses.length },
                allResponses
              )
            );

            gtmEcommercePush(
              "transaction",
              gtmFormatters.gtmEcommerceRenewSuccessFormatter(
                attributes,
                allResponses
              )
            );
          }
          break;
        case "gtm_buy_dedicated_ip":
          if (allResponses.length > 0) {
            gtmEcommercePush(
              "transaction",
              gtmFormatters.gtmEcommerceDedicatedIPSuccessFormatter(
                attributes,
                allResponses
              )
            );
          }

          break;
        case "gtm_buy_privacy":
          if (allResponses.length > 0) {
            gtmEcommercePush(
              "transaction",
              gtmFormatters.gtmEcommerceBuyPrivacyProtection(
                attributes,
                allResponses
              )
            );
          }

          break;
        default:
          dataLayerPush(eventAction, attributes);
      }
    } else if (eventAction) {
      dataLayerPush(eventAction, attributes);
    }
  }
  return next(action);
};

export default analyticsMiddleware;
