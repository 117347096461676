import { get, set, has } from "lodash";
import Constants from "./constants";

// Sometimes the messages that Obox returns is not descriptive enough and hence we modify the messages here
const modifyOboxMessages = store => next => action => {
  Constants.forEach(modifier => {
    modifier.keys.forEach(key => {
      if (has(action, key)) {
        get(action, key, "").includes(modifier.oboxMessage) &&
          set(action, key, modifier.webproMessage);
      }
    });
  });
  return next(action);
};

export default modifyOboxMessages;
