import { calculateTenureFloor } from "common/util";
import { BasePricingStrategy } from "./generic";
import { GenericQuery, PricingInfo, PricingType } from "./index";

export class TitanStrategy extends BasePricingStrategy {
  getAccountPrice(
    emailPricing: Record<PricingType, Record<string | number, number>>,
    pricingKey: PricingType,
    { discount, remainingNumberOfMonths, selectedTenure = 0 }: PricingInfo
  ) {
    return (
      emailPricing?.[pricingKey]?.[selectedTenure] ??
      emailPricing?.[pricingKey]?.[
        calculateTenureFloor(
          emailPricing?.[pricingKey],
          remainingNumberOfMonths ?? selectedTenure
        )
      ]
    );
  }
  getRenewPriceWithAddons(
    modifiedRenewalDetails: GenericQuery = { addonDetails: { addons: {} } },
    renewalData: GenericQuery,
    selectedTenure: number
  ) {
    const {
      addonDetails: { addons }
    } = modifiedRenewalDetails;
    return {
      resellerAddOnPrice: Object.keys(addons).reduce(
        (addonSum, addonName) => addonSum + addons[addonName]?.totalPrice,
        0
      ),
      customerAddOnPrice: Object.keys(addons).reduce(
        (addonSum, addonName) =>
          addonSum + addons[addonName]?.totalCustomerPrice,
        0
      )
    };
  }
}
