import React, { useRef, useEffect } from "react";
import { useListbox } from "common/listbox";
import { styled } from "linaria/react";
import { css } from "linaria";
import { OptionsPopover } from "common/popover";
import DropDownCaretIcon from "assets/icons/dropdown-caret-icon";
import { fonts, colors } from "common/styles";
import { useTranslation } from "react-i18next";
import { formatAmountWithCurrency } from "common/util";

let ListBox = styled.div`
  flex: 5.4;
  &[data-detailed="true"] {
    flex: 10;
  }
`;

export let SelectedOption = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 8px 12px;
  position: relative;
  width: 172px;
  z-index: 1111;
  border: 1px solid ${colors.gray.gray400};
  border-radius: 2px;
  &[data-expanded="true"] {
    border: 1px solid rgba(0, 0, 0, 0);
    background: ${colors.white.regular};
    border-radius: 0 2px 0 0;
    box-shadow: 0 0 6px 0 rgba(211, 211, 218, 0.6);
    &:before {
      content: "";
      position: absolute;
      bottom: -5px;
      background: ${colors.white.regular};
      height: 5px;
      width: 172px;
      left: -1px;
      right: 0;
    }
  }
`;

export let DropDownOptionTitle = styled.span`
  color: ${colors.black.regular};
  font: 600 12px/17px ${fonts.OpenSans};
`;

export let DropdownOption = styled.div`
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid #f2f2f4;
  }
  &:first-of-type {
    padding-top: 10px;
  }
  background: ${colors.white.regular};
  &:hover,
  &:focus {
    background: #f6f6f7;
  }
  &[aria-selected="true"] {
    background: #f6f6f8;
  }
  cursor: pointer;
  display: flex;
  padding: 16px 18px 15px 17px;
  position: relative;
`;

let DurationCell = styled.div`
  height: 17px;
  width: 65px;
  color: ${colors.black.regular};
  text-transform: capitalize;
  font: 600 12px/17px ${fonts.OpenSans};
`;

let CostCell = styled.div`
  width: 107px;
  color: ${colors.gray.gray700};
  font: 600 12px/17px ${fonts.OpenSans};
  padding: 0 10px;
`;

let TotalCostValue = styled.div`
  height: 17px;
  color: ${colors.black.regular};
  font: 600 12px/17px ${fonts.OpenSans};
  line-height: 17px;
`;

let CostSavingMessage = styled.div`
  height: 15px;
  width: 105px;
  font: italic 400 12px/15px ${fonts.OpenSans};
  margin-top: 3px;
  margin-bottom: 2px;
`;

let PopoverStyle = css`
  width: 311px;
  margin-top: 0;
  margin-left: 1px;
  right: 67px;
  .region-popover-top-space-adjuster {
    height: 10px !important;
  }
  .region-popover-bottom-space-adjuster {
    height: 0 !important;
  }
`;

export default function TelescopicSelectBox({
  onChange,
  defaultValue,
  pricing,
  currencySymbol
}) {
  const { t } = useTranslation();
  let listBoxRef = useRef(null);
  let durationList = Object.keys(pricing);
  let {
    selected,
    expand,
    getControls,
    getListboxProps,
    getSelectedItemProps,
    getOptionProps
  } = useListbox(listBoxRef, Math.min(durationList.length - 1, 3));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onChange(durationList[selected]), [selected]);

  return (
    <>
      <ListBox
        {...getListboxProps(
          t("MY_SHOP_SELECT_SERVER_LOCATION", "Select Server Location")
        )}
      >
        <SelectedOption
          ref={listBoxRef}
          tabIndex={0}
          {...getSelectedItemProps()}
          {...getControls()}
          data-expanded={expand}
        >
          <DropDownOptionTitle>
            {durationList[selected]} {t("MONTH_S", "Month(s)")}
          </DropDownOptionTitle>
          <DropDownCaretIcon style={{ position: "absolute", right: "13px" }} />
        </SelectedOption>
      </ListBox>

      {expand && (
        <OptionsPopover
          title={
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "95px",
                  padding: "0 20px",
                  textTransform: "none",
                  marginLeft: "47px",
                  fontWeight: "500"
                }}
              >
                {t("COST", "COST")}
                {t("MY_SHOP_PER_MONTH", "/mo")}
              </div>
              <div
                style={{
                  width: "100px",
                  paddingLeft: "23px",
                  fontWeight: "500"
                }}
              >
                {t("TOTAL_COST", "TOTAL COST")}
              </div>
            </div>
          }
          className={PopoverStyle}
        >
          {durationList.map((planDuration, index) => {
            let customerSavings =
              planDuration * (pricing[1] - pricing[planDuration]);
            return (
              <DropdownOption
                tabIndex="index"
                key={index}
                {...getOptionProps(index)}
              >
                <DropDownOptionTitle>
                  <div style={{ display: "flex" }}>
                    <DurationCell>
                      {planDuration}{" "}
                      {parseInt(planDuration) === 1
                        ? t("MONTH", "Month")
                        : t("MONTHS", "Months")}
                    </DurationCell>
                    <CostCell>
                      {currencySymbol}
                      <br />
                      {formatAmountWithCurrency({
                        cost: pricing[planDuration]
                      })}
                      {t("MY_SHOP_PER_MONTH", "/mo")}
                    </CostCell>
                    <div>
                      <TotalCostValue className="notranslate">
                        {formatAmountWithCurrency({
                          cost: planDuration * pricing[planDuration],
                          currency: currencySymbol,
                          currencyDisplay: "code"
                        })}
                      </TotalCostValue>
                      <CostSavingMessage
                        style={{
                          color: customerSavings
                            ? colors.green.regular
                            : colors.gray.gray700
                        }}
                      >
                        {t(
                          "MY_SHOP_SAVINGS",
                          `You save ${currencySymbol} ${customerSavings.toFixed(
                            2
                          )}`,
                          {
                            currencySymbol,
                            customerSavings: customerSavings.toFixed(2)
                          }
                        )}
                      </CostSavingMessage>
                    </div>
                  </div>
                </DropDownOptionTitle>
              </DropdownOption>
            );
          })}
        </OptionsPopover>
      )}
    </>
  );
}
