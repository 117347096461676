export const actionTypes = {
  GET_CERTIFICATE_DETAILS: "[SSL] Get certificate details"
};

export const actions = {
  getSSLCertificate: payload => ({
    type: actionTypes.GET_CERTIFICATE_DETAILS,
    ...payload
  })
};

export const initialState = { viewcert: { certificate: null } };

export const parsers = {
  getCertificate: response => ({ certificate: response.certificate })
};
