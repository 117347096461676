import { isSuccess } from "common/api-status/utils";

export const actionTypes = { SEARCH: "[SearchBar] Get" };

const initialState = { suggestions: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH:
      return initialState;
    case isSuccess(actionTypes.SEARCH): {
      const { suggestions } = action;
      return { suggestions };
    }
    default:
      return state;
  }
};
