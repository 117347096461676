import {
  categories as AnalyticsCategories,
  actions as AnalyticsActions,
  labels as AnalyticsLabels
} from "analytics/constants";
import { isFailure, isSuccess } from "common/api-status/utils";
export const actionTypes = {
  SHOW_THEFT_PROTECTION_ENABLE_MODAL: "[Theft protection] Show enable",
  SHOW_THEFT_PROTECTION_DISABLE_MODAL: "[Theft protection] Show disable",
  DISABLE_THEFT_PROTECTION: "[Theft protection] Disable",
  ENABLE_THEFT_PROTECTION: "[Theft protection] Enable"
};

export const initialState = {
  theft: {
    checked: false
  }
};

export const actions = {
  showModal: (_, checked, meta) => ({
    type: checked
      ? actionTypes.SHOW_THEFT_PROTECTION_ENABLE_MODAL
      : actionTypes.SHOW_THEFT_PROTECTION_DISABLE_MODAL,
    ...meta
  }),
  enable: payload => ({
    type: actionTypes.ENABLE_THEFT_PROTECTION,
    payload,
    track: {
      eventCategory: AnalyticsCategories.domains_view,
      eventAction: AnalyticsActions.theft_protection_on,
      eventLabel: AnalyticsLabels.theft_protection
    }
  }),
  enableSuccess: payload => ({
    type: isSuccess(actionTypes.ENABLE_THEFT_PROTECTION),
    ...payload
  }),
  enableFailure: error => ({
    type: isFailure(actionTypes.ENABLE_THEFT_PROTECTION),
    error
  }),
  disable: payload => ({
    type: actionTypes.DISABLE_THEFT_PROTECTION,
    payload,
    track: {
      eventCategory: AnalyticsCategories.domains_view,
      eventAction: AnalyticsActions.theft_protection_off,
      eventLabel: AnalyticsLabels.theft_protection
    }
  }),
  disableSuccess: payload => ({
    type: isSuccess(actionTypes.DISABLE_THEFT_PROTECTION),
    ...payload
  }),
  disableFailure: (error, payload) => ({
    type: isFailure(actionTypes.DISABLE_THEFT_PROTECTION),
    error,
    payload
  })
};

export const parsers = {
  detailsParser: ({ orderstatus = [] } = {}, meta) => ({
    checked: orderstatus.includes("transferlock")
  })
};
