import React from "react";
import classnames from "classnames";

import "./styles.scss";

export let Checkbox = ({ screen, ...props }) => (
  <div className={classnames("my-checkbox", screen)}>
    <label>
      <input
        {...props.field}
        data-testid={props["data-testid"]}
        onChange={props.onChange}
        type="checkbox"
        disabled={props.disabled}
      />
      <span>{props.children}</span>
    </label>
  </div>
);
