import api from "api";
import endpoints from "constants/endpoints";
import format from "string-format";

export default {
  getEmailAddresses: meta =>
    api.get(format(meta.endpoints.emailAddresses, meta)),
  getOverQuotaEmailAddresses: meta =>
    api.get(format(meta.endpoints.overQuotaEmailAddresses, meta)),
  deleteEmailAddress: (meta, emailAddress) =>
    api.post(meta.endpoints.deleteEmailAddress, {
      "order-id": meta.orderid,
      email: emailAddress
    }),
  resetEmailPassword: (meta, emailAddress) =>
    api.post(meta.endpoints.resetEmailAddress, {
      "order-id": meta.orderid,
      email: emailAddress
    }),
  addEmailAddress: async (meta, details) => {
    let { TOKEN: passwd } = await api.get(`${endpoints.user}password`);
    let response = await api.post(
      meta.endpoints.addEmailAddress,
      { "order-id": meta.orderid },
      new URLSearchParams({ ...details, passwd })
    );
    response.password = passwd;
    return response;
  }
};
