import {
  sideEffectMiddleware,
  sideEffectReducer
} from "@faizaanceg/redux-side-effect";
import { snitchMiddleware, snitchReducer } from "@faizaanceg/snitch";
import analyticsMiddleware from "analytics/middleware";
import { isFailure, isSuccess } from "common/api-status/utils";
import customerCreateReducer from "common/customer-create/ducks";
import customerManagementReducer from "common/customer-management/ducks";
import legalReducer from "common/legal-agreements/ducks";
import modifiyOboxMessagesMiddleware from "common/modify-obox-messages/middleware";
import orderReducer from "common/orders/ducks";
import payButtonsReducer from "common/pay-buttons/ducks";
import pendingActionsMiddleware from "common/pending-actions-notifications/middleware";
import detailsReducer from "common/profile-details/ducks";
import reloadableReducer, {
  reloadableMiddleware
} from "common/reloadable/ducks";
import searchReducer from "common/search-bar/ducks";
import rootReducer, {
  actionTypes as authActionTypes
} from "common/session-management/ducks";
import sideBarReducer from "common/side-bar/ducks";
import walletReducer from "common/wallet/ducks";
import addFundsReducer from "modals/add-funds/ducks";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import mainSaga from "sagas";
import widgetsReducer from "widgets/common/ducks";

const sagaMiddleware = createSagaMiddleware();
let middleware = [
  sagaMiddleware,
  modifiyOboxMessagesMiddleware,
  pendingActionsMiddleware,
  reloadableMiddleware,
  sideEffectMiddleware("apiStatus"),
  snitchMiddleware("modals"),
  analyticsMiddleware
];

if (
  process.env.BUILD_ENV === "development" ||
  process.env.BUILD_ENV === "staging"
) {
  const { createLogger } = require("redux-logger");
  middleware.push(createLogger());
}

const mainReducer = asyncReducers =>
  combineReducers({
    apiStatus: sideEffectReducer,
    legal: legalReducer,
    modals: snitchReducer,
    reloadable: reloadableReducer,
    orders: orderReducer,
    payButtons: payButtonsReducer,
    root: rootReducer,
    search: searchReducer,
    sideBar: sideBarReducer,
    wallet: walletReducer,
    widgets: widgetsReducer,
    customerCreate: customerCreateReducer,
    customerManagement: customerManagementReducer,
    funds: addFundsReducer,
    details: detailsReducer,
    ...asyncReducers
  });

let _compose = compose;

const actionTypes = [
  isSuccess(authActionTypes.LOGOUT_REQUEST),
  isFailure(authActionTypes.LOGOUT_REQUEST)
];

const appReducer = asyncReducers => (state, action) => {
  if (actionTypes.includes(action.type)) {
    state = undefined;
  }
  return mainReducer(asyncReducers)(state, action);
};

// We'll run Redux DevTools in both local and staging
if (
  process.env.BUILD_ENV !== "production" &&
  typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === "function"
) {
  _compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}

export function createWebproStore(initialState = {}) {
  const store = createStore(
    appReducer(),
    initialState,
    _compose(applyMiddleware(...middleware))
  );
  sagaMiddleware.run(mainSaga);
  store.asyncReducers = {};
  return store;
}

let webProStore = createWebproStore({});

export default webProStore;

export const injectReducerAndSaga = ({ key, reducer }, asyncSagas) => {
  if (webProStore.asyncReducers.hasOwnProperty(key)) {
    return;
  }
  webProStore.asyncReducers[key] = reducer;
  if (asyncSagas) {
    sagaMiddleware.run(asyncSagas);
  }
  webProStore.replaceReducer(appReducer(webProStore.asyncReducers));
};
