import {
  categories as AnalyticsCategories,
  actions as AnalyticsActions,
  labels as AnalyticsLabels
} from "analytics/constants";

export const actionTypes = {
  UPDATE_CONTACT_DETAILS: "[Contact details] Update",
  SHOW_MODAL: "[Contact details] Show Modal"
};

export const actions = {
  showModal: meta => ({ type: actionTypes.SHOW_MODAL, ...meta }),
  updateContactDetails: (meta, modifiedValues, initialValues) => ({
    type: actionTypes.UPDATE_CONTACT_DETAILS,
    meta,
    modifiedValues,
    initialValues,
    track: {
      eventCategory: AnalyticsCategories.domains_view,
      eventAction: AnalyticsActions.edit_contact_details,
      eventLabel: AnalyticsLabels.contact_details
    }
  })
};

export const initialState = {
  contact: {
    initialValues: {
      registrant: {},
      admin: {},
      billing: {},
      technical: {}
    },
    domainStatus: [],
    isGtld: false
  }
};
export const parsers = {
  onLoad: (payload, meta) => ({
    domainStatus: payload.domainstatus || [],
    initialValues: {
      registrant: helpers.fillContact(payload, "registrant"),
      technical: helpers.fillContact(payload, "tech"),
      admin: helpers.fillContact(payload, "admin"),
      billing: helpers.fillContact(payload, "billing")
    }
  }),
  setIRTP: payload => ({ isGtld: payload.category === "gtld" })
};
const helpers = {
  fillContact: (payload, type) => {
    const keyInPayload = `${type}contact`;

    if (payload[keyInPayload]) {
      const {
        name,
        company,
        emailaddr,
        telno,
        telnocc,
        address1,
        zip,
        city,
        state,
        country,
        contactid
      } = payload[keyInPayload];
      return {
        name,
        company,
        email: emailaddr,
        phone: telno,
        phone_cc: telnocc,
        fax: null,
        address: address1,
        zipcode: zip,
        city,
        state,
        country,
        contactId: contactid
      };
    }
    return {};
  }
};
