import { PgBillingSpotlight } from "assets";
import classNames from "classnames";
import UpdateButton from "common/buttons/update";
import { Checkbox } from "common/checkbox";
import { parseStringAsBoolean } from "common/parse-string";
import { actions as payActions } from "common/pay-buttons/ducks";
import {
  getPrivacyProtectionAddonPrice,
  getTaxAmount,
  getTaxInfo
} from "common/util";
import { getSimpleNameFromProductKey } from "constants/index";
import { css } from "linaria";
import { styled } from "linaria/react";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  DefaultRootState,
  connect,
  useDispatch,
  useSelector
} from "react-redux";
import { actions as pricingActions } from "../../session-management/ducks";
import { WPBuyModalProps } from "../buy-modal";
import { actions as upsellActions } from "../upsell/privacy-protection/ducks";
import { actions } from "./ducks";

let BillingPreferenceButtnWrapper = styled.div`
  display: flex;
  margin: 0 50px;
`;

let LabelCheckbox = styled.span`
  font-size: 14px;
`;

let LabelDescription = styled.div`
  display: grid;
  margin: 5px 0 0 22px;
  color: #8a929e;
`;

let popoverWrapper = css`
  max-width: 246px;
  width: 100%;

  .arrow {
    display: none;
  }
  .popover-content {
    padding: 5px 8px;
  }
`;

let PopoverBottomWrapper = styled.div`
  padding: 16px;
`;
let SpotlightDescription = styled.span`
  font-size: 12px;
  color: #596275;
`;

type BillingPreferencesTypes = {
  hideCurrentModal?: Function;
  total: string;
  payButtonsInfo?: WPBuyModalProps["payButtonsInfo"];
  assignedDomain?: string;
  title?: string;
  currencySymbol?: string;
  productkey?: string;
  goBackToPrevState?: Function;
  customerid?: string;
  customerId: string;
  disabled?: boolean;
  style?: any;
  customerPrice?: number;
  sellingPriceByCustomerId?: any;
  isPrivacyProtected?: boolean;
  productCategory?: string;
  addons?: any;
  expirytimestamp?: string;
  upsellDetails?: {
    showUpsell: boolean;
    upsellData: any;
  };
  isFetching?: boolean;
};

function BillingPreferencesButton({
  hideCurrentModal,
  total,
  payButtonsInfo,
  assignedDomain,
  title,
  currencySymbol,
  productkey,
  goBackToPrevState,
  customerId,
  disabled,
  style,
  customerPrice,
  sellingPriceByCustomerId,
  upsellDetails: { showUpsell, upsellData },
  isFetching
}: BillingPreferencesTypes) {
  const { t } = useTranslation();
  let user = useSelector(state => ({
    isWebPro: parseStringAsBoolean(state.details.profileInfo?.iswebpro),
    isReseller: state.root.userInfo.role === "reseller"
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (customerId && productkey) {
      dispatch(pricingActions.getPerCustomerPricing(customerId, productkey));
    }
  }, [customerId, productkey, dispatch]);

  const [showBilling, setShowBilling] = useState(false);
  const [billingOverlay, setBillingOverlay] = useState(false);

  return (
    <BillingPreferenceButtnWrapper className={style}>
      {user.isWebPro && !payButtonsInfo?.hideCustomerBilling && (
        <>
          <Checkbox
            screen={css`
              margin-left: 10px;
            `}
            onChange={() => setShowBilling(!showBilling)}
          >
            <LabelCheckbox>
              I want to bill my client for this order
            </LabelCheckbox>
            <LabelDescription>
              <span>
                On the next step you will select the client invoice type
              </span>
              <span>
                You can create invoice later also from the{" "}
                <span
                  onMouseOver={() => setBillingOverlay(true)}
                  className={css`
                    border-bottom: 1px dashed #8a929e;
                  `}
                >
                  billing tab
                </span>
              </span>
            </LabelDescription>
          </Checkbox>
          {billingOverlay && (
            <Popover
              id="popover-basic"
              placement="right"
              positionLeft={400}
              positionTop={300}
              className={popoverWrapper}
            >
              <img src={PgBillingSpotlight} width="228px" height="134px" />
              <PopoverBottomWrapper>
                <span
                  className={css`
                    font-weight: 500;
                  `}
                >
                  {" "}
                  Create Invoice in Billing tab
                </span>
                <br />
                <SpotlightDescription>
                  You can manually create invoices in the billing tab at any
                  time.
                </SpotlightDescription>
                <br />
                <UpdateButton
                  type="button"
                  className={classNames(
                    "wp-btn",
                    css`
                      margin-top: 20px;
                    `
                  )}
                  onClick={() => setBillingOverlay(false)}
                >
                  OKAY
                </UpdateButton>
              </PopoverBottomWrapper>
            </Popover>
          )}
        </>
      )}

      <UpdateButton
        data-testid="proceed-to-billing-references"
        disabled={Number(total) < 0 || disabled}
        className={
          user.isReseller
            ? classNames(
                "wp-btn-green",
                css`
                  padding: 11px 18px;
                  max-height: 39px;
                `,
                !payButtonsInfo?.hideCustomerBilling &&
                  css`
                    margin-left: auto;
                  `,
                payButtonsInfo?.hideCustomerBilling &&
                  css`
                    margin: auto;
                  `
              )
            : classNames(
                css`
                  margin: auto;
                  display: inline-block !important;
                `,
                "wp-btn-green-with-icon pay-card-icon"
              )
        }
        onClick={() => {
          hideCurrentModal();
          let payload = {
            payButtonsInfo,
            totalCost: total,
            assignedDomain,
            title,
            currencySymbol,
            productkey,
            customerId,
            sellingPriceByCustomerId: isEmpty(sellingPriceByCustomerId)
              ? { [customerId]: customerPrice }
              : sellingPriceByCustomerId,
            goBackToPrevState
          };
          if (showBilling || (user.isReseller && !user.isWebPro)) {
            dispatch(actions.openBillingPreferencesModal(payload));
          } else if (showUpsell) {
            dispatch(
              upsellActions.openUpsellPrivacyProtectionModal({
                ...payload,
                title: "PROCEED WITHOUT PRIVACY PROTECTION ?",
                ...upsellData
              })
            );
          } else {
            dispatch(
              payActions.choosePayment({
                actionDetails: {
                  cost: total,
                  currencySymbol,
                  ...payButtonsInfo?.actionDetails
                },
                isPendingPayment: payButtonsInfo?.isPendingPayment,
                transid: payButtonsInfo?.transid,
                layoutInfo: payButtonsInfo?.layoutInfo,
                startAndEndActionTypes: payButtonsInfo?.startAndEndActionTypes,
                assignedDomain,
                title,
                goBackToPrevState
              })
            );
          }
        }}
        type="button"
        updating={isFetching}
      >
        {process.env.FEATURE_ENDPOINT !== "customer"
          ? t("PROCEED", "PROCEED")
          : t("PAY_ONLINE", "PAY ONLINE")}
      </UpdateButton>
    </BillingPreferenceButtnWrapper>
  );
}

function mapStateToProps(state: DefaultRootState, myProps) {
  let { ordersByOrderID, renewalDetails } = state.orders;
  let {
    payButtonsInfo: {
      actionDetails: { value }
    },
    customerid,
    upSellInfo
  } = myProps;
  const orderID = Array.isArray(value)
    ? value[0]?.pathParams?.orderid
    : value?.meta?.orderid;
  let orderDetailsById = ordersByOrderID[orderID];

  let firstDomOrder = []
    .concat(value)
    .find(order => order?.meta?.productcategory === "domorder");
  const productCategory = firstDomOrder?.meta?.productcategory;

  const upsellDetails = getUpsellDetails(productCategory, {
    renewalDetails,
    value,
    upSellInfo
  });

  let customerId = orderDetailsById?.meta?.customerid || customerid;
  let sellingPriceByCustomerId = {};
  Array.isArray(value) &&
    value.forEach(e => {
      let customerid = ordersByOrderID[e?.meta?.orderid]?.meta?.customerid;
      sellingPriceByCustomerId = {
        ...sellingPriceByCustomerId,
        [customerid]:
          e?.meta?.customerPrice + (sellingPriceByCustomerId?.[customerid] ?? 0)
      };
    });
  return {
    customerId,
    sellingPriceByCustomerId,
    productCategory,
    upsellDetails
  };
}

export default connect(mapStateToProps)(BillingPreferencesButton);

function getUpsellDetails(
  productCategory: string,
  data: {
    renewalDetails: Record<string, any>;
    value: any;
    upSellInfo: any;
  }
) {
  const { renewalDetails, value, upSellInfo } = data;
  switch (getSimpleNameFromProductKey(productCategory)) {
    case "domain": {
      const orders = [].concat(value);
      let domOnlyOrdersWithoutPPCost = 0;
      let domOnlyOrdersWithoutPP = orders.filter(
        order =>
          order?.meta?.productcategory === "domorder" &&
          !order?.params?.["purchase-privacy"]
      );
      domOnlyOrdersWithoutPP.forEach(order => {
        let renewalOrderDetailsById = renewalDetails[order?.meta?.orderid];
        const {
          addonDetails: { addons = {} },
          expirytimestamp
        } = renewalOrderDetailsById;
        const addonDetails = addons?.purchase_privacy;
        const { addonPrice } = getPrivacyProtectionAddonPrice({
          addonDetails,
          pricingDetails: addonDetails?.pricingDetails,
          customerPricingDetails: addonDetails?.customerPricingDetails,
          expirytimestamp,
          selectedTenure: order?.params?.years
        });
        domOnlyOrdersWithoutPPCost += addonPrice;
      });

      const subTotalWithPPAddon =
        upSellInfo?.subTotal + domOnlyOrdersWithoutPPCost;
      const applicableTax = getTaxInfo(
        { productcategory: "domorder" },
        upSellInfo?.taxinfo
      );
      const totalTaxWithPPAddon =
        parseFloat(getTaxAmount(subTotalWithPPAddon, applicableTax)) ?? 0;
      const totalWithPP = Number(
        subTotalWithPPAddon + totalTaxWithPPAddon
      ).toFixed(2);
      return {
        showUpsell: domOnlyOrdersWithoutPP.length > 0,
        upsellData: {
          ...upSellInfo,
          domOnlyOrdersWithoutPP,
          domOnlyOrdersWithoutPPCost,
          totalWithPP
        }
      };
    }

    default:
      return { showUpsell: false, upsellData: {} };
  }
}
