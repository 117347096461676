import { connect } from "react-redux";
import { actions } from "./ducks";

const bucketUrl = `https://${
  process.env.BUILD_ENV === "production" ? "prod" : "stag"
}-webpro-images.s3.amazonaws.com`;

export const withLogo = connect(state => {
  const actorId =
    process.env.FEATURE_ENDPOINT === "reseller"
      ? state.root.userInfo.userid
      : state.root.brandingDetails.parentId;
  return { logoUrl: `${bucketUrl}/${actorId}` };
}, actions);
