import storage from "@faizaanceg/pandora";
import {
  constants as NotificationTypes,
  actions as Notify
} from "common/inline-notifications/ducks";
import { actions as OrderActions } from "common/orders/ducks";
import {
  getOrderDetails,
  getPricingFromProductKey,
  onGetPlanDetails
} from "common/orders/sagas";
import CommonServices from "common/services";
import ProductKeyMapping from "constants/ProductKeyMapping";
import { getSimpleNameFromProductKey } from "constants/index";
import { getDefaultPanel } from "constants/panel";
import { mapEffect } from "map-effect";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { actionTypes, actions } from "./ducks";
import { services } from "./services";

export default [
  takeEvery(actionTypes.GET_ADMIN_PANEL_URL, mapEffect(onGetAdminPanelURL)),
  takeEvery(actionTypes.FETCH_DEPENDENCY, mapEffect(onGetWidgetDetails)),
  takeEvery(actionTypes.GET_WIDGET_DETAILS, mapEffect(onGetWidgetDetails)),
  takeEvery(actionTypes.GET_PRICING_DETAILS, onGetPricingDetailsUsingId),
  takeEvery(actionTypes.DISPATCH_QUEUE, mapEffect(processDispatchQueue))
];

export function* processDispatchQueue() {
  yield all(storage.get("dispatchQueue", []).map(_ => put(_)));
  storage.remove("dispatchQueue");
}

function* onGetAdminPanelURL({
  panelWindow,
  payload: { panelName, productkey, ...payload }
}) {
  let simpleProductName = getSimpleNameFromProductKey(productkey);
  const endpoints =
    payload.endpoints ?? ProductKeyMapping[productkey].meta.endpoints;
  panelName = panelName ?? getDefaultPanel(simpleProductName, productkey);
  let { brandingurl, role, parentbrandingurl } = yield select(state => ({
    brandingurl: state.root.userInfo.brandingurl,
    role: state.root.userInfo.role,
    parentbrandingurl: state.root.brandingDetails.parentbrandingurl
  }));
  let redirectUrl = role === "reseller" ? brandingurl : parentbrandingurl;
  const response = yield services.getAdminPanelURL(
    { panelName, redirectUrl, endpoints, ...payload },
    simpleProductName
  );
  if (response?.autoLoginUrl) {
    panelWindow.location.replace(response.autoLoginUrl);
  } else {
    let errorMessage = "Something went wrong! Please try again in sometime";
    yield put(Notify.showNotification(errorMessage, NotificationTypes.ERROR));
    throw new Error(errorMessage);
  }
  return response;
}

onGetAdminPanelURL.onFailure = action => action.panelWindow.close();

function* onGetWidgetDetails({ payload }) {
  if (payload.key === "getPlanDetails") {
    yield put(
      OrderActions.getPlanDetails({ productKey: payload.meta.productkey })
    );
  }
  return (payload.key === "getPricingDetails" &&
    process.env.FEATURE_ENDPOINT === "bigrock") ||
    payload.key === "getCompletePricing"
    ? yield call(
        getPricingFromProductKey,
        payload.meta.productkey,
        false,
        payload.meta.customerid
      )
    : yield services.getWidgetDetails(payload);
}

function* onGetPricingDetailsUsingId(action) {
  try {
    const { parent, child, meta, id } = action;
    yield put(actions.setupState({ meta, hierarchy: { parent, child } }));
    const { planid } = yield call(
      getOrderDetails,
      { orderid: meta.orderid },
      { getRawData: true }
    );
    const { productKey } = meta;
    let planDetails;
    if (getSimpleNameFromProductKey(productKey) === "blockstorage") {
      planDetails = yield onGetPlanDetails(action);
    }
    let pricingDetails = yield getPricingFromProductKey(productKey, true);
    let params = { "order-id": meta.orderid, "plan-id": planid };
    let { customerId, role } = yield select(state => {
      return {
        customerId: state.root.userInfo.userid,
        role: state.root.userInfo.role
      };
    });
    if (role !== "reseller") {
      params["customer-id"] = customerId;
    }
    const apiId =
      id === "plan" || role !== "reseller"
        ? planid
        : (yield CommonServices.getBandID(params)).bandid;
    yield put(
      actions.getPricingDetailsUsingIDSuccess({
        apiId,
        pricingDetails,
        child,
        meta,
        parent,
        planDetails: planDetails?.[planid] ?? {}
      })
    );
  } catch (error) {
    yield put(actions.getPricingDetailsUsingIDFailure(error));
  }
}
