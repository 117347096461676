import { Syringe } from "@faizaanceg/syringe";
import api from "api";
import { parseStringAsBoolean } from "common/parse-string";
import endpoints from "constants/endpoints";

const ENDPOINTS = {
  CUSTOMER: `${endpoints.zuulOrderBox}customers/`,
  RESELLER_LOCK: `${endpoints.zuulOrderBoxRest}domains/bulk/reseller-lock/`
};

// prettier-ignore
const EUCountries = [
  "AT", "BE", "BG", "CY",
  "CZ", "DE", "DK", "EE",
  "ES", "FI", "FR", "GB",
  "GR", "HR", "HU", "IE",
  "IT", "LT", "LU", "LV",
  "MT", "NL", "PL", "PT",
  "RO", "SE", "SI", "SK",
  "MC", "IM"
];

type CreateCustomerForm = {
  username: string;
  name: string;
  company: string;
  address_line_1: string;
  city: string;
  state: string;
  country: string;
  zipcode: number;
  phone: number;
  phone_cc: number;
  lang_pref: string;
  indiangstid: string;
  russiavatid: string;
  vatid: string;
};

type ModifyCustomer = {
  username: string;
  passwd?: string;
  name: string;
  company: string;
  "address-line-1": string;
  "address-line-2"?: string;
  "address-line-3"?: string;
  city: string;
  state: string;
  country: string;
  "other-state"?: string;
  zipcode: number;
  phone: number;
  "phone-cc": number;
  "alt-phone"?: number;
  "alt-phone-cc"?: number;
  mobile?: number;
  "mobile-cc"?: number;
  fax?: number;
  "fax-cc"?: number;
  "lang-pref": string;
  "sms-consent"?: boolean;
  "indian-gst-id"?: string;
  "russia-vat-id"?: string;
  "vat-id"?: string;
} & Record<string, any>;

const toCustomer = (
  customer: CreateCustomerForm,
  isUpdate?: Boolean
): ModifyCustomer => {
  let payload: ModifyCustomer = {
    username: customer.username,
    name: customer.name,
    company: customer.company,
    "address-line-1": customer.address_line_1,
    city: customer.city,
    state: customer.state,
    country: customer.country,
    zipcode: customer.zipcode,
    phone: customer.phone,
    "phone-cc": customer.phone_cc,
    "lang-pref": customer.lang_pref
  };
  if (payload.country === "IN") {
    payload["indian-gst-id"] = customer.indiangstid;
  }
  if (payload.country === "RU") {
    payload["russia-vat-id"] = customer.russiavatid;
  }
  if (EUCountries.includes(payload.country)) {
    payload[isUpdate ? "vatId" : "vat-id"] = customer.vatid;
  }
  return payload;
};

const sessionManager = Syringe.inject("sessionManager");

export const CustomerManagementSdk = {
  create: async (customer: CreateCustomerForm) => {
    let payload = toCustomer(customer);
    let { TOKEN: passwd } = await api.get<{ TOKEN: string }>(
      `${endpoints.user}password`
    );
    payload.passwd = passwd;
    let customerid: number = await api.postBody(
      `${ENDPOINTS.CUSTOMER}v2/signup`,
      new URLSearchParams(payload)
    );
    return customerid;
  },
  detailsById: async (id: string) => {
    let details = await api.get<Record<string, any>>(
      `${ENDPOINTS.CUSTOMER}details-by-id`,
      {
        "customer-id": id
      }
    );
    return {
      ...details,
      status: details.customerstatus,
      twofactorgoogleauth_enabled: parseStringAsBoolean(
        details.twofactorgoogleauth_enabled
      ),
      twofactorsmsauth_enabled: parseStringAsBoolean(
        details.twofactorsmsauth_enabled
      )
    };
  },
  delete: (id: string) =>
    api.post(`${ENDPOINTS.CUSTOMER}delete`, { "customer-id": id }),
  suspend: (id: string, reason: string) =>
    api.post(`${ENDPOINTS.CUSTOMER}suspend`, { "customer-id": id, reason }),
  unsuspend: (id: string) =>
    api.post(`${ENDPOINTS.CUSTOMER}unsuspend`, { "customer-id": id }),
  update: async (id: string, customer: any): Promise<boolean> => {
    const { details } = await sessionManager.create();
    const { role } = details;
    return role === "reseller"
      ? api.post(
          `${ENDPOINTS.CUSTOMER}modify`,
          { "customer-id": id },
          new URLSearchParams(toCustomer(customer, true))
        )
      : api.put(`${endpoints.webpro}customers/${id}`, customer);
  },
  savePassword: (id: string, newPasswd: string) =>
    api.post(
      `${ENDPOINTS.CUSTOMER}v2/change-password`,
      { "customer-id": id },
      new URLSearchParams({ "new-passwd": newPasswd })
    ),
  savePin: (id: string, { oldPin, newPin }) =>
    api.post(
      `${endpoints.zuulOrderBox}reseller/change-customer-pin`,
      { "customer-id": id },
      new URLSearchParams({ "old-pin": oldPin, "new-pin": newPin })
    ),
  orders: {
    count: (customerIds: string[]) =>
      api.get(`${endpoints.zuulOrderBoxV2}orders/count`, {
        "customer-id": customerIds
      }),
    move: (domain: string, oldId: string, newId: string) =>
      api.post(`${endpoints.zuulOrderBox}products/move`, {
        "existing-customer-id": oldId,
        "new-customer-id": newId,
        "domain-name": domain,
        "default-contact": "oldcontact"
      }),
    bulkSuspend: (ids: string[], reason: string) =>
      api.post(`${endpoints.zuulOrderBox}orders/bulk-suspend`, {
        "order-id": ids,
        reason
      }),
    bulkUnsuspend: (ids: string[]) =>
      api.post(`${endpoints.zuulOrderBox}orders/bulk-unsuspend`, {
        "order-id": ids,
        "remove-child-locks": false
      }),
    bulkLockDomains: (userIds: string[], reason: string) =>
      api.postBody(`${ENDPOINTS.RESELLER_LOCK}add`, {
        userIds,
        lockFor: "customerid",
        reason,
        removeChildLocks: true
      }),
    bulkUnlockDomains: (userIds: string[]) =>
      api.postBody(`${ENDPOINTS.RESELLER_LOCK}remove`, {
        userIds,
        lockFor: "customerid",
        removeChildLocks: true
      })
  }
};
