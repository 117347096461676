import { isFailure, isSuccess } from "common/api-status/utils";
import { sum } from "common/math";

export const actionTypes = {
  ADD_FUNDS: "[Add Funds] Add Funds",
  GET_NEXT_30_DAYS_RENEWALS: "[Add Funds] Get Next 30 days renewals",
  GET_GREEDY_TRANSACTIONS: "[Add Funds] Get Greedy Transactions",
  SHOW_ADD_FUNDS_MODAL: "[Add Funds] show Add Funds Modal"
};

const initialState = {
  pendingAmount: 0, // greedy transactions pending amount
  renewalAmount: 0, // next 30 days renewal transactions amount
  greedyTransactions: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case isSuccess(actionTypes.GET_NEXT_30_DAYS_RENEWALS): {
      return { ...state, renewalAmount: action.effectResponse };
    }

    case isSuccess(actionTypes.GET_GREEDY_TRANSACTIONS): {
      let greedyTransactions = helpers.serializeGreedyTransactionsResponse(
        action.effectResponse
      );
      return {
        ...state,
        greedyTransactions,
        pendingAmount: greedyTransactions.map(_ => _.amount).reduce(sum, 0)
      };
    }

    default: {
      return state;
    }
  }
};

export const actions = {
  showModal: () => ({
    type: actionTypes.SHOW_ADD_FUNDS_MODAL
  }),
  addFunds: payload => ({
    type: actionTypes.ADD_FUNDS,
    payload
  }),
  addFundsSuccess: response => ({
    type: isSuccess(actionTypes.ADD_FUNDS),
    response
  }),
  addFundsFailure: error => ({
    type: isFailure(actionTypes.ADD_FUNDS),
    error
  }),
  getNext30DaysRenewals: () => ({
    type: actionTypes.GET_NEXT_30_DAYS_RENEWALS
  }),
  getGreedyTransactions: () => ({
    type: actionTypes.GET_GREEDY_TRANSACTIONS
  })
};

export const helpers = {
  serializeGreedyTransactionsResponse: (response = []) =>
    Object.values(response).map(greedyTransaction => ({
      why: greedyTransaction["reseller_transaction.description"],
      amount: Math.abs(
        +greedyTransaction["reseller_transaction.unutilisedsellingamount"]
      )
    }))
};
