export const actionTypes = {
  ENROLL_SSL: "[SSL Certificate] Enroll SSL",
  OPEN_ENROLL_CERIFICATE_MODAL: "[SSL Certificate] Open SSL enroll modal",

  CHANGE_VERIFICATION_EMAIL_SSL: "[SSL Certificate] Change verification email",
  RECHECK_VERIFICATION_STATUS: "[SSL Certificate] Recheck Verification Status"
};

export const actions = {
  changeVerificationEmailSSL: payload => ({
    type: actionTypes.CHANGE_VERIFICATION_EMAIL_SSL,
    payload
  }),
  enrollSSL: payload => ({ type: actionTypes.ENROLL_SSL, payload }),
  recheckVerificationStatus: payload => ({
    type: actionTypes.RECHECK_VERIFICATION_STATUS,
    payload
  })
};

export const initialState = { manage: { planDetails: {} } };

export const parsers = {
  reissueDetailsParser: ({ actionstatus = "" }, meta) => ({
    isReissue:
      actionstatus !== "WAITING_FOR_VERIFICATION" &&
      actionstatus !== "WAITING_FOR_ENROLLMENT",
    onlyChangeVerificationEmail: actionstatus === "WAITING_FOR_VERIFICATION"
  }),
  reissuePlanParser: (response, { productKey }) => ({
    planDetails: response[productKey]
  })
};
