import webProStore from "ducks";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

export const dangerouslyReadStoreForPaymentStatus = (): boolean => {
  let store = webProStore?.getState();
  let isPaymentsAllowedForUser =
    process.env.FEATURE_ENDPOINT !== "customer" ||
    store?.wallet.paymentGateways.length > 0;
  return isPaymentsAllowedForUser;
};

export const withUserPayments = connect(state => ({
  isPaymentsAllowedForUser:
    process.env.FEATURE_ENDPOINT !== "customer" ||
    state.wallet.paymentGateways.length > 0
}));

export const withPaymentComponent = (
  PaymentComponent,
  PaymentRequestComponent
) => ({ isPaymentsAllowedForUser, ...props }) => {
  if (isPaymentsAllowedForUser) {
    return <PaymentComponent {...props} />;
  } else {
    return <PaymentRequestComponent {...props} />;
  }
};

export default compose(withUserPayments, withPaymentComponent);
