import api from "api";
import constants from "constants/endpoints";

export const services = {
  updateContactDetails: params =>
    api.post(`${constants.zuulOrderBox}contacts/add`, params),
  modifyContactDetails: (orderId, params) =>
    api.post(`${constants.domain}modify-contact`, {
      "order-id": orderId,
      "reg-contact-id": params.reg_contact,
      "admin-contact-id": params.admin_contact,
      "tech-contact-id": params.tech_contact,
      "billing-contact-id": params.billing_contact,
      "designated-agent": params["designated-agent"],
      "sixty-day-lock-optout": params.sixty_day_lockout
    })
};
