import {
  actions as AnalyticsActions,
  categories as AnalyticsCategories,
  ecommerceEventName
} from "analytics/constants";
import { isFailure, isSuccess } from "common/api-status/utils";
import * as PlanDefaults from "common/plan-details/defaults";
import { composeReducers } from "common/util";
import { getSimpleNameFromProductKey } from "constants/index";
import ProductKeyMapping from "constants/ProductKeyMapping";
import { get, uniqBy } from "lodash";

// Common actions that can be performed on a order
export const renewActionTypes = {
  RENEWAL_OPEN: "[Renewal Modal] Open renewal modal",
  RENEWAL_REQUEST: "[Renewal Modal] Renew"
};

export const actionTypes = {
  ORDERS_RENEWED_REQUEST: "[Orders] Renew",

  GET_ORDERS_REQUEST: "[Orders] Fetch Orders",
  GET_OFFLINE_ORDERS_REQUEST: "[Orders] Fetch Offline Orders",
  DELETE_CART_ITEM: "[Orders] Delete Cart item",

  UPDATE_RENEWAL_DETAILS: "[Orders] Update Renewal Details",
  UPDATE_PRICING_AND_ADDON_DETAILS: "[Orders] Update Pricing And Addon Details",
  GET_SHORTCUTS_REQUEST: "[Orders] Update shortcuts",
  GET_DOMAIN_ORDERS: "[Customer Move] Get List of Orders for Domain",
  MOVE_ORDER_REQUEST: "[Customer Move] Move Order",
  CREATE_CUSTOMER_AND_MOVE_ORDER_REQUEST:
    "[Customer Move] Create and Move Request",
  POPULATE_OBOX_ORDER_DETAILS: "[Orders] Populate obox details",
  POPULATE_CUSTOMER_DETAILS: "[Orders] Populate customer details",
  GET_DOMAIN_ORDERS_ORDER_MANAGEMENT: "[Order Management] Get domain orders",
  UPDATE_PLAN_DETAILS: "[Orders] Update plan details",
  SUSPEND_ORDER: "[Order Management] Suspend",
  UNSUSPEND_ORDER: "[Order Management] Unsuspend",
  RENEWAL_REQUEST: "[Renewal Modal] Renew",
  DELETE_ORDER: "[Order Management] Delete",
  CHANGE_DOMAIN: "[Change domain] Change",
  GET_ORDER_LATEST_ACTIVITY: "[Order Management] Get latest activity",
  GET_AUTO_RENEW_DETAILS: "[Orders] Auto renew details",
  MARK_RENEWED: "[Orders] Mark order as renewed",
  RESEND_VERIFICATION_MAIL: "[Dashboard] Resend verification email",
  GET_ONHOLD_ORDERS_REQUEST: "[Orders] Fetch Onhold Orders",
  TOGGLE_ONHOLD_ORDER: "[Orders] Select/Unselect Onhold Order",
  EXECUTE_ONHOLD_ORDERS_REQUEST: "[Orders] Execute Onhold Orders",
  PAY_OUTSTANDING_INVOICES_REQUEST: "[Orders] Pay outstanding invoice",
  OPEN_ONHOLD_ORDERS_MODAL: "[Orders] Open On Hold Orders Modal",
  CLOSE_ONHOLD_ORDERS_MODAL: "[Orders] Close On Hold Orders Modal",
  DISMISS_LOW_FUNDS_ALERT: "[Orders] Dismiss low funds alert",
  INVOICE_MARKED_AS_PAID: "[Orders] Invoice marked as paid"
};

export const actions = {
  openOnHoldOrdersModal(preSelectedOrderIds = []) {
    return {
      type: actionTypes.OPEN_ONHOLD_ORDERS_MODAL,
      preSelectedOrderIds
    };
  },
  closeOnHoldOrdersModal() {
    return {
      type: actionTypes.CLOSE_ONHOLD_ORDERS_MODAL
    };
  },
  createCustomerAndMoveOrder(domainName, losingCustomer, values) {
    return {
      type: actionTypes.CREATE_CUSTOMER_AND_MOVE_ORDER_REQUEST,
      domainName,
      losingCustomer,
      values,
      track: {
        eventCategory: AnalyticsCategories.move,
        eventAction: AnalyticsActions.move_create_new_customer
      }
    };
  },
  moveOrder(domainName, losingCustomer, gainingCustomer) {
    return {
      type: actionTypes.MOVE_ORDER_REQUEST,
      domainName,
      losingCustomer,
      gainingCustomer,
      track: {
        eventCategory: AnalyticsCategories.move,
        eventAction: AnalyticsActions.add_a_customer_dashboard
      }
    };
  },
  moveOrderSuccess({ customerid, name }, domainName) {
    return {
      type: isSuccess(actionTypes.MOVE_ORDER_REQUEST),
      customerid,
      defaultMessage: `Move of order to ${name} was successful`,
      domainName,
      track: {
        eventCategory: ecommerceEventName.gtmMoveOrders,
        eventAction: "gtmMoveOrders"
      }
    };
  },
  moveOrderFailure(error) {
    return {
      type: isFailure(actionTypes.MOVE_ORDER_REQUEST),
      error
    };
  },
  getOrdersForDomain(domainName) {
    return {
      type: actionTypes.GET_DOMAIN_ORDERS,
      domainName
    };
  },
  getOrdersForDomainOrderManagement: domainName => ({
    type: actionTypes.GET_DOMAIN_ORDERS_ORDER_MANAGEMENT,
    domainName
  }),
  getOrders(orderType, loadFromStart, loadShortcuts) {
    return {
      type: actionTypes.GET_ORDERS_REQUEST,
      orderType,
      loadFromStart,
      loadShortcuts
    };
  },
  getOrdersSuccess(payload, orderType, maxCount = 0) {
    return {
      type: isSuccess(actionTypes.GET_ORDERS_REQUEST),
      payload,
      orderType,
      maxCount
    };
  },
  getOrdersFailure(parent, error) {
    return {
      type: isFailure(actionTypes.GET_ORDERS_REQUEST),
      error,
      parent
    };
  },
  getOrderRequests(orderType, loadFromStart, loadShortcuts) {
    return {
      type: actionTypes.GET_OFFLINE_ORDERS_REQUEST,
      orderType,
      loadFromStart,
      loadShortcuts
    };
  },
  getOrderRequestsSuccess(payload, orderType, maxCount = 0) {
    return {
      type: isSuccess(actionTypes.GET_OFFLINE_ORDERS_REQUEST),
      payload,
      orderType,
      maxCount
    };
  },
  getOrderRequestsFailure(parent, error) {
    return {
      type: isFailure(actionTypes.GET_OFFLINE_ORDERS_REQUEST),
      error,
      parent
    };
  },
  deleteCartItem(payload) {
    return {
      type: actionTypes.DELETE_CART_ITEM,
      payload
    };
  },
  getShortcutsSuccess(payload) {
    return {
      type: isSuccess(actionTypes.GET_SHORTCUTS_REQUEST),
      payload
    };
  },
  getShortcutsError(payload, error) {
    return {
      type: isFailure(actionTypes.GET_SHORTCUTS_REQUEST),
      payload,
      error
    };
  },
  renewOrders(payload) {
    return {
      type: actionTypes.ORDERS_RENEWED_REQUEST,
      payload
    };
  },
  renewOrdersSuccess() {
    return {
      type: isSuccess(actionTypes.ORDERS_RENEWED_REQUEST)
    };
  },
  updateRenewalDetails(payload) {
    return {
      type: actionTypes.UPDATE_RENEWAL_DETAILS,
      payload
    };
  },
  updatePricingAndAddonDetailsWithCb(order, callback) {
    return {
      type: actionTypes.UPDATE_PRICING_AND_ADDON_DETAILS,
      order,
      callback
    };
  },
  populateOboxOrderDetails(
    oboxOrderDetails,
    orderDetailsFormattedUrl,
    formattedOboxOrderDetails
  ) {
    const { orderid } = oboxOrderDetails;
    return {
      type: actionTypes.POPULATE_OBOX_ORDER_DETAILS,
      orderid,
      response: {
        oboxOrderDetails,
        orderDetailsFormattedUrl,
        formattedOboxOrderDetails
      }
    };
  },
  populateCustomerDetails(payload) {
    return {
      type: actionTypes.POPULATE_CUSTOMER_DETAILS,
      payload
    };
  },
  populateCustomerDetailsFailure(error) {
    return {
      type: isFailure(actionTypes.POPULATE_CUSTOMER_DETAILS),
      error
    };
  },
  populateOboxOrderDetailsFailure(error) {
    return {
      type: isFailure(actionTypes.POPULATE_OBOX_ORDER_DETAILS),
      error
    };
  },
  getPlanDetails: meta => ({
    type: actionTypes.UPDATE_PLAN_DETAILS,
    meta
  }),
  getAutoRenewDetailsSuccess(detailsByOrderId) {
    return {
      type: isSuccess(actionTypes.GET_AUTO_RENEW_DETAILS),
      detailsByOrderId
    };
  },
  getAutoRenewDetailsFailure(error) {
    return {
      type: isFailure(actionTypes.GET_AUTO_RENEW_DETAILS),
      error
    };
  },
  markAsRenewed(orderId) {
    return { type: actionTypes.MARK_RENEWED, orderId };
  },
  getOrderLatestActivity(orderId) {
    return { type: actionTypes.GET_ORDER_LATEST_ACTIVITY, orderId };
  },
  resendNotification(orderId) {
    return { type: actionTypes.RESEND_VERIFICATION_MAIL, orderId };
  },
  getOnholdOrders(selectedOrderIds) {
    return { type: actionTypes.GET_ONHOLD_ORDERS_REQUEST, selectedOrderIds };
  },
  toggleOnholdOrder(orderId) {
    return { type: actionTypes.TOGGLE_ONHOLD_ORDER, orderId };
  },
  dismissLowFundsAlert() {
    return { type: actionTypes.DISMISS_LOW_FUNDS_ALERT };
  },
  invoiceMarkedAsPaid(orderId) {
    return { type: actionTypes.INVOICE_MARKED_AS_PAID, orderId };
  }
};
/**
 *
 * @param {keyof typeof ProductKeyMapping} productKey
 * @param {string} productName
 * @returns {Record<string, any>}
 */
function createTemplate(productKey, productName) {
  return {
    oboxOrderDetails: {
      productcategory: "hosting",
      currentstatus: "dummy",
      orderid: `${productKey}template`,
      productkey: productKey
    },
    currentstatus: "dummy",
    activity: [],
    isDummyOrder: true,
    product_category: productName,
    productType: getSimpleNameFromProductKey(productKey),
    orderid: `${productKey}template`,
    productkey: productKey,
    productDetailsKey: productKey,
    productKey,
    productCategory: "hosting",
    productcategory: "hosting",
    meta: {
      ...ProductKeyMapping[productKey].meta,
      currentstatus: "dummy",
      orderid: `${productKey}template`,
      product_category: productName,
      productCategory: productName,
      productkey: productKey,
      productDetailsKey: productKey,
      productKey,
      productcategory: "hosting"
    }
  };
}

const initialState = {
  recent: { orderIDs: [], maxCount: 0 },
  expired: { orderIDs: [], maxCount: 0 },
  expiring: { orderIDs: [], maxCount: 0 },
  ordersByOrderID: {
    titanmailindiatemplate: createTemplate(
      "titanmailindia",
      "Titan EMail (India)"
    ),
    titanmailglobaltemplate: createTemplate(
      "titanmailglobal",
      "Titan EMail (Global)"
    )
  },
  customersByID: {},
  dashboard: { actionsByOrderID: {} },
  autoRenewDetails: {},
  renewalDetails: {},
  customerMove: { orders: [], completed: false, errors: null },
  planDetails: {
    codeguard: PlanDefaults.CODEGUARD,
    sitelock: PlanDefaults.SITELOCK
  },
  onholdOrders: [],
  lowFundsAlertFlag: true,
  orderRequests: {}
};

const internalActionReducer = (state = initialState, action) => {
  switch (action.type) {
    case isFailure(actionTypes.GET_DOMAIN_ORDERS):
    case isFailure(actionTypes.MOVE_ORDER_REQUEST):
      return {
        ...state,
        customerMove: {
          ...state.customerMove,
          completed: false,
          errors: action.error
        }
      };
    case isSuccess(actionTypes.MOVE_ORDER_REQUEST):
      return {
        ...state,
        customerMove: {
          ...state.customerMove,
          completed: true
        }
      };
    case actionTypes.GET_DOMAIN_ORDERS:
      return {
        ...state,
        customerMove: {
          ...state.customerMove,
          errors: null,
          completed: false
        }
      };
    case isSuccess(actionTypes.GET_DOMAIN_ORDERS):
      return {
        ...state,
        customerMove: { ...state.customerMove, orders: action.effectResponse }
      };

    case isSuccess(actionTypes.GET_AUTO_RENEW_DETAILS): {
      return {
        ...state,
        autoRenewDetails: {
          ...state.autoRenewDetails,
          ...action.detailsByOrderId
        }
      };
    }

    case actionTypes.MARK_RENEWED: {
      let { orderId } = action;
      let renewedOrder = state.ordersByOrderID[orderId];
      if (renewedOrder.isExpired || renewedOrder.isExpiring) {
        // This happens only when the renewal on OB is not instantaneous.
        return state;
      }
      let isExpiringOrder = state.expiring.orderIDs.includes(orderId);
      let isExpiredOrder = state.expired.orderIDs.includes(orderId);
      return isExpiredOrder || isExpiringOrder
        ? {
            ...state,
            ...(isExpiringOrder && {
              expiring: {
                ...state.expiring,
                maxCount: state.expiring.maxCount - 1,
                orderIDs: state.expiring.orderIDs.filter(
                  orderId => orderId !== action.orderId
                )
              }
            }),
            ...(isExpiredOrder && {
              expired: {
                ...state.expired,
                maxCount: state.expired.maxCount - 1,
                orderIDs: state.expired.orderIDs.filter(
                  orderId => orderId !== action.orderId
                )
              }
            })
          }
        : state;
    }

    case isSuccess(actionTypes.GET_ORDERS_REQUEST): {
      let { orderType, payload, maxCount } = action;
      const orderIDs = Array.from(
        new Set([
          ...state[orderType].orderIDs,
          ...payload.map(order => order.orderid)
        ])
      );

      return {
        ...state,
        [orderType]: { ...state[orderType], maxCount, orderIDs },
        ordersByOrderID: {
          ...state.ordersByOrderID,
          ...payload.reduce(
            (orders, order) => ({
              ...orders,
              [order.orderid]: {
                ...get(state.ordersByOrderID, order.orderid, {}),
                ...order,
                orderDetailsFormattedUrl: get(
                  state.ordersByOrderID,
                  [order.orderid, "orderDetailsFormattedUrl"],
                  ""
                ),
                oboxOrderDetails: get(
                  state.ordersByOrderID,
                  [order.orderid, "oboxOrderDetails"],
                  {}
                )
              }
            }),
            {}
          )
        }
      };
    }

    case isSuccess(actionTypes.GET_OFFLINE_ORDERS_REQUEST): {
      let { payload } = action;
      return {
        ...state,
        orderRequests: { payload }
      };
    }

    case actionTypes.POPULATE_OBOX_ORDER_DETAILS: {
      const {
        response: {
          oboxOrderDetails,
          orderDetailsFormattedUrl,
          formattedOboxOrderDetails
        }
      } = action;
      const { orderid } = oboxOrderDetails;
      return {
        ...state,
        ordersByOrderID: {
          ...state.ordersByOrderID,
          [orderid]: {
            ...get(state.ordersByOrderID, orderid, {}),
            ...formattedOboxOrderDetails,
            orderDetailsFormattedUrl,
            oboxOrderDetails
          }
        }
      };
    }

    case actionTypes.POPULATE_CUSTOMER_DETAILS: {
      let customer = action.payload;
      return {
        ...state,
        customersByID: {
          ...state.customersByID,
          [customer.customerid]: customer
        }
      };
    }

    case isSuccess(actionTypes.GET_SHORTCUTS_REQUEST): {
      const { actionList, orderID } = action.payload;
      return {
        ...state,
        dashboard: {
          actionsByOrderID: {
            ...state.dashboard.actionsByOrderID,
            [orderID]: {
              actionList: actionList.length > 0 ? actionList : undefined
            }
          }
        }
      };
    }
    case actionTypes.UPDATE_RENEWAL_DETAILS: {
      const { type: updateType, orderid: orderID, ...payload } = action.payload;
      let existingDetails = get(state.renewalDetails, orderID, {
        phone: 0,
        pricing: {},
        expirydatetime: 0,
        orderDetails: {},
        unit: "",
        addonDetails: {}
      });

      switch (updateType) {
        case "customer": {
          const {
            value: { telno, telnocc }
          } = payload;
          return {
            ...state,
            renewalDetails: {
              ...state.renewalDetails,
              [orderID]: { ...existingDetails, phone: telno, telnocc }
            }
          };
        }
        case "price": {
          const {
            value: {
              pricingDetails: pricing,
              customerPricing,
              ...restoreDetails
            },
            expirydatetime,
            expirytimestamp,
            orderDetails,
            unit
          } = payload;

          return {
            ...state,
            renewalDetails: {
              ...state.renewalDetails,
              [orderID]: {
                ...existingDetails,
                pricing,
                customerPricing,
                expirydatetime,
                expirytimestamp,
                orderDetails,
                unit,
                restoreDetails
              }
            }
          };
        }
        case "orderdetails": {
          const {
            addonDetails,
            pricingDetails: pricing,
            expirydatetime,
            expirytimestamp,
            miscDetails,
            planDetails,
            orderDetails,
            unit,
            customerPricing
          } = payload;

          return {
            ...state,
            renewalDetails: {
              ...state.renewalDetails,
              [orderID]: {
                ...existingDetails,
                addonDetails,
                miscDetails,
                pricing,
                customerPricing,
                planDetails,
                expirydatetime,
                expirytimestamp,
                orderDetails,
                unit
              }
            }
          };
        }
        default:
          return state;
      }
    }

    case isSuccess(actionTypes.GET_DOMAIN_ORDERS_ORDER_MANAGEMENT): {
      let orders = action.effectResponse ?? [];
      let domainOrders = Object.fromEntries(
        orders.map(order => [
          order.orderid,
          { ...(state.ordersByOrderID[order.orderid] ?? {}), ...order }
        ])
      );
      return {
        ...state,
        ordersByOrderID: { ...state.ordersByOrderID, ...domainOrders }
      };
    }

    case isSuccess(actionTypes.UPDATE_PLAN_DETAILS): {
      return {
        ...state,
        planDetails: {
          ...state.planDetails,
          [action?.meta?.productKey]: action.effectResponse
        }
      };
    }

    case isSuccess(actionTypes.GET_ORDER_LATEST_ACTIVITY): {
      let {
        effectResponse: { activity },
        orderId
      } = action;
      return {
        ...state,
        ordersByOrderID: {
          ...state.ordersByOrderID,
          [orderId]: {
            ...(state.ordersByOrderID[orderId] ?? {}),
            activity: uniqBy(
              [
                ...activity,
                ...(state.ordersByOrderID[orderId]?.activity ?? [])
              ],
              "eaqid"
            )
          }
        }
      };
    }

    case isSuccess(actionTypes.GET_ONHOLD_ORDERS_REQUEST): {
      return {
        ...state,
        onholdOrders: action.effectResponse
      };
    }

    case actionTypes.TOGGLE_ONHOLD_ORDER: {
      return {
        ...state,
        onholdOrders: state.onholdOrders.map(order => {
          if (order.orderId === action.orderId) {
            return {
              ...order,
              isSelected: !order.isSelected
            };
          }
          return order;
        })
      };
    }
    case actionTypes.OPEN_ONHOLD_ORDERS_MODAL: {
      return {
        ...state,
        onholdOrders: state.onholdOrders.map(order => ({
          ...order,
          isSelected:
            action.preSelectedOrderIds.length === 0 ||
            action.preSelectedOrderIds.indexOf(String(order.orderId)) >= 0 ||
            // action.preSelectedOrderIds.includes(order.orderId) ||
            false
        }))
      };
    }
    case actionTypes.DISMISS_LOW_FUNDS_ALERT: {
      return {
        ...state,
        lowFundsAlertFlag: false
      };
    }

    case actionTypes.INVOICE_MARKED_AS_PAID: {
      return {
        ...state,
        ordersByOrderID: {
          ...state.ordersByOrderID,
          [action.orderId]: {
            ...get(state.ordersByOrderID, action.orderId, {}),
            pendingTransaction: null
          }
        }
      };
    }

    default:
      return state;
  }
};

const externalActionReducer = (state = initialState, action) => {
  switch (action.type) {
    case isSuccess(actionTypes.DELETE_ORDER):
    case isSuccess(actionTypes.CHANGE_DOMAIN):
      // TODO: Needs getDetails after successful move
      // TODO: Maybe we just clear out data for an particular orderId?
      return initialState;
    default:
      return state;
  }
};

export default composeReducers(internalActionReducer, externalActionReducer);
