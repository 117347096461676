import { last, isEmpty } from "lodash";
import { FormikErrors } from "formik";
type FieldCheck<Values> = {
  isWrong: (v: Values) => boolean;
  message: (v: Values) => string;
};
type FlatCheck<Values> = FieldCheck<Values> | FieldCheck<Values>[];
type NestedCheck<Values> = { arrayKey: keyof Values } & {
  [X in keyof Values]?: FlatCheck<Values>;
};
type ErrorCheck<Values> = NestedCheck<Values> | FlatCheck<Values>;

export type ErrorSheet<Values> = {
  [K in keyof Values]?: ErrorCheck<Values>;
};
type FormikValidator<Values> = (values: Values) => FormikErrors<Values>;

export function createFormikValidator<Values>(
  errorSheet: ErrorSheet<Values>
): FormikValidator<Values> {
  return values => {
    let errors = {};
    for (const [errorName, checks] of Object.entries<ErrorCheck<Values>>(
      errorSheet
    )) {
      if ((checks as NestedCheck<Values>).arrayKey) {
        let { arrayKey, ...errorSheet } = checks as NestedCheck<Values>;
        const errorList = values[checks["arrayKey"]].map(
          createFormikValidator(errorSheet as unknown)
        );
        if (!errorList.every(isEmpty)) {
          errors[arrayKey as string] = errorList;
        }
      } else {
        let errorMessages = ([] as FieldCheck<Values>[])
          .concat(checks as FlatCheck<Values>)
          .filter(opt => opt.isWrong(values))
          .map(opt => opt.message(values));
        if (errorMessages.length > 0) {
          errors[errorName] = Array.isArray(checks)
            ? errorMessages
            : last(errorMessages);
        }
      }
    }
    return errors;
  };
}
