import { getLocationFromProductName, SimpleName } from "constants/index";

export const PanelPorts = { CPANEL: 2087, PLESK: 8880 };

export function getDefaultPanel(simpleName: SimpleName, productKey: string) {
  switch (simpleName) {
    case "gsuite":
    case "businessemail":
    case "enterpriseemail":
      return "mailhosting";
    case "titanmail":
      return "titanmail";
    case "wph":
      return "adminpanel";
    case "websitebuilder":
    case "hosting":
      return "websitebuilder";
    case "rchosting":
    case "hgdedicatedserver":
    case "bhvpshosting":
    case "dedibybh":
    case "managedserver":
    case "dedicatedserver":
    case "sdh":
    case "mdh":
      return "webhosting";
    case "vpshosting":
      return getLocationFromProductName(productKey) === "us"
        ? productKey
        : "webhosting";
    default:
      return productKey;
  }
}
