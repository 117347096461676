import classnames from "classnames";
import ModalHandler from "common/modal-handler";
import { get, isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { constants } from "./ducks";
import "./styles.scss";

export class InlineNotification extends React.Component {
  static propTypes = {
    messageMap: PropTypes.object,
    triggeredBy: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    messageType: PropTypes.oneOf([
      constants.SUCCESS,
      constants.ERROR,
      constants.PENDING
    ]),
    getMessage: PropTypes.func,
    defaultMessage: PropTypes.string,
    hideAfter: PropTypes.number,
    showDismiss: PropTypes.bool
  };

  static defaultProps = {
    messageMap: {},
    getMessage: (_) => null,
    defaultMessage: "",
    hideAfter: 4000,
    messageType: constants.SUCCESS,
    showDismiss: true
  };

  getMessage = (action) =>
    this.props.getMessage(action) ||
    action.defaultMessage ||
    action.error?.message ||
    get(this.props.messageMap, action.type, this.props.defaultMessage);

  renderNotification = ({ show, onHide }, triggerAction = {}) => {
    const { hideAfter, messageType, showDismiss } = this.props;
    const { notificationType = messageType } = triggerAction;
    let message = this.getMessage(triggerAction);
    if (isEmpty(message)) {
      return null;
    }
    if (show) {
      setTimeout(onHide, hideAfter);
    }
    return (
      show && (
        <div
          className={classnames("notification", {
            "notification-success": notificationType === constants.SUCCESS,
            "notification-error": notificationType === constants.ERROR,
            "notification-pending": notificationType === constants.PENDING
          })}
        >
          {notificationType !== constants.ERROR && (
            <span className="notification-tick" />
          )}
          {message}
          {showDismiss && (
            <span
              role="button"
              title="Dismiss Notification"
              className="notification-dismiss"
              onClick={onHide}
            >
              x
            </span>
          )}
        </div>
      )
    );
  };

  render() {
    return (
      <ModalHandler
        id={this.props.defaultMessage}
        opensOn={this.props.triggeredBy || Object.keys(this.props.messageMap)}
        render={this.renderNotification}
      />
    );
  }
}
