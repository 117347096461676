import {
  actions as AnalyticsActions,
  categories as AnalyticsCategories
} from "analytics/constants";
import { isSuccess } from "common/api-status/utils";

export const actionTypes = {
  CREATE_CUSTOMER: "[Customer] Create",
  VALIDATE_ZIPCODE: "[Customer create] Validate zipcode",
  GET_COUNTRY_FROM_ZIPCODE: "[Customer create] Get country from zipcode"
};

export const actions = {
  validateZipcode: (zipcode, countryCode) => ({
    type: actionTypes.VALIDATE_ZIPCODE,
    payload: { countryCode, zipcode }
  }),
  getCountryFromZipcode: (zipcode, setFieldValue) => ({
    type: actionTypes.GET_COUNTRY_FROM_ZIPCODE,
    payload: { zipcode },
    setFieldValue
  }),
  createNewCustomer: (payload, onSuccess = _ => {}) => ({
    type: actionTypes.CREATE_CUSTOMER,
    payload,
    onSuccess,
    track: {
      eventCategory: AnalyticsCategories.customer_management,
      eventAction: AnalyticsActions.create_customer
    }
  })
};

const initialState = { isZipcodeValid: true };

export default (state = initialState, action) => {
  switch (action.type) {
    case isSuccess(actionTypes.VALIDATE_ZIPCODE): {
      return {
        ...state,
        isZipcodeValid: action.effectResponse
      };
    }
    default:
      return state;
  }
};
