import { Discount } from "common/pricing-strategy/index";
import { fonts } from "common/styles/index";
import { css } from "linaria";
import * as React from "react";

type DiscountBannerProps = { discount?: Discount };

const Star = props => (
  <svg width="12px" height="12px" viewBox="0 0 12 12" {...props}>
    <g
      transform="translate(-563 -282) translate(362 135) translate(51 139) translate(150 6) translate(0 2)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <circle fill="#9C82E3" cx={6} cy={6} r={6} />
      <path
        d="M8.858 5.168l-1.943-.19-.772-1.88a.153.153 0 00-.286 0l-.772 1.88-1.943.19c-.137.013-.192.19-.089.285l1.466 1.35L4.09 8.8c-.03.14.114.25.232.177L6 7.932l1.678 1.045c.118.073.262-.037.232-.177l-.43-1.996 1.467-1.351c.103-.095.048-.272-.089-.285z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export function EmailDiscountBanner({ discount }: DiscountBannerProps) {
  return discount ? (
    <div
      className={css`
        align-items: center;
        background: #f3f0ff;
        color: #7159b2;
        display: flex;
        font: 700 11px/1 ${fonts.BrandonGrotesque};
        justify-content: center;
        letter-spacing: 0.5px;
        padding: 7px 0;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
      `}
    >
      <Star />
      <span
        className={css`
          padding: 0 10px;
        `}
      >
        {discount.value}
        {discount.type === "PERCENT" ? "%" : ""} off for the first{" "}
        {discount.number_of_accounts} accounts
      </span>
      <Star />
    </div>
  ) : null;
}
