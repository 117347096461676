import { isSuccess } from "common/api-status/utils";
import { call, put, takeEvery } from "redux-saga/effects";
import { actions, actionTypes } from "./ducks";
import { actions as commonActions } from "widgets/common/ducks";
import { services } from "widget-models/manage-addons/services";
import { getOrderDetails, getPricingFromProductKey } from "common/orders/sagas";
import { mapEffect } from "map-effect";

export const managedServicesSagas = [
  takeEvery(
    actionTypes.GET_PLAN_DETAILS_MANAGED_SERVICES_ADDON,
    mapEffect(getMSAddonPlanDetails)
  ),
  takeEvery(
    isSuccess(actionTypes.GET_PLAN_DETAILS_MANAGED_SERVICES_ADDON),
    mapEffect(
      getMSAddonPricingDetails,
      actionTypes.GET_PRICING_DETAILS_MANAGED_SERVICES_ADDON
    )
  )
];

function getMSAddonPricingDetails(action) {
  return getPricingFromProductKey(
    action.effectResponse.orderDetails.productkey
  );
}

function* getMSAddonPlanDetails(action) {
  let orderDetails = yield call(getOrderDetails, action.payload, {
    forceReload: false,
    getRawData: true
  });

  yield put(
    commonActions.setupState({
      meta: action.payload,
      hierarchy: action.payload
    })
  );

  const planDetails = yield services.getPlanDetails(action.payload);
  return { planDetails, orderDetails };
}
