import api from "api";
import constants from "constants/index";

export const services = {
  saveDomainForwardingDetails: (params, orderId) =>
    api.postBody(
      `${constants.zuulOrderBox}domainforward/manage`,
      new URLSearchParams({
        "order-id": orderId,
        "forward-to": params.forward_to,
        "url-masking": params.url_masking,
        "meta-tags": params.meta_tags,
        noframes: params.no_frames,
        "sub-domain-forwarding": params.sub_domain_forwarding,
        "path-forwarding": params.path_forwarding
      })
    ),
  activateDomainForwarding: orderId =>
    api.post(`${constants.zuulOrderBox}domainforward/activate`, {
      "order-id": orderId
    }),
  deleteDomainForwarding: domainName =>
    api.post(`${constants.zuulOrderBox}domainforward/delete`, {
      "domain-name": domainName
    })
};
