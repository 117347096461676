import api from "api";
import endpoints from "constants/endpoints";
import * as React from "react";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

const BillingMode = "ManualBilling";
const Endpoints = {
  BILLING_MODE: `${endpoints.zuulOrderBox}reseller/billing-mode`
};

export function useBillingMode() {
  let billingMode = useSWR<string>(Endpoints.BILLING_MODE);
  let mutation = useSWRMutation(Endpoints.BILLING_MODE, url =>
    api.call("PUT", url, { mode: BillingMode })
  );
  return [billingMode.data === BillingMode, mutation] as const;
}

export const withBillingPanda = function(Component) {
  return function(props) {
    let [isBillingActive] = useBillingMode();
    return <Component isBillingActive={isBillingActive} {...props} />;
  };
};
