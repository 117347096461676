export const actionTypes = {
  PAY_PENDING_AMOUNT: "[Transactions] Pay pending amount",
  SHOW_PENDING_PAYMENT_MODAL: "[Transactions] Show pending payment modal",
  HIDE_PENDING_PAYMENT_MODAL: "[Transactions] Hide pending payment modal",
  SHOW_CANCEL_PENDING_INVOICE_MODAL:
    "[Transactions] Show Cancel Pending Invoice modal",
  HIDE_CANCEL_PENDING_INVOICE_MODAL:
    "[Transactions] Hide Cancel Pending Invoice modal",
  CANCEL_PENDING_INVOICE: "[Transactions] Cancel Pending Invoice"
};

export const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_PENDING_PAYMENT_MODAL: {
      return {
        ...state,
        pendingPaymentInfoForCurrentTrans: {
          ...action.payload,
          title: `PAY ${action.payload.type.toUpperCase()}`
        }
      };
    }

    default:
      return state;
  }
};

export const actions = {
  showPendingPaymentModal: payload => ({
    type: actionTypes.SHOW_PENDING_PAYMENT_MODAL,
    payload
  }),
  showCancelPendingInvoiceModal: payload => ({
    type: actionTypes.SHOW_CANCEL_PENDING_INVOICE_MODAL,
    payload
  })
};
