import { isSuccess } from "common/api-status/utils";
import { actionTypes as orderActionTypes } from "common/orders/ducks";
import {
  getCustomerDetails,
  updatePricingAndAddonDetails
} from "common/orders/sagas";
import { renewalActions } from "common/renewal-modals/renewal-modal";
import { getSimpleNameFromProductKey } from "constants/index";
import { mapEffect } from "map-effect";
import { call, put, takeEvery } from "redux-saga/effects";
import { actionTypes, actions } from "./ducks";
import { services } from "./services";

export const orderManagementSagas = [
  takeEvery(actionTypes.SUSPEND_ORDER, mapEffect(onSuspendOrder)),
  takeEvery(actionTypes.UNSUSPEND_ORDER, mapEffect(onUnsuspendOrder)),
  takeEvery(actionTypes.GET_RENEWAL_PRICING, mapEffect(getRenewalPricing)),
  takeEvery(actionTypes.DELETE_ORDER, onDeleteOrder),
  takeEvery(
    isSuccess(orderActionTypes.GET_DOMAIN_ORDERS_ORDER_MANAGEMENT),
    getCustomerInfo
  )
];

function* onDeleteOrder(action) {
  try {
    yield services.deleteOrder(
      action.endpoint,
      action.orderid,
      action.reason,
      getSimpleNameFromProductKey(action.productDetailsKey)
    );
    yield put(actions.deleteOrderSuccess(action.orderid));
    // browserHistory.push("/orders"); // TODO: Come up with a better solution.
  } catch (error) {
    yield put(actions.deleteOrderFailure(error, action.orderid));
  }
}

function onSuspendOrder(action) {
  return services.suspendOrder(action.payload);
}

function onUnsuspendOrder(action) {
  return services.unSuspendOrder(action.payload);
}

/**
 * Loads customer information in the order management page
 */
function* getCustomerInfo(action) {
  const [order] = Object.values(action.effectResponse ?? []);
  if (order) {
    yield call(getCustomerDetails, order.meta);
  }
}

function* getRenewalPricing(action) {
  yield call(updatePricingAndAddonDetails, action.orderdetails, null, null);
  yield put(renewalActions.openRenewalModal(action.orderdetails));
}
