import { isEmpty } from "lodash";
import { useMemo } from "react";
import useSWR from "swr";

export let LoadingEffect = Symbol.for("loading");
export let CompletedEffect = Symbol.for("done");

/**
 * @typedef {Object} APIStatus
 * @property {boolean} isFetching - Indicates whether the loader is in progress.
 * @property {boolean} isCompleted - Indicates whether the loader is completed successfully.
 * @property {boolean} isSettled - Indicates whether the loader is completed.
 * @property {any} errors - Provide errors if any
 */

/**
 * @param {symbol} loadingState
 * @returns {APIStatus}
 */
function getAPIStatus(loadingState) {
  return {
    get isFetching() {
      return loadingState === LoadingEffect;
    },
    get isCompleted() {
      return loadingState === CompletedEffect;
    },
    get isSettled() {
      return loadingState !== LoadingEffect;
    },
    get errors() {
      return loadingState instanceof Error ? loadingState : null;
    }
  };
}

export function useAPIEffect([state, setState]) {
  let apiStatus = useMemo(() => getAPIStatus(state), [state]);
  function addEffectHandlers(effectProducer) {
    setState(LoadingEffect);
    return effectProducer()
      .then(() => {
        setState(CompletedEffect);
        return null;
      })
      .catch(error => {
        setState(error);
        return error;
      })
      .then(output => {
        setTimeout(() => setState(null), 2000);
        return output;
      });
  }
  return [apiStatus, setState, addEffectHandlers];
}

/**
 *
 * @param {string|null} endpoint
 * @param {any} update
 * @returns {readonly [APIStatus, {refetch: import("swr").SWRResponse["mutate"]}]}
 * @deprecated Use {@link https://swr.vercel.app SWR} instead
 */
export function useAPI(endpoint, update) {
  let swr = useSWR(isEmpty(endpoint) ? null : endpoint, {
    onSuccess(data) {
      if (update) {
        update(data);
      }
    }
  });
  return useMemo(() => [getAPIEffect(swr), { refetch: () => swr.mutate() }], [
    swr
  ]);
}

/**
 *
 * @param {import("swr").SWRResponse} swr
 * @returns {APIStatus}
 */
export function getAPIEffect(swr) {
  return {
    isFetching: swr.isLoading,
    isCompleted: swr.data !== undefined,
    isSettled: !swr.isLoading && !swr.isValidating,
    errors: swr.error ?? null
  };
}
