import {
  actions as AnalyticsActions,
  categories as AnalyticsCategories,
  labels as AnalyticsLabels
} from "analytics/constants";
import { isSuccess } from "common/api-status/utils";

export const actionTypes = {
  GET_LOCK_DETAILS: "[Domain Lock] get lock details",
  LOCK: "[Domain Lock] Lock",
  UNLOCK: "[Domain Lock] unlock"
};

export const initialState = {
  resellerLock: { isEnabled: false, reason: "" }
};

export default (state = {}, action) => {
  switch (action.type) {
    case isSuccess(actionTypes.GET_LOCK_DETAILS): {
      return {
        ...state,
        domain: { ...state.domain, resellerLock: action.effectResponse }
      };
    }

    default: {
      return state;
    }
  }
};

export const actions = {
  lock: ({ category, orderid, reason }) => ({
    type: actionTypes.LOCK,
    orderid,
    payload: { orderid, reason },
    track: {
      eventCategory: AnalyticsCategories.order_micro_states,
      eventAction: `${AnalyticsActions.lock} ${category}`,
      eventLabel: AnalyticsLabels.lock
    }
  }),
  unlock: orderid => ({ type: actionTypes.UNLOCK, orderid })
};
