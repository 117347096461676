export const actionTypes = {
  SHOW_ADD_MODAL: "[Manage Storage] Show Add modal",
  HIDE_ADD_MODAL: "[Manage Storage] Hide Create Modal",
  PURCHASE_STORAGE: "[Manage Storage] Purchase",
  GO_TO_KNOW_MORE: "[Manage Storage] Go to Know more"
};

export const initialState = {
  manageStorageSize: {
    details: {},
    pricing: {},
    meta: {},
    location: {},
    totalStorageSizeInGB: 0
  }
};

export const parsers = {
  storageSizeDetailsParser: (_, meta) => {
    return { details: meta.details, meta, location: meta.location };
  },
  totalStorageSizeParser: payload => ({
    totalStorageSizeInGB: payload.totalStorageSizeInGB
  }),
  backupDetailsParser: (_, meta) => {
    return {
      meta
    };
  },
  getPricingDetails: (payload = {}, { productkey, details }) => {
    const {
      customer_pricing = {},
      [productkey]: { plans = {} } = ({} = customer_pricing)
    } = payload;
    return { pricing: plans[details.planid] };
  }
};
