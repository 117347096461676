import { formatAmountWithCurrency } from "common/localize-amount";
import { connect, useSelector } from "react-redux";

const selector = state => ({
  myCurrencySymbol: state.wallet.myCurrencySymbol,
  currencySymbol: state.wallet.currencySymbol,
  walletBalance: state.wallet.walletBalance,
  myCurrencySymbolUnit: formatAmountWithCurrency({
    currency: state.wallet.myCurrencySymbol,
    currencyDisplay: "symbol"
  }),
  taxinfo: state.wallet.taxinfo
});

export let withWalletInfo = connect(selector);

export let useWalletInfo = () => useSelector(selector);
