import storage from "@faizaanceg/pandora";

export const addQueryParamsToUrl = (
  url: string,
  queryParams: Record<string, any> = {}
): string => {
  const parsedUrl = new URL(url, window.location.origin);
  url = url.replace(parsedUrl.search, "");
  let search = parsedUrl.searchParams;
  for (const [key, values] of Object.entries(queryParams)) {
    if (values !== undefined) {
      [].concat(values).forEach(value => search.append(key, value));
    }
  }
  let qs = search.toString();
  return qs.length === 0 ? url : `${url}?${qs}`;
};

export const getOBLogoutUrl = () =>
  process.env.FEATURE_ENDPOINT !== "reseller"
    ? "/login"
    : process.env.BUILD_ENV === "production"
    ? addQueryParamsToUrl(
        "https://www.foundationapi.com/servlet/LogoutServlet",
        {
          loginPageRole: "reseller",
          currenturl: `https://${storage.get(
            "parentbrandingurl",
            window.location.hostname
          )}`
        }
      )
    : "/tools";

const COOKIE_SEPARATOR = "; ";
const COOKIE_SPLITTER = "=";

export const parseCookies = (cookies = ""): Record<string, string> =>
  Object.fromEntries(
    cookies.split(COOKIE_SEPARATOR).map(cookie => cookie.split(COOKIE_SPLITTER))
  );

export function parseSearch(searchString) {
  return Object.fromEntries(new URLSearchParams(searchString).entries());
}
