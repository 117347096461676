import {
  actions as AnalyticsActions,
  categories as AnalyticsCategories,
  labels as AnalyticsLabels
} from "analytics/constants";
import { isFailure, isSuccess } from "common/api-status/utils";
import { parseStringAsBoolean } from "common/util";
import { isEmpty } from "lodash";

export const actionTypes = {
  SHOW_MODAL: "[Domain Forwarding] Show",
  SHOW_DELETE_MODAL: "[Domain Forwarding] Show Delete",
  SAVE_DOMAIN_FORWARDING_DETAILS: "[Domain Forwarding] Save",
  ACTIVATE_DOMAIN_FORWARDING: "[Domain Forwarding] Activate",
  DELETE_DOMAIN_FORWARDING: "[Domain Forwarding] Delete"
};

export const initialState = {
  domainForwarding: {
    isEnabled: false,
    forwardToUrl: "",
    urlMasking: {
      isEnabled: false,
      headerTags: {
        isShow: false,
        data: ""
      },
      noFramesPageContent: {
        isShow: false,
        data: ""
      }
    },
    isSubDomainForwardingEnabled: false,
    isPathForwardingEnabled: false,
    isUsingDefaultNameServers: true
  }
};

export const actions = {
  showModal: meta => ({
    type: actionTypes.SHOW_MODAL,
    ...meta
  }),
  saveDomainForwardingDetails: (orderid, payload, activate = false) => ({
    type: actionTypes.SAVE_DOMAIN_FORWARDING_DETAILS,
    orderid,
    payload,
    activate,
    track: {
      eventCategory: AnalyticsCategories.domains_view,
      eventAction: AnalyticsActions.manage_domain_forwarding,
      eventLabel: AnalyticsLabels.domain_forwarding
    }
  }),
  saveDomainForwardingDetailsSuccess: (orderid, response) => ({
    type: isSuccess(actionTypes.SAVE_DOMAIN_FORWARDING_DETAILS),
    orderid,
    response
  }),
  saveDomainForwardingDetailsFailure: error => ({
    type: isFailure(actionTypes.SAVE_DOMAIN_FORWARDING_DETAILS),
    error
  }),
  activateDomainForwarding: orderid => ({
    type: actionTypes.ACTIVATE_DOMAIN_FORWARDING,
    orderid,
    track: {
      eventCategory: AnalyticsCategories.domains_view,
      eventAction: AnalyticsActions.enable_domain_forwarding,
      eventLabel: AnalyticsLabels.domain_forwarding
    }
  }),
  activateDomainForwardingSuccess: orderid => ({
    type: isSuccess(actionTypes.ACTIVATE_DOMAIN_FORWARDING),
    orderid
  }),
  activateDomainForwardingFailure: error => ({
    type: isFailure(actionTypes.ACTIVATE_DOMAIN_FORWARDING),
    error
  }),
  showDeleteModal: meta => ({
    type: actionTypes.SHOW_DELETE_MODAL,
    ...meta
  }),
  deleteDomainForwarding: (domainname, orderid) => ({
    type: actionTypes.DELETE_DOMAIN_FORWARDING,
    domainname,
    orderid,
    track: {
      eventCategory: AnalyticsCategories.domains_view,
      eventAction: AnalyticsActions.disable_domain_forwarding,
      eventLabel: AnalyticsLabels.domain_forwarding
    }
  })
};

const helpers = {
  serializeAPIResponseToState: response => {
    const {
      headertags = "",
      urlmasking = "false",
      forward = null,
      pathforwarding = "false",
      noframes = "",
      subdomainforwarding = "false"
    } = response;
    return {
      forwardToUrl: forward,
      urlMasking: {
        isEnabled: parseStringAsBoolean(urlmasking),
        headerTags: {
          isShow: !!headertags,
          data: headertags
        },
        noFramesPageContent: {
          isShow: !!noframes,
          data: noframes
        }
      },
      isSubDomainForwardingEnabled: parseStringAsBoolean(subdomainforwarding),
      isPathForwardingEnabled: parseStringAsBoolean(pathforwarding)
    };
  }
};

export const parsers = {
  detailsParser: response => ({
    isEnabled: !isEmpty(response),
    ...helpers.serializeAPIResponseToState(response)
  }),
  usesDefaultNSParser: response => ({
    isUsingDefaultNameServers: parseStringAsBoolean(response)
  })
};
