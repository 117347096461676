import {
  actions as AnalyticsActions,
  categories as AnalyticsCategories,
  labels as AnalyticsLabels
} from "analytics/constants";
import { isFailure, isSuccess } from "common/api-status/utils";
import { parseStringAsBoolean } from "common/parse-string";
import {
  getPrivacyProtectionCost,
  getPrivacyProtectionTenure
} from "common/util";

export const actionTypes = {
  SHOW_MODAL: "[Manage DNS] Show modal",
  SAVE_NAME_SERVERS: "[Name Servers] Save",
  OPEN_DNS_RECORDS: "[DNS] Open",
  GET_DNS_RECORDS: "[DNS] Get",
  ADD_NEW_RECORD: "[DNS] Add",
  EDIT_DNS_RECORD: "[DNS] Edit",
  DELETE_DNS_RECORD: "[DNS] Delete",
  OPEN_PDNS_RECORDS: "[Premium DNS] Show Premium DNS Name Server Modal",
  SHOW_BUY_PDNS_MODAL: "[Premium DNS] Show Buy PDNS Modal",
  PURCHASE_PDNS: "[Premium DNS] Buy PDNS",
  SHOW_RENEW_PDNS_MODAL: "[Premium DNS] Renew PDNS"
};

export const actions = {
  openPDNSRecords: meta => ({
    type: actionTypes.OPEN_PDNS_RECORDS,
    ...meta
  }),
  openBuyPDNS: meta => ({
    type: actionTypes.SHOW_BUY_PDNS_MODAL,
    ...meta
  }),
  openRenewPDNS: meta => ({
    type: actionTypes.SHOW_RENEW_PDNS_MODAL,
    ...meta
  }),
  getDNS(domainName, recordTypes, newRecord = {}) {
    return {
      type: actionTypes.GET_DNS_RECORDS,
      domainName,
      recordTypes,
      newRecord
    };
  },
  getDNSSuccess(payload) {
    return {
      type: isSuccess(actionTypes.GET_DNS_RECORDS),
      payload
    };
  },
  getDNSFailure(error) {
    return {
      type: isFailure(actionTypes.GET_DNS_RECORDS),
      error
    };
  },

  editDNSRecord(
    recordType,
    domainName,
    { value, ...params },
    resetForm,
    manageEditSuccess,
    manageEditFailure
  ) {
    return {
      type: actionTypes.EDIT_DNS_RECORD,
      recordType,
      domainName,
      params,
      resetForm,
      manageEditSuccess,
      manageEditFailure
    };
  },
  editDNSRecordSuccess(payload) {
    return {
      type: isSuccess(actionTypes.EDIT_DNS_RECORD),
      payload,
      message: payload.type + " record has been updated successfully.",
      defaultMessage: payload.type + " record has been updated successfully."
    };
  },
  editDNSRecordFailure(error, host, value, type) {
    return {
      type: isFailure(actionTypes.EDIT_DNS_RECORD),
      host,
      value,
      error,
      defaultMessage: error.message
    };
  },
  deleteDNSRecord(recordType, domainName, params, resetForm) {
    return {
      type: actionTypes.DELETE_DNS_RECORD,
      recordType,
      domainName,
      params,
      resetForm
    };
  },
  deleteDNSRecordSuccess(payload) {
    return {
      type: isSuccess(actionTypes.DELETE_DNS_RECORD),
      payload,
      message: payload.type + " record has been deleted.",
      defaultMessage: payload.type + " record has been deleted."
    };
  },
  deleteDNSRecordFailure(error, host, value, type) {
    return {
      type: isFailure(actionTypes.DELETE_DNS_RECORD),
      host,
      value,
      error,
      defaultMessage: error.message
    };
  },
  addDNSRecord(recordType, domainName, params, resetForm) {
    return {
      type: actionTypes.ADD_NEW_RECORD,
      recordType,
      domainName,
      params,
      resetForm
    };
  },
  addDNSRecordSuccess(payload) {
    return {
      type: isSuccess(actionTypes.ADD_NEW_RECORD),
      payload,
      message: payload.type + " record has been added.",
      defaultMessage: payload.type + " record has been added."
    };
  },
  addDNSRecordFailure(error, type) {
    return {
      type: isFailure(actionTypes.ADD_NEW_RECORD),
      error,
      defaultMessage: error.message
    };
  },
  showDnsNameServersModal: meta => ({
    type: actionTypes.SHOW_MODAL,
    track: {
      eventCategory: AnalyticsCategories.domains_view,
      eventAction: AnalyticsActions.name_servers_on_card,
      eventLabel: AnalyticsLabels.nameservers_dns
    },
    ...meta
  }),
  saveNameServers: payload => ({
    type: actionTypes.SAVE_NAME_SERVERS,
    payload,
    track: {
      eventCategory: AnalyticsCategories.domains_view,
      eventAction: AnalyticsActions.edit_name_servers_in_modal,
      eventLabel: AnalyticsLabels.nameservers_dns
    }
  })
};

export const initialState = {
  nameServers: {
    allDnsRecords: [],
    currentNameServers: [],
    defaultNameServers: [],
    usingDefaultNameServers: true,
    isDnsActivated: false
  },
  pdns: {
    hasPdns: false
  }
};

export default (state, action) => {
  //note: currently we are using this widget only for the domains
  const parent = "domain";

  switch (action.type) {
    case isSuccess(actionTypes.GET_DNS_RECORDS):
      return {
        ...state,
        [parent]: {
          ...state[parent],
          nameServers: {
            ...state[parent].nameServers,
            ...action.payload
          }
        }
      };

    default:
      return state;
  }
};

const helpers = {
  serializeNameServersResponse: ({ noOfNameServers, ...orderDetails }) =>
    Array(+noOfNameServers)
      .fill(orderDetails)
      .map((orderDetails, index) => orderDetails[`ns${index + 1}`]),
  serializeDefaultNameServersResponse: ({ isdefault, ...nameservers }) =>
    Object.values(nameservers)
};

export const parsers = {
  detailsParser: response => ({
    currentNameServers: helpers.serializeNameServersResponse(response)
  }),
  pdnsParser: ({ premiumdnsallowed = false }) => ({
    hasPdns: premiumdnsallowed
  }),
  pricingParserPDNS: (
    payload,
    { expiryTimestamp, details: { premiumdnsendtime } }
  ) => {
    const { premium_dns: pdnsCost } = payload.reseller_pricing ?? payload;
    return {
      cost: parseFloat(
        getPrivacyProtectionCost(
          pdnsCost,
          getPrivacyProtectionTenure(expiryTimestamp)
        ).toFixed(2)
      ),
      duration: getPrivacyProtectionTenure(expiryTimestamp, premiumdnsendtime)
    };
  },
  defaultNameServersParser: response => ({
    defaultNameServers: helpers.serializeDefaultNameServersResponse(response)
  }),
  isUsingDefaultNameserversParser: response => ({
    usingDefaultNameServers: parseStringAsBoolean(response)
  }),
  dnsStatusParser: (response, { orderid }) => ({
    isDnsActivated: response[orderid]
  })
};
