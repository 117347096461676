import {
  categories as AnalyticsCategories,
  actions as AnalyticsActions,
  labels as AnalyticsLabels
} from "analytics/constants";

import { isFailure, isSuccess } from "common/api-status/utils";

export const actionTypes = {
  GENERATE_WHMCS_KEY: "[WHMCS] Generate key",
  SHOW_ADD_MODAL: "[WHMCS] Show add modal",
  BUY_WHMCS: "[WHMCS] Buy WHMCS",
  SHOW_DELETE_KEY_MODAL: "[WHMCS] Delete Key Modal",
  DELETE_WHMCS_KEY: "[WHMCS] Delete key"
};

export const actions = {
  getWHMCSKey: ({ meta }) => ({
    type: actionTypes.GENERATE_WHMCS_KEY,
    ...meta
  }),
  buyWHMCS: ({ parent, child, ...payload }) => ({
    type: actionTypes.BUY_WHMCS,
    payload: { ...payload, parent, child },
    track: {
      eventCategory: AnalyticsCategories.buy_addon,
      eventAction: `${AnalyticsActions.buy_whmcs}${parent}`,
      eventLabel: AnalyticsLabels.buy_addon
    }
  }),
  buyWHMCSSuccess: payload => ({
    type: isSuccess(actionTypes.BUY_WHMCS),
    ...payload
  }),
  buyWHMCSFailure: (error, payload) => ({
    type: isFailure(actionTypes.BUY_WHMCS),
    error,
    payload
  }),
  deleteWHMCSKey: ({ meta }) => ({
    type: actionTypes.DELETE_WHMCS_KEY,
    ...meta
  })
};

export const initialState = {
  whmcs: {
    isWHMCSIncluded: undefined,
    licenseKey: ""
  }
};

export default (state, action) => {
  switch (action.type) {
    case actionTypes.SHOW_ADD_MODAL: {
      if (!action.value) {
        return state;
      }
      const { addonToBePurchased, parent, child } = action.value;
      return {
        ...state,
        [parent]: {
          ...state[parent],
          [child]: { ...state[parent][child], addonToBePurchased }
        }
      };
    }
    case isSuccess(actionTypes.GENERATE_WHMCS_KEY):
      const {
        orderid: parent,
        hierarchy: { child },
        effectResponse: { licenseKey }
      } = action;
      return {
        ...state,
        [parent]: {
          ...state[parent],
          [child]: {
            ...state[parent][child],
            licenseKey
          }
        }
      };

    default:
      return state;
  }
};

export const parsers = {
  detailsParser: (payload, meta) => ({
    licenseKey: payload.licenseKey,
    isWHMCSIncluded: payload.includes_whmcs
  })
};
