import { isFailure, isSuccess } from "common/api-status/utils";
import { withAutoRenewal } from "common/auto-renewal";
import ModalHandler from "common/modal-handler";
import { actionTypes as summaryTypes } from "common/payment-actions-summary/ducks";
import "modals/modals.scss";
import React from "react";
import { Modal } from "react-bootstrap";
import storage from "@faizaanceg/pandora";
import { withTranslation, Trans } from "react-i18next";
import { PaymentTabs } from "../../add-payment-method-tabs/tabs";
import { actionTypes } from "../../ducks";
import { reduceObject } from "common/util";
import { PayuTabs } from "../../add-payment-method-tabs/payu-flow";
import classnames from "classnames";
import { connect } from "react-redux";

const currencySymbol =
  process.env.FEATURE_ENDPOINT === "bigrock" ? "INR" : "USD";

class EnableAutoRenewal extends React.Component {
  static mapStateToProps = state => {
    return {
      role: state.root.userInfo.role
    };
  };

  getDomainName = orderMap => {
    let domains = Object.values(orderMap);
    return domains.length === 1 ? domains[0] : null;
  };
  render() {
    const Tabs = this.props.role === "reseller" ? PaymentTabs : PayuTabs;
    let { paypw, paypal, payu, role } = this.props;
    if (!paypw.isAllowed && !paypal.isAllowed && !payu.isAllowed) {
      return null;
    }
    return (
      <ModalHandler
        opensOn={[
          actionTypes.SHOW_ENABLE,
          summaryTypes.CLOSE_PAYMENT_ACTIONS_SUMMARY_MODAL
        ]}
        closesOn={[
          isSuccess(actionTypes.ADD_CARD),
          isFailure(actionTypes.ADD_CARD)
        ]}
        render={(modalProps, action) => {
          if (!modalProps.show) {
            return null;
          }
          let deferredOrders = storage.get("deferred-orders", {});
          let {
            orderIds = Object.keys(deferredOrders),
            domainName = this.getDomainName(deferredOrders)
          } = action;
          if (orderIds.length === 0) {
            return null;
          }
          return (
            <Modal
              dialogClassName="add-card-auto-renewal-modal secure-modal"
              keyboard
              show
              onHide={modalProps.onHide}
            >
              <Modal.Body>
                <div className="modal-close" onClick={modalProps.onHide} />

                <div className="add-card-auto-renewal">
                  <div className="add-card-auto-renewal-top">
                    <div className="modal-title">
                      {this.props.t(
                        "ODR_ENABLE_AUTO_RENEWAL",
                        "ENABLE AUTO-RENEWAL"
                      )}
                    </div>
                    <div className="modal-dom-name">
                      {this.props.t("ODR_COUNT_FOR", "For")}:{" "}
                      {domainName || `${orderIds.length} orders`}
                    </div>
                  </div>
                  <div
                    className={classnames("add-card-auto-renewal-bottom", {
                      "add-card-auto-renewal-bottom-ccp":
                        process.env.FEATURE_ENDPOINT === "bigrock"
                    })}
                  >
                    <div>
                      <div className="add-card-modal-text">
                        {this.props.t(
                          "ODR_ENABLE_AUTO_RENEWAL_TEXT1",
                          "To auto-renew your order(s), we need your payment details on file. We will store these securely and use them to renew your order when you don't have sufficient funds. We'll send you a reminder before charging your card / paypal account."
                        )}
                      </div>
                      <div className="add-card-inner-section">
                        <div className="add-card-auto-renewal-title">
                          {this.props.t(
                            "ODR_ENABLE_AUTO_RENEWAL_TEXT2",
                            "Which payment method would you like us to use?"
                          )}
                        </div>
                        <Tabs
                          paypw={paypw}
                          payu={payu}
                          paypal={paypal}
                          addCardSuccess={this.props.addCardSuccess}
                          enableAutoRenewal={this.props.enableAutoRenewal}
                          role={role}
                          orderMap={orderIds
                            .map(id => ({ [id]: true }))
                            .reduce(reduceObject, {})}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="add-card-note">
                  <Trans i18nKey="ODR_ENABLE_AUTO_RENEWAL_TEXT3">
                    To verify your details, we will charge your card{" "}
                    {{ currencySymbol }} 1, which will be automatically credited
                    back.
                  </Trans>
                </div>
              </Modal.Body>
              <Modal.Footer />
            </Modal>
          );
        }}
      />
    );
  }
}

export default connect(EnableAutoRenewal.mapStateToProps)(
  withAutoRenewal(withTranslation()(EnableAutoRenewal))
);
