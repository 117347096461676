import { getOrderDetails, onGetPlanDetails } from "common/orders/sagas";
import { getSimpleNameFromProductKey } from "constants/index";
import { mapEffect } from "map-effect";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { actions, actionTypes } from "./ducks";
import { services } from "./services";

export default [
  takeLatest(actionTypes.DELETE_DEDICATED_IP, onDeleteIP),
  takeEvery(actionTypes.GET_FREE_IP_STATUS, mapEffect(onGetFreeIPStatus))
];

export function* onDeleteIP(action) {
  try {
    const simpleName = getSimpleNameFromProductKey(action.payload.productkey);
    ["sdh", "mdh", "rchosting"].includes(simpleName)
      ? yield services.deleteDedicatedIP({ simpleName, ...action.payload })
      : yield services.deleteAddonIP({
          ...action.payload,
          ...(action.payload?.deleteItem ?? {})
        });

    yield put(actions.deleteIPSuccess(action.payload));
  } catch (error) {
    yield put(actions.deleteIPFailure(error, action.payload));
  }
}

function* onGetFreeIPStatus({ payload }) {
  const { meta } = payload;

  let orderDetails = yield call(
    getOrderDetails,
    { orderid: meta.orderid },
    { getRawData: true }
  );

  const {
    planid,
    installed_os: { os_name = "" } = {},
    productkey,
    productKey = productkey
  } = orderDetails;

  let planDetails = yield call(onGetPlanDetails, { meta: { productKey } });
  return { ...(planDetails?.[planid] ?? {}), os_name };
}
