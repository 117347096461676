import { getCustomerDetails, onGetPlanDetails } from "common/orders/sagas";
import { mapEffect } from "map-effect";
import { call, select, takeEvery } from "redux-saga/effects";
import { actionTypes } from "./ducks";
import { services } from "./services";

export default [
  takeEvery(actionTypes.GET_TITAN_MAIL_STATUS, mapEffect(getTitanMailStatus))
];

function* getTitanMailStatus({ payload }) {
  const { meta } = payload;
  let customer = yield call(getCustomerDetails, meta);
  let productKey = `titanmail${
    customer?.country === "IN" ? "india" : "global"
  }`;
  const state = yield select(state => ({
    orders: Object.values(state.orders.ordersByOrderID).filter(
      order => order.domainname === meta.domainname
    ),
    isSellingTitan: state.root.activeProductsList?.hosting.includes(productKey)
  }));
  let showTitan = false;
  if (state.isSellingTitan) {
    let planDetails = yield call(onGetPlanDetails, { meta: { productKey } });
    showTitan = yield services.canShowTitan(
      state.orders,
      planDetails,
      productKey
    );
  }
  return { showTitan, dummyOrderId: `${productKey}template` };
}
