import { Syringe } from "@faizaanceg/syringe";
import api from "api";
import InvoiceOptions from "common/invoice-options";
import { getPlanDetails } from "common/plan-details/services";
import { findTrialPlanId } from "common/plan-details/util";
import endpoints from "constants/endpoints";
import { OrderStatus } from "constants/index";
import format from "string-format";

/**
 * Check if a productKey + planId is available to purchase for a domain
 * @param {{ domainname: string; productKey: string; planId: number }} payload
 * @returns {Promise<{is_available: boolean}>}
 */
async function getProductAvailability({ domainname, productKey, planId }) {
  return api
    .get(
      `${endpoints.zuulOrderBoxRest}product/${productKey}/plan/${planId}/domain/availability`,
      { "domain-name": domainname }
    )
    .catch(() => ({ is_available: false }));
}

const sessionManager = Syringe.inject("sessionManager");

export const services = {
  /**
   * Check if domain is eligible to show titan product
   * @param {any[]} orders
   * @param {Record<string, any>} planDetails
   * @param {string} productKey
   * @returns {Promise<boolean>}
   */
  async canShowTitan(orders, planDetails, productKey) {
    let activeDomainOrder = orders.find(
      order =>
        order.isActive && order.productType === "domain" && !order.isExpired
    );
    if (!activeDomainOrder) {
      return false;
    }
    if (
      orders.find(
        _ =>
          _.currentstatus !== OrderStatus.DELETED &&
          _.productDetailsKey === productKey
      )
    ) {
      return false;
    }
    const freeMailStatus = await api.get(
      format(
        activeDomainOrder.meta.endpoints.getFreeEmailServiceStatus,
        activeDomainOrder.meta
      )
    );
    if (freeMailStatus[activeDomainOrder.orderid]) {
      return false;
    }
    let status = await getProductAvailability({
      domainname: activeDomainOrder.domainname,
      productKey,
      planId: findTrialPlanId(planDetails)
    });
    return status.is_available;
  },
  /**
   *
   * @param {import("common/widget-types").WidgetInstance["meta"]} meta
   * @returns {Promise<{entityid: string; actionstatusdesc: string;}>}
   */
  async addTitanMail({ domainname, customerid, endpoints, productkey }) {
    const { details } = await sessionManager.create();
    const { role } = details;
    const plans = await getPlanDetails(productkey);
    const planId = findTrialPlanId(plans[productkey]);
    return api.postBody(
      endpoints.addOrder,
      new URLSearchParams({
        "invoice-option":
          role === "reseller"
            ? InvoiceOptions.NO_INVOICE
            : InvoiceOptions.ONLY_ADD,
        noOfMonths: "3",
        "additional-param-json": JSON.stringify({ no_of_accounts: "2" }),
        "plan-id": planId,
        "domain-name": domainname,
        "customer-id": customerid
      })
    );
  }
};
