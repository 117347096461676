type FormatCurrency = Intl.NumberFormatOptions & {
  cost?: number;
  currency: string;
  currencyDisplay: "code" | "symbol";
};

const CURRENCY_LOCALE = {
  USD: "en-US",
  INR: "hi-IN",
  IDR: "id-ID",
  NGN: "en-NG"
};

const getLocaleFromCurrency = (currencySymbol: string): string =>
  CURRENCY_LOCALE[currencySymbol] ?? "en-IN";

export const formatAmountWithCurrency = (
  formattingDetails: FormatCurrency
): string => {
  let { currency, cost, currencyDisplay } = formattingDetails;

  let currencyDetails = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  } as Intl.NumberFormatOptions;

  if (currency && currencyDisplay !== null) {
    currencyDetails = {
      ...currencyDetails,
      ...formattingDetails,
      style: "currency"
    };
  }
  let numberFormat = new Intl.NumberFormat(
    getLocaleFromCurrency(currency),
    currencyDetails
  );
  // if cost present return amount with currencySymbol
  if (cost >= 0) {
    return numberFormat.format(cost);
  }
  // if cost not present return only currencySymbol
  else {
    return numberFormat
      .formatToParts(cost)
      .filter(cost => cost.type === "currency")[0]?.value;
  }
};
