import { colors, fonts } from "common/styles";
import { Field } from "formik";
import { css, cx } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import { WPRadioButton } from ".";

let BorderWrapper = styled.div`
  padding: 17px 21px;
  border: 1px ${colors.gray.gray400} solid;
  border-radius: 2px;
  margin-top: 14px;
  display: flex;
  align-items: center;
`;

type WPBorderedRadioButtonProps = {
  labelName: string;
  variantColor: "green" | "red" | "blue";
  appendRadioDescription?: React.ReactNode;
  labelStyles?: any;
  fieldValue?: string;
  fieldName: string;
  icon?: any;
  checked?: boolean;
  onChange?: Function;
  disabled?: boolean;
  iconSrcSet?: string;
  iconStyle?: any;
};

export const WPBorderedRadioButton: React.FunctionComponent<WPBorderedRadioButtonProps> = ({
  labelName,
  variantColor,
  labelStyles,
  appendRadioDescription,
  fieldValue,
  fieldName,
  icon,
  checked,
  onChange,
  disabled,
  iconSrcSet,
  iconStyle
}) => {
  return (
    <BorderWrapper>
      <WPRadioButton
        labelStyles={labelStyles}
        labelName={labelName}
        variantColor={variantColor}
        appendReasonElement={appendRadioDescription}
      >
        <Field
          type="radio"
          name={fieldName}
          value={fieldValue}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
      </WPRadioButton>
      {icon && (
        <img
          className={cx(
            iconStyle,
            css`
              margin-left: auto;
            `
          )}
          src={icon}
          srcSet={iconSrcSet}
          alt=""
        />
      )}
    </BorderWrapper>
  );
};
