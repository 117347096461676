/**
 *
 * @param {Record<string, any>} plans
 * @param {string} planName
 * @returns {string}
 */
export function findPlanIdByName(plans, planName) {
  for (const [id, details] of Object.entries(plans)) {
    if (details.plan_name === planName) {
      return id;
    }
  }
  return null;
}

/**
 * @param {import("./plan-details").PlanDetails} plan
 * @returns {boolean}
 */
export function isPlanInTrial(plan) {
  return plan?.is_free;
}

/**
 *
 * @param {string} featureName
 * @returns
 */
export function findByFeature(featureName) {
  return (/** @type {{ description: string; value: string; }} */ feature) =>
    feature.description.toLowerCase() === featureName;
}

export function findTrialPlanId(plans) {
  for (const [id, details] of Object.entries(plans)) {
    if (isPlanInTrial(details)) {
      return id;
    }
  }
  return null;
}

export let getDetails = (planDetailsById = {}, orderDetails = {}) => {
  let planDetails = planDetailsById?.[orderDetails.planid] ?? {};
  const { supported_os: OSes = [] } = planDetails;
  const {
    installed_os: { os_name: currentOrderOSName = "" } = {}
  } = orderDetails;
  planDetails.supported_os = OSes.filter(_ => _.os_name === currentOrderOSName);

  planDetails.addonPlanDetails = Object.fromEntries(
    planDetails?.supported_os?.[0]?.addons?.map(addon => [
      addon.addon_name,
      addon
    ]) ?? []
  );

  orderDetails.addonsBought = (orderDetails?.installed_os?.addons ?? []).reduce(
    (
      addonsBought,
      { name: addonNameForVPS, addon_name: addonName = addonNameForVPS }
    ) => {
      let addon = planDetails.addonPlanDetails?.[addonName] ?? {};
      if (addonName.startsWith("storage")) {
        let space = addon.space / 1024;
        let updatedSpace = addonsBought.storage
          ? addonsBought.storage.space + space
          : space;
        return {
          ...addonsBought,
          storage: {
            space: updatedSpace,
            displayText: updatedSpace + " GB",
            subTitle: "disk storage"
          }
        };
      }
      if (addonName.startsWith("ipaddress")) {
        let noOfIPs = (addonsBought?.noOfIPs ?? 0) + 1;
        return {
          ...addonsBought,
          noOfIPs,
          ipaddress: {
            displayText: noOfIPs + " IPs",
            subTitle: "IP address"
          }
        };
      }
      if (addonName.startsWith("ram")) {
        let space = addon.space / 1024;
        let updatedRAM = addonsBought.ram
          ? addonsBought.ram.space + space
          : space;
        return {
          ...addonsBought,
          ram: {
            space: updatedRAM,
            displayText: updatedRAM + " GB",
            subTitle: "ram"
          }
        };
      }
      if (addonName.startsWith("cpu")) {
        let cores = addon.cpu_cores || addon.cores;
        let updatedCores = addonsBought.cpu
          ? addonsBought.cpu.cores + cores
          : cores;
        return {
          ...addonsBought,
          cpu: {
            cores: updatedCores,
            displayText: updatedCores + " cores",
            subTitle: "CPU"
          }
        };
      }
      if (addonName.startsWith("whmcs") || addonName.startsWith("cpanel")) {
        return {
          ...addonsBought,
          [addonName]: { displayText: "Active", subTitle: addonName }
        };
      }
      return addonsBought;
    },
    {}
  );

  return { planDetails, orderDetails };
};
