import { Syringe } from "@faizaanceg/syringe";
import api from "api";
import Endpoints from "constants/endpoints";

const RESELLER = {
  RATES: `${Endpoints.zuulOrderBox}reseller/all-tax-percentages`,
  TAAS: `${Endpoints.zuulOrderBoxV2}tax-rules/taas`
};

const CUSTOMER = {
  RATES: `${Endpoints.zuulOrderBox}billing/tax-percentages`
};

type TaxRate = { domorder: number; hosting: number };
type ResellerTaxRates = { "regional-and-custom-taxes": TaxRate; vat: TaxRate };
type TAASResponse = { taxRate: number };
type TaxType =
  | "USSalesTax"
  | "IndianGST"
  | "IndianServiceTax"
  | "ParaguayTax"
  | "EUVat"
  | "RussiaVat"
  | "AustraliaGST"
  | "NewZealandGST"
  | "SingaporeGST";

type UpdateRegionBasedTax = {
  userID: string;
  taxId: string;
  role: Role;
  country: string;
  state: string;
  taxType: TaxType;
};

const sessionManager = Syringe.inject("sessionManager");

export const TaxSdk = {
  reseller: {
    percentages() {
      return api.get<ResellerTaxRates>(RESELLER.RATES);
    },
    async taas(userId: string) {
      const payload = { userId, role: "reseller", amount: 100 };
      const taas: TaxRate = { domorder: 0, hosting: 0 };
      for (const productCategory of ["domorder", "hosting"]) {
        try {
          const tax: TAASResponse = await api.postBody(RESELLER.TAAS, {
            ...payload,
            productCategory
          });
          taas[productCategory] = tax.taxRate;
        } catch {}
      }
      return { taas };
    }
  },
  customer: {
    percentages(userId: string) {
      return api.get<TaxRate>(CUSTOMER.RATES, { "customer-id": userId });
    }
  },
  saveRegionBasedTaxId(taxDetails: UpdateRegionBasedTax) {
    return api.postBody(
      `${Endpoints.zuulOrderBox}billing/save-regionbased-tax-id`,
      taxDetails
    );
  },
  async percentages(userId: string) {
    const {
      details: { role }
    } = await sessionManager.create();
    return role === "reseller"
      ? TaxSdk.reseller.percentages()
      : TaxSdk.customer.percentages(userId);
  }
};
