import api from "api";
import format from "string-format";

export const services = {
  deleteWHMCSKey: action =>
    api.post(
      format(action.endpoints.deleteWHMCSKey, { orderid: action.orderid })
    ),
  getWHMCSKey: action =>
    api.post(format(action.endpoints.getWHMCSKey, { orderid: action.orderid }))
};
