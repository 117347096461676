import { mapEffect } from "map-effect";
import { takeEvery, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./ducks";
import { services } from "./services";

export default [
  takeEvery(
    actionTypes.CHANGE_VERIFICATION_EMAIL_SSL,
    mapEffect(changeVerificationEmailSSL)
  ),
  takeEvery(actionTypes.ENROLL_SSL, mapEffect(enrollSSL)),
  takeLatest(
    actionTypes.RECHECK_VERIFICATION_STATUS,
    mapEffect(recheckVerificationStatus)
  )
];

function changeVerificationEmailSSL(action) {
  return services.changeVerificationEmailSSL(action.payload);
}

function enrollSSL(action) {
  return services.enrollSSL(action.payload);
}

export function recheckVerificationStatus(action) {
  return services.recheckVerificationStatus(action.payload);
}
