import { convertUTCTimestampToDate, formatBytes } from "common/util";
import { get } from "lodash";
const PlanDetailsFormatters = {
  convertTimestampToDate: timestamp => convertUTCTimestampToDate(timestamp),

  formatRAM: (ramInMB, planDetails) =>
    ramInMB !== undefined
      ? `${formatBytes(ramInMB, "MB")} ${get(
          planDetails,
          ["ram", "ram_type"],
          ""
        )}`
      : "",

  formatUnmeteredDiskSpace: (diskSpaceInMB, planDetails) =>
    diskSpaceInMB !== undefined
      ? +diskSpaceInMB === -1
        ? "Unmetered"
        : `${formatBytes(+diskSpaceInMB, "MB")} ${get(
            planDetails,
            ["storage", "storage_type"],
            ""
          )}`
      : "",
  formatDiskSpace: (diskSpaceInMB, planDetails) =>
    diskSpaceInMB !== undefined
      ? +diskSpaceInMB === -1
        ? "Unlimited"
        : `${formatBytes(+diskSpaceInMB, "MB")} ${get(
            planDetails,
            ["storage", "storage_type"],
            ""
          )}`
      : "",
  formatUnmeteredBandwidth: (bandwidth, planDetails) =>
    bandwidth !== undefined
      ? +bandwidth === -1
        ? "Unmetered"
        : formatBytes(bandwidth, "MB")
      : "",
  formatBandwidth: (bandwidth, planDetails) =>
    bandwidth !== undefined
      ? +bandwidth === -1
        ? "Unlimited"
        : formatBytes(bandwidth, "MB")
      : "",

  formatCPUCores: (cpuCores, planDetails) =>
    cpuCores !== undefined ? `${cpuCores} Cores` : "",

  formatNumberOfDomains: (numberOfDomains, planDetails) =>
    numberOfDomains !== undefined
      ? +numberOfDomains === 0
        ? "Single"
        : +numberOfDomains === -1
        ? "Unlimited"
        : +numberOfDomains
      : "",

  formatEmailAccounts: (numberOfEmailAccounts, planDetails) =>
    numberOfEmailAccounts !== undefined
      ? +numberOfEmailAccounts === -1
        ? "Unlimited"
        : numberOfEmailAccounts
      : "",

  formatCloudBackup: (cloudBackup, planDetails) =>
    cloudBackup !== undefined ? formatBytes(+cloudBackup, "MB") : "",

  formatAddonsCategory: addonName => {
    if (addonName.includes("RAM")) {
      return "RAM Upgrade";
    } else if (addonName.includes("CPU")) {
      return "CPU Upgrade";
    } else if (addonName.includes("Mbps")) {
      return "Bandwidth";
    } else if (addonName.includes("SAN")) {
      return "SAN Storage";
    } else if (addonName.includes("IP Address")) {
      return "Dedicated IP";
    } else {
      return addonName;
    }
  },
  /**
   *
   * @param {Record<string, any>} planDetails
   * @returns {{description: string; value: boolean | string;}[]}
   */
  formatFeatures: planDetails =>
    Object.values(planDetails?.features ?? {}).flatMap(
      details => details.features
    )
};

export default PlanDetailsFormatters;
