import {
  Maximize,
  Minimize,
  NotificationIcon,
  SupportIconPersonHover
} from "assets";
import classnames from "classnames";
import { formatAmountWithCurrency } from "common/localize-amount";
import useMediaQuery from "common/media-query";
import { colors } from "common/styles";
import WPTooltip from "common/tooltip";
import { css, cx } from "linaria";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import {
  BuyLinkProps,
  ROUTE_OPTION,
  getSideBarItems,
  mainCategory
} from "./constants";
import { Dollar } from "./icons/dollar";
import { PlusSign } from "./icons/plus-sign";
import InternationalComponents from "./internationalization";
import {
  SideBarWallet,
  SidebarAddfunds,
  SidebarBeta,
  SidebarBuyButton,
  SidebarCategoryText,
  SidebarCostWrapper,
  SidebarCurrency,
  SidebarIcon,
  SidebarItem,
  SidebarNav,
  SidebarNavList,
  SidebarNavListBottom,
  SidebarNavListTop,
  SidebarSeparator,
  SidebarSupportButton,
  SidebarText,
  SidebarWalletActions,
  SidebarWalletAmount
} from "./styles";

function IconLink(props) {
  let {
    icon: Icon,
    text,
    id,
    beta,
    index,
    exact = false,
    children = []
  } = props.item;

  const showNotification = false;
  let childRoutesArray = [];

  if (children.length) {
    children.map(child => {
      childRoutesArray.push(ROUTE_OPTION[child.index]);
    });
  }

  let location = useLocation();
  let currentPath = location.pathname;

  let isChildRouteActive =
    children.length > 0
      ? childRoutesArray.some(route => route === currentPath)
      : false;

  const { t } = useTranslation();
  return (
    <WPTooltip
      id={`sidebar-tooltip-${index}`}
      label={text.toLowerCase()}
      position="right"
      show={props.isSidebarCollapsed}
      popupStyles={{
        fontFamily: "OpenSans",
        fontWeight: 600,
        textTransform: "capitalize"
      }}
    >
      <NavLink
        exact={exact}
        activeClassName="selected-option"
        to={ROUTE_OPTION[index]}
        id={id}
        key={text}
        className={classnames({
          "selected-option": childRoutesArray.includes(currentPath)
        })}
      >
        <SidebarIcon
          className={classnames("sidebar-icon", {
            ccp_sidebar_icon_mobile: process.env.FEATURE_ENDPOINT === "bigrock"
          })}
        >
          <Icon />
        </SidebarIcon>
        <SidebarText className="sidebar-text">
          {t(`SIDEBAR_${text}`, text)}
          {children.length > 0 ? (
            isChildRouteActive ? (
              <img src={Minimize} className="sidebar-arrow" />
            ) : (
              <>
                <img src={NotificationIcon} className="notification-dot" />
                <img src={Maximize} className="sidebar-arrow" />
              </>
            )
          ) : null}
        </SidebarText>

        {beta && (
          <SidebarBeta style={{ marginLeft: "-6px" }}>
            {t("NEW_TAG", "NEW")}
          </SidebarBeta>
        )}
      </NavLink>

      {isChildRouteActive &&
        children.map(child => (
          <NavLink
            to={ROUTE_OPTION[child.index]}
            className="submenu"
            id={id}
            key={child.text}
          >
            <SidebarText className="sidebar-text">
              {t(`SIDEBAR_${child.text}`, child.text)}
              {child.showNotification ? (
                <>
                  <span style={{ fontSize: "10px" }}>
                    ({props.numberOfOrderRequests})
                  </span>
                  <img
                    src={NotificationIcon}
                    className="notification-dot child-notification-dot"
                  />
                </>
              ) : (
                ""
              )}
            </SidebarText>
          </NavLink>
        ))}
    </WPTooltip>
  );
}

function shortenWalletAmount(balance) {
  if (balance >= 1000000000) {
    return (balance / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (balance >= 1000000) {
    return (balance / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (balance >= 1000) {
    return (balance / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return balance;
}

function MyBusinessCategoryItems(props) {
  let { items: sidebarItems, showOverlayTooltip: collapsed } = props;
  let businessCategoryItems = sidebarItems.filter(
    items => items.category === mainCategory.mybusiness.fallbackText
  );
  const { t } = useTranslation();
  return businessCategoryItems.length ? (
    <>
      <SidebarSeparator className="sidebar-separator" />
      <div className="sidebar-category">
        <SidebarCategoryText className="sidebar-category-text">
          {t(
            mainCategory.mybusiness.sofiKey,
            mainCategory.mybusiness.fallbackText
          )}
        </SidebarCategoryText>
        {businessCategoryItems.map(item => (
          <IconLink
            item={item}
            isSidebarCollapsed={collapsed}
            key={item.text}
            numberOfOrderRequests={props.numberOfOrderRequests}
          />
        ))}
      </div>
    </>
  ) : null;
}

function SettingsCategoryItems(props) {
  let { items: sidebarItems, showOverlayTooltip: collapsed } = props;
  let settingsCategoryItems = sidebarItems.filter(
    items => items.category === mainCategory.settings.fallbackText
  );
  const { t } = useTranslation();
  return settingsCategoryItems.length ? (
    <>
      <SidebarSeparator className="sidebar-separator settings" />
      <div className="sidebar-category">
        <SidebarCategoryText className="sidebar-category-text">
          {t(mainCategory.settings.sofiKey, mainCategory.settings.fallbackText)}
        </SidebarCategoryText>
        {settingsCategoryItems.map(item => (
          <IconLink
            item={item}
            isSidebarCollapsed={collapsed}
            key={item.text}
          />
        ))}
      </div>
    </>
  ) : null;
}

function NoCategoryItems(props) {
  let { items: sidebarItems, showOverlayTooltip: collapsed } = props;
  return sidebarItems
    .filter(items => items.category === null)
    .map(item => (
      <IconLink item={item} isSidebarCollapsed={collapsed} key={item.text} />
    ));
}

export default function SideBar(props) {
  const {
    position,
    currencySymbol,
    walletBalance = 0,
    showAddFunds,
    userId,
    ...features
  } = props;
  const orderequests = useSelector(state => state.orders.orderRequests.payload);
  let numberOfOrderRequests = 0;
  const validCartStatus = ["offline", "partially_paid"];

  const offlinePartiallyPaidNonEmptyCarts = orderequests?.data?.filter(
    _ => validCartStatus.includes(_.cart_status) && _.items.length
  );

  offlinePartiallyPaidNonEmptyCarts?.forEach(cart => {
    cart.items.forEach(item => {
      if (item.status === "active") {
        numberOfOrderRequests++;
      }
    });
  });

  const { t } = useTranslation();

  const { parentid, role } = useSelector(state => state?.root?.userInfo);
  let sideBarItems = getSideBarItems({ ...features, parentid, role });
  let location = useLocation();
  const isCustomizeInvoicePage =
    location.pathname === "/settings/pro-suite/invoices" ||
    location.pathname.includes("/settings/pro-suite/payment-gateways/edit") ||
    location.pathname.includes("/settings/pro-suite/payment-gateways/setup");
  let isMobile = useMediaQuery("(max-width: 767px)");
  return (
    <>
      <SidebarNav
        className={cx(
          isMobile && process.env.FEATURE_ENDPOINT === "bigrock"
            ? "is-collapsed fixed"
            : props.isCollapsed && "is-collapsed",
          position === "fixed" && "fixed"
        )}
      >
        <SidebarNavList
          className={classnames("sidebar_nav_list", {
            sidebar_ccp_nav_list: process.env.FEATURE_ENDPOINT === "bigrock"
          })}
        >
          <SidebarNavListTop className="sidebar-nav-list-top">
            <SidebarItem
              className={classnames("sidebar-item", {
                ccp_sidebar_item: process.env.FEATURE_ENDPOINT === "bigrock"
              })}
            >
              <NoCategoryItems
                items={sideBarItems}
                showOverlayTooltip={props.isCollapsed}
              />
              <MyBusinessCategoryItems
                items={sideBarItems}
                showOverlayTooltip={props.isCollapsed}
                numberOfOrderRequests={numberOfOrderRequests}
              />
              <SettingsCategoryItems
                items={sideBarItems}
                showOverlayTooltip={props.isCollapsed}
              />
            </SidebarItem>
          </SidebarNavListTop>
          <div
            className={classnames(
              process.env.FEATURE_ENDPOINT === "bigrock" &&
                "sidebar-cta-container"
            )}
          >
            {process.env.FEATURE_ENDPOINT === "bigrock" && (
              <SidebarSupportButton className="sidebar-support-button">
                <a
                  href="/support"
                  target="_blank"
                  rel="noreferrer noopener"
                  role="button"
                >
                  <span>
                    <img
                      alt=""
                      src={SupportIconPersonHover}
                      style={{ position: "relative" }}
                    />
                    {t("SUPPORT", "SUPPORT")}
                  </span>
                </a>
              </SidebarSupportButton>
            )}
            <SideBarWallet>
              <SidebarBuyButton className="sidebar-buy-button">
                <a
                  {...BuyLinkProps(role)}
                  rel="noreferrer noopener external"
                  role="button"
                >
                  <span>
                    <PlusSign />
                    {t("BUY", "BUY")}
                  </span>
                </a>
              </SidebarBuyButton>
              {process.env.FEATURE_ADD_FUNDS !== "none" && (
                <>
                  <SidebarNavListBottom
                    className={classnames(
                      ("sidebar-nav-list-bottom",
                      isCustomizeInvoicePage &&
                        css`
                          margin-bottom: 80px;
                          border-bottom: 1px ${colors.gray.gray400} solid;
                        `),
                      {
                        ccp_sidebar_nav_list_bottom:
                          process.env.FEATURE_ENDPOINT === "bigrock"
                      }
                    )}
                  >
                    <SidebarCostWrapper className="sidebar-cost-wrapper">
                      <SidebarCurrency className="sidebar-currency">
                        <span>
                          <Dollar />
                        </span>
                      </SidebarCurrency>
                      <div>
                        <SidebarWalletActions className="sidebar-wallet-actions">
                          <SidebarAddfunds
                            data-testid="open-add-funds"
                            onClick={showAddFunds}
                          >
                            {t("ADD_FUNDS", "Add Funds")}
                          </SidebarAddfunds>
                        </SidebarWalletActions>
                        <SidebarWalletAmount className="sidebar-wallet-amount notranslate">
                          {props.isCollapsed
                            ? shortenWalletAmount(walletBalance)
                            : formatAmountWithCurrency({
                                cost: walletBalance,
                                currency: currencySymbol,
                                currencyDisplay: "code"
                              })}
                        </SidebarWalletAmount>
                      </div>
                    </SidebarCostWrapper>
                  </SidebarNavListBottom>
                </>
              )}
              <InternationalComponents />
            </SideBarWallet>
          </div>
        </SidebarNavList>
      </SidebarNav>
    </>
  );
}
