import APIStatus from "common/api-status";
import UpdateButton from "common/buttons/update";
import ModalError from "common/errors/modal";
import React from "react";
import { Modal } from "react-bootstrap";

class DeleteCardModal extends React.Component {
  render() {
    const {
      description = "",
      itemToBeDeleted = {},
      deletePaymentMethod,
      ...props
    } = this.props;
    const { paymentType, token } = itemToBeDeleted;
    return (
      <Modal
        show={props.show}
        dialogClassName="vps del-ded-ip-modal genmodal"
        keyboard
        onHide={props.onHide}
      >
        <Modal.Body>
          <div className="modal-close" onClick={props.onHide} />
          <div className="pr-left-ct">
            <div className="modal-title">DELETE {paymentType}</div>
            <APIStatus monitors={this.props.monitorInfo}>
              {apiStatus =>
                apiStatus.errors && (
                  <ModalError className="margin-12">
                    {apiStatus.errors.message}
                  </ModalError>
                )
              }
            </APIStatus>
            <div className="privacy-disable-ct">
              <div className="delete-card-text">
                Auto-renewal may fail if there are no valid payment modes on
                file.
              </div>
              <div className="delete-card-text-2">
                Are you sure you want to delete this payment mode ?
              </div>
              <div className="privacy-pay-disable">
                <APIStatus monitors={this.props.monitorInfo}>
                  {apiStatus => (
                    <UpdateButton
                      color="red"
                      className="wp-btn-red"
                      data-testid="delete-item-confirm"
                      updating={apiStatus.isFetching}
                      onClick={deletePaymentMethod.bind(this, token)}
                    >
                      YES, DELETE {paymentType}
                    </UpdateButton>
                  )}
                </APIStatus>
              </div>
            </div>
          </div>
          {description && (
            <div className="pr-right-ct">
              <div className="pr-right-text white-space-respect">
                {description}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    );
  }
}

export default DeleteCardModal;
