import React from "react";

export let Cart = props => (
  <svg width={24} height={24} {...props}>
    <path
      d="M8 19c1.125 0 2 .902 2 2 0 1.098-.924 2-2 2s-2-.902-2-2c0-1.098.924-2 2-2zm0 2.998c.565 0 .973-.451.973-1.003 0-.551-.462-1.002-.973-1.002-.511 0-.973.45-.973 1.002s.462 1.003.973 1.003zM18 19c1.125 0 2 .902 2 2 0 1.098-.924 2-2 2-1.125 0-2-.902-2-2 0-1.098.924-2 2-2zm0 2.998c.565 0 .973-.451.973-1.003 0-.551-.462-1.002-.973-1.002-.565 0-.973.45-.973 1.002 0 .557.457 1.003.973 1.003zM3.652 1l.736 2.905H23l-3.434 10.217c1.15.169 2.01 1.146 2.01 2.402 0 1.38-1.041 2.476-2.37 2.476H6.309v-.904h12.899c.805 0 1.423-.667 1.423-1.526s-.664-1.525-1.423-1.525H6.262L2.897 1.95H1V1h2.652zm3.32 13.095h11.616l3.084-9.24H4.648l2.324 9.24z"
      stroke="#A0A7B3"
      strokeWidth={0.06}
      fill="#A0A7B3"
    />
  </svg>
);
