import React from "react";

export const MyShop = props => (
  <svg width="19px" height="18px" viewBox="0 0 19 18" {...props}>
    <g
      transform="translate(-21 -408) translate(0 266) translate(21 81) translate(0 61)"
      id="fill-area"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    >
      <path d="M2.59 8.277v7.66c-.04.501.223.752.79.752h3.91V12.12c-.009-.422.161-.633.512-.633h3.093c.39.009.585.22.585.633v4.568h3.892c.495-.012.743-.263.743-.752v-7.66h1.08v7.91c.005 1.044-.511 1.566-1.55 1.566H3.11c-1.044-.059-1.566-.581-1.566-1.566v-7.91H2.59z" />
      <path d="M2.05993643 0L16.6686321 0 18.494719 4.69565217 0.233849469 4.69565217z" />
      <path d="M0 8.309c.833 1.171 1.885 1.276 3.157.313l.448-.01c1.094 1.048 2.24 1.052 3.436.01l.732-.004c1.004 1.08 2.055 1.082 3.154.004l.773.004c1.05 1.076 2.092 1.071 3.123-.014l.798-.006c1.214.97 2.234.871 3.06-.297v-2.53L0 5.775v2.534z" />
    </g>
  </svg>
);
