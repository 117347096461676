import { getDifferenceInMonths, isValidNumber } from "common/util";
import { clamp } from "lodash";
import {
  GenericQuery,
  PricingInfo,
  PricingStrategy,
  PricingType,
  getDiscountBreakdownOptions
} from "./index";

export class BasePricingStrategy implements PricingStrategy {
  getAccountPrice(
    emailPricing: Record<PricingType, Record<string | number, number>>,
    pricingKey: PricingType,
    { discount, remainingNumberOfMonths, selectedTenure = 0 }: PricingInfo
  ) {
    return 0;
  }
  getDiscountBreakdown({
    discount,
    emailPricing,
    expiryTimestamp,
    numberOfAccounts,
    pricingKey,
    selectedTenure,
    totalAccounts = numberOfAccounts
  }: getDiscountBreakdownOptions) {
    const remainingNumberOfMonths = getDifferenceInMonths(expiryTimestamp);
    let price = { regular: 0, discount: 0 };
    let accounts = { regular: 0, discount: 0 };
    price.regular = this.getAccountPrice(emailPricing, pricingKey, {
      remainingNumberOfMonths,
      selectedTenure,
      numberOfAccounts: totalAccounts
    });
    price.discount = this.getAccountPrice(emailPricing, pricingKey, {
      remainingNumberOfMonths,
      selectedTenure,
      numberOfAccounts: totalAccounts,
      discount
    });
    accounts.regular = clamp(
      totalAccounts - (discount?.number_of_accounts ?? 0),
      0,
      numberOfAccounts
    );
    accounts.discount = clamp(
      numberOfAccounts - accounts.regular,
      0,
      numberOfAccounts
    );
    return { price, accounts };
  }
  getPrice(
    modifiedRenewalDetails: GenericQuery,
    renewalData: GenericQuery,
    selectedTenure: number
  ) {
    return {
      resellerPrice: parseFloat(
        modifiedRenewalDetails?.price ??
          renewalData?.price ??
          renewalData?.pricing[selectedTenure] * selectedTenure
      ),
      customerPrice: parseFloat(
        modifiedRenewalDetails?.customerPrice ??
          renewalData?.customerPrice ??
          renewalData?.customerPricing?.[selectedTenure] * selectedTenure
      )
    };
  }

  getAddonPrice(addonDetails: GenericQuery, selectedTenure: number) {
    if (addonDetails.addonPriceIncluded) {
      return {
        resellerPrice:
          parseInt(addonDetails.totalPrice, 10) === 0
            ? // @ts-ignore TODO: refactor and remove parseFLoat if not necessary
              -parseFloat(addonDetails.price * selectedTenure)
            : 0,
        customerPrice:
          parseInt(addonDetails.totalCustomerPrice, 10) === 0
            ? // @ts-ignore
              -parseFloat(addonDetails.customerPrice * selectedTenure)
            : 0
      };
    }
    return {
      resellerPrice: isValidNumber(addonDetails.totalPrice)
        ? parseFloat(addonDetails.totalPrice)
        : 0,
      customerPrice: isValidNumber(addonDetails.totalCustomerPrice)
        ? parseFloat(addonDetails.totalCustomerPrice)
        : 0
    };
  }

  getRenewPriceWithAddons(
    modifiedRenewalDetails: GenericQuery = {
      addonDetails: { addons: {} },
      doNotConsiderBasePrice: false
    },
    renewalData: GenericQuery,
    selectedTenure: number
  ) {
    const {
      addonDetails: { addons },
      doNotConsiderBasePrice
    } = modifiedRenewalDetails;
    const customerPriceWithAddon = !doNotConsiderBasePrice
      ? this.getPrice(modifiedRenewalDetails, renewalData, selectedTenure)
          ?.customerPrice
      : 0;
    const priceWithAddon = !doNotConsiderBasePrice
      ? this.getPrice(modifiedRenewalDetails, renewalData, selectedTenure)
          ?.resellerPrice
      : 0;
    return {
      resellerAddOnPrice: Object.keys(addons).reduce(
        (addonSum, addonName) =>
          addonSum +
          this.getAddonPrice(addons[addonName], selectedTenure)?.resellerPrice,
        priceWithAddon
      ),
      customerAddOnPrice: Object.keys(addons).reduce(
        (addonSum, addonName) =>
          addonSum +
          this.getAddonPrice(addons[addonName], selectedTenure)?.customerPrice,
        customerPriceWithAddon
      )
    };
  }
}
