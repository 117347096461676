import storage from "@faizaanceg/pandora";
import { SearchQueryBuilder } from "@superpanel/bodybuilder";
import api from "api";
import constants from "constants/endpoints";

export const services = {
  getOrders: (from, params = {}) =>
    from < 0
      ? Promise.reject(new Error("Invalid parameter 'from'"))
      : api.get(`${constants.zuulOrderBox}orders/list`, params),
  get: api.get,
  post: api.post,
  getAutoRenewDetails: orderIds =>
    api.get(`${constants.zuulOrderBox}actions/last-auto-renew-details`, {
      "order-id": orderIds
    }),
  getCurrentActions: params =>
    api
      .get(constants.current_actions, params)
      .catch(_ => ({ recsindb: "0", recsonpage: "0" })),
  getArchivedActions: async params => {
    let cacheKey = `memoize.getArchivedActions.${JSON.stringify(params)}`;
    let result = storage.get(cacheKey, null);
    if (result !== null) {
      return result;
    }
    let actions = await api
      .get(constants.archived_actions, params)
      .catch(_ => ({ recsindb: "0", recsonpage: "0" }));
    storage.set(cacheKey, actions, { ttl: 1000 * 60 * 5 });
    return actions;
  },
  resendVerificationMail: orderId =>
    api.post(`${constants.domain}raa/resend-verification`, {
      "order-id": orderId
    }),
  getTotalRenewCost: orderIds =>
    api.get(`${constants.zuulOrderBox}orders/get-total-renew-cost`, {
      "order-ids": orderIds
    }),
  getOnholdOrdersDetails: () =>
    api.get(
      `${constants.zuulOrderBoxV2}entity-action/reseller-insufficient-fund-actions`
    ),
  searchByOrderId: orderIds => {
    let bodybuilder = SearchQueryBuilder();
    bodybuilder = bodybuilder
      .sort("_id", "asc")
      .filter("terms", "orderId", orderIds);
    return api
      .call("POST", constants.es_search, { size: 50 }, bodybuilder.build())
      .then(response => response.hits.hits.map(hit => hit._source));
  },
  getOrderDetails: orderId =>
    api.get(constants.get_order_details_with_orderid, {
      "order-id": orderId
    })
};
