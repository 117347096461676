import Endpoints from "constants/endpoints";

type ExposeOptions = {
  useCommonInfo?: boolean;
  addons?: boolean;
  manageSites?: boolean;
};

export function exposeAPIBuilder(productName: string, options: ExposeOptions) {
  /*
   * Available template values are as follows
   * orderid - Id of the order for which the endpoints are generated.
   * customerid - Id of the customer to whom the order belongs to
   * selected_tenure - How many months is this being renewed for?
   * addonId - Id of the addon that is being deleted.
   * targetId - id of the plan to be upgraded.
   */
  const productUrl = `${Endpoints.zuulOrderBoxRest}product/${productName}`;
  const resourceUrl = `${productUrl}/order/{orderid}`;
  const endpoints: Record<string, string> = {
    addOrder: `${Endpoints.bclOrderBoxRest}product/${productName}/order`,
    getPlanDetails: Endpoints.get_plan_details,
    getPricingDetails: Endpoints.pricing_details_productkey,
    getDetails: `${productUrl}?order-id={orderid}`,
    delete: resourceUrl,
    getUpgradeCost: `${resourceUrl}/plan/{targetId}/cost`,
    getUpgradeCostForReseller: `${resourceUrl}/plan/{targetId}/reseller/cost`,
    upgradePlan: `${resourceUrl}/plan/{targetId}`,
    renew: `${resourceUrl}/tenure/{selected_tenure}`
  };
  if (options?.manageSites === true) {
    endpoints.manageSites = `${resourceUrl}/manage/site`;
  }
  if (options?.addons === true) {
    endpoints.buyAddon = `${resourceUrl}/addon`;
    endpoints.deleteAddon = `${resourceUrl}/addon/{addonId}`;
  }
  if (options?.useCommonInfo === true) {
    endpoints.getDetails = `${Endpoints.get_order_details_with_orderid}?order-id={orderid}`;
  }
  return endpoints;
}

type v1Options = {
  addons?: boolean;
  serverManagement?: boolean;
  shell?: boolean;
  ssl?: boolean;
  dedicatedIP?: boolean;
  dns?: boolean;
  email?: boolean;
};

export function v1HostingAPIBuilder(productUrl: string, options: v1Options) {
  /*
   * Available template values are as follows
   * orderid - Id of the order for which the endpoints are generated.
   * customerid - Id of the customer to whom the order belongs to
   * selected_tenure - How many months is this being renewed for?
   * addonId - Id of the addon that is being deleted.
   */
  productUrl = `${Endpoints.zuulOrderBox}${productUrl}/`;
  const endpoints: Record<string, string> = {
    delete: `${productUrl}delete?order-id={orderid}`,
    getDetails: `${productUrl}details?order-id={orderid}`,
    getPlanDetails: Endpoints.get_plan_details,
    getPricingDetails: Endpoints.pricing_details_productkey,
    renew: `${productUrl}renew`
  };
  if (options?.addons === true) {
    endpoints.buyAddon = productUrl + "addon/add";
    endpoints.enableSSL = endpoints.buyAddon;
    endpoints.deleteAddon =
      productUrl + "addon/delete?order-id={orderid}&addon-id={addonId}";
    endpoints.disableSSL = endpoints.deleteAddon;
  }
  if (options?.serverManagement === true) {
    endpoints.changeHostingPanelPassword = `${productUrl}change-password`;
    endpoints.serverStatus = `${productUrl}server-state?order-id={orderid}`;
    endpoints.changeServerState = `${productUrl}change-server-state`;
    endpoints.getVNCAccess = `${productUrl}vnc-access?order-id={orderid}`;
  }
  if (options?.shell === true) {
    endpoints.enableShellAccess = `${productUrl}enable-shell-access?order-id={orderid}`;
    endpoints.disableShellAccess = `${productUrl}disable-shell-access?order-id={orderid}`;
  }
  if (options?.ssl === true) {
    endpoints.enableSSL = `${productUrl}enable-ssl`;
    endpoints.disableSSL = `${productUrl}disable-ssl`;
  }
  if (options?.dedicatedIP === true) {
    endpoints.enableSSL = `${productUrl}add-dedicated-ip`;
    endpoints.disableSSL = `${productUrl}delete-dedicated-ip`;
  }
  if (options?.dns === true) {
    endpoints.getDNSRecords = `${productUrl}dns-record?order-id={orderid}`;
  }
  if (options?.email === true) {
    let commonMailUrl = `${Endpoints.zuulOrderBox}mail/`;
    endpoints.addEmailAddress = `${commonMailUrl}user/add-with-send-mail`;
    endpoints.addEmailAccounts = `${productUrl}add-email-account`;
    endpoints.deleteEmailAccounts = `${productUrl}delete-email-account`;
    endpoints.buyBlocks = productUrl + "addon/add";
    endpoints.deleteBlocks =
      productUrl +
      "addon/delete?order-id={orderid}&addon=email_storage_5&quantity={blocks}";
    endpoints.getUsedAccounts = `${commonMailUrl}users/no-of-users?order-id={orderid}`;
    endpoints.emailAddresses = `${commonMailUrl}users/search?order-id={orderid}&over-quota=false&page-no=1`;
    endpoints.overQuotaEmailAddresses = `${commonMailUrl}users/search?order-id={orderid}&over-quota=true&page-no=1`;
    endpoints.deleteEmailAddress = `${commonMailUrl}user/delete`;
    endpoints.resetEmailAddress = `${commonMailUrl}user/reset-password`;
  }
  return endpoints;
}
