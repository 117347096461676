export const actionTypes = {
  START: "[Reload Me] Start",
  STOP: "[Reload Me] Stop",
  RELOAD: "[Reload Me] Toggle"
};

const initialState = {
  listeningTo: [],
  stateById: {}
};

export let onReload = _ => `${_} Reload`;
export let ReloadActionType = "[Order] Manual Reload";

export const actions = {
  listen(actionProps) {
    return {
      ...actionProps,
      type: actionTypes.START
    };
  },
  unlisten(key) {
    return {
      key,
      type: actionTypes.STOP
    };
  },
  reload(actionProps) {
    return {
      ...actionProps,
      type: actionTypes.RELOAD
    };
  }
};

export const reloadableMiddleware = store => next => action => {
  let result = next(action);
  let { type } = action;
  if (Object.values(actionTypes).includes(type)) {
    return result;
  }
  store
    .getState()
    .reloadable.listeningTo.filter(
      ({ actionType, updateWhen }) =>
        actionType === onReload(type) && updateWhen(action)
    )
    .map(actions.reload)
    .forEach(store.dispatch);
  return result;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START: {
      let { reloadsOn, key, updateWhen } = action;
      return {
        ...state,
        listeningTo: [
          ...state.listeningTo,
          ...reloadsOn.map(_ => ({
            actionType: onReload(_),
            key,
            updateWhen
          }))
        ],
        stateById: {
          ...state.stateById,
          [key]: Date.now()
        }
      };
    }

    case actionTypes.STOP: {
      return {
        ...state,
        listeningTo: state.listeningTo.filter(_ => _.key !== action.key),
        stateById: {
          ...state.stateById,
          [action.key]: undefined
        }
      };
    }

    case actionTypes.RELOAD: {
      return {
        ...state,
        stateById: {
          ...state.stateById,
          [action.key]: Date.now()
        }
      };
    }

    default:
      return state;
  }
};
