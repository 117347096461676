export const tabs = {
  REGISTRANT: "registrant",
  ADMIN: "admin",
  BILLING: "billing",
  TECHNICAL: "technical"
};

export const ApprovalType = {
  DESIGNATED_AGENT: "designated-agent",
  REGISTRANT: "registrant"
};
