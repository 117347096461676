import api from "api";
import { parseStringAsBoolean, StringAsBoolean } from "common/parse-string";
import { parseSearch } from "common/urls";
import endpoints from "constants/endpoints";

const RESELLER = {
  SELF: `${endpoints.bclCacheableOrderBox}resellers/details.json`
};

const CUSTOMER = {
  SELF: `${endpoints.zuulOrderBoxV2}customers/`
};

const Endpoints = {
  PARENT: `${endpoints.webpro}customer/resellerdetails`
};

type ResellerDetails = {
  twofactorgoogleauth_enabled: StringAsBoolean;
  twofactorsmsauth_enabled: StringAsBoolean;
  isoldreseller: StringAsBoolean;
  switch_panel_enabled: StringAsBoolean;
  billingmode: string;
  supportsautorenew: StringAsBoolean;
  resellerstatus: string;
};

type ParentDetails = {
  brandname: string;
  url: string;
  logo: string;
  userid: string;
  sellingcurrencysymbol: string;
};

export const UserDetailsSdk = {
  async parent() {
    let { url } = parseSearch(window.location.search);
    let qs = {};
    if (process.env.BUILD_ENV !== "production") {
      qs["branded-url"] = url;
    }
    return api.get<ParentDetails>(Endpoints.PARENT, qs);
  },
  /**
   *
   * Fetch details about authenticated reseller.
   *
   * | Roles    |
   * | -------- |
   * | Reseller |
   *
   * #### Usage
   * ```js
   * const reseller = await HttpApi.UserDetails.reseller();
   * ```
   */
  async reseller() {
    let details = await api.get<ResellerDetails>(RESELLER.SELF);
    return {
      ...details,
      twofactorgoogleauth_enabled: parseStringAsBoolean(
        details.twofactorgoogleauth_enabled
      ),
      twofactorsmsauth_enabled: parseStringAsBoolean(
        details.twofactorsmsauth_enabled
      ),
      isOldReseller: parseStringAsBoolean(details.isoldreseller),
      isSwitchPanelEnabled: parseStringAsBoolean(details.switch_panel_enabled),
      billingMode: details.billingmode,
      supportautorenew: parseStringAsBoolean(details.supportsautorenew),
      status: details.resellerstatus
    };
  },
  /**
   *
   * Fetch details about authenticated customer.
   *
   * | Roles    |
   * | -------- |
   * | Customer |
   *
   * #### Usage
   *
   * ```js
   * const customer = await HttpApi.UserDetails.customer();
   * ```
   *
   */

  async customer() {
    let response = await api.get<OrderBoxV2.CustomerDto>(CUSTOMER.SELF);
    let {
      customerId,
      status,
      customerStatusDesc,
      pin,
      resellerId,
      twoFactorAuthEnabledPrivate,
      ...details
    } = response;
    return {
      ...details,
      customerid: customerId,
      customerstatus: status,
      customerstatusdesc: customerStatusDesc,
      pin: String(pin),
      resellerId: String(resellerId),
      twofactorauth_enabled: twoFactorAuthEnabledPrivate,
      useremail: details.username,
      parentid: String(resellerId)
    };
  }
};
