import api from "api";
import endpoints from "constants/endpoints";

export const services = {
  updateSecret: (orderID, qs) =>
    api.post(
      `${endpoints.domain}modify-auth-code`,
      { "order-id": orderID },
      new URLSearchParams({ "auth-code": qs.auth_code })
    )
};
