import api from "api";
import format from "string-format";

export const services = {
  searchBackupOrder: ({ endpoints: { searchBackupOrder }, ...payload }) =>
    api.get(format(searchBackupOrder, payload)),
  getDetails: ({ endpoints: { getBackupOrderDetails }, ...payload }) =>
    api.get(format(getBackupOrderDetails, payload)),
  installAgent: ({ endpoints: { installBackupAgent }, ...payload }) =>
    api.post(format(installBackupAgent, payload))
};
