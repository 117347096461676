import { put, takeEvery } from "redux-saga/effects";

import { actionTypes, actions } from "./ducks";
import { services } from "./services";

export default [
  takeEvery(actionTypes.MODIFY_TRANSFER_SECRET_REQUEST, onModifyTransferSecret)
];

export function* onModifyTransferSecret(action) {
  try {
    const { authCode, ...meta } = action.payload;
    yield services.updateSecret(meta.orderid, {
      auth_code: authCode
    });
    yield put(actions.modifyTransferSecretSuccess(authCode, meta));
  } catch (error) {
    yield put(actions.modifyTransferSecretFailure(error));
  }
}
