import { styled } from "linaria/react";
import { colors, fonts } from "common/styles";

export let GridContainer = styled.div`
  display: grid;
  grid-template-areas:
    "header header"
    "menu main"
    "menu footer";
  background-color: #f9f9fb;
  grid-template-columns: min-content auto;
`;

export let GridHeader = styled.div`
  grid-area: header;
`;

export let GridMain = styled.div`
  grid-area: main;
  transition: all 0.2s ease;
  @media (width < 768px) {
    &.mobile_grid_view {
      overflow: hidden;
    }
  }
`;

export let GridSidebar = styled.div`
  grid-area: menu;
  &:hover {
    transition: all 0.2s ease;
  }
`;

export let GridFooter = styled.div`
  grid-area: footer;
  align-self: end;
  &[data-zero-margin="true"] {
    margin: 0;
  }
  @media (max-width: 767px) {
    margin: 50px 0 80px 0;
  }
`;

export let AutoRetryRenewalWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 240px;
  margin-top: 4px;
  // ccp responsive css starts
  &.ccp_auto_renewal_wrapper {
    width: auto !important;
  }
  @media (width < 768px) {
    &.ccp_auto_renewal_wrapper {
      width: auto !important;
    }
  }
  // ccp responsive css ends
`;

export let TypographyMainType = styled.span`
  color: ${colors.gray.gray900};
  font-family: ${fonts.OpenSans};
  font-size: 12px;
`;

export let TypographySecondaryType = styled.span`
  color: ${colors.gray.gray700};
  font-family: ${fonts.OpenSans};
  font-size: 11px;
`;
