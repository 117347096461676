import { isDateInSeries } from "common/timeseries";
import { monthIndices } from "constants/index";

let today = new Date();

export let easterEggs = {
  christmas: [
    {
      start: Date.UTC(today.getFullYear(), monthIndices.Dec - 1, 23),
      end: Date.UTC(today.getFullYear() + 1, monthIndices.Jan - 1, 4)
    }
  ]
};

export function isChristmas() {
  return isDateInSeries(Date.now(), easterEggs.christmas);
}
