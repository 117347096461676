import DropDownCaretIcon from "assets/icons/dropdown-caret-icon";
import { useListbox } from "common/listbox";
import { OptionsPopover } from "common/popover";
import { colors, fonts } from "common/styles";
import { styled } from "linaria/react";
import React, { useEffect, useRef } from "react";

let ListBox = styled.div`
  position: relative;
`;

export let DropdownOption = styled.div`
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid #f2f2f4;
  }
  &:first-of-type {
    padding-top: 10px;
  }
  background: ${colors.white.regular};
  &:hover,
  &:focus {
    background: #f6f6f7;
  }
  &[aria-selected="true"] {
    background: #f6f6f8;
  }
  cursor: pointer;
  display: flex;
  padding: 16px 18px 15px 17px;
  position: relative;
`;

export let SelectedOption = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  position: relative;
  width: 100%;
  z-index: 1111;
  border: 1px solid ${colors.gray.gray400};
  border-radius: 2px;
  &[data-expanded="true"] {
    border: 1px solid rgba(0, 0, 0, 0);
    background: ${colors.white.regular};
    border: 1px solid ${colors.blue.regular};
  }
`;

export let DropDownOptionTitle = styled.span`
  color: ${colors.black.regular};
  font: 600 12px/17px ${fonts.OpenSans};
`;

type WPSelectProps = {
  items: any;
  title?: string;
  onChange: any;
  width?: number;
  dropDownStyles?: any;
  selectBoxStyles?: any;
  defaultSelectedIndex?: number;
};

export function WPSelect({
  items,
  title,
  onChange,
  width,
  defaultSelectedIndex,
  dropDownStyles,
  selectBoxStyles
}: WPSelectProps) {
  let listBoxRef = useRef(null);
  let {
    selected,
    expand,
    getControls,
    getListboxProps,
    getSelectedItemProps,
    getOptionProps,
    handleReset
  } = useListbox(listBoxRef, defaultSelectedIndex);
  useEffect(() => {
    onChange(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (selected !== defaultSelectedIndex) {
      handleReset(defaultSelectedIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelectedIndex]);
  return (
    <div style={{ maxWidth: width || "100%", position: "relative" }}>
      <ListBox {...getListboxProps(title)} className="list-box">
        <SelectedOption
          ref={listBoxRef}
          tabIndex="0"
          {...getSelectedItemProps()}
          {...getControls()}
          data-expanded={expand}
          className={selectBoxStyles}
        >
          <DropDownOptionTitle>{items[selected]}</DropDownOptionTitle>
          <DropDownCaretIcon
            data-attr-rotate={expand}
            style={{ position: "relative", top: "1px" }}
          />
        </SelectedOption>
      </ListBox>
      {expand && (
        <OptionsPopover
          title={title}
          style={{ width: "100%" }}
          className="options-popover"
        >
          {items.map((item, index) => (
            <DropdownOption
              tabIndex="0"
              key={item + index}
              {...getOptionProps(index)}
              className={dropDownStyles}
            >
              <DropDownOptionTitle>{item}</DropDownOptionTitle>
            </DropdownOption>
          ))}
        </OptionsPopover>
      )}
    </div>
  );
}
