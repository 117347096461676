import dataLayerPush from "common/datalayer-push";
import { CustomerManagementSdk } from "common/sdk/customer-management";
import { getCountryCode } from "constants/countries";
import { mapEffect } from "map-effect";
import { delay } from "redux-saga";
import { put, takeEvery, takeLatest } from "redux-saga/effects";
import { actions, actionTypes } from "./ducks";
import services from "./services";

export const customerCreateSagas = [
  takeLatest(actionTypes.VALIDATE_ZIPCODE, mapEffect(onValidateZipcode)),
  takeLatest(
    actionTypes.GET_COUNTRY_FROM_ZIPCODE,
    mapEffect(onGetCountryFromZipcode)
  ),
  takeEvery(actionTypes.CREATE_CUSTOMER, mapEffect(createCustomer))
];

function createCustomer(action) {
  return CustomerManagementSdk.create(action.payload);
}

createCustomer.onSuccess = (action, customerid) => {
  action.onSuccess(customerid);
  dataLayerPush("CustomerCreate", {
    primaryDetails: {
      name: action.payload.name,
      emailId: action.payload.username,
      countryCode: action.payload.phone_cc,
      phoneNo: action.payload.phone
    },
    aboutTheirBusiness: {
      companyName: action.payload.company,
      address: action.payload.address_line_1,
      pin: action.payload.zipcode,
      state: `${action.payload.state} ${action.payload.country}`,
      city: action.payload.city
    }
  });
};

function* onValidateZipcode(action) {
  yield delay(100);
  return services.validateZipcode(action.payload);
}

function* onGetCountryFromZipcode(action) {
  yield delay(100);
  const response = yield services.getCountryFromZipcode(action.payload);
  let setFieldValue = action.setFieldValue;

  if (response.city != null) {
    setFieldValue("city", response.city);
  }
  if (response.country != null) {
    setFieldValue(
      "country",
      getCountryCode(response.country).country_short || ""
    );
    yield put(
      actions.validateZipcode(
        action.payload.zipcode,
        getCountryCode(response.country).country_short
      )
    );
  }
  if (response.state != null) {
    setFieldValue("state", response.state);
  }
}
