import PropTypes from "prop-types";
import { useEffect, useState } from "react";

export function useSticky(distanceFromTop, elementRef) {
  let [isStuck, setStuck] = useState();
  useEffect(() => {
    function onScroll() {
      let box = elementRef.current.getBoundingClientRect();
      setStuck(Math.trunc(box.top) === distanceFromTop);
    }
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [distanceFromTop, elementRef]);
  return isStuck;
}

function useScroll(onScroll) {
  let [scrollY, setScroll] = useState(window.scrollY);

  function handleScroll() {
    setScroll(window.scrollY);
    onScroll();
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return scrollY;
}

export default function ScrollSpy(props) {
  let posY = useScroll(props.onScroll);
  return props.render({
    posY,
    isStuck: posY > props.stickyThreshold
  });
}

ScrollSpy.propTypes = {
  render: PropTypes.func.isRequired,
  stickyThreshold: PropTypes.number,
  onScroll: PropTypes.func
};

ScrollSpy.defaultProps = {
  onScroll: _ => {},
  stickyThreshold: 0
};
