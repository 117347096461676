import { applyAll } from "common/util";
import React from "react";
import Snitch from "./snitch";

/**
 * @typedef {Object} SnitchProps
 * @property {string | string[]} closesOn
 * @property {(triggerAction: Record<string, any>) => boolean} closesWhen
 * @property {boolean} defaultOpen
 * @property {string | string[]} opensOn
 * @property {(triggerAction: Record<string, any>) => boolean} opensWhen
 * @property {() => void} onHide
 * @property {(modalProps: import("modals/modal").ModalHandlerProps, triggerAction: import("modals/modal").ModalHandlerProps["triggerAction"]) => JSX.Element} render
 */

/** @type {React.FC<Partial<SnitchProps>>} */
const ModalHandler = ({ render, onHide = _ => _, ...props }) => (
  <Snitch
    {...props}
    render={({ show, open, close }, triggerAction) =>
      render(
        {
          show,
          onOpen: open,
          onHide: applyAll(onHide, close)
        },
        triggerAction
      )
    }
  />
);

export default ModalHandler;
