import { PlanDetails } from "common/plan-details/plan-details";
import { findPlanIdByName, isPlanInTrial } from "common/plan-details/util";
import { WidgetInstance } from "common/widget-types";
import { OrderStatus } from "constants/index";

export const actionTypes = {
  ACKNOWLEDGE_INTEREST: "[Plan Upgrades] Acknowledge Interest",
  CHOOSE_PLAN: "[Plan Upgrades] Choose Plan",
  CONFIRM_UPGRADE: "[Plan Upgrades] Confirm Upgrade"
};

export const actions = {
  acknowledgeInterest: ({ meta }) => ({
    type: actionTypes.ACKNOWLEDGE_INTEREST,
    ...meta
  }),
  showUpgradeModal: meta => ({ type: actionTypes.CONFIRM_UPGRADE, ...meta }),
  choosePlan: ({ meta }) => ({ type: actionTypes.CHOOSE_PLAN, ...meta })
};

export const parsers = {
  titanUpgradeDetails: (
    planDetails: Record<string, Record<string, PlanDetails>>,
    meta: WidgetInstance["meta"]
  ) => {
    let planid = meta.details?.planid;
    let productPlans = planDetails[meta.productkey];
    let currentPlan = productPlans[planid];
    currentPlan.id = String(planid);
    let targetPlan: Partial<PlanDetails> = {};
    let upgradeStatus = "unavailable";
    if (
      meta.currentstatus?.toLowerCase() === OrderStatus.ACTIVE ||
      isPlanInTrial(currentPlan)
    ) {
      for (let [id, plan] of Object.entries(productPlans ?? {})) {
        if (isPlanInTrial(plan)) {
          plan.siblingPlan = findPlanIdByName(productPlans, "Business");
        }
        if (plan.plan_name === "Enterprise" && plan.plan_status === "Active") {
          targetPlan = plan;
          targetPlan.id = id;
          upgradeStatus = planid === Number(id) ? "complete" : "available";
        }
      }
    }
    return { currentPlan, targetPlan, productPlans, upgradeStatus };
  }
};
