import api from "api";
import constants from "constants/endpoints";

export const services = {
  saveNameServers: (ns, orderId) =>
    api.post(`${constants.domain}modify-ns`, { ns, "order-id": orderId }),
  activateDNSRecords: orderId =>
    api.post(`${constants.zuulOrderBox}dns/activate`, { "order-id": orderId }),
  getDNSRecords: ({ domainName, type, pageNo, noOfRecords }) =>
    api.get(`${constants.dns}search-records`, {
      "domain-name": domainName,
      type,
      "page-no": pageNo,
      "no-of-records": noOfRecords
    }),
  editRecord: (domainName, type, params) =>
    api.post(
      `${constants.dns}update-${type}-record`,
      { "domain-name": domainName },
      new URLSearchParams(params)
    ),
  deleteRecord: (domainName, type, params) =>
    api.post(`${constants.dns}delete-${type}-record`, {
      "domain-name": domainName,
      ...params
    }),
  addRecord: (domainName, type, params) =>
    api.post(
      `${constants.dns}add-${type}-record`,
      { "domain-name": domainName },
      new URLSearchParams(params)
    )
};
