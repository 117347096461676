import {
  actions as AnalyticsActions,
  categories as AnalyticsCategories,
  labels as AnalyticsLabels
} from "analytics/constants";
import { isFailure, isSuccess } from "common/api-status/utils";
import { getProrataCost, reduceObject } from "common/util";
import { getSimpleNameFromProductKey, MaxLimitAddons } from "constants/index";

export const actionTypes = {
  SHOW_ADD_MODAL: "[Manage Addons] Show add modal",
  SHOW_DELETE_MODAL: "[Manage Addons] Show delete modal",
  SHOW_MANAGE_MODAL: "[Manage Addons] Show manage modal",

  BUY_ADDONS: "[Manage Addons] Buy",

  DELETE_ADDON: "[Manage Addons] Delete",

  GET_PRICING_DETAILS_ALLOWED_ADDONS:
    "[Widgets] Get pricing details of allowed addons",

  GET_PLAN_DETAILS_ALLOWED_ADDONS:
    "[Widgets] Get plan details of allowed addons"
};

export const initialState = {
  addons: {
    addonDetails: {},
    addonsList: [],
    addonsPricing: {}
  }
};

export const actions = {
  deleteAddon: ({ parent, ...addonDetails }) => ({
    type: actionTypes.DELETE_ADDON,
    payload: { parent, ...addonDetails },
    track: {
      eventCategory: AnalyticsCategories.delete_addon,
      eventAction: `${AnalyticsActions.delete_addon}${parent}`,
      eventLabel: AnalyticsLabels.delete_addon
    }
  }),
  deleteAddonSuccess: payload => ({
    type: isSuccess(actionTypes.DELETE_ADDON),
    ...payload,
    track: {
      eventCategory: "Addon",
      eventAction: "gtm_delete_addon_success",
      attributes: {
        addonId: payload.addonId,
        addonName: payload.addonName,
        orderid: payload.orderid,
        productkey: payload.productkey
      }
    }
  }),
  deleteAddonFailure: (error, payload) => ({
    type: isFailure(actionTypes.DELETE_ADDON),
    error,
    payload
  }),
  getAddonPricingDetailsSuccess: (pricingDetails, planDetails, payload) => ({
    type: isSuccess(actionTypes.GET_PRICING_DETAILS_ALLOWED_ADDONS),
    pricingDetails,
    planDetails,
    payload
  }),
  getAddonPricingDetailsFailure: (error, planDetails, payload) => ({
    type: isFailure(actionTypes.GET_PRICING_DETAILS_ALLOWED_ADDONS),
    error,
    planDetails,
    payload
  }),
  getDetailsOfAllowedAddons: ({ meta, hierarchy }) => ({
    type: actionTypes.GET_PLAN_DETAILS_ALLOWED_ADDONS,
    payload: { ...hierarchy, parent: meta.orderid, ...meta }
  }),
  getDetailsOfAllowedAddonsSuccess: (response, orderDetails, payload) => ({
    type: isSuccess(actionTypes.GET_PLAN_DETAILS_ALLOWED_ADDONS),
    planDetails: response,
    orderDetails,
    payload
  }),
  getDetailsOfAllowedAddonsFailure: (response, orderDetails, payload) => ({
    type: isFailure(actionTypes.GET_PLAN_DETAILS_ALLOWED_ADDONS),
    response,
    orderDetails,
    payload
  })
};

export default (state, action) => {
  switch (action.type) {
    case actionTypes.SHOW_DELETE_MODAL: {
      if (!action.value) {
        return state;
      }
      const { addonToBeDeleted, parent, child } = action.value;
      return {
        ...state,
        [parent]: {
          ...state[parent],
          [child]: {
            ...state[parent][child],
            addonToBeDeleted
          }
        }
      };
    }

    case isSuccess(actionTypes.GET_PLAN_DETAILS_ALLOWED_ADDONS): {
      const productkey = action.orderDetails.productkey;
      if (productkey.startsWith("wordpress")) {
        const {
          orderDetails: { planid },
          planDetails,
          payload: { parent, child }
        } = action;
        const {
          [productkey]: {
            [planid]: { addons, ram, cores }
          }
        } = planDetails;
        const addonDetails = {};
        addons
          .filter(addon => addon.addon_name !== "ipaddress")
          .forEach(addon => {
            const addOnName = addon.addon_name
              ? addon.addon_name
              : addon.addonName;
            addonDetails[addOnName] = {
              addOnName,
              addOnDisplayText: helpers.getAddonName(productkey, addOnName),
              maxLimit: helpers.getMaxLimit(
                addOnName,
                {
                  ram: { ram_memory: parseInt(ram, 10) },
                  cpu: { cpu_cores: parseInt(cores, 10) }
                },
                productkey
              ),
              ...addon
            };
          });
        return {
          ...state,
          [parent]: {
            ...state[parent],
            [child]: { ...state[parent][child], addonDetails }
          }
        };
      }
      const {
        orderDetails: { planid, installed_os: { os_name = "" } = {} },
        planDetails,
        payload: { parent, child }
      } = action;
      const {
        [productkey]: {
          [planid]: { supported_os = [], ...planLimits } = {}
        } = {}
      } = planDetails;
      const addonDetails = {};
      supported_os
        .filter(
          supported_os_details => supported_os_details.os_name === os_name
        )
        .forEach(supported_os_details => {
          supported_os_details.addons
            .filter(
              ({ addon_name, addonName = addon_name }) =>
                addonName !== "ipaddress" && addonName !== "ssl" // IP address is treated differently.
            )
            .forEach(
              ({
                addon_name,
                addonName: addOnName = addon_name,
                supported_quantity: maxLimit = helpers.getMaxLimit(
                  addOnName,
                  planLimits,
                  productkey
                ),
                ...addon
              }) => {
                addonDetails[addOnName] = {
                  addOnName,
                  addOnDisplayText: helpers.getAddonName(productkey, addOnName),
                  maxLimit,
                  ...addon
                };
              }
            );
        });
      return {
        ...state,
        [parent]: {
          ...state[parent],
          [child]: { ...state[parent][child], addonDetails }
        }
      };
    }

    case isSuccess(actionTypes.GET_PRICING_DETAILS_ALLOWED_ADDONS): {
      const {
        payload: { parent, child, productkey, expiryTimestamp } = {}
      } = action;
      const { [productkey]: { addons: addonsPricing = {} } = {} } =
        action.pricingDetails.reseller_pricing ?? action.pricingDetails;

      const proratedAddonsPricing = Object.entries(addonsPricing)
        .map(([addonName, addonPrice]) => ({
          [addonName]: getProrataCost(addonPrice, expiryTimestamp)
        }))
        .reduce(reduceObject, {});
      return {
        ...state,
        [parent]: {
          ...state[parent],
          [child]: {
            ...state[parent][child],
            addonsPricing: proratedAddonsPricing
          }
        }
      };
    }
    default:
      return state;
  }
};

export const helpers = {
  getAddonName(productkey, addonName) {
    let getHelper;
    if (
      productkey.startsWith("dedi") ||
      productkey === "bluehostdedicatedserverlinuxus" ||
      productkey.startsWith("hostgator")
    ) {
      getHelper = helpers.getDedicatedAddonName;
    } else if (
      productkey.startsWith("cloud") ||
      productkey.startsWith("wordpress")
    ) {
      getHelper = helpers.getCHAddonName;
    } else {
      getHelper = helpers.getVPSAddonName;
    }
    return getHelper(addonName);
  },
  getCHAddonName(addonName) {
    switch (addonName) {
      case "ram_1":
        return "1 GB RAM";
      case "cpu_1":
        return "1 Core CPU ";
      default:
        break;
    }
  },
  getDedicatedAddonName(addonName) {
    switch (addonName) {
      case "whmcs":
        return "WHMCS License";
      case "cpanel":
        return "cPanel License";
      case "cpanel_pro_cloud":
        return "cPanel Pro Cloud License";
      case "cpanel_plus_cloud":
        return "cPanel Plus Cloud License";
      case "cpanel_premier_cloud":
        return "cPanel Premier Cloud License";
      case "dvps_cpanel_blocks":
        return "cPanel Block (50 Accounts)";
      case "cpanel_admin_cloud":
        return "cPanel Admin Cloud License";
      case "bandwidth_1":
        return "1 Mbps";
      case "storage_1":
        return "50 GB SAN Storage";
      case "storage_2":
        return "100 GB SAN Storage";
      case "storage_3":
        return "200 GB SAN Storage";
      case "storage_4":
        return "300 GB SAN Storage";
      case "storage_5":
        return "500 GB SAN Storage";
      case "storage_6":
        return "250 GB SAN Storage";
      case "storage_7":
        return "750 GB SAN Storage";
      case "storage_8":
        return "1 TB SAN Storage";
      case "storage_9":
        return "2 TB SAN Storage";
      case "storage_10":
        return "2.5 TB SAN Storage";
      case "storage_11":
        return "3 TB SAN Storage";
      case "storage_12":
        return "3.5 TB SAN Storage";
      case "storage_13":
        return "4 TB SAN Storage";
      case "ram_2":
        return "4 GB RAM";
      case "ram_3":
        return "8 GB RAM";
      case "ram_4":
        return "12 GB RAM";
      case "ram_5":
        return "16 GB RAM";
      case "ram_6":
        return "20 GB RAM";
      case "ram_7":
        return "24 GB RAM";
      case "ram_8":
        return "28 GB RAM";
      case "plesk_web_admin_license":
        return "Plesk Web Admin (10 domains)";
      case "plesk_web_host_license":
        return "Plesk Web Host (Unlimited domains)";
      case "plesk_web_pro_license":
        return "Plesk Web Pro (30 domains)";
      case "plesk_10_domain":
        return "Plesk 10 Domain License";
      case "plesk_100_domain":
        return "Plesk 100 Domain License";
      case "plesk_unlimited_domain":
        return "Plesk Unlimited Domain License";
      case "plesk_30_domain":
        return "Plesk 30 Domain License";
      case "managed_services":
        return "Managed Services";
      case "backup":
        return "Acronis Cyber Backup";
      default:
        return addonName;
    }
  },
  getMaxLimit(addonName, planLimits, productkey) {
    if (
      productkey === "bluehostdedicatedserverlinuxus" ||
      productkey === "hostgatordedicatedserverlinuxus"
    ) {
      switch (addonName) {
        case "whmcs":
        case "cpanel":
          return 1;
        default:
          return Infinity;
      }
    }
    if (getSimpleNameFromProductKey(productkey) === "wph") {
      //
      // In case of WPH the plan details response contains RAM and CPU that have already been installed
      // at the time of purchase. The max limit for each of them is 8.
      //
      switch (addonName) {
        case "ram_1": {
          return (
            MaxLimitAddons.WORDPRESS_MAX_LIMIT_ADDONS -
            planLimits.ram.ram_memory / 1024
          );
        }
        case "cpu_1": {
          return (
            MaxLimitAddons.WORDPRESS_MAX_LIMIT_ADDONS - planLimits.cpu.cpu_cores
          );
        }
        default:
          return 1;
      }
    }
    switch (addonName) {
      case "ram_1":
        return planLimits.ram.ram_memory / 1024;

      case "cpu_1":
        return planLimits.cpu.cpu_cores;

      case "bandwidth_1":
        return 5;

      default:
        return 1;
    }
  },
  getVPSAddonName(addonName) {
    switch (addonName) {
      case "ram_9":
        return "2 GB RAM";
      case "ram_10":
        return "4 GB RAM";
      case "ram_11":
        return "6 GB RAM";
      case "cpu_1":
        return "1 Core CPU";
      case "cpu_2":
        return "2 Core CPU";
      case "cpu_4":
        return "4 Core CPU";
      case "whmcs":
        return "WHMCS";
      case "plesk_10_domain":
        return "Plesk 10-Domain License";
      case "plesk_100_domain":
        return "Plesk 100-Domain License";
      case "plesk_unlimited_domain":
        return "Plesk Unlimited Domain License";
      case "plesk_30_domain":
        return "Plesk 30-Domain License";
      case "cpanel":
        return "cPanel License";
      case "cpanel_pro_cloud":
        return "cPanel Pro Cloud License";
      case "cpanel_plus_cloud":
        return "cPanel Plus Cloud License";
      case "cpanel_premier_cloud":
        return "cPanel Premier Cloud License";
      case "dvps_cpanel_blocks":
        return "cPanel Block (50 Accounts)";
      case "cpanel_admin_cloud":
        return "cPanel Admin Cloud License";
      case "cpanel_blocks":
        return "cPanel Accounts";
      case "email_storage_5":
        return "Email Storage";
      case "storage_1":
        return "50 GB SAN Storage";
      case "storage_2":
        return "100 GB SAN Storage";
      case "storage_3":
        return "200 GB SAN Storage";
      case "storage_4":
        return "300 GB SAN Storage";
      case "storage_5":
        return "500 GB SAN Storage";
      case "blockstorage":
        return "Block Storage";
      case "backup":
        return "Acronis Cyber Backup";
      case "managed_services":
        return "Managed Services";
      default:
        return addonName;
    }
  }
};

export const parsers = {
  wordpressHostingAddonParser: (payload, meta) => {
    const { productkey } = meta;
    const addonsList = payload.addon_quantity
      .filter(addon => addon.addon_name !== "ipaddress")
      .map(({ addon_name, addon_id, is_paid = false }) => ({
        addOnName: addon_name,
        addOnID: addon_id,
        is_paid,
        // Hard-coded false as for WP Hosting even if addons are paid, deletion is not allowed
        showDelete: false,
        name: helpers.getAddonName(productkey, addon_name)
      }));
    return { addonsList };
  },
  parseAddonDetails: (
    { installed_os: { addons = [] } = {} },
    { productkey }
  ) => {
    const addonsList = addons
      .filter(
        ({ addon_name, name = addon_name }) =>
          name !== "ssl" &&
          name !== "ipaddress" &&
          name !== "virtuozzo" &&
          name !== "managed_services"
      )
      .map(
        ({
          addon_name,
          name = addon_name,
          addon_id,
          addOnId = addon_id,
          addOnID = addOnId,
          is_paid: showDelete = false
        }) => ({
          addOnName: name,
          addOnID,
          name: helpers.getAddonName(productkey, name),
          showDelete
        })
      );
    return { addonsList };
  }
};
