import React, { useState } from "react";
import storage from "@faizaanceg/pandora";
import { styled } from "linaria/react";
import { cx } from "linaria";
import LeftSidePattern from "./assets/leftside-pattern.png";
import RightSidePattern from "./assets/rightside-pattern.png";
import InvoiceImage from "./assets/invoice-center-img.png";
import ScrollSpy from "common/scroll-spy";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

let BetaTooltip = styled.div`
  position: fixed;
  background: white;
  color: black;
  padding: 22px 30px 30px;
  border: 1px solid #eeeef2;
  border-radius: 2px;
  box-shadow: 0 0 30px 0 rgba(211, 211, 218, 0.6);
  left: 80px;
  z-index: 1;
  width: 460px;
  top: 320px;
  transition: 0.2s;
  text-align: center;
  animation: 0.3s "scale-fade-in" 1 linear;
  &.is-expanded {
    margin-left: 140px;
    top: 355px;
    &.is-fixed {
      top: 290px;
    }
  }
  &.is-fixed {
    top: 252px;
  }
  &:before {
    width: 0;
    height: 0;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-right: 9px solid #ffffff;
    content: "";
    position: absolute;
    left: -9px;
    top: 14px;
  }
  &:after {
    content: "";
    background-image: url(${LeftSidePattern}), url(${RightSidePattern});
    background-position: left -1px center, right center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: 35px, 35px;
    z-index: -1;
  }
`;

let BetaTooltipTitle = styled.div`
  font-family: "OpenSans";
  font-size: 16px;
  font-weight: 700;
  color: #434c5e;
  line-height: 24px;
  margin-bottom: 7px;
`;

let BetaTooltipText = styled.div`
  font-family: "OpenSans";
  font-size: 13px;
  font-weight: 400;
  color: #496275;
  line-height: 20px;
  padding: 0 25px;
  margin-bottom: 20px;
`;

let BetaProTooltipActions = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  a.beta-explore {
    background: #468def;
    display: inline-block;
    color: #ffffff;
    font-size: 11px;
    line-height: 15px;
    font-family: "OpenSans";
    font-weight: 600;
    padding: 11px 40px 12px;
    text-transform: uppercase;
    border-radius: 2px;
  }
`;

let BetaSkipAction = styled.button`
  color: #468def;
  font-family: "OpenSans";
  font-weight: 400;
  font-size: 12px;
  background: transparent;
  border: none;
  outline: none;
  margin-left: 4px;
`;

export default function BetaProTooltip(props) {
  let [show, updateShow] = useState(true);
  const { t } = useTranslation();

  const hideTooltip = () => {
    updateShow(false);
    storage.set("disable-pro-tooltip", true, { shouldPersist: true });
  };

  return (
    <>
      {show && !storage.get("disable-pro-tooltip") && (
        <ScrollSpy
          stickyThreshold={64}
          render={({ isStuck }) => (
            <BetaTooltip
              className={cx(
                "sidebar-tooltip",
                isStuck && "is-fixed",
                !props.isExpanded && "is-expanded"
              )}
            >
              <BetaTooltipTitle>
                {t("PRO_SUITE_WALKTHROUGH_TITLE", "Invoice like a PRO!")}
              </BetaTooltipTitle>
              <BetaTooltipText>
                {t(
                  "PRO_SUITE_WALKTHROUGH_MESSAGE",
                  "Now create invoices for all your services and record payments from clients using our simple Pro-Invoice tool!"
                )}
              </BetaTooltipText>
              <img
                src={InvoiceImage}
                width="100%"
                style={{ marginBottom: "25px" }}
              />
              <BetaProTooltipActions>
                <Link
                  to="/settings/pro-suite/invoices"
                  className="beta-explore"
                >
                  {t("EXPLORE", "Explore")}
                </Link>
                <BetaSkipAction onClick={_ => hideTooltip()}>
                  {t("SKIP", "Skip")}
                </BetaSkipAction>
              </BetaProTooltipActions>
            </BetaTooltip>
          )}
        />
      )}
    </>
  );
}
