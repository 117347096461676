import api from "api";
import { h } from "common/h";
import endpoints from "constants/endpoints";
import { mapEffect } from "map-effect";
import { takeEvery } from "redux-saga/effects";
import { actionTypes } from "./ducks";

/**
 *
 * @param {File} file
 * @returns {Promise<File>}
 * @throws {{key: string; message: string}}
 */
function checkFileUpload(file) {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    if (file.size > 5242880) {
      reject({
        key: "PROSUITE_IMAGE_SIZE_ERROR",
        message: "Image size should be less than 5mb"
      });
    } else {
      reader.onload = event => {
        const img = h("img", { src: event.target.result });
        img.style.display = "none";
        img.onerror = () => {
          img.remove();
          reject({
            key: "PROSUITE_IMAGE_UPLOAD_ERROR",
            message: "Please select an Image"
          });
        };
        img.onload = () => {
          img.remove();
          resolve();
        };
        document.body.appendChild(img);
      };
      reader.readAsDataURL(file);
    }
  });
}

export let logoSagas = [
  takeEvery(
    actionTypes.UPLOAD_LOGO,
    mapEffect(async ({ file }) => {
      await checkFileUpload(file);
      let form = new FormData();
      form.append("logo", new Blob([file], { type: file.type }), file.name);
      return api.postBody(`${endpoints.apiHost}webpro/common/logo`, form);
    })
  )
];
