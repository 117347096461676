import api from "api";

export const services = {
  changePassword: ({ endpoint, orderid, newPassword = "", productkey }) =>
    api.postBody(
      endpoint,
      new URLSearchParams({
        "order-id": orderid,
        "new-passwd": newPassword
      })
    )
};
