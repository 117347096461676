import APIStatus from "common/api-status";
import ModalHandler from "common/modal-handler";
import { actionTypes as sessionActionTypes } from "common/session-management/ducks";
import { openPage, showLoader } from "common/util";
import React from "react";
import { Overlay, Popover } from "react-bootstrap";
import history from "../history";
import { Link, Router } from "react-router-dom";
import { withRouter } from "react-router";
import storage from "@faizaanceg/pandora";
import HelpIcon from "./assets/help.svg";
import RCLogo from "./assets/resellerclub-logo.png";
import { SwitchPanel } from "./switch-panel";
import UserProfileIcon from "./assets/user.svg";
import FeedbackModal from "./feedback/add-feedback";
import ProfilePopover from "./profile-popover";
import { styled } from "linaria/react";
import { css, cx } from "linaria";
import BetaProTooltip from "common/beta-pro-tooltip";
import AutoLoginHelpDesk from "./hooks/helpdesk";
import { withTranslation } from "react-i18next";
import WalkThroughWrapper from "./walk-through-wrapper";
import ErrorBoundary from "common/errors/boundary";
import { isChristmas } from "common/seasonal-greetings";
import classnames from "classnames";
import { withLogo } from "common/logo";

import "./styles.scss";

let HamburgerWrapper = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #bbbbca 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(7, 7);
    opacity: 0;
    transition: transform 0.3s, opacity 1s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: 0.5;
    transition: 0s;
  }
  // ccp responsive css starts
  @media (width < 768px) {
    &.ccp_view {
      display: none;
    }
  }
  // ccp responsive css ends
`;

let Hamburger = styled.button`
  text-indent: -99999px;
  width: 18px;
  height: 12px;
  background: none;
  position: relative;
  cursor: pointer;
  transition: background-color 0.8s;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  border: none;
  outline: none;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0px;
    width: 18px;
    height: 2px;
    background: #bbbbca;
    box-shadow: 0 5px 0 0 #bbbbca, 0 10px 0 0 #bbbbca;
  }
`;

let LogoWrapper = styled.div`
  @media (max-width: 481px) {
    flex: 2;
  }
  display: flex;
  align-items: center;
`;

let userProfileDropdown = css`
  max-width: 290px;
  width: 100%;
  right: 20px;
  padding: 0;
  box-shadow: none !important;
  .popover-content {
    padding: 0;
    width: 300px;
    right: 30px;
    position: absolute;
    background: #fff;
    box-shadow: 0 -1px 2px 0 rgba(211, 211, 218, 0.4),
      0 0 6px 0 rgba(211, 211, 218, 0.6);
    border-radius: 2px;
  }
`;

class Header extends React.Component {
  helpRef = React.createRef();
  profileRef = React.createRef();

  state = {
    isShowLogo: true,
    toggleProfile: false,
    toggleHelp: false
  };

  componentDidMount() {
    this.walkthroughRef = React.createRef();
  }

  handleLogoClick = history => history.replace("/dashboard");

  handleHelpClick = () => this.setState({ toggleHelp: !this.state.toggleHelp });

  handleProfileClick = () =>
    this.setState({ toggleProfile: !this.state.toggleProfile });

  helpSections = autoLoginToHelpdesk => (
    <Popover
      id="popover-trigger-click-help"
      bsClass="wp-popover help-popover popover"
      title=""
      onClick={this.handleHelpClick}
    >
      <Router history={history}>
        <a
          role="button"
          className="wp-header-help-actions"
          href="/kb"
          rel="noopener noreferrer"
          target="_blank"
        >
          {this.props.t("KNOWLEDGEBASE", "Knowledge Base")}
        </a>
        {process.env.FEATURE_ENDPOINT === "reseller" && (
          <button
            type="button"
            className="wp-header-help-actions"
            onClick={autoLoginToHelpdesk}
          >
            {this.props.t("SUPPORT", "Support")}
          </button>
        )}
        {process.env.FEATURE_ENDPOINT === "reseller" && (
          <Link
            to="/dashboard/help"
            data-testid="help-link"
            className="wp-header-help-actions"
          >
            {this.props.t("CONTACT_ACC_MGR", "Contact your account manager")}
          </Link>
        )}
        {process.env.FEATURE_ENDPOINT === "reseller" && (
          <button
            type="button"
            className="wp-header-help-actions"
            onClick={() => {
              openPage("/dashboard");
              setTimeout(this.walkthroughRef.current.startTour, 500);
            }}
            rel="noopener noreferrer"
            target="_blank"
            data-testid="walkthrough-help-button"
          >
            {this.props.t(
              "HEADER_PANEL_WALKTHROUGH",
              "Walk me through the new panel"
            )}
          </button>
        )}
      </Router>
    </Popover>
  );

  setLogoDisplay = isShowLogo => this.setState({ isShowLogo });

  monitorInfo = [[sessionActionTypes.GO_TO_CONTROL_PANEL]];

  render() {
    const {
      name,
      brandingName,
      logoUrl,
      isSwitchPanelEnabled,
      isMaintenanceOn
    } = this.props;
    return (
      <Router history={history}>
        <ErrorBoundary>
          <ErrorBoundary errorMessage="">
            {process.env.FEATURE_ENDPOINT !== "customer" && (
              <WalkThroughWrapper
                isOldReseller={this.props.isOldReseller}
                isMaintenanceOn={isMaintenanceOn}
                showWalkThroughModal={this.props.showWalkThroughModal}
                ref={this.walkthroughRef}
                name={name}
              />
            )}
          </ErrorBoundary>
          {storage.get("walk-through-completed", false) &&
            process.env.FEATURE_ENDPOINT === "reseller" &&
            !isMaintenanceOn && (
              <BetaProTooltip isExpanded={this.props.isSidebarOpen} />
            )}
          <div
            className={cx("wp-header", isChristmas() && "wp-header-christmas")}
          >
            <LogoWrapper>
              <HamburgerWrapper
                onClick={this.props.sideBarCollapsed}
                className={classnames({
                  ccp_view: process.env.FEATURE_ENDPOINT === "bigrock"
                })}
              >
                <Hamburger />
              </HamburgerWrapper>
              {process.env.FEATURE_ENDPOINT === "reseller" ? (
                <img
                  src={RCLogo}
                  className="wp-header-logo"
                  alt="ResellerClub Logo"
                />
              ) : (
                <div
                  className="customer-branding-name"
                  onClick={this.handleLogoClick.bind(this, history)}
                >
                  {this.state.isShowLogo ? (
                    <img
                      className="customer-logo"
                      src={logoUrl}
                      alt={brandingName + " Logo"}
                      onError={this.setLogoDisplay.bind(this, false)}
                      onLoad={this.setLogoDisplay.bind(this, true)}
                    />
                  ) : (
                    brandingName
                  )}
                </div>
              )}
            </LogoWrapper>
            <div className="wp-header-actionables">
              {process.env.FEATURE_ENDPOINT !== "customer" &&
                isSwitchPanelEnabled &&
                !isMaintenanceOn && <SwitchPanel />}
              {process.env.FEATURE_HELP && (
                <>
                  <button
                    type="button"
                    className="wp-header-help"
                    onClick={
                      !this.state.toggleHelp ? this.handleHelpClick : null
                    }
                    ref={this.helpRef}
                  >
                    <img
                      src={HelpIcon}
                      className="wp-header-help-icon"
                      alt="Help Icon"
                      id="step7"
                      data-testid="help-button"
                    />
                    <span className="wp-header-help-text">
                      {this.props.t("HELP", "Help")}
                    </span>
                  </button>

                  <AutoLoginHelpDesk>
                    {({ autoLoginToHelpdesk, helpDeskStatus }) => (
                      <>
                        {!helpDeskStatus.isFetching ? (
                          <Overlay
                            show={this.state.toggleHelp}
                            placement="bottom"
                            target={this.helpRef.current}
                            rootClose
                            onHide={this.handleHelpClick}
                          >
                            {this.helpSections(autoLoginToHelpdesk)}
                          </Overlay>
                        ) : (
                          showLoader
                        )}
                      </>
                    )}
                  </AutoLoginHelpDesk>
                </>
              )}
              <button
                type="button"
                className="wp-header-user"
                onClick={
                  !this.state.toggleProfile ? this.handleProfileClick : null
                }
                ref={this.profileRef}
              >
                <img
                  className="wp-header-user-icon"
                  src={UserProfileIcon}
                  alt="user"
                />
                <span className="wp-header-user-name">{name}</span>
              </button>
              <Overlay
                show={this.state.toggleProfile}
                placement="bottom"
                target={this.profileRef.current}
                rootClose
                onHide={this.handleProfileClick}
              >
                <Popover id="header-profile" className={userProfileDropdown}>
                  <ProfilePopover clicked={this.handleProfileClick} />
                </Popover>
              </Overlay>
            </div>
          </div>
          <ModalHandler
            opensOn={sessionActionTypes.OPEN_FEEDBACK_MODAL}
            render={modalProps => <FeedbackModal {...modalProps} />}
          />
          <APIStatus monitors={this.monitorInfo}>
            {apiStatus => apiStatus.isFetching && showLoader}
          </APIStatus>
        </ErrorBoundary>
      </Router>
    );
  }
}

export default withLogo(withRouter(withTranslation()(Header)));
