import api from "api";
import format from "string-format";
import endpoints from "constants/endpoints";

export const services = {
  changeVerificationEmailSSL: payload =>
    api.post(
      format(payload.endpoints.changeVerificationEmail, payload.params),
      payload.params
    ),
  enrollSSL: payload =>
    api.postBody(
      format(
        payload.isReissue
          ? payload.endpoints.reissue
          : payload.endpoints.enroll,
        payload.params
      ),
      new URLSearchParams(payload.params)
    ),
  recheckVerificationStatus: ({ eaqid }) =>
    api.post(`${endpoints.cron_entity_action}${eaqid}`)
};
