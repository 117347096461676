import { DialogContent } from "@reach/dialog";
import ModalError from "common/errors/modal/index";
import { formatAmountWithCurrency } from "common/localize-amount";
import { colors, fonts } from "common/styles";
import { getTaxAmount, getTaxInfo } from "common/util";
import { useWalletInfo } from "common/wallet";
import { css, cx } from "linaria";
import { styled } from "linaria/react";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Cart } from "./assets/cart";
import { CloseModal } from "./assets/close-modal";
import BillingPreferencesButton from "./billing-preferences/component";
import { DialogOverlay } from "./index";
import {
  DialogContentStyle,
  DialogOverlayStyle,
  SecureModal,
  WPModalClose,
  WPModalContentBody
} from "./styles";

let WPBuyModalContainer = styled.div`
  display: grid;
  grid-template-areas: "main right";
  grid-template-columns: 1fr;
  width: 100%;
`;

let WPBuyGridMainContent = styled.div`
  grid-area: main;
`;
let WPBuyGridRightContent = styled.div`
  grid-area: right;
  max-width: 198px;
  background: ${colors.gray.gray100};
  padding: 25px 30px;
  border-radius: 0 2px 2px 0;
  font: 400 11px/1.5 ${fonts.OpenSans};
  p {
    color: #697489;
    margin-bottom: 12px;
    line-height: 18px;
  }
  strong {
    display: inline-block;
    font-weight: 600;
  }
`;

let WPBuyModalFor = styled.div`
  font: 400 12px/1.5 ${fonts.OpenSans};
  padding: 1px 70px 0;
  color: ${colors.gray.gray900};
`;

let WPBuyModalCart = styled.div`
  border: 1px ${colors.gray.gray400} solid;
  border-radius: 2px;
  margin: 50px 50px 30px;
  position: relative;
`;

let WPBuyModalCartIcon = styled.div`
  position: absolute;
  background: ${colors.gray.gray100};
  border: 1px #e8e8e8 solid;
  top: -26px;
  left: -26px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

let WPBuyModalCartTitleWrapper = styled.div`
  text-align: center;
  padding: 14px 0 16px;
  border-bottom: 1px ${colors.gray.gray400} solid;
`;

let WPBuyModalCartTitle = styled.div`
  font: 700 17px/1.5 ${fonts.BrandonGrotesque};
  color: ${colors.black.regular};
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

let WPBuyModalCostWrapper = styled.div`
  background-color: #f6f6f8;
  padding: 16px 16px 13px;
  display: grid;
  grid-auto-columns: max-content;
  justify-content: center;
  &[data-right-aligned="true"] {
    justify-content: flex-end;
    padding: 16px 24px 13px;
  }
`;

let WPBuyModalCostGrid = styled.div`
  display: inline-grid;
  grid-template-areas: "costtitle cost";
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  align-items: center;
  text-align: right;
  &[data-cost-type="subTotal"] {
    margin-bottom: 5px;
  }
  &[data-cost-type="totalTax"] {
    margin-bottom: 10px;
  }
  &[data-cost-type="total"] {
    border-top: 1px #d6d6d7 solid;
    padding-top: 3px;
  }
`;

let WPBuyModalCostTitle = styled.div`
  grid-area: costtitle;
  text-align: right;
  text-transform: uppercase;
  color: ${colors.black.regular};
  font: 400 12px/1.5 ${fonts.OpenSans};
  &[data-cost-type="subTotal"] {
    font-weight: 600;
  }
  &[data-cost-type="totalTax"] {
    color: #697489;
  }
  &[data-cost-type="total"] {
    font: 600 16px/1.5 ${fonts.OpenSans};
  }
`;
let WPBuyModalCost = styled.div`
  grid-area: cost;
  position: relative;
  text-align: left;
  color: ${colors.black.regular};
  font: 400 12px/1.5 ${fonts.OpenSans};
  &[data-cost-type="subTotal"] {
    font-weight: 700;
  }
  &[data-cost-type="totalTax"] {
    font-weight: 600;
    color: #697489;
  }
  &[data-cost-type="total"] {
    font: 700 18px/1.5 ${fonts.OpenSans};
    color: ${colors.green.regular};
  }
  &[data-value-type="negative"]::before {
    content: "-";
    left: -8px;
    position: absolute;
  }
  &[data-right-aligned="true"] {
    text-align: right;
  }
`;

let BillingReferencesWrapper = styled.div`
  margin-bottom: 40px;
`;

const OptionalNotificationText = styled.div`
  padding: 10px;
  margin: 10px 10px 0;
  line-height: 1.5;
  color: ${colors.gray.gray900};
  background: #fff9e4;
  border: 1px solid #e3d39b;
  border-radius: 5px;
  text-align: start;
`;

type LineItem = { lineItem: string; value: string; type?: string };

export type WPBuyModalProps = {
  additionalLineItems?: {
    price?: LineItem;
    credit?: LineItem & { isUnutilized: boolean; unutilizedMessage?: string };
  };
  productcategory?: string;
  onHide?: any;
  errorMessage?: string;
  apiStatus?: any;
  title: string;
  assignedDomain?: string;
  subtitle?: string | ReactElement;
  rightContent?: any;
  rightContentStyle?: Object;
  notificationText?: string | ReactElement;
  children: React.ReactNode;
  payButtonsInfo: {
    isPayButtonsDisabled: boolean;
    layoutInfo: any;
    startAndEndActionTypes: any;
    actionDetails: any;
    hideCurrentModal?: Function;
    hideCustomerBilling?: boolean;
    isPendingPayment?: boolean;
    transid?: string;
  };
  subTotal: number;
  customerPrice?: number;
  renewTaxInfo?: number;
  goBackToPrevState?: Function;
  rightAlignCost?: boolean;
  productkey?: string;
};

type TotalCostBreakdownProps = {
  subTotal: number | string;
  totalTax: number | string;
  total: number | string;
};

const totalCostDetails: TotalCostBreakdownProps = {
  subTotal: "Sub Total",
  totalTax: "Tax",
  total: "Total Cost"
};

export const WPBuyFlow: React.FC<WPBuyModalProps> = ({
  onHide,
  additionalLineItems = {},
  apiStatus,
  title,
  assignedDomain,
  subtitle,
  errorMessage,
  children,
  productcategory,
  subTotal = 0,
  payButtonsInfo,
  rightContent,
  rightContentStyle = {},
  goBackToPrevState,
  renewTaxInfo,
  rightAlignCost = false,
  productkey,
  customerPrice = 0,
  notificationText = ""
}) => {
  const { currencySymbol, taxinfo } = useWalletInfo();
  const upSellInfo = { productcategory, taxinfo, subTotal };
  let applicableTax = getTaxInfo({ productcategory }, taxinfo) ?? 0;
  let totalTax =
    renewTaxInfo ?? parseFloat(getTaxAmount(subTotal, applicableTax)) ?? 0;
  let total = Number(subTotal + totalTax).toFixed(2);
  const { t } = useTranslation();
  let totalCostBreakdown: TotalCostBreakdownProps = {
    subTotal,
    totalTax,
    total
  };
  return (
    <WPBuyModalContainer>
      <WPBuyGridMainContent>
        <WPBuyModalCart>
          <WPBuyModalCartIcon>
            <Cart />
          </WPBuyModalCartIcon>
          <WPBuyModalCartTitleWrapper>
            <WPBuyModalCartTitle>{title}</WPBuyModalCartTitle>
            {assignedDomain && (
              <WPBuyModalFor>
                {t("ODR_DNS_FOR", "For")}: {assignedDomain}
              </WPBuyModalFor>
            )}
            {subtitle && <WPBuyModalFor>{subtitle}</WPBuyModalFor>}
            {!!notificationText ? (
              <OptionalNotificationText>
                {notificationText}
              </OptionalNotificationText>
            ) : null}
          </WPBuyModalCartTitleWrapper>
          {apiStatus?.errors ? (
            <ModalError
              style={{
                width: "95%",
                position: "relative",
                margin: "20px auto 0"
              }}
            >
              {errorMessage ?? apiStatus?.errors?.message}
            </ModalError>
          ) : null}
          <WPModalContentBody>{children}</WPModalContentBody>
          <WPBuyModalCostWrapper data-right-aligned={rightAlignCost}>
            {additionalLineItems?.price && (
              <WPBuyModalCostGrid data-cost-type="totalTax">
                <WPBuyModalCostTitle data-cost-type="totalTax">
                  {additionalLineItems.price.lineItem}
                </WPBuyModalCostTitle>
                <WPBuyModalCost
                  data-cost-type="totalTax"
                  data-value-type={additionalLineItems.price.type}
                  data-right-aligned={rightAlignCost}
                >
                  {additionalLineItems.price.value}
                </WPBuyModalCost>
              </WPBuyModalCostGrid>
            )}
            {additionalLineItems?.credit && (
              <>
                <WPBuyModalCostGrid data-cost-type="totalTax">
                  <WPBuyModalCostTitle data-cost-type="totalTax">
                    {additionalLineItems.credit.lineItem}
                  </WPBuyModalCostTitle>
                  <WPBuyModalCost
                    data-cost-type="totalTax"
                    data-value-type={additionalLineItems.credit.type}
                    data-right-aligned={rightAlignCost}
                  >
                    {additionalLineItems.credit.value}
                  </WPBuyModalCost>
                </WPBuyModalCostGrid>
                {additionalLineItems.credit.isUnutilized && (
                  <p
                    className={css`
                      color: ${colors.red.regular};
                      border-top: 1px solid ${colors.red.regular};
                      margin: 0;
                      padding: 8px 0 10px;
                      position: relative;
                      max-width: 500px;
                      &::after {
                        position: absolute;
                        top: -5px;
                        left: 58%;
                        width: 8px;
                        height: 8px;
                        content: "";
                        background-color: #f6f6f8;
                        transform: rotate(45deg);
                        border-left: 1px solid ${colors.red.regular};
                        border-top: 1px solid ${colors.red.regular};
                      }
                    `}
                  >
                    {additionalLineItems.credit.unutilizedMessage}
                  </p>
                )}
              </>
            )}
            {Object.entries(totalCostBreakdown).map(([type, value]) => (
              <WPBuyModalCostGrid data-cost-type={type} key={type}>
                <WPBuyModalCostTitle data-cost-type={type}>
                  {type === "totalTax" && !renewTaxInfo && `${applicableTax}% `}
                  {totalCostDetails[type]}
                </WPBuyModalCostTitle>
                <WPBuyModalCost
                  data-cost-type={type}
                  data-right-aligned={rightAlignCost}
                >
                  {formatAmountWithCurrency({
                    cost: Number(value),
                    currency: currencySymbol,
                    currencyDisplay: "code"
                  })}
                </WPBuyModalCost>
              </WPBuyModalCostGrid>
            ))}
          </WPBuyModalCostWrapper>
        </WPBuyModalCart>
        <BillingReferencesWrapper>
          <BillingPreferencesButton
            isFetching={apiStatus?.isFetching}
            upSellInfo={upSellInfo}
            total={total}
            assignedDomain={assignedDomain}
            title={title}
            currencySymbol={currencySymbol}
            customerPrice={customerPrice}
            productkey={productkey}
            payButtonsInfo={payButtonsInfo}
            goBackToPrevState={goBackToPrevState}
            hideCurrentModal={() => onHide(true)}
          />
        </BillingReferencesWrapper>
      </WPBuyGridMainContent>
      {rightContent && (
        <WPBuyGridRightContent style={rightContentStyle}>
          {rightContent}
        </WPBuyGridRightContent>
      )}
    </WPBuyModalContainer>
  );
};

const WPBuyModal: React.FC<WPBuyModalProps & {
  show?: boolean;
  hide?: any;
  onHide?: any;
  alignCenter?: boolean;
  secureModal?: boolean;
  width?: number;
}> = ({ alignCenter, show, width, secureModal, children, ...props }) => {
  let onDismiss = props.hide || props.onHide;
  return (
    <DialogOverlay
      className={DialogOverlayStyle}
      onDismiss={() => onDismiss(false)}
      isOpen={show}
      style={{
        alignItems: alignCenter && "center"
      }}
    >
      <DialogContent
        className={cx(DialogContentStyle, secureModal && SecureModal)}
        style={{
          width: width ? `${width}px` : "auto"
        }}
      >
        <WPModalClose onClick={() => onDismiss(false)}>
          <CloseModal />
        </WPModalClose>
        <WPBuyFlow {...props} onHide={onDismiss}>
          {children}
        </WPBuyFlow>
      </DialogContent>
    </DialogOverlay>
  );
};

export default WPBuyModal;
