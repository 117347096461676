const validations = {
  areNameServersValid: nameServers => {
    if (!nameServers.some((ns = "") => ns.trim().length > 0)) {
      throw new Error("There should be at least 1 Name Server.");
    }
  },
  normalizeRecordType: type => {
    switch (type) {
      case "A":
        return "ipv4";
      case "AAAA":
        return "ipv6";
      default:
        return type.toLowerCase();
    }
  }
};

export default validations;
