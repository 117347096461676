import api from "api";
import constants from "constants/endpoints";
export const services = {
  cancelIrtpContactChanges: orderId =>
    api.post(`${constants.domain}irtp/verification/cancel`, {
      "order-id": orderId,
      reason: "IRTP action cancelled"
    }),
  cancelPrivacyAction: (orderid, eaqid) =>
    api.post(`${constants.zuulOrderBox}actions/cancel-action`, {
      eaqid,
      "order-id": orderid
    }),
  isActionCancellable: (orderid, eaqid) =>
    api.get(`${constants.zuulOrderBox}actions/is-cancellable`, {
      eaqid,
      "order-id": orderid
    }),
  resendIRTPAuthorization: orderId =>
    api.post(`${constants.domain}irtp/verification/resend`, {
      "order-id": orderId
    }),
  resendDomainContactVerificationEmail: orderId =>
    api.post(`${constants.domain}raa/resend-verification`, {
      "order-id": orderId
    }),
  getIRTPStatus: ({ orderid, domainname }) =>
    api.get(`${constants.domain}irtp/verification/details`, {
      "order-id": orderid,
      "domain-name": domainname
    }),
  resendDisableRequest: ({ orderid }) =>
    api.post(`${constants.domain}gdpr/resend-authorization-mail`, {
      "order-id": orderid
    }),
  cancelDisableRequest: ({ orderid }) =>
    api.post(`${constants.domain}gdpr/disable/cancel-action`, {
      "order-id": orderid
    }),
  updateAuthCode: (updatedAuthCode, orderid) =>
    api.post(
      `${constants.domain_transfer_in}/submit-auth-code`,
      { "order-id": orderid },
      new URLSearchParams({ "auth-code": updatedAuthCode })
    )
};
