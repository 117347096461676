import storage from "@faizaanceg/pandora";
import { getOBLogoutUrl, parseCookies } from "common/urls";
import {
  DefaultCredentialsManager,
  SessionManager,
  Session
} from "common/credentials";
import jwtDecode from "jwt-decode";

export class OrderBoxSessionManager extends SessionManager<Session> {
  async create(refreshNeeded = false) {
    const session = await super.create(refreshNeeded);
    if (session !== null) {
      let details = jwtDecode<{
        preferred_username: string;
        userId: string;
      }>(session.accessToken);
      let [role, parentId] = details.preferred_username.split("_");
      let userId = details.userId;
      session.details = { role, parentId, userId };
      this.activeSession = session;
    }
    return this.activeSession;
  }
}

export class OLCredentialsManager extends DefaultCredentialsManager {
  async attach(init: RequestInit, _url: string) {
    init.credentials = "include";
    const { csrf } = parseCookies(document.cookie);
    init.headers = new Headers(init.headers ?? {});
    if (csrf && init.method !== "GET") {
      init.headers.set("X-CSRF-TOKEN", csrf);
    }
    return init;
  }
  async applyRefresh() {
    let isComplete = await super.applyRefresh();
    if (!isComplete) {
      this.onFailure();
      return false;
    }
    return true;
  }
  async onFailure() {
    let disableAPISideEffects = storage.get("disableAPISideEffects", false);
    if (!disableAPISideEffects) {
      let sendUserTo = getOBLogoutUrl();
      storage.clear();
      if (
        window.dataLayer[window.dataLayer.length - 1].event !==
        "Session Expired"
      ) {
        window.dataLayer.push({
          event: "Session Expired",
          attributes: {
            message: "Session has Expired"
          }
        });
      }
      window.location.replace(sendUserTo);
    }
  }
}
