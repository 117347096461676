import { getSimpleNameFromProductKey } from "constants/index";
import { getPlatformFromProductName, platform } from "constants/platform";
import { isEmpty } from "lodash";
export function isRenewalAllowed(renewalDetails) {
  switch (
    getSimpleNameFromProductKey(renewalDetails.orderDetails?.productkey)
  ) {
    case "rchosting":
      return isRCHostingRenewalAllowed(renewalDetails);

    default:
      return true;
  }
}

function isRCHostingRenewalAllowed(renewalDetails) {
  let { productkey } = renewalDetails.orderDetails;
  if (getPlatformFromProductName(productkey) === platform.WINDOWS) {
    return true;
  }
  let { blocks } = renewalDetails.addonDetails.addons;
  if (isEmpty(blocks)) {
    return true;
  }
  return (
    blocks?.blocksNeeded <= 0 ||
    (blocks?.blocksNeeded > 0 && blocks?.param >= blocks?.blocksNeeded)
  );
}
