import { retryRenewalModalSaga } from "common/auto-renewal-retry/sagas";
import { customerCreateSagas } from "common/customer-create/sagas";
import { customerManagementSagas } from "common/customer-management/sagas";
import { legalSagas } from "common/legal-agreements/sagas";
import { logoSagas } from "common/logo/sagas";
import { orderManagementSagas } from "common/order-management/sagas";
import { ordersSagas } from "common/orders/sagas";
import { payButtonsSagas } from "common/pay-buttons/sagas";
import { detailsSagas } from "common/profile-details/sagas";
import { rootSagas } from "common/session-management/sagas";
import { sideBarSagas } from "common/side-bar/sagas";
import { walletSagas } from "common/wallet/sagas";
import { addFundsSagas } from "modals/add-funds/sagas";
import notificationSagas from "notifications/sagas";
import { all } from "redux-saga/effects";
import { productSagas } from "widget-models/sagas";
import { widgetSagas } from "widgets/sagas";

let sagasToListenTo = [
  ...legalSagas,
  ...ordersSagas,
  ...orderManagementSagas,
  ...productSagas,
  ...rootSagas,
  ...widgetSagas,
  ...notificationSagas,
  ...detailsSagas,
  ...payButtonsSagas,
  ...walletSagas,
  ...retryRenewalModalSaga,
  ...addFundsSagas
];

if (process.env.FEATURE_HAS_CUSTOMERS) {
  sagasToListenTo = [
    ...sagasToListenTo,
    ...customerCreateSagas,
    ...customerManagementSagas
  ];
}

if (process.env.FEATURE_ENDPOINT === "reseller") {
  sagasToListenTo = [...sagasToListenTo, ...logoSagas];
}

if (process.env.FEATURE_SIDEBAR_ORDERS === "conditional-business") {
  sagasToListenTo = [...sagasToListenTo, ...sideBarSagas];
}

export default function* mainSaga() {
  yield all(sagasToListenTo);
}
