import React from "react";
import { Tab, TabContent, Tabs } from "common/tab-switcher";
import storage from "@faizaanceg/pandora";
import AddCardIFrame from "../add-card-iframe";
import { CreateBillingAgreementForm } from "../billing-agreement-form";
import { Card } from "assets/icons/cards";
import { PayPal } from "assets";

export class PaymentTabs extends React.Component {
  render() {
    let { paypw, paypal } = this.props;
    let initialTab = null;
    if (!paypw.isAllowed && !paypal.isAllowed) {
      return null;
    }
    if (paypal.isAllowed) {
      initialTab = "PAYPAL";
    }
    if (paypw.isAllowed) {
      initialTab = "CC";
    }
    return (
      <Tabs initialValue={initialTab}>
        <div className="add-card-auto-renewal-tab">
          {paypw.isAllowed && (
            <Tab>
              {setTab => (
                <div className="del-reasons">
                  <input
                    type="radio"
                    name="method"
                    defaultChecked={initialTab === "CC"}
                    onChange={_ => setTab("CC")}
                    className="del-radio-ip"
                    id="creditcard"
                  />
                  <label htmlFor="creditcard" className="del-labels">
                    <span className="del-radio-btn" />
                    <img alt="card-type" src={Card} className="card-icon" />
                    <div className="add-card-label">
                      Add your Credit/Debit card
                      <br />
                      {/* TODO: Get info about text here */}
                      <span>(Visa/MasterCard)</span>
                    </div>
                  </label>
                </div>
              )}
            </Tab>
          )}
          {paypal.isAllowed && paypw.isAllowed && (
            <span className="add-card-or">OR</span>
          )}
          {paypal.isAllowed && (
            <Tab>
              {setTab => (
                <div className="del-reasons">
                  <input
                    type="radio"
                    name="method"
                    defaultChecked={initialTab === "PAYPAL"}
                    onChange={_ => setTab("PAYPAL")}
                    className="del-radio-ip"
                    id="paypal"
                  />
                  <label htmlFor="paypal" className="del-labels">
                    <span className="del-radio-btn" />
                    <img alt="card-type" src={PayPal} className="card-icon" />
                    <div className="add-card-label">Your PayPal account</div>
                  </label>
                </div>
              )}
            </Tab>
          )}
        </div>
        <TabContent activeWhen="CC">
          <div className="add-card-iframe">
            <AddCardIFrame
              onSuccess={query => {
                this.props.addCardSuccess(query);
                if (query.get("status") === "AuthSuccessful") {
                  Object.keys(this.props.orderMap).forEach(_ => {
                    this.props.enableAutoRenewal(_);
                  });
                }
                storage.remove("deferred-orders");
              }}
            />
          </div>
        </TabContent>
        <TabContent activeWhen="PAYPAL">
          <div className="text-align-center">
            <CreateBillingAgreementForm
              gatewayType="paypalec"
              onSubmit={_ => {
                storage.set("deferred-orders", this.props.orderMap);
              }}
            >
              <button type="submit" className="add-card-payment-submit">
                Login to PayPal or create a new one
              </button>
            </CreateBillingAgreementForm>
          </div>
        </TabContent>
      </Tabs>
    );
  }
}
