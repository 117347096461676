import { call, takeEvery, put } from "redux-saga/effects";
import { services } from "./services";
import { actions, actionTypes } from "./ducks";
import { mapEffect } from "map-effect";

const widgetDomainForwardingSagas = [
  takeEvery(
    actionTypes.SAVE_DOMAIN_FORWARDING_DETAILS,
    onSaveDomainForwardingDetails
  ),
  takeEvery(
    actionTypes.DELETE_DOMAIN_FORWARDING,
    mapEffect(deleteDomainForwarding)
  )
];

function* onSaveDomainForwardingDetails(action) {
  try {
    const {
      forwardToUrl,
      urlMasking: {
        isEnabled: isUrlMaskingEnabled,
        headerTags: { isShow: isShowHeaderTags, data: headerTagsValue },
        noFramesPageContent: {
          isShow: isShowNoFramesPageContent,
          data: noFramesPageContentValue
        }
      },
      isSubDomainForwardingEnabled,
      isPathForwardingEnabled
    } = action.payload;

    if (action.activate) {
      yield call(onActivateDomainForwarding, action.orderid);
    }

    const response = yield services.saveDomainForwardingDetails(
      {
        forward_to: forwardToUrl,
        url_masking: isUrlMaskingEnabled,
        meta_tags:
          isUrlMaskingEnabled && isShowHeaderTags ? headerTagsValue : null,
        no_frames:
          isUrlMaskingEnabled && isShowNoFramesPageContent
            ? noFramesPageContentValue
            : null,
        sub_domain_forwarding: isSubDomainForwardingEnabled,
        path_forwarding: isPathForwardingEnabled
      },
      action.orderid
    );
    yield put(
      actions.saveDomainForwardingDetailsSuccess(action.orderid, response)
    );
  } catch (error) {
    yield put(actions.saveDomainForwardingDetailsFailure(error));
  }
}

function* onActivateDomainForwarding(orderid) {
  try {
    yield put(actions.activateDomainForwarding(orderid));
    let response = yield services.activateDomainForwarding(orderid);
    yield put(actions.activateDomainForwardingSuccess(orderid));
    return response;
  } catch (error) {
    yield put(actions.activateDomainForwardingFailure(error));
  }
}

function deleteDomainForwarding(action) {
  return services.deleteDomainForwarding(action.domainname);
}

export default widgetDomainForwardingSagas;
