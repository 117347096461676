import { mapEffect } from "map-effect";
import history from "common/history";
import { takeLatest } from "redux-saga/effects";
import { actionTypes } from "./ducks";
import { services } from "./services";

export default [takeLatest(actionTypes.CHANGE_DOMAIN, mapEffect(changeDomain))];

export function changeDomain(action) {
  return services.changeDomain(action.payload);
}

function redirectUser(action) {
  const { domainname, productkey = "all" } = action.payload;
  history.push(`/orders/manage/${domainname}/${productkey}`);
}
changeDomain.onSuccess = redirectUser;
