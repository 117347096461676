import { isSuccess, isFailure } from "common/api-status/utils";

export const actionTypes = {
  SHOW_MANAGE_MODAL: "[Manage Backup] Show Manage modal",
  HIDE_MANAGE_MODAL: "[Manage Backup] Hide Manage Modal",
  SHOW_ADD_STORAGE_MODAL: "[Manage Backup] Show Add Storage Modal",
  SHOW_RENEW_MODAL: "[Manage Backup] Show Renew Modal",
  SEARCH_BACKUP_ORDER: "[Manage Backup] Searching Backup Order",
  SHOW_BUY_BACKUP_INFO_MODAL: "[Manage Backup] Show Buy Backup Info modal",
  SHOW_BUY_BACKUP_MODAL: "[Manage Backup] Show Buy Backup modal",
  GET_BACKUP_PRICING: "[Manage Backup] Get Backup Pricing",
  BUY_BACKUP: "[Manage Backup] Buy",
  SHOW_USE_EXISTING_BACKUP_MODAL: "[Manage Backup] Show Use Here modal",
  SHOW_INSTALLING_AGENT_MODAL: "[Manage Backup] Show Installing Agent modal",
  SHOW_REINSTALL_AGENT_MODAL: "[Manage Backup] Show Reinstall Agent modal",
  INSTALL_AGENT: "[Manage Backup] Use existing backup (install agent)",
  REINSTALL_BACKUP_AGENT: "[Manage Backup] Reinstall Backup Agent",
  RENEW_BACKUP: "[Manage Backup] Renew Backup"
};

export const initialState = {
  manageBackup: {
    isBackupProductAvailable: false,
    isBackupOrderFound: false,
    hostingOrderMappingState: false,
    planToAdd: null,
    pricing: {},
    planDetails: {},
    searchResponse: {},
    details: {}
  }
};
export const actions = {
  searchBackupOrder: ({ meta, hierarchy }) => ({
    type: actionTypes.SEARCH_BACKUP_ORDER,
    payload: { ...hierarchy, parent: meta.orderid, ...meta }
  }),
  onSearchBackupOrderSuccess: payload => ({
    type: isSuccess(actionTypes.SEARCH_BACKUP_ORDER),
    payload
  }),
  onSearchBackupOrderFailure: payload => ({
    type: isFailure(actionTypes.SEARCH_BACKUP_ORDER),
    payload
  }),
  openBuyBackupInfo: ({ meta }) => ({
    type: actionTypes.SHOW_BUY_BACKUP_INFO_MODAL,
    ...meta
  }),
  openExistingBackup: ({ meta }) => ({
    type: actionTypes.SHOW_USE_EXISTING_BACKUP_MODAL,
    ...meta
  }),
  openInstallingBackupAgent: ({ meta }) => ({
    type: actionTypes.SHOW_INSTALLING_AGENT_MODAL,
    ...meta
  }),
  openReinstallBackupAgent: ({ meta }) => ({
    type: actionTypes.SHOW_REINSTALL_AGENT_MODAL,
    ...meta
  }),
  getBackupPricingDetails: ({ meta, hierarchy }) => ({
    type: actionTypes.GET_BACKUP_PRICING,
    payload: { ...hierarchy, parent: meta.orderid, ...meta }
  }),
  getBackupPricingSuccess: payload => ({
    type: isSuccess(actionTypes.GET_BACKUP_PRICING),
    payload
  }),
  getBackupPricingFailure: payload => ({
    type: isFailure(actionTypes.GET_BACKUP_PRICING),
    payload
  })
};

export default (state, action) => {
  switch (action.type) {
    case isSuccess(actionTypes.SEARCH_BACKUP_ORDER): {
      const {
        payload: { parent, child } = {},
        details,
        searchResponse,
        isBackupOrderFound,
        hostingOrderMappingState
      } = action.effectResponse;
      return {
        ...state,
        [parent]: {
          ...state[parent],
          [child]: {
            ...state[parent][child],
            details,
            searchResponse,
            isBackupOrderFound,
            hostingOrderMappingState
          }
        }
      };
    }
    case isSuccess(actionTypes.GET_BACKUP_PRICING): {
      let { child, parent, productKey } = action.payload;
      let isBackupProductAvailable = action.effectResponse.planDetails?.miscellaneous?.supportedProductKeys?.includes(
        productKey
      );
      return {
        ...state,
        [parent]: {
          ...state[parent],
          [child]: {
            ...state[parent][child],
            ...action.effectResponse,
            isBackupProductAvailable
          }
        }
      };
    }
    default:
      return state;
  }
};
