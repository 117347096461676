import { sortBy } from "lodash";
import { delay } from "redux-saga";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { actions, actionTypes } from "./ducks";
import { services } from "./services";
import validations from "./validations";

export default [
  takeEvery(actionTypes.GET_DNS_RECORDS, onGetDNSRecords),
  takeEvery(actionTypes.EDIT_DNS_RECORD, onEditDNSRecord),
  takeEvery(actionTypes.DELETE_DNS_RECORD, onDeleteDNSRecord),
  takeEvery(actionTypes.ADD_NEW_RECORD, onAddDNSRecord)
];

function* onGetDNSRecords(action) {
  try {
    const fetchCalls = action.recordTypes.map(type =>
      call(services.getDNSRecords, {
        type,
        domainName: action.domainName,
        pageNo: 1,
        noOfRecords: 50
      })
    );
    let responses = yield all(fetchCalls);
    responses = [].concat
      .apply(
        [],
        responses.map(({ recsindb, recsonpage, ...response }) =>
          Object.values(response)
        )
      )
      .reverse();
    const existingRecords = yield select(
      state => state.widgets.domain.nameServers.allDnsRecords
    );
    const otherRecords = existingRecords.filter(
      dnsRecord => !action.recordTypes.includes(dnsRecord.type)
    );
    let allDnsRecords = [...responses, ...otherRecords];
    allDnsRecords = sortBy(allDnsRecords, "type");
    let CNAMEHosts = allDnsRecords
      .filter(dnsRecord => dnsRecord.type === "CNAME")
      .map(dnsRecord => dnsRecord.host);
    allDnsRecords = allDnsRecords.map(dnsRecord => ({
      ...dnsRecord,
      disabled: dnsRecord.type !== "CNAME" && dnsRecord.host in CNAMEHosts
    }));
    if (action.newRecord !== {}) {
      allDnsRecords
        .filter(
          dnsRecord =>
            dnsRecord.host === action.newRecord.host &&
            dnsRecord.value === action.newRecord.value
        )
        .forEach(dnsRecord => (dnsRecord.newRecordFlag = true));
    }
    let payload = {
      allDnsRecords
    };
    yield put(actions.getDNSSuccess(payload));
    yield delay(4000);
    allDnsRecords.forEach(dnsRecord => (dnsRecord.newRecordFlag = false));
    payload = { allDnsRecords };
    yield put(actions.getDNSSuccess(payload));
  } catch (error) {
    yield put(actions.getDNSFailure(error));
  }
}

function* onEditDNSRecord(action) {
  try {
    let response = "";
    response = yield services.editRecord(
      action.domainName,
      validations.normalizeRecordType(action.recordType),
      action.params
    );
    // TODO: Can be removed once we use same api client for ccp as well
    if (response.status === "Failed") {
      throw new Error(response.msg);
    }
    const payload = {
      editDnsRecords: response,
      type: action.recordType
    };
    yield put(actions.editDNSRecordSuccess(payload));
    action.manageEditSuccess();
  } catch (error) {
    yield put(
      actions.editDNSRecordFailure(
        error,
        action.params.host,
        action.params["current-value"],
        action.recordType
      )
    );
    action.manageEditFailure();
    action.resetForm();
  }
}

function* onDeleteDNSRecord(action) {
  try {
    let response = "";
    response = yield services.deleteRecord(
      action.domainName,
      validations.normalizeRecordType(action.recordType),
      action.params
    );
    const payload = {
      deleteDnsRecords: response,
      type: action.recordType
    };
    yield put(actions.deleteDNSRecordSuccess(payload));
    yield put(actions.getDNS(action.domainName, [action.recordType]));
  } catch (error) {
    yield put(
      actions.deleteDNSRecordFailure(
        error,
        action.params.host,
        action.params.value,
        action.recordType
      )
    );
    action.resetForm();
  }
}

function* onAddDNSRecord(action) {
  try {
    let response = yield services.addRecord(
      action.domainName,
      validations.normalizeRecordType(action.recordType),
      action.params
    );
    const payload = {
      addDNSRecord: response,
      type: action.recordType
    };
    yield put(actions.addDNSRecordSuccess(payload));
    yield put(
      actions.getDNS(action.domainName, [action.recordType], action.params)
    );
    action.resetForm();
  } catch (error) {
    yield put(actions.addDNSRecordFailure(error, action.recordType));
    action.resetForm();
  }
}
