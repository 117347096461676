import EmailDiscountTooltip from "common/discount-view/tooltip";
import { FormFieldWithError } from "common/inline-error-field";
import { getPricingStrategy } from "common/pricing-strategy/factory";
import { colors, fonts } from "common/styles";
import WPTooltip from "common/tooltip";
import {
  calculateTenureFloor,
  createErrorMap,
  formatAmountWithCurrency,
  getDifferenceInMonths,
  getPrivacyProtectionAddonPrice,
  getProrataCost
} from "common/util";
import { withUserPayments } from "common/with-user-payments";
import { getSimpleNameFromProductKey } from "constants/index";
import { Field, Form, Formik } from "formik";
import { css } from "linaria";
import React from "react";
import { ErrorMessage, Message } from "./addons/messages";

class AddonsComponent extends React.Component {
  state = {
    showNumberInput: {},
    numberInput: {},
    checkboxInput: {}
  };

  componentDidMount() {
    const { addonDetails: { addons = {} } = {}, orderid } = this.props;
    Object.keys(addons)
      .filter(addonName => addons[addonName].param !== undefined)
      .forEach(addonName => {
        this.updateParentComponent(
          addons[addonName],
          orderid,
          addonName,
          addons[addonName].inputType,
          addons[addonName].param
        );
      });
  }

  componentDidUpdate(prevProps) {
    // since addon total price logic is in the addon component
    // The child should update the parent whenever there's a change in price
    // Change in price could occur due the following two reasons
    // 1.) Tenure change
    // 2.) Select/De-select/change input in the addoncomponent itself
    if (prevProps.selectedTenure != this.props.selectedTenure) {
      const { addonDetails: { addons = {} } = {}, orderid } = this.props;
      Object.keys(addons)
        .filter(addonName => !!addons[addonName].inputType)
        .forEach(addonName => {
          this.updateParentComponent(
            addons[addonName],
            orderid,
            addonName,
            addons[addonName].inputType,
            addons[addonName].param ?? this.state.checkboxInput[orderid]
          );
        });
    }
  }

  toggleNumberInput = (orderid, addonDetails, addonName) => {
    const nextToggleState =
      this.state.showNumberInput[orderid] === undefined
        ? true
        : !this.state.showNumberInput[orderid];
    // default value for number input is 1
    const nextNumberInput = nextToggleState ? 1 : 0;
    this.setState(
      {
        showNumberInput: {
          ...this.state.showNumberInput,
          [orderid]: nextToggleState
        },
        numberInput: {
          ...this.state.numberInput,
          [orderid]: nextNumberInput
        }
      },
      () =>
        this.updateParentComponent(addonDetails, orderid, addonName, "number")
    );
  };

  updateParentComponent = (
    addonDetails,
    orderid,
    addonName,
    inputType,
    inputValue
  ) => {
    const {
      addonDetails: { addons = {} } = {},
      productkey,
      selectedTenure,
      expirytimestamp
    } = this.props;
    let {
      addonPrice,
      customerAddonPrice,
      doNotConsiderBasePrice = false
    } = this.calculatePriceOfSelectedAddon(
      addonDetails,
      expirytimestamp,
      addons[addonName].pricingDetails,
      productkey,
      selectedTenure,
      this.state.numberInput[orderid] ?? inputValue,
      this.state.checkboxInput[orderid],
      addons[addonName].customerPricingDetails
    );
    let param;
    switch (inputType) {
      case "number": {
        param = this.state.numberInput[orderid] ?? inputValue;
        break;
      }
      case "checkbox": {
        param = this.state.checkboxInput[orderid] ?? addonDetails.param;
        // if checkbox is unchecked then return 0
        addonPrice = param ? addonPrice : 0;
        customerAddonPrice = param ? customerAddonPrice : 0;
        break;
      }
      case "addBlocks": {
        addonPrice = 0;
        customerAddonPrice = 0;
        param = -1;
        break;
      }
      default:
        param = "";
    }

    this.props.onAddonsSelected(
      orderid,
      addonName,
      addonPrice,
      param,
      doNotConsiderBasePrice,
      customerAddonPrice
    );
  };

  onChangeNumberInput = (addonDetails, orderid, addonName, e) => {
    this.setState(
      {
        numberInput: {
          ...this.state.numberInput,
          [orderid]: e.target.value ? Number(e.target.value) : 0
        }
      },
      this.updateParentComponent.bind(
        this,
        addonDetails,
        orderid,
        addonName,
        "number"
      )
    );
  };

  onChangeCheckbox = (addonDetails, orderid, addonName, e) =>
    this.setState(
      {
        checkboxInput: {
          ...this.state.checkboxInput,
          [orderid]: e.target.checked
        }
      },
      this.updateParentComponent.bind(
        this,
        addonDetails,
        orderid,
        addonName,
        "checkbox",
        e.target.checked
      )
    );

  /**
   *
   * @param {*} addonDetails
   * @param {*} expirytimestamp
   * @param {*} pricingDetails
   * @param {*} productkey
   * @param {string} selectedTenure
   * @param {*} numberInput
   * @param {*} checkboxInput
   * @param {*} customerPricingDetails
   * @returns
   */
  calculatePriceOfSelectedAddon = (
    addonDetails,
    expirytimestamp,
    pricingDetails = {},
    productkey,
    selectedTenure,
    numberInput = 0,
    checkboxInput,
    customerPricingDetails = {}
  ) => {
    // TODO : check if there are other locations for email
    switch (getSimpleNameFromProductKey(productkey)) {
      case "businessemail":
      case "enterpriseemail": {
        if (Number(numberInput) === 0) {
          return 0;
        }
        // the folllowing calculation is for time before expiry
        const { existingEmailAccounts } = addonDetails;
        const totalNumberOfAccounts = existingEmailAccounts + numberInput;
        const emailStrategy = getPricingStrategy(productkey);
        const priceOfSingleAccountTillExpiry = emailStrategy.getAccountPrice(
          pricingDetails,
          "add",
          {
            expiryTimestamp: expirytimestamp,
            numberOfAccounts: totalNumberOfAccounts
          }
        );

        const priceOfSingleAccountPostExpiry = emailStrategy.getAccountPrice(
          pricingDetails,
          "renew",
          { selectedTenure, numberOfAccounts: totalNumberOfAccounts }
        );
        const proratedCost = getProrataCost(
          priceOfSingleAccountTillExpiry,
          expirytimestamp
        );
        //customer email addOn
        const customerPriceOfSingleAccountTillExpiry = emailStrategy.getAccountPrice(
          customerPricingDetails,
          "add",
          {
            expiryTimestamp: expirytimestamp,
            numberOfAccounts: totalNumberOfAccounts
          }
        );

        const customerPriceOfSingleAccountPostExpiry = emailStrategy.getAccountPrice(
          customerPricingDetails,
          "renew",
          { selectedTenure, numberOfAccounts: totalNumberOfAccounts }
        );
        const customerProratedCost = getProrataCost(
          customerPriceOfSingleAccountTillExpiry,
          expirytimestamp
        );
        // When additional email accounts are added we don't have to consider the base price that we get from renew price api call
        // Since we add the renew cost of all account here!
        return {
          addonPrice:
            proratedCost * numberInput +
            priceOfSingleAccountPostExpiry *
              totalNumberOfAccounts *
              selectedTenure,
          doNotConsiderBasePrice: true,
          customerAddonPrice:
            customerProratedCost * numberInput +
            customerPriceOfSingleAccountPostExpiry *
              totalNumberOfAccounts *
              selectedTenure
        };
      }
      case "titanmail": {
        const { existingEmailAccounts } = addonDetails;
        const totalNumberOfAccounts = Math.max(
          numberInput,
          existingEmailAccounts
        );
        const addOnAccounts = totalNumberOfAccounts - existingEmailAccounts;
        const emailStrategy = getPricingStrategy(productkey);
        const fullTenure =
          Number(selectedTenure) + getDifferenceInMonths(expirytimestamp);
        const userPrice = {
          add: emailStrategy.getAccountPrice(pricingDetails, "add", {
            selectedTenure: calculateTenureFloor(pricingDetails.add, fullTenure)
          }),
          renew: emailStrategy.getAccountPrice(pricingDetails, "renew", {
            selectedTenure
          })
        };
        const customerPrice = {
          add: emailStrategy.getAccountPrice(customerPricingDetails, "add", {
            selectedTenure: calculateTenureFloor(
              customerPricingDetails.add,
              fullTenure
            )
          }),
          renew: emailStrategy.getAccountPrice(
            customerPricingDetails,
            "renew",
            { selectedTenure }
          )
        };
        return {
          addonPrice:
            userPrice.add * addOnAccounts * fullTenure +
            userPrice.renew * existingEmailAccounts * selectedTenure,
          doNotConsiderBasePrice: true,
          customerAddonPrice:
            customerPrice.add * addOnAccounts * fullTenure +
            customerPrice.renew * existingEmailAccounts * selectedTenure
        };
      }
      case "domain": {
        return getPrivacyProtectionAddonPrice({
          addonDetails,
          pricingDetails,
          customerPricingDetails,
          expirytimestamp,
          selectedTenure
        });
      }
      case "mdh":
      case "sdh": {
        // if checkbox is unchecked return 0
        const { addonEnabled } = addonDetails;
        const proratedCost = +getProrataCost(pricingDetails, expirytimestamp);
        const customerProratedCost = +getProrataCost(
          customerPricingDetails,
          expirytimestamp
        );

        return addonEnabled
          ? {
              addonPrice: pricingDetails * selectedTenure,
              customerAddonPrice: customerPricingDetails * selectedTenure
            }
          : {
              addonPrice: pricingDetails * selectedTenure + proratedCost,
              customerAddonPrice:
                customerPricingDetails * selectedTenure + customerProratedCost
            };
      }
      case "bhvpshosting":
      case "cloudhosting":
      case "dedibybh":
      case "dedicatedserver":
      case "hgdedicatedserver":
      case "managedserver":
      case "vpshosting":
      case "wph":
      case "rchosting": {
        return {
          addonPrice: pricingDetails * selectedTenure,
          customerAddonPrice: customerPricingDetails * selectedTenure
        };
      }

      default: {
        return { addonPrice: 0, customerAddonPrice: 0 };
      }
    }
  };

  renderAddonCustomisation() {
    const {
      addonDetails: { addons = {} } = {},
      currencySymbol = "",
      orderid,
      productkey,
      selectedTenure,
      expirytimestamp,
      isPaymentsAllowedForUser
    } = this.props;
    return Object.keys(addons)
      .filter(addonName => !!addons[addonName].inputType)
      .map(addonName => {
        let { inputType } = addons[addonName];
        switch (inputType) {
          case "unmodifiable_checkbox":
            const { addonPrice, customerAddonPrice } = isPaymentsAllowedForUser
              ? this.calculatePriceOfSelectedAddon(
                  addons[addonName],
                  expirytimestamp,
                  addons[addonName].pricingDetails,
                  productkey,
                  selectedTenure,
                  this.state.numberInput[orderid],
                  undefined,
                  addons[addonName].customerPricingDetails
                )
              : {};
            return (
              <div
                className="conf-ren-checkbox default-checked"
                key={addonName}
                style={{ minWidth: "165px" }}
              >
                <label>
                  <input
                    checked
                    type="checkbox"
                    name="check"
                    className="custom-checkbox lfloat"
                    data-testid="renew-modal-checkbox"
                    readOnly
                  />
                  <span className="custom-checkbox-label lfloat"> </span>
                  <div className="auto-ren-txt-addons lfloat">
                    {addons[addonName].addonDisplayName}
                  </div>
                  {isPaymentsAllowedForUser && (
                    <div className="conf-ren-addon-price">
                      <div className="conf-price lfloat">
                        for{" "}
                        <span
                          className="notranslate"
                          style={{ fontWeight: "600" }}
                        >
                          {formatAmountWithCurrency({
                            cost: addonPrice,
                            currency: currencySymbol,
                            currencyDisplay: "code"
                          })}
                        </span>
                      </div>
                    </div>
                  )}
                </label>
              </div>
            );
          case "checkbox": {
            const { addonPrice, customerAddonPrice } = isPaymentsAllowedForUser
              ? this.calculatePriceOfSelectedAddon(
                  addons[addonName],
                  expirytimestamp,
                  addons[addonName].pricingDetails,
                  productkey,
                  selectedTenure,
                  this.state.numberInput[orderid],
                  undefined,
                  addons[addonName].customerPricingDetails
                )
              : {};
            return (
              <div className="conf-ren-checkbox" key={addonName}>
                <label>
                  <input
                    checked={addons[addonName].param}
                    type="checkbox"
                    name="check"
                    className="custom-checkbox lfloat"
                    data-testid="renew-modal-checkbox"
                    onChange={this.onChangeCheckbox.bind(
                      this,
                      addons[addonName],
                      orderid,
                      addonName
                    )}
                  />
                  <span className="custom-checkbox-label lfloat"> </span>
                  <div className="auto-ren-txt-addons lfloat">
                    {addons[addonName]?.tooltip ? (
                      <WPTooltip
                        position="bottom"
                        label={addons[addonName]?.tooltip}
                      >
                        {addons[addonName].addonDisplayName}
                      </WPTooltip>
                    ) : (
                      addons[addonName].addonDisplayName
                    )}
                  </div>
                  {isPaymentsAllowedForUser && (
                    <div className="conf-ren-addon-price">
                      <div className="conf-price lfloat">
                        for{" "}
                        <span className="notranslate">
                          {formatAmountWithCurrency({
                            cost: addonPrice,
                            currency: currencySymbol,
                            currencyDisplay: "code"
                          })}
                        </span>
                      </div>
                    </div>
                  )}
                </label>
              </div>
            );
          }
          case "number": {
            const {
              addClass: { input = "", container = "" }
            } = addons[addonName];
            return (
              <div className="conf-ren-checkbox-addons" key={addonName}>
                <label className="inline-text">
                  {
                    <Formik
                      initialValues={{
                        numberaddoninput: addons[addonName].minCount
                      }}
                      validate={createErrorMap({
                        numberaddoninput: {
                          isWrong: v =>
                            isNaN(v.numberaddoninput) ||
                            v.numberaddoninput < addons[addonName].minCount,
                          message: v =>
                            `Value should be atleast ${addons[addonName].minCount}`
                        }
                      })}
                      render={formikProps => (
                        <Form className={container}>
                          <Field
                            component={FormFieldWithError}
                            type={inputType}
                            min={addons[addonName].minCount}
                            className={`addonsrenewalinput ${input}`}
                            name="numberaddoninput"
                            onChange={e => {
                              formikProps.handleChange(e);
                              this.onChangeNumberInput(
                                addons[addonName],
                                orderid,
                                addonName,
                                e
                              );
                            }}
                          />
                        </Form>
                      )}
                    />
                  }
                  {addons[addonName].labelText && (
                    <span>{addons[addonName].labelText}</span>
                  )}
                </label>
              </div>
            );
          }
          case "tooltip": {
            let { costBreakdown } = addons[addonName];
            return (
              <EmailDiscountTooltip
                breakdown={getPricingStrategy(productkey).getDiscountBreakdown({
                  ...costBreakdown,
                  selectedTenure
                })}
                discount={costBreakdown.discount}
              >
                {addons[addonName].addonDisplayName}
              </EmailDiscountTooltip>
            );
          }
          case "errorMessage": {
            return (
              <ErrorMessage key={addonName}>
                {addons[addonName].message}
              </ErrorMessage>
            );
          }
          case "message": {
            return (
              <Message key={addonName}>{addons[addonName].message}</Message>
            );
          }
          case "addBlocks": {
            let { param, buttonValue, buttonText } = addons[addonName];
            if (param >= 0) {
              return null;
            }
            const { addonPrice } =
              isPaymentsAllowedForUser &&
              this.calculatePriceOfSelectedAddon(
                addons[addonName],
                expirytimestamp,
                addons[addonName].pricingDetails,
                productkey,
                selectedTenure
              );
            return (
              <button
                className={css`
                  background: transparent;
                  border: 0;
                  padding: 0;
                  outline: none;
                  color: ${colors.gray.gray900};
                  font: 400 12px/1 ${fonts.OpenSans};
                  margin-top: 5px;
                  text-decoration: underline;
                `}
                key={addonName}
                onClick={() =>
                  this.props.onAddonsSelected(
                    orderid,
                    addonName,
                    addonPrice * buttonValue,
                    buttonValue
                  )
                }
              >
                {buttonText}
              </button>
            );
          }
          default:
            return <div key={addonName} />;
        }
      });
  }

  render() {
    const { addonDetails = {} } = this.props;
    return (
      <>
        {addonDetails?.displayText?.trim() && (
          <div className="auto-ren-txt">{addonDetails.displayText}</div>
        )}
        {this.renderAddonCustomisation()}
      </>
    );
  }
}

export default withUserPayments(AddonsComponent);
