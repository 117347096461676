import { Syringe } from "@faizaanceg/syringe";
import api from "api";
import format from "string-format";

const SiteManager = Syringe.inject("siteManager");

export const services = {
  changeServerState: async ({ meta = {}, action = "" }) => {
    let { ip } = await SiteManager.clientDetails();
    return api.get(meta.endpoints.changeServerState, {
      action,
      "customer-ip": ip,
      "order-id": meta.orderid
    });
  },
  getVNCAccess: (meta = {}) =>
    api.get(format(meta.endpoints.getVNCAccess, meta))
};
