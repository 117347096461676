export const actionTypes = {
  SHOW_MODAL: "[Nameservers] Show modal"
};

export const initialState = { nameServers: { nameservers: [], dns: [] } };

export const parsers = {
  dnsParser: (dns = []) => ({ dns }),
  titanDNSParser: ({ dns_records = [] }) => ({ dns: dns_records }),
  nameserversParser: ({
    nameserver = [],
    ns_detail: nsDetails = nameserver
  }) => ({ nameservers: nsDetails }),
  nameserverAndDnsParser: ({ nameserver = "", dns = [] }) => ({
    nameservers: nameserver.split(","),
    dns
  })
};
