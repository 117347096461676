import React from "react";

export function Pro(props) {
  return (
    <svg {...props} width="21px" height="16px" viewBox="0 0 21 16">
      <g
        id="fill-area"
        transform="translate(-20.000000, -304.000000)"
        fillRule="evenodd"
      >
        <g transform="translate(20.000000, 304.000000)">
          <path d="M1,0 L17,0 C17.5522847,-1.01453063e-16 18,0.44771525 18,1 L18,12 C18,12.5522847 17.5522847,13 17,13 L1,13 C0.44771525,13 6.76353751e-17,12.5522847 0,12 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z M2.637,8.867 C2.637,8.937 2.693,9 2.77,9 L3.414,9 C3.484,9 3.547,8.937 3.547,8.867 L3.547,7.222 L4.422,7.222 C5.276,7.222 5.983,6.515 5.983,5.647 C5.983,4.8 5.276,4.1 4.415,4.1 L2.77,4.1 C2.693,4.1 2.637,4.163 2.637,4.233 L2.637,8.867 Z M3.547,6.368 L3.547,4.961 L4.359,4.961 C4.751,4.961 5.08,5.255 5.08,5.64 C5.08,6.053 4.751,6.368 4.359,6.368 L3.547,6.368 Z M6.781,8.867 C6.781,8.937 6.837,9 6.914,9 L7.558,9 C7.628,9 7.691,8.937 7.691,8.867 L7.691,7.082 L8.468,7.082 L9.399,8.937 C9.413,8.965 9.448,9 9.511,9 L10.246,9 C10.365,9 10.414,8.888 10.365,8.797 L9.406,7.019 C10.015,6.774 10.442,6.249 10.442,5.605 C10.442,4.772 9.756,4.1 8.916,4.1 L6.914,4.1 C6.837,4.1 6.781,4.163 6.781,4.233 L6.781,8.867 Z M7.698,6.333 L7.698,4.94 L8.839,4.94 C9.21,4.94 9.532,5.248 9.532,5.619 C9.532,6.011 9.21,6.333 8.839,6.333 L7.698,6.333 Z M11.093,6.557 C11.093,7.957 12.206,9.07 13.606,9.07 C15.006,9.07 16.126,7.957 16.126,6.557 C16.126,5.157 15.006,4.03 13.606,4.03 C12.206,4.03 11.093,5.157 11.093,6.557 Z M12.003,6.557 C12.003,5.675 12.724,4.94 13.606,4.94 C14.488,4.94 15.216,5.675 15.216,6.557 C15.216,7.439 14.488,8.16 13.606,8.16 C12.724,8.16 12.003,7.439 12.003,6.557 Z" />
          <rect x="19" y="2" width="1.5" height="13" rx="0.75" />
          <path d="M3.5,14 L20.5065703,14 L20.5065703,15 C20.5065703,15.2761424 20.2827127,15.5 20.0065703,15.5 L3.5,15.5 C3.22385763,15.5 3,15.2761424 3,15 L3,14.5 C3,14.2238576 3.22385763,14 3.5,14 Z" />
        </g>
      </g>
    </svg>
  );
}
