import {
  categories as AnalyticsCategories,
  actions as AnalyticsActions,
  labels as AnalyticsLabels
} from "analytics/constants";

export const actionTypes = {
  SHOW_MODAL: "[Change domain] Show",
  CHANGE_DOMAIN: "[Change domain] Update"
};

export const initialState = {
  changeDomain: {}
};

export const actions = {
  changeDomain: payload => ({
    type: actionTypes.CHANGE_DOMAIN,
    payload,
    track: {
      eventCategory: AnalyticsCategories.change_domain,
      eventAction: `${AnalyticsActions.change_domain} ${payload.productkey}`,
      eventLabel: AnalyticsLabels.change_domain
    }
  })
};
