import { CredentialsManager } from "http-with-fetch";

export type Session = {
  details: { userId: string; parentId: string; role: string };
  accessToken?: string;
};

type SessionUrl<T> = (refreshNeeded: boolean) => Promise<T>;

export class SessionManager<SessionType = Session> {
  sessionUrl: SessionUrl<SessionType>;
  activeSession: SessionType = null;
  constructor(sessionUrl: SessionUrl<SessionType>) {
    this.sessionUrl = sessionUrl;
  }
  markInvalid() {
    this.activeSession = null;
  }
  async create(refreshNeeded = false) {
    if (this.activeSession === null) {
      this.activeSession = await this.sessionUrl(refreshNeeded).catch(
        () => null
      );
    }
    return this.activeSession;
  }
}

export class DefaultCredentialsManager implements CredentialsManager {
  manager: SessionManager<Session>;
  constructor(manager: SessionManager<Session>) {
    this.manager = manager;
  }
  async attach(init: RequestInit, url: string) {
    init.credentials = "include";
    return init;
  }
  async isValid(_: Request, res: Response) {
    if (res.status === 401) {
      this.manager.markInvalid();
      return false;
    }
    return true;
  }
  async applyRefresh() {
    let session = await this.manager.create(true);
    return session !== null;
  }
}
