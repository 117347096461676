import api from "api";
import format from "string-format";

export const services = {
  deleteDedicatedIP: ({ endpoints, simpleName, addonName = "", orderid }) => {
    let body = {};
    if (simpleName === "rchosting") {
      if (addonName.length === 0) {
        return Promise.reject(new Error("Invalid value for IP"));
      }
      body = { ips: [addonName] };
    }
    return api.post(endpoints.disableSSL, { ...body, "order-id": orderid });
  },
  deleteAddonIP: ({ endpoints = {}, ...meta }) =>
    api.post(format(endpoints.deleteAddon, meta))
};
