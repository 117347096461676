import { call, put } from "redux-saga/effects";
import { isFailure, isSuccess } from "common/api-status/utils";

let removeMetadata = ({ track, type, ...action }) => action;

let Effect = { SUCCESS: 1, FAILURE: -1 };

export function* getEffect(effectProducer, action) {
  try {
    let effectResponse = yield call(effectProducer, action);
    return { effectType: Effect.SUCCESS, effectResponse };
  } catch (error) {
    return { effectType: Effect.FAILURE, error };
  }
}

export let mapEffect = (effectProducer, effectActionType) =>
  function* effectToSaga(action) {
    let { onSuccess, onFailure } = effectProducer;
    let actionToCopy = removeMetadata(action);
    let { effectType, ...effectInfo } = yield call(
      getEffect,
      effectProducer,
      action
    );
    let message;
    if (effectType === Effect.SUCCESS && onSuccess) {
      let _ = yield call(onSuccess, action, effectInfo.effectResponse);
      if (typeof _ === "string") {
        message = _;
      }
    }
    if (effectType === Effect.FAILURE && onFailure) {
      let _ = yield call(onFailure, action, effectInfo.error);
      if (typeof _ === "string") {
        message = _;
      }
    }
    let actionType = effectActionType || action.type;
    yield put({
      ...actionToCopy,
      ...effectInfo,
      ...(message && { defaultMessage: message }),
      type:
        effectType === Effect.SUCCESS
          ? isSuccess(actionType)
          : isFailure(actionType)
    });
  };

export let testEffect = effectProducer =>
  function* effectToSaga(action) {
    let { onSuccess, onFailure } = effectProducer;
    let { effectType, ...effectInfo } = yield call(
      getEffect,
      effectProducer,
      action
    );
    if (effectType === Effect.SUCCESS) {
      if (effectInfo.effectResponse) {
        yield put(effectInfo.effectResponse);
      }
      if (onSuccess) {
        yield call(onSuccess, action, effectInfo.effectResponse);
      }
    }
    if (effectType === Effect.FAILURE) {
      yield put(effectInfo.error);
      if (onFailure) {
        yield call(onFailure, action, effectInfo.error);
      }
    }
  };
