import ProductCategories from "common/product-categories";
import apiEndpoints from "./endpoints";

export { shortcuts } from "./shortcuts";

export const TextConstants = {
  dashboard_tab_1: "recently added",
  dashboard_tab_2: "to be renewed",
  order_management_tab_1: "ALL",
  order_management_tab_2: "EXPIRING IN 30 DAYS",
  order_management_tab_3: "EXPIRED",
  order_management_tab_4: "ORDER REQUESTS",
  order_management_auto_renew_tab: "SET TO AUTO RENEWAL",
  customer_dashboard: "you have x orders",
  customer_upgrade_dashboard: "you have recently added 10 orders",
  dedicated_ip_information: `
  A Dedicated IP can be used for a variety of functions, such as to add
  an SSL certificate to your website or to assign an IP to your
  Nameserver(s).`,
  email_accounts_information: "Email accounts information",
  view_email_accounts_description: `You can manage (edit / delete) email accounts which are currently in use from the Email Admin Panel.`,
  delete_email_accounts_text: `Deleting email accounts will NOT result in a refund.

  If you need to delete the email accounts you are currently using, you can do that from the Email Admin Panel.`,
  gsuite_delete_email_accounts_text: `Deleting email accounts will NOT result in a refund.

    If you need to delete the email accounts you are currently using, you can do that from the Email Admin Panel.`
};

export type SimpleName =
  | "all"
  | "dedibybh"
  | "managedserver"
  | "bhvpshosting"
  | "sdh"
  | "mdh"
  | "vpshosting"
  | "dedicatedserver"
  | "cloudhosting"
  | "procloud"
  | "enterpriseemail"
  | "businessemail"
  | "gsuite"
  | "sslcert"
  | "rchosting"
  | "wph"
  | "wpcloud"
  | "codeguard"
  | "websitebuilder"
  | "hgshosting"
  | "hgdedicatedserver"
  | "domain"
  | "sitelock"
  | "businesscloud"
  | "hosting"
  | "blockstorage"
  | "webzai"
  | "backup"
  | "backorder"
  | "titanmail";

export const simpleNames: SimpleName[] = [
  "domain",
  "titanmail",
  "sdh",
  "mdh",
  "vpshosting",
  "dedicatedserver",
  "cloudhosting",
  "procloud",
  "enterpriseemail",
  "businesscloud", // TODO: Can we replace this with cloudhosting?
  "businessemail",
  "gsuite",
  "sslcert",
  "rchosting",
  "hgdedicatedserver",
  "sitelock",
  "dedibybh", //TODO: change product key to bluehostdedicated / dedicatedbluehost
  "bhvpshosting",
  "codeguard",
  "wph",
  "wpcloud",
  "hgshosting",
  "managedserver",
  "hosting",
  "blockstorage",
  "webzai",
  "backup",
  "backorder"
];

export const immovableProducts = ["blockstorage", "backup", "backorder"];

export const OrderStatus = {
  DUMMY: "dummy",
  INACTIVE: "inactive",
  ACTIVE: "active",
  SUSPENDED: "suspended",
  PENDING_DELETE: "pendingdeleterestorable",
  DELETED: "deleted",
  ARCHIVED: "archived"
};

export const StorageStatus = {
  ATTACHED: "attached",
  AVAILABLE: "available"
};

export const BackupAgentInstallStatus = {
  ACTIVE: "active",
  FAILED: "failed",
  INSTALLATION_STUCK: "installation_stuck",
  NO_MAPPING_FOUND: "no_mapping_found",
  PENDING: "pending"
};

export const platform = {
  WINDOWS: "windows",
  LINUX: "linux"
};

export const MaxLimitAddons = {
  WORDPRESS_MAX_LIMIT_ADDONS: 8
};

export const getSimpleNameFromProductKey = (
  productKey: string = ""
): SimpleName => {
  if (productKey.length === 0 || productKey === "all") {
    return "all";
  }

  if (simpleNames.includes(productKey as SimpleName)) {
    return productKey as SimpleName;
  }

  if (productKey.startsWith("bluehostdedicated")) {
    return "dedibybh";
  }

  if (productKey.startsWith("managedserver")) {
    return "managedserver";
  }
  if (productKey.startsWith("bluehostvps")) {
    return "bhvpshosting";
  }
  if (
    productKey.startsWith("single") ||
    productKey.startsWith("w2k") ||
    productKey.startsWith("linux")
  ) {
    return "sdh";
  }
  if (productKey.startsWith("multi")) {
    return "mdh";
  }
  if (productKey.startsWith("virtual")) {
    return "vpshosting";
  }
  if (productKey.startsWith("dedi")) {
    return "dedicatedserver";
  }
  if (productKey.startsWith("cloud")) {
    return "cloudhosting";
  }
  if (productKey.startsWith("procloud")) {
    return "procloud";
  }
  if (productKey.startsWith("enterprise")) {
    return "enterpriseemail";
  }
  if (productKey.startsWith("eelite")) {
    return "businessemail";
  }
  if (productKey.startsWith("gapps")) {
    return "gsuite";
  }
  if (productKey.startsWith("ssl") || productKey.startsWith("thawte")) {
    return "sslcert";
  }
  if (productKey.startsWith("reseller")) {
    return "rchosting";
  }
  if (productKey.startsWith("wordpresscloud")) {
    return "wpcloud";
  }
  if (productKey.startsWith("wordpress")) {
    return "wph";
  }
  if (productKey.startsWith("codeguard")) {
    return "codeguard";
  }
  if (productKey.startsWith("hosting") || productKey.startsWith("sblite")) {
    return "websitebuilder";
  }
  if (productKey.startsWith("hostgatorhostingshared")) {
    return "hgshosting";
  }
  if (productKey.startsWith("hostgatordedicatedserver")) {
    return "hgdedicatedserver";
  }
  if (productKey.startsWith("businesscloud")) {
    return "businesscloud";
  }
  if (productKey.startsWith("blockstorage")) {
    return "blockstorage";
  }
  if (productKey.startsWith("acronis")) {
    return "backup";
  }
  if (productKey.startsWith("backorder")) {
    return "backorder";
  }
  if (productKey.startsWith("sitebuilder")) {
    return "webzai";
  }
  if (productKey.startsWith("titanmail")) {
    return "titanmail";
  }
  return "domain";
};

export const getLocationFromProductName = (productName: string) => {
  if (productName.endsWith("us") || productName.endsWith("usa")) {
    return "us";
  }
  if (productName.endsWith("usca")) {
    return "usaca";
  }
  if (productName.endsWith("uk")) {
    return "uk";
  }
  if (productName.endsWith("hk")) {
    return "hk";
  }
  if (productName.endsWith("in") || productName.endsWith("india")) {
    return "in";
  }
  if (productName.endsWith("tr")) {
    return "tr";
  }
  if (productName.endsWith("gbl")) {
    return "gbl";
  }
  if (productName.endsWith("se") || productName.endsWith("sea")) {
    return "se";
  }
  if (productName.endsWith("asia")) {
    return "asia";
  }
  return "us";
};

export const getProductCategoryFromKey = (productKey: string) => {
  if (productKey.includes("acronis")) {
    return "ACRONIS BACKUP";
  }
  if (productKey === "productbundle") {
    return "COMBO PLANS";
  }
  if (productKey === "weebly") {
    return "WEEBLY";
  }
  if (productKey === "sblite") {
    return "WEBSITE BUILDER";
  }
  let [productCategoryName] = Object.values(ProductCategories)
    .map(productCategory =>
      Object.entries(productCategory).find(([, productKeys]) =>
        productKeys.includes(productKey)
      )
    )
    .find(Boolean) ?? ["Domain Registration"];
  return productCategoryName;
};

export const getPlatformFromProductName = productName => {
  if (productName.includes("windows")) {
    return platform.WINDOWS;
  }
  return platform.LINUX;
};

export const monthIndices = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12
};

const softLaunchProducts = process.env.FEATURE_SOFTLAUNCH;

export function isSoftLaunchProduct(productKey) {
  return softLaunchProducts.includes(getSimpleNameFromProductKey(productKey));
}

const noShortcutProducts = process.env.FEATURE_NO_SHORTCUTS;

export function hasNoShortcuts(productKey) {
  return noShortcutProducts.includes(getSimpleNameFromProductKey(productKey));
}

const skeletonEnabledUserIDs = process.env.FEATURE_SKELETON_ENABLED_USER_IDS;
const userMode = process.env.FEATURE_ENDPOINT;

export function isSkeletonEnabled({
  userid,
  parentid
}: {
  userid: string;
  parentid: string;
}): boolean {
  return skeletonEnabledUserIDs.includes(
    userMode === "customer" ? parentid : userid
  );
}

const gatewaywithTaxPayerId = ["paasvoucherbased"];

export function gatewayRequiresTaxPayerId(gatewayType) {
  return gatewaywithTaxPayerId.includes(gatewayType);
}

export function getBackupProductKeyFromLocation(location) {
  switch (location) {
    case "us":
      return "acronisbackupus";
    default:
      return "acronisbackupasia";
  }
}

export const getLocationForBackup = location =>
  location === "us" ? "us" : "asia";

export default apiEndpoints;
