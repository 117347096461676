import { helpers } from "common/orders/helpers";
import {
  getOrderDetails,
  getPricingFromProductKey,
  onGetPlanDetails,
  updatePricingAndAddonDetails
} from "common/orders/sagas";
import {
  getBackupProductKeyFromLocation,
  getLocationForBackup
} from "constants/index";
import ProductKeyMapping from "constants/ProductKeyMapping";
import { mapEffect } from "map-effect";
import { call, select, takeEvery, takeLatest } from "redux-saga/effects";
import { actionTypes } from "./ducks";
import { services } from "./services";

export default [
  takeEvery(actionTypes.SEARCH_BACKUP_ORDER, mapEffect(searchBackupOrder)),
  takeEvery(actionTypes.GET_BACKUP_PRICING, mapEffect(getBackupPricing)),
  takeEvery(actionTypes.INSTALL_AGENT, mapEffect(installAgent)),
  takeLatest(actionTypes.RENEW_BACKUP, mapEffect(renewBackup))
];

function* searchBackupOrder(action) {
  let isBackupOrderFound = false;
  let hostingOrderMappingState = null;
  let backupOrderDetails = {};
  let hostingOrderId = action.payload.orderid;
  let searchResponse = yield services.searchBackupOrder({
    ...action.payload,
    location: getLocationForBackup(action.payload.location),
    "customer-id": action.payload.customerid
  });

  if (searchResponse?.orderid) {
    isBackupOrderFound = true;
    backupOrderDetails = yield call(
      getOrderDetails,
      {
        orderid: searchResponse.orderid,
        productDetailsKey: searchResponse.productkey
      },
      { getRawData: true, forceReload: false }
    );
  }
  if (
    searchResponse?.orderid &&
    searchResponse?.orderdetails?.hasOwnProperty(hostingOrderId)
  ) {
    hostingOrderMappingState = searchResponse.orderdetails[
      hostingOrderId
    ].toLowerCase();
  }
  return {
    searchResponse,
    isBackupOrderFound,
    hostingOrderMappingState,
    details: backupOrderDetails,
    payload: action.payload
  };
}

function* getBackupPricing(action) {
  let response = {};
  let { location, details } = action.payload;
  let backupProductKey = getBackupProductKeyFromLocation(location);
  let backupPlans = yield call(onGetPlanDetails, {
    meta: {
      ...ProductKeyMapping[backupProductKey].meta,
      productKey: backupProductKey
    }
  });
  response = yield call(getPricingFromProductKey, backupProductKey, true);
  let planToAdd = Object.keys(backupPlans)[0];
  let { [backupProductKey]: backupPricing } =
    response.reseller_pricing ?? response;
  return {
    planToAdd,
    pricing: backupPricing.plans[planToAdd],
    planDetails: backupPlans[planToAdd]
  };
}

function* installAgent(payload) {
  yield services.installAgent({
    ...payload,
    location: getLocationForBackup(payload.location),
    hostingOrderId: payload.orderid,
    backupOrderId: payload.details.orderid
  });
}

function* renewBackup(action) {
  let { orderid, productkey } = action?.backupOrderDetails ?? action;
  let orderDetails = yield call(
    getOrderDetails,
    {
      orderid,
      productDetailsKey: productkey
    },
    { getRawData: true, forceReload: false }
  );
  yield call(
    updatePricingAndAddonDetails,
    helpers.formatOboxOrderDetails(orderDetails, {
      product_category: `Acronis Cyber Backup`,
      deletesIn: 0,
      deletesOn: 0
    }),
    orderDetails,
    null
  );
  let renewalDetails = yield select(
    state => state.orders.renewalDetails[orderid] ?? null
  );
  if (renewalDetails === null) {
    throw new Error(
      `Renewal flow for backup ${action.backupOrderDetails?.domainName} failed`
    );
  }
  return renewalDetails;
}
