import * as React from "react";

function LanguageIcon(props) {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
      <g fill="#BBBBCA" fillRule="nonzero" stroke="none" strokeWidth={1}>
        <path
          d="M9.412 14.242v-3.697h-4.12c.04 1.45.23 2.84.552 4.1 1.127-.232 2.331-.37 3.568-.403zM8.314 19.28c.358.304.726.508 1.098.613v-4.479a19.395 19.395 0 00-3.23.356c.173.505.371.982.592 1.424.451.902.97 1.604 1.54 2.087zM13.223 17.194c.22-.442.419-.919.593-1.424a19.39 19.39 0 00-3.232-.356v4.479c.373-.105.741-.31 1.1-.612.57-.483 1.088-1.185 1.539-2.087zM14.961 3.877a12.529 12.529 0 002.265-.851 9.978 9.978 0 00-4.09-2.583A8.83 8.83 0 0114.271 2.2c.26.52.49 1.08.69 1.676zM10.584 10.545v3.697c1.238.033 2.442.171 3.568.403.324-1.26.513-2.65.553-4.1h-4.12zM6.774 2.725c-.22.442-.419.919-.593 1.423 1.02.203 2.109.325 3.231.356V.026c-.372.105-.74.309-1.098.612-.57.482-1.089 1.185-1.54 2.087zM9.412 9.373V5.677a20.498 20.498 0 01-3.568-.403 18.694 18.694 0 00-.552 4.1h4.12zM5.036 16.042c-.826.227-1.589.513-2.265.851l.145.149a9.973 9.973 0 003.945 2.434 8.83 8.83 0 01-1.135-1.758 13.01 13.01 0 01-.69-1.676zM15.291 14.92c.998.276 1.915.631 2.715 1.058a9.923 9.923 0 001.99-5.433h-4.119a19.923 19.923 0 01-.586 4.374zM14.961 16.042c-.2.595-.43 1.157-.69 1.676a8.83 8.83 0 01-1.135 1.758 9.973 9.973 0 004.09-2.583 12.525 12.525 0 00-2.265-.851zM15.291 5a19.92 19.92 0 01.586 4.373h4.12a9.923 9.923 0 00-1.99-5.432c-.801.426-1.718.782-2.716 1.058zM10.584 5.677v3.696h4.121c-.04-1.45-.23-2.84-.553-4.1-1.126.233-2.33.37-3.568.404zM11.683.638c-.358-.303-.726-.507-1.099-.612v4.478a19.395 19.395 0 003.232-.356 11.778 11.778 0 00-.593-1.423c-.451-.902-.97-1.605-1.54-2.087zM5.036 3.877c.2-.595.43-1.157.69-1.676A8.83 8.83 0 016.861.443a9.973 9.973 0 00-4.09 2.583c.676.338 1.439.623 2.265.851zM4.706 14.92a19.922 19.922 0 01-.586-4.375H0a9.923 9.923 0 001.99 5.433c.8-.427 1.718-.782 2.716-1.059zM4.706 5A13.417 13.417 0 011.99 3.94 9.923 9.923 0 000 9.373h4.12c.04-1.54.24-3.023.586-4.374z"
          transform="translate(-21 -730) translate(0 71) translate(21 659)"
        />
      </g>
    </svg>
  );
}

export default LanguageIcon;
