import pandora from "@faizaanceg/pandora";
import { FeedbackSdk } from "common/sdk/feedback";
import { actions } from "common/session-management/ducks";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useSWRMutation from "swr/mutation";
import SwitchToPanelIcon from "./assets/switchtopanel.svg";

export function SwitchPanel() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userId, role } = useSelector(state => {
    return {
      userId: state.root.userInfo.userid,
      role: state.root.userInfo.role
    };
  });
  const checkFeedback = useSWRMutation("feedback-list", async () => {
    if (process.env.FEATURE_ENDPOINT === "bigrock") {
      dispatch(
        actions.goToControlPanel({
          "view-name": "listorders"
        } as any)
      );
      return;
    }
    const response = await FeedbackSdk.fetch(userId);
    if (response["feedback-list"].length === 0) {
      let switchPanelCount = pandora.get("feedback", 0) as number;
      const newCount = switchPanelCount + 1;
      pandora.set("feedback", newCount, { shouldPersist: true });
      if (Number.isInteger((newCount - 2) / 3)) {
        dispatch(actions.openFeedbackModal());
      } else {
        dispatch(actions.goToControlPanel());
      }
    } else {
      dispatch(actions.goToControlPanel());
    }
  });
  return (
    <button
      type="button"
      className="wp-header-switch"
      onClick={checkFeedback.trigger}
      data-testid="switch-panel-button"
    >
      <img
        src={SwitchToPanelIcon}
        className="wp-header-help-icon"
        alt="Help Icon"
      />
      <span className="wp-header-help-text">
        {role === "reseller"
          ? t("SWITCH_PANEL", "Switch to old panel")
          : t("PANEL_REVERT", "Revert to old look ")}
      </span>
    </button>
  );
}
