import { isSuccess, isFailure } from "common/api-status/utils";

export const actionTypes = {
  CHANGE_SERVER_STATE: "[Power Bar] Change server state",
  GET_VNC_ACCESS: "[Power Bar] Get VNC Access Url"
};

export const actions = {
  changeServerState: (meta, action) => ({
    type: actionTypes.CHANGE_SERVER_STATE,
    meta,
    action
  }),
  changeServerStateSuccess: (response, meta) => ({
    type: isSuccess(actionTypes.CHANGE_SERVER_STATE),
    response,
    meta
  }),
  changeServerStateFailure: (error, orderid) => ({
    type: isFailure(actionTypes.CHANGE_SERVER_STATE),
    error,
    orderid
  }),
  getVNCAccess: meta => ({
    type: actionTypes.GET_VNC_ACCESS,
    meta
  })
};
export const initialState = {
  serverpowerbar: { serverState: "", lastModified: "" }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case isSuccess(actionTypes.CHANGE_SERVER_STATE):
      const {
        meta: { parent = "", child = "serverpowerbar" }
      } = action;
      return {
        ...state,
        [parent]: { ...state[parent], [child]: initialState }
      };
    default:
      return state;
  }
};

export const parsers = {
  parseServerStatus: ({
    "server-status": serverState = "",
    "last-modified": lastModified = ""
  }) => {
    return { serverState: serverState.toLowerCase(), lastModified };
  }
};
