import i18n from "i18next";
import { initReactI18next } from "react-i18next";

let langPref = window.__langPref;

export const LANGUAGES = {
  en: "English",
  id: "Bahasa Indonesia",
  pt: "Portuguese",
  es: "Spanish",
  tr: "Türk"
  // hi: "हिंदी"   // Only for tesing
};

export const oboxLanguageMap = {
  en: "en",
  id: "ID",
  pt: "pt",
  es: "es",
  tr: "tr"
};

export const oboxLanguageReverseMap = {
  en: "en",
  ID: "id",
  pt: "pt",
  es: "es",
  tr: "tr"
};

let defaultLanguage = "en";

if (Object.keys(LANGUAGES).includes(oboxLanguageReverseMap[langPref])) {
  defaultLanguage = oboxLanguageReverseMap[langPref] || "en";
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: window.__sofiTranslations || {},
    lng: defaultLanguage,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
