import { Syringe } from "@faizaanceg/syringe";
import api from "api";
import { Collection } from "common/orderbox-types";
import constants from "constants/endpoints";

const billing = `${constants.zuulOrderBox}billing/`;

interface TransactionRouteConfig {
  CURRENT: string;
  ARCHIVED: string;
  DETAILS: string;
  GREEDY: string;
  PENDING?: string;
}

const CUSTOMER: TransactionRouteConfig = {
  CURRENT: `${billing}customer-transactions/search`,
  ARCHIVED: `${billing}customer-archived-transactions/search`,
  DETAILS: `${billing}customer-transactions`,
  PENDING: `${constants.bclCacheableOrderBox}billing/orders/list-child-pending-transactions.json`,
  GREEDY: `${billing}customer-greedy-transactions`
};
const RESELLER: TransactionRouteConfig = {
  CURRENT: `${billing}my-transactions/search`,
  ARCHIVED: `${billing}my-archived-transactions/search`,
  DETAILS: `${billing}reseller-transactions`,
  GREEDY: `${billing}reseller-greedy-transactions`
};

const TRANSACTIONS: Record<Role, TransactionRouteConfig> = {
  reseller: RESELLER,
  customer: CUSTOMER
};

const serializeTransactions = ({ recsindb = 0, recsonpage = 0, ...list }) => ({
  recsindb: Number(recsindb),
  recsonpage: Number(recsonpage),
  list: Object.values(list)
});

async function getTransactions(url: string, options: TransactionOptions) {
  let transactions = await api.get(url, options).catch(() => ({}));
  return serializeTransactions(transactions);
}

export type TransactionOptions = {
  "balance-type": string;
  "ignore-skipfee-transaction"?: boolean;
  "no-of-records": number;
  "order-by"?: string;
  "page-no": number;
  "transaction-description"?: string;
  "transaction-type": string | string[];
};

let EmptyCollection: Collection = { recsindb: "0", recsonpage: "0" };

const sessionManager = Syringe.inject("sessionManager");

export const TransactionsSdk = {
  current: async (params: TransactionOptions) => {
    const {
      details: { role }
    } = await sessionManager.create();
    return getTransactions(TRANSACTIONS[role as Role].CURRENT, params);
  },
  archived: async (params: TransactionOptions) => {
    const {
      details: { role }
    } = await sessionManager.create();
    return getTransactions(TRANSACTIONS[role as Role].ARCHIVED, params);
  },
  payPendingAmount: ({ transactionType, transid }) =>
    api.post(
      `${constants.zuulOrderBox}billing/reseller-pay`,
      transactionType === "invoice"
        ? { "invoice-ids": transid }
        : { "debit-ids": transid }
    ),
  details: async (payload: { transid: string | string[] }) => {
    const {
      details: { role }
    } = await sessionManager.create();
    return api.get(TRANSACTIONS[role as Role].DETAILS, {
      "transaction-ids": payload.transid
    });
  },
  async greedy() {
    const { details } = await sessionManager.create();
    return api
      .get(TRANSACTIONS[details.role as Role].GREEDY, {
        [`${details.role}-id`]: details.userId
      })
      .catch(() => ({}));
  },
  orders: {
    async pending(orderId: string) {
      try {
        let response = await api.get<{
          pendingInvoices: Collection;
          pendingChildInvoices?: Collection;
        }>(`${constants.zuulOrderBox}orders/get-pending-invoices`, {
          "order-id": orderId
        });
        if (response.pendingInvoices === undefined) {
          response.pendingInvoices = EmptyCollection;
        }
        if (response.pendingChildInvoices === undefined) {
          response.pendingChildInvoices = EmptyCollection;
        }
        return response;
      } catch (error) {
        return {
          pendingInvoices: EmptyCollection,
          pendingChildInvoices: EmptyCollection
        };
      }
    }
  },
  reseller: {
    async greedy() {
      const { details } = await sessionManager.create();
      return api
        .get(RESELLER.GREEDY, { "reseller-id": details.userId })
        .catch(() => ({}));
    },
    pending: (offset: number, limit: number) =>
      getTransactions(RESELLER.CURRENT, {
        "balance-type": "onlyunbalanced",
        "order-by": "reseller_transaction.transactiondate desc",
        "ignore-skipfee-transaction": true,
        "transaction-type": ["custom_invoice", "debit", "invoice"],
        "page-no": offset,
        "no-of-records": limit
      })
  },
  customer: {
    cancelPendingInvoices: (invoiceIds: string | string[]) =>
      api.post(`${CUSTOMER.DETAILS}/cancel`, { "invoice-ids": invoiceIds }),
    details: (transactionIds: string | string[]) =>
      api.get(CUSTOMER.DETAILS, { "transaction-ids": transactionIds }),
    pending: (orderIds: string | string[]) =>
      api
        .post(CUSTOMER.PENDING, { "order-id": orderIds })
        .catch(() => ({ recsindb: "0", recsinpage: "0" })),
    async greedy(userId: string) {
      return api.get(CUSTOMER.GREEDY, { "customer-id": userId });
    }
  }
};
