import Snowfall from "react-snowfall";
import * as React from "react";

export default React.memo(function ChristmasSnowfall(props: {
  isVisible: boolean;
}) {
  let [isVisible, setVisible] = React.useState(props.isVisible);
  React.useEffect(() => {
    setVisible(props.isVisible);
  }, [props.isVisible]);
  React.useEffect(() => {
    let isRunning = true;
    if (isVisible === false) {
      return;
    }
    let timeoutId = setTimeout(() => {
      if (isRunning) {
        setVisible(false);
      }
    }, 10000);
    return () => {
      isRunning = false;
      clearTimeout(timeoutId);
    };
  }, [isVisible]);
  return (
    <Snowfall style={{ zIndex: 9999 }} snowflakeCount={isVisible ? 150 : 0} />
  );
});
