import {
  actions as AnalyticsActions,
  categories as AnalyticsCategories,
  labels as AnalyticsLabels
} from "analytics/constants";
import { isFailure, isSuccess } from "common/api-status/utils";
import { actionTypes as orderActionTypes } from "common/orders/ducks";
import { simpleNames } from "constants/index";

export const actionTypes = {
  SUSPEND_ORDER: "[Order Management] Suspend",
  UNSUSPEND_ORDER: "[Order Management] Unsuspend",
  RENEW_ORDER: "[Order Management] Renew",
  DELETE_ORDER: "[Order Management] Delete",
  GET_RENEWAL_PRICING: "[Order Management] Get Renewal Pricing",
  RESET_DATA: "[Order Management] Reset",
  SHOW_SUSPEND_ORDER_MODAL: "[Order Management] Show Suspend Modal",
  SHOW_SUSPEND_ORDER_CONFIRM_MODAL:
    "[Order Management] Show Suspend Confirm Modal",
  SHOW_UNSUSPEND_ORDER_MODAL: "[Order Management] Show Unsuspend Modal",
  SHOW_DELETE_ORDER_CONFIRM_MODAL:
    "[Order Management] Show Delete Confirm Modal",
  SHOW_DELETE_ORDER_MODAL: "[Order Management] Show Delete Modal"
};

const initialState = { orders: { list: [] } };

export default (state = initialState, action) => {
  switch (action.type) {
    case isSuccess(orderActionTypes.GET_DOMAIN_ORDERS_ORDER_MANAGEMENT): {
      const orders = action.effectResponse;
      const list = simpleNames
        .map(product =>
          orders
            .filter(order => order.productType === product)
            .map(order => order.orderid)
        )
        .flat();
      return { orders: { list } };
    }

    case actionTypes.RESET_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export const actions = {
  showSuspendModal: meta => ({
    type: actionTypes.SHOW_SUSPEND_ORDER_MODAL,
    ...meta
  }),
  showSuspendConfirmModal: meta => ({
    type: actionTypes.SHOW_SUSPEND_ORDER_CONFIRM_MODAL,
    ...meta
  }),
  suspendOrder: ({ orderid, reason, product_name: name = "" }) => ({
    type: actionTypes.SUSPEND_ORDER,
    payload: { orderid, reason },
    track: {
      eventCategory: AnalyticsCategories.order_micro_states,
      eventAction: `${AnalyticsActions.suspend} ${name}`,
      eventLabel: AnalyticsLabels.suspend
    }
  }),
  showUnsuspendModal: meta => ({
    type: actionTypes.SHOW_UNSUSPEND_ORDER_MODAL,
    ...meta
  }),
  unSuspendOrder: orderid => ({
    type: actionTypes.UNSUSPEND_ORDER,
    payload: { orderid }
  }),
  renewOrder: payload => ({ type: actionTypes.RENEW_ORDER, payload }),
  renewOrderSuccess: response => ({
    type: isSuccess(actionTypes.RENEW_ORDER),
    response
  }),
  renewOrderFailure: error => ({
    type: isFailure(actionTypes.RENEW_ORDER),
    error
  }),
  showDeleteOrderModal: meta => ({
    type: actionTypes.SHOW_DELETE_ORDER_MODAL,
    ...meta
  }),
  showDeleteConfirmModal: meta => ({
    type: actionTypes.SHOW_DELETE_ORDER_CONFIRM_MODAL,
    ...meta
  }),
  deleteOrder: ({
    endpoint,
    orderid,
    reason,
    productDetailsKey = null,
    textReason = "",
    product_name: name = "",
    productType = ""
  }) => ({
    type: actionTypes.DELETE_ORDER,
    endpoint,
    orderid,
    reason: reason === "Other" ? textReason : reason,
    productDetailsKey,
    track: {
      eventCategory: AnalyticsCategories.order_micro_states,
      eventAction: `${AnalyticsActions.delete} ${name}`,
      eventLabel: AnalyticsLabels.delete
    }
  }),
  deleteOrderSuccess: response => ({
    type: isSuccess(actionTypes.DELETE_ORDER),
    payload: { orderid: response }
  }),
  deleteOrderFailure: (error, orderId) => ({
    type: isFailure(actionTypes.DELETE_ORDER),
    error,
    orderId
  }),
  getRenewalPricingDetails: (orderdetails, taxinfo) => ({
    type: actionTypes.GET_RENEWAL_PRICING,
    orderdetails: { productKey: orderdetails.productkey, ...orderdetails },
    taxinfo
  }),
  resetData: () => ({ type: actionTypes.RESET_DATA })
};
