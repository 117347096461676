import React from "react";
import storage from "@faizaanceg/pandora";

let StorageContext = React.createContext(() => {});

export function StorageProvider(props) {
  let [state, forceUpdate] = React.useState({});
  let updateStorage = React.useCallback(function updateStorage(
    key,
    newValue,
    options
  ) {
    storage.set(key, newValue, options);
    forceUpdate(storage.getSnapshot());
  },
  []);
  let contextState = React.useMemo(() => [state, updateStorage], [
    state,
    updateStorage
  ]);
  return (
    <StorageContext.Provider value={contextState}>
      {props.children}
    </StorageContext.Provider>
  );
}

export function useStorage(storageKey, defaultValue) {
  let updateStorage = React.useContext(StorageContext)[1];
  let updateState = React.useCallback(
    function(value, options) {
      updateStorage(storageKey, value, options);
    },
    [storageKey, updateStorage]
  );
  return [storage.get(storageKey, defaultValue), updateState];
}
