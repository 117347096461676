import api from "api";
import endpoints from "constants/endpoints";

interface SitelockWebsite {
  domainName: string;
  notification: { creationTime: string; notificationId: string }[];
}
interface SitelockAccount {
  customerId: string;
  website: SitelockWebsite[];
}
interface SitelockThreats {
  account: SitelockAccount[];
}

const SITELOCK = `${endpoints.zuulOrderBox}sitelock/threat/`;

export let services = {
  deleteThreat: ({ notificationId }) =>
    api.post(`${SITELOCK}processed`, { "notification-id": notificationId }),
  getThreats: ({ customerid, domainname }) =>
    api
      .get(`${SITELOCK}pending`)
      .then<SitelockWebsite["notification"]>((threatList: SitelockThreats) =>
        threatList?.account
          ?.filter(threat => threat.customerId === customerid)
          .filter(threat =>
            threat.website.some(website => website.domainName === domainname)
          )
          .map(threat => threat.website.map(website => website.notification))
          .flat(2)
      )
      .catch(() => [])
};
