import { Syringe } from "@faizaanceg/syringe";
import {
  DialogContent,
  DialogInner,
  DialogOverlayProps,
  unstable_DialogWrapper as DialogWrapper
} from "@reach/dialog";
import ModalError from "common/errors/modal/index";
import { cx } from "linaria";
import React, { ForwardedRef, useRef } from "react";
import { CloseModal } from "./assets/close-modal";
import {
  DialogContentStyle,
  DialogOverlayStyle,
  FullScreenModal,
  SecureModal,
  WPModalClose,
  WPModalContent,
  WPModalContentBody,
  WPModalContentRight,
  WPModalFlex,
  WPModalTitle
} from "./styles";

type ModalProps = {
  show?: boolean;
  hide?: any;
  onHide?: any;
  alignCenter?: boolean;
  secureModal?: boolean;
  width?: number;
  errorMessage?: string;
  apiStatus?: any;
  title?: string;
  mainContent?: any;
  rightContent?: any;
  rightContentStyle?: Object;
  customStyle?: string;
  hideCloseButton?: boolean;
  fullScreen?: boolean;
};

type Fullscreen = {
  fullScreen?: boolean;
};

export const DialogOverlay = React.forwardRef(function DialogOverlay(
  { isOpen = true, fullScreen = false, ...props },
  forwardedRef
) {
  let container = Syringe.inject("portalContainer");
  let containerRef = useRef(container);
  return isOpen ? (
    <DialogWrapper isOpen={isOpen} containerRef={containerRef}>
      <DialogInner
        data-reach-dialog-overlay=""
        ref={forwardedRef}
        as="div"
        dangerouslyBypassFocusLock
        dangerouslyBypassScrollLock={fullScreen}
        {...props}
      />
    </DialogWrapper>
  ) : null;
}) as (
  props: DialogOverlayProps & JSX.IntrinsicElements["div"] & Fullscreen,
  ref: ForwardedRef<any>
) => React.ReactElement | null;

const WPModal = ({
  hide,
  onHide,
  alignCenter,
  show,
  width,
  secureModal,
  apiStatus,
  title,
  errorMessage,
  mainContent,
  rightContent,
  rightContentStyle,
  customStyle,
  hideCloseButton,
  fullScreen
}: ModalProps) => {
  let onDismiss = hide || onHide;
  return (
    <DialogOverlay
      className={DialogOverlayStyle}
      onDismiss={onDismiss}
      isOpen={show}
      fullScreen={fullScreen}
      style={{ alignItems: alignCenter && "center" }}
    >
      <DialogContent
        className={cx(
          DialogContentStyle,
          customStyle,
          secureModal && SecureModal,
          fullScreen ? FullScreenModal : ""
        )}
        style={{
          width: width ? `${width}px` : "auto"
        }}
      >
        {!hideCloseButton && (
          <WPModalClose onClick={onDismiss}>
            <CloseModal />
          </WPModalClose>
        )}
        <WPModalFlex>
          <WPModalContent>
            {apiStatus?.errors ? (
              <ModalError>
                {errorMessage ?? apiStatus?.errors?.message}
              </ModalError>
            ) : null}
            <WPModalTitle>{title}</WPModalTitle>
            <WPModalContentBody>{mainContent}</WPModalContentBody>
          </WPModalContent>
          {rightContent && (
            <WPModalContentRight style={rightContentStyle}>
              {rightContent}
            </WPModalContentRight>
          )}
        </WPModalFlex>
      </DialogContent>
    </DialogOverlay>
  );
};

export default WPModal;
