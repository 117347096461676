import { Card } from "assets/icons/cards";
import { PayU } from "assets/icons/gateways";
import { services } from "common/pay-buttons/services";
import React from "react";
import storage from "@faizaanceg/pandora";
import { CreateBillingAgreementForm } from "../billing-agreement-form";

export function PayuTabs(props) {
  const [paymentMethod, setPaymentMethod] = React.useState([]);
  let initialTab = null;
  if (!props.paypw.isAllowed && !props.payu.isAllowed) {
    return null;
  }
  if (props.paypw.isAllowed) {
    initialTab = "CC";
  }
  if (props.payu.isAllowed) {
    initialTab = "PAYU";
  }
  return (
    <div className="add-card-auto-renewal-tab add-card-auto-renewal-tab-ccp">
      {props.paypw.isAllowed && (
        <div className="del-reasons">
          <input
            type="radio"
            name="method"
            value="pay_pw"
            defaultChecked={initialTab === "CC"}
            onChange={event => setPaymentMethod(event.target.value)}
            className="del-radio-ip-ccp"
            id="creditcard"
          />
          <label htmlFor="creditcard" className="del-labels">
            <span className="del-radio-btn" />
            <img alt="card-type" src={Card} className="card-icon" />
            <div className="add-card-label">
              Add your Credit/Debit card
              <br />
              {/* TODO: Get info about text here */}
              <span>(Visa/MasterCard)</span>
            </div>
          </label>
        </div>
      )}
      {props.payu.isAllowed && (
        <div className="del-reasons">
          <input
            type="radio"
            name="method"
            value="payu"
            onChange={event => setPaymentMethod(event.target.value)}
            className="del-radio-ip-ccp"
            id="payu_in"
          />
          <label htmlFor="payu_in" className="del-labels">
            <span className="del-radio-btn" />
            <img alt="card-type" src={PayU} className="card-icon" />
            <div className="add-card-label">NetBanking using PayU.in</div>
          </label>
        </div>
      )}
      <div className="text-align-center">
        {paymentMethod === "payu" ? (
          <CreateBillingAgreementForm
            gatewayType="payu.in"
            onSubmit={_ => {
              storage.set("deferred-orders", props.orderMap);
            }}
          >
            <button type="submit" className="add-card-payment-submit">
              PROCEED
            </button>
          </CreateBillingAgreementForm>
        ) : (
          <button
            type="submit"
            className="add-card-payment-submit"
            onClick={() => {
              storage.set("deferred-orders", props.orderMap);
              services.addNewCard();
            }}
          >
            PROCEED
          </button>
        )}
      </div>
    </div>
  );
}
