import { actionTypes as autoRenewalActionTypes } from "common/auto-renewal/ducks";
import { actionTypes as orderSuspensionAlertActionTypes } from "common/order-suspension/ducks";
import { actionTypes as transactionActionTypes } from "common/pending-payment-buttons/ducks";
import { ReloadActionType } from "common/reloadable/ducks";
import { actionTypes as manageUpgradeActionTypes } from "modals/upgrade-plan-helpers/ducks";
import { actionTypes as cancelPrivacyActionTypes } from "notifications/manage-domain/ducks";
import { actionTypes as childNameServerActionTypes } from "widget-models/childname-server/ducks";
import { actionTypes as domainForwardingActionTypes } from "widget-models/domain-forwarding/ducks";
import { actionTypes as emailPowerbarActionTypes } from "widget-models/email-address/ducks";
import { actionTypes as gsuiteSetupActionTypes } from "widget-models/gsuite-setup/ducks";
import { actionTypes as manageAddonsActionTypes } from "widget-models/manage-addons/ducks";
import { actionTypes as manageBackupActionTypes } from "widget-models/manage-backup/ducks";
import { actionTypes as manageBlocksActionTypes } from "widget-models/manage-blocks/ducks";
import { actionTypes as manageDNSSECActionTypes } from "widget-models/manage-dns-sec/ducks";
import { actionTypes as manageDNSActionTypes } from "widget-models/manage-dns/ducks";
import { actionTypes as manageEmailActionTypes } from "widget-models/manage-email/ducks";
import { actionTypes as manageIPActionTypes } from "widget-models/manage-ip/ducks";
import { actionTypes as managePanelActionTypes } from "widget-models/manage-panel/ducks";
import { actionTypes as manageSSLActionTypes } from "widget-models/manage-ssl/ducks";
import { actionTypes as manageStorageActionTypes } from "widget-models/manage-storage/ducks";
import { actionTypes as manageVolumesActionTypes } from "widget-models/manage-volumes/ducks";
import { actionTypes as manageWHMCSActionTypes } from "widget-models/manage-whmcs/ducks";
import { actionTypes as managedServicesActionTypes } from "widget-models/managed-service-addon/ducks";
import { actionTypes as modifyTransferSecretActionTypes } from "widget-models/modify-transfer-secret/ducks";
import { actionTypes as privacyActionTypes } from "widget-models/privacy/ducks";
import { actionTypes as serverPowerBarActionTypes } from "widget-models/server-power-bar/ducks";
import { actionTypes as shellAccessActionTypes } from "widget-models/shell-access/ducks";
import { actionTypes as theftProtectionActionTypes } from "widget-models/theft-protection/ducks";
import { actionTypes as manageDomainContactsActionTypes } from "widgets/domain-widgets/contact-details/ducks";
import { actionTypes, renewActionTypes } from "../ducks";

const UpdateActionTypes = [
  transactionActionTypes.CANCEL_PENDING_INVOICE,
  ReloadActionType,
  actionTypes.SUSPEND_ORDER,
  actionTypes.UNSUSPEND_ORDER,
  actionTypes.DELETE_ORDER,
  autoRenewalActionTypes.SET_AUTO_RENEWAL,
  renewActionTypes.RENEWAL_REQUEST,
  modifyTransferSecretActionTypes.MODIFY_TRANSFER_SECRET_REQUEST,
  manageEmailActionTypes.DELETE_EMAIL_ACCOUNTS,
  manageEmailActionTypes.PURCHASE_EMAIL_ACCOUNTS,
  managePanelActionTypes.PANEL_PASSWORD_CHANGE,
  manageIPActionTypes.BUY_DEDICATED_IP,
  manageIPActionTypes.DELETE_DEDICATED_IP,
  manageAddonsActionTypes.BUY_ADDONS,
  manageAddonsActionTypes.DELETE_ADDON,
  manageBlocksActionTypes.BUY_BLOCKS,
  manageBlocksActionTypes.DELETE_BLOCKS,
  gsuiteSetupActionTypes.GSUITE_ADMIN_SETUP_REQUEST,
  shellAccessActionTypes.ENABLE_SHELL_ACCESS,
  shellAccessActionTypes.DISABLE_SHELL_ACCESS,
  theftProtectionActionTypes.DISABLE_THEFT_PROTECTION,
  theftProtectionActionTypes.ENABLE_THEFT_PROTECTION,
  privacyActionTypes.PURCHASE_PRIVACY_PROTECTION,
  privacyActionTypes.ENABLE_FREE_PRIVACY_PROTECTION,
  privacyActionTypes.DISABLE_FREE_PRIVACY_PROTECTION,
  cancelPrivacyActionTypes.CANCEL_DISABLE_PRIVACY,
  privacyActionTypes.ENABLE_PRIVACY_PROTECTION,
  privacyActionTypes.DISABLE_PRIVACY_PROTECTION,
  manageDNSActionTypes.SAVE_NAME_SERVERS,
  childNameServerActionTypes.UPDATE_CHILD_NAMESERVERS,
  domainForwardingActionTypes.SAVE_DOMAIN_FORWARDING_DETAILS,
  serverPowerBarActionTypes.CHANGE_SERVER_STATE,
  manageDomainContactsActionTypes.UPDATE_CONTACT_DETAILS,
  emailPowerbarActionTypes.HIDE_CREATE_MODAL,
  emailPowerbarActionTypes.DELETE_EMAIL_ADDRESS,
  manageVolumesActionTypes.BUY_VOLUME,
  manageVolumesActionTypes.UPGRADE_VOLUME,
  manageVolumesActionTypes.DELETE_VOLUME,
  manageDNSActionTypes.PURCHASE_PDNS,
  manageSSLActionTypes.ENROLL_SSL,
  manageSSLActionTypes.CHANGE_VERIFICATION_EMAIL_SSL,
  manageSSLActionTypes.RECHECK_VERIFICATION_STATUS,
  managedServicesActionTypes.PURCHASE_MANAGED_SERVICE,
  managedServicesActionTypes.DELETE_MANAGED_SERVICE,
  manageStorageActionTypes.PURCHASE_STORAGE,
  manageBackupActionTypes.BUY_BACKUP,
  manageBackupActionTypes.INSTALL_AGENT,
  orderSuspensionAlertActionTypes.REFRESH_PENDING_INVOICE_STATUS,
  manageDNSSECActionTypes.ADD_DNS_SEC,
  manageDNSSECActionTypes.DELETE_DNS_SEC,
  manageWHMCSActionTypes.DELETE_WHMCS_KEY,
  manageWHMCSActionTypes.BUY_WHMCS,
  manageUpgradeActionTypes.CONFIRM_UPGRADE
];

export default UpdateActionTypes;
