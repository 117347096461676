import React from "react";
import WPModal from "common/modal";
import { colors, fonts } from "common/styles";
import { styled } from "linaria/react";
import { WPButton } from "common/modal/styles";
import { LockIcon, Cross } from "assets";
import { Link } from "react-router-dom";

let TwoFactorWrapper = styled.div`
  text-align: center;
  padding: 20px;
`;

let TwoFactorIcon = styled.img`
  width: 58px;
  height: 58px;
`;

let TwoFactorTitle = styled.div`
  font: 600 15px/20px ${fonts.BrandonGrotesque};
  color: ${colors.black.regular};
  margin: 10px 0 14px 0;
  text-transform: uppercase;
`;

let TwoFactorSubText = styled.div`
  font: 400 14px/21px ${fonts.OpenSans};
  color: ${colors.black.regular};
  margin: 0 0 25px 0;
`;

let TwoFactorSkipLink = styled.p`
  color: ${colors.blue.regular};
  font: 400 12px/21px ${fonts.OpenSans};
  margin: 0;
  cursor: pointer;
`;

let TwoFactorCloseIcon = styled.img`
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

let TwoFactorLink = styled(Link)`
  font: 600 12px/21px ${fonts.OpenSans};
  color: ${colors.white.regular};
  margin: 0 0 25px 0;
  &:hover,
  &:active,
  &:visited,
  &:focus {
    color: ${colors.white.regular};
  }
`;

const EnableTwoFactorAuthenticationModal = props => {
  const { onHide, show } = props;
  return (
    <WPModal
      show={show}
      hide={onHide}
      alignCenter
      width={600}
      title=""
      hideCloseButton={true}
      mainContent={
        <TwoFactorWrapper>
          <TwoFactorIcon src={LockIcon} />
          <TwoFactorTitle>ENABLE TWO-FACTOR AUTHENTICATION</TwoFactorTitle>
          <TwoFactorSubText>
            Add an extra layer of security to your account. Whenever you sign in
            to your account, you'll need to enter your password and verification
            code.
          </TwoFactorSubText>
          <WPButton
            type="button"
            style={{ margin: "0 auto 14px auto" }}
            onClick={onHide}
          >
            <TwoFactorLink to={"/dashboard/profile"}>
              ENABLE TWO-FACTOR AUTHENTICATION
            </TwoFactorLink>
          </WPButton>
          <TwoFactorSkipLink onClick={onHide}>Skip for now</TwoFactorSkipLink>
          <TwoFactorCloseIcon onClick={onHide} src={Cross} />
        </TwoFactorWrapper>
      }
    />
  );
};

export default EnableTwoFactorAuthenticationModal;
