import classNames from "classnames";
import APIStatus from "common/api-status";
import { isSuccess } from "common/api-status/utils";
import UpdateButton from "common/buttons/update";
import ModalError from "common/errors/modal";
import ModalHandler from "common/modal-handler";
import { State } from "common/state-container";
import { createErrorMap, formatAmountWithCurrency } from "common/util";
import { Field, Form, Formik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import AutomaticProvisionWarning from "./automatic-provision-warning";
import { getGatewayLogo } from "./constants";
import { actions, actionTypes } from "./ducks";
import "./styles.scss";
import { errorSheet } from "./validations";
import TaxPayerID from "common/payment-extra-info/component";
import { gatewayRequiresTaxPayerId } from "constants/index";
import { styled } from "linaria/react";
import classnames from "classnames";
import { BrandConfigurationOperations } from "common/brand-configuration";
let TaxPayerDiv = styled.div`
  position: absolute;
  top: 55%;
  height: 40%;
  background: white;
  padding-left: 1px;
  padding-right: 1px;
  display: flex;
`;

class AddFunds extends React.Component {
  static mapStateToProps = state => ({
    ...state.funds,
    wallet: state.wallet,
    userCountry: state.root.userInfo.country,
    onholdOrders: state.orders?.onholdOrders || [],
    brandConfiguration: BrandConfigurationOperations.findById(
      state.root.userInfo.parentid
    )
  });

  static mapDispatchToProps = actions;

  componentDidMount() {
    this.props.getNext30DaysRenewals();
    this.props.getGreedyTransactions();
  }

  monitorInfo = [
    [actionTypes.ADD_FUNDS],
    [actionTypes.GET_NEXT_30_DAYS_RENEWALS],
    [actionTypes.GET_GREEDY_TRANSACTIONS]
  ];

  state = {
    taxPayerIdValue: null,
    canSave: true
  };

  handleUpdateCanSave = cs => {
    this.setState({ canSave: cs });
  };

  render() {
    const {
      pendingAmount = 0,
      renewalAmount,
      greedyTransactions,
      wallet: { currencySymbol, walletBalance, paymentGateways },
      onholdOrders,
      brandConfiguration,
      t
    } = this.props;

    return (
      <ModalHandler
        opensOn={actionTypes.SHOW_ADD_FUNDS_MODAL}
        closesOn={isSuccess(actionTypes.ADD_FUNDS)}
        render={modalProps => (
          <Modal keyboard dialogClassName="add-funds genmodal" {...modalProps}>
            <Modal.Body>
              <div className="modal-close" onClick={modalProps.onHide} />
              <APIStatus
                monitors={this.monitorInfo}
                render={(addFundsStatus, ...apiStatuses) => {
                  const isFetching = apiStatuses.some(
                    apiStatus => apiStatus.isFetching
                  );
                  if (isFetching) {
                    return t("LOADING", "Loading...");
                  }
                  if (paymentGateways.length === 0) {
                    return t(
                      "TXNRC_NO_PG",
                      "No payment gateways assigned to this account"
                    );
                  }
                  return (
                    <>
                      <div className="funds-title">
                        {t("ADD_FUNDS_TO_ACC", "Add funds to your account")}
                      </div>
                      <Formik
                        validate={createErrorMap(errorSheet)}
                        enableReinitialize
                        initialValues={{
                          fundsToBeAdded: undefined,
                          gateway: paymentGateways[0].paymenttypeid
                        }}
                        onSubmit={this.props.addFunds}
                        render={({
                          errors,
                          isValid,
                          handleChange,
                          handleBlur,
                          values
                        }) => {
                          let gateway = paymentGateways.find(
                            _gateway =>
                              _gateway.paymenttypeid === values.gateway
                          );
                          const amountAfterDeductions =
                            process.env.FEATURE_ENDPOINT === "bigrock"
                              ? +values.fundsToBeAdded
                              : +values.fundsToBeAdded -
                                +pendingAmount -
                                0.01 *
                                  +gateway.transactionfeepercent *
                                  +values.fundsToBeAdded -
                                +gateway.transactionfeefixed;

                          return (
                            <div className="wp-add-funds-body">
                              <Form className="withdraw-form">
                                <div className="add-funds-errors">
                                  {errors.fundsToBeAdded && (
                                    <ModalError>
                                      {errors.fundsToBeAdded}
                                    </ModalError>
                                  )}
                                  {addFundsStatus.errors && (
                                    <ModalError>
                                      {t(
                                        "ADD_FUNDS_ERR",
                                        "We are not able to add funds. Try adding the funds again."
                                      )}
                                    </ModalError>
                                  )}
                                </div>
                                <div className="withdraw-form-box">
                                  <div style={{ display: "flex" }}>
                                    <div className="withdraw-form-box-item withdraw-form-box-left">
                                      <h4>
                                        {t(
                                          "ADD_FUNDS_WHAT_AMOUNT",
                                          "What amount would you like to add"
                                        )}
                                      </h4>
                                      <div className="funds-wrap notranslate">
                                        <label htmlFor="amount">
                                          {currencySymbol}
                                        </label>
                                        <Field
                                          type="text"
                                          name="fundsToBeAdded"
                                          autoComplete="off"
                                          required
                                          className="funds-input"
                                        />
                                      </div>
                                      {process.env.FEATURE_ENDPOINT ===
                                      "bigrock" ? (
                                        ""
                                      ) : (
                                        <div className="withdraw-amount-note">
                                          <ul>
                                            <li>
                                              <div className="add-funds-deduction-title">
                                                <span className="funds-deduction">
                                                  (-)
                                                </span>{" "}
                                                {t(
                                                  "ADD_FUNDS_TX_FEE",
                                                  "A transaction fee"
                                                )}
                                              </div>
                                              <div className="add-funds-deduction-cost">
                                                {gateway.transactionfeepercent}%
                                                +{" "}
                                                {gateway.gatewaycurrencysymbol}{" "}
                                                {gateway.transactionfeefixed}
                                              </div>
                                            </li>
                                            <State
                                              initial={{
                                                showGreedyTransactions: false
                                              }}
                                              render={(state, setState) => (
                                                <li>
                                                  <div
                                                    className="add-funds-deduction-title cursor-on-hover"
                                                    onClick={_ =>
                                                      setState({
                                                        showGreedyTransactions: !state.showGreedyTransactions
                                                      })
                                                    }
                                                  >
                                                    <span className="funds-deduction">
                                                      {state.showGreedyTransactions
                                                        ? "(-)"
                                                        : "(+)"}
                                                    </span>{" "}
                                                    {t(
                                                      "ADD_FUNDS_PENDING_INV",
                                                      "Pending Invoices of"
                                                    )}{" "}
                                                  </div>
                                                  <div className="add-funds-deduction-cost notranslate">
                                                    {formatAmountWithCurrency({
                                                      cost: pendingAmount,
                                                      currency: currencySymbol,
                                                      currencyDisplay: "code"
                                                    })}
                                                  </div>
                                                  {state.showGreedyTransactions && (
                                                    <div>
                                                      {greedyTransactions.map(
                                                        (
                                                          greedyTransaction,
                                                          index
                                                        ) => (
                                                          <div
                                                            key={`gt_${index}`}
                                                            className="notranslate"
                                                          >
                                                            {
                                                              greedyTransaction.why
                                                            }{" "}
                                                            {formatAmountWithCurrency(
                                                              {
                                                                cost:
                                                                  greedyTransaction.amount,
                                                                currency: currencySymbol,
                                                                currencyDisplay:
                                                                  "code"
                                                              }
                                                            )}
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  )}
                                                </li>
                                              )}
                                            />
                                          </ul>
                                        </div>
                                      )}

                                      {process.env.FEATURE_ENDPOINT ===
                                      "bigrock" ? (
                                        <div className="post-withdrawal">
                                          <h4>Current Wallet Balance</h4>
                                          <div className="post-withdrawal-balance notranslate">
                                            {currencySymbol}{" "}
                                            {formatAmountWithCurrency({
                                              cost: walletBalance,
                                              currency: currencySymbol,
                                              currencyDisplay: null
                                            })}
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="post-withdrawal">
                                          <h4>
                                            {" "}
                                            {t(
                                              "ADD_FUNDS_AMOUNT_TO_ADD",
                                              "Amount that will be added to your wallet"
                                            )}{" "}
                                          </h4>
                                          <div className="post-withdrawal-balance notranslate">
                                            {currencySymbol}{" "}
                                            {isNaN(amountAfterDeductions) ||
                                            amountAfterDeductions <= 0
                                              ? 0
                                              : formatAmountWithCurrency({
                                                  cost: amountAfterDeductions,
                                                  currency: currencySymbol,
                                                  currencyDisplay: null
                                                })}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="withdraw-form-box-item withdraw-form-box-right">
                                      <div className="wallet-balance">
                                        <h4>
                                          {t(
                                            "ADD_FUNDS_NEW_WALLET_BALANCE",
                                            "Your new wallet balance"
                                          )}
                                        </h4>
                                        <div className="wallet-balance-amount">
                                          <div className="wallet-balance-wrap notranslate">
                                            <div className="wallet-currency-type">
                                              {currencySymbol}
                                            </div>
                                            <div className="wallet-cost notranslate">
                                              {isNaN(amountAfterDeductions) ||
                                              amountAfterDeductions <= 0
                                                ? formatAmountWithCurrency({
                                                    cost: walletBalance,
                                                    currency: currencySymbol,
                                                    currencyDisplay: null
                                                  })
                                                : formatAmountWithCurrency({
                                                    cost:
                                                      walletBalance +
                                                      amountAfterDeductions,
                                                    currency: currencySymbol,
                                                    currencyDisplay: null
                                                  })}
                                            </div>
                                          </div>
                                          {renewalAmount > 0 ? (
                                            <div className="wallet-renew-cost">
                                              <Trans i18nKey="ADD_FUNDS_RENEW_MSG">
                                                Orders worth{" "}
                                                <span className="notranslate">
                                                  {{
                                                    amount: formatAmountWithCurrency(
                                                      {
                                                        cost: renewalAmount,
                                                        currency: currencySymbol,
                                                        currencyDisplay: "code"
                                                      }
                                                    )
                                                  }}
                                                </span>{" "}
                                                are up for renewal in the next
                                                30 days
                                              </Trans>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <AutomaticProvisionWarning
                                    onholdOrders={onholdOrders}
                                  />
                                </div>

                                <div className="funds-gateway">
                                  <div className="funds-gateway-title">
                                    {t(
                                      "ADD_FUNDS_CHOOSE_PG",
                                      "CHOOSE A PAYMENT GATEWAY TO ADD FUNDS"
                                    )}
                                  </div>
                                  <ul className="payment-gateways">
                                    {paymentGateways.map((gateway, _index) => (
                                      <li key={`gateway_${_index}`}>
                                        <label
                                          className={classNames(
                                            "gateway-label",
                                            {
                                              selected:
                                                values.gateway ===
                                                gateway.paymenttypeid
                                            }
                                          )}
                                        >
                                          <input
                                            type="radio"
                                            name="gateway"
                                            id={gateway.gatewayname}
                                            checked={
                                              values.gateway ===
                                              gateway.paymenttypeid
                                            }
                                            value={gateway.paymenttypeid}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                          <div className="gateway-radio" />
                                          <div className="gateway-description">
                                            <div
                                              className="gateway-logo"
                                              style={{
                                                background: `url(${getGatewayLogo(
                                                  gateway.gatewaytype
                                                )}) center center no-repeat`,
                                                backgroundSize: "contain"
                                              }}
                                            />
                                            <div className="gateway-name">
                                              {gateway.gatewayname}
                                            </div>
                                            <div
                                              className={classnames(
                                                "gateway-info",
                                                {
                                                  hide_on_ccp:
                                                    process.env
                                                      .FEATURE_ENDPOINT ===
                                                    "bigrock"
                                                }
                                              )}
                                            >
                                              {t(
                                                "ADD_FUNDS_TX_FEE_AMOUNT",
                                                "You will be charged a Transaction fee of {{percent}} % + {{symbol}} {{fee}}",
                                                {
                                                  percent:
                                                    gateway.transactionfeepercent,
                                                  symbol:
                                                    gateway.gatewaycurrencysymbol,
                                                  fee:
                                                    gateway.transactionfeefixed
                                                }
                                              )}
                                            </div>
                                            {values.gateway ===
                                              gateway.paymenttypeid &&
                                              gatewayRequiresTaxPayerId(
                                                gateway.gatewaytype
                                              ) && (
                                                <TaxPayerDiv>
                                                  <TaxPayerID
                                                    countryCode={
                                                      this.props.userCountry
                                                    }
                                                    setCanSaveValue={
                                                      this.handleUpdateCanSave
                                                    }
                                                    showLabel={false}
                                                    customStyle={{
                                                      padding:
                                                        "10px 15px 10px 0"
                                                    }}
                                                  />
                                                </TaxPayerDiv>
                                              )}
                                          </div>
                                        </label>
                                      </li>
                                    ))}
                                  </ul>
                                </div>

                                {brandConfiguration.urls.storefront
                                  .offlinePaymentOptions && (
                                  <div className="funds-gateway">
                                    <div className="funds-gateway-title offlinepayment-title">
                                      OTHER OFFLINE PAYMENT OPTIONS
                                    </div>
                                    <div className="offline-anchor">
                                      <a
                                        href={
                                          brandConfiguration.urls.storefront
                                            .offlinePaymentOptions
                                        }
                                        target="_blank"
                                      >
                                        Click here for more information
                                      </a>
                                    </div>
                                  </div>
                                )}

                                <div className="add-funds-cta clear">
                                  <UpdateButton
                                    className="wp-btn-green"
                                    data-testid="add-funds-btn"
                                    disabled={
                                      !isValid ||
                                      !this.state.canSave ||
                                      isNaN(amountAfterDeductions) ||
                                      amountAfterDeductions <= 0
                                    }
                                    type="submit"
                                    updating={addFundsStatus.isFetching}
                                  >
                                    {t("ADD_FUNDS", "Add Funds")}
                                  </UpdateButton>
                                </div>
                              </Form>
                            </div>
                          );
                        }}
                      />
                    </>
                  );
                }}
              />
            </Modal.Body>
          </Modal>
        )}
      />
    );
  }
}

export default connect(
  AddFunds.mapStateToProps,
  AddFunds.mapDispatchToProps
)(withTranslation()(AddFunds));
