import { sortedIndex } from "lodash";

export const sum = (left: number, right: number) => left + right;

export const roundOff = (unary: number, noOfDecimals = 2) => {
  const roundOffBy = 10 ** noOfDecimals;
  return Math.round(unary * roundOffBy) / roundOffBy;
};

export const percentage = (left: any, right: any) =>
  (Number(left) * 100) / Number(right);

export function findNeighborInRange(range: number[], value: number) {
  let closestIndex = sortedIndex(range, value);
  if (closestIndex === 0 || range[closestIndex] === value) {
    return range[closestIndex];
  }
  return range[closestIndex - 1];
}
