import api from "api";
import constants from "constants/endpoints";

export const services = {
  setAutoRenewStatus: (orderId, status) =>
    api.post(`${constants.zuulOrderBox}orders/set-auto-renew`, {
      "attempt-auto-renew": false,
      "order-id": orderId,
      status
    }),
  deletePaymentMethod: token =>
    api.postBody(
      `${constants.zuulOrderBox}pg/delete-tokens`,
      new URLSearchParams({ token })
    )
};
