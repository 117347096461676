import {
  Amex,
  Card,
  DinersClub,
  Discover,
  JCB,
  MasterCard,
  Visa
} from "assets/icons/cards";
import { getSimpleNameFromProductKey } from "constants/index";
import { differenceInDays, endOfMonth, isPast } from "date-fns";
import { get } from "lodash";
import {
  addDaysToUNIXTimestamp,
  convertUNIXToDate,
  formatDate,
  parseStringAsBoolean
} from "../util";
import { paymentMandateOptions } from "routes/dashboard/routes/my-profile/payment-mandates-options";

export function didAutoRenewFail(order, autoRenewInfo) {
  let { expiresOn: actualExpiryDate, oboxOrderDetails: obData = {} } = order;
  let autoRenewAttemptDuration = parseInt(obData.autoRenewAttemptDuration, 10);
  let previousAutoRenewFailed = false;
  if (autoRenewInfo.status.toLowerCase() !== "success") {
    let calculateExpiryDate = formatDate(
      addDaysToUNIXTimestamp(
        autoRenewInfo.actionadded,
        autoRenewAttemptDuration
      )
    );
    previousAutoRenewFailed = calculateExpiryDate === actualExpiryDate;
  }
  return previousAutoRenewFailed;
}

export function willRenewNextTime(order) {
  let {
    isExpired,
    isExpiring,
    expiresIn = "",
    recurring,
    oboxOrderDetails: obData
  } = order;
  if (obData === undefined) {
    return false;
  }
  let autoRenewAttemptDuration = parseInt(obData.autoRenewAttemptDuration, 10);
  return (
    !recurring &&
    (isExpiring ? expiresIn <= autoRenewAttemptDuration : isExpired)
  );
}

export function isRenewalDisabled(order, renewalDetails) {
  let {
    isActive,
    productDetailsKey,
    oboxOrderDetails: obData = {},
    isOrderSuspendedUponExpiry = "false"
  } = order;

  if (parseStringAsBoolean(isOrderSuspendedUponExpiry)) {
    return false;
  }

  let conditions = [];
  conditions.push(!isActive);

  switch (getSimpleNameFromProductKey(productDetailsKey)) {
    case "domain": {
      let { raaVerificationStatus: status = "" } = obData;
      conditions.push(status.toLowerCase() === "suspended");

      let isDomainInRestorationPhase = get(
        renewalDetails,
        ["restoreDetails", "isInRestorationPhase"],
        false
      );
      conditions.push(isDomainInRestorationPhase);

      let { orderstatus = [] } = obData;
      let isLocked = orderstatus.includes("resellerlock");
      conditions.push(isLocked && process.env.FEATURE_ENDPOINT === "customer");
      break;
    }
    case "sslcert": {
      let { endtime } = obData;
      let remainingNumberOfDays = differenceInDays(
        convertUNIXToDate(endtime),
        new Date()
      );
      conditions.push(remainingNumberOfDays > 90);
      break;
    }
    default:
      break;
  }

  return conditions.some(Boolean);
}

const cardTypes = {
  VI: "visa",
  MC: "mastercard",
  AX: "americanexpress",
  JC: "japancreditbureau",
  TO: "maestro",
  DI: "diners",
  DC: "discover"
};

export function getCardIcon(cardType) {
  switch (cardType) {
    case cardTypes.VI:
      return Visa;
    case cardTypes.MC:
      return MasterCard;
    case cardTypes.AX:
      return Amex;
    case cardTypes.JC:
      return JCB;
    case cardTypes.DI:
      return DinersClub;
    case cardTypes.DC:
      return Discover;
    default:
      return Card;
  }
}

let supportsAutoRenew = (paymentGateways, gatewayType) =>
  paymentGateways.some(
    _ =>
      _.gatewaytype === gatewayType && parseStringAsBoolean(_.autorenewsupport)
  );

let hasNonINRPaymentMethod = (paymentGateways, gatewayType) =>
  paymentGateways.some(
    _ => _.gatewaytype === gatewayType && _.gatewaycurrencysymbol !== "INR"
  );

let getExpiryDate = (expiryMonth, expiryYear) =>
  `${String(expiryMonth).padStart(2, "0")}/${expiryYear}`;
export function serializePaymentMethods(
  paymentMethods,
  allowedPaymentGateways
) {
  const isPayPalAllowed = supportsAutoRenew(allowedPaymentGateways, "paypalec");
  const isPayUAllowed =
    process.env.FEATURE_ENDPOINT === "bigrock" &&
    supportsAutoRenew(allowedPaymentGateways, "payu.in");
  let isPayPWAllowed =
    supportsAutoRenew(allowedPaymentGateways, "pay.pw") &&
    (hasNonINRPaymentMethod(allowedPaymentGateways, "pay.pw") ||
      process.env.FEATURE_ENDPOINT === "bigrock");
  const {
    paypalec = [],
    "pay.pw": paypw = [],
    "payu.in": payu = []
  } = paymentMethods;
  const cards = isPayPWAllowed
    ? paypw.map(({ token, expiryMonth, expiryYear }) => ({
        cardType: get(cardTypes, [token.slice(0, 2)], ""),
        expiryDate: getExpiryDate(expiryMonth, expiryYear),
        hasExpired: isPast(endOfMonth(new Date(expiryYear, expiryMonth))),
        cardNumber: "xxxx xxxx xxxx " + token.slice(-4),
        token,
        paymentType: paymentMandateOptions.CARD
      }))
    : [];

  const accounts = isPayPalAllowed
    ? paypalec.map(accountDetails => ({
        ...accountDetails,
        paymentType: paymentMandateOptions.PAYPAL,
        expiryDate: getExpiryDate(
          accountDetails.expiryMonth,
          accountDetails.expiryYear
        ),
        hasExpired: isPast(
          endOfMonth(
            new Date(accountDetails.expiryYear, accountDetails.expiryMonth)
          )
        )
      }))
    : [];

  return {
    paypw: { cards, isAllowed: isPayPWAllowed },
    paypal: { accounts, isAllowed: isPayPalAllowed },
    payu: {
      accounts: isPayUAllowed
        ? payu.map(accountInfo => ({
            ...accountInfo,
            paymentType:
              accountInfo.tokenType === paymentMandateOptions.UPI
                ? paymentMandateOptions.UPI
                : paymentMandateOptions.NETBANKING,
            expiryDate: getExpiryDate(
              accountInfo.expiryMonth,
              accountInfo.expiryYear
            ),
            hasExpired: isPast(
              endOfMonth(
                new Date(accountInfo.expiryYear, accountInfo.expiryMonth)
              )
            )
          }))
        : [],
      isAllowed: isPayUAllowed
    }
  };
}
