import { Syringe } from "@faizaanceg/syringe";
import { SearchQueryBuilder } from "@superpanel/bodybuilder";
import { isSuccess } from "common/api-status/utils";
import { helpers } from "common/orders/helpers";
import { getOrderDetails } from "common/orders/sagas";
import { services as orderServices } from "common/orders/services";
import { CustomerManagementSdk } from "common/sdk/customer-management";
import { UserDetailsSdk } from "common/sdk/user-details";
import commonServices from "common/services";
import { addQueryParamsToUrl, openPage } from "common/util";
import constants from "constants/index";
import { mapEffect } from "map-effect";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { actionTypes, actions } from "./ducks";
import services from "./services";

const actionsToRefresh = [
  actionTypes.DELETE_CUSTOMER,
  actionTypes.SUSPEND_CUSTOMER,
  actionTypes.UNSUSPEND_CUSTOMER,
  actionTypes.SAVE_PIN,
  actionTypes.SAVE_PASSWORD,
  actionTypes.MODIFY_CUSTOMER_REQUEST
].map(isSuccess);

export const customerProfileSagas = [
  takeEvery(actionTypes.AUTO_LOGIN, doAutoLogin),
  takeEvery(actionTypes.MODIFY_CUSTOMER_REQUEST, modifyCustomer),
  takeEvery(actionTypes.SAVE_PIN, saveCustomerPin),
  takeEvery(actionsToRefresh, refreshCustomerDetails),
  takeEvery(actionTypes.SUSPEND_CUSTOMER, mapEffect(suspendCustomer)),
  takeEvery(actionTypes.UNSUSPEND_CUSTOMER, mapEffect(unSuspendCustomer)),
  takeEvery(actionTypes.SAVE_PASSWORD, savePassword),
  takeEvery(actionTypes.GET_CUSTOMER_DETAILS, getCustomerDetails),
  takeEvery(isSuccess(actionTypes.GET_CUSTOMER_DETAILS), initCustomerActions),
  takeEvery(actionTypes.GET_CUSTOMER_ACTIONS, onGetCustomerActions),
  takeEvery(actionTypes.DELETE_CUSTOMER, deleteCustomer)
];

function* doAutoLogin(action) {
  // Autologin is split into these tasks
  // 1. Opening a new window
  // 2. Get the token from /api
  // 3. Get the branded url to redirect to
  // 4. Construct url and redirect user there
  // If we fail at any step (mostly 2, 3) then we need to consider
  // the entire operation as failure and do clean up accordingly (close window etc.)

  let externalAccessManager = Syringe.inject("externalAccessManager");
  const autoLoginWindow = externalAccessManager.createWindow();

  try {
    let [{ TOKEN }, { brandingurl = "" }] = yield Promise.all([
      services.getAutoLoginToken(action.payload),
      UserDetailsSdk.reseller()
    ]);
    autoLoginWindow.location = addQueryParamsToUrl(
      `https://${brandingurl}${constants.webpro}customer/autologin`,
      { token: TOKEN }
    );
    yield put(actions.autoLoginSuccess());
  } catch (error) {
    autoLoginWindow.close();
    yield put(actions.autoLoginFailure(error));
  }
}

function* saveCustomerPin(action) {
  try {
    let { oldPin, newPin, customerid } = action.payload;
    yield CustomerManagementSdk.savePin(customerid, { oldPin, newPin });
    yield put(actions.savePinSuccess(action.payload));
  } catch (error) {
    yield put(actions.savePinFailure(error));
  }
}

function* suspendCustomer(action) {
  let { lockDomains, suspendOrders, reason } = action.params;
  if (lockDomains) {
    yield CustomerManagementSdk.orders.bulkLockDomains(
      [action.customerId],
      reason
    );
  }
  if (suspendOrders) {
    let activeOrdersQuery = SearchQueryBuilder()
      .filterOrder()
      .filter("term", "customerId", action.customerId)
      .notFilter("terms", "currentStatus", ["deleted", "suspended"])
      .build();
    let orders = yield call(services.searchElasticSearch, activeOrdersQuery);
    let orderIds = orders.map(_ => _._source.orderId);
    if (orderIds.length > 0) {
      yield CustomerManagementSdk.orders.bulkSuspend(orderIds, reason);
    }
  }
  yield CustomerManagementSdk.suspend(action.customerId, reason);
}

function* unSuspendCustomer(action) {
  yield CustomerManagementSdk.unsuspend(action.customerId);
  let { unlockDomain, unsuspendOrder } = action.params;
  if (unlockDomain) {
    yield CustomerManagementSdk.orders.bulkUnlockDomains([action.customerId]);
  }
  if (unsuspendOrder) {
    let suspendedOrdersQuery = SearchQueryBuilder()
      .filterOrder()
      .filter("term", "customerId", action.customerId)
      .filter("term", "currentStatus", "suspended")
      .build();
    let orders = yield call(services.searchElasticSearch, suspendedOrdersQuery);
    let orderIds = orders.map(_ => _._source.orderId);
    if (orderIds.length > 0) {
      yield CustomerManagementSdk.orders.bulkUnsuspend(orderIds);
    }
  }
}

function* savePassword(action) {
  try {
    const { customerid, newPassword } = action;
    yield CustomerManagementSdk.savePassword(customerid, newPassword);
    yield put(actions.savePasswordSucccess(action.newPassword));
  } catch (error) {
    yield put(actions.savePasswordFailure(error));
  }
}

function* getCustomerDetails(action) {
  const { customerId } = action;
  try {
    let [customer, { orderList: orders }] = yield all([
      call(commonServices.getCustomerDetails, customerId),
      call(orderServices.getOrders, 0, {
        "page-no": 1,
        "no-of-records": 50,
        "customer-id": customerId
      })
    ]);
    yield all(
      orders
        .map(order => helpers.formatOrderList(order))
        .map(order => fork(getOrderDetails, order))
    );
    yield put(actions.getCustomerDetailsSuccess(customer, orders));
  } catch (error) {
    yield put(actions.getCustomerDetailsFailure(error));
  }
}

function* initCustomerActions(action) {
  try {
    const {
      payload: { orders }
    } = action;
    if (orders.length > 0) {
      yield put(actions.getCustomerActions(orders.map(order => order.orderid)));
    }
  } catch (error) {
    yield put(actions.getCustomerActionsFailure(error));
  }
}

function* onGetCustomerActions({ orderIds }) {
  try {
    let params = {
      "order-id": orderIds,
      "page-no": 1,
      "no-of-records": 10,
      "order-by": "actionadded desc"
    };
    let customerActions = yield orderServices.getCurrentActions(params);
    if (Number(customerActions.recsindb) === 0) {
      customerActions = yield orderServices.getArchivedActions(params);
    }
    yield put(actions.getCustomerActionsSuccess(customerActions));
  } catch (error) {
    yield put(actions.getCustomerActionsFailure(error));
  }
}

function* deleteCustomer(action) {
  try {
    yield CustomerManagementSdk.delete(action.customerId);
    yield put(actions.deleteCustomerSuccess());
    openPage("/customers");
  } catch (error) {
    yield put(actions.deleteCustomerFailure(error));
  }
}

function* modifyCustomer(action) {
  try {
    yield CustomerManagementSdk.update(action.customerid, action.values);
    yield put(actions.modifyCustomerDetailsSuccess());
  } catch (error) {
    yield put(actions.modifyCustomerDetailsFailure());
  }
}

function* refreshCustomerDetails() {
  const customerProfileState = state => state.customerManagement.profile;
  let customerProfile = yield select(customerProfileState);
  const { customer: { customerid: customerId } = {} } = customerProfile;
  if (customerId) {
    yield put(actions.getCustomerDetails({ customerId }));
  }
}
