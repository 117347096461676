import { isFailure, isSuccess } from "common/api-status/utils";
import { actionTypes as autoRenewalActionTypes } from "common/auto-renewal/ducks";
import { actionTypes as customerCreateTypes } from "common/customer-create/ducks";
import { actionTypes as customerRequestActions } from "common/customer-requests/ducks";
import { actionTypes as inlineNotificationActionTypes } from "common/inline-notifications/ducks";
import { actionTypes as privacyPolicyActionTypes } from "common/legal-agreements/ducks";
import { actionTypes as logoActionTypes } from "common/logo/ducks";
import {
  actionTypes as orderActionTypes,
  renewActionTypes
} from "common/orders/ducks";
import { actionTypes as transactionActionTypes } from "common/pending-payment-buttons/ducks";
import { actionTypes as sessionManagementActionTypes } from "common/session-management/ducks";
import { reduceObject } from "common/util";
import { actionTypes as domainNotificationTypes } from "notifications/manage-domain/ducks";
import { actionTypes as changeDomainActionTypes } from "widget-models/change-domain/ducks";
import { actionTypes as childNameServersActionTypes } from "widget-models/childname-server/ducks";
import { actionTypes as widgetDomainForwardingActions } from "widget-models/domain-forwarding/ducks";
import { actionTypes as widgetResellerLockActionTypes } from "widget-models/domain-lock/ducks";
import { actionTypes as emailPowerBarActionTypes } from "widget-models/email-address/ducks";
import { actionTypes as gsuiteActionTypes } from "widget-models/gsuite-setup/ducks";
import { actionTypes as manageAddonsActionTypes } from "widget-models/manage-addons/ducks";
import { actionTypes as manageBackupActionTypes } from "widget-models/manage-backup/ducks";
import { actionTypes as manageBlocksActionTypes } from "widget-models/manage-blocks/ducks";
import { actionTypes as manageDNSSECActionTypes } from "widget-models/manage-dns-sec/ducks";
import {
  actionTypes as dnsActionTypes,
  actionTypes as widgetDNSNameServersActions,
  actionTypes as widgetPDNSNameServersActions
} from "widget-models/manage-dns/ducks";
import { actionTypes as manageEmailActionTypes } from "widget-models/manage-email/ducks";
import { actionTypes as manageIPActionTypes } from "widget-models/manage-ip/ducks";
import { actionTypes as managePanelActionTypes } from "widget-models/manage-panel/ducks";
import { actionTypes as manageSSLActionTypes } from "widget-models/manage-ssl/ducks";
import { actionTypes as manageStorageActionTypes } from "widget-models/manage-storage/ducks";
import { actionTypes as manageVolumesActionTypes } from "widget-models/manage-volumes/ducks";
import { actionTypes as manageWHMCSActionTypes } from "widget-models/manage-whmcs/ducks";
import { actionTypes as managedServicesActionTypes } from "widget-models/managed-service-addon/ducks";
import { actionTypes as modifyTransferSecretActionTypes } from "widget-models/modify-transfer-secret/ducks";
import { actionTypes as privacyProtectionActionTypes } from "widget-models/privacy/ducks";
import { actionTypes as restoreDomainActionTypes } from "widget-models/restore-domain/ducks";
import { actionTypes as serverPowerBarActionTypes } from "widget-models/server-power-bar/ducks";
import { actionTypes as shellAccessActionTypes } from "widget-models/shell-access/ducks";
import { actionTypes as theftProtectionActionTypes } from "widget-models/theft-protection/ducks";
import { actionTypes as viewSSLActionTypes } from "widget-models/view-certificate/ducks";
import { COPY_TRANSFER_SECRET } from "widgets/common/ducks";
import { actionTypes as widgetContactDetailsActions } from "widgets/domain-widgets/contact-details/ducks";

export const commonMessageMap = {
  [COPY_TRANSFER_SECRET]: "",
  [inlineNotificationActionTypes.SHOW]: ""
};

export const successMessageMap = [
  {
    defaultMessage: "The logo has been successfully updated",
    triggeredBy: logoActionTypes.UPLOAD_LOGO
  },
  {
    defaultMessage: "Order deleted successfully",
    triggeredBy: orderActionTypes.DELETE_ORDER
  },
  {
    defaultMessage: "Order Request deleted successfully",
    triggeredBy: orderActionTypes.DELETE_CART_ITEM
  },
  {
    defaultMessage: "Order suspended successfully",
    triggeredBy: orderActionTypes.SUSPEND_ORDER
  },
  {
    defaultMessage: "Order unsuspended successfully",
    triggeredBy: orderActionTypes.UNSUSPEND_ORDER
  },
  {
    defaultMessage: "Email address deleted successfully",
    triggeredBy: emailPowerBarActionTypes.DELETE_EMAIL_ADDRESS
  },
  {
    defaultMessage: "Restoration of domain was successful",
    triggeredBy: restoreDomainActionTypes.RESTORE_DOMAIN
  },
  {
    defaultMessage: "Customer has been successfully created",
    triggeredBy: customerCreateTypes.CREATE_CUSTOMER
  },
  { triggeredBy: orderActionTypes.MOVE_ORDER_REQUEST },
  {
    triggeredBy: renewActionTypes.RENEWAL_REQUEST
  },
  {
    defaultMessage: "Onhold order execution succeeded",
    triggeredBy: orderActionTypes.EXECUTE_ONHOLD_ORDERS_REQUEST
  },
  {
    defaultMessage: "Pay outstanding invoice succeeded",
    triggeredBy: orderActionTypes.PAY_OUTSTANDING_INVOICES_REQUEST
  },
  {
    defaultMessage: "Email Accounts have been successfully purchased.",
    triggeredBy: manageEmailActionTypes.PURCHASE_EMAIL_ACCOUNTS
  },
  {
    defaultMessage: "Email Accounts have been Successfully deleted.",
    triggeredBy: manageEmailActionTypes.DELETE_EMAIL_ACCOUNTS
  },
  {
    defaultMessage:
      "Name servers have been updated. It normally takes 24 - 72 hours to propogate",
    triggeredBy: widgetDNSNameServersActions.SAVE_NAME_SERVERS
  },
  {
    defaultMessage: "Transfer code has been updated.",
    triggeredBy: modifyTransferSecretActionTypes.MODIFY_TRANSFER_SECRET_REQUEST
  },
  {
    defaultMessage: "Privacy protection has been purchased",
    triggeredBy: privacyProtectionActionTypes.PURCHASE_PRIVACY_PROTECTION
  },
  {
    defaultMessage: "Privacy protection has been enabled on your domain",
    triggeredBy: privacyProtectionActionTypes.ENABLE_PRIVACY_PROTECTION
  },
  {
    defaultMessage: "Privacy protection has been disabled on your domain",
    triggeredBy: privacyProtectionActionTypes.DISABLE_PRIVACY_PROTECTION
  },
  {
    defaultMessage: "GDPR protection has been enabled on your domain",
    triggeredBy: privacyProtectionActionTypes.ENABLE_FREE_PRIVACY_PROTECTION
  },
  {
    defaultMessage:
      "An Email to disable GDPR protection (Free) has been sent to the Registrant. This email is valid for 5 days",
    triggeredBy: privacyProtectionActionTypes.DISABLE_FREE_PRIVACY_PROTECTION
  },
  {
    defaultMessage: "Mail has been resent",
    triggeredBy: domainNotificationTypes.RESEND_DISABLE_PRIVACY
  },
  {
    defaultMessage: "Transfer Auth code has been updated.",
    triggeredBy: domainNotificationTypes.SET_UPDATED_AUTH_CODE
  },
  {
    defaultMessage: "Request to disable GDPR protection has been cancelled",
    triggeredBy: domainNotificationTypes.CANCEL_DISABLE_PRIVACY
  },
  {
    defaultMessage:
      "Child name servers have been updated. It normally takes 24 - 72 hours to propogate",
    triggeredBy: childNameServersActionTypes.UPDATE_CHILD_NAMESERVERS
  },
  {
    defaultMessage: "Theft protection has been enabled",
    triggeredBy: theftProtectionActionTypes.ENABLE_THEFT_PROTECTION
  },
  {
    defaultMessage: "Theft protection has been disabled",
    triggeredBy: theftProtectionActionTypes.DISABLE_THEFT_PROTECTION
  },
  {
    defaultMessage: "The domain has been locked",
    triggeredBy: widgetResellerLockActionTypes.LOCK
  },
  {
    defaultMessage: "The domain has been unlocked",
    triggeredBy: widgetResellerLockActionTypes.UNLOCK
  },
  {
    defaultMessage: "The domain forwarding details have been updated",
    triggeredBy: widgetDomainForwardingActions.SAVE_DOMAIN_FORWARDING_DETAILS
  },
  {
    defaultMessage: "The request has been placed successfully",
    triggeredBy: customerRequestActions.ADD_CUSTOMER_NOTIFICATION_REQUEST
  },
  {
    defaultMessage: "Your Google Workspace Admin Account has been setup",
    triggeredBy: gsuiteActionTypes.GSUITE_ADMIN_SETUP_REQUEST
  },
  {
    defaultMessage: "1 Dedicated IP has been successfully purchased",
    triggeredBy: manageIPActionTypes.BUY_DEDICATED_IP
  },
  {
    defaultMessage: "IP has been deleted successfully",
    triggeredBy: manageIPActionTypes.DELETE_DEDICATED_IP
  },
  {
    defaultMessage: "Bought addon successfully",
    triggeredBy: manageAddonsActionTypes.BUY_ADDONS
  },
  {
    defaultMessage: "Addon deleted successfully",
    triggeredBy: manageAddonsActionTypes.DELETE_ADDON
  },
  {
    defaultMessage: "Password changed successfully",
    triggeredBy: managePanelActionTypes.PANEL_PASSWORD_CHANGE
  },
  {
    defaultMessage: "Domain changed successfully",
    triggeredBy: changeDomainActionTypes.CHANGE_DOMAIN
  },
  {
    defaultMessage: "Shell access enabled",
    triggeredBy: shellAccessActionTypes.ENABLE_SHELL_ACCESS
  },
  {
    defaultMessage: "Shell access disabled",
    triggeredBy: shellAccessActionTypes.DISABLE_SHELL_ACCESS
  },
  {
    defaultMessage: "Verification method successfully changed",
    triggeredBy: manageSSLActionTypes.CHANGE_VERIFICATION_EMAIL_SSL
  },
  {
    defaultMessage: "Verification email sent successfully.",
    triggeredBy: domainNotificationTypes.RESEND_DOMAIN_CONTACT_VERIFICATION
  },
  {
    defaultMessage: "Your changes have been saved",
    triggeredBy: privacyPolicyActionTypes.UPDATE_PRIVACY_POLICY
  },
  {
    defaultMessage: "Server action has been performed successfully",
    triggeredBy: serverPowerBarActionTypes.CHANGE_SERVER_STATE
  },
  {
    defaultMessage: "Email sent successfully.",
    triggeredBy: domainNotificationTypes.RESEND_IRTP_AUTHORIZATION
  },
  {
    triggeredBy: dnsActionTypes.EDIT_DNS_RECORD
  },
  {
    triggeredBy: dnsActionTypes.DELETE_DNS_RECORD
  },
  {
    triggeredBy: dnsActionTypes.ADD_NEW_RECORD
  },
  {
    triggeredBy: autoRenewalActionTypes.SET_AUTO_RENEWAL
  },
  {
    triggeredBy: widgetContactDetailsActions.UPDATE_CONTACT_DETAILS
  },
  {
    defaultMessage: "Verification mail has been sent",
    triggeredBy: orderActionTypes.RESEND_VERIFICATION_MAIL
  },
  {
    defaultMessage: "The blocks have been purchased successfully",
    triggeredBy: manageBlocksActionTypes.BUY_BLOCKS
  },
  {
    defaultMessage: "The blocks have been deleted successfully",
    triggeredBy: manageBlocksActionTypes.DELETE_BLOCKS
  },
  {
    defaultMessage: "Block storage has been purchased successfully",
    triggeredBy: manageVolumesActionTypes.BUY_VOLUME
  },
  {
    defaultMessage: "Block storage has been upgraded successfully",
    triggeredBy: manageVolumesActionTypes.UPGRADE_VOLUME
  },
  {
    defaultMessage: "Premium DNS has been purchased successfully",
    triggeredBy: widgetPDNSNameServersActions.PURCHASE_PDNS
  },
  {
    defaultMessage: "Block storage has been deleted successfully",
    triggeredBy: manageVolumesActionTypes.DELETE_VOLUME
  },
  {
    defaultMessage: "ManagedServices has been purchased successfully",
    triggeredBy: managedServicesActionTypes.PURCHASE_MANAGED_SERVICE
  },
  {
    defaultMessage: "Storage has been purchased successfully",
    triggeredBy: manageStorageActionTypes.PURCHASE_STORAGE
  },
  {
    defaultMessage: "Backup has been purchased successfully",
    triggeredBy: manageBackupActionTypes.BUY_BACKUP
  },
  {
    triggeredBy: manageBackupActionTypes.INSTALL_AGENT,
    defaultMessage: "Install agent started successfully"
  },
  { triggeredBy: manageBackupActionTypes.REINSTALL_BACKUP_AGENT },
  { triggeredBy: manageBackupActionTypes.RENEW_BACKUP },
  {
    defaultMessage: "WHMCS License Key has been successfully deleted",
    triggeredBy: manageWHMCSActionTypes.DELETE_WHMCS_KEY
  },
  {
    defaultMessage:
      "DNSSEC entry deleted successfully. Changes will reflect in some time",
    triggeredBy: manageDNSSECActionTypes.DELETE_DNS_SEC
  },
  {
    defaultMessage: "Order request has been successfully deleted",
    triggeredBy: transactionActionTypes.CANCEL_PENDING_INVOICE
  }
]
  .map(message => ({
    [isSuccess(message.triggeredBy)]: message.defaultMessage
  }))
  .reduce(reduceObject, {});

export const failureMessageMap = [
  {
    defaultMessage: "Something went wrong. Transfer Auth code update failed.",
    triggeredBy: domainNotificationTypes.SET_UPDATED_AUTH_CODE
  },
  {
    defaultMessage: "Something went wrong. Please contact our support team.",
    triggeredBy: managedServicesActionTypes.PURCHASE_MANAGED_SERVICE
  },
  {
    defaultMessage: "Something went wrong. Please contact our support team.",
    triggeredBy: managedServicesActionTypes.DELETE_MANAGED_SERVICE
  },
  {
    triggeredBy: emailPowerBarActionTypes.DELETE_EMAIL_ADDRESS
  },
  {
    defaultMessage: "Something went wrong. Please try again after sometime",
    triggeredBy: sessionManagementActionTypes.GO_TO_CONTROL_PANEL
  },
  {
    defaultMessage: "Customer could not be created",
    triggeredBy: customerCreateTypes.CREATE_CUSTOMER
  },
  {
    defaultMessage: "There was some error while placing the request",
    triggeredBy: customerRequestActions.ADD_CUSTOMER_NOTIFICATION_REQUEST
  },
  {
    defaultMessage: "Renewal of your order failed",
    triggeredBy: renewActionTypes.RENEWAL_REQUEST
  },
  {
    defaultMessage: "Onhold order execution failed",
    triggeredBy: orderActionTypes.EXECUTE_ONHOLD_ORDERS_REQUEST
  },
  {
    defaultMessage: "Pay outstanding invoice failed",
    triggeredBy: orderActionTypes.PAY_OUTSTANDING_INVOICES_REQUEST
  },
  {
    defaultMessage: "Something went wrong. Please try again after sometime",
    triggeredBy: orderActionTypes.DELETE_CART_ITEM
  },
  {
    defaultMessage: "There was some error while buying addon",
    triggeredBy: manageAddonsActionTypes.BUY_ADDONS
  },
  {
    defaultMessage: "Something went wrong. Please Resend mail again.",
    triggeredBy: domainNotificationTypes.RESEND_IRTP_AUTHORIZATION
  },
  {
    defaultMessage: "Something went wrong. Please try again after sometime",
    triggeredBy: serverPowerBarActionTypes.CHANGE_SERVER_STATE
  },
  {
    triggeredBy: dnsActionTypes.EDIT_DNS_RECORD
  },
  {
    triggeredBy: dnsActionTypes.ADD_NEW_RECORD
  },
  {
    triggeredBy: dnsActionTypes.DELETE_DNS_RECORD
  },
  {
    defaultMessage: "Your Certificate has not been enrolled",
    triggeredBy: viewSSLActionTypes.GET_CERTIFICATE_DETAILS
  },
  {
    defaultMessage: "There was some error while deleting the license key",
    triggeredBy: manageWHMCSActionTypes.DELETE_WHMCS_KEY
  },
  { triggeredBy: manageSSLActionTypes.CHANGE_VERIFICATION_EMAIL_SSL },
  { triggeredBy: manageStorageActionTypes.PURCHASE_STORAGE },
  { triggeredBy: manageBackupActionTypes.BUY_BACKUP },
  {
    triggeredBy: manageBackupActionTypes.INSTALL_AGENT,
    defaultMessage:
      "Something went wrong while installing agent. Please check again after sometime"
  },
  {
    triggeredBy: manageBackupActionTypes.REINSTALL_BACKUP_AGENT,
    defaultMessage: "Something went wrong. Please check again after sometime"
  },
  {
    triggeredBy: manageBackupActionTypes.RENEW_BACKUP,
    defaultMessage: "Something went wrong. Please check again after sometime"
  },
  {
    defaultMessage: "Something went wrong. Please try again after sometime",
    triggeredBy: widgetPDNSNameServersActions.PURCHASE_PDNS
  },
  {
    defaultMessage: "There was some error while deleting the order request",
    triggeredBy: transactionActionTypes.CANCEL_PENDING_INVOICE
  }
]
  .map(message => ({
    [isFailure(message.triggeredBy)]: message.defaultMessage
  }))
  .reduce(reduceObject, {});
