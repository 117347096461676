import { Syringe } from "@faizaanceg/syringe";
import { mapEffect } from "map-effect";
import { put, takeEvery } from "redux-saga/effects";
import { actionTypes, actions } from "./ducks";
import { services } from "./services";

export default [
  takeEvery(actionTypes.CHANGE_SERVER_STATE, onChangeServerState),
  takeEvery(actionTypes.GET_VNC_ACCESS, mapEffect(onGetVNCAccess))
];

function* onChangeServerState(action) {
  try {
    const serverStateResponse = yield services.changeServerState(action);
    if (serverStateResponse.status.toLowerCase() === "success") {
      yield put(
        actions.changeServerStateSuccess(serverStateResponse, action.meta)
      );
    } else {
      throw new Error("Server state was not changed successfully");
    }
  } catch (error) {
    yield put(actions.changeServerStateFailure(error, action.meta.orderid));
  }
}

async function onGetVNCAccess(action) {
  let externalAccessManager = Syringe.inject("externalAccessManager");
  const vncWindow = externalAccessManager.createWindow();
  const { url } = await services.getVNCAccess(action.meta);
  vncWindow.location.replace(url);
}
