import * as React from "react";

function ForwardArrow(props) {
  return (
    <svg width="6px" height="11px" viewBox="0 0 6 11" {...props}>
      <path
        transform="translate(-174 -735) translate(0 71)"
        stroke="#788194"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M174.5 664.5L179.5 669.5 174.5 674.5"
      />
    </svg>
  );
}

export default ForwardArrow;
