import { BasePricingStrategy } from "./generic";
import { GenericQuery, PricingInfo, PricingType } from "./index";

export class GsuiteStrategy extends BasePricingStrategy {
  getAccountPrice(
    emailPricing: Record<PricingType, Record<string | number, number>>,
    pricingKey: PricingType,
    { discount, remainingNumberOfMonths, selectedTenure = 0 }: PricingInfo
  ) {
    let basePrice =
      emailPricing?.[pricingKey]?.[selectedTenure] ??
      emailPricing?.[pricingKey]?.[
        remainingNumberOfMonths >= 11.9 ? "12" : "1"
      ] ??
      0;
    switch (discount?.type) {
      case "PERCENT":
        return basePrice * (1 - discount.value / 100);
      default:
        return basePrice;
    }
  }
  getPrice(
    modifiedRenewalDetails: GenericQuery,
    renewalData: GenericQuery,
    selectedTenure: number
  ) {
    let addonDetails =
      modifiedRenewalDetails?.addonDetails ?? renewalData.addonDetails;
    let costBreakdown =
      addonDetails?.addons?.discount_breakdown?.costBreakdown ?? {};
    let { price, accounts } = super.getDiscountBreakdown({
      ...costBreakdown,
      selectedTenure
    });
    return {
      resellerPrice:
        (price.discount * accounts.discount +
          price.regular * accounts.regular) *
        selectedTenure,
      customerPrice: parseFloat(
        modifiedRenewalDetails?.customerPrice ??
          renewalData?.customerPrice ??
          renewalData?.customerPricing?.[selectedTenure] * selectedTenure
      )
    };
  }
  getRenewPriceWithAddons(
    modifiedRenewalDetails: GenericQuery,
    renewalData: GenericQuery,
    selectedTenure: number
  ) {
    return {
      resellerAddOnPrice: this.getPrice(
        modifiedRenewalDetails,
        renewalData,
        selectedTenure
      )?.resellerPrice,
      customerAddOnPrice: this.getPrice(
        modifiedRenewalDetails,
        renewalData,
        selectedTenure
      )?.customerPrice
    };
  }
}
