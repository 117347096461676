import commonServices from "common/services";
import { mapEffect } from "map-effect";
import { takeEvery } from "redux-saga/effects";
import { actionTypes } from "./ducks";
import { services } from "./services";

export const domainTransferInSagas = [
  takeEvery(actionTypes.GET_TRANSFER_DETAILS, mapEffect(getTransferDetails))
];

function* getTransferDetails(action) {
  let productKey = "";
  let contact = {
    admin: undefined,
    billing: undefined,
    tech: undefined,
    registrant: undefined
  };
  let transferCost = 0;
  let { customerId, domainName } = action.payload;
  const {
    Contact: { admin, billing, tech, registrant }
  } = yield services.getDefaultContactForACustomer({
    "customer-id": customerId,
    type: "Contact"
  });
  contact = { admin, billing, tech, registrant };
  let { productKey: productkey } = yield services.getProductKeyForADomain(
    domainName
  );
  if (productkey) {
    const response = yield commonServices.getPricingForProductKey(productkey);
    const addtransferdomain = response?.[productkey].addtransferdomain;

    if (addtransferdomain) {
      transferCost = +addtransferdomain["1"];
    }
    productKey = productkey;
  }
  return { transferCost, contact, productKey };
}
