/**
 *
 * @param {string} payload
 * @returns {Promise<string>}
 */
export async function createHash(payload) {
  if (crypto?.subtle) {
    let hashBuffer = await crypto.subtle
      .digest("SHA-1", new TextEncoder().encode(payload))
      .then(buf =>
        Array.from(new Uint8Array(buf))
          .map(b => b.toString(16).padStart(2, "0"))
          .join("")
      )
      .catch(() => null);
    if (hashBuffer) {
      return hashBuffer;
    }
  }
  return fetch(`/service/sha1-hash`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ payload })
  })
    .then(response => response.json())
    .then(_ => _.payload)
    .catch(() => payload);
}
