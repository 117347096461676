import { isSuccess } from "common/api-status/utils";
import { get } from "lodash";
import UpdateActionTypes from "common/orders/constants/UpdateActionTypes";

// Some actions are put into the queue on Obox when there is a pending action on the order
// 'actionstatus' !== "Success" in the response implies that the action is the queue
const pendingActionsMiddleware = store => next => action => {
  let response = get(action, "response", get(action, "value", {}));
  if (UpdateActionTypes.map(isSuccess).includes(action.type)) {
    if (get(response, ["actionstatus"], "Success") !== "Success") {
      const pendingActionNotificationInfo = {
        notificationType: "pending",
        defaultMessage: get(
          response,
          "actionstatusdesc",
          "Your action is pending"
        )
      };
      return next({ ...action, ...pendingActionNotificationInfo });
    }
  }
  return next(action);
};

export default pendingActionsMiddleware;
