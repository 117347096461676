import DomainLock from "./domain-lock/ducks";
import EmailAddresses from "./email-address/ducks";
import ManageAddons from "./manage-addons/ducks";
import ManageBackup from "./manage-backup/ducks";
import ManageDNS from "./manage-dns/ducks";
import ManageEmail from "./manage-email/ducks";
import ManageIP from "./manage-ip/ducks";
import ManageTitan from "./manage-titan/ducks";
import ManageVolumes from "./manage-volumes/ducks";
import ManageWHMCS from "./manage-whmcs/ducks";
import ManagedServiceAddons from "./managed-service-addon/ducks";
import ManageServerPowerBar from "./server-power-bar/ducks";
export default [
  DomainLock,
  EmailAddresses,
  ManageAddons,
  ManageDNS,
  ManageEmail,
  ManageIP,
  ManageServerPowerBar,
  ManageVolumes,
  ManageWHMCS,
  ManagedServiceAddons,
  ManageBackup,
  ManageTitan
];
