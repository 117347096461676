import { isSuccess } from "common/api-status/utils";
import { getProrataCost } from "common/util";
import { helpers } from "widget-models/manage-addons/ducks";

export const actionTypes = {
  SHOW_BUY_MANAGED_SERVICE_MODAL:
    "[Managed Service Addon] Show Buy Managed Service modal",
  PURCHASE_MANAGED_SERVICE: "[Managed Service Addon] Purchase Managed Service",
  DELETE_MANAGED_SERVICE: "[Managed Service Delete] Delete Managed Service",

  GET_PLAN_DETAILS_MANAGED_SERVICES_ADDON:
    "[Managed Service Addon] Get plan details of Managed Services addons",
  GET_PRICING_DETAILS_MANAGED_SERVICES_ADDON:
    "[Managed Service Addon] Get pricing details Managed Services addons"
};
export const initialState = {
  managedServiceAddon: {
    isManagedServiceAvailable: false,
    addonDetails: {}
  }
};

export const actions = {
  openBuyMananagedService: ({ orderid, supportTitle }) => ({
    type: actionTypes.SHOW_BUY_MANAGED_SERVICE_MODAL,
    supportTitle,
    orderid
  }),
  openDeleteManagedService: ({
    orderid,
    details: {
      installed_os: { addons = [] }
    }
  }) => {
    let itemToBeDeleted = addons.find(
      addon => addon.addon_name === "managed_services"
    );
    return {
      type: actionTypes.DELETE_MANAGED_SERVICE,
      orderid,
      itemToBeDeleted
    };
  },
  getDetailsOfManagedServicesAddon: ({ meta, hierarchy }) => ({
    type: actionTypes.GET_PLAN_DETAILS_MANAGED_SERVICES_ADDON,
    payload: { ...hierarchy, parent: meta.orderid, ...meta }
  })
};

export default (state, action) => {
  switch (action.type) {
    case isSuccess(actionTypes.GET_PLAN_DETAILS_MANAGED_SERVICES_ADDON): {
      const {
        effectResponse: {
          planDetails,
          orderDetails: {
            planid,
            installed_os: { os_name = "" } = {},
            productkey
          }
        },
        payload: { parent, child }
      } = action;
      const {
        [productkey]: {
          [planid]: { supported_os = [], ...planLimits } = {}
        } = {}
      } = planDetails;
      let isManagedServiceAvailable = false;
      const addonDetails = {};

      supported_os
        .filter(
          supported_os_details => supported_os_details.os_name === os_name
        )
        .forEach(supported_os_details => {
          supported_os_details.addons
            .filter(
              ({ addon_name, addonName = addon_name }) =>
                addonName === "managed_services"
            )
            .forEach(
              ({
                addon_name,
                addonName: addOnName = addon_name,
                supported_quantity: maxLimit = helpers.getMaxLimit(
                  addOnName,
                  planLimits,
                  productkey
                ),
                ...addon
              }) => {
                addonDetails[addOnName] = {
                  addOnName,
                  addOnDisplayText: helpers.getAddonName(productkey, addOnName),
                  maxLimit,
                  ...addon
                };
              }
            );
        });
      isManagedServiceAvailable = addonDetails.hasOwnProperty(
        "managed_services"
      );
      return {
        ...state,
        [parent]: {
          ...state[parent],
          [child]: {
            ...state[parent][child],
            addonDetails,
            isManagedServiceAvailable
          }
        }
      };
    }

    case isSuccess(actionTypes.GET_PRICING_DETAILS_MANAGED_SERVICES_ADDON): {
      const {
        payload: { parent, child, productkey, expiryTimestamp } = {}
      } = action;
      const { [productkey]: { addons: addonsPricing = {} } = {} } =
        action.effectResponse.reseller_pricing ?? action.effectResponse;

      const proratedAddonsPricing = Object.fromEntries(
        Object.entries(addonsPricing).map(([addonName, addonPrice]) => [
          addonName,
          getProrataCost(addonPrice, expiryTimestamp)
        ])
      );

      return {
        ...state,
        [parent]: {
          ...state[parent],
          [child]: {
            ...state[parent][child],
            addonsPricing: proratedAddonsPricing
          }
        }
      };
    }

    default:
      return state;
  }
};

export const parsers = {
  parseManagedServicesAddonDetails: (response, { productkey }) => {
    const addonsList =
      response?.installed_os?.addons
        ?.filter(
          ({ addon_name, name = addon_name }) => name === "managed_services"
        )
        .map(
          ({
            addon_name,
            name = addon_name,
            addon_id,
            addOnId = addon_id,
            addOnID = addOnId,
            is_paid: showDelete = false
          }) => ({
            addOnName: name,
            addOnID,
            name: helpers.getAddonName(productkey, name),
            showDelete
          })
        ) ?? [];
    return { addonsList };
  }
};
