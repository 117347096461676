import {
  actions as AnalyticsActions,
  categories as AnalyticsCategories,
  labels as AnalyticsLabels
} from "analytics/constants";
import { isFailure, isSuccess } from "common/api-status/utils";

export const actionTypes = {
  SHOW_MODAL_ADD: "[Add DNS SEC] Show modal",
  SHOW_MODAL_MANAGE: "[Manage DNS SEC] Show modal",
  SHOW_MODAL_DELETE: "[Delete DNS SEC] Show modal",
  ADD_DNS_SEC: "[DNS SEC] Add",
  DELETE_DNS_SEC: "[DNS SEC] Delete"
};

export const actions = {
  showModalAdd: meta => ({
    type: actionTypes.SHOW_MODAL_ADD,
    ...meta
  }),
  showModalManage: meta => ({
    type: actionTypes.SHOW_MODAL_MANAGE,
    ...meta
  }),
  showModalDelete: payload => ({
    type: actionTypes.SHOW_MODAL_DELETE,
    ...payload
  }),
  deleteDNSSEC(orderid, params) {
    return {
      type: actionTypes.DELETE_DNS_SEC,
      orderid,
      params
    };
  },
  addDNSSEC(orderid, params) {
    return {
      type: actionTypes.ADD_DNS_SEC,
      orderid,
      params
    };
  }
};

export const initialState = {
  dnsSecList: []
};

export const parsers = {
  defaultDNSSecParser: payload => ({
    dnsSecList: payload?.dnssec
  })
};
