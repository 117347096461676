import { parseCookies } from "common/util";
import endpoints from "constants/endpoints";
import React, { PropsWithChildren } from "react";
import storage from "@faizaanceg/pandora";

type BillingAgreement = PropsWithChildren<{
  onSubmit?: () => void;
  gatewayType: "paypalec" | "payu.in";
}>;

export function CreateBillingAgreementForm({
  onSubmit = () => {},
  gatewayType,
  children
}: BillingAgreement) {
  let { csrf } = parseCookies(document.cookie);
  return (
    <form
      onSubmit={() => {
        onSubmit();
        storage.set("billing-agreement-checkpoint", window.location.pathname);
        return true;
      }}
      action={`${endpoints.billing}billing-agreement`}
      method="POST"
    >
      <input
        type="hidden"
        name="REDIRECT_URL"
        value={`${window.location.origin}${endpoints.webhooks}integrations/complete/billing-agreement-flow`}
        readOnly
      />
      <input type="hidden" name="gatewayType" value={gatewayType} readOnly />
      <input type="hidden" name="force-cp" value="true" readOnly />
      {csrf ? (
        <input type="hidden" name="X-CSRF-TOKEN" value={csrf} readOnly />
      ) : null}
      {children}
    </form>
  );
}
