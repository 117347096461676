import { any, array, bool, func, object, string, symbol } from "prop-types";
import { reduceObject } from "common/util";

const valueToPropType = value => {
  switch (typeof value) {
    case "boolean":
      return bool;
    case "function":
      return func;
    case "object":
      return Array.isArray(value) ? array : object;
    case "string":
      return string;
    case "symbol":
      return symbol;
    default:
      return any;
  }
};

export const convertToPropTypes = (object = {}) =>
  Object.keys(object)
    .map(key => ({
      [key]: valueToPropType(object[key])
    }))
    .reduce(reduceObject, {});
