import React from "react";
import { Modal } from "react-bootstrap";
import AddCardIFrame from "../../add-card-iframe";
import { withTranslation } from "react-i18next";

class AddPaymentMethod extends React.Component {
  render() {
    const { paymentMethodDetails, ...props } = this.props;

    return (
      <Modal
        show={props.show}
        dialogClassName="add-card-modal secure-modal"
        keyboard
        onHide={props.onHide}
      >
        <Modal.Body>
          <div className="modal-close" onClick={props.onHide} />
          <div className="add-card-top">
            <div className="modal-title">
              {this.props.t("TXNRC_ADD_CARD", "ADD A CREDIT/DEBIT CARD")}
            </div>
            <div className="add-card-modal-text">
              {this.props.t(
                "PROFILE_SECURE_CARD_MESSAGE",
                "We will securely save your card and use it to auto-renew your orders. Incase you have funds in your wallet, we will use those first before we use your card."
              )}
              <br />
              <a
                href="/kb/auto-renewal"
                className="add-card-modal-link"
                rel="nofollow noopener"
                target="_blank"
              >
                {this.props.t(
                  "PROFILE_AUTO_RENEWAL_LEARN",
                  "Learn more about auto-renewal"
                )}
              </a>
            </div>
          </div>
          <div className="add-card-title">
            <div className="add-card-text1">
              {this.props.t("PROFILE_ADD_CARD", "Add your Credit/Debit Card")}
            </div>
            {/* TODO: Get info about text here */}
            <div className="add-card-text2">
              {this.props.t("PROFILE_VISA_MASTERCARD", "(Visa/MasterCard)")}
            </div>
          </div>
          <div className="add-card-iframe">
            <AddCardIFrame onSuccess={this.props.addCardSuccess} />
          </div>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    );
  }
}

export default withTranslation()(AddPaymentMethod);
