import storage from "@faizaanceg/pandora";
import { Syringe } from "@faizaanceg/syringe";
import api from "api";
import { User } from "common/sdk/user";
import { addQueryParamsToUrl } from "common/urls";
import constants from "constants/endpoints";
import { isEmpty } from "lodash";
import format from "string-format";

export const services = {
  async createResellerPaymentLink(payload) {
    const { paymenttypeid, totalAmount, description, key } = payload;
    let externalAccessManager = Syringe.inject("externalAccessManager");
    return api.postBody(`${constants.billing}addFundsForPurchase`, {
      custompaymentid: paymenttypeid,
      amount: totalAmount,
      redirecturl: externalAccessManager.createPaymentUrl(key),
      description,
      saveCard: !isEmpty(storage.get("deferred-orders", {}))
    });
  },
  async customerPay(invoiceIds) {
    return api.post(constants.pay_invoice, { "invoice-ids": invoiceIds });
  },
  async placeAction(payload) {
    const {
      method,
      params = {},
      pathParams = {},
      endpoint,
      requestBody = {}
    } = payload;
    return api.call(method, format(endpoint, pathParams), params, requestBody);
  },
  getCurrencyConversionRate: (from, to) =>
    api.get(`${constants.zuulOrderBox}currency/conversion-rate`, { from, to }),
  async addNewCard() {
    let { TOKEN: loginId } = await User.token();
    let payload = {
      autoLoginParameters: { currentUrl: "", loginId, role: "customer" },
      redirectUrl: `${window.location.origin}${constants.webhooks}integrations/complete/billing-agreement-flow`,
      transactionMode: "addcard"
    };
    let { redirectUrl } = await api.postBody(
      `${constants.zuulOrderBox}addcard/initiate`,
      new URLSearchParams({ "request-json": JSON.stringify(payload) })
    );
    if (redirectUrl) {
      window.location.replace(redirectUrl);
    }
  },
  /**
   *
   * @param {{invoiceIds: string[]; paymenttypeid: string; key: string}}
   * @returns {Promise<{redirectUrl: string;}>}
   */
  async createPaymentLink({ invoiceIds, paymenttypeid, key }) {
    let { TOKEN: loginId } = await User.token();
    let externalAccessManager = Syringe.inject("externalAccessManager");
    let payload = {
      autoLoginParameters: {
        currentUrl: externalAccessManager.createCurrentHost(),
        loginId,
        role: "customer"
      },
      customPaymentId: paymenttypeid,
      redirectUrl: externalAccessManager.createPaymentUrl(key),
      transactionIdList: invoiceIds.map(id => `${id}_invoice`),
      transactionMode: "payment"
    };
    return api.postBody(
      `${constants.zuulOrderBoxRest}payment/initiate`,
      new URLSearchParams({ "request-json": JSON.stringify(payload) })
    );
  },
  /**
   *
   * @param {{invoiceIds: string[]; paymenttypeid: string; key: string}}
   * @returns {Promise<void>}
   */
  async payToCustomGateway({ invoiceIds, paymenttypeid, key }) {
    let { TOKEN: userLoginId } = await User.token();
    let externalAccessManager = Syringe.inject("externalAccessManager");
    let redirectUrl = externalAccessManager.createPaymentUrl(key);
    let payload = {
      custompaymentid: paymenttypeid,
      isPGTransaction: true,
      nooftrans: invoiceIds.length,
      paymentfor: "customer",
      transactionMode: "payment",
      transid_type: invoiceIds.map(invoiceId => `${invoiceId}_invoice`),
      userLoginId
    };
    if (redirectUrl) {
      payload.REDIRECT_URL = redirectUrl;
    }
    let brandingUrl = await api.get(
      `${constants.bclOrderBox}resellers/get-branding-url.json`
    );
    window.location = addQueryParamsToUrl(
      `${brandingUrl}/servlet/AutoLoginServlet`,
      payload
    );
  }
};
