import changeDomainSagas from "./change-domain/sagas";
import childNameServersSagas from "./childname-server/sagas";
import domainForwardingSagas from "./domain-forwarding/sagas";
import emailAddresses from "./email-address/sagas";
import gsuiteSetupSagas from "./gsuite-setup/sagas";
import manageAddonsSagas from "./manage-addons/sagas";
import manageBackupSagas from "./manage-backup/sagas";
import manageBlocksSagas from "./manage-blocks/sagas";
import manageDNSSECSagas from "./manage-dns-sec/sagas";
import manageDNSSagas from "./manage-dns/sagas";
import manageEmailSagas from "./manage-email/sagas";
import manageIPSagas from "./manage-ip/sagas";
import managePanelSagas from "./manage-panel/sagas";
import manageSSLSagas from "./manage-ssl/sagas";
import manageTitanSagas from "./manage-titan/sagas";
import manageVolumeSagas from "./manage-volumes/sagas";
import manageWHMCSSagas from "./manage-whmcs/sagas";
import { managedServicesSagas } from "./managed-service-addon/sagas";
import modifyTransferSecretSagas from "./modify-transfer-secret/sagas";
import privacySagas from "./privacy/sagas";
import serverPowerBarSagas from "./server-power-bar/sagas";
import shellAccessSagas from "./shell-access/sagas";
import theftProtectionSagas from "./theft-protection/sagas";

export const productSagas = [
  ...changeDomainSagas,
  ...childNameServersSagas,
  ...domainForwardingSagas,
  ...emailAddresses,
  ...gsuiteSetupSagas,
  ...manageAddonsSagas,
  ...manageBlocksSagas,
  ...manageDNSSagas,
  ...manageDNSSECSagas,
  ...manageEmailSagas,
  ...manageIPSagas,
  ...managePanelSagas,
  ...manageSSLSagas,
  ...manageVolumeSagas,
  ...manageWHMCSSagas,
  ...modifyTransferSecretSagas,
  ...privacySagas,
  ...serverPowerBarSagas,
  ...shellAccessSagas,
  ...theftProtectionSagas,
  ...managedServicesSagas,
  ...manageBackupSagas,
  ...manageTitanSagas
];
