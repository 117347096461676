import { isPast } from "date-fns";

let InvoiceStatus = {
  paid: "PAID",
  pending: "PENDING",
  overdue: "OVERDUE",
  // refund: "REFUND",
  bad_debt: "BAD_DEBT",
  cancel: "CANCEL"
};
let REASON_STRING = t => t("BILLING_ENTER_REASON", "Enter your reason here");
const actions = t => ({
  MARK_AS_PAID: t("BILLING_MARK_AS_FULLY_PAID", "MARK AS FULLY PAID"),
  MARK_AS_UNPAID: t("BILLING_MARK_AS_UNPAID", "MARK AS UNPAID"),
  // MARK_AS_REFUND: "MARK AS REFUND",
  MARK_AS_BAD_DEBT: t("BILLING_MARK_AS_BAD_DEBT", "MARK AS BAD DEBT"),
  RECORD_PAYMENT: t("BILLING_RECORD_A_PAYMENT", "RECORD A PAYMENT"),
  EMAIL_REMINDER: t("BILLING_SEND_IN_EMAIL", "SEND IN EMAIL"),
  PRINT: t("SIMPLE_PRINT", "PRINT"),
  CANCEL: t("SIMPLE_CANCEL", "CANCEL"),
  DOWNLOAD: t("SIMPLE_DOWNLOAD", "DOWNLOAD")
});

// const invoiceTypes = {
//   ALL: "ALL",
//   PAID: "PAID",
//   PENDING: "PENDING",
//   OVERDUE: "OVERDUE",
//   //REFUND: "REFUND",
//   BAD_DEBT: "BAD_DEBT",
//   CANCEL: "CANCEL"
// };

export function getActionsByState(state, t) {
  switch (state) {
    case InvoiceStatus.paid: {
      return [];
      // return [
      //   actions.MARK_AS_UNPAID,
      //   actions.MARK_AS_REFUND,
      //   actions.EMAIL_REMINDER,
      //   actions.CANCEL
      // ];
    }
    case InvoiceStatus.pending:
    case InvoiceStatus.overdue: {
      return [
        actions(t).MARK_AS_PAID,
        actions(t).RECORD_PAYMENT,
        actions(t).MARK_AS_BAD_DEBT,
        actions(t).EMAIL_REMINDER,
        actions(t).CANCEL
      ];
    }
    case InvoiceStatus.bad_debt:
      return [];
    // baddebt to paid why not possible
    case InvoiceStatus.refund: {
      return [actions(t).CANCEL];
    }
    case InvoiceStatus.cancel:
    default: {
      return [];
    }
  }
}

let datePickerTabs = t => ({
  THIS_MONTH: t("BILLING_THIS_MONTH", "This Month"),
  THIRTY_DAYS: t("BILLING_LAST_30_DAYS", "Last 30 Days"),
  THREE_MONTHS: t("BILLING_LAST_3_MONTHS", "Last 3 Months"),
  CUSTOM: t("BILLING_CUSTOM", "Custom")
});

function getInvoiceState(invoice) {
  if (!invoice) return;
  if (
    invoice.state === InvoiceStatus.paid &&
    invoice.maxReversibleAmountWithoutTaxAndVat === 0
  ) {
    return InvoiceStatus.cancel;
  }
  if (
    invoice.type === "custom_invoice" &&
    invoice.state === InvoiceStatus.pending &&
    invoice.dueDate !== null &&
    isPast(invoice.dueDate)
  ) {
    return InvoiceStatus.overdue;
  }
  return invoice.state;
}

function generateParams(skip, count, state, searchText, from, to) {
  return {
    skip,
    count,
    ...(state !== "ALL" && { state }),
    ...(searchText && { searchText: searchText.toLowerCase() }),
    ...(from && {
      fromDate: from.getTime(),
      toDate: to ? to.getTime() : Date.now()
    })
  };
}

export {
  actions,
  InvoiceStatus,
  REASON_STRING,
  getInvoiceState,
  datePickerTabs,
  generateParams
};
