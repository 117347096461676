import {
  getLocationFromProductName,
  getSimpleNameFromProductKey as getProductTypeFromName
} from "..";
import Constants from "../endpoints";
import { platform as Platform, getPlatformFromProductName } from "../platform";
import { exposeAPIBuilder, v1HostingAPIBuilder } from "./builders";

const gAppsBuilder = productName => {
  const productType = getProductTypeFromName(productName);
  const countryCode = getLocationFromProductName(productName);
  const resource = `gapps/${countryCode}`;
  const productUrl = `${Constants.zuulOrderBox}${resource}/`;
  const endpoints = v1HostingAPIBuilder(resource, { dns: true });
  return {
    productType,
    location: countryCode,
    meta: {
      endpoints: {
        ...endpoints,
        getDetails: `${endpoints.getDetails}&options=used_account_count`,
        addEmailAccounts: `${productUrl}add-account`,
        deleteEmailAccounts: `${productUrl}delete-account`,
        setupAdminPanel: `${productUrl}admin/add`
      }
    }
  };
};

const cloudSitesBuilder = productName => {
  const productType = getProductTypeFromName(productName);
  const platform = getPlatformFromProductName(productName);
  const location = getLocationFromProductName(productName);
  const productUrl = `${
    productType === "cloudhosting" ? "cloudsites" : productType
  }/${platform}/${location}`;
  const endpoints = v1HostingAPIBuilder(productUrl, {
    addons: true,
    dns: true,
    shell: true,
    serverManagement: true,
    ssl: true
  });
  return {
    productType,
    platform,
    location,
    meta: { platform, location, endpoints }
  };
};

const procloudBuilder = productName => {
  const productType = getProductTypeFromName(productName);
  const platform = getPlatformFromProductName(productName);
  const location = getLocationFromProductName(productName);
  const endpoints = v1HostingAPIBuilder(`${productType}/${location}`);
  return {
    productType,
    platform,
    location,
    meta: { platform, location, endpoints }
  };
};

const resellerHostingBuilder = productName => {
  const productType = getProductTypeFromName(productName);
  const platform = getPlatformFromProductName(productName);
  const location = getLocationFromProductName(productName);
  const resource = `resellerhosting/${platform}/${location}`;
  const productUrl = `${Constants.zuulOrderBox}${resource}/`;
  const endpoints = v1HostingAPIBuilder(resource, {
    dedicatedIP: true,
    dns: true
  });
  return {
    productType,
    platform,
    location,
    meta: {
      platform,
      location,
      endpoints: {
        ...endpoints,
        changeDomain: `${productUrl}change-domain-name`,
        deleteWHMCSKey: `${productUrl}revoke-license-key`,
        changeHostingPanelPassword: `${productUrl}change-password`,
        getBlocksUsage: `${productUrl}resource-usage?order-id={orderid}`,
        buyBlocks: `${productUrl}add-cpanel-blocks`,
        deleteBlocks: `${productUrl}delete-cpanel-blocks?order-id={orderid}&cpanel-blocks={blocks}`,
        buyWHMCS: `${productUrl}generate-license-key`
      }
    }
  };
};

const sharedHostingBuilder = productName => {
  const productType = getProductTypeFromName(productName);
  const platform = getPlatformFromProductName(productName);
  const location = getLocationFromProductName(productName);
  const obCountryCode = location === "usaca" ? "us/ca" : location;
  const resource =
    productType === "sdh" ? "singledomainhosting" : "multidomainhosting";
  const resourceUrl = `${resource}/${platform}/${obCountryCode}`;
  const productUrl = `${Constants.zuulOrderBox}${resourceUrl}/`;
  const endpoints = v1HostingAPIBuilder(resourceUrl, { dns: true, ssl: true });
  return {
    productType,
    platform,
    location,
    meta: {
      platform,
      location,
      endpoints: {
        ...endpoints,
        changeHostingPanelPassword: `${productUrl}change-password`,
        changeDomain: `${productUrl}change-domain-name`
      }
    }
  };
};

const managedServerHostingBuilder = productName => {
  const productType = getProductTypeFromName(productName);
  const platform = getPlatformFromProductName(productName);
  const countryCode = getLocationFromProductName(productName);
  const endpoints = v1HostingAPIBuilder(
    `${productType}/${platform}/${countryCode}`,
    null,
    { addons: true, serverManagement: true }
  );
  return {
    productType,
    platform,
    location: countryCode,
    meta: { platform, location: countryCode, endpoints }
  };
};
const dedicatedHostingBuilder = productName => {
  const productType = getProductTypeFromName(productName);
  const platform = getPlatformFromProductName(productName);
  const countryCode = getLocationFromProductName(productName);
  const productUrl = `${productType}/${platform}/${countryCode}`;
  const endpoints = v1HostingAPIBuilder(productUrl, {
    addons: true,
    serverManagement: true
  });
  const backupProductUrl = `${Constants.zuulOrderBox}backup/{location}/`;
  return {
    productType,
    platform,
    location: countryCode,
    meta: {
      platform,
      location: countryCode,
      endpoints: {
        ...endpoints,
        getBackupOrderDetails: `${backupProductUrl}details?order-id={orderid}`,
        searchBackupOrder: `${backupProductUrl}search?customer-id={customerid}`,
        buyBackup: `${backupProductUrl}add`,
        buyStorage: `${backupProductUrl}add-storage`,
        getBackupAutoLoginDetails: `${backupProductUrl}auto-login-details`,
        installBackupAgent: `${backupProductUrl}install-agent?hosting-order-id={hostingOrderId}&backup-order-id={backupOrderId}`
      }
    }
  };
};

const blockStorageBuilder = productName => {
  const platform = Platform.LINUX;
  const productType = getProductTypeFromName(productName);
  const location = getLocationFromProductName(productName);
  const productUrl = `${productType}/${location}`;
  const endpoints = v1HostingAPIBuilder(productUrl);
  endpoints.upgradeVolume = `${Constants.zuulOrderBox}${productUrl}/upgrade`;
  return {
    productType,
    platform,
    location,
    meta: { platform, location, endpoints }
  };
};

const virtualServerBuilder = productName => {
  const location = getLocationFromProductName(productName);
  const platform = getPlatformFromProductName(productName);
  const endpoints = v1HostingAPIBuilder(
    `virtualserver/${platform}/${location}`,
    { addons: true, dns: true, serverManagement: true }
  );
  const backupProductUrl = `${Constants.zuulOrderBox}backup/{location}/`;
  const blockstorageProductUrl = `${Constants.zuulOrderBox}blockstorage/${location}/`;
  return {
    productType: getProductTypeFromName(productName),
    platform,
    location,
    meta: {
      platform,
      location,
      endpoints: {
        ...endpoints,
        buyVolume: `${blockstorageProductUrl}add`,
        deleteVolume: `${blockstorageProductUrl}delete`,
        upgradeVolume: `${blockstorageProductUrl}upgrade`,
        getBackupOrderDetails: `${backupProductUrl}details?order-id={orderid}`,
        searchBackupOrder: `${backupProductUrl}search?customer-id={customerid}`,
        buyBackup: `${backupProductUrl}add`,
        buyStorage: `${backupProductUrl}add-storage`,
        getBackupAutoLoginDetails: `${backupProductUrl}auto-login-details`,
        installBackupAgent: `${backupProductUrl}install-agent?hosting-order-id={hostingOrderId}&backup-order-id={backupOrderId}`
      }
    }
  };
};

const sslBuilder = productType => ({
  productType,
  meta: {
    endpoints: {
      ...v1HostingAPIBuilder(productType),
      enroll: `${Constants.ssl}enroll`,
      reissue: `${Constants.ssl}reissue`,
      changeVerificationEmail: `${Constants.ssl}change-verification-method`,
      getCertDetails: `${Constants.ssl}cert-details?order-id={orderid}&formatting-required=false`
    }
  }
});

const domorderBuilder = productName => ({
  productType: getProductTypeFromName(productName),
  meta: {
    endpoints: {
      getDetails: `${Constants.domain}details?options=All&order-id={orderid}`,
      getDefaultNameServers: `${Constants.zuulOrderBox}reseller/get-branded-nameservers`,
      isUsingDefaultNameServers: `${Constants.domain}validate-domain-nameservers?order-id={orderid}`,
      domainForwarding: `${Constants.zuulOrderBox}domainforward/details?order-id={orderid}`,
      getDnsServiceStatus: `${Constants.zuulOrderBox}orders/is-dns-service-active?order-id={orderid}`,
      getFreeEmailServiceStatus: `${Constants.zuulOrderBox}orders/is-free-email-service-active?order-id={orderid}`,
      getPricingDetails: Constants.pricing_details_productkey,
      getTLDInfoForDomain: `${Constants.zuulOrderBoxV2}domains/{domainname}/product`,
      purchasePrivacyProtection: `${Constants.domain}purchase-privacy`,
      renew: `${Constants.domain}renew`,
      restore: `${Constants.domain}restore`,
      delete: `${Constants.domain}delete?order-id={orderid}`,
      buyPdns: `${Constants.domain}premium-dns`
    }
  }
});

const emailBuilder = productName => {
  const productType = getProductTypeFromName(productName);
  const location = getLocationFromProductName(productName);
  const resource =
    productType === "businessemail" ? "eelite" : "enterpriseemail";
  const endpoints = v1HostingAPIBuilder(`${resource}/${location}`, {
    dns: true,
    email: true
  });
  return { productType, location, meta: { location, endpoints } };
};

const dedicatedServerBluehostBuilder = productName => {
  const productType = getProductTypeFromName(productName);
  const location = getLocationFromProductName(productName);
  const platform = getPlatformFromProductName(productName);
  const endpoints = v1HostingAPIBuilder(
    `bluehost/dedicatedserver/${platform}/${location}`,
    { addons: true, serverManagement: true }
  );
  return {
    productType,
    platform,
    location,
    meta: { platform, location, endpoints }
  };
};

const bluehostVirtualServerBuilder = productName => {
  const productType = getProductTypeFromName(productName);
  const location = getLocationFromProductName(productName);
  const platform = getPlatformFromProductName(productName);
  const endpoints = v1HostingAPIBuilder(
    `bluehost/vps/${platform}/${location}`,
    { addons: true, serverManagement: true, dns: true }
  );
  endpoints.delete = `${endpoints.delete}&brand=bluehost`;
  return {
    productType,
    platform,
    location,
    meta: { platform, location, endpoints }
  };
};

const hgsharedhosting = productName => {
  const productType = getProductTypeFromName(productName);
  const location = getLocationFromProductName(productName);
  const platform = getPlatformFromProductName(productName);
  const endpoints = v1HostingAPIBuilder(
    `hgsharedhosting/${platform}/${location}`,
    { addons: true, dns: true, shell: true, serverManagement: true }
  );
  return {
    productType,
    platform,
    location,
    meta: { platform, location, endpoints }
  };
};

const hgDedicatedServerBuilder = productName => {
  const productType = getProductTypeFromName(productName);
  const platform = getPlatformFromProductName(productName);
  const location = getLocationFromProductName(productName);
  const endpoints = v1HostingAPIBuilder(
    `${productType}/${platform}/${location}`,
    { addons: true, serverManagement: true }
  );
  return {
    productType,
    platform,
    location,
    meta: { platform, location, endpoints }
  };
};

const wbBuilder = productName => {
  const productType = getProductTypeFromName(productName);
  const endpoints = v1HostingAPIBuilder(`webservices`, { dns: true });
  return { productType, meta: { endpoints } };
};
const backupBuilder = productName => {
  const location = getLocationFromProductName(productName);
  const resource = `backup/${location}`;
  const productUrl = `${Constants.zuulOrderBox}${resource}/`;
  return {
    productType: getProductTypeFromName(productName),
    meta: {
      location,
      endpoints: {
        ...v1HostingAPIBuilder(resource),
        buyStorage: `${productUrl}add-storage`,
        delete: `${productUrl}delete?backup-order-id={orderid}`,
        getBackupAutoLoginDetails: `${productUrl}auto-login-details`,
        buyBackup: `${productUrl}add`
      }
    }
  };
};

const wordpressHostingBuilder = productName => {
  const productType = getProductTypeFromName(productName);
  const location = getLocationFromProductName(productName);
  const platform = getPlatformFromProductName(productName);
  const endpoints = exposeAPIBuilder(productName, { addons: true });
  return {
    productType,
    platform,
    location,
    meta: {
      platform,
      location,
      endpoints: { ...endpoints, enableSSL: endpoints.buyAddon }
    }
  };
};

const simpleHostingBuilder = productName => {
  const productType = getProductTypeFromName(productName);
  const endpoints = v1HostingAPIBuilder(productType);
  return { productType, meta: { endpoints } };
};

const simpleExposeBuilder = productName => {
  return {
    productType: getProductTypeFromName(productName),
    meta: { endpoints: exposeAPIBuilder(productName, { useCommonInfo: true }) }
  };
};

const titanExposeBuilder = productName => {
  const endpoints = exposeAPIBuilder(productName, { useCommonInfo: false });
  const productUrl = `${Constants.zuulOrderBoxRest}product/${productName}`;
  const resourceUrl = `${productUrl}/order/{orderid}`;
  const resourceUrlv2 = `${productUrl}/orders/{orderid}`;
  return {
    productType: getProductTypeFromName(productName),
    meta: {
      endpoints: {
        ...endpoints,
        getAutoLoginUrl: `${resourceUrl}/sso-url`,
        addEmailAccounts: `${resourceUrlv2}/seats/{accounts}`,
        deleteEmailAccounts: `${resourceUrlv2}/seats/{accounts}`
      }
    }
  };
};

const wpcloud = productName => {
  const endpoints = exposeAPIBuilder(productName, {
    manageSites: true,
    useCommonInfo: false
  });
  return {
    productType: getProductTypeFromName(productName),
    meta: { endpoints }
  };
};

const productKeys = /** @type {const} */ ([
  "acronisbackupasia",
  "acronisbackupus",
  "backorderservice",
  "blockstorageus",
  "blockstoragein",
  "bluehostdedicatedserverlinuxus",
  "bluehostvpslinuxus",
  "businesscloudlinuxus",
  "cloudsiteslinuxin",
  "cloudsiteslinuxus",
  "procloudasia",
  "codeguard",
  "dedicatedserverlinuxin",
  "dedicatedserverlinuxus",
  "dedicatedserverwindowshk",
  "dedicatedserverwindowsin",
  "hostgatordedicatedserverlinuxus",
  "domorder",
  "eeliteus",
  "enterpriseemailus",
  "gappsgbl",
  "gappsin",
  "gappsse",
  "hostgatorhostingsharedlinuxus",
  "hosting",
  "managedserverlinuxus",
  "multidomainhosting",
  "multidomainhostinglinuxhk",
  "multidomainhostinglinuxin",
  "multidomainhostinglinuxtr",
  "multidomainhostinglinuxuk",
  "multidomainhostinglinuxusca",
  "multidomainwindowshosting",
  "multidomainwindowshostinghk",
  "multidomainwindowshostingin",
  "multidomainwindowshostingtr",
  "multidomainwindowshostinguk",
  "resellerhosting",
  "resellerhostinglinuxin",
  "resellerhostinglinuxtr",
  "resellerhostinglinuxuk",
  "resellerhostinglinuxhk",
  "resellerwindowshosting",
  "resellerwindowshostingin",
  "resellerwindowshostingtr",
  "resellerwindowshostinguk",
  "singledomainhostinglinuxhk",
  "singledomainhostinglinuxin",
  "singledomainhostinglinuxtr",
  "singledomainhostinglinuxuk",
  "singledomainhostinglinuxus",
  "singledomainhostinglinuxusca",
  "singledomainhostingwindowshk",
  "singledomainhostingwindowsin",
  "singledomainhostingwindowstr",
  "singledomainhostingwindowsuk",
  "singledomainhostingwindowsus",
  "sitelock",
  "sslcert",
  "titanmailindia",
  "titanmailglobal",
  "virtualserverlinuxin",
  "virtualserverlinuxus",
  "webzai",
  "wordpresscloudindia",
  "wordpresshostingindia",
  "wordpresshostingusa"
]);

const builders = {
  sdh: sharedHostingBuilder,
  mdh: sharedHostingBuilder,
  vpshosting: virtualServerBuilder,
  dedicatedserver: dedicatedHostingBuilder,
  hgdedicatedserver: hgDedicatedServerBuilder,
  cloudhosting: cloudSitesBuilder,
  businesscloud: cloudSitesBuilder,
  procloud: procloudBuilder,
  enterpriseemail: emailBuilder,
  businessemail: emailBuilder,
  gsuite: gAppsBuilder,
  sslcert: sslBuilder,
  sitelock: simpleHostingBuilder,
  rchosting: resellerHostingBuilder,
  domain: domorderBuilder,
  bhvpshosting: bluehostVirtualServerBuilder,
  wph: wordpressHostingBuilder,
  wpcloud,
  managedserver: managedServerHostingBuilder,
  hgshosting: hgsharedhosting,
  dedibybh: dedicatedServerBluehostBuilder,
  codeguard: simpleHostingBuilder,
  hosting: wbBuilder,
  blockstorage: blockStorageBuilder,
  backup: backupBuilder,
  backorder: simpleExposeBuilder,
  webzai: simpleHostingBuilder,
  titanmail: titanExposeBuilder
};

/**
 * @typedef {import("../../../webpro").CreateUnionFrom<typeof productKeys>} ProductKeys
 */

/**
 * @typedef {ReturnType<typeof getPlatformFromProductName>} Platform
 * @typedef {ReturnType<typeof getLocationFromProductName>} Location
 */

/**
 * @typedef {Object} ProductMapping
 * @property {import("..").SimpleName} productType
 * @property {{endpoints: Record<string, string>; platform?: Platform; location?: Location; }} meta
 */

/** @type {Record<ProductKeys, ProductMapping>} */
export default Object.fromEntries(
  productKeys.map(key => [key, builders[getProductTypeFromName(key)](key)])
);
