export let fonts = {
  OpenSans: "OpenSans, sans-serif",
  BrandonGrotesque: "brandon-grotesque, sans-serif"
};

export let colors = {
  white: {
    regular: "#ffffff"
  },
  black: {
    regular: "#434c5e",
    black100: "#1f2229"
  },
  gray: {
    gray100: "#f9f9fb",
    gray200: "#fbfbfb",
    gray300: "#f3f3f5",
    gray400: "#e9e9eb",
    gray500: "#a9a9a9",
    gray600: "#d9d9db",
    gray700: "#8a929e",
    gray800: "#6d7687",
    gray900: "#596275",
    grayInactive: "#808087",
    grayDisabled: "#E0E0E2",
    grayDisabledText: "#A2A2A6"
  },
  blue: {
    regular: "#468def",
    hover: "#4b97ff"
  },
  red: {
    regular: "#e76060",
    hover: "#f66b6e"
  },
  green: {
    regular: "#28bd8b",
    hover: "#2dcc96"
  },
  yellow: {
    regular: "#fffcf1",
    light: "#fff7e0",
    dark: "#ffe8ad"
  },
  transparent: {
    regular: "transparent"
  },
  orange: {
    dull: "#e76060"
  }
} as const;
