import AutoRenewal from "common/auto-renewal/index";
import { Checkbox } from "common/checkbox";
import datalayerPush from "common/datalayer-push";
import { formatAmountWithCurrency } from "common/localize-amount";
import { colors, fonts } from "common/styles";
import TelescopicSelectBox from "common/TelescopicSelectBox";
import { applyAll, showLoader } from "common/util";
import { withWalletInfo } from "common/wallet";
import { getSimpleNameFromProductKey } from "constants/index";
import { styled } from "linaria/react";
import { isEmpty } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import storage from "@faizaanceg/pandora";
import { AddBlocks } from "./addons/add-blocks";
import AddonsComponent from "./AddonsComponent";
import CrossIcon from "./assets/cross";
import {
  getDomainName,
  getRenewalPrice,
  getSelectedTenure,
  TenureOptions
} from "./constants";
import { RenewalGridRows } from "./renewal-modal";

let RenewalIcon = styled.div`
  margin-right: 10px;
  margin-top: 4px;
  &[data-valign-center="true"] {
    margin-top: 0;
  }
`;

let RestoreNote = styled.div`
  font: italic 400 11px ${fonts.OpenSans};
  color: #6a7489;
  margin-left: 35px;
  margin-top: 5px;
  strong {
    font-weight: 600;
    color: ${colors.black.regular};
  }
`;

let RenewalTitle = styled.div`
  display: flex;
  position: relative;
  top: -4px;
  word-break: break-all;
  &[data-valign-center="true"] {
    align-items: center;
  }
  .custom-checkbox-label {
    margin-top: 2px;
  }
`;

let RenewalRowsWrapper = styled.div`
  position: relative;
  padding: 14px 30px 21px;
  border-bottom: 1px ${colors.gray.gray300} solid;
  &:last-child {
    border-bottom: none;
  }
`;

let RenewalDeleteButton = styled.button`
  outline: none;
  cursor: pointer;
  background: transparent;
  padding: 0;
  border: none;
  margin-top: 6px;
  margin-right: 10px;
`;
type RenewalModalRowsProps = {
  onHide: Function;
  renewalDataByOrderID: Record<string, any>;
  isFetchingPricingData: boolean;
  modifiedRenewalDetails: any;
  isInRestorationPhase: Function;
  forDashboard: boolean;
  getAddonDetails: Function;
  onAddonsSelected: Function;
  currencySymbol: string;
  setModifiedRenewalDetails: Function;
  removeOrderFromRenewal: Function;
  setDeferredOrders: (orders: any) => void;
};

const RenewalModalRows: React.FC<RenewalModalRowsProps> = ({
  onHide,
  renewalDataByOrderID,
  isFetchingPricingData,
  modifiedRenewalDetails,
  isInRestorationPhase,
  forDashboard,
  getAddonDetails,
  onAddonsSelected,
  currencySymbol,
  setModifiedRenewalDetails,
  removeOrderFromRenewal,
  setDeferredOrders
}): any => {
  const { t } = useTranslation();
  if (isEmpty(renewalDataByOrderID)) {
    onHide();
  }
  if (isFetchingPricingData) {
    return showLoader;
  }
  return Object.entries(renewalDataByOrderID).map(
    ([orderid, renewalInfo]: any) => {
      let price = getRenewalPrice(
        orderid,
        renewalDataByOrderID,
        modifiedRenewalDetails
      )?.resellerPrice;
      const selectedTenure = getSelectedTenure(
        orderid,
        renewalDataByOrderID,
        modifiedRenewalDetails
      );
      const {
        orderDetails,
        addonDetails,
        expirytimestamp,
        pricing = {},
        unit = ""
      } = renewalInfo;
      let addBlocks =
        modifiedRenewalDetails[orderid]?.addonDetails?.addons?.blocks?.param >=
        0;

      const addonsToShow = Object.keys(getAddonDetails(orderid).addons).filter(
        addonName => !!getAddonDetails(orderid).addons[addonName].inputType
      );
      const isTitleWithoutAddonDetails =
        !forDashboard &&
        addonDetails?.displayText?.trim() === "" &&
        addonsToShow?.length === 0;
      return (
        <RenewalRowsWrapper key={orderid}>
          <RenewalGridRows data-renewal-is-dashboard={forDashboard}>
            <RenewalTitle data-valign-top={isTitleWithoutAddonDetails}>
              <RenewalIcon data-valign-top={isTitleWithoutAddonDetails}>
                <img
                  alt="order"
                  src={orderDetails.icon}
                  className="prod-logo-new lfloat"
                  style={{ height: "26px" }}
                />
              </RenewalIcon>
              <div>
                <div className="ren-prod-name">
                  {forDashboard
                    ? orderDetails.domainname
                    : orderDetails.product_category}
                </div>
                {forDashboard && (
                  <div className="ren-prod-sub">
                    {orderDetails.product_category}
                  </div>
                )}
                {addonDetails && !isInRestorationPhase(orderid) && (
                  <AddonsComponent
                    addonDetails={getAddonDetails(orderid)}
                    orderid={orderid}
                    currencySymbol={currencySymbol}
                    productkey={orderDetails.keyToGetProductDetails}
                    selectedTenure={selectedTenure}
                    expirytimestamp={expirytimestamp}
                    onAddonsSelected={onAddonsSelected}
                  />
                )}
              </div>
            </RenewalTitle>
            <div>
              <div className="auto-show-dropdown">
                <div className="tbr-dom-name-1">
                  {getSimpleNameFromProductKey(
                    orderDetails.keyToGetProductDetails
                  ) === "rchosting" ? (
                    <TelescopicSelectBox
                      defaultValue={12}
                      pricing={pricing}
                      currencySymbol={currencySymbol}
                      onChange={selectDropdownResult =>
                        setModifiedRenewalDetails(orderid, selectDropdownResult)
                      }
                    />
                  ) : (
                    <select
                      data-testid="renew-tenures"
                      name="ren-year-drop"
                      className="ren-year-drop"
                      defaultValue={selectedTenure}
                      onChange={e => {
                        datalayerPush("Renew Duration Changed", {
                          orderid,
                          newDuration:
                            unit === "year(s)"
                              ? parseInt(e.target.value, 10) * 12
                              : parseInt(e.target.value, 10)
                        });
                        setModifiedRenewalDetails(orderid, e.target.value);
                      }}
                    >
                      <TenureOptions pricing={pricing} unit={unit} />
                    </select>
                  )}
                  <AutoRenewal.Toggle
                    onlyRenderToggle
                    orderId={orderid}
                    render={({
                      autoRenewable,
                      hasAutoRenewablePaymentMethods,
                      hasTokens,
                      turnOffAutoRenewal,
                      turnOnAutoRenewal,
                      setAutorenewal,
                      nextTime
                    }) => {
                      let dontDefer =
                        (hasAutoRenewablePaymentMethods && hasTokens) ||
                        !hasAutoRenewablePaymentMethods;
                      return (
                        <Checkbox
                          field={{ defaultChecked: autoRenewable }}
                          onChange={() => {
                            if (autoRenewable) {
                              if (dontDefer) {
                                setAutorenewal(false);
                              } else {
                                turnOffAutoRenewal();
                              }
                              let {
                                [orderid]: toBeRemoved,
                                ...deferredOrders
                              } = storage.get("deferred-orders", {});
                              setDeferredOrders(deferredOrders);
                            } else {
                              if (dontDefer) {
                                setAutorenewal(true);
                              } else {
                                turnOnAutoRenewal();
                                let deferredOrders = storage.get(
                                  "deferred-orders",
                                  {}
                                );
                                setDeferredOrders({
                                  ...deferredOrders,
                                  [orderid]: orderDetails.domainname
                                });
                              }
                            }
                          }}
                          screen="order-manage-screen"
                        >
                          {t("AUTO_RENEW", "Auto-renew")} {selectedTenure}{" "}
                          {unit} {nextTime ? t("NEXT_TIME", "next time") : ""}
                        </Checkbox>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            {forDashboard && (
              <div>
                <div className="ren-cost " style={{ marginTop: "2px" }}>
                  {formatAmountWithCurrency({
                    cost: price,
                    currency: currencySymbol,
                    currencyDisplay: "code"
                  })}
                </div>
              </div>
            )}
            {forDashboard && (
              <div>
                <RenewalDeleteButton
                  onClick={() => removeOrderFromRenewal(orderid)}
                >
                  <CrossIcon />
                </RenewalDeleteButton>
              </div>
            )}
          </RenewalGridRows>
          {addBlocks ? (
            <AddBlocks
              orderId={orderid}
              onChange={(blocks, addonPrice, customerAddonPrice) =>
                onAddonsSelected(
                  orderid,
                  "blocks",
                  addonPrice * blocks * selectedTenure,
                  blocks,
                  undefined,
                  customerAddonPrice * blocks * selectedTenure
                )
              }
            />
          ) : null}
          {isInRestorationPhase(orderid) && (
            <RestoreNote>
              {t(
                "PRODUCT_RESTORE_FIVE",
                "It might take upto 5 days to restore"
              )}{" "}
              <strong>{getDomainName(orderid, renewalDataByOrderID)}</strong>
            </RestoreNote>
          )}
        </RenewalRowsWrapper>
      );
    }
  );
};

export default withWalletInfo(RenewalModalRows);
