import React from "react";
import { PaymentTabs } from "./tabs";
export class AddPaymentMethod extends React.Component {
  render() {
    const {
      paypw,
      paypal,
      orderMap,
      enableAutoRenewal,
      addCardSuccess
    } = this.props;
    return (
      <PaymentTabs
        paypw={paypw}
        paypal={paypal}
        addCardSuccess={addCardSuccess}
        enableAutoRenewal={enableAutoRenewal}
        orderMap={orderMap}
      />
    );
  }
}
