import { ErrorFlashImage } from "assets";
import classNames from "classnames";
import * as React from "react";
import "./styles.scss";

function ModalError(props) {
  let {
    defaultMessage = "Some error has occurred. Please try again later",
    children = "",
    className,
    ...remainingProps
  } = props;
  let message = children?.length > 0 ? children : defaultMessage;
  return (
    <div className={classNames("modal-error", className)} {...remainingProps}>
      <div>
        <img className="error-flash" src={ErrorFlashImage} alt="error" />
      </div>
      {message}
    </div>
  );
}

export default ModalError;
