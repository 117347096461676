import { IntroWalkthrough } from "assets";
import { applyAll } from "common/util";
import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./modals.scss";

const WalkThroughModal = ({ startWalkThrough, showHelpMessage, ...props }) => {
  const { t } = useTranslation();

  return (
    <Modal
      dialogClassName="walkthrough-modal"
      backdrop={false}
      show={props.show}
      keyboard
      onHide={props.onHide}
    >
      <Modal.Body>
        <div>
          <div className="walkthrough-content">
            <div className="walkthrough-text">
              <div>
                <img
                  src={IntroWalkthrough}
                  className="intro-walkthrough"
                  alt="allow-walkthrough"
                />
              </div>
              <div>
                {t(
                  "DASHBOARD_WALKTHROUGH_PROMPT",
                  "ALLOW US TO WALK YOU THROUGH"
                )}
              </div>
            </div>
            <button
              onClick={applyAll(props.onHide, startWalkThrough)}
              data-testid="yes-walthrough"
              className="wt-btn"
            >
              {t("DASHBOARD_WALKTHROUGH_ACCEPT", "Yes, Please")}
            </button>
            <button
              onClick={() => {
                applyAll(props.onHide, showHelpMessage)();
              }}
              className="anti-wt-btn"
              data-testid="no-walkthrough"
            >
              {t("DASHBOARD_WALKTHROUGH_DENY", "No, thanks. I'll explore")}
            </button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default WalkThroughModal;
