import { Syringe } from "@faizaanceg/syringe";
import { User } from "common/sdk/user";
import { getSuperSiteUrl } from "common/supersite";
import { addQueryParamsToUrl } from "common/urls";
import endpoints from "constants/endpoints";
import { css } from "linaria";
import { Billing } from "./icons/billing";
import { Customers } from "./icons/customers";
import { Home } from "./icons/home";
import { MyShop } from "./icons/my-shop";
import { Orders } from "./icons/orders";
import { Pro } from "./icons/pro";
import { Services } from "./icons/services";
export const webProRoutes = {
  HOME: 1,
  ORDERS: 2,
  CUSTOMERS: 3,
  SERVICES: 4,
  SETTINGS: 5,
  PROFILE: 6,
  HELP: 7,
  TRANSACTIONS: 8,
  LOGIN: 9,
  PROSUITE: 10,
  INVOICES: 11,
  MYSHOP: 12,
  ORDERREQUESTS: 13
};

export const isAffiliateEnabled = parentId =>
  process.env.FEATURE_AFFILIATE_ENABLED_PARENT_IDS.includes(parentId);

export const mainCategory = {
  mybusiness: {
    sofiKey: "MY_BUSINESS",
    fallbackText: "MY BUSINESS"
  },
  settings: {
    sofiKey: "SETTINGS",
    fallbackText: "SETTINGS"
  }
};

export const ROUTE_OPTION = {
  [webProRoutes.HOME]: "/dashboard",
  [webProRoutes.PROFILE]: "/profile",
  [webProRoutes.ORDERREQUESTS]: "/orders-requests",
  [webProRoutes.ORDERS]: "/orders",
  [webProRoutes.SERVICES]: "/affiliate",
  [webProRoutes.INVOICES]: "/invoices",
  [webProRoutes.HELP]: "/dashboard/help",
  [webProRoutes.LOGIN]: "/login",
  [webProRoutes.CUSTOMERS]: "/customers",
  [webProRoutes.SETTINGS]: "/settings",
  [webProRoutes.TRANSACTIONS]: "/transactions",
  [webProRoutes.PROSUITE]: "/settings/pro-suite",
  [webProRoutes.MYSHOP]: "/my-shop"
};

export const getSideBarItems = ({
  showOrdersIcon,
  isBillingActive,
  parentid,
  role
}) => {
  let items = [
    {
      icon: Home,
      text: "HOME",
      index: webProRoutes.HOME,
      category: null,
      exact: true
    }
  ];

  if (process.env.FEATURE_SIDEBAR_ORDERS === "default-business") {
    items.push({
      icon: Orders,
      text: "ORDERS",
      index: webProRoutes.ORDERS,
      category: mainCategory.mybusiness.fallbackText,
      showNotification: true,
      children: [
        {
          text: "ALL ORDERS",
          index: webProRoutes.ORDERS,
          category: mainCategory.mybusiness.fallbackText
        },
        {
          text: "ORDER REQUESTS",
          index: webProRoutes.ORDERREQUESTS,
          category: mainCategory.mybusiness.fallbackText,
          showNotification: true
        }
      ]
    });
  } else if (
    (process.env.FEATURE_SIDEBAR_ORDERS === "conditional-business" &&
      showOrdersIcon) ||
    process.env.FEATURE_SIDEBAR_ORDERS === "default-null"
  ) {
    items.push({
      icon: Orders,
      text: "ORDERS",
      index: webProRoutes.ORDERS,
      category: null
    });
  }

  if (process.env.FEATURE_HAS_CUSTOMERS) {
    items.push({
      icon: Customers,
      text: "CUSTOMERS",
      index: webProRoutes.CUSTOMERS,
      id: "step5",
      category: mainCategory.mybusiness.fallbackText
    });
  }

  if (process.env.FEATURE_SIDEBAR_INVOICE === "default-business") {
    items.push({
      icon: Billing,
      text: "BILLING",
      index: webProRoutes.INVOICES,
      id: "step4",
      category: mainCategory.mybusiness.fallbackText
    });
  } else if (
    process.env.FEATURE_SIDEBAR_INVOICE === "default-null" ||
    (process.env.FEATURE_SIDEBAR_INVOICE === "conditional-null" &&
      isBillingActive)
  ) {
    items.push({
      icon: Billing,
      text: "BILLING",
      index:
        process.env.FEATURE_ENDPOINT === "bigrock"
          ? webProRoutes.TRANSACTIONS
          : webProRoutes.INVOICES,
      category: null
    });
  }
  if (isAffiliateEnabled(parentid))
    items.push({
      id: "partner_benifits",
      icon: Services,
      text: "PARTNER BENEFITS",
      index: webProRoutes.SERVICES,
      category: mainCategory.mybusiness.fallbackText,
      beta: true
    });
  if (role === "reseller") {
    items.push({
      icon: Pro,
      text: "PRO-SUITE",
      index: webProRoutes.PROSUITE,
      category: mainCategory.settings.fallbackText
    });
    items.push({
      icon: MyShop,
      text: "MY SHOP",
      index: webProRoutes.MYSHOP,
      category: mainCategory.settings.fallbackText
    });
  }

  return items;
};

export const BuyLinkProps = role =>
  role === "reseller"
    ? { href: endpoints.buy_order, target: "_blank" }
    : {
        className:
          process.env.FEATURE_ENDPOINT === "customer"
            ? css`
                margin-bottom: 40px;
              `
            : "",
        onClick: e => {
          let externalAccessManager = Syringe.inject("externalAccessManager");
          const autoLoginWindow = externalAccessManager.createWindow("_blank");
          try {
            getSuperSiteUrl().then(currentURL =>
              User.token().then(({ TOKEN }) => {
                autoLoginWindow.location.replace(
                  addQueryParamsToUrl(`https://${currentURL}/buy.php`, {
                    r: "customer",
                    p: "home",
                    tid: TOKEN
                  })
                );
              })
            );
          } catch (error) {
            autoLoginWindow.close();
          }
        }
      };
