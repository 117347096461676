export type StringAsBoolean = "t" | "f" | "true" | "false";
export const parseStringAsBoolean = (value: StringAsBoolean) => {
  switch (typeof value === "string" ? value.toLowerCase() : String(value)) {
    case "t":
    case "true":
      return true;
    case "f":
    case "false":
      return false;
    default:
      console.error(`${value} is not a valid boolean.`);
      return false;
  }
};
