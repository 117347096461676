import { Reducer, useMemo, useReducer } from "react";

export type BaseAction<A> = { type: string; payload: A };
type CustomReducer<S, T = any> = Reducer<S, BaseAction<T>>;

export type ReducerFactory<S = any> = Record<string, CustomReducer<S>>;

export type CustomActions = { [x: string]: (payload: any) => void };

type ReducerCreator = <S>(factory: ReducerFactory<S>) => CustomReducer<S>;

type MapDispatch = <S>(
  dispatch: React.Dispatch<any>,
  reducerFactory: ReducerFactory<S>
) => CustomActions;

let createReducer: ReducerCreator = factory => (state, action) =>
  factory[action.type]?.(state, action) ?? state;

let mapDispatch: MapDispatch = (dispatch, reducerFactory) =>
  Object.fromEntries(
    Object.keys(reducerFactory).map(type => [
      type,
      payload => {
        if (process.env.NODE_ENV === "development") {
          console.warn(type, payload);
        }
        dispatch({ type, payload });
      }
    ])
  );
export function useCustomReducer<S = any>(
  factory: ReducerFactory<S>,
  initialState: S
): [S, CustomActions] {
  let [state, dispatch] = useReducer<CustomReducer<S>>(
    useMemo(() => createReducer<S>(factory), [factory]),
    initialState
  );
  return [state, useMemo(() => mapDispatch<S>(dispatch, factory), [factory])];
}
