import { isSuccess } from "common/api-status/utils";
import PlanDetailsFormatters from "common/plan-details/formatters";
import { findByFeature } from "common/plan-details/util";

export const initialState = {
  freeTitanEmail: { showTitan: false, isActivating: false }
};

export const actionTypes = {
  GET_TITAN_MAIL_STATUS: "[Manage Titan] Get Titan Mail Status",
  UPDATE_TITAN_MAIL_STATUS: "[Manage Titan] Update Titan Mail Status"
};

export default (state, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_TITAN_MAIL_STATUS:
    case isSuccess(actionTypes.GET_TITAN_MAIL_STATUS): {
      const { payload, effectResponse } = action;
      const {
        hierarchy: { child, parent }
      } = payload;

      return {
        ...state,
        [parent]: {
          ...state[parent],
          [child]: {
            ...state[parent][child],
            ...effectResponse
          }
        }
      };
    }
    default:
      return state;
  }
};

export const actions = {
  getTitanMailStatus: payload => ({
    type: actionTypes.GET_TITAN_MAIL_STATUS,
    payload
  }),
  updateTitanMailStatus: payload => ({
    type: actionTypes.UPDATE_TITAN_MAIL_STATUS,
    payload: {
      hierarchy: {
        parent: "domain",
        child: "freeTitanEmail"
      }
    },
    effectResponse: payload
  })
};

export const constants = {
  EmailStorage: "email storage (per email account)"
};

export const parsers = {
  detailsParser: payload => {
    const {
      emailaccounts,
      noofaccounts: accounts = emailaccounts,
      used_account_count,
      is_free,
      currentOrderTenure
    } = payload;
    return {
      isTrial: is_free,
      accounts,
      currentOrderTenure,
      noOfAccounts: accounts,
      usedAccounts: used_account_count
    };
  },
  titanFullPricingParser: (payload = {}, { productkey }) => {
    return {
      emailPricing: {
        CostPrice: (payload.reseller_pricing ?? payload)[productkey]?.plans,
        SalePrice: payload.customer_pricing?.[productkey]?.plans
      }
    };
  },
  titanParseStorage: (planDetails, meta) => {
    let storage = "";
    let planid = meta.details?.planid;
    let productPlans = planDetails[meta.productKey];
    let plan = productPlans[planid];
    let feature = PlanDetailsFormatters.formatFeatures(plan).find(
      findByFeature(constants.EmailStorage)
    );
    storage = feature?.value;
    return { emailStorage: { sofiKey: "", text: `${storage} per account` } };
  },
  titanPricingParser: (payload = {}, { productkey }) => {
    const { [productkey]: { plans } = {} } =
      payload.reseller_pricing ?? payload.customer_pricing ?? payload;
    return { emailPricing: plans };
  }
};
