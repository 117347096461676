import { useHover } from "common/hoverable";
import { OptionsPopover } from "common/popover";
import { colors, fonts } from "common/styles";
import { getIcon } from "common/util";
import { styled } from "linaria/react";
import React from "react";

const LowFundsDialogue = styled.div`
  border-radius: 2px;
  padding: 13px 18px;
  font-size: 13px;
  margin: 3px 113px 30px 40px;
  line-height: 20px;
  position: relative;
  background-color: ${colors.yellow.light};
  border: 1px solid ${colors.yellow.dark};

  & > div::after,
  & > div::before {
    bottom: 100%;
    left: 38px;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  & > div::after {
    border-color: rgba(255, 247, 224, 0);
    border-bottom-color: ${colors.yellow.light};
    border-width: 9px;
    margin-left: -4px;
  }
  & > div::before {
    border-color: rgba(255, 232, 173, 0);
    border-bottom-color: ${colors.yellow.dark};
    border-width: 10px;
    margin-left: -5px;
  }

  .region-popover-top-space-adjuster {
    display: none;
  }

  .add-funds-warning {
    box-shadow: 0 0 6px 0 rgba(211, 211, 218, 0.6);
    top: 37px;
    left: 0px;
    padding: 0 25px;
    width: 304px;
  }
  .add-funds-warning::after,
  .add-funds-warning::before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .add-funds-warning::after {
    border-bottom-color: ${colors.white.regular};
    border-width: 5px;
    margin-left: -125px;
  }
  .add-funds-warning::before {
    border-color: transparent;
    border-bottom-color: ${colors.white.regular};
    border-width: 8px;
    margin-left: -126px;
  }
`;

const ProductCard = styled.div`
  display: grid;
  grid-template-columns: 39px auto;
  border-bottom: 1px solid ${colors.gray.gray400};
  :last-child {
    border: none;
  }
  .icon-holder {
    padding-top: 20px;
  }
  .domain-name {
    color: ${colors.black.regular};
    font-family: "Open Sans";
    font: 600 13px/1 ${fonts.OpenSans};
    letter-spacing: 0;
    margin-bottom: 0;
    margin-top: 19px;
  }
  .product-description {
    color: #6a7489;
    font: normal 11px/15px ${fonts.OpenSans};
    letter-spacing: 0;
    margin: 6px 0 17px;
    text-transform: uppercase;
  }
`;

const HoverTrigger = styled.span`
  display: inline-block;
  position: relative;
  text-decoration: underline;
  cursor: pointer;
`;

const AutomaticProvisionWarning = ({ onholdOrders = [] }) => {
  const { hovered, onMouseEnter, onMouseLeave } = useHover();

  return onholdOrders.length > 0 ? (
    <LowFundsDialogue>
      <div>
        *The amount added will automatically provision{" "}
        <HoverTrigger onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {" "}
          orders on-hold
          {hovered && (
            <OptionsPopover title={null} className="add-funds-warning">
              {onholdOrders.map(order => (
                <ProductCard key={order.orderId}>
                  <div className="icon-holder">
                    <img
                      src={getIcon(order.productKey)}
                      alt={order.productcategory}
                    />
                  </div>
                  <div>
                    <p className="domain-name">{order.domainName}</p>
                    <p className="product-description">{order.description}</p>
                  </div>
                </ProductCard>
              ))}
            </OptionsPopover>
          )}
        </HoverTrigger>{" "}
        due to low funds. Your customers paid for these orders, but they were
        put on-hold because of your insufficient funds.
      </div>
    </LowFundsDialogue>
  ) : null;
};

export default AutomaticProvisionWarning;
