import { isFailure, isSuccess } from "common/api-status/utils";
export const actionTypes = {
  SHOW_SHELL_ENABLE_MODAL: "[Shell access] Show enable modal",
  SHOW_SHELL_DISABLE_MODAL: "[Shell access] Show disable modal",

  ENABLE_SHELL_ACCESS: "[Shell access] Enable",

  DISABLE_SHELL_ACCESS: "[Shell access] Disable"
};

export const initialState = {
  shell: {
    checked: false
  }
};

export const actions = {
  showModal: (_, checked, meta) => ({
    type: checked
      ? actionTypes.SHOW_SHELL_ENABLE_MODAL
      : actionTypes.SHOW_SHELL_DISABLE_MODAL,
    ...meta
  }),
  enable: payload => ({
    type: actionTypes.ENABLE_SHELL_ACCESS,
    payload
  }),
  enableSuccess: payload => ({
    type: isSuccess(actionTypes.ENABLE_SHELL_ACCESS),
    ...payload
  }),
  enableFailure: error => ({
    type: isFailure(actionTypes.ENABLE_SHELL_ACCESS),
    error
  }),
  disable: payload => ({
    type: actionTypes.DISABLE_SHELL_ACCESS,
    payload
  }),
  disableSuccess: payload => ({
    type: isSuccess(actionTypes.DISABLE_SHELL_ACCESS),
    ...payload
  }),
  disableFailure: error => ({
    type: isFailure(actionTypes.DISABLE_SHELL_ACCESS),
    error
  })
};

export const parsers = {
  detailsParser: ({ shellaccess = "" }, meta) => ({
    checked: shellaccess.toUpperCase() === "ENABLED"
  })
};
