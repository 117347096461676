export const categories = {
  domains_view: "Domains View",
  be_view: "Business View",
  ee_view: "Enterprise View",
  te_view: "Titan View",
  gsuite_view: "GSuite View",
  sdh_view: "SDH View",
  mdh_view: "MDH View",
  ch_view: "CH View",
  rh_view: "RH View",
  wph_view: "Wordpress view",
  vps_view: "VPS View",
  dedi_view: "Dedi View",
  hgsh_view: "HG Shared Hosting View",
  search: "Search",
  order_micro_states: "Order micro states",
  open_third_party_panel: "Open third party panel",
  settings: "Settings",
  customer_management: "Customer Management",
  download_csv_files: "Download CSV Files",
  renewals: "Renewals",
  shortcuts: "Shortcuts",
  plan_details: "Plan Details View",
  delete_addon: "Delete addon",
  buy_addon: "Buy addon",
  change_domain: "Change Domain",
  move: "Move",
  move_customer_options: "Move - Customer Options"
};

export const actions = {
  //==== Search Bar ====//
  gtm_search_got_focus: "gtm_search_got_focus",
  search_orders_tab: "Search_Orders Tab",
  search_customer_tab: "Search_Customers Tab",
  recent_search_orders_tab: "Recent Search_Orders Tab",
  recent_search_customer_tab: "Recent Search_Customers Tab",
  //==== Order Micro states====//
  suspend: "Suspend ", //For all micro states product_category would be appended dynamically
  lock: "Lock ",
  delete: "Delete ",
  renew: "Renew ",
  //==== Open 3rd party Panel ====//
  open_third_party_panel: "Open third party panel ", // here product_category would be appended dynamically
  //====View PLan details ====//
  view_plan_details: "View Plan details_", // here product_category gets appended dynamically
  //==== Domains Card (OM) ==== //
  name_servers_on_card: "Name_servers_on card",
  edit_name_servers_in_modal: "Edit Name Servers_in modal",
  dns_records_on_card: "DNS Records_on card",
  dns_records_in_modal: "DNS Records_in modal",
  edit_child_name_servers: "Edit Child Name Servers",
  edit_contact_details: "Edit Contact Details",
  buy_privacy_protection: "Buy Privacy Protection",
  privacy_protection_on: "Privacy Protection_ON",
  privacy_protection_off: "Privacy Protection_OFF",
  view_edit_transfer_code: "View/Edit Transfer Code",
  theft_protection_on: "Theft Protection_ON",
  theft_protection_off: "Theft Protection_OFF",
  enable_domain_forwarding: "Enable Domain Forwarding",
  disable_domain_forwarding: "Disable Domain Forwarding",
  manage_domain_forwarding: "Manage Domain Forwarding",
  activate_free_email: "Activate Free Email",
  manage_free_email: "Manage Free Email",
  //==== BE Card (OM) ====//
  total_accounts_be: "Total Accounts_BE",
  buy_more_acccounts_be: "Buy More Accounts_BE",
  delete_accounts_be: "Delete Accounts_BE",
  view_dns_records_be: "View DNS Records_BE",
  //==== EE Card (OM) ====//
  total_accounts_ee: "Total Accounts_EE",
  buy_more_acccounts_ee: "Buy More Accounts_EE",
  delete_accounts_ee: "Delete Accounts_EE",
  view_dns_records_ee: "View DNS Records_EE",
  //==== TE CARD (OM) ====//
  total_accounts_te: "Total Accounts_TE",
  upgrade_to_paid_te: "Upgrade_To_Paid_TE",
  buy_more_acccounts_te: "Buy More Accounts_TE",
  delete_accounts_te: "Delete Accounts_TE",
  view_dns_records_te: "View DNS Records_TE",
  //==== GSuite Card (OM) ====//
  total_accounts_gsuite: "Total Accounts_Gsuite",
  buy_more_acccounts_gsuite: "Buy More Accounts_Gsuite",
  delete_accounts_gsuite: "Delete Accounts_Gsuite",
  //==== SDH Card (OM) ====//
  view_edit_admin_details_sdh: "View/Edit Admin details_SDH",
  view_dedicated_ip_sdh: "View Dedicated IP_SDH",
  view_name_servers_sdh: "View Name Servers_SDH",
  //==== MDH Card (OM) ====//
  view_edit_admin_details_mdh: "View/Edit Admin details_MDH",
  view_dedicated_ip_mdh: "View Dedicated IP_MDH",
  view_name_servers_mdh: "View Name Servers_MDH",
  //==== CH Card (OM) ====//
  view_edit_admin_details_ch: "View/Edit Admin details_CH",
  view_dedicated_ip_ch: "View Dedicated IP_CH",
  view_name_servers_ch: "View Name Servers_CH",
  //==== RH Card (OM) ====//
  buy_cpanel_blocks: "Buy cPanel blocks",
  view_edit_admin_details_rh: "View/Edit Admin details_RH",
  view_dedicated_ip_rh: "View Dedicated IP_RH",
  view_name_servers_rh: "View Name Servers_RH",
  //==== VPS Card (OM) ====//
  view_edit_admin_details_vps: "View/Edit Admin details_VPS",
  view_dedicated_ip_vps: "View Dedicated IP_VPS",
  //==== Dedi Card (OM) ====//
  view_edit_admin_details_dedi: "View/Edit Admin details_Dedi",
  view_dedicated_ip_dedi: "View Dedicated IP_Dedi",
  //==== HG SH Card (OM) ====//
  view_name_servers_hgsh: "View Name Servers_HGSH",
  view_edit_admin_details_hgsh: "View/Edit Admin details_HGSH",
  view_dedicated_ip_hgsh: "View Dedicated IP_HGSH",

  delete_dedicated_ip: "Delete Dedicated IP_",
  buy_whmcs: "Buy WHMCS",
  change_domain: "Change Domain_",
  buy_addon: "Buy addon_",
  delete_addon: "Delete addon_",
  //==== Wordpress ====//
  view_dedicated_ip_wph: "View Dedicated IP_WPH",
  view_name_servers_wph: "View Name Servers_WPH",
  //==== Settings ====//
  change_url_prefix_link: "Change URL prefix link",
  //==== Customer Management ====//
  create_customer: "Create a Customer",
  auto_login_customer_account: "Auto-login to Customer Acc",
  suspend_customer: "Suspend Customer",
  delete_customer: "Delete Customer",
  edit_customer_profile: "Edit Customer Profile",
  change_customer_password: "Change Customer Password",
  change_customer_pin: "Change Customer PIN",
  add_a_customer_dashboard: "Add a Customer_Dashboard",
  add_a_customer_list_orders: "Add a Customer_Orders Tab",
  add_a_customer_order_view: "Add a Customer_Order View",
  move_orders_order_view: "Move Orders_Order View",
  move_orders_renewal_tab: "Add a Customer_Renewal Tab",
  move_orders_to_another_customer_profile:
    "Move Orders to Another Customer_Profile",
  move_create_new_customer: "Move_Create New Customer",
  move_create_existing_customer: "Move_Choose Existing Customer"
};

export const labels = {
  //==== Search Bar ====//
  gtm_search_got_focus: "User clicked on search bar",
  gtm_search_result_clicked: "User clicked on a search result",
  move_add_customer_from_dashboard: "Add Customer From Dashboard",
  move_move_orders_renewal_tab: "Add Customer From Renewal Tab",
  move_add_customer_from_list_orders: "Add Customer From Order Tab",
  move_add_a_customer_order_view: "Add Customer From Order View",
  move_move_orders_order_view: "Move Orders from Order View",
  move_orders_to_another_customer_profile:
    "Move Orders from Order View Profile",
  search_from_dashboard: "Search From Dashboard",
  search_from_orders_tab: "Search From Orders Tab",
  search_from_customer_tab: "Search From Customers Tab",
  recent_search_from_dashboard: "Recent Search From Dashboard",
  recent_search_from_order_tab: "Recent Search From Orders Tab",
  recent_search_customer_tab: "Recent Search From Customers Tab",
  //==== Order Micro states====//
  suspend: "Suspend Order",
  lock: "Lock Order",
  delete: "Delete Order",
  renew: "Renew Order",
  //==== Open 3rd party panel====//
  go_to_hosting_panel: "Go To Hosting Panel",
  view_plan_details: "View Plan details",
  //==== Domains Card (OM) ==== //
  nameservers_dns: "Name Servers & DNS",
  child_name_servers: "Child Name Servers",
  contact_details: "Contact Details",
  privacy_protection: "Privacy Protection",
  transfer_code: "Transfer Code",
  theft_protection: "Theft protection",
  domain_forwarding: "Domain Forwarding",
  free_email: "Free Email",
  //==== BE Card (OM) ====//
  view_email_accounts: "View email accounts",
  add_more_email_accounts: "Add More Email Accounts",
  delete_email_accounts: "Delete Email Accounts",
  hosting_name_servers_info: "Hosting Name Servers info",

  buy_cpanel_blocks: "Buy cPanel blocks",
  //==== EE Card (OM) ====//
  //==== GSuite Card (OM) ====//
  hosting_package_admin_details: "Hosting package Admin Details",
  view_dedicated_ips: "View Dedicated IPs",
  delete_addon: "Delete addon",
  buy_addon: "Buy addon",
  change_domain: "Change domain",
  //==== Settings ====//
  change_settings: "Change Settings"
};

export const GAActionTypes = {
  emitActionType: "[GA] Emit GA event"
};

export const ecommerceEventName = {
  gtmMoveOrders: "Move Orders"
};
