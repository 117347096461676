import { mapEffect } from "map-effect";
import { takeEvery } from "redux-saga/effects";
import { actionTypes } from "./ducks";
import { services } from "./services";

function getThreats(action) {
  return services.getThreats(action.meta);
}

function deleteThreat(action) {
  return services.deleteThreat(action.meta);
}

deleteThreat.onSuccess = action => action.meta.deleteThreat();

export default [
  takeEvery(actionTypes.GET_THREATS, mapEffect(getThreats)),
  takeEvery(actionTypes.DELETE_THREAT, mapEffect(deleteThreat))
];
