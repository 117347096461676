import * as React from "react";

function LanguageDropdownTick(props) {
  return (
    <svg width="14px" height="14px" viewBox="0 0 14 14" {...props}>
      <g
        transform="translate(-760 -436) translate(447 247) translate(30 165) translate(20 20.757) translate(263 3.243)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <circle fill="#28BD8B" cx={7} cy={7} r={7} />
        <path
          d="M10.381 4.17L5.83 8.722l-1.84-1.84a.579.579 0 00-.82.82L5.42 9.95a.577.577 0 00.82 0L11.2 4.987a.579.579 0 10-.819-.818z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export default LanguageDropdownTick;
