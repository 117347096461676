import { isSuccess, isFailure } from "common/api-status/utils";
export const actionTypes = {
  MODIFY_TRANSFER_SECRET_REQUEST: "[Transfer secret] Modify Transfer Secret",
  SHOW_WIDGET_TRANSFER_SECRET_MODAL:
    "[Domain] Show widget transfer secret modal"
};

export const actions = {
  modifyTransferSecret: payload => ({
    type: actionTypes.MODIFY_TRANSFER_SECRET_REQUEST,
    payload
  }),
  modifyTransferSecretSuccess: (response, payload) => ({
    type: isSuccess(actionTypes.MODIFY_TRANSFER_SECRET_REQUEST),
    value: response,
    ...payload
  }),
  modifyTransferSecretFailure: error => ({
    type: isFailure(actionTypes.MODIFY_TRANSFER_SECRET_REQUEST),
    error
  }),
  showTransferSecretModal: ({ meta }) => ({
    type: actionTypes.SHOW_WIDGET_TRANSFER_SECRET_MODAL,
    ...meta
  })
};

export const initialState = {
  transfer: { secret: "" }
};

export const parsers = {
  widgetTranferParser: (payload, meta) =>
    payload.domsecret ? { secret: payload.domsecret } : {}
};
