let kbLinks = {};

switch (process.env.FEATURE_ENDPOINT) {
  case "reseller":
  case "customer":
    kbLinks = {
      understandingSNI: "/kb/understanding-sni",
      understandingCloudHostingSNI: "/kb/sni-for-cloud-hosting",
      domainProtect: "/kb/node/3463",
      privacyProtection: "/kb/privacy-protection",
      childNameServers: "/kb/set-up-child-nameservers",
      transferDomainName: "/kb/transferring-a-domain-name",
      generateCSR: "/kb/generate-csr",
      sslVerificationMethod: "/kb/ssl-verification-methods",
      addCnameRecord: "/kb/ssl-verification-methods#cname-verification",
      createPath: "/kb/ssl-verification-methods#http-verification",
      evSSL: "/kb/what-is-ev-ssl",
      installCertificate: "/kb/install-a-certificate",
      theftProtection: "/kb/protecting-a-domain-name",
      modifyDomainContactDetails: "/kb/modifying-domain-contact-details",
      verifyRegistrantDetails: "/kb/whois-data-verification",
      configureNameServersAndDNS: "/kb/changing-nameservers-of-your-domain",
      twoYearSSLDetails: "https://manage.india.resellerclub.com/kb/node/3263",
      secureBrandedUrl: "/kb/how-to-secure-your-branded-url",
      manageBlockStorage: "/kb/manage-block-storage",
      premiumDNS: "/kb/node/3345",
      paymentGatewaysLink: {
        paypalec: "/kb/Integration-with-paypal-gateway",
        "payu.in": "/kb/Integration-with-payu-gateway",
        ccavenue: "/kb/Integration-with-ccavenue-gateway"
      },
      gworkspaceAgreemet:
        "/servlet/ViewAgreementServlet?requestfor=resellergappsagreement",
      resellermasteragreement:
        "/servlet/ViewAgreementServlet?requestfor=resellermasteragreement",
      managedServicesAddon: null,
      troubleshootBackup: "/kb/acronis-linux-server-troubleshooting",
      manageBackups: "/kb/node/3387"
    };
    break;
  case "bigrock":
    let baseUrl = "/kb/";
    kbLinks = {
      understandingSNI: baseUrl + "answer/2643",
      understandingCloudHostingSNI: baseUrl + "answer/2643",
      privacyProtection: baseUrl + "servlet/KBServlet/cat299.html",
      domainProtect: baseUrl + "node/3463",
      childNameServers: baseUrl + "servlet/KBServlet/faq456.html",
      transferDomainName: baseUrl + "servlet/KBServlet/cat121.html",
      generateCSR: baseUrl + "servlet/KBServlet/faq853.html",
      sslVerificationMethod: baseUrl + "node/3363",
      addCnameRecord: baseUrl + "node/3363#ssl-cname-verification",
      createPath: baseUrl + "node/3363#ssl-http-verification",
      evSSL: baseUrl + "node/3369",
      installCertificate: baseUrl + "answer/2392",
      theftProtection: baseUrl + "servlet/KBServlet/faq457.html",
      modifyDomainContactDetails: baseUrl + "servlet/KBServlet/faq452.html",
      verifyRegistrantDetails: baseUrl + "answer/2008",
      configureNameServersAndDNS: baseUrl + "servlet/KBServlet/faq455.html",
      twoYearSSLDetails: baseUrl + "node/3263",
      manageBlockStorage: baseUrl + "node/3305",
      premiumDNS: baseUrl + "node/3345",
      managedServicesAddon: "/storefront/managed-vps-hosting",
      troubleshootBackup: baseUrl + "acronis-linux-server-troubleshooting",
      manageBackups: baseUrl + "node/3387",
      autoPayUPI: baseUrl + "upi-autopay"
    };
    break;
  default:
    break;
}

export default kbLinks;
