import React from "react";
import "./styles.scss";
import classnames from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export let AutoRenewSlider = ({
  sliderStyle = {},
  tooltipText = "You can't change auto renew for this order.",
  screen,
  disabled,
  ...inputProps
}) => (
  <OverlayTrigger
    trigger="click"
    placement="bottom"
    rootClose
    overlay={
      disabled ? (
        <Tooltip id="auto-renew-slider">{tooltipText}</Tooltip>
      ) : (
        <div />
      )
    }
  >
    <div className={classnames("slider-control", screen)} style={sliderStyle}>
      <label>
        <input
          type="checkbox"
          data-testid="auto-renewal-status"
          disabled={disabled}
          {...inputProps}
        />
        <div />
      </label>
    </div>
  </OverlayTrigger>
);
