import { isSuccess } from "common/api-status/utils";
import { mergeWith } from "lodash";

export const actionTypes = {
  GET_DETAILS: "[Profile Details] Get",
  MODIFY_DETAILS: "[Profile Details] Update"
};

export const actions = {
  getDetails: () => ({ type: actionTypes.GET_DETAILS }),
  modifyDetails: (payload, onSuccess = () => {}, successMessage) => ({
    type: actionTypes.MODIFY_DETAILS,
    onSuccess,
    successMessage,
    payload
  })
};

export const initialState = {
  profileInfo: {},
  parentInfo: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case isSuccess(actionTypes.GET_DETAILS):
      return {
        profileInfo: helpers.modifyProfileResponse(
          action.effectResponse.profileDetails
        ),
        parentInfo: action.effectResponse.parentInfo
      };

    default:
      return state;
  }
};

const helpers = {
  modifyProfileResponse(response) {
    const defaultValues = {
      address1: "",
      address2: "",
      address3: "",
      name: "",
      company: "",
      username: "",
      useremail: "",
      pin: "",
      city: "",
      zip: "",
      telnocc: "",
      telno: "",
      langpref: "",
      alttelnocc: "",
      alttelno: "",
      faxnocc: "",
      faxno: "",
      mobileno: "",
      mobilenocc: "",
      state: "",
      country: "",
      ussalestaxid: null,
      russiavatid: null,
      indiangstid: null,
      australiagstid: null,
      newzealandgstid: null,
      singaporegstid: null,
      vatid: null
    };
    return mergeWith(response, defaultValues, (responseValue, defaultValue) =>
      !!responseValue && responseValue !== "null" ? responseValue : defaultValue
    );
  }
};
