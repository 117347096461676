import { useEffect, useState } from "react";

/**
 * Runs a media query and returns its value when it changes.
 *
 * @param {string} [query] Media Query.
 * @return {boolean} return value of the media query.
 */
export default function useMediaQuery(query) {
  const [match, setMatch] = useState(
    () =>
      !!(
        query &&
        typeof window !== "undefined" &&
        window.matchMedia(query).matches
      )
  );

  useEffect(() => {
    if (!query) {
      return;
    }
    const updateMatch = () => setMatch(window.matchMedia(query).matches);
    updateMatch();
    const list = window.matchMedia(query);
    list.addEventListener("change", updateMatch);
    return () => {
      list.removeEventListener("change", updateMatch);
    };
  }, [query]);

  return !!query && match;
}
