import { styled } from "linaria/react";
import { fonts, colors } from "common/styles/index";

export let SidebarBeta = styled.span`
  border-radius: 7px 9px 9px 0;
  width: 29px;
  height: 14px;
  background-color: ${colors.red.regular};
  color: ${colors.white.regular};
  font: 900 8px/1 ${fonts.BrandonGrotesque};
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all linear;
  text-indent: 0px;
  margin-left: 0;
`;

export let SidebarNavList = styled.div`
  padding: 0;
  transition: 0.2s all linear;
  top: 0;
  position: relative;
  background: ${colors.white.regular};
  box-shadow: 0 2px 4px 0 #d3d3da;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow-x: scroll;
  // ccp responsive css starts
  @media (width < 768px) {
    &.sidebar_ccp_nav_list {
      position: fixed;
      height: auto;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      top: initial;
      bottom: 0;
      padding: 10px 15px;
      border-top: 2px solid #468def;
      border-radius: 5px;
    }
  }
  // ccp responsive css ends

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export let SideBarWallet = styled.div`
  position: relative;
`;

export let SidebarNavListTop = styled.div`
  padding-top: 7px;
`;
export let SidebarNavListBottom = styled.div`
  width: 100%;
  padding: 25px 18px 25px 20px;
  transition: 0.2s all linear;
  // ccp responsive css starts
  @media (width < 768px) {
    &.ccp_sidebar_nav_list_bottom {
      display: none;
    }
  }
  // ccp responsive css ends
`;
export let SidebarText = styled.div`
  font: 500 12px/1 ${fonts.BrandonGrotesque};
  color: ${colors.black.regular};
  text-transform: uppercase;
  margin-left: 15px;
  transition: 0.2s all linear;
  min-width: 70px;
  opacity: 1;
  letter-spacing: 0.5px;
  .sidebar-arrow {
    position: absolute;
    right: 20px;
  }
  .notification-dot {
    height: 7px;
    position: relative;
    right: -3px;
    top: -5px;
    width: 6px;
  }
  .child-notification-dot {
    position: absolute;
    top: 0;
    right: -10px;
  }
`;
export let SidebarItem = styled.div`
  a {
    display: flex;
    text-decoration: none;
    padding: 15px 20px 14px 19px;
    transition: 0.2s width, height, border-radius, padding linear;
    position: relative;
    border-radius: 0 100px 100px 0;
    margin-right: 10px;
    width: 198px;
    height: 48px;
    align-items: center;

    &:hover {
      background-color: #f5f5f5;
    }
    #fill-area {
      fill: #bbbbca;
    }

    &.selected-option {
      background-color: #e9f2ff;
      &.submenu-arrow {
        background: #e9f2ff;
      }
      &.submenu-up-arrow {
        background: #e9f2ff;
      }
      #fill-area {
        fill: ${colors.blue.regular};
      }
      #partner-benefit {
        fill: ${colors.blue.regular};
        stroke: ${colors.blue.regular};
        path {
          fill: ${colors.blue.regular};
          stroke: ${colors.blue.regular};
        }
      }
      .sidebar-text {
        font-weight: 700;
      }
    }
    &.submenu {
      padding: 15px 20px 5px 40px;
      height: auto;
      div {
        font: 500 11px/1 ${fonts.BrandonGrotesque};
        position: relative;
      }
      &:hover {
        background-color: #fff;
        div {
          color: ${colors.blue.regular};
        }
      }
      &.active {
        div {
          color: ${colors.blue.regular};
        }
      }
    }
  }
  // ccp responsive css starts
  @media (width < 768px) {
    &.ccp_sidebar_item {
      display: flex;
      flex-wrap: wrap;
    }
  }
  // ccp responsive css ends
`;
export let SidebarIcon = styled.div`
  display: flex;
  align-items: center;
  svg {
    min-width: 20px;
  }
  // ccp responsive css starts
  @media (width < 768px) {
    &.ccp_sidebar_icon_mobile {
      svg {
        height: 2rem !important;
        width: 2.5rem !important;
      }
    }
  }
  // ccp responsive css ends
`;

export let SidebarCategoryText = styled.div`
  font: 500 11px/1.5 ${fonts.BrandonGrotesque};
  text-transform: uppercase;
  letter-spacing: 0.2px;
  color: ${colors.gray.gray700};
  transition: 0.2s all linear;
  min-width: 100px;
  letter-spacing: 0.46px;
  margin: 0 0 2px 18px;
  opacity: 1;
`;

export let SidebarSeparator = styled.div`
  border-bottom: 1px solid #f0f0f5;
  margin-top: 7px;
`;

export let SidebarBuyButton = styled.div`
  width: 100%;
  padding: 0 20px;
  transition: 0.2s all linear;
  a {
    background: ${colors.blue.regular};
    border-radius: 2px;
    font: 600 12px/1.5 ${fonts.OpenSans};
    color: ${colors.white.regular};
    border: 0;
    text-align: center;
    padding: 10px 15px;
    width: 100%;
    transition: 0.2s all linear;
    display: block;
    position: relative;
    &:hover,
    &:focus,
    &:active {
      color: ${colors.white.regular};
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: 5px;
      }
    }
  }
`;

export let SidebarSupportButton = styled.div`
  width: 100%;
  position: relative;
  left: 0;
  margin: 80px 0 20px 0;
  // bottom: 150px;
  padding: 0 20px;
  transition: 0.2s all linear;
  a {
    background: transparent;
    border-radius: 2px;
    font: 600 12px OpenSans;
    color: #000000;
    border: 1px solid #468def;
    text-align: center;
    color: #468def;
    padding: 10px;
    width: 100%;
    line-height: 17px;
    transition: 0.2s all linear;
    display: block;
    position: relative;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 17px;
        margin-right: 5px;
      }
    }
  }
  @media (width < 768px) {
    position: relative;
    margin: 0;
    width: max-content;
  }
`;

export let SidebarCostWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  transition: 0.2s all linear;
`;
export let SidebarCurrency = styled.div`
  margin-right: 7px;
  span {
    display: flex;
    width: 22px;
    height: 22px;
    background-color: #d6ffdb;
    border-radius: 22px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 3px;
  }
`;
export let SidebarWalletActions = styled.div`
  position: relative;
  min-width: 150px;
  display: flex;
  align-items: center;
`;

export let SidebarAddFundsSeparator = styled.div`
  margin-right: 9px;
  border-right: 1px solid ${colors.gray.gray800};
  margin-left: 9px;
  border-radius: 0.5px;
  height: 12px;
`;
export let SidebarAddfunds = styled.button`
  font: 12px/13px ${fonts.OpenSans};
  color: ${colors.gray.gray800};
  text-decoration: none;
  border: 0;
  outline: none;
  padding: 0;
  background: transparent;
  display: inline-block;
  border-bottom: 1px rgba(109, 118, 135, 0.7) solid;
`;
export let SidebarWalletAmount = styled.div`
  font: 600 13px/1.5 ${fonts.OpenSans};
  color: ${colors.gray.gray900};
  margin-top: 4px;
`;

export let SidebarNav = styled.div`
  max-width: 198px;
  background: ${colors.white.regular};
  width: 100%;
  transition: 0.2s all linear;
  height: calc(100vh - 70px);
  position: sticky;
  top: 0;
  z-index: 4;
  margin-right: 4px;
  .sidebar-category {
    margin: 18px 0 7px;
    #partner_benifits {
      .sidebar-text {
        width: 80px;
      }
    }
    @media (width < 768px) {
      margin: 0 !important;
    }
  }
  &.fixed {
    height: 100vh;
    @media (width < 768px) {
      z-index: 1000;
    }
  }
  &.is-collapsed {
    max-width: 60px;

    .sidebar-category {
      margin-bottom: 26px;
    }
    .sidebar-text {
      display: none;
    }

    .sidebar-category-text {
      display: none;
    }

    .sidebar-buy-button {
      padding: 0 8px;
      bottom: 121px;
      a {
        padding: 10px 7px;
        font-size: 11px;
        line-height: 15px;
        svg {
          width: 8px;
          margin-right: 1px;
        }
        @media (width < 768px) {
          padding: 10px 15px;
          border-radius: 10px;
          font-size: 13px;
          letter-spacing: 1px;
          line-height: normal;
        }
      }
    }

    .sidebar-support-button {
      padding: 0 8px;
      a {
        font-size: 0;
        img {
          height: 17px;
          margin-right: 1px;
          @media (width < 768px) {
            height: 24px;
            margin-right: 0;
          }
        }
        @media (width < 768px) {
          border-radius: 10px;
        }
      }
      @media (width < 768px) {
        bottom: 90px;
        padding: 0 12px;
      }
    }

    .sidebar-nav-list-bottom {
      padding: 25px 8px;
    }

    .sidebar-wallet-actions {
      display: none;
    }

    .sidebar-cost-wrapper {
      display: block;
      text-align: center;
    }

    .sidebar-currency {
      margin-right: 0;
      margin-bottom: 2px;
      span {
        margin: auto;
      }
    }

    .sidebar-wallet-amount {
      margin-top: 6px;
    }

    .sidebar-beta {
      position: absolute;
      text-indent: -9999px;
      width: 12px;
      height: 12px;
      border-radius: 8px;
      border: 2px solid ${colors.white.regular};
      right: 4px;
      top: 4px;
    }

    .sidebar-item {
      a {
        margin-bottom: 7px;
        margin-left: 10px;
        border-radius: 2px;
        padding: 0;
        background: transparent;
        width: 38px;
        height: 38px;

        .sidebar-icon {
          border-radius: 2px;
          padding: 0;
          width: 38px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (width < 768px) {
            padding: 0px 5px;
            width: auto;
          }
        }

        &.selected-option {
          background-color: #e9f2ff;

          &:hover {
            background-color: #e9f2ff;
          }
        }
        &:hover {
          background-color: #f5f5f5;
        }

        @media (width < 768px) {
          border-radius: 10px;
          width: auto;
          padding: 0 5px;
          margin-bottom: initial;
          height: 50px;
        }
      }
    }

    .sidebar-nav-list-top {
      padding-top: 13px;
      @media (width < 768px) {
        padding-top: 0 !important;
      }
    }

    .sidebar-cta-container {
      display: flex;
      align-items: center;
    }

    .sidebar-separator {
      margin-top: 11px;
      margin-bottom: 27px;

      &.settings {
        margin-bottom: 10px;
        + .sidebar-category {
          margin-top: 0;
        }
      }
    }

    ${SidebarBeta} {
      margin-left: -6px;
      text-indent: -9999px;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      position: relative;
      left: -6px;
      top: -8px;
    }
    @media (width < 768px) {
      margin: 0;
    }
  }
  @media (height < 450px) {
    height: 130vh sidebar-support-button;
  }
`;

export let SidebarLanguageSwitch = styled.button`
  height: 63px;
  width: 100%;
  padding: 19px 18px 20px 51px;
  transition: 0.2s all linear;
  border: none;
  border-top: 1px solid #f0f0f5;
  background-color: #fff;
  text-align: left;
  font-size: 13px;
  svg:nth-child(1) {
    position: absolute;
    left: 22px;
    bottom: 21px;
  }
  svg:nth-child(2) {
    position: absolute;
    right: 20px;
  }
`;
