import { isSuccess } from "common/api-status/utils";
import { call, put, takeEvery } from "redux-saga/effects";
import { actions, actionTypes } from "./ducks";
import { actions as commonActions } from "widgets/common/ducks";
import { services } from "./services";
import { getOrderDetails, getPricingFromProductKey } from "common/orders/sagas";

export default [
  takeEvery(actionTypes.DELETE_ADDON, deleteAddon),
  takeEvery(actionTypes.GET_PLAN_DETAILS_ALLOWED_ADDONS, getAddonPlanDetails),
  takeEvery(
    isSuccess(actionTypes.GET_PLAN_DETAILS_ALLOWED_ADDONS),
    getAddonPricingDetails
  )
];

export function* deleteAddon(action) {
  try {
    yield services.deleteAddon(action.payload);
    yield put(actions.deleteAddonSuccess(action.payload));
  } catch (error) {
    yield put(actions.deleteAddonFailure(error, action.payload));
  }
}

function* getAddonPricingDetails(action) {
  try {
    const pricing = yield getPricingFromProductKey(
      action.orderDetails.productkey
    );

    yield put(
      actions.getAddonPricingDetailsSuccess(
        pricing,
        action.response,
        action.payload
      )
    );
  } catch (error) {
    yield put(
      actions.getAddonPricingDetailsFailure(
        error,
        action.response,
        action.payload
      )
    );
  }
}

function* getAddonPlanDetails(action) {
  let orderDetails = yield call(getOrderDetails, action.payload, {
    forceReload: false,
    getRawData: true
  });
  yield put(
    commonActions.setupState({
      meta: action.payload,
      hierarchy: action.payload
    })
  );
  try {
    const response = yield services.getPlanDetails(action.payload);
    yield put(
      actions.getDetailsOfAllowedAddonsSuccess(
        response,
        orderDetails,
        action.payload
      )
    );
  } catch (error) {
    yield put(
      actions.getDetailsOfAllowedAddonsFailure(
        error,
        orderDetails,
        action.payload
      )
    );
  }
}
