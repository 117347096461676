export const actionTypes = {
  OPEN_PRIVACY_PROTECTION_UPSELL_MODAL: "[UPSELL] Open Privacy Protection Modal"
};

export const actions = {
  openUpsellPrivacyProtectionModal: payload => ({
    type: actionTypes.OPEN_PRIVACY_PROTECTION_UPSELL_MODAL,
    ...payload
  })
};
