import React from "react";

export function Home(props) {
  return (
    <svg {...props} width="20px" height="18px" viewBox="0 0 20 18">
      <g
        id="fill-area"
        fill="none"
        fillRule="evenodd"
        transform="translate(-20.000000, -33.000000)"
      >
        <g transform="translate(20.000000, 34.000000)">
          <g transform="translate(2.000000, 0.000000)">
            <path d="M0.551724138,5.20689655 L15.4482759,5.20689655 L14.8814691,15.1260153 C14.8512257,15.6552747 14.4132206,16.0689655 13.8830977,16.0689655 L1.83390562,16.0689655 C1.29274296,16.0689655 0.849769014,15.6384672 0.83431353,15.0975253 L0.551724138,5.20689655 Z M8,13.5862069 C9.19979101,13.5862069 10.1724138,12.6135841 10.1724138,11.4137931 C10.1724138,10.2140021 9.19979101,9.24137931 8,9.24137931 C6.80020899,9.24137931 5.82758621,10.2140021 5.82758621,11.4137931 C5.82758621,12.6135841 6.80020899,13.5862069 8,13.5862069 Z" />
            <path d="M8.70801155,-0.567891119 L16.965889,5.85490247 C17.4018362,6.19397251 17.4803707,6.82224811 17.1413006,7.2581953 C16.9518444,7.50178184 16.6605388,7.64425469 16.3519484,7.64425469 L-0.163806538,7.64425469 C-0.716091288,7.64425469 -1.16380654,7.19653944 -1.16380654,6.64425469 C-1.16380654,6.33566425 -1.02133369,6.04435867 -0.777747151,5.85490247 L7.48013032,-0.567891119 C7.8412408,-0.848754829 8.34690106,-0.848754829 8.70801155,-0.567891119 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
