let actionTypes = {
  SELECTED: "[Listbox] Selected",
  MOVE_DOWN: "[Listbox] Move down",
  MOVE_UP: "[Listbox] Move up",
  TOGGLE: "[Listbox] Toggle options",
  CLOSE: "[Listbox] Close options",
  FOCUS: "[Listbox] Focus",
  RESET: "[Listbox] Reset Dropdown"
};

export let actions = {
  selectItem: selectedIndex => ({
    type: actionTypes.SELECTED,
    selectedIndex
  }),
  moveDown: () => ({ type: actionTypes.MOVE_DOWN }),
  moveUp: () => ({ type: actionTypes.MOVE_UP }),
  toggle: () => ({ type: actionTypes.TOGGLE }),
  focus: id => ({ type: actionTypes.FOCUS, id }),
  close: id => ({ type: actionTypes.CLOSE, id }),
  reset: defaultSelectedIndex => ({
    type: actionTypes.RESET,
    defaultSelectedIndex
  })
};

export function reducer(state, action) {
  switch (action.type) {
    case actionTypes.SELECTED: {
      let { selectedIndex } = action;
      return {
        activeChild: 0,
        selected: selectedIndex > 0 ? selectedIndex - 1 : state.selected,
        expand: false
      };
    }
    case actionTypes.RESET: {
      let { defaultSelectedIndex } = action;
      return {
        activeChild: 0,
        selected: defaultSelectedIndex,
        expand: false
      };
    }
    case actionTypes.MOVE_DOWN: {
      return { ...state, activeChild: state.activeChild + 1 };
    }
    case actionTypes.MOVE_UP: {
      let index = state.activeChild;
      return index === 0 ? state : { ...state, activeChild: index - 1 };
    }
    case actionTypes.FOCUS: {
      return { ...state, activeChild: action.id };
    }

    case actionTypes.TOGGLE: {
      return { ...state, expand: !state.expand };
    }
    case actionTypes.CLOSE: {
      return { ...state, expand: false };
    }
    default:
      return state;
  }
}
