import { mapEffect } from "map-effect";
import { takeEvery } from "redux-saga/effects";
import { actionTypes } from "./ducks";
import services from "./services";

export default [
  takeEvery(actionTypes.GSUITE_ADMIN_SETUP_REQUEST, mapEffect(onSetupGsuite))
];

function onSetupGsuite(action) {
  return services.setupGsuiteAdmin(action.meta, action.values);
}
