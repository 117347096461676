import { withBillingPanda } from "common/feature-flag";
import { withWalletInfo } from "common/wallet";
import { actions as addFundsActions } from "modals/add-funds/ducks";
import { connect } from "react-redux";
import SideBar from "./component";
import { actions } from "./ducks";

const mapDispatchToProps = {
  ...actions,
  showAddFunds: addFundsActions.showModal
};

const mapStateToProps = state => ({
  userId: state.root.userInfo.userid,
  ...state.sideBar
});

export default withWalletInfo(
  withBillingPanda(connect(mapStateToProps, mapDispatchToProps)(SideBar))
);
