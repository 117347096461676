import React from "react";

export function Orders(props) {
  return (
    <svg {...props} width="20px" height="18px" viewBox="0 0 20 18">
      <g
        id="fill-area"
        transform="translate(-20.000000, -103.000000)"
        fillRule="evenodd"
      >
        <g transform="translate(20.000000, 34.000000)">
          <g transform="translate(0.000000, 69.000000)">
            <path d="M10.4569674,0.324769672 L18.2602664,4.32578795 C18.7517162,4.57777109 18.9458423,5.18044259 18.6938592,5.67189243 C18.5970397,5.86072218 18.4426612,6.01385714 18.2530546,6.10914623 L10.4497443,10.0307938 C10.1668241,10.1729787 9.83328092,10.1727701 9.55053881,10.0302313 L1.77151012,6.10858948 C1.27834901,5.85997217 1.0801071,5.25864195 1.32872441,4.76548084 C1.42386344,4.57676162 1.57634753,4.42303696 1.76428968,4.32637194 L9.54332961,0.325347947 C9.82998233,0.177912735 10.1701282,0.177697444 10.4569674,0.324769672 Z" />
            <path d="M2.12490354,7.70910041 L1.29864825,8.23171976 C0.894016359,8.48765574 0.773474503,9.02315179 1.02941048,9.42778368 C1.11375424,9.56113023 1.23289124,9.66892943 1.37398046,9.73956232 L9.60665857,13.8610523 C9.8931692,14.0044869 10.2310611,14.0019453 10.5153816,13.854217 L18.6148743,9.64585301 C19.0258049,9.43234023 19.1858436,8.92612891 18.9723308,8.51519839 C18.8965451,8.36933992 18.7795607,8.24897283 18.6359212,8.16906131 L17.7353178,7.66802518 C17.4463714,7.50727453 17.0966438,7.49974626 16.8010476,7.64791398 L10.4827921,10.8149421 C10.2043953,10.9544886 9.87687899,10.9563733 9.5968947,10.8200399 L3.09725203,7.65515437 C2.78568317,7.50344144 2.41777779,7.52385289 2.12490354,7.70910041 Z" />
            <path d="M2.12490354,11.5388876 L1.29864825,12.061507 C0.894016359,12.317443 0.773474503,12.852939 1.02941048,13.2575709 C1.11375424,13.3909175 1.23289124,13.4987167 1.37398046,13.5693496 L9.60665857,17.6908395 C9.8931692,17.8342741 10.2310611,17.8317325 10.5153816,17.6840042 L18.6148743,13.4756402 C19.0258049,13.2621275 19.1858436,12.7559161 18.9723308,12.3449856 C18.8965451,12.1991272 18.7795607,12.0787601 18.6359212,11.9988485 L17.7353178,11.4978124 C17.4463714,11.3370618 17.0966438,11.3295335 16.8010476,11.4777012 L10.4827921,14.6447294 C10.2043953,14.7842759 9.87687899,14.7861605 9.5968947,14.6498271 L3.09725203,11.4849416 C2.78568317,11.3332287 2.41777779,11.3536401 2.12490354,11.5388876 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
