import api from "api";
import endpoints from "constants/endpoints";

const Endpoints = {
  FEEDBACK: `${endpoints.zuulOrderBox}v1/feedback`
};

type Feedback = {
  app: string;
  category: string;
  userId: number;
  reason: string;
  role: Role;
};

export const FeedbackSdk = {
  /**
   *
   * Records a new feedback for a given user and app.
   *
   * | Roles    |
   * | -------- |
   * | Reseller |
   *
   * #### Usage
   *
   * ```js
   * await HttpApi.Feedback.accept({ app: "WebPro", category: "General", reason, role: "reseller", userId });
   * ```
   *
   */
  async accept(feedback: Feedback) {
    return api.postBody<{ "feedback-id": number }>(
      Endpoints.FEEDBACK,
      feedback
    );
  },
  /**
   *
   * Fetch feedback for a given userId.
   *
   * | Roles    |
   * | -------- |
   * | Reseller |
   *
   * #### Usage
   *
   * ```js
   * const response = await HttpApi.Feedback.fetch(userInfo.userid);
   * for (let feedback of response["feedback-list"]) {
   *    console.log("Feedback for ", feedback.app, " with ", feedback.reason);
   * }
   * ```
   *
   */
  async fetch(userid: string) {
    return api.get<{ "feedback-list": Feedback[] }>(
      `${Endpoints.FEEDBACK}/search`,
      { userid }
    );
  }
};
