import React from "react";

export function Billing(props) {
  return (
    <svg {...props} width="20px" height="17px" viewBox="0 0 20 17">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="fill-area"
          transform="translate(-20.000000, -169.000000)"
          fillRule="nonzero"
        >
          <g id="billing" transform="translate(20.000000, 169.000000)">
            <path d="M0.104166667,0.0208333333 L19.875,0.0208333333 L19.875,1.25 L17.4791667,1.25 L17.4791667,14.8125 L16.25,16.0416667 L15.6458333,16.6458333 L13.7708333,14.7708333 L11.8958333,16.6458333 L10.0208333,14.7708333 L8.14583333,16.6458333 L6.27083333,14.7708333 L4.39583333,16.6458333 L2.5625,14.8125 L2.5625,1.25 L0.104166667,1.25 L0.104166667,0.0208333333 Z M9.29166667,9.3125 C9.14583333,9.16666667 9.04166667,9.02083333 9.02083333,8.83333333 L7.89583333,9 C7.95833333,9.45833333 8.1875,9.875 8.5625,10.1875 C8.8125,10.375 9.08333333,10.5208333 9.39583333,10.625 L9.39583333,11.7083333 L10.5,11.7083333 L10.5,10.6458333 C10.8541667,10.5625 11.2083333,10.3958333 11.4791667,10.1666667 L11.5208333,10.125 C11.9583333,9.6875 12.2083333,9.08333333 12.1666667,8.45833333 C12.125,7.85416667 11.8333333,7.29166667 11.3333333,6.91666667 C10.875,6.58333333 10.4375,6.47916667 10.0625,6.39583333 C9.70833333,6.3125 9.5,6.25 9.33333333,6.0625 C9.14583333,5.875 9.04166667,5.66666667 9.04166667,5.47916667 C9.04166667,5.27083333 9.16666667,5.125 9.27083333,5.02083333 C9.64583333,4.70833333 10.2708333,4.72916667 10.6458333,5.04166667 C10.7083333,5.10416667 10.7708333,5.1875 10.8125,5.25 L11.9583333,5.08333333 C11.8541667,4.75 11.6666667,4.4375 11.375,4.20833333 C11.1041667,3.97916667 10.7916667,3.8125 10.4375,3.72916667 L10.4375,2.64583333 L9.33333333,2.64583333 L9.33333333,3.75 C9.04166667,3.83333333 8.75,3.97916667 8.52083333,4.16666667 C8.52083333,4.16666667 8.52083333,4.16666667 8.5,4.1875 L8.47916667,4.20833333 C8.10416667,4.54166667 7.89583333,5 7.875,5.45833333 C7.85416667,5.95833333 8.0625,6.47916667 8.47916667,6.89583333 C8.89583333,7.3125 9.375,7.4375 9.77083333,7.52083333 C10.0625,7.58333333 10.3333333,7.66666667 10.6041667,7.85416667 C10.8333333,8.02083333 10.9583333,8.27083333 10.9791667,8.54166667 C11,8.83333333 10.875,9.125 10.6666667,9.33333333 C10.3541667,9.64583333 9.6875,9.64583333 9.29166667,9.3125 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
