import { lazy } from "react";
const ResellerWalkThrough = lazy(() =>
  import(/* webpackChunkName: "reseller-walkthrough" */ "./reseller")
);
const BigrockWalkThrough = lazy(() =>
  import(/* webpackChunkName: "bigrock-walkthrough" */ "./bigrock")
);

export default process.env.FEATURE_ENDPOINT === "reseller"
  ? ResellerWalkThrough
  : BigrockWalkThrough;

export { actions, actionTypes } from "./ducks";
