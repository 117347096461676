import React from "react";

export function Dollar(props) {
  return (
    <svg width="6px" height="12px" viewBox="0 0 6 12" {...props}>
      <path
        d="M11.471 10.128c-1.277-.512-1.646-.822-1.646-1.381 0-.448.339-.97 1.294-.97.846 0 1.494.333 1.5.336a.471.471 0 00.22.055.445.445 0 00.417-.285l.232-.594a.367.367 0 00-.198-.472c-.52-.226-1.538-.402-1.548-.404-.016-.003-.074-.015-.074-.081l-.003-.862c0-.259-.216-.47-.482-.47h-.417a.477.477 0 00-.482.47l.001.906c0 .07-.075.1-.102.106-1.284.306-2.087 1.246-2.087 2.433 0 1.479 1.223 2.147 2.543 2.649 1.055.414 1.486.834 1.486 1.45 0 .671-.61 1.14-1.484 1.14-.745 0-1.755-.473-1.765-.478a.472.472 0 00-.203-.046.45.45 0 00-.427.296l-.221.6c-.08.224.042.406.198.487.62.323 1.82.512 1.872.52.015.003.088.028.088.095v.902c0 .259.216.47.482.47h.43a.477.477 0 00.483-.47v-.95c0-.088.066-.096.08-.099 1.367-.307 2.21-1.308 2.21-2.565 0-1.29-.717-2.125-2.397-2.788z"
        transform="translate(-28 -711) translate(0 71) translate(20 630) translate(0 5)"
        fill="#28BD8B"
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </svg>
  );
}
