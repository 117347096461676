import { Syringe } from "@faizaanceg/syringe";
import { isFailure, isSuccess } from "common/api-status/utils";
import { composeReducers, getProrataCost } from "common/util";
import { getSimpleNameFromProductKey } from "constants/index";
import { get } from "lodash";
import { initialState as childNameServerInitialState } from "widget-models/childname-server/ducks";
import { initialState as domainForwardingInitialState } from "widget-models/domain-forwarding/ducks";
import { initialState as resellerLockInitialState } from "widget-models/domain-lock/ducks";
import productsReducer from "widget-models/ducks";
import { initialState as emailAddressInitialState } from "widget-models/email-address/ducks";
import { initialState as freeEmailInitialState } from "widget-models/free-email/ducks";
import { initialState as gsuiteSetupInitialState } from "widget-models/gsuite-setup/ducks";
import { initialState as manageAddonsInitialState } from "widget-models/manage-addons/ducks";
import { initialState as manageBackupInitialState } from "widget-models/manage-backup/ducks";
import { initialState as manageBlocksInitialState } from "widget-models/manage-blocks/ducks";
import { initialState as dnsSecInitialState } from "widget-models/manage-dns-sec/ducks";
import { initialState as domainNameServersInitialState } from "widget-models/manage-dns/ducks";
import { initialState as manageEmailInitialState } from "widget-models/manage-email/ducks";
import { initialState as manageIPInitialState } from "widget-models/manage-ip/ducks";
import { initialState as managePanelInitialState } from "widget-models/manage-panel/ducks";
import { initialState as manageSSLInitialState } from "widget-models/manage-ssl/ducks";
import { initialState as manageStorageInitialState } from "widget-models/manage-storage/ducks";
import { initialState as freeTitanEmailInitialState } from "widget-models/manage-titan/ducks";
import { initialState as manageVolumeInitialState } from "widget-models/manage-volumes/ducks";
import { initialState as manageWHMCSInitialState } from "widget-models/manage-whmcs/ducks";
import { initialState as managedServiceInitialState } from "widget-models/managed-service-addon/ducks";
import { initialState as modifyTransferSecretInitialState } from "widget-models/modify-transfer-secret/ducks";
import { initialState as nameServersInitialState } from "widget-models/name-servers/ducks";
import { initialState as privacyProtectionInitialState } from "widget-models/privacy/ducks";
import { initialState as serverPowerBarInitialState } from "widget-models/server-power-bar/ducks";
import { initialState as shellAccessInitialState } from "widget-models/shell-access/ducks";
import { initialState as theftProtectionInitialState } from "widget-models/theft-protection/ducks";
import { initialState as viewCertificateInitialState } from "widget-models/view-certificate/ducks";
import { initialState as contactDetailsInitialState } from "widgets/domain-widgets/contact-details/ducks";
import { initialState as domainTransferInInitialState } from "widgets/domain-widgets/domain-transfer-in/ducks";
import domainWidgetsReducer from "widgets/domain-widgets/ducks";

export const actionTypes = {
  GET_ADMIN_PANEL_URL: "[Widgets] Get admin panel url",
  GET_WIDGET_DETAILS: "[Widgets] Get widgets details",
  UPDATE_PRICES: "[Widgets] Update prices",
  GET_PRICING_DETAILS: "[Widgets] get pricing details",
  DISPATCH_QUEUE: "[Root] Dispatch queue",
  FETCH_DEPENDENCY: "[Widgets] Fetch Widget Dependency",
  SETUP_STATE: "[Widgets] Setup state"
};

export const actions = {
  deleteItem: type => ({ parent, child, orderid, ...addonToBeDeleted }) => ({
    type,
    value: {
      parent,
      child,
      addonToBeDeleted
    },
    orderid
  }),
  getAdminPanelURL: (payload, track) => {
    let externalAccessManager = Syringe.inject("externalAccessManager");
    return {
      type: actionTypes.GET_ADMIN_PANEL_URL,
      payload,
      panelWindow: externalAccessManager.createWindow(),
      track
    };
  },
  getWidgetDetails: payload => ({
    type: actionTypes.GET_WIDGET_DETAILS,
    payload
  }),
  getPricingDetailsUsingPlanID: ({ meta, hierarchy }) => ({
    type: actionTypes.GET_PRICING_DETAILS,
    ...hierarchy,
    parent: meta.orderid,
    meta,
    id: "plan"
  }),
  getPricingDetailsUsingIDSuccess: ({
    apiId: id,
    pricingDetails,
    child,
    meta,
    parent,
    planDetails
  }) => ({
    type: actionTypes.UPDATE_PRICES,
    value: {
      pricing: helpers.parsePricingDetails(pricingDetails, meta, id),
      parent,
      child,
      planDetails
    }
  }),
  getPricingDetailsUsingIDFailure: error => ({
    type: isFailure(actionTypes.GET_PRICING_DETAILS),
    error
  }),
  getPricingDetailsUsingBandID: ({ meta, hierarchy }) => ({
    type: actionTypes.GET_PRICING_DETAILS,
    ...hierarchy,
    parent: meta.orderid,
    meta,
    id: "band"
  }),
  processDispatchQueue: () => ({ type: actionTypes.DISPATCH_QUEUE }),
  fetchDependency: payload => ({ type: actionTypes.FETCH_DEPENDENCY, payload }),
  setupState: payload => ({ type: actionTypes.SETUP_STATE, payload })
};

const widgetStateByProduct = {
  domain: {
    ...childNameServerInitialState,
    ...privacyProtectionInitialState,
    ...domainNameServersInitialState,
    ...dnsSecInitialState,
    ...domainForwardingInitialState,
    ...freeEmailInitialState,
    ...freeTitanEmailInitialState,
    ...contactDetailsInitialState,
    ...resellerLockInitialState,
    ...theftProtectionInitialState,
    ...domainTransferInInitialState,
    ...modifyTransferSecretInitialState
  },
  businessemail: {
    manageEmailAccounts: { link: "" },
    ...manageBlocksInitialState,
    ...manageEmailInitialState,
    ...nameServersInitialState,
    ...emailAddressInitialState
  },
  titanmail: {
    manageEmailAccounts: { link: "" },
    manageUpgrade: {
      upgradeStatus: "unavailable"
    },
    ...manageEmailInitialState,
    ...nameServersInitialState
  },
  enterpriseemail: {
    manageEmailAccounts: { link: "" },
    ...manageBlocksInitialState,
    ...manageEmailInitialState,
    ...nameServersInitialState,
    ...emailAddressInitialState
  },
  gsuite: {
    manageEmailAccounts: { link: "" },
    ...gsuiteSetupInitialState,
    ...manageEmailInitialState,
    ...nameServersInitialState
  },
  sdh: {
    ...manageIPInitialState,
    ...managePanelInitialState,
    ...nameServersInitialState
  },
  mdh: {
    ...manageIPInitialState,
    ...managePanelInitialState,
    ...nameServersInitialState
  },
  wph: {
    ...manageAddonsInitialState,
    ...manageIPInitialState,
    ...nameServersInitialState
  },
  rchosting: {
    ...manageBlocksInitialState,
    ...manageIPInitialState,
    ...managePanelInitialState,
    ...manageWHMCSInitialState,
    ...nameServersInitialState
  },
  managedserver: {
    ...manageAddonsInitialState,
    ...manageIPInitialState,
    ...managePanelInitialState
  },
  dedicatedserver: {
    ...manageAddonsInitialState,
    ...manageIPInitialState,
    ...managePanelInitialState,
    ...serverPowerBarInitialState,
    ...managedServiceInitialState,
    ...manageBackupInitialState
  },
  hgdedicatedserver: {
    ...manageWHMCSInitialState,
    ...manageAddonsInitialState,
    ...manageIPInitialState,
    ...managePanelInitialState,
    ...serverPowerBarInitialState
  },
  dedibybh: {
    ...manageWHMCSInitialState,
    ...manageAddonsInitialState,
    ...manageIPInitialState,
    ...managePanelInitialState,
    ...serverPowerBarInitialState
  },
  vpshosting: {
    ...manageAddonsInitialState,
    ...manageIPInitialState,
    ...managePanelInitialState,
    ...serverPowerBarInitialState,
    ...manageVolumeInitialState,
    ...managedServiceInitialState,
    ...manageBackupInitialState
  },
  bhvpshosting: {
    ...manageAddonsInitialState,
    ...manageIPInitialState,
    ...managePanelInitialState,
    ...serverPowerBarInitialState
  },
  cloudhosting: {
    ...manageAddonsInitialState,
    ...manageIPInitialState,
    ...managePanelInitialState,
    ...nameServersInitialState,
    ...shellAccessInitialState
  },
  hgshosting: {
    ...managePanelInitialState,
    ...manageIPInitialState,
    ...nameServersInitialState,
    ...domainNameServersInitialState,
    ...shellAccessInitialState
  },
  sslcert: { ...manageSSLInitialState, ...viewCertificateInitialState },
  codeguard: {},
  hosting: { email: { emailAccounts: 0 }, ...nameServersInitialState },
  sitelock: { sitelockDashboardLink: { link: "" } },
  blockstorage: manageVolumeInitialState,
  backup: manageStorageInitialState
};

const widgetInitialState = { domain: widgetStateByProduct.domain };

export let widgetReducers = (state = widgetInitialState, action) => {
  switch (action.type) {
    case actionTypes.SETUP_STATE:
    case actionTypes.GET_WIDGET_DETAILS: {
      const { payload } = action;
      let { meta = action.meta, hierarchy = action.meta } = payload;
      return state[hierarchy.parent]
        ? state
        : {
            ...state,
            [hierarchy.parent]:
              widgetStateByProduct[getSimpleNameFromProductKey(meta.productkey)]
          };
    }

    case isSuccess(actionTypes.GET_WIDGET_DETAILS): {
      const { effectResponse, payload } = action;
      const meta = { ...payload.meta, ...payload.hierarchy };
      const { parent, child } = meta;
      return {
        ...state,
        [parent]: {
          ...state[parent],
          [child]: {
            ...state[parent]?.[child],
            ...payload.onLoadParser(effectResponse, meta)
          }
        }
      };
    }

    case isSuccess(actionTypes.FETCH_DEPENDENCY): {
      const { effectResponse, payload } = action;
      return payload.onLoads.reduce((state, { hierarchy, parser }) => {
        const meta = {
          ...payload.meta,
          ...payload.hierarchy,
          ...hierarchy
        };
        const { parent, child } = meta;
        return {
          ...state,
          [parent]: {
            ...state[parent],
            [child]: {
              ...state[parent]?.[child],
              ...parser(effectResponse, meta)
            }
          }
        };
      }, state);
    }

    case actionTypes.UPDATE_PRICES: {
      const {
        value: { parent, child, pricing, planDetails }
      } = action;
      return {
        ...state,
        [parent]: {
          ...state[parent],
          [child]: {
            ...state[parent][child],
            cost: pricing.error || pricing.ssl,
            pricing,
            planDetails: {
              ...(state[parent][child]?.planDetails ?? {}),
              ...planDetails
            }
          }
        }
      };
    }

    case RESET_WIDGETS:
      return widgetInitialState;

    default:
      return state;
  }
};

const helpers = {
  parsePricingDetails: (
    pricingDetails,
    { productKey, expiryTimestamp },
    id
  ) => {
    let defaultPricingPath = ["reseller_pricing", productKey, id];
    let pricingPath;
    switch (getSimpleNameFromProductKey(productKey)) {
      // since different products require different parsing logic
      case "businessemail":
      case "enterpriseemail":
      case "wph":
      case "dedibybh":
      case "managedserver":
      case "hgdedicatedserver":
      case "dedicatedserver":
      case "bhvpshosting":
      case "vpshosting": {
        pricingPath = ["reseller_pricing", productKey, "addons"];
        break;
      }
      case "blockstorage": {
        pricingPath = ["reseller_pricing", productKey, "plans", id];
        break;
      }
      case "sdh": {
        pricingPath =
          process.env.FEATURE_ENDPOINT === "reseller"
            ? ["reseller_pricing", productKey, productKey, id, "pricing"]
            : defaultPricingPath;
        break;
      }
      default:
        pricingPath = defaultPricingPath;
        break;
    }
    let pricing = get(pricingDetails, pricingPath, {
      error: "Could not get prices"
    });
    return pricing.ssl || pricing.ipaddress
      ? {
          ...pricing,
          ssl: getProrataCost(pricing.ssl || pricing.ipaddress, expiryTimestamp)
        }
      : pricing;
  }
};

export const COPY_TRANSFER_SECRET = "[Domain] Copy Transfer Code";

const RESET_WIDGETS = "[widgets] Reset";

export const copy = defaultMessage => () => ({
  type: COPY_TRANSFER_SECRET,
  defaultMessage
});

export const resetWidgets = () => ({
  type: RESET_WIDGETS
});

export default composeReducers(
  ...domainWidgetsReducer,
  ...productsReducer,
  widgetReducers
);
