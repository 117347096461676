import { PanelPorts } from "constants/panel";
import { platform as PLATFORM } from "constants/platform";
import ProductKeyMapping from "constants/ProductKeyMapping";
import { get, isEmpty } from "lodash";
import format from "string-format";
import { helpers as addonsHelper } from "../manage-addons/ducks";

export const actionTypes = {
  SHOW_MODAL: "[Manage Panel] Show modal",
  PANEL_PASSWORD_CHANGE: "[Manage Panel] Change password"
};

export const actions = {
  savePanelPassword: ({ orderid, endpoints, newPassword, productkey }) => ({
    type: actionTypes.PANEL_PASSWORD_CHANGE,
    endpoint: endpoints.changeHostingPanelPassword,
    orderid,
    productkey,
    newPassword
  })
};

export const initialState = {
  manage: {
    newPassword: "",
    panelLink: "",
    password: "",
    username: "",
    additionalPanelDetails: [],
    ipDetails: { websiteAddress: "", nameServerAddresses: [] }
  }
};

const helpers = {
  additionalPanelMatchers: ["cpanel", "whmcs", "plesk"],
  getPanelDetails: orderDetails => addon => {
    switch (addon.addon_name) {
      case "cpanel":
        return {
          panelKey: addon.addon_name,
          panelTitle: "CPanel Details",
          panelLink: `https://${orderDetails.ipaddress}:${PanelPorts.CPANEL}`,
          panelUrl: `https://${orderDetails.ipaddress}:${PanelPorts.CPANEL}`,
          user: "root"
        };

      case "plesk":
        return {
          panelKey: addon.addon_name,
          panelTitle: "Plesk Details",
          panelLink: `https://${orderDetails.ipaddress}:${PanelPorts.PLESK}`,
          panelUrl: `https://${orderDetails.ipaddress}:${PanelPorts.PLESK}`,
          user: "root"
        };

      case "plesk_web_admin_license":
      case "plesk_web_host_license":
      case "plesk_web_pro_license":
      case "plesk_10_domain":
      case "plesk_100_domain":
      case "plesk_unlimited_domain":
      case "plesk_30_domain": {
        if (isEmpty(addon.license_key)) {
          return { panelKey: null };
        }
        let panelTitle = addonsHelper.getDedicatedAddonName(addon.addon_name);
        return {
          panelKey: "pleskLicenseKey",
          panelTitle,
          licenseKey: addon.license_key
        };
      }

      case "whmcs":
        return {
          panelKey: addon.addon_name,
          panelTitle: "WHMCS Details",
          licenseKey: addon.license_key
        };
      default:
        return { panelKey: null };
    }
  }
};

export const parsers = {
  parseServerPanel: (response, meta) => {
    const { platform } = meta;
    const panelLink = `http://${response.ipaddress}${
      platform === PLATFORM.WINDOWS ? `:${PanelPorts.PLESK}` : "/cpanel"
    }`;
    return {
      panelLink,
      username: response.siteadminusername,
      password: response?.siteadminpassword ?? "",
      newPassword: response?.siteadminpassword ?? ""
    };
  },
  resellerDetailsParser: (payload, meta) => {
    const { platform } = meta;
    const panelLink = `http://${payload.ipaddress}${
      platform === PLATFORM.WINDOWS ? `:${PanelPorts.PLESK}` : "/whm"
    }`;
    let { ns_ip_detail: nameServerAddresses = [] } = payload;
    return {
      ...(platform === PLATFORM.LINUX && {
        ipDetails: {
          websiteAddress: payload.ipaddress,
          nameServerAddresses:
            nameServerAddresses.length > 0
              ? nameServerAddresses
              : [payload.ipaddress]
        }
      }),
      panelLink,
      username: payload.siteadminusername,
      password: payload?.siteadminpassword ?? "",
      newPassword: payload?.siteadminpassword ?? ""
    };
  },
  parseWebhostPanelDetails: (response = {}, { orderid, customerid }) => {
    const {
      productkey,
      siteadminpassword: password = "",
      siteadminusername: username = "",
      installed_os: { addons = [] } = {}
    } = response;
    let additionalPanelDetails = [];
    let panelLink = "";
    let panelUrl = "";
    panelLink = "https://panel.webhostbox.net";
    panelUrl = format(
      get(
        ProductKeyMapping,
        [productkey, "meta", "endpoints", "getAdminPanelURL"],
        panelLink
      ),
      { orderid, customerid }
    );
    additionalPanelDetails = addons
      .filter(({ addon_name: addonName }) =>
        helpers.additionalPanelMatchers.some(_ => addonName.startsWith(_))
      )
      .map(helpers.getPanelDetails(response));
    return {
      additionalPanelDetails,
      username,
      password,
      newPassword: password,
      panelLink,
      panelUrl
    };
  }
};
