import { SearchQueryBuilder } from "@superpanel/bodybuilder";
import { asEpochSeconds as roundOffDate } from "common/date";
import { roundOff, sum } from "common/math";
import { roundOffMoney } from "common/util";
import { InvoiceStatus } from "constants/invoices";
import { endOfDay, startOfDay, subMonths } from "date-fns";
import { isEmpty, orderBy } from "lodash";
import { InvoiceDetails, InvoiceStore } from "../hooks/list-invoices-context";
import { DowloadInvoiceProps } from "./download-invoice-csv";

export let discountTypes = { PERCENT: "PERCENT", FIXED: "FIXED" };

export let fixedTaxLabels = t => [
  t("BILLING_EXEMPT_LABEL", "Exempt"),
  t("BILLING_REVERSE_CHARGE", "Reverse charge"),
  t("BILLING_NON_TAXABLE", "Non Taxable")
];

export function calculateCostDetails(t, values) {
  let lineItemAmounts = values.lineItems.map(
    ({ meta: { manHours, taxRule, taxRules, fixedAmount } }) => ({
      taxRule: taxRule ?? taxRules[0],
      amount:
        manHours && manHours.rate > 0
          ? manHours.hours * manHours.rate
          : +fixedAmount
    })
  );
  let subTotal = lineItemAmounts.map(_ => _.amount).reduce(sum, 0);

  let discount = values.discount;
  let discountPerLineItem = values.controls.discount
    ? discount.type === discountTypes.PERCENT
      ? +discount.amount
      : roundOff((discount.amount * 100) / subTotal, 2)
    : 0;
  let discountAmount = values.controls.discount
    ? discount.type === discountTypes.PERCENT
      ? (subTotal * discountPerLineItem) / 100
      : +discount.amount
    : 0;

  let isCustomInvoice =
    (!values.type && values.type !== "invoice") ||
    values.type === "custom_invoice";
  let taxRules;
  if (isCustomInvoice) {
    taxRules = lineItemAmounts.map(({ taxRule, amount }) => {
      let taxableAmount = amount - (discountPerLineItem * amount) / 100;
      let { percentage: slab, label } = taxRule;
      let tax = slab === null ? 0 : (slab * taxableAmount) / 100;
      return { tax, taxableAmount, slab, label };
    });
  } else {
    taxRules = values.taxRules.map(taxRule => {
      let taxableAmount = values.totalAmount - values.discount;
      let { percentage: slab, label } = taxRule;
      let tax = slab === null ? 0 : (slab * taxableAmount) / 100;
      return { tax, taxableAmount, slab, label };
    });
  }
  let totalTax = taxRules.map(_ => _.tax).reduce(sum, 0);
  let slabsByTaxLabel = taxRules.reduce((completedItems, taxInfo) => {
    let { slab, label, tax } = taxInfo;
    let taxSlab =
      fixedTaxLabels(t).includes(label) || slab === null
        ? {}
        : {
            [label]: {
              ...(completedItems?.[label] ?? {}),
              [slab]: (completedItems?.[label]?.[slab] ?? 0) + tax
            }
          };
    return { ...completedItems, ...taxSlab };
  }, {});

  let totalAmount = subTotal - discountAmount + totalTax;
  let totalAmountDue =
    values.controls.partialPayment && values.partialPayment
      ? totalAmount - values.partialPayment
      : totalAmount;

  let roundOffTotal = +roundOffMoney(totalAmount, 2);
  return {
    subTotal,
    slabsByTaxLabel,
    lineItemAmountsBeforeDiscount: lineItemAmounts.map(_ => _.amount),
    lineItemAmountsAfterDiscount: taxRules.map(_ => _.taxableAmount),
    taxAmount: taxRules.map(_ => _.taxableAmount + _.tax),
    discountAmount,
    totalAmount: roundOffTotal === 0 ? 0 : roundOffTotal,
    totalAmountDue
  };
}

export function getFormControl({ discount, state, amountDue, dueDate }) {
  return {
    discount: discount?.amount ?? false,
    fullyPaid: state === InvoiceStatus.paid,
    partialPayment: amountDue > 0,
    dueDate: !!dueDate
  };
}

export function getCustomerDetails(addressInfo, taxInfo) {
  const customerTaxDetails = [];
  if (addressInfo.userCountry === "IN") {
    customerTaxDetails.push({
      label: "indiangstid",
      taxId: taxInfo?.indiangstid
    });
  }
  return {
    name: addressInfo.userName,
    company: addressInfo.userCompany,
    address1: addressInfo.userAddress1,
    address2: addressInfo.userAddress2,
    address3: addressInfo.userAddress3,
    state: addressInfo.userState,
    city: addressInfo.userCity,
    zip: addressInfo.userZip,
    country: addressInfo.userCountry,
    customerTaxDetails
  };
}

export function getResellerDetails(addressInfo, taxInfo) {
  return {
    useremail: addressInfo.parentEmail,
    company: addressInfo.parentCompany,
    telnocc: addressInfo.parentTelnocc,
    telno: addressInfo.parentTelno,
    address1: addressInfo.parentAddress1,
    address2: addressInfo.parentAddress2,
    address3: addressInfo.parentAddress3,
    state: addressInfo.parentState,
    city: addressInfo.parentCity,
    zip: addressInfo.parentZip,
    country: addressInfo.parentCountry,
    brandname: addressInfo.parentBrandName,
    ...(taxInfo?.parent_indiangstid && {
      indiangstid: taxInfo.parent_indiangstid
    }),
    ...(taxInfo?.parent_vatid && {
      vatid: taxInfo.parent_vatid
    }),
    ...(taxInfo?.parent_russiavatid && {
      russiavatid: taxInfo.parent_russiavatid
    }),
    ...(taxInfo?.parent_ussalestaxid && {
      ussalestaxid: taxInfo.parent_ussalestaxid
    })
  };
}

export function convertCreationDate(invoices) {
  const millisecondsInDay = 86400;
  if (!Array.isArray(invoices)) {
    return {
      ...invoices,
      creationTime: invoices.creationDate,
      creationDate: Math.floor(
        invoices.creationDate / millisecondsInDay
      ).toString()
    };
  }
  return invoices.map(items => ({
    ...items,
    creationTime: items.creationDate,
    creationDate: Math.floor(items.creationDate / millisecondsInDay).toString()
  }));
}

export const getSystemInvoiceState = invoice => {
  let invoiceState;
  if (invoice?.transactionSerialId && invoice?.unUtilisedSellingAmount === 0) {
    invoiceState = "PAID";
  } else {
    invoiceState = "PENDING";
  }
  return invoiceState;
};

export function createTransactionsFromES(response): InvoiceDetails[] {
  return response.hits.hits.reduce((invoicesById, invoice) => {
    let {
      addressInfo,
      unUtilisedSellingAmount,
      sellingAmount,
      sellingCurrencySymbol,
      creationDate,
      customInvoice = {}
    } = invoice._source;

    let invoiceState =
      customInvoice?.state?.toUpperCase() ??
      getSystemInvoiceState(invoice._source);

    if (invoiceState === InvoiceStatus.pending && customInvoice?.state) {
      invoiceState =
        customInvoice?.dueDate < roundOffDate(Date.now())
          ? InvoiceStatus.overdue
          : InvoiceStatus.pending;
    }
    let invoiceData = {
      ...invoice._source,
      ...customInvoice,
      state: invoiceState,
      customerName: addressInfo.userName,
      customerId: +invoice._source.customerId,
      company: addressInfo.userCompany,
      amountDue: Math.abs(unUtilisedSellingAmount),
      totalAmount: Math.abs(sellingAmount),
      currencySymbol: sellingCurrencySymbol,
      creationDate: +creationDate
    };
    invoicesById[invoiceData.transactionId] = convertCreationDate(invoiceData);
    return invoicesById;
  }, {});
}

export function parseESInvoices(response: any) {
  let totalInvoices = response.hits.total.value;
  let invoices = createTransactionsFromES(response);

  let allInvoices = Object.values(invoices).reduce(
    (invoicesByState, invoice) => {
      let invoiceState = invoice?.state.toLowerCase();
      if (isEmpty(invoicesByState[invoiceState])) {
        invoicesByState[invoiceState] = {
          invoices: [invoice],
          pageCount: 1,
          totalInvoices: 1
        };
      } else {
        invoicesByState[invoiceState] = {
          ...invoicesByState[invoiceState],
          invoices: [...invoicesByState[invoiceState].invoices, invoice],
          totalInvoices: invoicesByState[invoiceState].totalInvoices + 1
        };
      }
      return invoicesByState;
    },
    {}
  );
  return { invoices: allInvoices, totalInvoices };
}

export function invoicesSystemESBodyBuilder({
  statusFilter,
  dateFilter,
  searchText,
  customerId,
  resellerId,
  role = "customer"
}: {
  statusFilter?: string;
  dateFilter?: DowloadInvoiceProps["filters"]["dateFilter"]["system"];
  searchText?: string;
  customerId?: string;
  resellerId?: string;
  role?: Role;
}) {
  let bodyBuilder = SearchQueryBuilder();
  let date = new Date();
  bodyBuilder = bodyBuilder
    .sort([{ creationDate: { order: "desc" } }])
    .filter("term", "type", "invoice")
    .query("range", "creationDate", {
      gte: roundOffDate(subMonths(date, 6)),
      lt: roundOffDate(endOfDay(date)),
      format: "epoch_millis"
    });

  if (customerId) {
    return bodyBuilder.filter("term", "customerId", customerId);
  }

  if (role === "reseller") {
    bodyBuilder = bodyBuilder.filter("term", "resellerId", resellerId);
  }

  if (statusFilter?.toLowerCase() === "paid") {
    bodyBuilder = bodyBuilder
      .filter("exists", "field", "transactionSerialId")
      .filter("term", "unUtilisedSellingAmount", 0);
  }
  if (statusFilter?.toLowerCase() === "pending") {
    bodyBuilder = bodyBuilder.notFilter("term", "unUtilisedSellingAmount", 0);
  }

  if (dateFilter.from) {
    bodyBuilder = bodyBuilder.query("range", "creationDate", {
      gte: roundOffDate(dateFilter.from),
      lte: roundOffDate(dateFilter.to || startOfDay(Date.now())),
      format: "epoch_millis"
    });
  }
  if (searchText) {
    bodyBuilder = bodyBuilder.filter("multi_match", {
      query: searchText.toLowerCase(),
      type: "phrase_prefix",
      fields: ["description", "addressInfo.userName", "addressInfo.userCompany"]
    });
  }
  return bodyBuilder;
}

export function invoicesReceiptsESBodyBuilder({
  dateFilter,
  searchText,
  customerId,
  resellerId
}: {
  dateFilter?: DowloadInvoiceProps["filters"]["dateFilter"]["manual"];
  searchText?: string;
  customerId?: string;
  resellerId?: string;
}) {
  let bodyBuilder = SearchQueryBuilder();
  let date = new Date();
  bodyBuilder = bodyBuilder
    .sort([{ creationDate: { order: "desc" } }])
    .filter("term", "type", "receipt")
    .query("range", "creationDate", {
      gte: roundOffDate(subMonths(date, 6)),
      lt: roundOffDate(endOfDay(date)),
      format: "epoch_millis"
    });

  if (customerId) {
    return bodyBuilder.filter("term", "customerId", customerId);
  }

  if (dateFilter.from) {
    bodyBuilder = bodyBuilder.query("range", "creationDate", {
      gte: roundOffDate(dateFilter.from),
      lte: roundOffDate(dateFilter.to || startOfDay(Date.now())),
      format: "epoch_millis"
    });
  }
  if (searchText) {
    bodyBuilder = bodyBuilder.filter("multi_match", {
      query: searchText.toLowerCase(),
      type: "phrase_prefix",
      fields: ["description", "addressInfo.userName", "addressInfo.userCompany"]
    });
  }
  return bodyBuilder;
}

export function invoicesManualESBodyBuilder({
  statusFilter,
  dateFilter,
  searchText,
  customerId
}: {
  statusFilter?: string;
  dateFilter?: DowloadInvoiceProps["filters"]["dateFilter"]["manual"];
  searchText?: string;
  customerId?: string;
  resellerId?: string;
}) {
  let bodyBuilder = SearchQueryBuilder();
  let date = new Date();
  bodyBuilder = bodyBuilder
    .sort([{ creationDate: { order: "desc" } }])
    .filter("term", "type", "custom_invoice")
    .filter("exists", "field", "addressInfo")
    .notFilter("prefix", "key", "MerchantInvoice")
    .query("range", "creationDate", {
      gte: roundOffDate(subMonths(date, 6)),
      lt: roundOffDate(endOfDay(date)),
      format: "epoch_millis"
    });

  if (customerId) {
    return bodyBuilder.filter("term", "customerId", customerId);
  }

  switch (statusFilter.toUpperCase()) {
    case InvoiceStatus.pending:
      bodyBuilder = bodyBuilder
        .orFilter("range", "customInvoice.dueDate", {
          gte: "now",
          format: "epoch_second"
        })
        .notFilter("exists", "field", "customInvoice.dueDate")
        .queryMinimumShouldMatch(1);
      break;
    case InvoiceStatus.overdue: {
      bodyBuilder = bodyBuilder
        .query("range", "customInvoice.dueDate", {
          lt: "now",
          format: "epoch_second"
        })
        .filter("exists", "field", "customInvoice.dueDate")
        .queryMinimumShouldMatch(1);
      break;
    }
    default:
      break;
  }

  if (statusFilter) {
    bodyBuilder.filter("term", "customInvoice.state", {
      value:
        statusFilter.toUpperCase() === InvoiceStatus.overdue
          ? "pending"
          : statusFilter.toLowerCase()
    });
  }
  if (dateFilter.from) {
    bodyBuilder = bodyBuilder.query("range", "creationDate", {
      gte: roundOffDate(dateFilter.from),
      lte: roundOffDate(dateFilter.to || startOfDay(Date.now())),
      format: "epoch_millis"
    });
  }
  if (searchText) {
    bodyBuilder = bodyBuilder.filter("multi_match", {
      query: searchText.toLowerCase(),
      type: "phrase_prefix",
      fields: [
        "customInvoice.description",
        "addressInfo.userName",
        "addressInfo.userCompany"
      ]
    });
  }
  return bodyBuilder;
}

export function getRequestDTO(payload, t) {
  let {
    controls,
    partialPayment,
    discount,
    dueDate,
    lineItems,
    customerDetails,
    ...formPayload
  } = payload;
  let { totalAmount } = calculateCostDetails(t, payload);
  return {
    ...formPayload,
    description: lineItems[0].description,
    lineItems: lineItems.map(lineItem => {
      let { manHours, fixedAmount, hsnCode, taxRule } = lineItem.meta;
      let useHSN =
        customerDetails.country === "IN" ||
        taxRule.label.toUpperCase() === "GST";
      return {
        ...lineItem,
        meta: {
          taxRules: [taxRule],
          hsnCode: useHSN ? hsnCode : "",
          ...(manHours.rate > 0 ? { manHours } : { fixedAmount })
        }
      };
    }),
    totalAmount,
    greedy: false,
    role: "CUSTOMER",
    ...(controls.discount && { discount }),
    ...(controls.fullyPaid && { amountPaidAtCreation: totalAmount }),
    ...(controls.partialPayment && { amountPaidAtCreation: partialPayment }),
    dueDate: controls.dueDate ? Date.parse(dueDate) : null
  };
}

export const getpageCount = (invoice, invoicesStore) => {
  let invoiceType =
    invoice === "all"
      ? "manualInvoices"
      : invoice === "manual"
      ? "manualInvoices"
      : invoice === "receipt"
      ? "receipts"
      : "systemInvoices";
  let {
    searchFilter,
    selectedFilters = [],
    dateFilterRange,
    customerInvoices,
    ...allInvoiceTypes
  } = invoicesStore[invoiceType];

  if (searchFilter.searchText.length > 0) {
    return searchFilter.searchPageCount;
  } else if (selectedFilters.length > 0) {
    return (
      invoicesStore[invoiceType][selectedFilters[0].toLowerCase()]?.pageCount ??
      0
    );
  } else if (dateFilterRange.from && dateFilterRange.to) {
    return dateFilterRange.dateFilterPageCount;
  } else if (invoice === "receipts") {
    return allInvoiceTypes.pageCount ?? 0;
  } else {
    let invoiceState = Object.keys(allInvoiceTypes).find(_ =>
      Object.keys(InvoiceStatus).includes(_)
    );
    return allInvoiceTypes?.[invoiceState]?.pageCount ?? 0;
  }
};

export const sortInvoicesByDate = (
  invoicesData:
    | InvoiceStore["invoices"]["manualInvoices"]
    | InvoiceStore["invoices"]["systemInvoices"]
) => {
  return orderBy(Object.values(invoicesData), _ => _.creationTime, ["desc"]);
};

export const makeSystemInvoiceOrReceipt = invoiceInfo => {
  const isReceipt = invoiceInfo.type === "receipt";
  const totalAmount = isReceipt
    ? invoiceInfo.sellingAmount
    : roundOffMoney(
        invoiceInfo.sellingAmount - invoiceInfo.sellingTaxAmount,
        2
      );
  const hsnCode =
    invoiceInfo.taxRules[0] && invoiceInfo.taxRules[0]?.additionalInfo !== ""
      ? invoiceInfo.taxRules[0].additionalInfo?.replace(/[^0-9]/g, "")
      : "";

  return {
    ...invoiceInfo,
    invoiceId: invoiceInfo.transactionId,
    creationTime: +invoiceInfo.creationDate * 1000,
    creationDate: Math.floor(+invoiceInfo.creationDate / 86400),
    currencySymbol: invoiceInfo.sellingCurrencySymbol,
    totalAmount: totalAmount,
    discount: invoiceInfo.sellingDiscountAmount,
    amountDue: invoiceInfo.unUtilisedSellingAmount,
    userTransactionInfo: invoiceInfo.transactionInfo,
    lineItems: [
      {
        description: invoiceInfo.description,
        meta: {
          fixedAmount: totalAmount,
          hsnCode,
          manHours: null,
          taxRule: invoiceInfo.taxRules[0] ?? null,
          taxRules: invoiceInfo.taxRules
        }
      }
    ],
    actions: [],
    state: getSystemInvoiceState(invoiceInfo)
  };
};
