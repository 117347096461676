import { takeEvery, takeLatest, put } from "redux-saga/effects";
import { services } from "./services";
import { actions, actionTypes } from "./ducks";

export default [
  takeLatest(
    actionTypes.DISABLE_PRIVACY_PROTECTION,
    onDisablePrivacyProtection
  ),
  takeEvery(actionTypes.ENABLE_PRIVACY_PROTECTION, onEnablePrivacyProtection),
  takeEvery(
    actionTypes.ENABLE_FREE_PRIVACY_PROTECTION,
    onEnableFreePrivacyProtection
  ),
  takeEvery(
    actionTypes.DISABLE_FREE_PRIVACY_PROTECTION,
    onDisableFreePrivacyProtection
  )
];

export function* onDisablePrivacyProtection(action) {
  try {
    yield services.modifyPrivacyProtection(action.payload, false);
    yield put(actions.disableSuccess(action.payload));
  } catch (error) {
    yield put(actions.disableFailure(error, action.payload));
  }
}

export function* onEnablePrivacyProtection(action) {
  try {
    yield services.modifyPrivacyProtection(action.payload, true);
    yield put(actions.enableSuccess(action.payload));
  } catch (error) {
    yield put(actions.enableFailure(error, action.payload));
  }
}

export function* onDisableFreePrivacyProtection(action) {
  try {
    yield services.disableFreePrivacyProtection(action.payload);
    yield put(actions.disableFreePrivacySuccess(action.payload));
  } catch (error) {
    yield put(actions.disableFreePrivacyFailure(error, action.payload));
  }
}

export function* onEnableFreePrivacyProtection(action) {
  try {
    yield services.enableFreePrivacyProtection(action.payload);
    yield put(actions.enableFreePrivacySuccess(action.payload));
  } catch (error) {
    yield put(actions.enableFreePrivacyFailure(error, action.payload));
  }
}
