import { Syringe } from "@faizaanceg/syringe";
import { GsuiteConsentImage } from "assets";
import { isFailure, isSuccess } from "common/api-status/utils";
import UpdateButton from "common/buttons/update";
import {
  actions as notificationActions,
  constants as showNotificationConstants
} from "common/inline-notifications/ducks";
import { ConsentSdk } from "common/sdk/consents";
import { actionTypes } from "common/session-management/ducks";
import { services } from "common/session-management/services";
import kbLinks from "constants/kb-links";
import { Field, Form, Formik } from "formik";
import { ModalHandlerProps } from "modals/modal";
import "modals/modals.scss";
import React from "react";
import { Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useSWRMutation from "swr/mutation";

const SiteManager = Syringe.inject("siteManager");

interface GsuiteConsentProps extends ModalHandlerProps {
  useFullScreen: boolean;
}

export const GsuiteConsent = (props: GsuiteConsentProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const acceptConsent = useSWRMutation("accept-gsuite-consent", async () => {
    try {
      dispatch({ type: actionTypes.ACCEPT_GSUITE_CONSENT });
      const products = { productKeys: ["gappsgbl", "gappsin", "gappsse"] };
      let { ip } = await SiteManager.clientDetails();
      const response = await ConsentSdk.accept("gsuite-consent", { ip });
      let signupResponse = await services.completeProductSignup(products);
      if (response.consent.consentName.length > 0 && signupResponse.eaqid > 0) {
        dispatch({ type: isSuccess(actionTypes.ACCEPT_GSUITE_CONSENT) });
        dispatch(
          notificationActions.showNotification(
            `Thank you for signing up. We are processing this and the product will be available in your panel in a few minutes. Stay tuned!`
          )
        );
      }
    } catch (error) {
      dispatch({ type: isFailure(actionTypes.ACCEPT_GSUITE_CONSENT), error });
      dispatch(
        notificationActions.showNotification(
          `Oops, something went wrong. Please try again in sometime.`,
          showNotificationConstants.ERROR
        )
      );
    }
  });
  return (
    <Modal
      dialogClassName={
        !props.useFullScreen ? "gsuite-consent-pending" : "gsuite-consent-modal"
      }
      backdrop={!props.useFullScreen}
      show={props.show}
      keyboard={false}
      onHide={props.onHide}
    >
      <Modal.Body>
        <div>
          {!props.useFullScreen ? (
            <div className="modal-close" onClick={props.onHide} />
          ) : null}
          <div className="gsuite-consent-modal-wrap">
            <div className="gsuite-consent-modal-top">
              <div className="gsuite-consent-title">
                {t(
                  "GWS_TNC",
                  "ACCEPT GOOGLE'S T&C FOR OFFERING GOOGLE WORKSPACE TO YOUR CLIENTS"
                )}
              </div>
              <div className="gsuite-consent-subtitle">
                {t(
                  "GWS_TNC_SUBTEXT",
                  "We don't want the legal mumbo-jumbo stopping you from using one of the best productivity tools in the industry."
                )}
              </div>
              <img src={GsuiteConsentImage} alt="" />
              <div className="gsuite-consent-footer-text">
                {t(
                  "GWS_TNC_MSG",
                  "To continue managing your existing Google Workspace orders and offering them to your Customers in future, please agree to Google's T&C."
                )}
              </div>
            </div>
            <div className="gsuite-consent-modal-bottom">
              <Formik
                initialValues={{ gsuiteConsent: "" }}
                onSubmit={() => acceptConsent.trigger()}
              >
                {({ values }) => (
                  <Form>
                    <label htmlFor="gsuiteConsent" className="order-select">
                      <Field
                        name="gsuiteConsent"
                        id="gsuiteConsent"
                        component="input"
                        type="checkbox"
                        className="custom-checkbox"
                      />
                      <span className="custom-checkbox-label" />
                      <Trans i18nKey="GWS_TNC_CONTENT">
                        I agree to the{" "}
                        <a
                          href={kbLinks.gworkspaceAgreemet}
                          target="_blank"
                          rel="nofollow"
                        >
                          Google Workspace Product Agreement Extension
                        </a>{" "}
                        and the{" "}
                        <a
                          href={kbLinks.resellermasteragreement}
                          target="_blank"
                          rel="nofollow"
                        >
                          Reseller Master Agreement
                        </a>{" "}
                        applicable to this Product/Service
                      </Trans>
                    </label>
                    <div className="text-align-center">
                      <UpdateButton
                        data-testid="accept-gsuite-consent"
                        type="submit"
                        className="wp-btn"
                        disabled={!values.gsuiteConsent}
                        updating={acceptConsent.isMutating}
                      >
                        {props.useFullScreen
                          ? t("GWS_PROCEED", "Proceed to your account")
                          : t("SAVE", "Save")}
                      </UpdateButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
