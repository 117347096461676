/**
 * We need to hardcode the plans for CodeGuard as OrderBox doesn't return them
 * https://manage.logicboxes.com/kb/node/2303
 */
export const CODEGUARD = {
  "229": {
    plan_name: "Basic",
    WEBSITES: "5",
    "DEMAND-BACKUP": "No",
    DATABASE: "Unlimited",
    "WEBSITE-RESTORE": "3/month",
    "DISK-SPACE": "1GB",
    BACKUP_FREQUENCY: "5 Days"
  },
  "230": {
    plan_name: "Professional",
    WEBSITES: "10",
    "DEMAND-BACKUP": "Yes",
    DATABASE: "Unlimited",
    "WEBSITE-RESTORE": "Unlimited",
    "DISK-SPACE": "5GB",
    BACKUP_FREQUENCY: "Daily"
  },
  "231": {
    plan_name: "Premium",
    WEBSITES: "25",
    "DEMAND-BACKUP": "Yes",
    DATABASE: "Unlimited",
    "WEBSITE-RESTORE": "Unlimited",
    "DISK-SPACE": "10GB",
    BACKUP_FREQUENCY: "Daily"
  },
  "232": {
    plan_name: "Enterprise",
    WEBSITES: "100",
    "DEMAND-BACKUP": "Yes",
    DATABASE: "Unlimited",
    "WEBSITE-RESTORE": "Unlimited",
    "DISK-SPACE": "25GB",
    BACKUP_FREQUENCY: "Daily"
  }
};

/**
 * We need to hardcode the plans for SiteLock as OrderBox doesn't return them
 * https://manage.logicboxes.com/kb/node/2188
 */
export const SITELOCK = {
  "178": {
    plan_name: "Basic",
    "FILE CHANGE MONITORING": "No",
    "CROSS-SITE SCRIPTING SCAN": "1-time",
    "SQL INJECTION SCAN": "1-time",
    "NO. OF PAGES": "25",
    "AUTOMATIC MALWARE REMOVAL": "No",
    "APPLICATION SCAN": "1-time"
  },
  "179": {
    plan_name: "Professional",
    "FILE CHANGE MONITORING": "Yes",
    "CROSS-SITE SCRIPTING SCAN": "1-time",
    "SQL INJECTION SCAN": "1-time",
    "NO. OF PAGES": "100",
    "AUTOMATIC MALWARE REMOVAL": "Yes",
    "APPLICATION SCAN": "1-time"
  },
  "180": {
    plan_name: "Premium",
    "FILE CHANGE MONITORING": "Yes",
    "CROSS-SITE SCRIPTING SCAN": "Unlimited",
    "SQL INJECTION SCAN": "Unlimited",
    "NO. OF PAGES": "500",
    "AUTOMATIC MALWARE REMOVAL": "Yes",
    "APPLICATION SCAN": "Unlimited"
  },
  "181": {
    plan_name: "Enterprise",
    "FILE CHANGE MONITORING": "Yes",
    "CROSS-SITE SCRIPTING SCAN": "Unlimited",
    "SQL INJECTION SCAN": "Unlimited",
    "NO. OF PAGES": "2500",
    "AUTOMATIC MALWARE REMOVAL": "Yes",
    "APPLICATION SCAN": "Unlimited"
  }
};
