import { actionTypes, actions } from "./ducks";
import { call, put, takeEvery } from "redux-saga/effects";

import services from "./services";
import { mapEffect } from "map-effect";

export default [
  takeEvery(actionTypes.GET_EMAIL_ADDRESSES, mapEffect(onGetEmailAddresses)),
  takeEvery(actionTypes.DELETE_EMAIL_ADDRESS, onDeleteEmailAddress),
  takeEvery(
    actionTypes.RESET_EMAIL_ADDRESS_PASSWORD,
    mapEffect(onResetEmailPassword)
  ),
  takeEvery(actionTypes.CREATE_EMAIL_ADDRESS, onCreateEmailAddress)
];

function* onGetEmailAddresses(action) {
  const emailAddresses = yield call(services.getEmailAddresses, action.meta);
  const overQuotaemailAddresses = yield call(
    services.getOverQuotaEmailAddresses,
    action.meta
  );
  const response = [
    ...emailAddresses.response.users,
    ...overQuotaemailAddresses.response.users
  ];
  return response
    .filter(value => value.accountType !== "FORWARD_ONLY")
    .map(value => value.emailAddress);
}

function* onDeleteEmailAddress(action) {
  let { meta, emailAddress } = action;
  try {
    yield services.deleteEmailAddress(meta, emailAddress);
    yield put(actions.deleteEmailAddressSuccess(meta, emailAddress));
    yield put(actions.getEmailAddresses(meta));
  } catch (error) {
    yield put(actions.deleteEmailAddressFailure(meta, error));
  }
}

function* onResetEmailPassword(action) {
  const {
    response: { data }
  } = yield services.resetEmailPassword(action.meta, action.emailAddress);
  return { newPassword: data };
}

function* onCreateEmailAddress(action) {
  try {
    let { meta, parameters } = action;
    const accountDetails = yield services.addEmailAddress(meta, parameters);
    if (accountDetails?.response?.status === "FAILURE") {
      throw new Error(accountDetails?.response?.message);
    }
    yield put(actions.createEmailAddressSuccess(meta, accountDetails));
    yield put(actions.getEmailAddresses(meta));
  } catch (error) {
    yield put(actions.createEmailAddressFailure(action.meta, error));
  }
}
