import { isFailure, isSuccess } from "common/api-status/utils";
import { convertToPropTypes } from "common/prop-types";

export const actionTypes = {
  AUTHENTICATE_USER: "[Root] Authenticate",

  BRANDING_DETAILS_REQUEST: "[Root] Branding Details",

  LOGOUT_REQUEST: "[Root] Logout",

  VERIFY_USER: "[Root] Verify user",

  GET_SESSION: "[Root] Update session",

  OPEN_FEEDBACK_MODAL: "[Root] Open Feedback Modal",

  GO_TO_CONTROL_PANEL: "[Root] Go to control panel",

  ACCEPT_GSUITE_CONSENT: "[Root] Accept Gsuite Constent",

  RETRIEVE_GSUITE_CONSENT: "[Root] Retrieve Gsuite Constent",

  GSUITE_ORDER_EXIST: "[Root] Gsuite Order Exists",

  IS_BOTS_CUSTOMER: "[Root] Is BOTS Customer",

  GSUITE_CONSENT_SHOW: "[Root] Consent Given",

  CONSOLIDATED_PRICING_REQUEST: "[Root] Get Consolidated Pricing",

  ACTIVE_PRODUCTS_LIST: "[Root] Get Active Products List",

  PRODUCT_METADATA: "[Root] Get Product Metadata",

  STORE_FALLBACK_PRICING: "[Root] Store Fallback Pricing",

  GENERATE_CUSTOMER_SMS_OTP: "[Root] 2FA Generate SMS OTP",

  PER_CUSTOMER_PRICING_REQUEST: "[Root] Get Per Customer Pricing",

  UPDATE_SMARTCART_HEALTH: "[Root] update smartcart health"
};

const initialState = {
  billingActive: true,
  brandingDetails: { companyName: undefined },
  userInfo: {
    isSwitchPanelEnabled: process.env.FEATURE_ENDPOINT === "bigrock"
  },
  defaultCustomer: { customerId: "", notFound: false },
  isLoggedIn: false,
  isUrlReal: process.env.FEATURE_ENDPOINT === "reseller",
  consolidatedPricing: {},
  activeProductsList: { hosting: [], domorder: [] },
  productMetadata: { domorder: { domains: {} }, hosting: {} },
  perCustomerPricing: {},
  smartCart: "unknown"
};

export const selector = state => state.root;

export default (state = initialState, action) => {
  switch (action.type) {
    case isSuccess(actionTypes.GET_SESSION):
    case isSuccess(actionTypes.AUTHENTICATE_USER):
    case isSuccess(actionTypes.VERIFY_USER): {
      const {
        effectResponse = {},
        payload: {
          role,
          userid,
          logoUrl = "",
          askMigrationConsent,
          userDetails = {},
          defaultCustomer = {}
        } = effectResponse
      } = action;
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...userDetails,
          askMigrationConsent,
          logoUrl,
          role,
          userid
        },
        isLoggedIn: true,
        defaultCustomer: {
          ...state.defaultCustomer,
          ...defaultCustomer
        }
      };
    }

    case isSuccess(actionTypes.BRANDING_DETAILS_REQUEST):
      return { ...state, brandingDetails: action.payload, isUrlReal: true };

    case isSuccess(actionTypes.PER_CUSTOMER_PRICING_REQUEST):
      return {
        ...state,
        perCustomerPricing: {
          ...state.perCustomerPricing,
          [action.payload.customerId]: {
            ...state.perCustomerPricing[action.payload.customerId],
            ...action.payload.customerPricing
          }
        }
      };

    case isSuccess(actionTypes.CONSOLIDATED_PRICING_REQUEST):
      return { ...state, consolidatedPricing: action.effectResponse };

    case isSuccess(actionTypes.ACTIVE_PRODUCTS_LIST):
      return { ...state, activeProductsList: action.effectResponse };

    case isSuccess(actionTypes.PRODUCT_METADATA):
      return { ...state, productMetadata: action.effectResponse };

    case actionTypes.STORE_FALLBACK_PRICING:
      return {
        ...state,
        consolidatedPricing: { ...state.consolidatedPricing, ...action.payload }
      };
    case isFailure(actionTypes.AUTHENTICATE_USER):
    case isFailure(actionTypes.VERIFY_USER):
    case isFailure(actionTypes.LOGOUT_REQUEST):
    case isSuccess(actionTypes.LOGOUT_REQUEST):
      return { ...state, isLoggedIn: false };
    case actionTypes.UPDATE_SMARTCART_HEALTH:
      return { ...state, smartCart: action.payload };
    default:
      return state;
  }
};

export const actions = {
  authenticateUser: (location, payload) => ({
    type: actionTypes.AUTHENTICATE_USER,
    location,
    payload
  }),
  authenticateSuccess: payload => ({
    type: isSuccess(actionTypes.AUTHENTICATE_USER),
    payload
  }),
  authenticateFailure: error => ({
    type: isFailure(actionTypes.AUTHENTICATE_USER),
    error
  }),

  getBrandingDetails: location => ({
    type: actionTypes.BRANDING_DETAILS_REQUEST,
    location
  }),
  getBrandingDetailsSuccess: parent => ({
    type: isSuccess(actionTypes.BRANDING_DETAILS_REQUEST),
    payload: {
      companyName: parent.brandname,
      logo: parent.logo,
      parentId: parent.userid ?? parent.resellerid,
      parentbrandingurl: parent.url
    }
  }),
  getPerCustomerPricing: (customerId, productKey) => ({
    type: actionTypes.PER_CUSTOMER_PRICING_REQUEST,
    customerId,
    productKey
  }),
  getPerCustomerPricingSuccess: payload => ({
    type: isSuccess(actionTypes.PER_CUSTOMER_PRICING_REQUEST),
    payload
  }),
  getBrandingDetailsFailure: error => ({
    type: isFailure(actionTypes.BRANDING_DETAILS_REQUEST),
    error
  }),
  storeFallbackPrice: payload => ({
    type: actionTypes.STORE_FALLBACK_PRICING,
    payload
  }),

  logout: () => ({
    type: actionTypes.LOGOUT_REQUEST
  }),
  logoutSuccess: () => ({
    type: isSuccess(actionTypes.LOGOUT_REQUEST)
  }),
  logoutFailure: error => ({
    type: isFailure(actionTypes.LOGOUT_REQUEST),
    error
  }),
  switchPanelSuccess: () => ({
    type: isSuccess(actionTypes.GO_TO_CONTROL_PANEL)
  }),
  switchPanelFailure: error => ({
    type: isFailure(actionTypes.GO_TO_CONTROL_PANEL),
    error
  }),
  verifyUser: payload => ({
    type: actionTypes.VERIFY_USER,
    payload
  }),
  verifyUserSuccess: payload => ({
    type: isSuccess(actionTypes.VERIFY_USER),
    payload
  }),
  verifyUserFailure: error => ({
    type: isFailure(actionTypes.VERIFY_USER),
    error
  }),
  openFeedbackModal: payload => ({
    type: actionTypes.OPEN_FEEDBACK_MODAL,
    payload
  }),
  goToControlPanel: ({ track, ...payload } = {}) => {
    return {
      type: actionTypes.GO_TO_CONTROL_PANEL,
      payload,
      track
    };
  },
  gsuiteConsentShow(payload) {
    return { type: actionTypes.GSUITE_CONSENT_SHOW, payload };
  },
  gsuiteConsentShowSuccess(payload) {
    return {
      type: isSuccess(actionTypes.GSUITE_CONSENT_SHOW),
      payload
    };
  },
  gsuiteConsentShowFailure(error) {
    return {
      type: isFailure(actionTypes.GSUITE_CONSENT_SHOW),
      error
    };
  },
  getSession() {
    return { type: actionTypes.GET_SESSION };
  },
  updateSmartCartHealth(payload) {
    return {
      type: actionTypes.UPDATE_SMARTCART_HEALTH,
      payload
    };
  },
  generateCustomerSMSOTP: payload => ({
    type: actionTypes.GENERATE_CUSTOMER_SMS_OTP,
    payload
  })
};

export const types = {
  ...convertToPropTypes(actions),
  ...convertToPropTypes(initialState)
};
