import {
  actions as AnalyticsActions,
  categories as AnalyticsCategories,
  labels as AnalyticsLabels
} from "analytics/constants";
import { isFailure, isSuccess } from "common/api-status/utils";
import { parseStringAsBoolean } from "common/parse-string";

export const actionTypes = {
  SHOW_ADD_MODAL: "[Manage IP] Show add modal",
  SHOW_MANAGE_MODAL: "[Manage IP] Show manage modal",
  SHOW_DELETE_MODAL: "[Manage IP] Show delete modal",

  BUY_DEDICATED_IP: "[Manage IP] Buy IP",

  DELETE_DEDICATED_IP: "[Manage IP] Delete IP",

  GET_FREE_IP_STATUS: "[Manage IP] Get free IP status"
};

export const initialState = {
  dedicatedip: { numberOfDedicatedIps: 0, ipList: [], cost: 0 }
};

export const actions = {
  deleteIP: ({ parent, child, ...payload }) => ({
    type: actionTypes.DELETE_DEDICATED_IP,
    payload: { ...payload, parent, child },
    track: {
      eventCategory: AnalyticsCategories.delete_addon,
      eventAction: `${AnalyticsActions.delete_dedicated_ip}${parent}`,
      eventLabel: AnalyticsLabels.delete_addon
    }
  }),
  deleteIPSuccess: payload => ({
    type: isSuccess(actionTypes.DELETE_DEDICATED_IP),
    ...payload
  }),
  deleteIPFailure: (error, payload) => ({
    type: isFailure(actionTypes.DELETE_DEDICATED_IP),
    error,
    payload
  }),
  getFreeIPStatus: payload => ({
    type: actionTypes.GET_FREE_IP_STATUS,
    payload
  })
};

export default (state, action) => {
  switch (action.type) {
    case actionTypes.SHOW_DELETE_MODAL: {
      if (!action.value) {
        return state;
      }
      const { addonToBeDeleted, parent, child } = action.value;
      return {
        ...state,
        [parent]: {
          ...state[parent],
          [child]: { ...state[parent][child], addonToBeDeleted }
        }
      };
    }
    case isSuccess(actionTypes.GET_FREE_IP_STATUS): {
      const {
        payload: {
          hierarchy: { child },
          meta: { orderid: parent }
        },
        effectResponse: planDetails
      } = action;
      return {
        ...state,
        [parent]: {
          ...state[parent],
          [child]: {
            ...state[parent][child],
            planDetails: {
              ...(state[parent][child]?.planDetails ?? {}),
              ...planDetails
            }
          }
        }
      };
    }
    default:
      return state;
  }
};

export const parsers = {
  hostingIPListParser: (response, { parent, child, ...meta }) => {
    const ipRecord = response.find(record => record.type === "A");
    const hasIPBeenBought = parseStringAsBoolean(meta.details.ssl);
    if (hasIPBeenBought) {
      return { ipList: [{ name: ipRecord.value, showDelete: true }] };
    } else {
      return {};
    }
  },
  resellerHostingDetailsParser: (response, meta) => ({
    numberOfDedicatedIps: response.ip_count,
    ipList: response.dedicated_ip_detail.map(ip => ({
      name: ip,
      showDelete: true
    }))
  }),
  wordpressHostingDetailsParser: (response, meta) => {
    const { addon_quantity: addonQuantity = [] } = response;
    const ipList = addonQuantity.filter(
      addon => addon.addon_name === "ipaddress"
    );
    return {
      numberOfDedicatedIps: ipList.length,
      ipList: ipList.map(ipAddon => ({
        name: ipAddon.ip,
        // Hard-coded false as for WP Hosting even if IPs are paid, deletion is not allowed
        showDelete: false,
        addonId: ipAddon.addon_id
      }))
    };
  },
  multipleIPDetailsParser: (response, meta) => {
    const { installed_os: { addons = [] } = {} } = response;
    const ipList = addons
      .filter(addon => addon.name === "ssl" || addon.addon_name === "ipaddress")
      .map(
        ({
          name,
          addon_name,
          ipaddress,
          ipAddress = ipaddress,
          addon_id,
          addOnID = addon_id,
          primaryIP = false,
          is_paid = !primaryIP
        }) => ({
          name: ipAddress,
          addOnID,
          showDelete: is_paid
        })
      );
    return { numberOfDedicatedIps: ipList.length, ipList };
  }
};
