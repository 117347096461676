import { Syringe } from "@faizaanceg/syringe";
import api from "api";
import { asEpochSeconds } from "common/date";
import { sum } from "common/math";
import { services as orderSdk } from "common/orders/services";
import { User } from "common/sdk/user";
import { addQueryParamsToUrl } from "common/urls";
import constants from "constants/endpoints";
import { addDays } from "date-fns";

const sessionManager = Syringe.inject("sessionManager");

export const services = {
  addFunds: params => api.postBody(`${constants.billing}addFunds`, params),
  createAddFundsLink: async ({
    parentbrandingurl,
    gateway,
    fundsToBeAdded
  }) => {
    const { TOKEN: userLoginId } = await User.token();
    let url = addQueryParamsToUrl(
      `https://${parentbrandingurl}/servlet/AutoLoginServlet`,
      {
        paymentfor: "customer",
        isPGTransaction: true,
        transactionMode: "addfund",
        userLoginId,
        custompaymentid: gateway,
        REDIRECT_URL: `${window.location.origin}${constants.webhooks}add-funds-status`,
        amount: fundsToBeAdded,
        pgdescription: "Add funds from CCP",
        "force-cp": true
      }
    );
    return { url };
  },
  getNext30DaysRenewals: async customerId => {
    const today = new Date();
    const queryParams = {
      "order-by": "order_map.endtime",
      "endtime-end": asEpochSeconds(addDays(today, 30)),
      "endtime-start": asEpochSeconds(today),
      "page-no": 1,
      "no-of-records": 100
    };
    if (process.env.FEATURE_ENDPOINT !== "reseller") {
      queryParams["customer-id"] = customerId;
    }
    let { orderList = [] } = await orderSdk.getOrders(0, queryParams);
    if (orderList.length === 0) {
      return 0;
    }
    let renewPricingById = await orderSdk.getTotalRenewCost(
      orderList.map(_ => _.orderid)
    );
    const {
      details: { role }
    } = await sessionManager.create();
    return Object.values(renewPricingById)
      .map(({ resellerrenewcost, customerrenewcost }) => {
        let [baseCost = 0] =
          role === "reseller"
            ? Object.values(resellerrenewcost)
            : Object.values(customerrenewcost);
        return baseCost;
      })
      .reduce(sum, 0);
  }
};
