import { mapEffect } from "map-effect";
import { put, takeEvery } from "redux-saga/effects";
import { actionTypes, actions } from "./ducks";
import { services } from "./services";
export let autoRenewalSagas = [
  takeEvery(actionTypes.SET_AUTO_RENEWAL, updateAutoRenewal),
  takeEvery(actionTypes.DELETE_PAYMENT_METHOD, mapEffect(deletePaymentMethod))
];

function* updateAutoRenewal(action) {
  let { orderId, status, defaultMessage = "" } = action;
  try {
    yield services.setAutoRenewStatus(orderId, status);
    action.postSuccessAction();
    yield put(actions.setAutorenewalSuccess(orderId, defaultMessage));
  } catch (error) {
    yield put(actions.setAutorenewalFailure(error, orderId));
  }
}

function deletePaymentMethod(action) {
  return services.deletePaymentMethod(action.token);
}
