import { Syringe } from "@faizaanceg/syringe";
import api from "api";
import { parseStringAsBoolean, StringAsBoolean } from "common/parse-string";
import endpoints from "constants/endpoints";

const USER = `${endpoints.apiHost}user/`;
const Endpoints = {
  TOKEN: `${USER}token`,
  PASSWORD: `${USER}password`,
  TOTP_AUTH: `${USER}two-factor`
};
const sessionManager = Syringe.inject("sessionManager");

const RESELLER = {
  IMPERSONATE: `${USER}impersonate`,
  SELF: `${endpoints.bclCacheableOrderBox}resellers/details.json`
};

const CUSTOMER = {
  SELF: `${endpoints.zuulOrderBoxV2}customers/`
};

type ResellerDetails = {
  twofactorgoogleauth_enabled: StringAsBoolean;
  twofactorsmsauth_enabled: StringAsBoolean;
  isoldreseller: StringAsBoolean;
  switch_panel_enabled: StringAsBoolean;
  billingmode: string;
  supportsautorenew: StringAsBoolean;
  resellerstatus: string;
};

async function reseller() {
  let details = await api.get<ResellerDetails>(RESELLER.SELF);
  return {
    ...details,
    twofactorgoogleauth_enabled: parseStringAsBoolean(
      details.twofactorgoogleauth_enabled
    ),
    twofactorsmsauth_enabled: parseStringAsBoolean(
      details.twofactorsmsauth_enabled
    ),
    isOldReseller: parseStringAsBoolean(details.isoldreseller),
    isSwitchPanelEnabled: parseStringAsBoolean(details.switch_panel_enabled),
    billingMode: details.billingmode,
    supportautorenew: parseStringAsBoolean(details.supportsautorenew),
    status: details.resellerstatus
  };
}

async function customer() {
  let response = await api.get<OrderBoxV2.CustomerDto>(CUSTOMER.SELF);
  let {
    customerId,
    status,
    customerStatusDesc,
    pin,
    resellerId,
    twoFactorAuthEnabledPrivate,
    ...details
  } = response;
  return {
    ...details,
    customerid: customerId,
    customerstatus: status,
    customerstatusdesc: customerStatusDesc,
    pin: String(pin),
    resellerId: String(resellerId),
    twofactorauth_enabled: twoFactorAuthEnabledPrivate,
    useremail: details.username,
    parentid: String(resellerId)
  };
}

type TokenResponse = { TOKEN: string };

export const User = {
  /**
   *
   * Creates an autologin token for the current user
   *
   * | Roles    |
   * | -------- |
   * | Customer, Reseller |
   *
   * #### Usage
   *
   * ```js
   * const { TOKEN } = await HttpApi.User.token();
   * ```
   *
   */
  async token(destination = "OB") {
    return api.get<TokenResponse>(Endpoints.TOKEN, { destination });
  },
  /**
   *
   * Fetch complete details about authenticated user.
   *
   * | Roles    |
   * | -------- |
   * | Customer, Reseller |
   *
   * #### Usage
   * ```js
   * const user = await HttpApi.User.self();
   * ```
   */
  async self() {
    let session = await sessionManager.create();
    if (session === null) {
      throw new Error("Failed to get session");
    }
    let { details } = session;
    return details.role.toLowerCase() === "reseller"
      ? await reseller()
      : await customer();
  },
  password: () => api.get<TokenResponse>(Endpoints.PASSWORD),
  reseller: {
    async impersonate(id: string) {
      return api.post<TokenResponse>(RESELLER.IMPERSONATE, {
        "customer-id": id
      });
    }
  },
  twoFactor: {
    async enable() {
      let { details } = await sessionManager.create();
      return api.post<{ qrCode: string; key: string }>(
        `/api/totp-auth/${details.role.toUpperCase()}_${details.userId}/signup`
      );
    },
    async disable() {
      let { details } = await sessionManager.create();
      return api.delete(
        `/api/totp-auth/${details.role.toUpperCase()}_${details.userId}`
      );
    }
  }
};
