import api from "api";
import constants from "constants/endpoints";

export default {
  addNameServer: (orderId, { cns, ip }) =>
    api.post(`${constants.domain}add-cns`, { "order-id": orderId, cns, ip }),

  deleteNameServer: (orderId, { cns, ip }) =>
    api.post(`${constants.domain}delete-cns-ip`, {
      "order-id": orderId,
      cns,
      ip
    }),

  modifyNameServerIP: (orderId, { cns, oldIP, newIP }) =>
    api.post(`${constants.domain}modify-cns-ip`, {
      "order-id": orderId,
      cns,
      "old-ip": oldIP,
      "new-ip": newIP
    }),

  modifyNameServerCNS: (orderId, { oldCNS, newCNS }) =>
    api.post(`${constants.domain}modify-cns-name`, {
      "order-id": orderId,
      "old-cns": oldCNS,
      "new-cns": newCNS
    })
};
