import { formatAmountWithCurrency } from "common/localize-amount";
import { actions as logoutAction } from "common/session-management/ducks";
import { applyAll, getInitials } from "common/util";
import { withWalletInfo } from "common/wallet";
import { styled } from "linaria/react";
import { actions as addFundsActions } from "modals/add-funds/ducks";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, Router } from "react-router-dom";
import history from "../../history";
import { Dollar } from "../../side-bar/icons/dollar";

let UserProfileSection = styled.div`
  border-bottom: 1px #e9e9eb solid;
`;
let UserProfileImage = styled.div`
  width: 70px;
  margin-top: 20px;
  padding-left: 20px;
`;
let UserInitials = styled.div`
  background: #9575cd;
  color: #ffffff;
  height: 36px;
  width: 36px;
  line-height: 36px;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  font-family: "OpenSans";
  font-weight: 600;
`;
let UserProfileContent = styled.div`
  flex: auto;
  padding: 20px 20px 19px 0;
  border-bottom: 1px #f3f3f5 solid;
`;
let UserProfileName = styled.div`
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 500;
  color: #434c5e;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 19px;
`;
let UserProfileEmail = styled.div`
  font-family: "OpenSans";
  color: #596275;
  font-size: 12px;
  line-height: 17px;
  margin-top: 2px;
`;
let UserProfileWebProID = styled.div`
  font-family: "OpenSans";
  font-weight: normal;
  color: #596275;
  font-size: 12px;
  line-height: 17px;
  margin-top: 3px;
`;

let UserProfileLink = styled.div`
  a {
    font-family: "OpenSans";
    color: #434c5e;
    font-size: 13px;
    line-height: 18px;
    display: block;
    padding: 17px 0 18px 70px;
    transition: 0.2s all linear;
    &:hover,
    &:focus {
      color: #434c5e;
      background: #f7f7f7;
    }
  }
`;
let UserProfileDetails = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

let UserShowAddfundsLink = styled.div`
  font-family: "OpenSans";
  color: #596275;
  font-size: 12px;
  line-height: 14px;
  border-bottom: 1px rgba(89, 98, 117, 0.7) solid;
  display: inline-block;
  cursor: pointer;
  margin-top: 7px;
`;

let UserProfileBalance = styled.div`
  font-family: OpenSans;
  font-weight: 600;
  color: #434c5e;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 18px;
`;
let UserProfileWallet = styled.div`
  font-family: OpenSans;
  color: #6d7687;
  font-size: 11px;
  line-height: 15px;
  margin-top: 2px;
`;

let UserSignout = styled.button`
  color: #434c5e;
  font-size: 13px;
  line-height: 18px;
  padding: 17px 0 18px 70px;
  border: 0;
  outline: none;
  width: 100%;
  text-align: left;
  background: transparent;
  &:hover {
    background: #f7f7f7;
  }
`;

let UserCurrency = styled.div`
  background: #d6ffdb;
  color: #28bd8b;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
`;

function ProfilePopover(props) {
  const {
    logout,
    currencySymbol,
    walletBalance = 0,
    showAddFunds,
    userInfo
  } = props;
  const { role } = userInfo;
  const { t } = useTranslation();
  return (
    <Router history={history}>
      <div>
        <UserProfileSection>
          <UserProfileDetails>
            <UserProfileImage>
              <UserInitials>{getInitials(userInfo.name)}</UserInitials>
            </UserProfileImage>
            <UserProfileContent>
              <UserProfileName>{userInfo.name}</UserProfileName>
              <UserProfileEmail>{userInfo.useremail}</UserProfileEmail>
              <UserProfileWebProID>
                {t(
                  "USER_ID",
                  `${role === "reseller" ? "Web Pro" : "Customer"} ID`,
                  {
                    userType: role === "reseller" ? "Web Pro" : "Customer"
                  }
                )}{" "}
                : {userInfo.userid}
              </UserProfileWebProID>
            </UserProfileContent>
          </UserProfileDetails>
          <UserProfileLink>
            <NavLink
              role="button"
              to="/dashboard/profile"
              className="dropdown-security-btn"
              onClick={props.clicked}
            >
              {t("ACCOUNT_N_SECURITY", "Account & Security")}
            </NavLink>
          </UserProfileLink>
        </UserProfileSection>
        {process.env.FEATURE_ADD_FUNDS !== "none" && (
          <UserProfileSection>
            <UserProfileDetails>
              <UserProfileImage>
                <UserCurrency>
                  <Dollar />
                </UserCurrency>
              </UserProfileImage>
              <UserProfileContent>
                <UserProfileBalance className="notranslate">
                  {formatAmountWithCurrency({
                    cost: walletBalance,
                    currency: currencySymbol,
                    currencyDisplay: "code"
                  })}
                </UserProfileBalance>
                <UserProfileWallet>
                  {t("WALLET_BALANCE", "Wallet Balance")}
                </UserProfileWallet>
                <div>
                  <UserShowAddfundsLink
                    data-testid="open-add-funds"
                    onClick={applyAll(showAddFunds, props.clicked)}
                  >
                    {t("ADD_FUNDS", "Add funds")}
                  </UserShowAddfundsLink>
                </div>
              </UserProfileContent>
            </UserProfileDetails>
            {role === "reseller" && (
              <UserProfileLink>
                <NavLink
                  role="button"
                  to="/transactions"
                  onClick={props.clicked}
                >
                  {t("TXNRC_WITH_RC_MENU", "Transactions with ResellerClub")}
                </NavLink>
              </UserProfileLink>
            )}
          </UserProfileSection>
        )}
        <UserSignout onClick={logout}>{t("SIGN_OUT", "Sign Out")}</UserSignout>
      </div>
    </Router>
  );
}

const mapStateToProps = state => ({
  userInfo: state.root.userInfo
});
const mapDispatchToProps = {
  showAddFunds: addFundsActions.showModal,
  logout: logoutAction.logout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWalletInfo(ProfilePopover));
