import { isFailure, isSuccess } from "common/api-status/utils";
import { InlineNotification } from "common/inline-notifications";
import ModalHandler from "common/modal-handler";
import { services } from "common/pay-buttons/services";
import React from "react";
import { actionTypes } from "../ducks";
import AddPaymentMethod from "../modals/add-payment-method";
import DeletePaymentMethod from "../modals/delete-payment-method";

export function PaymentMethods(props) {
  const {
    paypw,
    paypal,
    payu,
    supportautorenew,
    deletePaymentMethod,
    showDeletePaymentMethod,
    showAddPaymentMethod,
    role,
    addCardSuccess
  } = props;
  return supportautorenew &&
    (paypw.isAllowed || paypal.isAllowed || payu.isAllowed) ? (
    <div
      className="manage-payment-methods"
      data-testid="manage-payment-methods"
    >
      {props.render({
        paypal,
        paypw,
        payu,
        showDeletePaymentMethod,
        showAddPaymentMethod:
          role === "reseller" ? showAddPaymentMethod : services.addNewCard
      })}
      <ModalHandler
        opensOn={actionTypes.SHOW_DELETE_PAYMENT_METHOD}
        closesOn={isSuccess(actionTypes.DELETE_PAYMENT_METHOD)}
        render={(modalProps, action) =>
          modalProps.show && (
            <DeletePaymentMethod
              {...modalProps}
              monitorInfo={[[actionTypes.DELETE_PAYMENT_METHOD]]}
              itemToBeDeleted={action}
              deletePaymentMethod={deletePaymentMethod}
            />
          )
        }
      />
      <ModalHandler
        opensOn={actionTypes.SHOW_ADD_PAYMENT_METHOD}
        closesOn={[
          isSuccess(actionTypes.ADD_CARD),
          isFailure(actionTypes.ADD_CARD)
        ]}
        render={(modalProps, action) =>
          modalProps.show && (
            <AddPaymentMethod
              {...modalProps}
              paymentMethodDetails={action}
              addCardSuccess={addCardSuccess}
            />
          )
        }
      />
      <InlineNotification
        defaultMessage="Your payment mode has been deleted"
        triggeredBy={isSuccess(actionTypes.DELETE_PAYMENT_METHOD)}
      />
      <InlineNotification
        triggeredBy={[
          isSuccess(actionTypes.ADD_CARD),
          isFailure(actionTypes.ADD_CARD)
        ]}
      />
    </div>
  ) : null;
}
