import api from "api";
import { isIntegratedWithExpose } from "common/util";
import constants from "constants/endpoints";
import format from "string-format";

export const services = {
  suspendOrder: ({ reason, orderid }) =>
    api.post(`${constants.zuulOrderBox}orders/suspend`, {
      reason,
      "order-id": orderid
    }),
  unSuspendOrder: ({ orderid }) =>
    api.post(`${constants.zuulOrderBox}orders/unsuspend`, {
      "order-id": orderid
    }),
  deleteOrder: (endpoint, orderid, reason, productName = null) =>
    isIntegratedWithExpose(productName)
      ? api
          .delete(
            format(endpoint, { orderid, reason }),
            {},
            new URLSearchParams()
          )
          .catch(async err => {
            if (err.cause?.response) {
              let json = await err.cause.response.json();
              throw new Error(json.error);
            }
          })
      : api.post(format(endpoint, { orderid }))
};
