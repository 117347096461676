import {
  AliPay,
  AuthorizeForm,
  AliPaySmall,
  AuthorizeFormSmall,
  CCAvenue,
  CCAvenue1x,
  // CCAvenueSmall1x,
  CCAvenueSmall2x,
  CheckoutV2,
  Doku,
  Ebanx,
  PagSeguro,
  PayPal,
  PayPal1x,
  // PayPalSmall1x,
  PayPalSmall2x,
  PayPW,
  Paytm,
  PayUGlobal,
  PayUGlobal1x,
  // PayUGlobalSmall1x,
  PayUGlobalSmall2x,
  Skrill,
  WebMoney,
  WorldPay,
  Dlocal,
  Wallet
} from "assets/icons/gateways";

export function getGatewayLogo(gatewayName) {
  switch (gatewayName) {
    case "2checkoutV2":
    case "2checkout":
      return CheckoutV2;
    case "alipay":
    case "alipayoverseas":
      return AliPay;
    case "authorizeform":
      return AuthorizeForm;
    case "ccavenue":
    case "ccavenueold":
      return CCAvenue;
    case "ebanx":
      return Ebanx;
    case "moneybookers":
      return Skrill;
    case "pagseguro":
      return PagSeguro;
    case "pay.pw":
      return PayPW;
    case "paypalec":
      return PayPal;
    case "paytm":
      return Paytm;
    case "payu.in":
      return PayUGlobal;
    case "payulatam":
      return PayUGlobal;
    case "webmoney":
      return WebMoney;
    case "worldpay":
      return WorldPay;
    case "doku":
      return Doku;
    case "paasvoucherbased":
      return Dlocal;
    case "wallet":
      return Wallet;
    default:
      return null;
  }
}

const multipleLogos = {
  ccavenue: [CCAvenue, CCAvenue1x, CCAvenueSmall2x, CCAvenueSmall2x],
  paypalec: [PayPal, PayPal1x, PayPalSmall2x, PayPalSmall2x],
  "payu.in": [PayUGlobal, PayUGlobal1x, PayUGlobalSmall2x, PayUGlobalSmall2x],
  payulatam: [PayUGlobal, PayUGlobal1x, PayUGlobalSmall2x, PayUGlobalSmall2x],
  authorizeform: [
    AuthorizeForm,
    AuthorizeForm,
    AuthorizeFormSmall,
    AuthorizeFormSmall
  ],
  alipay: [AliPay, AliPay, AliPaySmall, AliPaySmall]
};

export function getGatewayLogoMultiple(gatewayName) {
  return (
    multipleLogos[gatewayName] ?? Array(4).fill(getGatewayLogo(gatewayName))
  );
}
