import { FeedbackIcon, FeedbackThumb } from "assets";
import { Checkbox } from "common/checkbox";
import { FeedbackSdk } from "common/sdk/feedback";
import { actions } from "common/session-management/ducks";
import { wait } from "common/wait";
import { Field, FieldArray, Form, Formik } from "formik";
import { ModalHandlerProps } from "modals/modal";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import useSWRMutation from "swr/mutation";
import { reasons } from "./constants";

import "./styles.scss";

function FeedbackModal(props: ModalHandlerProps) {
  let userId = useSelector(state => state.root.userInfo.userid);
  const dispatch = useDispatch();
  const addFeedback = useSWRMutation("add-feedback", (_, { arg: reason }) =>
    FeedbackSdk.accept({
      app: "WebPro",
      category: "General",
      reason,
      role: "reseller",
      userId
    }).catch(() => {})
  );
  let [step, setStep] = useState(0);
  return (
    <Modal
      dialogClassName="feedback-modal"
      backdrop={false}
      show={props.show}
      keyboard
      onHide={props.onHide}
      data-testid="feedback-modal"
    >
      <Modal.Body>
        {step === 0 && (
          <div className="feedback-step-one">
            <div className="feedback-icon">
              <img src={FeedbackIcon} alt="feedback" />
            </div>
            <div className="feedback-step-title">
              Looks like you are switching often to the old panel
            </div>
            <div className="feedback-step-note">
              Tell us what you&apos;re missing, we will be deprecating the old
              panel soon.
            </div>
            <Formik
              enableReinitialize
              initialValues={{ reason: [], otherReason: "" }}
              onSubmit={async values => {
                const feedbackList = [...values.reason, values.otherReason];
                feedbackList.splice(feedbackList.indexOf("Other"), 1);
                setStep(1);
                await wait(3000);
                await addFeedback.trigger(feedbackList.join(","));
                dispatch(actions.goToControlPanel());
              }}
            >
              {({ values, dirty }) => (
                <Form>
                  <div className="feedback-checkbox-wrap">
                    <FieldArray
                      name="reason"
                      render={arrayHelpers =>
                        reasons.map(reason => (
                          <React.Fragment key={reason}>
                            <Checkbox
                              screen=""
                              name="reason"
                              field={{
                                checked: values.reason.includes(reason)
                              }}
                              onChange={e => {
                                if (e.target.checked) {
                                  arrayHelpers.push(reason);
                                } else {
                                  let idx = values.reason.indexOf(reason);
                                  arrayHelpers.remove(idx);
                                }
                              }}
                            >
                              {reason}
                            </Checkbox>
                            {reason.toLowerCase() === "other" &&
                              values.reason.includes(reason) && (
                                <label className="feedback-other-reason">
                                  <Field
                                    component="textarea"
                                    className="reason"
                                    placeholder="Enter a reason"
                                    name="otherReason"
                                  />
                                </label>
                              )}
                          </React.Fragment>
                        ))
                      }
                    />
                  </div>
                  <div className="feedback-actions">
                    <button
                      disabled={
                        !dirty ||
                        (values.reason.includes("Other") &&
                          values.otherReason === "")
                      }
                      type="submit"
                    >
                      SUBMIT MY FEEDBACK
                    </button>
                    <div
                      className="feedback-skip"
                      onClick={() => dispatch(actions.goToControlPanel())}
                    >
                      Skip for now
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
        {step === 1 && (
          <div className="feedback-step-two">
            <div className="feedback-section">
              <div className="feedback-icon">
                <img src={FeedbackThumb} alt="feedback-success" />
              </div>
              <div className="feedback-success-text">
                Thank you for the feedback
              </div>
              <div className="feedback-success-subtext">
                We are redirecting you to the old panel...
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}

export default FeedbackModal;
