import { mapEffect } from "map-effect";
import { call, takeEvery } from "redux-saga/effects";
import { actionTypes } from "./ducks";
import { ApprovalType } from "./modal/constants";
import { services } from "./services";

const widgetContactDetailsSagas = [
  takeEvery(actionTypes.UPDATE_CONTACT_DETAILS, mapEffect(updateContactDetails))
];

function* getModifyParamsForContacts(action) {
  let { meta, modifiedValues, initialValues } = action;
  let contactTypesToBeModified = [];
  for (let contactType in initialValues) {
    Object.entries(initialValues[contactType]).forEach(([key, value]) => {
      if (
        modifiedValues[contactType][key] !== value &&
        !contactTypesToBeModified.includes(contactType)
      ) {
        contactTypesToBeModified.push(contactType);
      }
    });
  }

  let modifiedContactIds = {};
  for (let contact of contactTypesToBeModified) {
    let { address, phone_cc, ...contactToBeModified } = modifiedValues[contact];
    let params = {
      type: "Contact",
      "customer-id": meta.customerid,
      "address-line-1": address,
      "phone-cc": phone_cc,
      ...contactToBeModified
    };
    modifiedContactIds[contact] = yield services.updateContactDetails(params);
  }

  modifiedContactIds = {
    admin: modifiedValues.admin.contactId,
    technical: modifiedValues.technical.contactId,
    registrant: modifiedValues.registrant.contactId,
    billing: modifiedValues.billing.contactId,
    ...modifiedContactIds
  };

  return {
    reg_contact: modifiedContactIds.registrant,
    admin_contact: modifiedContactIds.admin,
    billing_contact: modifiedContactIds.billing,
    tech_contact: modifiedContactIds.technical
  };
}

function* updateContactDetails(action) {
  const { modifiedValues } = action;

  let modifyParams = yield call(getModifyParamsForContacts, action);
  if (modifiedValues.approvalType) {
    let isDesignatedAgent =
      modifiedValues.approvalType === ApprovalType.DESIGNATED_AGENT;
    modifyParams = {
      ...modifyParams,
      "designated-agent": isDesignatedAgent,
      sixty_day_lockout:
        !isDesignatedAgent && !modifiedValues.disableSixtyDayLock
    };
  }

  return yield services.modifyContactDetails(action.meta.orderid, modifyParams);
}

updateContactDetails.onSuccess = action =>
  action.modifiedValues.approvalType
    ? "We have raised a request to change the Contact Details. They will get updated once the request is approved."
    : "Your contact details have been changed.";

export default widgetContactDetailsSagas;
