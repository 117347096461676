import { Syringe } from "@faizaanceg/syringe";
import { Portal } from "@reach/portal";
import { PRect, useRect } from "@reach/rect";
import {
  Position,
  TooltipContentProps,
  TooltipPopupProps,
  positionTooltip
} from "@reach/tooltip";
import { makeId, useComposedRefs } from "@reach/utils";
import React, { useRef } from "react";

function VisuallyHidden({ style = {}, ...props }) {
  return (
    <span
      style={{
        border: 0,
        clip: "rect(0 0 0 0)",
        height: "1px",
        margin: "-1px",
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        width: "1px",
        whiteSpace: "nowrap",
        wordWrap: "normal",
        ...style
      }}
      {...props}
    />
  );
}

function getStyles(
  position: Position,
  triggerRect: PRect,
  tooltipRect: PRect
): React.CSSProperties {
  let haventMeasuredTooltipYet = !tooltipRect;
  if (haventMeasuredTooltipYet) {
    return { visibility: "hidden" };
  }
  return position(triggerRect, tooltipRect);
}

const TooltipContent = React.forwardRef(function TooltipContent(
  {
    "aria-label": realAriaLabel,
    id,
    isVisible,
    label,
    position = positionTooltip,
    style,
    triggerRect,
    ...props
  },
  forwardedRef
) {
  let hasAriaLabel = realAriaLabel != null;

  let ownRef = React.useRef(null);
  let ref = useComposedRefs(forwardedRef, ownRef);
  let tooltipRect = useRect(ownRef, { observe: isVisible });
  return (
    <React.Fragment>
      <div
        role={hasAriaLabel ? undefined : "tooltip"}
        {...props}
        ref={ref}
        data-reach-tooltip=""
        id={hasAriaLabel ? undefined : id}
        style={{
          ...style,
          ...getStyles(position, triggerRect as PRect, tooltipRect as PRect)
        }}
      >
        {label}
      </div>
      {hasAriaLabel && (
        <VisuallyHidden role="tooltip" id={id}>
          {realAriaLabel}
        </VisuallyHidden>
      )}
    </React.Fragment>
  );
}) as (
  props: TooltipContentProps & JSX.IntrinsicElements["div"]
) => React.ReactElement | null;

export const TooltipPopupWithWC = React.forwardRef(function TooltipPopup(
  { label, isVisible, id, ...props },
  forwardRef
) {
  let container = Syringe.inject("portalContainer");
  let containerRef = useRef(container);
  return isVisible ? (
    <Portal containerRef={containerRef}>
      <TooltipContent
        ref={forwardRef}
        label={label}
        isVisible={isVisible}
        {...props}
        id={makeId("tooltip", String(id))}
      />
    </Portal>
  ) : null;
}) as (
  props: TooltipPopupProps & JSX.IntrinsicElements["div"]
) => React.ReactElement | null;
