import webProStore from "ducks";
import { getTaxAmount, getTaxInfo } from "common/util";
const _convertedCurrencyAppender = (
  currencySymbol,
  conversionRate,
  amountSummary
) => ({
  ...amountSummary,
  currencySymbol,
  conversionRate,
  total_sum_USD: amountSummary.total_sum * conversionRate,
  tax_sum_USD: amountSummary.tax_sum * conversionRate,
  subtotal_sum_USD: amountSummary.subtotal_sum * conversionRate
});

const gtmEcommerceDedicatedIPSuccessFormatter = (
  attributes,
  paymentResponse
) => {
  let { addonid, productkey, product_category } = attributes;
  let {
    wallet: { taxinfo, currencySymbol },
    root: { consolidatedPricing }
  } = { ...webProStore.getState() };
  let price = consolidatedPricing[productkey].addons[addonid];
  let netRevenue = 0;
  let netTax = 0;

  let ecommData = {
    currencyCode: currencySymbol,
    purchase: {
      products: []
    }
  };

  for (const e of paymentResponse) {
    let {
      payload: {
        pathParams: { orderid }
      }
    } = e;

    let tax = parseFloat(
      getTaxAmount(price, getTaxInfo({ product_category }, taxinfo))
    );

    netTax += tax;
    netRevenue += tax + price;
    ecommData.purchase.products.push({
      name: addonid || product_category,
      id: orderid,
      price: price,
      category: "Add-on",
      variant: "Purchase",
      quantity: 1,
      coupon: null
    });
  }

  ecommData.purchase.actionField = {
    id: Date.now(),
    revenue: netRevenue,
    tax: netTax,
    coupon: "NA"
  };

  return ecommData;
};

const gtmAddonsEcommerceSuccessFormatter = attributes => {
  //
  let { ordersArray } = attributes;
  let {
    wallet: { currencySymbol }
  } = { ...webProStore.getState() };

  let netRevenue = 0;
  let netTax = 0;

  let ecommData = {
    currencyCode: currencySymbol,
    purchase: {
      products: []
    }
  };

  for (const addon of ordersArray) {
    let { price } = addon;
    let tax = parseFloat(getTaxAmount(price, attributes.taxPercent));
    netTax += tax;
    netRevenue += tax + price;

    ecommData.purchase.products.push({
      name: addon.addondetail,
      id: addon.orderid,
      price: addon.price,
      category: "Add-on",
      variant: "Purchase",
      quantity: 1,
      coupon: null
    });
  }

  ecommData.purchase.actionField = {
    id: Date.now(),
    revenue: netRevenue,
    tax: netTax,
    coupon: "NA"
  };
  return ecommData;
};

const gtmRenewSuccessFormatter = (attributes, paymentResponse) => {
  let {
    wallet: { taxinfo, currencySymbol },
    payButtons: { conversionRate }
  } = { ...webProStore.getState() };

  attributes.numberOfOrders = paymentResponse.length;

  for (const e of paymentResponse) {
    let {
      payload: {
        meta: { orderid, productkey, productcategory, price: subtotal },
        pathParams: {
          selected_tenure: duration,
          privacy_protection,
          auto_renew
        }
      }
    } = e;

    let tax = parseFloat(
      getTaxAmount(subtotal, getTaxInfo({ productcategory }, taxinfo))
    );

    attributes.ordersArray.push({
      orderid,
      productkey,
      duration,
      privacy_protection,
      auto_renew,
      subtotal,
      tax: tax,
      total: tax + subtotal
    });

    attributes.amountSummary.subtotal_sum += subtotal;
    attributes.amountSummary.total_sum += tax + subtotal;
    attributes.amountSummary.tax_sum += tax;
  }

  attributes.amountSummary = {
    ..._convertedCurrencyAppender(
      currencySymbol,
      conversionRate,
      attributes.amountSummary
    )
  };

  return attributes;
};

const gtmEcommerceRenewSuccessFormatter = (attributes, paymentResponse) => {
  let {
    wallet: { taxinfo, currencySymbol }
  } = { ...webProStore.getState() };
  let netRevenue = 0;
  let netTax = 0;

  let ecommData = {
    currencyCode: currencySymbol,
    purchase: {
      products: []
    }
  };

  for (const e of paymentResponse) {
    let {
      payload: {
        meta: { product_category, price, productcategory },
        pathParams: { orderid }
      }
    } = e;

    let tax = parseFloat(
      getTaxAmount(price, getTaxInfo({ productcategory }, taxinfo))
    );

    netTax += tax;
    netRevenue += tax + price;
    ecommData.purchase.products.push({
      name: product_category,
      id: orderid,
      price: price,
      category: productcategory,
      variant: "Renewal",
      quantity: 1,
      coupon: null
    });
  }

  ecommData.purchase.actionField = {
    id: Date.now(),
    revenue: netRevenue,
    tax: netTax,
    coupon: "NA"
  };

  return ecommData;
};

const gtmAddonSuccessFormatter = (attributes, action, paymentResponse) => {
  let {
    wallet: { currencySymbol },
    payButtons: { conversionRate }
  } = { ...webProStore.getState() };

  for (const e of paymentResponse) {
    let {
      payload: {
        actionTypes: {
          failureActionPayload: { orderid }
        },
        meta: { price, action: addondetail }
      }
    } = e;
    let tax = parseFloat(
      getTaxAmount(price, action.track.attributes.taxPercent)
    );

    attributes.ordersArray.push({
      orderid,
      addondetail,
      price,
      tax: tax,
      total: tax + price
      // paymentMethod
    });

    attributes.amountSummary.subtotal_sum += price;
    attributes.amountSummary.total_sum += tax + price;
    attributes.amountSummary.tax_sum += tax;
  }

  attributes.amountSummary = {
    ..._convertedCurrencyAppender(
      currencySymbol,
      conversionRate,
      attributes.amountSummary
    )
  };

  return attributes;
};

const gtmEcommerceBuyPrivacyProtection = (attributes, paymentResponse) => {
  let {
    wallet: { taxinfo, currencySymbol },
    root: { consolidatedPricing }
  } = { ...webProStore.getState() };
  let price = consolidatedPricing.privacy_protection;
  let netRevenue = 0;
  let netTax = 0;

  let ecommData = {
    currencyCode: currencySymbol,
    purchase: {
      products: []
    }
  };

  for (const e of paymentResponse) {
    let {
      payload: {
        pathParams: { orderid }
      }
    } = e;

    let tax = parseFloat(getTaxAmount(price, getTaxInfo("", taxinfo)));

    netTax += tax;
    netRevenue += tax + price;
    ecommData.purchase.products.push({
      name: "Privacy Protection",
      id: orderid,
      price: price,
      category: "Add-on",
      variant: "Purchase",
      quantity: 1,
      coupon: null
    });
  }

  ecommData.purchase.actionField = {
    id: Date.now(),
    revenue: netRevenue,
    tax: netTax,
    coupon: "NA"
  };

  return ecommData;
};

const gtmFormatters = {
  gtmEcommerceDedicatedIPSuccessFormatter,
  gtmAddonsEcommerceSuccessFormatter,
  gtmRenewSuccessFormatter,
  gtmEcommerceRenewSuccessFormatter,
  gtmAddonSuccessFormatter,
  gtmEcommerceBuyPrivacyProtection
};

export default gtmFormatters;
