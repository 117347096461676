import { parseStringAsBoolean } from "common/parse-string";
import { dangerouslyReadStoreForPaymentStatus } from "common/with-user-payments";
import { getSimpleNameFromProductKey } from "constants/index";
import { groupBy } from "lodash";
import { helpers } from "widget-models/manage-addons/ducks";
import { parsers as blocksParsers } from "widget-models/manage-blocks/ducks";
import { parsers as manageIP } from "widget-models/manage-ip/ducks";
import { parsers as manageWHMCS } from "widget-models/manage-whmcs/ducks";
import { getBlockStorageDetails } from "./blockstorage";

const getDomainAddonDetails = (orderDetails, _, addonPricingDetails) => {
  let {
    privacyprotectendtime,
    privacyprotectedallowed,
    premiumdnsallowed,
    premiumdnsendtime
  } = orderDetails;
  let displayText = "";
  let addons = {};
  if (privacyprotectedallowed === "true") {
    let { privacy_protection = 0 } =
      addonPricingDetails.reseller_pricing ?? addonPricingDetails;
    let { privacy_protection: customer_privacy_protection = 0 } =
      addonPricingDetails.customer_pricing ?? addonPricingDetails;
    displayText = privacyprotectendtime ? "Includes: Domain Protect +" : "";
    addons.purchase_privacy = {
      totalPrice: 0,
      pricingDetails: privacy_protection,
      customerPricingDetails: customer_privacy_protection,
      price: privacy_protection,
      inputType: "checkbox",
      addonDisplayName: "Domain Protect+",
      type: "privacy_protection",
      param: true,
      hasAddonBeenPurchased: !!privacyprotectendtime
    };
  } else {
    addons.purchase_privacy = {
      type: "privacy_protection",
      param: false
    };
  }
  if (premiumdnsallowed === "true") {
    const { premium_dns = 0 } =
      addonPricingDetails.reseller_pricing ?? addonPricingDetails;
    let { premium_dns: customer_premium_dns = 0 } =
      addonPricingDetails.customer_pricing ?? addonPricingDetails;
    if (premiumdnsendtime) {
      displayText =
        displayText.length > 0
          ? (displayText += ", Premium DNS")
          : "Includes: Premium DNS";
    }
    addons.purchase_pdns = {
      totalPrice: 0,
      pricingDetails: premium_dns,
      customerPricingDetails: customer_premium_dns,
      price: premium_dns,
      inputType: "checkbox",
      addonDisplayName: "Premium DNS",
      type: "premium_dns",
      param: false,
      hasAddonBeenPurchased: !!premiumdnsendtime,
      tooltip:
        "Get advance DNS service to improve the security, performance, and reliability of your site"
    };
  } else {
    addons.purchase_pdns = {
      type: "premium_dns",
      param: false
    };
  }

  return {
    displayText,
    addons
  };
};

const getServerHostingAddonDetails = (orderDetails = {}, _, pricingDetails) => {
  const {
    addon_quantity = [],
    installed_os: { addons = addon_quantity } = {},
    productkey
  } = orderDetails;
  const addonsByName = groupBy(
    addons
      .filter(addon => addon.is_paid)
      .map(addon => ({
        ...addon,
        name: addon.name ? addon.name : addon.addon_name
      })),
    "name"
  );
  let addonsPricing = pricingDetails.reseller_pricing[productkey]?.addons ?? {};
  let addonsPricingForCustomer =
    pricingDetails.customer_pricing[productkey]?.addons ?? {};
  let SSLAddons = ["ipaddress", "ssl"];
  let addonDetails = Object.fromEntries(
    Object.entries(addonsByName).map(([name, addons]) => {
      let totalPrice = addons.length * addonsPricing[name];
      let addonDisplayName = SSLAddons.includes(name)
        ? "Dedicated IP"
        : helpers.getAddonName(productkey, name);
      return [
        name,
        {
          inputType: "unmodifiable_checkbox",
          addonDisplayName: `Includes ${addonDisplayName} x ${addons.length}`,
          addonEnabled: false,
          addonPriceIncluded: true,
          customerPricingDetails:
            addons.length * addonsPricingForCustomer[name],
          totalPrice,
          type: name,
          param: false,
          price: totalPrice,
          pricingDetails: totalPrice
        }
      ];
    })
  );
  return { addons: addonDetails };
};

const getVPSAddonDetails = (orderDetails = {}, _, __) => {
  const { installed_os: { addons = [] } = {} } = orderDetails;
  const displayText = addons
    .filter(addon => {
      let name = addon.name ? addon.name : addon.addon_name;
      return name !== "ipaddress" && name !== "ssl";
    })
    .map(addon =>
      helpers.getVPSAddonName(addon.name ? addon.name : addon.addon_name)
    )
    .join(", ");

  return {
    displayText: displayText.length > 0 ? `Includes ${displayText}` : "",
    addons: {}
  };
};

const getDedicatedServerAddonDetails = (orderDetails = {}, _, __) => {
  const { installed_os: { addons = [] } = {} } = orderDetails;
  const displayText = addons
    .filter(addon => addon.addon_name !== "ipaddress")
    .map(addon => helpers.getDedicatedAddonName(addon.addon_name))
    .join(", ");

  return {
    displayText: displayText.length > 0 ? `Includes ${displayText}` : "",
    addons: {}
  };
};

const getSingleDomainHostingAddonDetails = (
  orderDetails,
  _,
  addonPricingDetails
) => {
  const {
    planid,
    bandid,
    productkey = "default",
    ssl = "false"
  } = orderDetails;
  const sslPrice =
    addonPricingDetails.reseller_pricing?.[productkey]?.[productkey]?.[bandid]
      ?.pricing?.ssl ??
    (addonPricingDetails.reseller_pricing ?? addonPricingDetails)?.[
      productkey
    ]?.[planid]?.ssl ??
    0;
  const customerSSLPrice =
    addonPricingDetails.customer_pricing?.[productkey]?.[productkey]?.[bandid]
      ?.pricing?.ssl ??
    (addonPricingDetails.customer_pricing ?? addonPricingDetails)?.[
      productkey
    ]?.[planid]?.ssl ??
    0;
  let isSSLPurchased = parseStringAsBoolean(ssl);
  return {
    displayText: " ",
    addons: {
      ssl: {
        totalPrice: 0,
        pricingDetails: sslPrice,
        customerPricingDetails: customerSSLPrice,
        price: sslPrice,
        inputType: "checkbox",
        addonDisplayName: "Include Dedicated IP",
        type: "ssl",
        param: false,
        addonEnabled: isSSLPurchased,
        addonPriceIncluded: isSSLPurchased
      }
    }
  };
};

const getMultiDomainHostingAddonDetails = (
  orderDetails,
  _,
  addonPricingDetails
) => {
  let displayText = " ";
  const { planid, productkey = "default", ssl = "false" } = orderDetails;
  const { [productkey]: { [planid]: { ssl: sslPrice = 0 } = {} } = {} } =
    addonPricingDetails.reseller_pricing ?? addonPricingDetails;
  const {
    [productkey]: { [planid]: { ssl: customerSSLPrice = 0 } = {} } = {}
  } = addonPricingDetails.customer_pricing ?? addonPricingDetails;
  let isSSLPurchased = parseStringAsBoolean(ssl);
  return {
    displayText,
    addons: {
      ssl: {
        totalPrice: 0,
        pricingDetails: sslPrice,
        customerPricingDetails: customerSSLPrice,
        price: sslPrice,
        inputType: "checkbox",
        addonDisplayName: "Include Dedicated IP",
        type: "ssl",
        param: false,
        addonEnabled: isSSLPurchased
      }
    }
  };
};

const getBusinessEmailAddonDetails = (orderDetails, _, addonPricingDetails) => {
  let { emailaccounts, addons } = orderDetails;
  let addonDetails = {};
  let storageAddon = addons?.find(_ => _.addon_name === "email_storage_5");
  if (storageAddon) {
    addonDetails.blocksInfo = {
      inputType: "message",
      message: `Includes ${storageAddon?.quantity} Block${
        storageAddon?.quantity > 1 ? "s" : ""
      } of Email Storage`
    };
  }
  return {
    displayText: emailaccounts
      ? `${emailaccounts} account${emailaccounts > 1 ? "s" : ""}`
      : "",
    addons: addonDetails
  };
};

const getCloudhostingAddonDetails = (orderDetails, _, __) => {
  const { addon_quantity = {} } = orderDetails;
  let displayTexts = [];
  let addons = {};
  if (addon_quantity.ram) {
    displayTexts.push(`${addon_quantity.ram} GB RAM`);
  }
  if (addon_quantity.cpu) {
    displayTexts.push(`${addon_quantity.cpu} Core CPU`);
  }
  return {
    displayText: displayTexts.join(", "),
    addons
  };
};

const getWordpressAddonDetails = (orderDetails, _, __) => {
  const { addon_quantity = [] } = orderDetails;
  let displayQuantities = { ip: 0, ram: 0, cpu: 0 };
  addon_quantity.forEach(addon => {
    if (addon.addon_name === "cpu_1") {
      displayQuantities.cpu += 1;
    } else if (addon.addon_name === "ram_1") {
      displayQuantities.ram += 1;
    } else {
      displayQuantities.ip += 1;
    }
  });

  return {
    displayText: `Includes ${displayQuantities.ip} IP Address, ${displayQuantities.ram} GB RAM, ${displayQuantities.cpu} Core CPU`,
    addons: {}
  };
};

const getEmailAddonDetails = (orderDetails, _, __) => {
  let { emailaccounts, addons } = orderDetails;
  let addonDetails = {};
  let storageAddon = addons?.find(_ => _.addon_name === "email_storage_5");
  if (storageAddon) {
    addonDetails.blocksInfo = {
      inputType: "message",
      message: `Includes ${storageAddon?.quantity} Block${
        storageAddon?.quantity > 1 ? "s" : ""
      } of Email Storage`
    };
  }
  return {
    displayText: emailaccounts
      ? `${emailaccounts} account${emailaccounts > 1 ? "s" : ""}`
      : "",
    addons: addonDetails
  };
};

const getTitanEmailAddonDetails = (orderDetails, _, addonPricingDetails) => {
  let {
    emailaccounts,
    noofaccounts,
    addons,
    planid,
    productkey
  } = orderDetails;
  const noOfAccounts = emailaccounts || noofaccounts;
  let addonDetails = {};
  let emailPricing =
    (addonPricingDetails?.reseller_pricing ?? addonPricingDetails)?.[productkey]
      ?.plans?.[planid] ?? {};
  addonDetails.addEmail = {
    labelText: "accounts",
    inputType: "number",
    param: 0,
    addClass: {
      input: "titan-input",
      container: "titan-wrapper"
    },
    pricingDetails: emailPricing,
    customerPricingDetails:
      addonPricingDetails?.customer_pricing?.[productkey]?.plans?.[planid] ??
      {},
    minCount: noOfAccounts,
    existingEmailAccounts: noOfAccounts,
    type: "total-accounts"
  };
  return {
    displayText: "",
    addons: addonDetails
  };
};

const getGSuiteAddonDetails = (
  {
    noofaccounts,
    planid,
    productkey,
    expirytimestamp,
    planDetails,
    isAlreadyRenewedForMoreThan1Year,
    isTransferIn,
    tenureOfOrderInMonths
  },
  _,
  addonPricingDetails
) => {
  let emailPricing =
    (addonPricingDetails?.reseller_pricing ?? addonPricingDetails)?.[productkey]
      ?.plans?.[planid] ?? {};
  let addons = {};

  let isDiscountApplicable =
    planDetails?.discount &&
    !isAlreadyRenewedForMoreThan1Year &&
    !isTransferIn &&
    tenureOfOrderInMonths < 12;
  addons.discount_breakdown = {
    costBreakdown: {
      discount: isDiscountApplicable ? planDetails?.discount : null,
      emailPricing,
      expiryTimestamp: expirytimestamp,
      numberOfAccounts: noofaccounts,
      pricingKey: "renew"
    },
    inputType: isDiscountApplicable ? "tooltip" : "none",
    addonDisplayName: "View cost per account",
    type: "discount_breakdown"
  };
  return {
    displayText:
      noofaccounts > 0
        ? `${noofaccounts} account${noofaccounts > 1 ? "s" : ""}`
        : " ",
    addons
  };
};

function getResellerHostingDetails(orderDetails, _, addonPricingDetails) {
  let displayText = "";
  let addons = {};
  let whmcs = manageWHMCS.detailsParser(orderDetails);
  const { planid, productkey = "default" } = orderDetails;
  let { numberOfDedicatedIps } = manageIP.resellerHostingDetailsParser(
    orderDetails
  );
  const isSSLPurchased = numberOfDedicatedIps >= 1;
  const isWHMCSPurchased = whmcs.licenseKey !== undefined;

  let {
    [productkey]: {
      [planid]: { ssl: sslPrice = 0, whmcs: whmcsPrice = 0 } = {}
    } = {}
  } = addonPricingDetails.reseller_pricing ?? addonPricingDetails;
  let {
    [productkey]: {
      [planid]: {
        ssl: customerSSLPrice = 0,
        whmcs: customerWHMCSPrice = 0
      } = {}
    } = {}
  } = addonPricingDetails.customer_pricing ?? addonPricingDetails;

  sslPrice *= numberOfDedicatedIps;
  customerSSLPrice *= numberOfDedicatedIps;

  if (isSSLPurchased) {
    addons.ssl = {
      totalPrice: sslPrice,
      pricingDetails: sslPrice,
      customerPricingDetails: customerSSLPrice,
      price: sslPrice,
      inputType: "unmodifiable_checkbox",
      addonDisplayName: `${numberOfDedicatedIps} Dedicated IP(s)`,
      type: "ssl",
      param: false,
      addonPriceIncluded: true,
      addonEnabled: false
    };
  }

  if (isWHMCSPurchased) {
    addons.whmcs = {
      totalPrice: whmcsPrice,
      pricingDetails: whmcsPrice,
      customerPricingDetails: customerWHMCSPrice,
      price: whmcsPrice,
      inputType: "unmodifiable_checkbox",
      addonDisplayName: "WHMCS ",
      type: "whmcs",
      param: false,
      addonPriceIncluded: true,
      addonEnabled: false
    };
  }

  let { isAddonAvailable, blocks } = blocksParsers.resellerHostingParser(
    orderDetails
  );

  if (!isAddonAvailable) {
    return { displayText, addons };
  }
  let noOfBlocks = blocks.available;
  displayText = noOfBlocks > 0 ? `Includes ${noOfBlocks} cPanel block(s)` : "";

  if (!dangerouslyReadStoreForPaymentStatus()) {
    return { displayText, addons };
  }
  let { cpanel_accounts: freeAccounts } = orderDetails;
  let accountsFromBlock = noOfBlocks * blocks.unitsPerBlock;
  let accountsBeingUsed = orderDetails.miscDetails.accountsUsage;
  let accountsExceedingLimit =
    accountsBeingUsed - (accountsFromBlock + freeAccounts);
  let blocksNeeded = 0;
  if (accountsExceedingLimit > 0) {
    addons.warn_limit = {
      inputType: "errorMessage",
      message:
        "cPanel accounts used is more than the plan\nlimit, add more accounts to continue"
    };
    blocksNeeded = Math.trunc(accountsExceedingLimit / blocks.unitsPerBlock);
    if (accountsExceedingLimit % blocks.unitsPerBlock > 0) {
      blocksNeeded += 1;
    }
  }
  addons.blocks = {
    type: "blocks",
    blocksNeeded,
    buttonValue: blocksNeeded,
    buttonText: "Buy cPanel Add-on",
    pricingDetails:
      orderDetails.miscDetails.addonsPricing ??
      addonPricingDetails?.reseller_pricing[orderDetails.productkey][
        orderDetails?.planid
      ]?.cpanel_blocks,
    customerPricingDetails:
      addonPricingDetails?.customer_pricing[orderDetails.productkey][
        orderDetails?.planid
      ]?.cpanel_blocks,
    inputType: "addBlocks"
  };
  return { displayText, addons };
}

const getBackupDetails = (orderDetails, _, __) => {
  return {
    displayText: `Storage Size: ${orderDetails.totalStorageSizeInGB} GB`,
    addons: {}
  };
};

const getProCloudDetails = (orderDetails, _, addonPricingDetails) => {
  const cmsName = orderDetails?.halorderinfo?.application_installed_during_add;
  const price =
    addonPricingDetails?.reseller_pricing?.[
      orderDetails?.keyToGetProductDetails
    ]?.addons?.[cmsName];

  return {
    displayText: `Includes: ${cmsName}`,
    addons: {
      [cmsName]: {
        totalPrice: price,
        pricingDetails: price,
        price,
        inputType: "message",
        addonDisplayName: cmsName,
        type: cmsName,
        param: false,
        addonEnabled: true,
        addonPriceIncluded: false
      }
    }
  };
};

export const getProductAddonDetails = (
  orderDetails = { keyToGetProductDetails: "noop" },
  resellerRenewCost,
  addonPricingDetails
) => {
  let handler;
  let { keyToGetProductDetails } = orderDetails;
  switch (getSimpleNameFromProductKey(keyToGetProductDetails)) {
    case "domain":
      handler = getDomainAddonDetails;
      break;

    case "bhvpshosting":
    case "vpshosting":
    case "managedserver":
    case "dedicatedserver":
    case "dedibybh":
    case "cloudhosting":
    case "wph":
      handler = getServerHostingAddonDetails;
      break;

    case "sdh":
      handler = getSingleDomainHostingAddonDetails;
      break;

    case "mdh":
      handler = getMultiDomainHostingAddonDetails;
      break;

    case "businessemail":
    case "enterpriseemail":
      handler = getEmailAddonDetails;
      break;

    case "titanmail":
      handler = getTitanEmailAddonDetails;
      break;

    case "gsuite":
      handler = getGSuiteAddonDetails;
      break;

    case "rchosting":
      handler = getResellerHostingDetails;
      break;

    case "blockstorage":
      handler = getBlockStorageDetails;
      break;

    case "backup":
      handler = getBackupDetails;
      break;

    case "procloud":
      handler = getProCloudDetails;
      break;

    default:
      handler = (_, __, ___) => ({ displayText: "", addons: {} });
      break;
  }
  return handler(orderDetails, resellerRenewCost, addonPricingDetails);
};
