import {
  AddEmailAccount,
  AddEmailAccountHover,
  AddPrivacy,
  AddPrivacyHover,
  BuyEmailAccounts,
  BuyEmailAccountsHover,
  EditContactDetails,
  EditContactDetailsHover,
  EditNameServers,
  EditNameServersHover,
  GoToAdminPanel,
  GoToAdminPanelHover,
  GoToCPanel,
  GoToCPanelHover,
  GoToWhm,
  GoToWhmHover,
  GoToWordPress,
  GoToWordPressHover,
  ManageAddons,
  ManageAddonsHover,
  ManagePanel,
  ManagePanelHover,
  ManageVolume,
  ManageVolumeHover,
  PendingAction,
  Plesk,
  PleskHover,
  Renew,
  RenewHover,
  SitelockThreatDashboard,
  SSLIssueShortcut,
  SSLIssueShortcutHover,
  SSLViewShortcut,
  SSLViewShortcutHover,
  ViewNameServers,
  ViewNameServersHover
} from "assets";
import { isSoftLaunchProduct } from "constants/index";

const dedicatedServerShortcuts = {
  server_panel: {
    external: true,
    image: GoToAdminPanel,
    hover_image: GoToAdminPanelHover,
    type: "dedipanel",
    sofiKey: "TOOLTIP_SHORTCUTS_DEDI",
    tooltipText: "Go To Server Management Panel"
  },
  manage_rdp: {
    image: ManagePanel,
    hover_image: ManagePanelHover,
    type: "manage",
    sofiKey: "TOOLTIP_SHORTCUTS_MANAGE_RDP",
    tooltipText: "View RDP access details"
  }
};

const domainShortcuts = {
  edit_contact: {
    image: EditContactDetails,
    hover_image: EditContactDetailsHover,
    type: "contact",
    sofiKey: "TOOLTIP_SHORTCUTS_CONTACT",
    tooltipText: "Edit Contact"
  },
  edit_nameservers: {
    image: EditNameServers,
    hover_image: EditNameServersHover,
    type: "nameServers",
    sofiKey: "TOOLTIP_SHORTCUTS_NS",
    tooltipText: "Name Server Details"
  },
  pending_domain: {
    image: PendingAction,
    hover_image: PendingAction,
    type: "pending_domain",
    sofiKey: "TOOLTIP_SHORTCUTS_PENDING_DOMAIN",
    tooltipText: "The Registrant's email needs to be verified"
  },
  pending_gsuite: {
    image: PendingAction,
    hover_image: PendingAction,
    type: "pendingSetup",
    sofiKey: "INACTIVE_ORDER_SETUP_ORDER",
    tooltipText: "The order is inactive. Please setup the order to manage it."
  },
  privacyprotection: {
    image: AddPrivacy,
    hover_image: AddPrivacyHover,
    type: "privacy",
    sofiKey: "TOOLTIP_SHORTCUTS_PRIVACY",
    tooltipText: "Buy Privacy Protection"
  }
};

const externalPanelShortcuts = {
  cpanel: {
    external: true,
    image: GoToCPanel,
    hover_image: GoToCPanelHover,
    type: "cpanel",
    sofiKey: "TOOLTIP_SHORTCUTS_CPANEL",
    tooltipText: "Go To cPanel"
  },
  email_account: {
    image: AddEmailAccount,
    hover_image: AddEmailAccountHover,
    type: "createEmail",
    sofiKey: "TOOLTIP_SHORTCUTS_CREATE_EMAIL",
    tooltipText: "Create an Email ID"
  },
  buy_email_accounts: {
    image: BuyEmailAccounts,
    hover_image: BuyEmailAccountsHover,
    type: "activeEmailAccount",
    sofiKey: "TOOLTIP_SHORTCUTS_BUY_MORE_EMAIL",
    tooltipText: "Buy more email accounts"
  },
  manage_email: {
    external: true,
    image: GoToAdminPanel,
    hover_image: GoToAdminPanelHover,
    type: "manage_email",
    sofiKey: "TOOLTIP_SHORTCUTS_MANAGE_EMAIL",
    tooltipText: "Go to Email Management Panel"
  },
  plesk: {
    external: true,
    image: Plesk,
    hover_image: PleskHover,
    type: "plesk",
    sofiKey: "TOOLTIP_SHORTCUTS_PLESK",
    tooltipText: "Go To Plesk"
  },
  whm: {
    external: true,
    image: GoToWhm,
    hover_image: GoToWhmHover,
    type: "whm",
    sofiKey: "TOOLTIP_SHORTCUTS_WHM",
    tooltipText: "Go To WHM"
  },
  wordpress: {
    external: true,
    image: GoToWordPress,
    hover_image: GoToWordPressHover,
    type: "adminpanel",
    sofiKey: "TOOLTIP_SHORTCUTS_WORDPRESS",
    tooltipText: "Manage Wordpress hosting"
  },
  sitelock_threat: {
    external: true,
    image: SitelockThreatDashboard,
    hover_image: SitelockThreatDashboard,
    type: "sitelock",
    sofiKey: "TOOLTIP_SHORTCUTS_SITELOCK",
    tooltipText: "Threats found"
  },
  sitelock_panel: {
    external: true,
    image: GoToAdminPanel,
    hover_image: GoToAdminPanelHover,
    type: "sitelock",
    sofiKey: "TOOLTIP_SHORTCUTS_SITELOCK_ADMIN",
    tooltipText: "Go To Admin Panel"
  },
  website_builder: {
    external: true,
    image: GoToAdminPanel,
    hover_image: GoToAdminPanelHover,
    type: "sitelock",
    sofiKey: "TOOLTIP_SHORTCUTS_SITELOCK_EDITOR",
    tooltipText: "Go To Website Editor"
  },
  procloud_panel: {
    external: true,
    image: ViewNameServers,
    hover_image: ViewNameServersHover,
    type: "procloud",
    sofiKey: "TOOLTIP_PROCLOUD_PANEL",
    tooltipText: "Go To Pro Cloud Panel"
  }
};

const hostingShortcuts = {
  manage_addons: {
    image: ManageAddons,
    hover_image: ManageAddonsHover,
    type: "addons",
    sofiKey: "TOOLTIP_SHORTCUTS_ADDONS",
    tooltipText: "Manage Addons"
  },
  manage_panel: {
    image: ManagePanel,
    hover_image: ManagePanelHover,
    type: "manage",
    sofiKey: "TOOLTIP_SHORTCUTS_MANAGE_ADMIN",
    tooltipText: "View/Edit Admin Details"
  },
  view_nameservers: {
    image: ViewNameServers,
    hover_image: ViewNameServersHover,
    type: "nameServers",
    sofiKey: "TOOLTIP_SHORTCUTS_MANAGE_NS",
    tooltipText: "View Name Server details"
  },
  manage_volumes: {
    image: ManageVolume,
    hover_image: ManageVolumeHover,
    type: "manageVolumes",
    sofiKey: "TOOLTIP_SHORTCUTS_MANAGE_VOLUMES",
    tooltipText: "Manage Volumes"
  }
};

const sslShortcuts = {
  issue_certificate: {
    image: SSLIssueShortcut,
    isInactive: false,
    hover_image: SSLIssueShortcutHover,
    type: "manage",
    sofiKey: "TOOLTIP_SHORTCUTS_MANAGE_CERT",
    tooltipText: "Issue certificate"
  },
  reissue: {
    image: SSLIssueShortcut,
    hover_image: SSLIssueShortcutHover,
    type: "manage",
    sofiKey: "TOOLTIP_SHORTCUTS_MANAGE_REISSUE",
    tooltipText: "Reissue certificate"
  },
  resendVerificationEmail: {
    image: ManagePanel,
    isInactive: false,
    hover_image: ManagePanelHover,
    type: "manage",
    sofiKey: "TOOLTIP_SHORTCUTS_REVERIFY",
    tooltipText: "Resend Verification email"
  },
  view_certificate: {
    image: SSLViewShortcut,
    hover_image: SSLViewShortcutHover,
    type: "viewcert",
    sofiKey: "TOOLTIP_SHORTCUTS_VIEW_CERT",
    tooltipText: "View certificate details"
  }
};

const commonShortcuts = {
  renew: {
    image: Renew,
    hover_image: RenewHover,
    type: "renew",
    sofiKey: "TOOLTIP_SHORTCUTS_RENEW",
    tooltipText: "Renew"
  }
};

export const shortcuts = {
  ...commonShortcuts,
  ...dedicatedServerShortcuts,
  ...domainShortcuts,
  ...externalPanelShortcuts,
  ...hostingShortcuts,
  ...sslShortcuts
};

export function getStaticShortcuts({ productKey }, isOnHoldOrder = null, role) {
  let newActionList = [];
  if (isSoftLaunchProduct(productKey)) {
    return newActionList;
  }
  if (
    productKey.startsWith("singledomainhostinglinux") ||
    productKey.startsWith("multidomainhostinglinux") ||
    productKey === "multidomainhosting"
  ) {
    newActionList = [shortcuts.cpanel, shortcuts.view_nameservers];
  } else if (
    productKey.startsWith("singledomainhostingwindows") ||
    productKey.startsWith("multidomainhostingwindows") ||
    productKey.startsWith("multidomainwindows")
  ) {
    newActionList = [shortcuts.plesk, shortcuts.view_nameservers];
  } else if (productKey.startsWith("resellerhosting")) {
    newActionList = [
      shortcuts.whm,
      shortcuts.cpanel,
      shortcuts.view_nameservers
    ];
  } else if (productKey.startsWith("resellerwindows")) {
    newActionList = [
      shortcuts.plesk,
      shortcuts.view_nameservers,
      shortcuts.manage_panel
    ];
  } else if (productKey.startsWith("cloudsiteslinux")) {
    newActionList = [
      shortcuts.cpanel,
      shortcuts.view_nameservers,
      shortcuts.manage_panel
    ];
  } else if (productKey.startsWith("procloud")) {
    newActionList = [shortcuts.procloud_panel];
  } else if (productKey.startsWith("bluehostvps")) {
    newActionList = [shortcuts.manage_panel, shortcuts.manage_addons];
  } else if (productKey.startsWith("wordpresshosting")) {
    newActionList = [shortcuts.manage_addons, shortcuts.wordpress];
  } else if (productKey.startsWith("codeguard")) {
    newActionList = [
      {
        ...shortcuts.server_panel,
        sofiKey: "TOOLTIP_SHORTCUTS_CODEGUARD",
        tooltipText: "Go to CodeGuard Dashboard"
      }
    ];
  } else if (productKey.startsWith("virtualserverlinuxin")) {
    newActionList = [shortcuts.manage_addons, shortcuts.manage_panel];
  } else if (
    productKey.startsWith("dedicatedserverlinux") ||
    productKey.startsWith("managedserverlinux") ||
    productKey.startsWith("hostgatordedicated") ||
    productKey === "bluehostdedicatedserverlinuxus"
  ) {
    newActionList = [shortcuts.manage_addons, shortcuts.server_panel];
  } else if (productKey.startsWith("dedicatedserver")) {
    newActionList = [shortcuts.server_panel, shortcuts.manage_rdp];
  } else if (productKey.startsWith("hostgatorhostingshared")) {
    newActionList = [shortcuts.cpanel, shortcuts.view_nameservers];
  } else if (productKey.startsWith("hosting")) {
    newActionList = [shortcuts.website_builder];
  }
  if (role !== "reseller" && !isOnHoldOrder) {
    newActionList.push(shortcuts.renew);
  }
  return newActionList;
}
