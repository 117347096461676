import { Cheque, CreditCard, Debitcard, Netbanking, Upi, Wallet } from "assets";

export const PaymentGateways = [
  {
    name: "Netbanking",
    text:
      "Upon selecting the 'Netbanking' option, you'll be sent to the Netbanking site to complete the payment and directed back when finished.",
    icon: Netbanking,
    type: "gateway",
    paymenttype: "netbanking"
  },
  {
    name: "UPI",
    text:
      "Pay via the fastest growing and most trusted payment method in India. You can pay for your purchases instantly with zero downtime.",
    icon: Upi,
    type: "gateway",
    paymenttype: "upi"
  },
  {
    name: "Debit Card",
    text:
      "We accept MasterCard Debit Cards, Maestro Debit Cards, RuPay, State Bank of India, and Visa Debit Cards for your debit card payment.",
    icon: Debitcard,
    type: "gateway",
    paymenttype: "debit"
  },
  {
    name: "Credit Card",
    text:
      "We accept MasterCard, Visa, RuPay, American Express and Diner's Club Credit Cards.",
    icon: CreditCard,
    type: "gateway",
    paymenttype: "credit"
  },
  {
    name: "Wallets",
    text:
      "Pay conveniently using any online wallet like Paytm, JioMoney, Mobikwik, OlaMoney, Freecharge, etc.",
    icon: Wallet,
    type: "gateway",
    paymenttype: "wallets"
  },
  {
    name: "Cheque",
    text:
      "We'll provide you with our bank details where you can send your cheque. Your purchase will be settled once the funds are deposited into our account. ",
    icon: Cheque,
    paymenttype: "cheque",
    type: "offline"
  }
];
