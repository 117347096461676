import { put, takeEvery } from "redux-saga/effects";
import { services } from "./services";
import { actions, actionTypes } from "./ducks";

export default [
  takeEvery(actionTypes.DELETE_EMAIL_ACCOUNTS, deleteEmailAccounts)
];

export function* deleteEmailAccounts(action) {
  try {
    yield services.deleteEmailAccounts(action.payload);
    yield put(actions.deleteEmailAccountsSuccess(action.payload));
  } catch (error) {
    yield put(actions.deleteEmailAccountsFailure(error, action.payload));
  }
}
