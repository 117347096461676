import { useEffect } from "react";
import { withRouter } from "react-router";

function ScrollToTop({ children, location }) {
  useEffect(() => window.scroll(0, 0), [location.pathname]);
  return children;
}

export default withRouter(ScrollToTop);

// Documentation:
// https://reacttraining.com/react-router/web/guides/scroll-restoration
