import { SimpleApi } from "common/api-client";

export class SiteDetailsManager {
  /** @type {import("../../webpro").CompatSettings} */
  #compatSettings = null;
  /** @type {import("../../webpro").ClientDetails} */
  #client = null;
  /** @type {string} */
  #gateway = null;
  /** @type {import("../../webpro").SiteDetails} */
  #site = null;
  /** @type {import("../../webpro").SiteUrlProvider} */
  #siteUrlProvider = null;

  constructor(compatSettings, gateway, siteUrlProvider) {
    this.#compatSettings = compatSettings;
    this.#gateway = gateway;
    this.#siteUrlProvider = siteUrlProvider;
  }
  static register() {
    /** @type {import("@faizaanceg/syringe").Injection<"siteManager">} */
    const SiteManagerFactory = {
      name: "siteManager",
      injectFn: ({ compatSettings, siteUrlProvider }) =>
        new SiteDetailsManager(
          compatSettings,
          compatSettings.needsCompat ? `${compatSettings.layer}/` : "/api/",
          siteUrlProvider
        ),
      uses: [{ name: "compatSettings" }, { name: "siteUrlProvider" }]
    };
    return SiteManagerFactory;
  }

  async clientDetails() {
    if (this.#client === null) {
      this.#client = await SimpleApi.get(`${this.#gateway}user/details`);
    }
    return this.#client;
  }
  async details() {
    if (this.#site === null) {
      let siteUrl = this.#siteUrlProvider();
      let url = "";
      let urlKey = "";
      if (this.#compatSettings.needsCompat) {
        url = `user/branded-url/details`;
        urlKey = "branded-url";
      } else {
        url = `webpro/customer/resellerdetails`;
        urlKey = "url";
      }
      let qs = {};
      if (siteUrl) {
        qs[urlKey] = siteUrl;
      }
      this.#site = await SimpleApi.get(`${this.#gateway}${url}`, qs);
    }
    return this.#site;
  }
}
