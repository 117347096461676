// Each object contains an Obox Message and a Webpro message
// Keys would be an array of paths that would have to be modified
export default [
  {
    oboxMessage: "Internal Server Error",
    webproMessage:
      "There was some error while performing the action. Please try again in a few minutes",
    keys: [["error", "message"]]
  },
  {
    oboxMessage: "There is already an action pending on this Order.",
    webproMessage:
      "There is already an action pending on this Order. Please try again in a few minutes",
    keys: [["error", "message"]]
  },
  {
    oboxMessage: "{newipaddr=Invalid IP Address Format}",
    webproMessage: "Invalid IP address",
    keys: [["error", "message"]]
  },
  {
    oboxMessage:
      "A Child Name Servers can only be registered under your Domain Name. For instance, a Child Name Server ns2.xyz.com maybe registered only under xyz.com",
    webproMessage:
      "Child Name Servers can only be registered under your Domain Name. For instance, a Child Name Server ns2.xyz.com maybe registered only under xyz.com",
    keys: [["error", "message"]]
  },
  {
    oboxMessage: "Unable to send Mail : finished trying all SMTP hosts.",
    webproMessage: "Unable to send mail. Please try again in sometime.",
    keys: [["error", "message"]]
  }
];
