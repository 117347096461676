import { parseStringAsBoolean, StringAsBoolean } from "common/parse-string";

export const PremiumSupportedProducts = [
  "centralnicbrcom",
  "centralniccncom",
  "centralniccocom",
  "centralnicgbcom",
  "centralnicgbnet",
  "centralnickrcom",
  "centralnicpremium",
  "centralnicstandard",
  "centralnicukcom",
  "centralnicuscom",
  "centralnicuycom",
  "centralniczacom",
  "demodoteap",
  "dombiz",
  "donutsgroup1",
  "donutsgroup2",
  "donutsgroup3",
  "dotacademy",
  "dotaccountant",
  "dotaccountants",
  "dotactor",
  "dotadult",
  "dotafrica",
  "dotagency",
  "dotairforce",
  "dotapp",
  "dotarabicsite",
  "dotarchi",
  "dotarmy",
  "dotart",
  "dotassociates",
  "dotattorney",
  "dotau",
  "dotauction",
  "dotaudio",
  "dotauto",
  "dotautos",
  "dotband",
  "dotbar",
  "dotbargains",
  "dotbayern",
  "dotbeer",
  "dotbest",
  "dotbet",
  "dotbid",
  "dotbike",
  "dotbio",
  "dotblack",
  "dotblackfriday",
  "dotblog",
  "dotblue",
  "dotboats",
  "dotboutique",
  "dotbuild",
  "dotbusiness",
  "dotbuzz",
  "dotcafe",
  "dotcam",
  "dotcamera",
  "dotcamp",
  "dotcapetown",
  "dotcar",
  "dotcareer",
  "dotcars",
  "dotcasa",
  "dotcash",
  "dotcasino",
  "dotcenter",
  "dotcheap",
  "dotchineseonline",
  "dotchineseorg",
  "dotchinesewebsite",
  "dotchristmas",
  "dotcity",
  "dotcleaning",
  "dotclick",
  "dotcloud",
  "dotclub",
  "dotco",
  "dotcoffee",
  "dotcollege",
  "dotcommunity",
  "dotcompany",
  "dotconsulting",
  "dotcooking",
  "dotcool",
  "dotcountry",
  "dotcoupons",
  "dotcourses",
  "dotcredit",
  "dotcreditcard",
  "dotcricket",
  "dotcyrillicorg",
  "dotdance",
  "dotdate",
  "dotdeals",
  "dotdegree",
  "dotdemocrat",
  "dotdentist",
  "dotdesi",
  "dotdesign",
  "dotdev",
  "dotdiet",
  "dotdigital",
  "dotdirectory",
  "dotdoctor",
  "dotdog",
  "dotdownload",
  "dotdurban",
  "doteco",
  "dotemail",
  "dotenergy",
  "dotengineer",
  "dotexpert",
  "dotexpress",
  "dotfaith",
  "dotfamily",
  "dotfans",
  "dotfashion",
  "dotfeedback",
  "dotfishing",
  "dotfit",
  "dotfitness",
  "dotflowers",
  "dotfm",
  "dotforsale",
  "dotfoundation",
  "dotfun",
  "dotfutbol",
  "dotgame",
  "dotgames",
  "dotgarden",
  "dotgdn",
  "dotgift",
  "dotgives",
  "dotglass",
  "dotglobal",
  "dotgmbh",
  "dotgold",
  "dotgreen",
  "dotgroup",
  "dotguitars",
  "dotguru",
  "dothaus",
  "dothealth",
  "dothelp",
  "dothiphop",
  "dothomes",
  "dothorse",
  "dothospital",
  "dothost",
  "dothosting",
  "dothow",
  "doticu",
  "dotimmobilien",
  "dotink",
  "dotinternational",
  "dotinvestments",
  "dotio",
  "dotirish",
  "dotist",
  "dotistanbul",
  "dotjetzt",
  "dotjobs",
  "dotjoburg",
  "dotjuegos",
  "dotkaufen",
  "dotkim",
  "dotkitchen",
  "dotkiwi",
  "dotlaw",
  "dotlawyer",
  "dotlgbt",
  "dotlife",
  "dotlink",
  "dotlive",
  "dotloan",
  "dotloans",
  "dotlol",
  "dotlondon",
  "dotlotto",
  "dotlove",
  "dotltd",
  "dotltda",
  "dotluxury",
  "dotmarket",
  "dotme",
  "dotmedia",
  "dotmen",
  "dotmenu",
  "dotmiami",
  "dotmobile",
  "dotmoda",
  "dotmom",
  "dotmortgage",
  "dotmotorcycles",
  "dotnavy",
  "dotnetwork",
  "dotnews",
  "dotngo",
  "dotninja",
  "dotnyc",
  "dotone",
  "dotonl",
  "dotonline",
  "dotooo",
  "dotpage",
  "dotparty",
  "dotpet",
  "dotphoto",
  "dotphotography",
  "dotphysio",
  "dotpics",
  "dotpictures",
  "dotpink",
  "dotplumbing",
  "dotplus",
  "dotpoker",
  "dotporn",
  "dotpress",
  "dotproductions",
  "dotpromo",
  "dotproperty",
  "dotpub",
  "dotpw",
  "dotracing",
  "dotred",
  "dotrehab",
  "dotreisen",
  "dotrent",
  "dotrepublican",
  "dotrest",
  "dotreview",
  "dotreviews",
  "dotrich",
  "dotrip",
  "dotrocks",
  "dotrodeo",
  "dotrun",
  "dotsale",
  "dotsangathan",
  "dotscience",
  "dotscot",
  "dotservices",
  "dotsex",
  "dotsexy",
  "dotshabaka",
  "dotshiksha",
  "dotshoes",
  "dotshop",
  "dotshopping",
  "dotsite",
  "dotski",
  "dotsocial",
  "dotsoftware",
  "dotsolar",
  "dotsolutions",
  "dotsoy",
  "dotspace",
  "dotsrl",
  "dotstore",
  "dotstream",
  "dotstudio",
  "dotstudy",
  "dotsurf",
  "dotsystems",
  "dottattoo",
  "dottech",
  "dottechnology",
  "dottel",
  "dottips",
  "dottires",
  "dottoday",
  "dottools",
  "dottop",
  "dottoys",
  "dottrade",
  "dottube",
  "dottv",
  "dotuno",
  "dotvegas",
  "dotvet",
  "dotvideo",
  "dotvin",
  "dotvip",
  "dotvodka",
  "dotvote",
  "dotvoto",
  "dotwebcam",
  "dotwebsite",
  "dotwedding",
  "dotwien",
  "dotwiki",
  "dotwin",
  "dotwork",
  "dotworks",
  "dotworld",
  "dotws",
  "dotxxx",
  "dotxyz",
  "dotyachts",
  "dotyoga",
  "dotzone",
  "landrushdotboats",
  "landrushdotgreen",
  "landrushdotspace",
  "landrushdotyachts",
  "pregadonutsgroup1",
  "pregadonutsgroup2",
  "pregadonutsgroup3",
  "sunriseafilias",
  "sunrisedominionenterprise",
  "sunrisedotautos",
  "sunrisedotboats",
  "sunrisedotgreen",
  "sunrisedothomes",
  "sunrisedotkaufen",
  "sunrisedotmotorcycles"
];

type PremiumCheck = {
  premium: StringAsBoolean;
  resellerCostPriceHash?: { renew: string };
  costHash?: { renew: string };
};

export function calculatePremiumRenewPrice(
  premiumCheck: PremiumCheck,
  role: Role
): number {
  const premiumRenewPrice =
    role === "reseller"
      ? premiumCheck?.resellerCostPriceHash?.renew
      : premiumCheck?.costHash?.renew;
  return parseStringAsBoolean(premiumCheck.premium)
    ? Number(premiumRenewPrice)
    : null;
}
