import WPBadge from "common/badge";
import { colors, fonts } from "common/styles";
import { styled } from "linaria/react";
import React from "react";

let RadioButtonStyle = styled.span`
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px ${colors.gray.gray600} solid;
  background-color: ${colors.white.regular};
  border-radius: 100%;
  &:after {
    content: "";
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    background: ${colors.gray.gray600};
    transform: translateX(-50%) translateY(-50%);
    border-radius: 100%;
  }
`;

let RadioButtonName = styled.span`
  font: 400 13px/1.5 ${fonts.OpenSans};
  color: ${colors.black.regular};
  margin-left: 10px;
`;

let RadioButton = styled.div`
  position: relative;
  input[type="radio"] {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    &:disabled + ${RadioButtonStyle} {
      cursor: not-allowed;
    }
    &:checked + ${RadioButtonStyle} {
      &[data-color="green"] {
        border: 1px ${colors.green.regular} solid;
        &:after {
          display: block;
          background: ${colors.green.regular};
        }
      }
      &[data-color="red"] {
        border: 1px ${colors.red.regular} solid;
        &:after {
          display: block;
          background: ${colors.red.regular};
        }
      }
      &[data-color="blue"] {
        border: 1px ${colors.blue.regular} solid;
        &:after {
          display: block;
          background: ${colors.blue.regular};
        }
      }
    }
  }
  label {
    margin: 0;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    &[data-display-block="true"] {
      display: block;
      ${RadioButtonStyle} {
        top: 2px;
      }
    }
  }
`;

type WPRadioButtonProps = {
  labelName: string;
  variantColor: "green" | "red" | "blue";
  appendReasonElement?: React.ReactNode;
  labelStyles?: any;
  badge?: string;
};

export const WPRadioButton: React.FunctionComponent<WPRadioButtonProps> = ({
  labelName,
  children,
  variantColor,
  labelStyles,
  appendReasonElement,
  badge = null
}) => {
  return (
    <RadioButton data-color={variantColor}>
      <label data-display-block={Boolean(appendReasonElement)}>
        {children}
        <RadioButtonStyle data-color={variantColor} />
        <RadioButtonName style={labelStyles}>
          {labelName}
          {badge ? (
            <WPBadge
              text={badge}
              styles={{
                top: "-3px",
                right: "-7px",
                position: "relative",
                marginLeft: "10px",
                marginRight: "10px"
              }}
            />
          ) : null}{" "}
        </RadioButtonName>
        {appendReasonElement}
      </label>
    </RadioButton>
  );
};
