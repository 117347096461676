import { isFailure, isSuccess } from "common/api-status/utils";

export const actionTypes = {
  GET_EMAIL_ADDRESSES: "[Email Addresses] Get",
  DELETE_EMAIL_ADDRESS: "[Email Addresses] Delete",
  CREATE_EMAIL_ADDRESS: "[Email Addresses] Create",
  RESET_EMAIL_ADDRESS_PASSWORD: "[Email Addresses] Reset Password",
  SHOW_DELETE_EMAIL: "[Email Addresses] Show Delete",
  SHOW_RESET_EMAIL: "[Email Addresses] Show Reset",
  SHOW_CREATE_EMAIL_ADDRESS: "[Email Addresses] Show Create",
  SHOW_CONFIGURE_EMAIL_ADDRESS: "[Email Addresses] Show Configure",
  HIDE_CREATE_MODAL: "[Email Addresses] Hide Create Modal"
};

export const actions = {
  showConfigureModal: (meta, modalType) => ({
    type: actionTypes.SHOW_CONFIGURE_EMAIL_ADDRESS,
    ...meta,
    modalType
  }),
  showDeleteEmailModal: (meta, emailAddress) => ({
    ...meta,
    deleteItem: emailAddress,
    type: actionTypes.SHOW_DELETE_EMAIL
  }),
  showResetEmailAddressPasswordModal: (meta, emailAddress) => ({
    ...meta,
    emailAddress,
    type: actionTypes.SHOW_RESET_EMAIL
  }),
  showCreateEmailAddressModal: meta => ({
    type: actionTypes.SHOW_CREATE_EMAIL_ADDRESS,
    ...meta
  }),
  getEmailAddresses: meta => ({
    type: actionTypes.GET_EMAIL_ADDRESSES,
    meta
    // TODO: Add GA events
  }),
  getOverQuotaEmailAddresses: meta => ({
    type: actionTypes.GET_EMAIL_ADDRESSES,
    meta
    // TODO: Add GA events
  }),
  createEmailAddress: (meta, parameters) => ({
    type: actionTypes.CREATE_EMAIL_ADDRESS,
    meta,
    parameters
  }),
  createEmailAddressSuccess: (meta, accountDetails) => ({
    type: isSuccess(actionTypes.CREATE_EMAIL_ADDRESS),
    ...meta,
    accountDetails
  }),
  createEmailAddressFailure: (meta, error) => ({
    type: isFailure(actionTypes.CREATE_EMAIL_ADDRESS),
    meta,
    error
  }),
  deleteEmailAddress: (meta, { addonName }) => ({
    type: actionTypes.DELETE_EMAIL_ADDRESS,
    meta,
    emailAddress: addonName
  }),
  deleteEmailAddressSuccess: (meta, emailAddress) => ({
    type: isSuccess(actionTypes.DELETE_EMAIL_ADDRESS),
    ...meta,
    defaultMessage: `Deletion of ${emailAddress} Success`
  }),
  deleteEmailAddressFailure: (meta, emailAddress) => ({
    type: isFailure(actionTypes.DELETE_EMAIL_ADDRESS),
    ...meta,
    defaultMessage: `Deletion of ${emailAddress} Failed`
  }),
  resetEmailAddressPassword: (meta, emailAddress) => ({
    type: actionTypes.RESET_EMAIL_ADDRESS_PASSWORD,
    meta,
    emailAddress
  }),
  hideModal: orderid => ({
    type: isSuccess(actionTypes.HIDE_CREATE_MODAL),
    orderid
  })
};

export const initialState = {
  emailAddresses: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case isSuccess(actionTypes.GET_EMAIL_ADDRESSES): {
      let {
        meta: { orderid: parent },
        effectResponse
      } = action;
      return {
        ...state,
        [parent]: { ...state[parent], emailAddresses: effectResponse }
      };
    }

    default:
      return state;
  }
};
