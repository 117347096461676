import React from "react";

export class HtmlParserRenderProps extends React.Component {
  state = {
    Parser: _ => _
  };

  componentDidMount() {
    import(/* webpackChunkName: "react-html-parser" */ "react-html-parser")
      .then(pkg =>
        this.setState({
          Parser: pkg.default
        })
      )
      .catch(console.error);
  }

  render() {
    return this.props.children(this.state.Parser);
  }
}

export default function HtmlParser(props) {
  return (
    <HtmlParserRenderProps>
      {parser => parser(props.children)}
    </HtmlParserRenderProps>
  );
}
