export const isAmountInvalid = (
  v = { amountToWithdraw: 0, walletBalance: 0 }
) => v.amountToWithdraw <= 0 || v.walletBalance < v.amountToWithdraw;

let isDecimal = value => value.toString().split(".")[1]?.length > 2;

export const errorSheet = {
  fundsToBeAdded: {
    isWrong: values => {
      let { fundsToBeAdded = "" } = values;
      if (fundsToBeAdded.trim() === "") {
        return false;
      }
      fundsToBeAdded = +fundsToBeAdded;
      if (
        isNaN(fundsToBeAdded) ||
        fundsToBeAdded <= 0 ||
        isDecimal(fundsToBeAdded)
      ) {
        return true;
      }
    },
    message: v => "Please add a valid amount upto to 2 decimal places"
  }
};
