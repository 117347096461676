export const actionTypes = {
  SHOW_ALL_ORDERS: "[Side bar] Show all orders"
};

const initialState = {
  showOrdersIcon: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_ALL_ORDERS:
      return { ...state, showOrdersIcon: true };
    default:
      return state;
  }
};

export const actions = {
  showAllOrders: () => ({ type: actionTypes.SHOW_ALL_ORDERS })
};
