import api from "api";
import { CustomerManagementSdk } from "common/sdk/customer-management";
import { TaxSdk } from "common/sdk/tax";
import constants from "constants/endpoints";
import { Syringe } from "@faizaanceg/syringe";

const sessionManager = Syringe.inject("sessionManager");

export default {
  modifyDetails: async profileInfo => {
    const { details } = await sessionManager.create();
    const { role } = details;
    let updatedProfileInfo = helpers.modifyProfileInfo(profileInfo);
    if (String(profileInfo.twofactorgoogleauth_enabled) === "true") {
      updatedProfileInfo.medium = "gauth";
    }
    const updateProfileEndpoint =
      profileInfo.otp ||
      String(profileInfo.twofactorgoogleauth_enabled) === "true"
        ? `${constants.webpro}resellers/modify-details-with-otp`
        : `${constants.webpro}resellers/modify-details`;

    if (
      updatedProfileInfo.indiangstid === "" &&
      process.env.FEATURE_ENDPOINT === "bigrock"
    ) {
      await TaxSdk.saveRegionBasedTaxId({
        userID: profileInfo.customerid,
        taxId: "",
        taxType: "IndianGST",
        country: updatedProfileInfo.country,
        state: updatedProfileInfo.state,
        role: "customer"
      });
    }
    return role === "reseller"
      ? api.postBody(updateProfileEndpoint, updatedProfileInfo)
      : CustomerManagementSdk.update(
          profileInfo.customerid,
          updatedProfileInfo
        );
  }
};

const helpers = {
  modifyProfileInfo(profileInfo) {
    const {
      address1,
      address2,
      address3,
      customerid,
      faxno,
      faxnocc,
      langpref,
      mobileno: mobile,
      mobilenocc,
      telno: phone,
      telnocc,
      zip: zipcode,
      ...otherProfileInfo
    } = profileInfo;
    let finalProfileInfo = otherProfileInfo;
    if (address2) {
      finalProfileInfo.address_line_2 = address2;
    }
    if (address3) {
      finalProfileInfo.address_line_3 = address3;
    }
    if (mobile) {
      finalProfileInfo.mobile = mobile;
    }
    if (mobilenocc) {
      finalProfileInfo.mobile_cc = mobilenocc;
    }
    if (customerid) {
      finalProfileInfo.customer_id = customerid;
    }
    return {
      address_line_1: address1,
      lang_pref: langpref,
      zipcode,
      phone,
      phone_cc: telnocc,
      fax: faxno,
      fax_cc: faxnocc,
      ...finalProfileInfo
    };
  }
};
