import { DefaultCredentialsManager } from "common/credentials";
import { createApiClient } from "http-with-fetch";

// This is list of error messages that Obox returns with a status code 200
// So we would have to look at the response body to find out if an action hasn't gone through successfully
const OboxErrorMessages = [
  "error",
  "failed",
  "There is already an action pending on this Order.",
  "failure"
];

const helpers = {
  checkForError: async (request: Request, response: Response) => {
    let error = new Error(response.statusText);
    // @ts-ignore
    error.status = response.status;
    // @ts-ignore
    error.response = response;
    if (response.status === 500) {
      let apiResponse = await response.json().catch(() => null);
      error.message = apiResponse.message;
      // @ts-ignore
      error.response = new Response(JSON.stringify(apiResponse));
      throw error;
    }
    if (process.env.BUILD_ENV !== "production") {
      console.error("API Call Failed: ", error.message, request);
    }
    throw error;
  },
  parseResponse: async (response: Response): Promise<Record<string, any>> => {
    let rawResponse = await response.text();
    if (response.status === 204 || rawResponse === "") {
      return {};
    }
    try {
      return JSON.parse(rawResponse);
    } catch (err) {
      throw rawResponse;
    }
  },
  checkIfOboxError: response => {
    const {
      status = "",
      msg = "",
      code,
      actionstatusdesc = msg,
      message = actionstatusdesc,
      reason = message,
      error = reason,
      response: errorBody = {
        status,
        message: error
      }
    } = response;

    if (
      (((typeof errorBody.status === "string" &&
        OboxErrorMessages.includes(errorBody.status.toLowerCase())) ||
        OboxErrorMessages.includes(errorBody.message) ||
        status > 400 ||
        response.error) &&
        errorBody?.status?.toLowerCase() !== "success") ||
      !!code
    ) {
      let apiFailure = new Error(errorBody.message);
      // @ts-ignore
      apiFailure.response = new Response(JSON.stringify(response));
      throw apiFailure;
    }
  }
};

export const OBVisitor = {
  async ok<T>(response: Response) {
    let data: T | Record<string, any>;
    try {
      data = await helpers.parseResponse(response);
    } catch (rawResponse) {
      return rawResponse as T;
    }
    helpers.checkIfOboxError(data);
    return data as T;
  },
  fail: helpers.checkForError
};

/**
 * `SimpleApi` is used when the API call can be made without any session attached to it.
 */
export const SimpleApi = createApiClient(
  {
    credentials: new (class SimpleManager extends DefaultCredentialsManager {
      constructor() {
        super(null);
      }
      async isValid() {
        return true;
      }
    })(),
    visitor: OBVisitor
  },
  false
);
