export default (event, attributes) => {
  if (window) {
    if (process.env.FEATURE_ENDPOINT === "reseller") {
      attributes.resellerid = window.resellerid;
    } else {
      attributes.customerid = window.customerid;
    }

    attributes.isOldReseller = window.isOldReseller;
    if (typeof window !== "undefined") {
      window.dataLayer?.push({ event, attributes });
    }
  }
};

export const gtmEcommercePush = (event, ecommerce) => {
  if (window) {
    if (process.env.FEATURE_ENDPOINT === "reseller") {
      ecommerce.resellerid = window.resellerid;
    } else {
      ecommerce.customerid = window.customerid;
    }

    if (typeof window !== "undefined") {
      window.dataLayer?.push({ event, ecommerce });
    }
  }
};
