import { connect } from "react-redux";
import { serializePaymentMethods } from "./constants";
import { actions } from "./ducks";
import { PaymentMethods } from "./payment-methods";
import { AutoRenewSlider } from "./slider";
import { AddPaymentMethod } from "./add-payment-method-tabs";
import Toggle from "./toggle";

let mapStateToProps = state => {
  let { supportautorenew, role } = state.root.userInfo;
  let { paymentGateways, paymentMethods } = state.wallet;

  return {
    ...serializePaymentMethods(paymentMethods, paymentGateways),
    role,
    supportautorenew:
      supportautorenew ||
      (role === "customer" && process.env.FEATURE_AUTO_RENEWAL)
  };
};

export let withAutoRenewal = connect(mapStateToProps, actions);

export default {
  Toggle: withAutoRenewal(Toggle),
  Slider: AutoRenewSlider,
  PaymentMethods: withAutoRenewal(PaymentMethods),
  AddPaymentMethodTabs: withAutoRenewal(AddPaymentMethod)
};
