import * as React from "react";

function CrossIcon(props) {
  return (
    <svg width={10} height={10} viewBox="0 0 612 612" {...props}>
      <path
        fill="#c5c5c5"
        d="M612 36.004L576.521.603 306 270.608 35.478.603 0 36.004l270.522 270.007L0 575.997l35.478 35.4L306 341.411l270.521 269.986 35.479-35.4-270.541-269.986z"
      />
    </svg>
  );
}

export default CrossIcon;
