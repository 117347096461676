import APIStatus from "common/api-status";
import UpdateButton from "common/buttons/update";
import ModalError from "common/errors/modal";
import "modals/modals.scss";
import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";

export let DisableAutoRenewal = props => {
  const { t } = useTranslation();
  return (
    <Modal
      show={props.show}
      dialogClassName="disable-auto-renewal-modal genmodal"
      keyboard
      onHide={props.onHide}
    >
      <Modal.Body>
        <div className="modal-close" onClick={props.onHide} />
        <div className="pr-left-ct">
          <div className="modal-title">
            {t("ODR_DISABLE_AUTO_RENEWAL", "DISABLE AUTO RENEWAL")}
          </div>
          <div className="modal-dom-name">
            {t("ODR_DNS_FOR", "For")}: {props.domainName}
          </div>
          <APIStatus monitors={props.monitorInfo}>
            {apiStatus => (
              <>
                {apiStatus.errors && (
                  <ModalError className="push-top">
                    {apiStatus.errors.message}
                  </ModalError>
                )}
                <div className="auto-renewal-modal-body">
                  <div className="modal-body-text">
                    {t(
                      "ODR_DISABLE_AUTO_RENEWAL_TEXT1",
                      "We recommend you keep auto-renewal on for your order to avoid losing data or your order due to expiry."
                    )}
                  </div>
                  <div className="modal-body-text text-bold">
                    {t(
                      "ODR_DISABLE_AUTO_RENEWAL_TEXT2",
                      "Are you sure you want to disable auto-renewal for this order?"
                    )}
                  </div>
                  <UpdateButton
                    updating={apiStatus.isFetching}
                    onClick={props.disableAutoRenewal}
                    className="btn-push-top wp-btn-red"
                    data-testid="auto-renewal-disable"
                  >
                    {t(
                      "ODR_DISABLE_AUTO_RENEWAL_YES",
                      "YES, DISABLE AUTO-RENEWAL"
                    )}
                  </UpdateButton>
                </div>
              </>
            )}
          </APIStatus>
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};
