import { CreditCard, Netbanking, Upi } from "assets";

export const paymentMandateOptions = {
  CARDS: "CREDIT/DEBIT CARDS",
  NETBANKING: "NETBANKING",
  PAYPAL: "PAYPAL",
  UPI: "UPI"
};

export const getAutoRenewalPGMapping = allowedGateways => {
  const paymentMandates = [
    {
      name: "Credit/Debit Card",
      icon: CreditCard,
      show: allowedGateways.paypw
    },
    {
      name: "Netbanking",
      icon: Netbanking,
      show: allowedGateways.payu
    },
    {
      name: "PayPal",
      icon: Netbanking,
      show: allowedGateways.paypal
    },
    {
      name: "UPI",
      icon: Upi,
      show: allowedGateways.payu
    }
  ];
  return paymentMandates.filter(_ => _.show);
};
